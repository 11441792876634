import { Box, Dialog, styled, Typography } from '@mui/material'
import MuiButton from '@mui/material/Button'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as AssistantIcon } from '../../../../assets/images/Assistant.svg'
import { padSettingChanged } from '../reducers/pad_settings'
import { visibleTabNoteToggled } from '../reducers/tabs'
import { selectPadSettings } from '../selectors'
import { SandboxShowcaseTypes } from './constants'

const MODAL_BG_COLOR = '#353C4E'

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: MODAL_BG_COLOR,

    // There are some lingering styles from the Rails stylesheets. This increases specificity enough to override them.
    '& h2': {
      color: '#fff',
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '1.5rem',
      paddingTop: theme.spacing(4.75),
    },
  },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
  root: {
    backgroundColor: MODAL_BG_COLOR,
    color: '#FFF',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: MODAL_BG_COLOR,
    color: '#FFF',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4),
  },
}))(MuiDialogActions)

const Button = withStyles(() => ({
  root: {
    margin: '0px!important',
    borderColor: '#2D72CC',
    color: '#FFF',
    fontWeight: 100,
    fontSize: '0.875rem',
  },
  outlined: {
    '&:hover': {
      backgroundColor: '#2D72CC',
    },
  },
}))(MuiButton)

const useDialogStyles = makeStyles((theme) => ({
  logo: {
    position: 'absolute',
    top: '-59px',
    left: '6px',
  },
  paper: {
    overflowY: 'unset',
    maxWidth: '400px',
  },
}))

const Divider = styled(Box)(({ theme }) => ({
  width: '1px',
  height: '50px',
  background: '#727682',
  marginLeft: `${theme.spacing(3)} !important`,
  marginRight: theme.spacing(3),
}))

export function WelcomeModal() {
  const dispatch = useDispatch()
  const { sandboxModal } = useSelector(selectPadSettings)
  const dialogStyles = useDialogStyles()

  const handleViewClick = (user: string) => () => {
    dispatch(padSettingChanged('sandboxView', user))
    const nextURL = `${window.location?.origin}${window.location?.pathname}?viewmodal=false&switcher=true&view=${user}`
    window.history.replaceState('', '', nextURL)
    dispatch(padSettingChanged('sandboxModal', false))
    dispatch(padSettingChanged('sandboxSwitcher', true))
    dispatch(visibleTabNoteToggled(user === SandboxShowcaseTypes.Interviewer))
  }

  return (
    <div>
      <Dialog
        open={sandboxModal}
        aria-labelledby="sanbox-modal"
        PaperProps={{
          className: dialogStyles.paper,
        }}
      >
        <AssistantIcon className={dialogStyles.logo} />
        <DialogTitle id="sanbox-modal">Welcome to the Sandbox</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            We understand you want to be prepared for your interviews. Play around in the CoderPad
            IDE ahead of your interview and get familiar with the setup.
          </Typography>
          <Box mb={2.5} />
          <Typography gutterBottom>Please select a view</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleViewClick(SandboxShowcaseTypes.Candidate)}
            color="primary"
          >
            {SandboxShowcaseTypes.Candidate}
          </Button>
          <Divider />
          <Button
            variant="contained"
            disableElevation
            onClick={handleViewClick(SandboxShowcaseTypes.Interviewer)}
            color="primary"
          >
            {SandboxShowcaseTypes.Interviewer}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
