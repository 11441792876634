import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, Stack } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'
import { useTranscriberContext } from '../Transcriber/ThanscriberContext/TranscriberContext'
import TwilioTrack from '../twilio_track'
import CallData from './call_data'
import { DeviceSelector } from './DeviceSelector'

export function PendingScreen() {
  const {
    audioDevices,
    videoDevices,
    noDeviceListAvailable,
    audioDeviceId,
    videoDeviceId,
    audioDeviceState,
    videoDeviceState,
    requestingDevices,
    fromInvite,
    isJoining,
  } = useSelector((state) => {
    const groupedDevices = _.groupBy(state.call.enumeratedDevices, 'kind')

    return {
      ..._.pick(state.call, [
        'audioDeviceId',
        'videoDeviceId',
        'audioDeviceState',
        'videoDeviceState',
        'requestingDevices',
        'fromInvite',
        'isJoining',
      ]),
      audioDevices: groupedDevices.audioinput || [],
      videoDevices: groupedDevices.videoinput || [],
      noDeviceListAvailable: state.call.enumerateFailed,
    }
  })
  const dispatch = useDispatch()
  const hasTranscriptions = usePadConfigValue('hasTranscriptions')
  const onCancel = useCallback(() => {
    dispatch({ type: 'call_canceled' })
  }, [dispatch])
  const { setAudioDeviceId } = useTranscriberContext()
  const onDeviceChange = useCallback(
    (kind, deviceId) => {
      dispatch({
        type: 'device_switched',
        kind,
        deviceId,
      })
      if (kind === 'audio') {
        setAudioDeviceId(deviceId)
      }
    },
    [dispatch, setAudioDeviceId]
  )
  const onContinue = useCallback(() => {
    if (hasTranscriptions) {
      dispatch({
        type: 'call_transcription_consent_requested',
        _analytics: {
          name: 'Call Transcription Consent Requested',
        },
      })
    } else {
      dispatch({
        type: 'call_join_requested',
        _analytics: {
          name: 'Call Join Attempted',
          params: {
            from_invite: fromInvite,
          },
        },
      })
    }
  }, [dispatch, hasTranscriptions, fromInvite])

  const handleAudioDeviceChange = useCallback(
    (evt) => {
      onDeviceChange('audio', evt.target.value)
    },
    [onDeviceChange]
  )

  const handleVideoDeviceChange = useCallback(
    (evt) => {
      onDeviceChange('video', evt.target.value)
    },
    [onDeviceChange]
  )

  const handleBackgroundClick = useCallback(() => {
    if (!(requestingDevices || isJoining)) onCancel()
  }, [requestingDevices, isJoining, onCancel])

  const ableToProceed =
    !requestingDevices &&
    audioDeviceId &&
    audioDeviceState === 'open' &&
    videoDeviceState !== 'opening'

  const joinVerb = fromInvite ? 'Join' : 'Start'

  const noticeOverlay = isJoining ? `${joinVerb}ing call...` : null

  // Sometimes we display messages in place of the video.
  const textInLieu = requestingDevices ? (
    <p>Waiting for devices...</p>
  ) : videoDeviceState === 'error' ? (
    <p>Video unavailable</p>
  ) : null

  return (
    <Dialog
      open
      onClose={handleBackgroundClick}
      dialogTitle="Join Call"
      content={
        <Stack spacing={2.5} minWidth="350px">
          {noticeOverlay && <div className="Pending-noticeOverlay">{noticeOverlay}</div>}
          <div
            className={classNames({
              'Pending-videoContainer': true,
              'Pending-videoContainer--textInLieu': !!textInLieu,
            })}
          >
            {videoDeviceState === 'open' && (
              <TwilioTrack kind="video" track={CallData.localVideoTrack} />
            )}
            {textInLieu}
          </div>
          <DeviceSelector
            kind="video"
            selectedId={videoDeviceId}
            deviceState={videoDeviceState}
            deviceList={videoDevices}
            handleDeviceChange={handleVideoDeviceChange}
            noDeviceListAvailable={noDeviceListAvailable}
          />
          <DeviceSelector
            kind="audio"
            selectedId={audioDeviceId}
            deviceState={audioDeviceState}
            deviceList={audioDevices}
            handleDeviceChange={handleAudioDeviceChange}
            noDeviceListAvailable={noDeviceListAvailable}
          />
          {/* TODO: List of who's in the call */}
          {/* TODO: Audio level meter */}
        </Stack>
      }
      actions={[
        <Button key="cancel" variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="join"
          variant="contained"
          color="primary"
          onClick={onContinue}
          disabled={!ableToProceed}
        >
          {joinVerb} Call
        </Button>,
      ]}
    />
  )
}
