import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { humanizeSeconds } from './time_helper'

// This shows interviewers the scores of a take-home with test cases.
export default class PadReport extends React.PureComponent {
  static propTypes = {
    hidden: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { padOpenedAt, padStartedAt, takeHomeSubmittedAt, takeHome, report } = window.padConfig
    const question = report.question || {}
    const timeElapsedSeconds =
      (padOpenedAt || padStartedAt) && takeHomeSubmittedAt
        ? parseInt(takeHomeSubmittedAt - (padOpenedAt || padStartedAt))
        : 0
    const testCaseRuns = report.testCaseRuns
    const testsPassed = _.sumBy(report.testCaseRuns, 'passed')
    const testsTotal = report.testCaseRuns.length
    return (
      <div className={classNames({ hidden: this.props.hidden, PadReport: true })}>
        <h4 className="PadReport-questionTitle PadReport-heading">
          Question: <a href={'/questions/' + question.id}>{question.title}</a>
        </h4>
        {takeHome && (
          <p>
            Time elapsed: <span>{humanizeSeconds(timeElapsedSeconds)}</span>
          </p>
        )}
        <h4 className="PadReport-testsSummary PadReport-heading">
          <span className="PadReport-testsPassedCount">
            {testsPassed} Test{testsPassed === 1 ? '' : 's'} Passed
          </span>{' '}
          out of {testsTotal} Total
        </h4>
        <table className="table">
          <thead>
            <tr>
              <th>Pass</th>
              <th>Visible</th>
              <th>Arguments</th>
              <th>Result</th>
              <th>Expected</th>
            </tr>
          </thead>
          <tbody>
            {testCaseRuns &&
              testCaseRuns.map((tc) => {
                return (
                  <tr key={tc.id} className="PadReport-testDetail">
                    <td className={`PadReport-testCasePassed--${tc.passed ? 'pass' : 'fail'}`}>
                      {tc.passed ? '✓' : '✗'}
                    </td>
                    <td>{tc.visible ? '✓' : ''}</td>
                    <td>
                      <pre>
                        {typeof tc.arguments === 'object' ? tc.arguments.join(', ') : tc.arguments}
                      </pre>
                    </td>
                    <td className={`PadReport-testCaseResult--${tc.passed ? 'pass' : 'fail'}`}>
                      <pre>
                        {typeof tc.result === 'object' ? JSON.stringify(tc.result) : tc.result}
                      </pre>
                    </td>
                    <td>
                      <pre>{tc.expected_result}</pre>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
}
