import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useCallback, useMemo, useState } from 'react'
import { useFetch } from 'utils/fetch/useFetch'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'

export function useChangeQuestionVariant() {
  const fetch = useFetch()
  const slug = usePadConfigValue('slug')
  const [pending, setPending] = useState(false)
  const { environment } = useActiveEnvironment()

  const environmentSlugStem = useMemo(() => {
    return environment?.slug.split('-variant-')[0]
  }, [environment?.slug])

  return useCallback(
    async (questionVariantId: number) => {
      if (pending) {
        return
      }
      setPending(true)
      await fetch(`/${slug}/change_question_variant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question_variant_id: questionVariantId,
          environment_slug_stem: environmentSlugStem,
        }),
      })
      setPending(false)
    },
    [pending, fetch, slug, environmentSlugStem]
  )
}
