import { monaco } from '@codingame/monaco-editor-wrapper'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  usePadConfigValue,
  usePadConfigValues,
} from '../../../dashboard/components/PadContext/PadContext'
import { IFile } from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { selectLanguagesAvailable, selectUserInfo } from '../../selectors'
import SyncHandle from '../../sync_handle'
import { getUserColor } from '../../util'
import { Firepad } from '../types'
import { useFirepadCreateOptions } from './useFirepadCreateOptions'

export interface MonacoFirepadFileProps {
  file: IFile
  onError: (filePath: string, e: Error) => void
  onReady: (filePath: string) => void
  onDestroy: (filePath: string) => void
}

export const MonacoFirepadFile: React.FC<MonacoFirepadFileProps> = ({
  file,
  onError,
  onReady,
  onDestroy,
}) => {
  const firepadRef = useRef<Firepad | null>(null)
  const firebaseAuthorId = usePadConfigValue('firebaseAuthorId')
  const padUserId = useMemo(
    () => (firebaseAuthorId ?? Date.now().toString().substring(5)).toString(),
    [firebaseAuthorId]
  )
  const padUsers = useSelector(selectUserInfo)
  const { invisible, isPlayback } = usePadConfigValues('invisible', 'isPlayback')
  const firepadOptions = useFirepadCreateOptions(padUserId)
  const availableLanguages = useSelector(selectLanguagesAvailable) ?? {}
  const { environment, setLargeFile } = useActiveEnvironment()
  const activeFileLanguageMeta = availableLanguages[environment?.language ?? '']
  const monacoLanguage = useMemo(
    () => (file.path === '.cpad' ? 'json' : activeFileLanguageMeta?.monaco_language),
    [file.path, activeFileLanguageMeta]
  )

  const handleLargeFileUpdate = useCallback(
    (large: boolean) => {
      const modelUri = monaco.Uri.file(file.monacoPath)

      const model =
        monaco.editor.getModel(modelUri) ?? monaco.editor.createModel('', monacoLanguage, modelUri)

      if (large) {
        setLargeFile(file.id, true)
        if (firepadRef.current != null) {
          firepadRef.current.dispose()
          firepadRef.current = null
          model.dispose()
        }
      } else {
        setLargeFile(file.id, false)
        if (firepadRef.current == null) {
          const firepad = SyncHandle().firepad(model, file.firebasePath, firepadOptions)
          firepadRef.current = firepad

          firepad.on('error', (e: Error) => {
            onError(file.monacoPath, e)
          })

          firepad.on('ready', () => {
            onReady(file.monacoPath)
          })
        }
      }
    },
    [
      file.firebasePath,
      file.id,
      file.monacoPath,
      firepadOptions,
      monacoLanguage,
      onError,
      onReady,
      setLargeFile,
    ]
  )

  // Effect for creating the model and connecting to Firepad
  useEffect(() => {
    const modelUri = monaco.Uri.file(file.monacoPath)

    const model =
      monaco.editor.getModel(modelUri) ?? monaco.editor.createModel('', monacoLanguage, modelUri)

    let largeFileWatcher: (snap: firebase.database.DataSnapshot) => void | null
    if (isPlayback) {
      onReady(file.monacoPath)
    } else {
      // don't instantiate the firepad connection for binary or large files
      if (file.isBinary || file.isLargeFile) {
        onReady(file.monacoPath)
      } else {
        const firepad = SyncHandle().firepad(model, file.firebasePath, firepadOptions)
        firepadRef.current = firepad

        firepad.on('error', (e: Error) => {
          onError(file.monacoPath, e)
        })

        firepad.on('ready', () => {
          onReady(file.monacoPath)
        })
      }

      largeFileWatcher = SyncHandle().watch(`${file.firebasePath}/large`, handleLargeFileUpdate)
    }

    return () => {
      // If something gets weird with the disposal of Firepad, an exception is thrown. Don't let that stop the show,
      // log the exception and let the pad continue.
      try {
        if (firepadRef.current != null) {
          firepadRef.current.dispose()
          firepadRef.current = null
        }
        onDestroy(file.monacoPath)

        if (largeFileWatcher != null) {
          SyncHandle().off(`${file.firebasePath}/large`, largeFileWatcher)
        }

        model.dispose()
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            layer: 'react',
          },
        })
      }
    }
  }, [
    file.firebasePath,
    file.isBinary,
    file.isLargeFile,
    file.monacoPath,
    firepadOptions,
    monacoLanguage,
    onError,
    onReady,
    onDestroy,
    isPlayback,
    file.id,
    environment?.slug,
    handleLargeFileUpdate,
  ])

  // Effect to set the user color when the pad users change. This is relevant for just
  // the cursor in the editor. This has no effect on the user color in the pad footer.
  const userColor = useMemo((): string | undefined => {
    if (padUsers != null && !invisible! && padUserId != null) {
      return getUserColor(padUsers, padUserId)
    }
    return undefined
  }, [padUsers, invisible, padUserId])
  useEffect(() => {
    if (userColor != null) {
      firepadRef.current?.setUserColor(userColor)
    }
  }, [userColor])

  const userName = useSelector((state) => state.userState.uncommittedUsername)
  useEffect(() => {
    firepadRef.current?.setUserName(userName)
  }, [userName])

  return null
}
