import { GridValidRowModel } from '@mui/x-data-grid'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { DataTable } from 'packs/main/components/DataTable/DataTable'
import React, { useCallback } from 'react'

import { useRequestClientConfiguration } from '../RequestClientConfigurationContext'

export const RequestClientHeaders: React.FC = () => {
  const isPlayback = usePadConfigValue('isPlayback')
  const { headersData, setBodyType } = useRequestClientConfiguration()

  const onRowEdit = useCallback(
    (newRow: GridValidRowModel) => {
      if (newRow.name === 'Content-Type') {
        setBodyType(newRow.value)
      }
      return headersData.onRowEdit(newRow)
    },
    [headersData, setBodyType]
  )

  return (
    <DataTable
      rows={headersData.rows}
      columns={headersData.columns}
      onAddRow={headersData.handleAddRow}
      onDeleteRow={headersData.handleDeleteRow}
      onRowEdit={onRowEdit}
      readOnly={isPlayback}
    />
  )
}
