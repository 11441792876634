import { useEffect, useRef } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'
import trackEvent from '../../../track_event'

const CHECK_INTERVAL = 60000 // 1 minute.

interface NetworkInformation {
  effectiveType: string
  downlink: number
  rtt: number
}
function trackNavigatorConnection(connection: NetworkInformation) {
  trackEvent('latency_tracking_connection', {
    effectiveType: connection.effectiveType,
    downlink: connection.downlink,
    rtt: connection.rtt,
  })
}

export function useLatencyMonitoring() {
  const { firebaseAuthorId, isPlayback, slug } = usePadConfigValues(
    'firebaseAuthorId',
    'isPlayback',
    'slug'
  )

  const hasSetupConnectionListener = useRef(false)
  useEffect(() => {
    // @ts-ignore navigator.connection is only available in Chrome and Edge at this time.
    if (!isPlayback && !hasSetupConnectionListener.current && navigator.connection != null) {
      // @ts-ignore navigator.connection is only available in Chrome and Edge at this time.
      trackNavigatorConnection(navigator.connection)

      // @ts-ignore navigator.connection is only available in Chrome and Edge at this time.
      navigator.connection.onchange = ({ target }) => {
        trackNavigatorConnection(target)
      }
    }
    hasSetupConnectionListener.current = true
  }, [isPlayback])

  // Setup interval to check latency across the app and Firebase.
  const isSettingFB = useRef(false)
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null
    if (!isPlayback) {
      interval = setInterval(() => {
        if (document.hasFocus() && !isSettingFB.current) {
          isSettingFB.current = true
          const start = Date.now()
          const fbProm = new Promise((resolve) => {
            // This takes advantage of the `onComplete` callback provided to SyncHandle.set to measure the duration of
            // time a round trip to Firebase takes.
            SyncHandle().set(`firebaseLatencies/${firebaseAuthorId}`, Date.now(), () => {
              const latency = Date.now() - start
              resolve(latency)
            })
          })
          fbProm
            .then((firebaseLatency) => {
              trackEvent('latency_tracking_firebase', {
                firebaseLatency,
              })
            })
            .finally(() => {
              isSettingFB.current = false
            })
        }
      }, CHECK_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [firebaseAuthorId, isPlayback, slug])

  // TODO: after observing latency times from analytics, we can decide what to do with this notification code.
  // Setup listener to read latencies and dispatch notifications for clients experiencing latency, as necessary.
  // const notificationStatusRef = useRef(new Set())
  // const padUsers = useSelector(selectUserInfo)
  // useEffect(() => {
  //   const listener = SyncHandle().watch<Record<string, number>>(
  //     'firebaseLatencyTimes',
  //     (latencies) => {
  //       for (const authorId in latencies) {
  //         console.log(notificationStatusRef.current, notificationStatusRef.current.has(authorId))
  //         if (
  //           latencies[authorId] > NOTIFICATION_THRESHOLD &&
  //           !notificationStatusRef.current.has(authorId)
  //         ) {
  //           notificationStatusRef.current.add(authorId)
  //           const user = padUsers[authorId]
  //           if (user?.isOnline) {
  //             const message = user.self
  //               ? 'We have detected high latency while syncing your code editor. Please check your internet connection.'
  //               : `High editor syncing latency detected for user ${user.name}. Their code edits may not be synced.`
  //             dispatch(
  //               enqueueNotif({
  //                 message,
  //                 autoDismissMs: 5000,
  //                 variant: 'warning',
  //                 key: `latency-${authorId}`,
  //               })
  //             )
  //           }
  //         } else if (latencies[authorId] < NOTIFICATION_THRESHOLD) {
  //           notificationStatusRef.current[authorId] = false
  //           notificationStatusRef.current.delete(authorId)
  //         }
  //       }
  //     }
  //   )

  //   return () => {
  //     SyncHandle().off('firebaseLatencyTimes', listener)
  //   }
  // }, [dispatch, padUsers])
}
