import { styled } from '@mui/material'

export interface UserDotProps {
  color: string
}

export const UserDot = styled('span')<UserDotProps>(({ color }) => ({
  display: 'inline-block',
  width: 14,
  height: 14,
  borderRadius: '50%',
  backgroundColor: color ?? '#3E4248',
  marginRight: 4,
  flex: '0 0 auto',
}))
