import { Box, Link, Paper, styled, SvgIcon, Typography } from '@mui/material'
import React, { FC } from 'react'
import { cgBaseUrl } from 'utils/codingameService'

import { ReactComponent as ScreenLogo } from '../../../../../../assets/images/screen_logo.svg'
import { useAdSpaceAnalytics } from '../useAdSpaceAnalytics'

const Wrapper = styled(Paper)(({ theme }) => ({
  background: theme.palette.playback.adSpace.background,
  borderColor: theme.palette.playback.adSpace.border,
  borderWidth: '1px',
  borderRadius: '4px 4px 0 0',
  borderStyle: 'solid',
  borderBottom: 'none',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginTop: '61px',
  padding: theme.spacing(2),
}))

const AdLink = styled(Link)(({ theme }) => ({
  color: '#428bca',
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  fontWeight: 600,
  textDecoration: 'none',
}))

export const ScreenAd: FC = () => {
  const trackAnalytics = useAdSpaceAnalytics('xsell')

  return (
    <Wrapper>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon
          component={ScreenLogo}
          inheritViewBox
          sx={{ height: '43px', width: '43px', marginRight: 1 }}
        />
        <Typography
          sx={{
            color: 'playback.adSpace.tidle',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '120%',
          }}
        >
          Screen before you interview for more insights
        </Typography>
      </Box>
      <Typography
        sx={{
          color: 'playback.adSpace.body',
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '0.875rem',
          marginY: 2,
        }}
      >
        CoderPad now includes Screen! Send auto-graded tests in just a few clicks to get detailed
        reports and interview the best candidates.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <AdLink
          target="_blank"
          href={`${cgBaseUrl}/dashboard/campaigns/`}
          onClick={() =>
            trackAnalytics({
              message: 'pre-screen better',
              link: `${cgBaseUrl}/dashboard/campaigns/`,
            })
          }
        >
          Try Screen now
        </AdLink>
        <AdLink
          target="_blank"
          href="https://www.codingame.com/work/online-coding-test/"
          onClick={() =>
            trackAnalytics({
              message: 'pre-screen better',
              link: 'https://www.codingame.com/work/online-coding-test/',
            })
          }
        >
          Explore interactive demo
        </AdLink>
      </Box>
    </Wrapper>
  )
}
