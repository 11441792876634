import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, Typography } from '@mui/material'
import React from 'react'

interface ParticipantDeclinedTranscriptionProps {
  open: boolean
  onClose: () => void
}
export function ParticipantDeclinedTranscription({
  open,
  onClose,
}: ParticipantDeclinedTranscriptionProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      dialogTitle="Someone declined call transcription"
      content={
        <div>
          <Typography variant="body1">
            At least 1 call participant chose to join the call without accepting transcription
            recording.
          </Typography>
          <Typography variant="body1">
            The Interviewer Notes tab is available for note taking.
          </Typography>
        </div>
      }
      actions={[
        <Button key="accept" onClick={onClose} variant="contained" color="primary">
          Got it
        </Button>,
      ]}
    />
  )
}
