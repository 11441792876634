import _ from 'lodash'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'

const MonacoEditor = React.lazy(
  () => import(/* webpackChunkName: "MonacoEditor" */ './Monaco/MonacoEditor')
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function _MultiEditor({ files }: { files: Record<string, any> }): JSX.Element {
  return (
    <Suspense fallback={null}>
      <MonacoEditor />
    </Suspense>
  )
}

function MultiEditor() {
  const files = useSelector((state) =>
    state.padSettings.language === 'html' ? _.omit(state.files, ['_language', '0_global']) : {}
  )
  return <_MultiEditor files={files} />
}

export default MultiEditor
