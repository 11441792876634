import '../MonacoThemes'

import { monaco, updateUserConfiguration } from '@codingame/monaco-editor-wrapper'
import { selectEditorSettings, selectIsDarkTheme } from 'packs/main/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { setCoderpadTheme } from '../MonacoThemes'

export function useMonacoSettings() {
  const { environment } = useActiveEnvironment()
  const darkColorScheme = useSelector(selectIsDarkTheme)

  useEffect(() => {
    setCoderpadTheme(darkColorScheme)
  }, [darkColorScheme])

  useMonacoConfiguration(environment?.language ?? environment?.projectTemplateSlug)
}

export function useMonacoConfiguration(tabSettingsId?: string) {
  const { tabSizes, fontSize, autocomplete, autoCloseBrackets } = useSelector(selectEditorSettings)

  useEffect(() => {
    const DEFAULT_TAB_SIZE = 2
    const tabSize =
      tabSettingsId != null ? tabSizes[tabSettingsId] ?? DEFAULT_TAB_SIZE : DEFAULT_TAB_SIZE

    updateUserConfiguration(
      JSON.stringify({
        'editor.renderWhitespace': 'all',
        'editor.renderLineHighlight': 'line',
        'editor.wordWrap': 'on',
        'editor.fontSize': fontSize ?? 14,
        'editor.detectIndentation': false,
        'editor.tabSize': tabSize,
        'editor.wrappingIndent': 'indent',
        'editor.quickSuggestions': autocomplete,
        'editor.fixedOverflowWidgets': true,
        'editor.minimap.enabled': false,
        'editor.lineNumbersMinChars': 1,
        'editor.inlayHints.enabled': false,
        'editor.autoClosingBrackets': autoCloseBrackets ?? false ? 'languageDefined' : 'never',
        '[plaintext]': {
          'editor.wordBasedSuggestions': false,
        },
        '[markdown]': {
          'editor.quickSuggestions': false,
          'editor.wordBasedSuggestions': false,
        },
      })
    )

    // model options are not updated by monaco after creation
    const modelOptions: monaco.editor.ITextModelUpdateOptions = {
      tabSize,
    }
    for (const model of monaco.editor.getModels()) {
      model.updateOptions(modelOptions)
    }
  }, [tabSettingsId, tabSizes, fontSize, autocomplete, autoCloseBrackets])
}
