import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { selectUserInfo } from '../../../selectors'
import SyncHandle from '../../../sync_handle'
import { getUserColor } from '../../../util'

/**
 * There is some trickery going on here with Firepad to get user info into the global users document in Firebase.
 * In environments, the editor's Firepad connects to the document inside the environment's files, not the global
 * file. This means that connection and color info defined for the editor's Firepad does not propagate back to the
 * global users document. Because the connection info is not in the global users document, the app (most notably
 * the users list in the footer) shows the user as "offline". To rectify this:
 *
 * 1. Create a headless firepad in the environments pane that will populate the global users document with connection
 *     and color info. This will cause the user to appear "online" throughout the rest of the app.
 * 2. Still set the user color in the editor's Firepad. This is required to make the user's cursor the correct color.
 */
export function useFirebaseUsers() {
  const [headlessFP, setHeadlessFP] = useState<any>(null)

  const { firebaseAuthorId, invisible } = usePadConfigValues('firebaseAuthorId', 'invisible')

  // This is the value for the `userId` property sent to Firepad.
  const firepadUserId = useMemo(
    () => (firebaseAuthorId ?? Date.now().toString().substring(5)).toString(),
    [firebaseAuthorId]
  )

  // Set up the headless Firepad.
  useEffect(() => {
    const fp = SyncHandle().firepadHeadless('', { userId: firepadUserId })
    fp.on('ready', () => {
      setHeadlessFP(fp)
    })

    return () => {
      fp.dispose()
      setHeadlessFP(null)
    }
  }, [firebaseAuthorId, firepadUserId])

  // Effect to set the user color when the pad users change.
  const padUsers = useSelector(selectUserInfo)
  useEffect(() => {
    if (headlessFP && padUsers && !invisible) {
      headlessFP.setUserColor(getUserColor(padUsers, firepadUserId))
    }
  }, [headlessFP, invisible, padUsers, firepadUserId])
}
