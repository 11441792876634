import { gql, useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import { PadTranslation } from '../../../../packs/main/constants'
import * as queryStates from '../../../queryStates'
import {
  MutationPadEmailInviteSendArgs,
  PadEmailInviteSendInput,
  PadEmailInviteSendPayload,
} from '../../../types'

export const PAD_SEND_EMAIL_INVITE_MUTATION = gql`
  mutation PadEmailInviteSend($input: PadEmailInviteSendInput!) {
    padEmailInviteSend(input: $input) {
      message
      errors {
        message
        path
      }
    }
  }
`

export interface PadEmailInviteSendData {
  padEmailInviteSend: PadEmailInviteSendPayload
}

export function usePadEmailInviteSend() {
  const [padEmailInviteSend, { data, error, loading }] = useMutation<
    PadEmailInviteSendData,
    MutationPadEmailInviteSendArgs
  >(PAD_SEND_EMAIL_INVITE_MUTATION)

  const handlePadEmailInviteSend = useCallback(
    (padEmailInviteSendInput: PadEmailInviteSendInput) =>
      padEmailInviteSend({
        variables: { input: padEmailInviteSendInput },
        context: { source: 'usePadEmailInviteSend.ts' },
      }),
    [padEmailInviteSend]
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(error.message || PadTranslation.padInvitesEmailSendError)
    } else if (data?.padEmailInviteSend?.errors?.length) {
      return queryStates.error(data.padEmailInviteSend.errors[0].message)
    } else if (data?.padEmailInviteSend) {
      return queryStates.success(
        data?.padEmailInviteSend?.message || PadTranslation.padInvitesEmailSendSuccess
      )
    } else {
      return queryStates.initial()
    }
  }, [data, error, loading])

  return useMemo(
    () => ({
      padEmailInviteSend: handlePadEmailInviteSend,
      status,
    }),
    [handlePadEmailInviteSend, status]
  )
}
