import { monaco } from '@codingame/monaco-editor-wrapper'
import _ from 'lodash'
import { DefaultRootState } from 'react-redux'
import { Action } from 'redux'
import { channel, eventChannel } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { Socket } from 'socket.io-client'
import { Language } from 'utils'
import { fetchAuthToken } from 'utils/padAuthToken/padAuthToken'

import {
  EnvironmentSummary,
  EnvironmentTypes,
  IFile,
} from '../Environments/EnvironmentsContext/EnvironmentsContext'
import { createExecuteSocket } from '../executeSocket'
import logEvent from '../log_pad_event'
import padConfig from '../pad_config'
import {
  ExecEnv,
  selectExecEnabled,
  selectExecEnv,
  selectMultifileEnabled,
  selectQuestionId,
  selectTestCasesEnabled,
} from '../selectors'
import SyncHandle from '../sync_handle'
import trackEvent from '../track_event'

// Async task to handle executing code

let hasInitializedSocket = false

const socketCallbackPutProxy = channel()

enum PLATFORM_ERROR_REASON {
  Memory = 'MEMORY', // program used too much memory
  Cpu = 'CPU', // program used too much cpu
  Network = 'NETWORK', // program used too much network
  Output = 'OUTPUT', // program emitted too much output
  Ttl = 'TTL', // program reached its ttl
  Unknown = 'UNKNOWN',
}

export default function* executionSaga(initialLanguage: string) {
  if (hasInitializedSocket) {
    return
  }
  hasInitializedSocket = true
  let editor: monaco.editor.ICodeEditor | null = null
  const initialEnv: ExecEnv = yield select(selectExecEnv)
  const jwt: string = yield call(fetchAuthToken, padConfig.slug, {
    environmentSlug: initialEnv.environmentSlug,
    customDatabaseLanguage: initialEnv.customDatabaseLanguage,
  })
  const socket = createExecuteSocket(
    padConfig.slug,
    JSON.stringify({
      language: initialEnv.language ?? initialLanguage,
      firebaseShard: padConfig.firebaseShard,
      project: initialEnv.projectTemplateSlug,
      projectTemplateVersion: initialEnv.projectTemplateVersion,
      environmentSlug: initialEnv.environmentSlug,
      padEnded: padConfig.padState === 'ended',
      token: jwt,
    })
  )
  const socketChannel = eventChannel((emit) => {
    socket.on('reset', () => emit(['reset']))
    socket.on('started', (data: unknown) => emit(['started', data]))
    socket.on('output', (msg: unknown) => emit(['output', msg]))
    socket.once('catchup', (msg: unknown) => {
      emit(['output', msg])
    })
    socket.on('project/termOutput', (data: unknown) => emit(['termOutput', data]))
    socket.on('project/catchupTerms', (dataList: unknown[]) =>
      dataList.forEach((data: unknown) => emit(['termOutput', data]))
    )
    socket.on('htmlResult', (data: unknown) => emit(['htmlResult', data]))
    socket.on('serviceStatus', (data: unknown) => emit(['serviceStatus', data]))
    socket.on('catchupServiceStatus', (data: unknown) => emit(['serviceStatus', data]))
    socket.on('finished', (data: unknown) => emit(['finished', data]))
    socket.on('stopped', (data: unknown) => emit(['stopped', data]))
    socket.on('platformError', (msg: unknown) => emit(['platformError', msg]))
    socket.on('reconnect', (msg: unknown) => emit(['reconnect', msg]))
    socket.on('reconnecting', (msg: unknown) => emit(['reconnecting', msg]))
    socket.on('reconnect_failed', (msg: unknown) => emit(['connection_failed', msg]))
    socket.on('reconnect_error', (msg: unknown) => emit(['connection_failed', msg]))
    socket.on('connect_error', (msg: unknown) => emit(['connection_failed', msg]))
    socket.on('connect_timeout', (msg: unknown) => emit(['connection_failed', msg]))
    socket.on('disconnect', (reason: unknown) => emit(['connection_failed', reason]))
    socket.on('connect', () => emit(['connection_established']))

    socket.on('project/catchupTerm', (data: unknown) => emit(['termOutput', data]))
    socket.on('catchupTerm', (data: unknown) => {
      emit(['output', data])
    })

    // This has to return an unsubscribe function, but this channel should never close,
    // so don't bother... we would have to save and undo all the closures above for
    // a situation that never happens.
    return () => {
      console.log('oh no. This shouldnt ever happen')
    }
  })

  // Saga proxy used to dispatch actions from within socket callbacks. Cannot usually dispatch actions using
  // `yield` from within callbacks.
  yield takeEvery(socketCallbackPutProxy, function* (action: Action) {
    yield put(action)
  })

  yield takeEvery('console_catchup', function* () {
    socket.emit('catchupTerm')
  })

  yield takeEvery(
    'editor_mounted',
    function* (action: { fileId: string; editor: monaco.editor.ICodeEditor } & Action) {
      if (!action.fileId || action.fileId === '0_global') {
        editor = action.editor
      }
    }
  )

  yield takeEvery(
    ['question_data_loaded', 'question_selected_by_local_user', 'environment_question_changed'],
    function* (updatedEnv) {
      const currentEnv: ExecEnv = yield select(selectExecEnv)
      const {
        language,
        projectTemplateSlug,
        projectTemplateVersion,
        environmentId,
        environmentSlug,
        customDatabaseId = null,
        customDatabaseLanguage = null,
        customFiles = [],
        testCasesEnabled = false,
        spreadsheet,
      } = { ...currentEnv, ...updatedEnv }
      // If it's a test case question, then skip handling.
      if (testCasesEnabled) {
        return
      }
      /*
       * When the language is mysql or postgresql, an example database is attached in execute.
       * So that this database is still available when the user switches to a different language,
       * we need to set the customDatabaseLanguage in Firebase to the current language.
       */
      const isUsingFallbackCustomDatabase =
        language === 'mysql' || (language === 'postgresql' && !customDatabaseLanguage)
      if (isUsingFallbackCustomDatabase && environmentId != null) {
        SyncHandle().set(`environments/${environmentId}/customDatabaseLanguage`, language)
      }
      yield updateSocketEnv(socket, {
        language: spreadsheet != null ? Language.GSHEETS : language,
        project: projectTemplateSlug,
        firebaseShard: padConfig.firebaseShard,
        customDatabaseId,
        customDatabaseLanguage: isUsingFallbackCustomDatabase ? language : customDatabaseLanguage,
        customFiles: _.map(customFiles, 'id'),
        projectTemplateVersion,
        environmentSlug,
      })
    }
  )

  yield takeEvery(
    'environment_changed',
    function* ({ environment }: { environment: EnvironmentSummary } & Action) {
      if (environment == null) {
        return
      }

      const {
        language,
        projectTemplateSlug,
        projectTemplateVersion,
        customDatabaseLanguage,
        slug,
        spreadsheet,
      } = environment
      yield updateSocketEnv(socket, {
        language: spreadsheet != null ? Language.GSHEETS : language,
        project: projectTemplateSlug,
        firebaseShard: padConfig.firebaseShard,
        customDatabaseLanguage,
        projectTemplateVersion,
        environmentSlug: slug,
      })
    }
  )

  yield takeEvery(
    'pad_setting_changed',
    function* ({ key, value, remote }: { key: string; value: string; remote?: boolean } & Action) {
      if (
        ![
          'customDatabaseId',
          'customDatabaseLanguage',
          'language',
          'execEnabled',
          'projectTemplateSlug',
          'environmentSlug',
        ].includes(key)
      )
        return
      if (remote || (yield shouldBypass())) return

      const execSettings: ExecEnv = yield select(selectExecEnv)

      if (['html', 'markdown', 'plaintext'].includes(execSettings.language!)) return

      yield updateSocketEnv(socket, { ...execSettings, firebaseShard: padConfig.firebaseShard })
    }
  )

  yield takeEvery(
    'package_changed',
    function* ({ databaseLanguage }: { databaseLanguage: string } & Action) {
      if (!databaseLanguage) {
        return
      }
      // If the package needs a database and the pad doesn't have one,
      // add the example database {id: null, language}.
      const execSettings: ExecEnv = yield select(selectExecEnv)
      if (execSettings.customDatabaseLanguage !== databaseLanguage) {
        yield updateSocketEnv(socket, { ...execSettings, customDatabaseLanguage: databaseLanguage })
      }
    }
  )

  yield takeEvery('reset_clicked', function* (action: Action) {
    if ((yield shouldBypass()) as boolean) {
      return
    }

    socket.emit('reset')
  })

  yield takeEvery('clear_clicked', function* (action: Action) {
    if ((yield shouldBypass()) as boolean) {
      return
    }

    socket.emit('clear')
  })

  yield takeEvery('console_input_typed', function* ({ input }: { input: unknown } & Action) {
    if ((yield shouldBypass()) as boolean) {
      return
    }

    socket.emit('input', input)
  })

  yield takeEvery(
    'term_input_typed',
    function* ({ id, input }: { id: unknown; input: unknown } & Action) {
      if ((yield shouldBypass()) as boolean) {
        return
      }

      socket.emit('project/termInput', { id, input })
    }
  )

  yield takeEvery('project/request', function* (action: { requestConfig: unknown } & Action) {
    const { requestConfig } = action

    socket.emit('project/request', requestConfig, (request?: { id: string; message: string }) => {
      // Presence of a request `id` means execute was able to process the request. Otherwise try to interpret an error.
      if (request?.id) {
        socketCallbackPutProxy.put({ type: 'project/request_sent', requestId: request.id ?? null })
      } else {
        const message =
          request!.message ?? 'An unknown error occurred while performing the request.'
        socketCallbackPutProxy.put({ type: 'project/request_error', message })
      }
    })
  })

  yield takeEvery('project/abort_request', function* (action: { requestId: string } & Action) {
    const { requestId } = action

    socket.emit('project/requestAbort', requestId, () => {
      // We do not expect any sort of acknowledgement from execute for these actions.
    })
  })

  yield takeEvery(
    'run_clicked',
    function* (
      action: {
        meta?: { isEnvironment: boolean }
        payload: {
          environment: EnvironmentSummary
          files: IFile[]
          selectionOnly: boolean
          runCommand?: string
        }
      } & Action
    ) {
      if ((yield shouldBypass()) as boolean) {
        return
      }

      const environmentRunCommand = action.payload?.runCommand

      // If program is running, then this is a cancel.
      const running: boolean = yield select((state) => state.execution.running)
      if (running) {
        socket.emit('stop')
        return
      }

      const languageName: string = yield select((state) => state.padSettings.language)
      const projectTemplateSlug: string = yield select(
        (state) => state.padSettings.projectTemplateSlug
      )
      const projectTemplateVersion: string = yield select(
        (state) => state.padSettings.projectTemplateVersion
      )
      const environmentSlug: string = yield select((state) => state.padSettings.environmentSlug)
      const lang = window.CoderPad.LANGUAGES[languageName as Language]
      if (lang && !lang.execution) return

      let code: string | undefined = undefined
      let files: { path: string; contents: string }[]
      if (action.meta?.isEnvironment && environmentRunCommand) {
        console.log('DO RUN COMMAND', environmentRunCommand)
      } else if (
        action.meta?.isEnvironment &&
        action.payload?.environment &&
        action.payload?.files
      ) {
        /**
         * Pad environments do not store their file/code info in the Redux store. When a pad environment is ran,
         * extra info is included in the `run_clicked` action denoting the environment, as well as its files, so that
         * we can query for the code/file contents to send to the execution service.
         */
        const { environment, files: envFiles } = action.payload

        if (environment.kind === EnvironmentTypes.Language && environment.language === 'html') {
          // Query Firebase for all of the files' contents.
          files = yield Promise.all(
            envFiles.map((file) => {
              return new Promise((resolve) => {
                const headlessFirepad = SyncHandle().firepadHeadless(file.firebasePath)

                try {
                  headlessFirepad.getText().then((contents: string) => {
                    resolve({
                      path: `/home/coderpad/${file.name}`,
                      contents,
                    })
                  })
                } finally {
                  headlessFirepad.dispose()
                }
              })
            })
          )

          code = files.find((ef) => ef.path.endsWith('index.html'))!.contents
        } else if (envFiles.length === 1) {
          // if the user wants to execute only the code that they have selected in the editor,
          // then we need to get the current selection from monaco instead of from firebase.  make
          // sure the user has also selected a range, instead of just placing their cursor somewhere.
          const selectionRange = editor?.getSelection()
          const monacoModel = editor?.getModel()
          if (
            action.payload.selectionOnly &&
            monacoModel &&
            selectionRange &&
            !selectionRange.isEmpty()
          ) {
            code = monacoModel.getValueInRange(selectionRange)
          } else {
            // Just get the code from the single file in the environment.
            const headlessFirepad = SyncHandle().firepadHeadless(envFiles[0].firebasePath)

            try {
              code = yield headlessFirepad.getText()
            } finally {
              headlessFirepad.dispose()
            }
          }
        }
      } else if (lang.files && ((yield select(selectMultifileEnabled)) as ExecEnv)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const stateFiles: Record<string, any> = yield select(
          (state: DefaultRootState) => state.files
        )
        // TODO: make the omit of reserved elements less brittle
        // if user has the monaco, we need to query firebase for the latest version of the three files
        // -- exception for sandbox pads (which aren't connected to firebase), in this case we need to
        //    pull current editor contents (or fallback to example code for that file)
        const fileContentsPromises = Object.values(_.omit(stateFiles, '_language')).map((file) => {
          if (padConfig.isSandbox) {
            return new Promise((resolve) => {
              const modelUri = monaco.Uri.file(file.path.replace('/home/coderpad', '/tmp/project'))
              const monacoModel = monaco.editor.getModel(modelUri)
              const contents =
                monacoModel != null
                  ? monacoModel.getValue()
                  : file.id === '0_global'
                  ? lang.multifile_example
                  : lang.files!.find((f) => f.path === file.path)!.example
              resolve({
                path: file.path,
                contents: contents,
              })
            })
          } else {
            const firebasePath = file.fileId ? `files/${file.fileId}` : ''
            const headlessFirepad = SyncHandle().firepadHeadless(firebasePath)

            return new Promise((resolve) => {
              headlessFirepad.on('ready', () => {
                // Empty history in the file means we should send example code. Otherwise, send whatever we've got.
                if (headlessFirepad.isHistoryEmpty()) {
                  resolve({
                    path: file.path,
                    contents: lang.files!.find((f) => f.path === file.path)!.example,
                  })
                } else {
                  headlessFirepad.getText().then((text) => {
                    resolve({
                      path: file.path,
                      contents: text.trim(),
                    })
                  })
                }
              })
            })
          }
        })

        files = yield Promise.all(fileContentsPromises)
        code = files.find((f) => f.path === '/home/coderpad/index.html')!.contents
      } else {
        code = editor!.getValue()
      }

      if (!action.meta?.isEnvironment && !code!.trim()) {
        yield put({
          type: 'console_output_produced',
          output: '\r\nPlease enter some code to compile, first.\r\n',
        })
        return
      }

      const { environmentId, customDatabaseLanguage } = yield select(selectExecEnv)

      const {
        userId,
        userInfo,
        uncommittedUsername: username,
      }: DefaultRootState['userState'] = yield select((state: DefaultRootState) => state.userState)
      let msg = ''
      if (environmentRunCommand) {
        msg = `${username} ran target \`${environmentRunCommand}\`:`
      } else if (action.meta?.isEnvironment && action.payload?.files?.length) {
        const codeLen = (code || '').split('\n').length
        const lineStr =
          action.payload?.files?.length > 1
            ? 'several lines'
            : `${codeLen} line${codeLen === 1 ? '' : 's'}`
        msg = `${username} ran ${lineStr} of ${lang?.display ?? 'code'}:`
      } else {
        const lineCount = (code || '').split('\n').length
        const lineStr = lineCount >= 0 ? `${lineCount}` : 'a few'
        msg = `${username} ran ${lineStr} of ${lang?.display ?? 'code'}:`
      }
      const color = userInfo[userId]?.color ?? window.CoderPad.COLORS[0]
      const jwt = yield call(fetchAuthToken, padConfig.slug, {
        environmentSlug: environmentSlug,
        customDatabaseLanguage: customDatabaseLanguage,
      })
      const resultPromise = new Promise((resolve, reject) => {
        socket.emit(
          'execute',
          {
            code,
            color,
            files,
            msg,
            language: projectTemplateSlug ? null : lang.name,
            project: projectTemplateSlug,
            firebaseShard: padConfig.firebaseShard,
            projectTemplateVersion,
            environmentSlug,
            padEnded: padConfig.padState === 'ended',
            token: jwt,
            runCommand: environmentRunCommand,
          },
          resolve
        )
      })
      const result = yield resultPromise

      const syncHandle = SyncHandle()
      if (result) {
        const consolePath = padConfig.hasEnvironments ? `console/${environmentId}` : 'console'
        syncHandle.push(consolePath, {
          color,
          timestamp: syncHandle.TIMESTAMP_SENTINEL,
          text: msg,
          type: 'execution',
          authorId: userId,
        })
        syncHandle.push(consolePath, {
          color,
          timestamp: syncHandle.TIMESTAMP_SENTINEL,
          text: result,
          type: 'output',
          authorId: userId,
        })
      }

      logEvent('ran', { username, metadata: lang?.name ?? projectTemplateSlug ?? null })
      yield put({ type: 'local_user_ran_code' })
    }
  )

  yield takeLatest('saveFiles', function* () {
    socket.emit('saveFiles')
  })

  yield takeLatest('project/restartMainProcess', function* () {
    socket.emit('project/restartMainProcess')
  })

  yield takeEvery('project/catchupTerm', function* (action: { type: string; id: string }) {
    if (action?.id) {
      socket.emit('project/catchupTerm', { id: action.id })
    }
  })

  yield takeEvery(socketChannel, function* ([type, data]) {
    if (yield shouldBypass()) {
      return
    }

    if (type === 'reset') {
      yield put({ type: 'output_reset' })
    } else if (type === 'started') {
      const { id, msg, color } = data
      yield put({ type: 'execution_started', id, msg, color })
    } else if (type === 'finished') {
      const { id, elapsed } = data
      yield put({ type: 'execution_finished', id, elapsed })
    } else if (type === 'stopped') {
      yield put({ type: 'execution_stopped' })
    } else if (type === 'serviceStatus') {
      yield put({ type: 'service_status', status: data })
    } else if (type === 'platformError') {
      yield put({ type: 'execution_platform_errored', msg: data.msg })
      if (data.reason === PLATFORM_ERROR_REASON.Unknown) {
        const execEnv: ExecEnv = yield select(selectExecEnv)
        const questionId: number = yield select(selectQuestionId)
        trackEvent('Pad Execution PlatformError Unknown', {
          browser: padConfig.browser,
          language: execEnv.language,
          projectTemplateSlug: execEnv.projectTemplateSlug,
          projectTemplateVersion: execEnv.projectTemplateVersion,
          questionId: questionId,
          customDatabaseId: execEnv.customDatabaseId,
          customFiles: execEnv.customFiles,
        })
      }
    } else if (type === 'output') {
      yield put({ type: 'console_output_produced', output: data })
    } else if (type === 'termOutput') {
      yield put({ type: 'term_output_produced', ...data })
    } else if (type === 'connection_established') {
      yield put({ type: 'execution_connected' })
    } else if (type === 'reconnect') {
      yield put({ type: 'execution_connected' })
    } else if (type === 'reconnecting') {
      yield put({ type: 'execution_connecting' })
    } else if (type === 'connection_failed') {
      // For debugging when somebody experiences a connection issue to execute
      console.log('error', `Failed connection: ${data}`)

      // in execute we cause the "io server disconnect" reason exclusively for
      // max connections on a single pad. if we force disconnect for new reasons,
      // we need to handle them separately.
      if (data === 'io server disconnect') {
        yield put({ type: 'execution_connection_failed_max_clients' })
      } else {
        yield put({ type: 'execution_connection_failed' })
        const execEnv: ExecEnv = yield select(selectExecEnv)
        const questionId: number = yield select(selectQuestionId)
        trackEvent('Pad Execution Connection Failed', {
          browser: padConfig.browser,
          language: execEnv.language,
          projectTemplateSlug: execEnv.projectTemplateSlug,
          projectTemplateVersion: execEnv.projectTemplateVersion,
          questionId: questionId,
          customDatabaseId: execEnv.customDatabaseId,
          customFiles: execEnv.customFiles,
        })
      }
    } else if (type === 'htmlResult') {
      const { result } = data
      if (result.length > 0)
        yield put({ type: 'html_processed', content: result, time: Date.now() })
    }
  })
}

// If execution is disabled, or test cases are present, then bypass action
// handlers.
function* shouldBypass() {
  return !((yield select(selectExecEnabled)) as boolean) || (yield select(selectTestCasesEnabled))
}

export function* updateSocketEnv(
  socket: Socket,
  execSettings: ExecEnv & { project?: string; firebaseShard?: string }
) {
  const settings: ExecEnv & { firebaseShard?: string } = {
    ...execSettings,
    firebaseShard: padConfig.firebaseShard,
  }

  const jwt: string = yield call(fetchAuthToken, padConfig.slug, {
    environmentSlug: settings.environmentSlug,
    customDatabaseLanguage: settings.customDatabaseLanguage,
  })
  const signedSettings = { ...settings, token: jwt }
  socket.emit('env', signedSettings)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(socket.io.opts.query as any).initialExecSettings = JSON.stringify(signedSettings)
}
