import { Button } from '@mui/material'
import React from 'react'

import { MonacoFile } from '../../../utils/types'
import { FileMessageQueueItem } from '../FileMessageDialog'

export interface FileExistsDialogProps {
  fileName: string
  fileToDelete?: MonacoFile
  existingFile: MonacoFile
  newFileContents: string
  onSkip: () => void
  onReplace: () => void
  onKeepBoth: () => void
}

export const FileExistsDialog = ({
  fileName,
  existingFile,
  newFileContents,
  fileToDelete,
  onSkip,
  onReplace,
  onKeepBoth,
}: FileExistsDialogProps): FileMessageQueueItem => ({
  data: { newFileContents, existingFile, fileToDelete },
  title: 'File already exists',
  message: (
    <>
      A file with the name <b>{fileName}</b> already exists in this folder. Do you want to replace
      it?
    </>
  ),
  actions: [
    <Button key={1} color="inherit" onClick={onSkip}>
      Skip
    </Button>,
    <Button key={2} onClick={onReplace} color="warning">
      Replace
    </Button>,
    <Button key={3} onClick={onKeepBoth} color="primary" variant="contained">
      Keep both
    </Button>,
  ],
})
