import classNames from 'classnames'
import CodeMirror from 'codemirror'
import DOMPurify from 'dompurify'
import Katex from 'katex'
import { marked } from 'marked'
import extendedLatex from 'marked-extended-latex'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// https://github.github.com/gfm/#disallowed-raw-html-extension-
const FORBID_TAGS = [
  'title',
  'textarea',
  'style',
  'xmp',
  'iframe',
  'noembed',
  'noframes',
  'script',
  'plaintext',
]

marked.setOptions({
  tables: true,
  smartypants: true,
  headerPrefix: 'markdown-',
  highlight(code, lang) {
    const dummy = document.createElement('div')
    CodeMirror.runMode(code, lang, dummy)
    return dummy.innerHTML
  },
})
marked.use(
  extendedLatex({
    render: (formula, displayMode) =>
      Katex.renderToString(formula, {
        displayMode,
        output: 'html',
        throwOnError: false,
      }),
  })
)
marked.Renderer.prototype.table = (header, body) => `<table class="table">
    <thead>${header}</thead>
    <tbody>${body}</tbody>
  </table>`

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noreferrer noopener nofollow')
  }
})

export class MarkdownOutput extends React.Component {
  static propTypes = {
    darkColorScheme: PropTypes.bool,
    hidden: PropTypes.bool.isRequired,
    value: PropTypes.string,
  }
  render() {
    if (this.props.hidden) return null

    const dirtyHTML = marked(this.props.value || '')
    const cleanHTML = DOMPurify.sanitize(dirtyHTML, { FORBID_TAGS })

    return (
      <div
        className={classNames({
          MarkdownOutput: true,
          MarkdownOutput_dark: this.props.darkColorScheme,
          MarkdownOutput_light: !this.props.darkColorScheme,
          'cm-s-monokai': this.props.darkColorScheme,
          'cm-s-one-light': !this.props.darkColorScheme,
        })}
        dangerouslySetInnerHTML={{ __html: cleanHTML }}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    darkColorScheme: state?.editorSettings?.darkColorScheme || false,
    // Can be overridden for use in playback:
    value: ownProps.value || state.execution.markdown,
  }
}
const ConnectedMarkdownOutput = connect(mapStateToProps)(MarkdownOutput)
export default ConnectedMarkdownOutput
