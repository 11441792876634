import { AlertTitle, Theme } from '@mui/material'
import { Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'

import { INotification } from '../reducers/notifications'

interface INotificationProps {
  /** Notification to display. */
  notification: INotification
  /** Callback invoked when user dismisses the notification, or when the auto-dismiss timeout expires. */
  requestDismiss: () => void
}

const useAlertStyles = makeStyles<Theme, { isClickable: boolean }>({
  root: {
    fontSize: '0.875rem',
    width: 360,
    marginBottom: 12,
    cursor: ({ isClickable }) => (isClickable ? 'pointer' : 'auto'),
  },
})

/**
 * Notification alert component. Displays the message text. If an auto-dismiss duration is specified, a timeout is
 * used to automatically dismiss this notification.
 */
export const Notification: React.FC<INotificationProps> = ({ notification, requestDismiss }) => {
  const { message, autoDismissMs, variant, onClick, title } = notification
  const styles = useAlertStyles({ isClickable: !!onClick })

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (autoDismissMs) {
      timeout = setTimeout(requestDismiss, autoDismissMs)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [notification])

  return (
    <Alert severity={variant} onClose={requestDismiss} classes={styles} onClick={onClick}>
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {message}
    </Alert>
  )
}
