import { Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isLanguageAutocompleted, useWindow } from '../../../utils'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { PadSlidingPanel, PadSlidingPanelProps } from '../components/PadSlidingPanel'
import { PadTranslation } from '../constants'
import { editorSettingChanged } from '../reducers/editor_settings'
import { padSettingChanged } from '../reducers/pad_settings'
import { selectEditorSettings, selectPadSettings } from '../selectors'
import { PadSettingsAction } from './PadSettingsAction'
import { PadSettingsLink } from './PadSettingsLink'
import { PadSettingsSelect } from './PadSettingsSelect'
import { PadSettingsShortcuts } from './PadSettingsShortcuts'
import { PadSettingsToggle } from './PadSettingsToggle'

type PadSettingKey = 'execEnabled' | 'isPublic' | 'takeHomeTimeLimit'

export type PadSettingsProps = Omit<PadSlidingPanelProps, 'children'>

const fontSizeOptions = [12, 13, 14, 15, 16, 17, 18]
const tabSpacingFallback = 2
const tabSpacingOptions = [2, 4, 8]

export const PadSettings = memo<PadSettingsProps>((props) => {
  const { isOpen, onClose, ...rest } = props
  const dispatch = useDispatch()
  const {
    disablePadSettings,
    intellisenseDisabledByOrganization,
    isDemoPad,
    isLoggedIn,
    isOwner,
    isPlayback,
    slug,
    uiType,
  } = usePadConfigValues(
    'disablePadSettings',
    'intellisenseDisabledByOrganization',
    'isDemoPad',
    'isLoggedIn',
    'isOwner',
    'isPlayback',
    'slug',
    'uiType'
  )
  const {
    autoCloseBrackets,
    autocomplete,
    darkColorScheme,
    fontSize,
    keymap,
    tabSizes,
  } = useSelector(selectEditorSettings)
  const { execEnabled, isPublic, language, projectTemplateSlug, takeHome } = useSelector(
    selectPadSettings
  )
  const [isShortcutsOpen, setIsShortcutsOpen] = React.useState(false)
  const window = useWindow()

  const handleEditorSettingChange = useCallback(
    (key: string) => (value: unknown) => {
      dispatch(editorSettingChanged(key, value))
    },
    [dispatch]
  )

  const handlePadSettingChange = useCallback(
    (key: PadSettingKey) => (value: unknown) => {
      dispatch(padSettingChanged(key, value))
    },
    [dispatch]
  )

  const handleShortcutsClose = useCallback(() => {
    setIsShortcutsOpen(false)
  }, [])

  const handleViewShortcutsClick = useCallback(() => {
    setIsShortcutsOpen(true)
  }, [])

  const intellisenseSupportedLanguages = useMemo(
    () =>
      Object.keys(window.CoderPad.LANGUAGES)
        .flatMap((language) =>
          isLanguageAutocompleted(language) ? [window.CoderPad.LANGUAGES[language].display] : []
        )
        .sort(),
    [window]
  )

  const isDrawingOnlyPad = uiType === 'drawing_only'

  const isCodeExecutionToggleEnabled = useMemo(
    () => !isDrawingOnlyPad && !projectTemplateSlug && !takeHome,
    [isDrawingOnlyPad, projectTemplateSlug, takeHome]
  )

  const isPlaybackHistoryEnabled = useMemo(() => !isPlayback && !isDrawingOnlyPad && isOwner, [
    isDrawingOnlyPad,
    isOwner,
    isPlayback,
  ])

  const isWaitingRoomToggleEnabled = useMemo(() => isOwner && !isDemoPad && !takeHome, [
    isDemoPad,
    isOwner,
    takeHome,
  ])

  const tabSpacingId = language ?? projectTemplateSlug

  const tabSpacingValue = useMemo(() => tabSizes?.[tabSpacingId] || tabSpacingFallback, [
    tabSizes,
    tabSpacingId,
  ])

  useEffect(() => {
    if (!isOpen && isShortcutsOpen) {
      setIsShortcutsOpen(false)
    }
  }, [isOpen, isShortcutsOpen])

  return (
    <PadSlidingPanel isOpen={isOpen} onClose={onClose} {...rest}>
      <Box>
        <Stack divider={<Divider />} spacing={3}>
          <Stack spacing={4}>
            <Typography
              color="inherit"
              component="h1"
              sx={{ alignSelf: 'center', fontSize: '1.125rem', fontWeight: 500 }}
            >
              {PadTranslation.padSettingsEditorSettingsTitle}
            </Typography>
            <Box sx={{ paddingX: 4 }}>
              <Stack spacing={3}>
                <PadSettingsToggle
                  label={PadTranslation.padSettingsEditorSettingsThemeLabel}
                  onValueChange={handleEditorSettingChange('darkColorScheme')}
                  options={[
                    {
                      label: PadTranslation.padSettingsEditorSettingsThemeOptionLabelOn,
                      value: true,
                    },
                    {
                      label: PadTranslation.padSettingsEditorSettingsThemeOptionLabelOff,
                      value: false,
                    },
                  ]}
                  value={darkColorScheme}
                />
                {!isDrawingOnlyPad && (
                  <>
                    <PadSettingsToggle
                      label={PadTranslation.padSettingsEditorSettingsKeyBindingsLabel}
                      onValueChange={handleEditorSettingChange('keymap')}
                      options={[
                        {
                          label:
                            PadTranslation.padSettingsEditorSettingsKeyBindingsOptionLabelStandard,
                          value: 'sublime',
                        },
                        {
                          label: PadTranslation.padSettingsEditorSettingsKeyBindingsOptionLabelVim,
                          value: 'vim',
                        },
                        {
                          label:
                            PadTranslation.padSettingsEditorSettingsKeyBindingsOptionLabelEmacs,
                          value: 'emacs',
                        },
                      ]}
                      value={keymap}
                    />
                    <PadSettingsSelect
                      label={PadTranslation.padSettingsEditorSettingsFontSizeLabel}
                      onValueChange={handleEditorSettingChange('fontSize')}
                      options={fontSizeOptions.map((value) => ({
                        label: PadTranslation.padSettingsEditorSettingsFontSizeOptionLabel(value),
                        value,
                      }))}
                      value={fontSize}
                    />
                    <PadSettingsSelect
                      label={PadTranslation.padSettingsEditorSettingsTabSpacingLabel}
                      onValueChange={handleEditorSettingChange(`tabSizes.${tabSpacingId}`)}
                      options={tabSpacingOptions.map((value) => ({
                        label: `${value}`,
                        value,
                      }))}
                      value={tabSpacingValue}
                    />
                    {!intellisenseDisabledByOrganization && (
                      <PadSettingsToggle
                        infoTooltip={PadTranslation.padSettingsEditorSettingsIntellisenseTooltip(
                          intellisenseSupportedLanguages
                        )}
                        label={PadTranslation.padSettingsEditorSettingsIntellisenseLabel}
                        onValueChange={handleEditorSettingChange('autocomplete')}
                        options={[
                          {
                            label:
                              PadTranslation.padSettingsEditorSettingsIntellisenseOptionLabelOn,
                            value: true,
                          },
                          {
                            label:
                              PadTranslation.padSettingsEditorSettingsIntellisenseOptionLabelOff,
                            value: false,
                          },
                        ]}
                        value={autocomplete}
                      />
                    )}
                    <PadSettingsToggle
                      label={PadTranslation.padSettingsEditorSettingsAutoCloseBracketsLabel}
                      onValueChange={handleEditorSettingChange('autoCloseBrackets')}
                      options={[
                        {
                          label:
                            PadTranslation.padSettingsEditorSettingsAutoCloseBracketsOptionLabelOn,
                          value: true,
                        },
                        {
                          label:
                            PadTranslation.padSettingsEditorSettingsAutoCloseBracketsOptionLabelOff,
                          value: false,
                        },
                      ]}
                      value={autoCloseBrackets}
                    />
                    <PadSettingsAction
                      buttonLabel={PadTranslation.padSettingsEditorSettingsShortcutsButtonLabel}
                      label={PadTranslation.padSettingsEditorSettingsShortcutsLabel}
                      onClick={handleViewShortcutsClick}
                    />
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
          {!disablePadSettings && (
            <Stack spacing={4}>
              <Typography
                color="inherit"
                component="h1"
                sx={{ alignSelf: 'center', fontSize: '1.125rem', fontWeight: 500 }}
              >
                {PadTranslation.padSettingsPadSettingsTitle}
              </Typography>
              <Box sx={{ paddingX: 4 }}>
                <Stack spacing={3}>
                  {isCodeExecutionToggleEnabled && (
                    <PadSettingsToggle
                      label={PadTranslation.padSettingsPadSettingsCodeExecutionLabel}
                      onValueChange={handlePadSettingChange('execEnabled')}
                      options={[
                        {
                          label: PadTranslation.padSettingsPadSettingsCodeExecutionOptionLabelOn,
                          value: true,
                        },
                        {
                          label: PadTranslation.padSettingsPadSettingsCodeExecutionOptionLabelOff,
                          value: false,
                        },
                      ]}
                      value={execEnabled}
                    />
                  )}
                  {isWaitingRoomToggleEnabled && (
                    <PadSettingsToggle
                      label={PadTranslation.padSettingsPadSettingsWaitingRoomLabel}
                      onValueChange={handlePadSettingChange('isPublic')}
                      options={[
                        {
                          label: PadTranslation.padSettingsPadSettingsWaitingRoomOptionLabelOn,
                          value: false,
                        },
                        {
                          label: PadTranslation.padSettingsPadSettingsWaitingRoomOptionLabelOff,
                          value: true,
                        },
                      ]}
                      value={isPublic}
                    />
                  )}
                  {isPlaybackHistoryEnabled && (
                    <PadSettingsLink
                      buttonLabel={PadTranslation.padSettingsPadSettingsPlaybackHistoryButtonLabel}
                      href={`/${slug}/playback`}
                      label={PadTranslation.padSettingsPadSettingsPlaybackHistoryLabel}
                    />
                  )}
                  {isLoggedIn && (
                    <PadSettingsLink
                      buttonLabel={PadTranslation.padSettingsPadSettingsAccountSettingsButtonLabel}
                      href={'/dashboard/settings'}
                      label={PadTranslation.padSettingsPadSettingsAccountSettingsLabel}
                    />
                  )}
                </Stack>
              </Box>
            </Stack>
          )}
        </Stack>
        <PadSettingsShortcuts
          isOpen={isShortcutsOpen}
          onSettingsClose={onClose}
          onShortcutsClose={handleShortcutsClose}
        />
      </Box>
    </PadSlidingPanel>
  )
})
