import { Frame } from 'packs/main/playback/types'
import { TranscriptEntry, TranscriptEntryKind } from 'packs/main/Transcriber/types'

export const getTranscriptFrames = (transcriptHistory: TranscriptEntry[]): Frame[] => {
  return transcriptHistory.map((m) => {
    const isTranscript = m.kind === TranscriptEntryKind.Transcript
    return {
      type: 'transcript',
      value: isTranscript ? m.transcripts[m.preferredTranscript] : m.message,
      authorId: isTranscript ? m.userId : 'system',
      timestamp: m.timestamp,
      delay: 900,
      peripheralFrames: [] as Frame[],
    }
  })
}
