import CodeMirror from 'codemirror'
import _ from 'lodash'

import { isMobile } from '../environment/environment'

export default function initCodeMirror() {
  _.extend(CodeMirror.defaults, {
    theme: 'monokai',
    keymap: 'sublime',
    matchBrackets: true,
    lineNumbers: true,
    lineWrapping: true,
    autofocus: true,
    styleActiveLine: !isMobile(),
    indentWithTabs: false,
    extraKeys: {
      'Cmd-Enter': false,
      'Ctrl-Enter': false,
      Tab(cm: CodeMirror.Editor) {
        if (cm.somethingSelected()) {
          cm.indentSelection('add')
        } else {
          const cursor = cm.getCursor()
          const toStartOfLine = cm.getRange({ line: cursor.line, ch: 0 }, cursor)
          const column = CodeMirror.countColumn(toStartOfLine, null, cm.getOption('tabSize')!)
          const size = cm.getOption('indentUnit')! - (column % cm.getOption('indentUnit')!)
          cm.replaceSelection(Array(size + 1).join(' '))
        }
      },
    },
  })

  const beforeChangeHandler = function (
    instance: CodeMirror.Editor,
    changeObj: CodeMirror.EditorChangeCancellable
  ) {
    if (changeObj.update) {
      let changed = false
      const text = changeObj.text.map(function (str: string) {
        let ret = str.replace(/[\u00A0]/g, ' ')
        ret = ret.replace(/[\t]/g, Array(instance.getOption('indentUnit')! + 1).join(' '))
        if (ret != str) {
          changed = true
        }
        return ret
      })

      if (changed) {
        return changeObj.update(changeObj.from, changeObj.to, text)
      }
    }
  }

  CodeMirror.defineInitHook((cm: CodeMirror.Editor) => cm.on('beforeChange', beforeChangeHandler))

  // @ts-ignore resolveMode
  const oldResolveMode = CodeMirror.resolveMode
  // @ts-ignore resolveMode
  CodeMirror.resolveMode = function (spec) {
    let config = oldResolveMode(spec)
    if (_.isEqual(config, { name: spec }) && window.CoderPad.LANGUAGES[spec]) {
      config = oldResolveMode(window.CoderPad.LANGUAGES[spec].mode)
    }
    return config
  }
  window.CodeMirror = window.CodeMirror || CodeMirror
}
