import { registerEditorOpenHandler } from '@codingame/monaco-editor-wrapper'
import { useEffect } from 'react'

import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useMonacoContext } from './MonacoContext'

// On Ctrl/Cmd click in monaco, we can open a file (.h, .d.ts etc.)
// This hook registers a handler to open properly a file that already exist in firebase,
// otherwise it will open it in a popup (default monaco behavior)
export function useMonacoOpenHandler() {
  const { environment, files, activateFile } = useActiveEnvironment()
  const { getEditor } = useMonacoContext()

  useEffect(() => {
    if (environment?.projectTemplateSlug != null) {
      const disposable = registerEditorOpenHandler(async (model, input, editor, sideBySide) => {
        const file = files.find((file) => file.monacoPath === model.uri.path)

        if (file != null) {
          /*
            We need to wait for model to be attached to the editor (trigger by activateFile) before
            returning the editor so that monaco can properly set the cursor if necessary.
          */
          activateFile(file.id)
          return new Promise((resolve) => {
            const attachedChangedDisposable = model.onDidChangeAttached(() => {
              attachedChangedDisposable.dispose()
              resolve(getEditor())
            })
          })
        } else {
          return null
        }
      })
      return () => {
        disposable.dispose()
      }
    } else {
      return
    }
  }, [activateFile, environment?.projectTemplateSlug, files, getEditor])
}
