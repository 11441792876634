import TwilioVideo from 'twilio-video'

export default function supportsCalling() {
  if (TwilioVideo.isSupported) {
    return true
  } else {
    // check if its a chrome based browser like brave
    return navigator.userAgent.indexOf("Chrome") !== -1
  }
}
