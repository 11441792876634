// Goes inside the QuestionLibrary as the left list

import * as Sentry from '@sentry/browser'
import searchFuzzy from 'approx-string-match'
import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { createFetcher } from 'utils/fetch/fetch'

import { SearchInput } from '../dashboard/components/SearchInput/SearchInput'
import highlightText from './highlight_text'

const BASE_STATE = {
  query: '',
}

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetch = createFetcher(token)

export default class ExampleQuestionList extends React.PureComponent {
  static propTypes = {
    allowPadCreation: PropTypes.bool.isRequired,
    initiallySelectedQuestionId: PropTypes.string,
    languagesUsed: PropTypes.arrayOf(PropTypes.string),
    // Null/undefined if not in a pad.
    language: PropTypes.string,
    onSelectQuestion: PropTypes.func,
    takeHome: PropTypes.bool,
    exampleQuestionsExist: PropTypes.bool,
    hidden: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      ...BASE_STATE,
      records: [],
      isLoading: this.props.exampleQuestionsExist,
    }
    this.queryId = 0
    this.lastReceivedQueryId = -1
    this.onQueryChange = this.onQueryChange.bind(this)
    this._questionsListRef = React.createRef()
    this.variationsCache = {}
  }

  componentDidMount() {
    this.runQuery({
      requestSearchFn: this.requestExampleQuestions.bind(this),
      callback: (records) => {
        this.setState({
          records,
          allRecords: records,
        })

        if (this.props.initiallySelectedQuestionId) {
          // If id is in the format examples/slug/variation then we have to find the variation.
          const match = /(examples\/[\w-]+)(?:\/([\w-]+))?/.exec(
            this.props.initiallySelectedQuestionId
          )
          const [_full, exampleIdBase, selectedQuestionVariation] = match
          const selectedQuestion = _.find(records, ['id', exampleIdBase])
          if (selectedQuestion) {
            this.loadAndSelectQuestion(selectedQuestion, selectedQuestionVariation, true)
          } else {
            const msg = 'ExampleQuestionList could not find the question id in the record list.'
            console.error(msg)
            Sentry.captureMessage(msg, {
              id: this.props.initiallySelectedQuestionId,
            })
          }
        }
      },
    })
  }

  loadAndSelectQuestion = (question, variationName, scrollIntoView) => {
    const callback = (variations) => {
      this.props.onSelectQuestion(question, variations, variationName)
      if (scrollIntoView) {
        setTimeout(() => {
          const questionsList = this._questionsListRef.current
          const questionEl = questionsList.querySelector(`li[data-example-id="${question.id}"]`)
          if (questionEl) {
            questionsList.scrollTop = questionEl.offsetTop
          }
        }, 5)
      }
    }
    if (!question.has_variations || this.variationsCache[question.id]) {
      callback(this.variationsCache[question.id])
    } else {
      // Withhold question contents until all variations are loaded
      if (!variationName) {
        this.props.onSelectQuestion({ ...question, contents: null }, [])
      }
      this.runQuery({
        requestSearchFn: this.requestExampleQuestionVariations.bind(this, { id: question.id }),
        showLoading: false,
        callback: (variations) => {
          this.variationsCache[question.id] = variations
          callback(variations)
        },
      })
    }
  }

  async requestExampleQuestions() {
    const formData = new FormData()
    formData.append('pad_types', this.props.takeHome ? ['take_home', 'any'] : ['live', 'any'])

    const res = await fetch('/search/example_questions', {
      method: 'post',
      body: formData,
    })
    if (!res.ok) {
      return Sentry.captureException(
        new Error(`Search example questions failure: ${res.status} (${res.statusText})`)
      )
    }
    return res.json()
  }

  async requestExampleQuestionVariations(options = {}) {
    const formData = new FormData()
    formData.append('id', options.id)
    const res = await fetch('/search/example_question_variations', {
      method: 'post',
      body: formData,
    })
    if (!res.ok) {
      return Sentry.captureException(
        new Error(`Search example questions failure: ${res.status} (${res.statusText})`)
      )
    }
    return res.json()
  }

  runQuery({ requestSearchFn, callback, showLoading = true }) {
    const currentQueryId = this.queryId++
    if (showLoading) {
      this.setState({ isLoading: true })
    }
    return requestSearchFn().then((result) => {
      if (currentQueryId < this.lastReceivedQueryId) return
      const records = _.reduce(
        result.records,
        (result, question) => {
          // Modify question to match the QuestionLibrary live / takeHome mode
          // because QuestionSelected cares about question.takeHome.
          question.take_home = !!this.props.takeHome
          result.push(question)
          return result
        },
        []
      )
      this.lastReceivedQueryId = currentQueryId
      if (showLoading) {
        this.setState({ isLoading: false })
      }
      callback(records)
    })
  }

  onQueryChange(query) {
    if (!query) {
      return this.setState({ query: '', records: this.state.allRecords })
    }

    const allowedErrors = Math.floor(query.length * 0.3)
    const queryLower = query.toLowerCase()
    const scoreRecordAttribute = (record, attribute, weight) =>
      weight *
      _.sumBy(
        searchFuzzy(record[attribute]?.toLowerCase(), queryLower, allowedErrors),
        ({ start, end, errors }) => Math.ceil(end - start - errors, 0)
      )
    // Score questions with attribute weights like QuestionIndexWorker.
    const scoreRecord = (record) =>
      scoreRecordAttribute(record, 'title', 1.0) +
      scoreRecordAttribute(record, 'description', 0.4) +
      scoreRecordAttribute(record, 'author_name', 0.2)
    const records = _(this.state.allRecords)
      .map((record) => ({ ...record, queryScore: scoreRecord(record) }))
      .filter((record) => record.queryScore > 0)
      .orderBy((record) => record.queryScore, 'desc')
      .value()

    this.setState({ query, records })
  }

  renderQuestionListSection(headerText, questions) {
    return [
      <li className="header" key={headerText}>
        {headerText}
      </li>,
      ...questions.map((question) => {
        return (
          <ExampleQuestionListItem
            key={question.example_question_id}
            query={this.state.query}
            question={question}
            onSelect={this.loadAndSelectQuestion}
            isSelected={
              this.props.selectedQuestion && this.props.selectedQuestion.id === question.id
            }
          />
        )
      }),
    ]
  }

  render() {
    return (
      <div className={classNames({ hidden: this.props.hidden })}>
        <div id="questions-search">
          <SearchInput
            className="QuestionList-SearchQuery"
            debounce={250}
            onValueChange={this.onQueryChange}
            placeholder="Search examples…"
            variant="outlined"
          />
        </div>

        <ul
          id="questions-list"
          ref={this._questionsListRef}
          style={{
            top: '77px',
          }}
        >
          {this.state.isLoading ? (
            <li key="loading">
              <p>Now loading…</p>
            </li>
          ) : this.state.records.length > 0 ? (
            this.renderQuestionListSection('Examples', this.state.records)
          ) : (
            <li key="noResults">
              <p>Sorry, your search returned no results.</p>
              <p>Queries match against question titles, description and authors.</p>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

function ExampleQuestionListItem({ question, query, isSelected, onSelect }) {
  return (
    <li
      className={`ExampleQuestionListItem result ${isSelected && 'selected'}`}
      data-example-id={question.id}
      onClick={() => onSelect(question)}
    >
      <h4 dangerouslySetInnerHTML={{ __html: highlightText(question.title, query) }} />

      {question.description && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: highlightText(question.description, query) }}
        />
      )}
    </li>
  )
}
