import { VideoCamera } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LockIcon from '../../../../assets/images/Locked.svg'
import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'
import { FooterButton } from '../FooterButton'
import { selectCallStatus, selectIsSandboxShowcase } from '../selectors'
import supportsCalling from './supports_calling'

export function CallButton() {
  const callStatus = useSelector(selectCallStatus)
  const isSandboxShowcase = useSelector(selectIsSandboxShowcase)
  const callingEnabled = usePadConfigValue('callingEnabled')
  const dispatch = useDispatch()

  const toolTipText = useMemo(() => {
    if (isSandboxShowcase) {
      return (
        <Typography textAlign="center">
          <div>
            <div className="SandboxTips-content">
              <p>
                <b>Start Call</b>
              </p>
              Start a video call with the other participants in the pad.
            </div>
            <div className="SandboxTips-lock">
              <img src={LockIcon} className="LockIcon" alt="Lock Icon" />
              <i>Available with free account</i>
            </div>
          </div>
        </Typography>
      )
    } else {
      return (
        <Box textAlign="center">Start a video call with the other participants in the pad.</Box>
      )
    }
  }, [isSandboxShowcase])

  const onClick = () => {
    dispatch({
      type: 'intent_to_start_call_expressed',
      _analytics: {
        name: 'Call Setup',
        params: {
          from_invite: false,
          from_reconnect: false,
        },
      },
    })
  }

  if (!callingEnabled) return null
  if (callStatus !== 'no_call') return null

  if (!supportsCalling()) {
    return (
      <Tooltip
        placement="top"
        arrow
        title="Your browser doesn't support calling on CoderPad. We recommend latest versions of Firefox, Chrome, or Safari."
      >
        <span>
          <Button size="small" disabled variant="contained">
            Calling Unsupported
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <Tooltip placement="top" arrow title={toolTipText}>
        <span>
          <FooterButton
            onClick={isSandboxShowcase ? () => {} : onClick}
            startIcon={<VideoCamera sx={{ width: 20, height: 20 }} />}
          >
            <Box sx={{ display: { xs: 'none', lg: 'initial' } }}>Start Call</Box>
          </FooterButton>
        </span>
      </Tooltip>
    </>
  )
}
