import SyncHandle from 'packs/main/sync_handle'
import { useEffect } from 'react'

import { GlobalEvent, GlobalEventTypes } from './GlobalEventTypes'

/**
 * The time in milliseconds after which a global event is considered stale.
 * Stale events are not considered when looking for the latest event.
 */
const STALE_THRESHOLD = 1000 * 5

/**
 * An alternative to useGlobalEvents that is safe to use outside of playback.
 * This hook provides a subscription based API to listen to specific global
 * events in real time, as opposed to the declarative API provided by
 * useGlobalEvents.
 */
export function useRealtimeGlobalEvents(
  eventType: GlobalEventTypes,
  cb: (event: GlobalEvent) => void
) {
  useEffect(() => {
    const watcher = SyncHandle().watchChildrenAdditions('/globalEvents', (event: GlobalEvent) => {
      if (event.type === eventType && Date.now() - event.time < STALE_THRESHOLD) {
        cb(event)
      }
    })

    return () => {
      SyncHandle().off('/globalEvents', watcher)
    }
  }, [eventType, cb])
}
