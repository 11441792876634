import { Button } from '@mui/material'
import React from 'react'
import { FileWithPath } from 'react-dropzone'

import { FileMessageQueueItem } from '../FileMessageDialog'

export interface UnsupportedFileTypeDialogProps {
  file: FileWithPath
  onOk: () => void
}

export const UnsupportedFileTypeDialog = ({
  file,
  onOk,
}: UnsupportedFileTypeDialogProps): FileMessageQueueItem => ({
  title: 'Unsupported file type',
  message: (
    <>
      The file <b>{file.name}</b> is not a text file and cannot be uploaded.
    </>
  ),
  actions: [
    <Button key={1} color="secondary" onClick={onOk} variant="outlined">
      OK
    </Button>,
  ],
})
