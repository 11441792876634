import { Box, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import LockIcon from '../../../assets/images/Locked.svg'
import padConfig from './pad_config'
import { selectIsSandboxShowcaseInterviewer } from './selectors'

class _TitlePrompt extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    isSandboxShowcaseInterviewer: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = { willSetToDefaultTitleWhenBlurred: false }
  }

  get _defaultTitle() {
    return `Untitled Pad - ${padConfig.slug}`
  }

  componentDidMount() {
    $(this._el).inputAutogrow({ minWidth: 20, trailingSpace: 10 })
  }

  componentWillUnmount() {
    $(this._el).tooltip('destroy')
  }

  _getTooltipText = () => {
    if (this.props.isSandboxShowcaseInterviewer) {
      return (
        <div>
          <div className="SandboxTips-content">
            <p>
              <b>Pad Title</b>
            </p>
            Name your pads for easy reference. A best practice includes identifying the candidate,
            interviewer and role.
          </div>
          <div className="SandboxTips-lock">
            <img src={LockIcon} className="LockIcon" alt="Lock Icon" />
            <i>Available with free account</i>
          </div>
        </div>
      )
    } else {
      return "Edit this pad's (private) title"
    }
  }

  handleChange = (evt) => {
    let title = evt.target.value
    if (!title) {
      // If title is blanked out, reset to default title BUT keep the text input
      // appearing to be blank as long as it's focused, so you can type a new
      // title without being suddenly interrupted.
      title = this._defaultTitle
      this.setState({ willSetToDefaultTitleWhenBlurred: true })
    } else if (this.state.willSetToDefaultTitleWhenBlurred) {
      this.setState({ willSetToDefaultTitleWhenBlurred: false })
    }
    $(this._el).trigger('autogrow')
    this.props.onChange(title)
  }

  handleBlur = () => {
    if (this.state.willSetToDefaultTitleWhenBlurred)
      this.setState({ willSetToDefaultTitleWhenBlurred: false }, () => {
        $(this._el).trigger('autogrow')
      })
  }

  render() {
    let displayedValue = this.props.value
    if (displayedValue === this._defaultTitle && this.state.willSetToDefaultTitleWhenBlurred)
      displayedValue = ''
    return (
      <Tooltip placement="top" arrow title={<Box textAlign="center">{this._getTooltipText()}</Box>}>
        <input
          autoFocus={window.openedFromFork}
          className="TitlePrompt"
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          ref={(el) => (this._el = el)}
          value={displayedValue}
          style={{ maxWidth: 200 }}
          data-testid="pad-title-prompt"
        />
      </Tooltip>
    )
  }
}

function mapStateToProps(state) {
  return {
    value: state.padSettings.title,
    isSandboxShowcaseInterviewer: selectIsSandboxShowcaseInterviewer(state),
  }
}

const mapDispatchToProps = {
  onChange: (value) => ({
    type: 'pad_setting_changed',
    key: 'title',
    value,
  }),
}

const TitlePrompt = connect(mapStateToProps, mapDispatchToProps)(_TitlePrompt)

export default TitlePrompt
