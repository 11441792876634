import { Reducer } from 'redux'

export interface CpadConfiguration {
  targets: {
    [key: string]: {
      label?: string
      command: string
    }
  }
}
export interface ProjectState {
  autoSave: boolean
  saving: boolean
  runTargets: CpadConfiguration['targets']
}

const initialState: ProjectState = {
  autoSave: true,
  saving: false,
  runTargets: {},
}

type Action =
  | {
      type: 'project/updateAutoSave'
      value: boolean
    }
  | {
      type: 'project/updated'
      value: ProjectState
    }
  | {
      type: 'project/updateCpadConfiguration'
      value: CpadConfiguration
    }

export const projectReducer: Reducer<ProjectState, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case 'project/updateAutoSave':
      return { ...state, autoSave: action.value }
    case 'project/updated':
      return { ...state, autoSave: action.value.autoSave, saving: action.value.saving }
    case 'project/updateCpadConfiguration':
      return { ...state, runTargets: action.value.targets }
    default:
      return state
  }
}
