// Runs on questions edit and edit_with_test_cases.
if ($('.QuestionFormWithTestCases.question').length > 0) {
  const maxParameterIndex = 2
  const maxTestCaseIndex = 41
  const testCasePlaceholders = {"integer":"e.g. 1","string":"e.g. foo","integer array":"e.g. 1,2,3","string array":"e.g. foo,bar"}

  // Max QuestionParams per Question are hardcoded. We make QuestionParam
  // stubs and render invisible inputs, which we reveal with JS.
  const addParameterLink = document.querySelector('.QuestionForm-addParameter')
  function hideAddParameterLink() {
    addParameterLink.classList.add('hidden')
  }
  addParameterLink.onclick = function addParameter() {
    const parameterRow = document.querySelector('.QuestionForm-parameter.hidden')
    if (!parameterRow) {
      hideAddParameterLink()
      return
    }

    parameterRow.classList.remove('hidden')
    const rowIndex = parameterRow.dataset['index']
    const deleteParameterCheck = document.querySelector(
      '.QuestionForm-parameterDeleteCheckbox[data-index="' + rowIndex + '"]'
    )
    deleteParameterCheck.checked = false

    // Show corresponding TestCase inputs.
    const testCaseArgumentInputs = document.querySelectorAll(
      '.QuestionForm-testCaseArgument[data-index="' + rowIndex + '"]'
    )
    for (let i = 0; i < testCaseArgumentInputs.length; i++) {
      testCaseArgumentInputs[i].classList.remove('hidden')
    }

    // If we revealed the final parameter, hide the add link.
    if (parseInt(rowIndex) === maxParameterIndex) {
      hideAddParameterLink()
    }
  }

  // TestCases are also stubbed and rendered with invisible inputs.
  // Show them when the user clicks the add test case link.
  const addTestCaseLink = document.querySelector('.QuestionForm-addTestCase')
  addTestCaseLink.onclick = function addTestCase() {
    const testCaseRow = document.querySelector('.QuestionForm-testCase.hidden')
    if (!testCaseRow) { return }

    const rowIndex = testCaseRow.dataset['index']
    const testCaseHideCheckbox = document.querySelector('.QuestionForm-testCaseDeleteCheckbox[data-index="' + rowIndex + '"]')
    testCaseHideCheckbox.checked = ""
    testCaseRow.classList.remove('hidden')

    if (parseInt(rowIndex) === maxTestCaseIndex) {
      addTestCaseLink.classList.add('hidden')
    }
  }

  // Deleting QuestionParameters.
  const parameterDeleteCheckboxes = document.querySelectorAll('.QuestionForm-parameterDeleteCheckbox')
  function parameterDeleteChanged() {
    const index = this.dataset.index
    const isPersisted = this.classList.contains('QuestionForm-parameterDeleteCheckbox--persisted')
    const parameterRow = document.querySelector('.QuestionForm-parameter[data-index="' + index + '"]')
    const parameterDeleteLink = document.querySelector('.QuestionForm-parameterDeleteLink[data-index="' + index + '"]')
    const parameterDeletionStaged = document.querySelector('.QuestionForm-parameterDeletionStaged[data-index="' + index + '"]')
    const testCaseArgumentInputs = document.querySelectorAll(
      '.QuestionForm-testCaseArgument[data-index="' + index + '"]'
    )

    if (this.checked) {
      // Deleting nested records in Rails works by setting _destroy=1 on
      // the child record. Show a UI hint saying please save to commit.
      if (isPersisted) {
        parameterDeleteLink.innerText = 'Undo'
        parameterDeletionStaged.classList.remove('hidden')
        parameterRow.classList.add('QuestionForm-parameter--deletionStaged')

      // Stub params can just be hidden in the DOM.
      } else {
        parameterRow.classList.add('hidden')
        addParameterLink.classList.remove('hidden')
      }

      // Hide corresponding TestCase inputs.
      for (let i = 0; i < testCaseArgumentInputs.length; i++) {
        testCaseArgumentInputs[i].classList.add('hidden')
      }

    } else {
      // Pressed "Undo" on a staged deletion. Restore link text.
      if (isPersisted) {
        parameterDeleteLink.innerText = 'Delete'
        parameterDeletionStaged.classList.add('hidden')
        parameterRow.classList.remove('QuestionForm-parameter--deletionStaged')
      }

      // Show corresponding TestCase inputs.
      for (let i = 0; i < testCaseArgumentInputs.length; i++) {
        testCaseArgumentInputs[i].classList.remove('hidden')
      }
    }
  }
  for (let i = 0; i < parameterDeleteCheckboxes.length; i++) {
    parameterDeleteCheckboxes[i].addEventListener("change", parameterDeleteChanged)
  }

  // Deleting nested records in Rails works by setting _destroy=1 on the
  // child record. When this delete check box checks, show a UI hint.
  const testCaseDeleteCheckboxes = document.querySelectorAll('.QuestionForm-testCaseDeleteCheckbox--persisted')
  function testCaseDeleteChanged() {
    const index = this.dataset.index
    const testCaseRow = document.querySelector('.QuestionForm-testCase[data-index="' + index + '"]')
    const testCaseDeleteLink = document.querySelector('.QuestionForm-testCaseDeleteLink[data-index="' + index + '"]')
    const testCaseDeletionStaged = document.querySelector('.QuestionForm-testCaseDeletionStaged[data-index="' + index + '"]')
    if (this.checked) {
      testCaseDeleteLink.innerText = 'Undo'
      testCaseDeletionStaged.classList.remove('hidden')
      testCaseRow.classList.add('QuestionForm-testCase--deletionStaged')
    } else {
      testCaseDeleteLink.innerText = 'Delete'
      testCaseDeletionStaged.classList.add('hidden')
      testCaseRow.classList.remove('QuestionForm-testCase--deletionStaged')
    }
  }
  for (let i = 0; i < testCaseDeleteCheckboxes.length; i++) {
    testCaseDeleteCheckboxes[i].addEventListener("change", testCaseDeleteChanged)
  }

  // "Delete" stub records by just hiding elements.
  const testCaseHideCheckboxes = document.querySelectorAll('.QuestionForm-testCaseDeleteCheckbox--nonPersisted')
  function testCaseHideChanged() {
    const index = this.dataset.index
    const testCaseRow = document.querySelector('.QuestionForm-testCase[data-index="' + index + '"]')
    if (this.checked) {
      testCaseRow.classList.add('hidden')
      addTestCaseLink.classList.remove('hidden')
    }
  }
  for (let i = 0; i < testCaseHideCheckboxes.length; i++) {
    testCaseHideCheckboxes[i].addEventListener("change", testCaseHideChanged)
  }

  // Return value inputs show different placeholders depending on the type.
  const returnValueDataType = document.querySelector('.QuestionForm-returnValueDataType')
  function returnValueDataTypeChanged() {
    const returnValueInputs = document.querySelectorAll('.QuestionForm-returnValueInput')
    for (let i = 0; i < returnValueInputs.length; i++) {
      setPlaceholder(returnValueInputs[i], this.value)
      updateTextarea(returnValueInputs[i], this.value)
    }
  }
  returnValueDataType.addEventListener("change", returnValueDataTypeChanged)
  returnValueDataType.dispatchEvent(new Event('change')) // triggering change to set the placeholder when the page loads

  const parameterDataTypes = document.querySelectorAll('.QuestionForm-parameterDataType')
  function parameterDataTypeChanged() {
    const parameterInputs = document.querySelectorAll('.QuestionForm-testCaseArgument')
    for (let i = 0; i < parameterInputs.length; i++) {
      if (parameterInputs[i].dataset.index === this.dataset.index) {
        setPlaceholder(parameterInputs[i], this.value)
      }
    }
  }
  for (let i = 0; i < parameterDataTypes.length; i++) {
    parameterDataTypes[i].addEventListener("change", parameterDataTypeChanged)
    parameterDataTypes[i].dispatchEvent(new Event('change')) // triggering change to set the placeholder when the page loads
  }

  function setPlaceholder(element, dataType) {
    element.placeholder = testCasePlaceholders[dataType]
  }

  function updateTextarea(element, dataType) {
    element.classList.toggle("noresize", dataType !== "string")
    element.rows = element.value.split('\n').length
  }
}
