import { TextField } from '@mui/material'
import React, { FC, useEffect } from 'react'

import { track } from '../coderpad_analytics'
import { PadAnalyticsEvent } from '../constants'

interface SearchProps {
  value: string
  onChange: (value: string) => void
  onShowNext: () => void
  onShowPrevious: () => void
}

export const Search: FC<SearchProps> = ({ value, onChange, onShowNext, onShowPrevious }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (e.shiftKey) {
          onShowPrevious()
        } else {
          onShowNext()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onShowNext, onShowPrevious])

  return (
    <TextField
      style={{
        marginRight: 8,
      }}
      size="small"
      label="Search..."
      value={value}
      onChange={(event) => {
        track(PadAnalyticsEvent.TranscriptionPlaybackSearched, {
          searchText: event.target.value,
        })
        onChange(event.target.value)
      }}
    />
  )
}
