import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

export interface DataTableData {
  /**
   * The columns to display in the table. This comes from MUI
   * Docs: https://mui.com/x/api/data-grid/grid-col-def/#properties
   */
  columns: GridColDef[]
  /**
   * The rows to display in the table.
   */
  rows: GridValidRowModel[]
  /**
   * Function for setting the table rows. Ideally, you should avoid
   * setting rows this way, in favor of the add/edit/delete functions
   */
  setRows: (rows: GridValidRowModel[]) => void
  /**
   * Adds a single row to the table
   */
  handleAddRow: (row?: GridValidRowModel) => void
  /**
   * Deletes a single row from the table, based on the row ID
   */
  handleDeleteRow: (row: GridValidRowModel) => void
  /**
   * Edits a single row in the table, based on the row ID
   */
  onRowEdit: (row: GridValidRowModel) => GridValidRowModel
  /**
   * Gets a single row from the table, based on the provided query
   */
  getRow: (query: Record<string, string>) => GridValidRowModel | undefined
  /**
   * Resets the table data to the initial value
   */
  resetData: () => void
}

interface DataTableDataOptions {
  /**
   * Callback for when any mutation occurs to the table data
   */
  onMutation?: () => void
}

/**
 * A hook to manage the data for a DataTable. This hook includes a number of
 * callbacks to manage the data with unique IDs.
 */
export const useDataTableData = (
  columns: GridColDef[],
  initialValue: GridValidRowModel[],
  options: DataTableDataOptions = {}
): DataTableData => {
  const [rows, setRows] = useState<GridValidRowModel[]>(initialValue)
  const fields = useMemo(() => columns.map((c) => c.field), [columns])

  const onMutation = useMemo(() => options.onMutation || (() => {}), [options.onMutation])

  /**
   * Injects a new blank row into the table. This includes a new UUID for the row.
   */
  const handleAddRow = useCallback(
    (row?: GridValidRowModel) => {
      const newRow =
        row ||
        fields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: '',
          }),
          {} as GridValidRowModel
        )
      setRows((prevRows) => [
        ...prevRows,
        {
          id: uuid(),
          ...newRow,
        },
      ])
      onMutation()
    },
    [fields, onMutation]
  )

  /**
   * Deletes a row from the table by its ID.
   */
  const handleDeleteRow = useCallback(
    (row: GridValidRowModel) => {
      setRows((prevRows) => prevRows.filter((r) => r.id !== row.id))
      onMutation()
    },
    [setRows, onMutation]
  )

  /**
   * Updates a row in the table by its ID.
   */
  const onRowEdit = useCallback(
    (row: GridValidRowModel) => {
      setRows((prevRows) => {
        const index = prevRows.findIndex((r) => r.id === row.id)
        if (index === -1) {
          return prevRows
        }
        return [...prevRows.slice(0, index), row, ...prevRows.slice(index + 1)]
      })
      onMutation()
      return row
    },
    [setRows, onMutation]
  )

  /**
   * Gets the last row that matches the query.
   */
  const getRow = useCallback(
    (query: Record<string, string>) => {
      return [...rows].reverse().find((row) => {
        return Object.entries(query).every(([key, value]) => row[key] === value)
      })
    },
    [rows]
  )

  /**
   * Resets the data to the initial value.
   */
  const resetData = useCallback(() => {
    setRows(initialValue)
    onMutation()
  }, [initialValue, onMutation])

  return useMemo(
    () => ({
      columns,
      rows,
      setRows,
      handleAddRow,
      handleDeleteRow,
      onRowEdit,
      getRow,
      resetData,
    }),
    [columns, rows, setRows, handleAddRow, handleDeleteRow, onRowEdit, getRow, resetData]
  )
}
