import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { actionChannel, cps, fork, put, take, takeEvery } from 'redux-saga/effects'

import padConfig from '../pad_config'
import { ActionsKeys, ProjectTab } from '../reducers/projectTabs'

const OUTPUT_FILTER_REGEXES = [
  /\s?Welcome to Node\.js v\d*\.\d*\.\d*\.\s*Type "\.help" for more information\.\s/g, // javascript
]

// Async task to handle xterm.js I/O
export default function* consoleSaga() {
  let term = null
  let termInputChannel = null
  const terms = {}
  const executionPositions = new Map()

  let projectTemplateExecutionType = null
  yield takeEvery('pad_setting_changed', function* ({ key, value, remote }) {
    if (remote || !['projectTemplateExecutionType'].includes(key)) return

    projectTemplateExecutionType = value
  })

  yield takeEvery('console_created', function* (action) {
    term = action.term
    if (padConfig.invisible) return

    termInputChannel && termInputChannel.close()
    termInputChannel = eventChannel((emit) => {
      const dataListener = term.onData(emit)
      return () => dataListener.dispose()
    })
    yield takeEvery(termInputChannel, function* (data) {
      yield put({ type: 'console_input_typed', input: data })
    })
  })

  yield takeEvery('console_destroyed', function* () {
    term = null
    termInputChannel && termInputChannel.close()
    termInputChannel = null
    executionPositions.clear()
  })

  yield takeEvery('term_created', function* ({ id, term }) {
    if (padConfig.invisible) return
    terms[id]?.channel.close()
    terms[id] = {
      term,
      channel: eventChannel((emit) => {
        const dataListener = term.onData(emit)
        return () => dataListener.dispose()
      }),
    }
    yield takeEvery(terms[id].channel, function* (data) {
      yield put({ type: 'term_input_typed', id: id, input: data })
    })
  })

  yield takeEvery('term_destroyed', function* (action) {
    terms[action.id]?.channel.close()
    delete terms[action.id]
  })

  yield takeEvery('output_reset', function* () {
    if (term) {
      term.reset()
      term.write('\n\n\n\n')
    }
  })

  yield takeEvery('execution_platform_errored', function* ({ msg }) {
    if (term) term.write(`\r\n\x1b[48;2;231;76;60m${msg}\x1b[0m\r\n`)
  })

  yield takeEvery('term_output_produced', function* ({ id, output }) {
    terms[id]?.term.write(output)
  })

  yield takeEvery('service_status', function* ({ status }) {
    if (status === 'starting') {
      terms.MAIN_PROCESS?.term.reset()
      // Give preference to "server" wording unless we know that this is a RunCommand project.
      terms.MAIN_PROCESS?.term.writeln(
        projectTemplateExecutionType !== 'RunCommand' ? 'Starting server...' : 'Starting service...'
      )
      terms.DEFAULT_SHELL?.term.reset()
      terms.DEFAULT_SHELL?.term.writeln('Starting shell...')
    } else if (status === 'restarting') {
      terms.MAIN_PROCESS?.term.reset()
      // Give preference to "server" wording unless we know that this is a RunCommand project.
      terms.MAIN_PROCESS?.term.writeln(
        projectTemplateExecutionType !== 'RunCommand'
          ? 'Restarting server...'
          : 'Restarting service...'
      )
    } else if (status === 'stopped') {
      yield put({
        type: ActionsKeys.TabSelected,
        tab: ProjectTab.MainProcess,
      })
    }
  })

  // we want to handle execution started, output, and finished in sequence, but need
  // to fork this routine so that it does not block this function
  yield fork(function* handleExecutionStartAndFinish() {
    const actionChan = yield actionChannel([
      'execution_started',
      'execution_finished',
      'console_output_produced',
    ])
    while (true) {
      // We listen to execution_started and execution_finished here to provide visual
      // feedback to the user about how long their code is taking to run. The execution
      // backend provides notices in the form of events through our socket about how long
      // the code has taken to run, and we do some clever VT100 terminal manipulation to
      // alter the terminal to show this data. Specifically, when we write the words:
      //
      // Vincent ran 1 line of Ruby:
      //
      // we store the cursor position of the colon, effectively. Later, we hack the internals
      // of xterm to overwrite the characters stored after that position to say:
      //
      // Vincent ran 1 line of Ruby (finished in 1.56s):
      const action = yield take(actionChan)

      if (action.type == 'execution_started') {
        const { id, color, msg } = action
        const matches = /^(.*) ((ran (\d+|several) lines? of.*)|(ran target .*))/.exec(msg)
        if (term && matches) {
          const [, name, rest] = matches
          const rgb = [
            parseInt(color.slice(1, 3), 16),
            parseInt(color.slice(3, 5), 16),
            parseInt(color.slice(5, 7), 16),
          ].join(';')

          // Colorize the message. Also, invisibly add the execution ID so we can annotate
          // this line with the running time later.
          yield cps([term, term.write], `\r\n\r\n\x1b[38;2;${rgb}m${name}\x1b[0m ${rest}`)
          // there is some potential for data to end up in executionPositions that is not
          // removed later but this is a tiny memory leak at worst
          executionPositions.set(id, {
            col: term.buffer.active.cursorX - 1, // subtract one to overwrite the ":"
            row: term.buffer.active.cursorY + term.buffer.active.baseY,
          })
          yield cps([term, term.write], '\r\n\r\n')
        }
      } else if (action.type == 'execution_finished') {
        const { id, elapsed } = action
        if (executionPositions.has(id)) {
          const { row, col } = executionPositions.get(id)
          executionPositions.delete(id)

          const elapsedMsg = ` (finished in ${
            elapsed > 1000 ? (elapsed / 1000).toFixed(2) + 's' : elapsed + 'ms'
          }):`

          const lineData = _.get(term.buffer.active.getLine(row), '_line._data')
          if (!lineData) continue

          // see https://github.com/xtermjs/xterm.js/blob/7c82f9c7d4f2eecd26197470c2e0c17c2e53a8aa/src/common/buffer/BufferLine.ts
          // and https://github.com/xtermjs/xterm.js/blob/27d724a13aeb8366195caa965f9cd1442c211d31/src/common/buffer/Constants.ts
          // for constants. effectively, xterm represents cells in the buffer as a
          // big flat array of packed ints, and to change content we want to set a
          // very specific offset in that array to the character code OR'd by the
          // character width (1), offset to the proper place.
          for (let i = 0; i < elapsedMsg.length; i++) {
            lineData[(col + i) * /* CELL_SIZE */ 3 + /* Cell.CONTENT */ 0] =
              elapsedMsg.charCodeAt(i) | (1 << /* Content.WIDTH_SHIFT */ 22)
          }

          term.refresh(row, row)
        }
      } else {
        if (term) {
          const filteredOutput = _.reduce(
            OUTPUT_FILTER_REGEXES,
            (s, regex) => s.replace(regex, ''),
            action.output
          )
          yield cps([term, term.write], filteredOutput)
        } else {
          console.warn(`unexpected console output: ${action.output}`)
        }
      }
    }
  })
}
