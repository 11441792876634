import { Button, Tooltip } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectIsCodeTested, selectPadSettings, selectPadVisibleTestCasesPassed } from './selectors'
import trackEvent from './track_event'

export function TakeHomeSubmitButton() {
  const [selected, setSelected] = useState(false)
  const { publicTakeHomeThreshold, takeHomeStarted, publicTakeHome, takeHomeOpened } = useSelector(
    selectPadSettings
  )
  const testsPassed = useSelector(selectPadVisibleTestCasesPassed)
  const codeIsTested = useSelector(selectIsCodeTested)
  const enableSubmit = codeIsTested && testsPassed >= publicTakeHomeThreshold && takeHomeStarted
  const tooltipTitle: string = useMemo(() => {
    if (publicTakeHome) {
      if (enableSubmit) {
        return 'End your session and submit code for review.'
      } else {
        if (!takeHomeStarted) {
          return `Please wait for 5 minutes to elapse and pass at least ${publicTakeHomeThreshold} ${
            publicTakeHomeThreshold === 1 ? 'test' : 'tests'
          } to submit`
        } else if (!codeIsTested || testsPassed < publicTakeHomeThreshold) {
          return `Please run your code and pass at least ${publicTakeHomeThreshold} ${
            publicTakeHomeThreshold === 1 ? 'test' : 'tests'
          } to submit.`
        }
      }
    } else {
      if (takeHomeStarted) {
        return 'End your session and submit code for review.'
      } else {
        if (takeHomeOpened) {
          return 'Please enter or modify your code in the left hand editor panel, and take a few more minutes to review your solution before submitting.'
        } else {
          return 'When finished, click here to save your solution.'
        }
      }
    }
    return ''
  }, [
    publicTakeHome,
    enableSubmit,
    takeHomeStarted,
    publicTakeHomeThreshold,
    codeIsTested,
    testsPassed,
  ])

  const handleClick = () => {
    setSelected(!selected)
    $('#take-home-submit-modal').modal('toggle')
    trackEvent('Take Home Submit Modal Opened')
  }

  $('#take-home-submit-modal').on('hidden.bs.modal', function () {
    setSelected(false)
  })

  return (
    <Tooltip placement="top" title={tooltipTitle} arrow>
      <span>
        <Button
          size="small"
          variant="contained"
          onClick={handleClick}
          color="error"
          sx={{ paddingX: 2, whiteSpace: 'nowrap' }}
          disabled={publicTakeHome ? !enableSubmit : !takeHomeStarted}
        >
          Submit Take-Home
        </Button>
      </span>
    </Tooltip>
  )
}
