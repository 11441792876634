import { Language } from 'utils'

export interface IFolder {
  name: string
  path: string
  isOpen: boolean
  files: File[]
}

export interface IFile {
  id: string
  name: string
  path: string
  firebasePath: string
  monacoPath: string
  isLocked: boolean
  isImmutable: boolean
  isBinary: boolean
  isLargeFile: boolean
}

export interface IEnvironment {
  id: string
  slug: string
  nameDisplayable: string
  allowMultipleFiles: boolean
  language?: Language
  questionId?: number | string
  snippet?: string
  projectTemplateSlug?: string
  projectTemplateVersion?: string
  customDatabaseLanguage?: string
  spreadsheet?: string
  files: (IFolder | IFile)[]
  activeFile: IFile
  kind: EnvironmentTypes
  visible: boolean
  /**
    Folder of the currently selected File. Important when using "Create File"
    so that the new file is placed in whatever the active folder is
  */
  activeFolder: IFolder
  isQuestionWithVariants: boolean
}

export type EnvironmentSummary = Omit<IEnvironment, 'files' | 'activeFile' | 'activeFolder'>

export interface IFirebaseEnvironment {
  slug: string
  display: string
  language?: string
  projectTemplateSlug?: string
  questionId?: string
  customDatabaseLanguage?: string
  projectTemplateVersion?: string
  isPreview?: boolean
}

export enum EnvironmentTypes {
  Language = 'language',
  Question = 'question',
  Project = 'project',
  Spreadsheet = 'spreadsheet',
}
