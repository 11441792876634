export const PadTranslation = {
  padInvitesButtonLabel: 'Invite',
  padInvitesButtonTooltip: 'Invite someone else to join this pad',
  padInvitesEmailDescription:
    "We'll email the recipient directly with a link from CoderPad, so you don't have to give away your email address.",
  padInvitesEmailFieldLabel: "Recipient's email address",
  padInvitesEmailInvalidEmail: 'Please enter a valid email address',
  padInvitesEmailSendButtonLabel: 'Send',
  padInvitesEmailSendError: 'There was a problem sending the email invite.',
  padInvitesEmailSendSuccess: 'The email invite was sent.',
  padInvitesEmailTitle: 'Send Email Invite',
  padInvitesPermalinkCopyButtonTitleCopying: 'Copied link!',
  padInvitesPermalinkCopyButtonTitleDefault: 'Copy link to clipboard',
  padInvitesPermalinkDescription: (takeHome: boolean) =>
    takeHome
      ? 'If you need a link to email for a Take-Home interview, use this <strong>full URL.</strong>'
      : 'If you need a permanent link to email for a future interview, use this <strong>full URL.</strong>',
  padInvitesPermalinkTitle: 'Permanent Link',
  padInvitesShortlinkCopyButtonTitleCopying: 'Copied link!',
  padInvitesShortlinkCopyButtonTitleDefault: 'Copy link to clipboard',
  padInvitesShortlinkDescription:
    'Read this <strong>temporary</strong> shortlink out loud to invite someone to this pad. It only lasts an hour!',
  padInvitesShortlinkGenerateButtonLabel: 'Generate a shortlink',
  padInvitesShortlinkGenerateError: 'There was a problem generating the shortlink.',
  padInvitesShortlinkGenerateSuccess: 'The shortlink was generated.',
  padInvitesShortlinkTitle: 'Phone-Friendly Temporary Link',
  padSettingsButtonTooltip: 'Configure editor settings: Vim mode, auto-close brackets, etc.',
  padSettingsEditorSettingsAutoCloseBracketsLabel: 'Auto-Close Brackets',
  padSettingsEditorSettingsAutoCloseBracketsOptionLabelOff: 'Disabled',
  padSettingsEditorSettingsAutoCloseBracketsOptionLabelOn: 'Enabled',
  padSettingsEditorSettingsFontSizeLabel: 'Font Size',
  padSettingsEditorSettingsFontSizeOptionLabel: (value: number) => `${value} px`,
  padSettingsEditorSettingsIntellisenseLabel: 'IntelliSense',
  padSettingsEditorSettingsIntellisenseOptionLabelOff: 'Disabled',
  padSettingsEditorSettingsIntellisenseOptionLabelOn: 'Enabled',
  padSettingsEditorSettingsIntellisenseTooltip: (supportedLanguages: string[]) =>
    `IntelliSense is supported in ${supportedLanguages
      .slice(0, -1)
      .join(', ')}, and ${supportedLanguages.slice(-1)}.`,
  padSettingsEditorSettingsKeyBindingsLabel: 'Key Bindings',
  padSettingsEditorSettingsKeyBindingsOptionLabelEmacs: 'Emacs',
  padSettingsEditorSettingsKeyBindingsOptionLabelStandard: 'Standard',
  padSettingsEditorSettingsKeyBindingsOptionLabelVim: 'Vim',
  padSettingsEditorSettingsShortcutsButtonLabel: 'View Shortcuts',
  padSettingsEditorSettingsShortcutsLabel: 'CoderPad Shortcuts',
  padSettingsEditorSettingsTabSpacingLabel: 'Tab Spacing',
  padSettingsEditorSettingsThemeLabel: 'Theme',
  padSettingsEditorSettingsThemeOptionLabelOff: 'Light',
  padSettingsEditorSettingsThemeOptionLabelOn: 'Dark',
  padSettingsEditorSettingsTitle: 'Editor Settings',
  padSettingsPadSettingsAccountSettingsButtonLabel: 'Open Settings',
  padSettingsPadSettingsAccountSettingsLabel: 'Account Settings',
  padSettingsPadSettingsCodeExecutionLabel: 'Code Execution',
  padSettingsPadSettingsCodeExecutionOptionLabelOff: 'Disabled',
  padSettingsPadSettingsCodeExecutionOptionLabelOn: 'Enabled',
  padSettingsPadSettingsPlaybackHistoryButtonLabel: 'View Playback',
  padSettingsPadSettingsPlaybackHistoryLabel: 'Playback History',
  padSettingsPadSettingsTitle: 'Pad Settings',
  padSettingsPadSettingsWaitingRoomLabel: 'Waiting Room',
  padSettingsPadSettingsWaitingRoomOptionLabelOff: 'Disabled',
  padSettingsPadSettingsWaitingRoomOptionLabelOn: 'Enabled',
  padSettingsShortcutsTitle: 'CoderPad Shortcuts',
  sandboxCreatePadFromSandboxButtonForkError: 'We could not generate the copy. Please try again.',
  sandboxCreatePadFromSandboxButtonForkTitle: 'Copy of Sandbox',
  sandboxCreatePadFromSandboxButtonLabel: 'Create Pad',
  sandboxCreatePadFromSandboxButtonTooltipText: 'Create a new pad with this code from your sandbox',
} as const

export type PadTranslation = typeof PadTranslation[keyof typeof PadTranslation]

export type PadTranslationKey = keyof typeof PadTranslation
