import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { HintTitle } from '../EnvironmentSelector.style'
import { SelectorItem } from '../SelectorItem/SelectorItem'

export const FocusTimeButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isOwner = usePadConfigValue('isOwner')
  const dispatch = useDispatch()

  const onStartFocusTime = useCallback(() => {
    dispatch({
      type: 'focus_time_toggled',
      value: true,
      remote: false,
    })
  }, [dispatch])

  return (
    <div>
      <SelectorItem
        iconName="focus"
        label="Focus"
        onClick={() => setIsOpen(true)}
        isActive={isOpen}
        hint={
          <HintTitle>
            {isOwner
              ? 'Focus Time lets you "pause" the interview while the candidate collects their thoughts.'
              : 'Improve your performance by collecting your thoughts for 5 minutes, without your interviewer watching.'}
          </HintTitle>
        }
      />
      <Dialog open={isOpen}>
        <DialogTitle sx={(theme) => ({ color: theme.palette.mode === 'dark' ? '#fff' : '#000' })}>
          Start Focus Time
          <IconButton
            aria-label="close"
            onClick={() => setIsOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isOwner ? (
            <>
              <Typography>
                How can you assess a candidate's coding skillset when they're under artificial
                stress? Giving a qualified candidate a few minutes to collect their thoughts can{' '}
                <a
                  href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  drastically improve their ability to pass technical assessments
                </a>
                .
              </Typography>
              <Typography py={3}>
                Focus Time will “pause” the interview for 5 minutes, blurring your screen while the
                candidate starts to think through the problem without the stress of someone
                watching. We'll play a sound when it's time for you to come back to the interview.
              </Typography>
              <Typography>
                (If you're using a 3rd party audio/videoconference, be sure to mute those as well).
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                You're stressed, we get it.{' '}
                <a
                  href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  You're more likely to perform better
                </a>{' '}
                if you take a few minutes to collect your thoughts.
              </Typography>
              <Typography pt={3}>
                Focus Time “pauses” your interview for 5 minutes, so you can think through the
                problem on your own, without the interviewer watching. We'll play a sound when 10
                seconds are left, and again when time is up.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onStartFocusTime}>
            Begin Focus Time
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
