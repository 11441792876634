import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Slide, Stack, Typography } from '@mui/material'
import React, { memo, useMemo } from 'react'

import { useEnvironment } from '../../../utils/environment/environment'
import { PadTranslation } from '../constants'
import { ScrollView } from '../ScrollView/ScrollView'

const shortcuts = [
  {
    description: 'Add multiple cursors',
    keys: { mac: ['⌥ + Click'], windows: ['Alt + Click'] },
  },
  { description: 'Autocomplete code', keys: { mac: ['Ctrl + Space'], windows: ['Ctrl + Space'] } },
  {
    description: 'Duplicate line above',
    keys: { mac: ['⌥ + Shift + ↑'], windows: ['Alt + Shift + ↑'] },
  },
  {
    description: 'Duplicate line below',
    keys: { mac: ['⌥ + Shift + ↓'], windows: ['Alt + Shift + ↓'] },
  },
  {
    description: 'Move line down',
    keys: { mac: ['⌥ + ↓'], windows: ['Alt + ↓'] },
  },
  {
    description: 'Move line up',
    keys: { mac: ['⌥ + ↑'], windows: ['Alt + ↑'] },
  },
  {
    description: 'Run code',
    keys: { mac: ['⌘ + Enter', '⌘ + S'], windows: ['Ctrl + Enter', 'Ctrl + S'] },
  },
  {
    description: 'Run only selected code',
    keys: { mac: ['⌘ + Shift + Enter'], windows: ['Ctrl + Shift + Enter'] },
  },
  {
    description: 'Select entire line',
    keys: { mac: ['⌘ + L'], windows: ['Ctrl + L'] },
  },
]

export interface PadSettingsShortcutsProps {
  isOpen: boolean
  onSettingsClose: () => void
  onShortcutsClose: () => void
}

export const PadSettingsShortcuts = memo<PadSettingsShortcutsProps>((props) => {
  const { isOpen, onSettingsClose, onShortcutsClose } = props
  const { isMac } = useEnvironment()

  const activePlatform = useMemo<'mac' | 'windows'>(() => (isMac ? 'mac' : 'windows'), [isMac])

  return (
    <Box
      component={Slide}
      direction="left"
      in={isOpen}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.background[500]
            : theme.palette.background.paper,
        borderRadius: 1,
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <ScrollView
        sx={{
          overflowY: 'auto',
          paddingY: 3,
        }}
      >
        <Stack spacing={4}>
          <Box sx={{ display: 'flex', paddingX: 2 }}>
            <Stack alignItems="center" direction="row" spacing={2} sx={{ flex: 1 }}>
              <IconButton
                data-testid="PadSettingShortcutsBackButton"
                onClick={onShortcutsClose}
                size="small"
              >
                <ArrowBackIcon />
              </IconButton>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <Typography
                  color="inherit"
                  component="h1"
                  sx={{ fontSize: '1.125rem', fontWeight: 500, marginLeft: -7 }}
                >
                  {PadTranslation.padSettingsShortcutsTitle}
                </Typography>
              </Box>
            </Stack>
            <IconButton
              data-testid="PadSettingShortcutsCloseButton"
              onClick={onSettingsClose}
              size="small"
              sx={{
                position: 'absolute',
                right: 4,
                top: 4,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ paddingX: 3 }}>
            <Stack spacing={2}>
              {shortcuts.map((shortcut) => (
                <Stack alignItems="center" direction="row" key={shortcut.description} spacing={1}>
                  <Typography sx={{ flex: 1 }}>{shortcut.description}</Typography>
                  <Stack
                    alignItems="center"
                    direction="row"
                    divider={<Typography>or</Typography>}
                    spacing={1}
                  >
                    {shortcut.keys[activePlatform].map((keyOption) => (
                      <Typography
                        key={keyOption}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                              ? theme.palette.background[900]
                              : 'rgba(0, 0, 0, 0.1)',
                          borderRadius: 2,
                          paddingX: 1,
                          paddingY: 0.5,
                        }}
                      >
                        {keyOption}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Stack>
      </ScrollView>
    </Box>
  )
})
