import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Cookie, CookieJar } from 'tough-cookie'

import { useWindow } from '../../../../utils/window'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { addGlobalEvent } from '../../playback/GlobalEvents/addGlobalEvent'
import { RequestClientRequestGlobalEvent } from '../../playback/GlobalEvents/GlobalEventTypes'
import { useClearExpiredCookies, useDeleteCookie, useSetCookie } from './actions/cookies'
import { useAbortRequest, useSendRequest } from './actions/requests'
import { RequestConfig, RequestSummary } from './types'
import { useCookieJar } from './watchers/useCookieJar'
import { useIncomingRequestUpdates } from './watchers/useIncomingRequestUpdates'
import { useRequestHistory } from './watchers/useRequestHistory'

interface RequestClientContextContract {
  requestBaseUrl: string
  requestHistory: RequestSummary[]
  sendRequest: (config: RequestConfig) => void
  abortRequest: (requestId: string) => void
  cookieJar: CookieJar
  setCookie: (newCookie: Cookie) => void
  deleteCookie: (cookie: Cookie) => void
  clearExpiredCookies: () => void
  onSelectHistoricalRequest: (requestId: string | null) => void
  currentlyViewingRequestId: string | null
}

export const RequestClientContext = React.createContext<RequestClientContextContract>({
  requestBaseUrl: '',
  requestHistory: [],
  sendRequest: () => null,
  abortRequest: () => null,
  cookieJar: new CookieJar(),
  setCookie: () => null,
  deleteCookie: () => null,
  clearExpiredCookies: () => null,
  onSelectHistoricalRequest: () => null,
  currentlyViewingRequestId: null,
})

export const RequestClientContextProvider: React.FC = ({ children }) => {
  const { slug: padSlug, isPlayback, firebaseAuthorId } = usePadConfigValues(
    'slug',
    'isPlayback',
    'firebaseAuthorId'
  )
  const {
    CoderPad: { PROJECT_IFRAME_URL_SUFFIX },
  } = useWindow()

  const baseUrl = useMemo(() => {
    const url = new URL(`https://${padSlug.toLowerCase()}.${PROJECT_IFRAME_URL_SUFFIX}`)
    url.port = ''
    return url.origin
  }, [padSlug, PROJECT_IFRAME_URL_SUFFIX])

  const requestHistory = useRequestHistory()
  const cookieJar = useCookieJar()
  useIncomingRequestUpdates(cookieJar, baseUrl)

  const sendRequest = useSendRequest(cookieJar, baseUrl)
  const abortRequest = useAbortRequest()
  const setCookie = useSetCookie(cookieJar)
  const deleteCookie = useDeleteCookie(cookieJar)
  const clearExpiredCookies = useClearExpiredCookies(cookieJar)

  const [currentlyViewingRequestId, setCurrentlyViewingRequestId] = useState<string | null>(null)
  const lastSentRequestId = useSelector((state) => state.execution?.lastSentRequestId)

  // Effect to update the request id when a request send is acknowledged by execute.
  useEffect(() => {
    if (lastSentRequestId) {
      setCurrentlyViewingRequestId(lastSentRequestId)
      if (!isPlayback) {
        addGlobalEvent<RequestClientRequestGlobalEvent>({
          type: 'request-client-request',
          user: firebaseAuthorId!,
          data: {
            requestId: lastSentRequestId,
          },
        })
      }
    }
  }, [lastSentRequestId, isPlayback, firebaseAuthorId])

  const contextValue = useMemo(() => {
    return {
      requestBaseUrl: baseUrl,
      requestHistory,
      sendRequest,
      abortRequest,
      cookieJar,
      setCookie,
      deleteCookie,
      clearExpiredCookies,
      onSelectHistoricalRequest: setCurrentlyViewingRequestId,
      currentlyViewingRequestId,
    }
  }, [
    baseUrl,
    requestHistory,
    sendRequest,
    abortRequest,
    setCookie,
    deleteCookie,
    clearExpiredCookies,
    cookieJar,
    setCurrentlyViewingRequestId,
    currentlyViewingRequestId,
  ])

  return (
    <RequestClientContext.Provider value={contextValue}>{children}</RequestClientContext.Provider>
  )
}

export function useRequestClient() {
  const contextVal = useContext(RequestClientContext)

  if (contextVal == null) {
    throw new Error(
      '`useRequestClient` hook must be a descendant of a `RequestClientContextProvider`'
    )
  }

  return contextVal
}
