import CodeMirror from 'codemirror'
import React, { useEffect } from 'react'

import { withMarkdownCodeMirror } from '../../../../with_markdown_code_mirror'

export const highlight = (code: string, language: string) => {
  const dummy = document.createElement('div')
  // @ts-ignore global CoderPad.
  CodeMirror.runMode(code, CoderPad.LANGUAGES[language].mode, dummy)
  return dummy.innerHTML
}

interface IMarkdownDisplayProps {
  codeMirror: any
  value: string
}
const _MarkdownDisplay: React.FC<IMarkdownDisplayProps> = ({ children, codeMirror, value }) => {
  useEffect(() => {
    if (codeMirror) {
      codeMirror.setValue(value || '')
    }
  }, [codeMirror, value])

  // withMarkdownCodeMirror adds CodeMirror DOM elements as children.
  return <>{children}</>
}
// `withMarkdownCodeMirror` expects you to pass `darkColorScheme` and `readOnly` props.
export const MarkdownDisplay = withMarkdownCodeMirror(_MarkdownDisplay)
