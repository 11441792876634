import { MonacoFile } from '../utils/types'

/**
 * Iterates through the list of files and recursively checks if the file name is already taken.
 * If it is, it will append the next available number to the file name.
 */
export const findNextAvailableFileName = (
  files: MonacoFile[],
  fileName: string,
  index: number
): string => {
  const newFileName = fileName.replace(/\.[^/.]+$/, (extension) => ` (${index})${extension}`)
  if (files.find((file) => file.path === newFileName)) {
    return findNextAvailableFileName(files, fileName, index + 1)
  }
  return newFileName
}

/**
 * Converts CLRF line endings to LF line endings.
 */
export const convertWindowsToUnixLineEndings = (text: string) => {
  return text.replace(/\r\n/g, '\n')
}
