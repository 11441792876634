import { Alert, Box, Button, Typography } from '@mui/material'
import React from 'react'

import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { useEnvironmentSelectorMenuContext } from '../EnvironmentSelector/EnvironmentSelectorMenuContext'

export const EmptyEnvironmentsScreen = () => {
  const { isPlayback, isSandbox } = usePadConfigValues('isPlayback', 'isSandbox')
  const { openMenu } = useEnvironmentSelectorMenuContext()

  if (isPlayback) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        p="80px"
        flexDirection="column"
        alignItems="center"
      >
        <Alert severity="warning">
          There are no environments available for playback. All of the environments were deleted
          from this pad before it was ended.
        </Alert>
      </Box>
    )
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        p="80px"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h2" sx={{ color: 'text.primary' }}>
          Pick an environment to get started!
        </Typography>
        <Box
          sx={{
            margin: (theme) => `${theme.spacing(3)} 0`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" color="primary" onClick={openMenu}>
            Open selection menu
          </Button>
        </Box>
        {isSandbox && (
          <Alert severity="warning">
            You are currently working in a sandbox. Each tab and its contents will be deleted after
            one hour of inactivity, so save important work elsewhere.
          </Alert>
        )}
      </Box>
    )
  }
}
