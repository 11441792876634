import { useThemeColor } from '@codingame/monaco-editor-react'
import MonacoLanguageClient, {
  CodinGameInfrastructure,
  registerLanguageClient,
  StatusChangeEvent,
} from '@codingame/monaco-languageclient-react'
import {
  defaultLanguageClientOptions,
  Infrastructure,
  LanguageClientManagerOptions,
  LanguageClientOptions,
} from '@codingame/monaco-languageclient-wrapper'
import { CircularProgress } from '@mui/material'
import mapValues from 'lodash/mapValues'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { checkLspSessionFlag } from '../../../utils/checkFeatureFlag'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { track } from '../coderpad_analytics'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { selectEditorSettings } from '../selectors'
import { useMonacoContext } from './MonacoContext'
import { CoderPadProjectLSPInfrastructure } from './ProjectLSPInfrastructure'
import { useMonacoOpenHandler } from './useMonacoOpenHandler'

/**
 * Temporary add python2
 * It's not included in the monaco-languageclient-wrapper as it will be removed in the future and is only used by CoderPad
 */
registerLanguageClient('python2', {
  documentSelector: [
    {
      language: 'python',
    },
  ],
  mutualizable: false,
})

abstract class AbstractCoderPadLSPInfrastructure extends CodinGameInfrastructure {
  constructor(
    private padSlug: string,
    serverAddress: string,
    useMutualizedProxy: boolean,
    sessionId?: string
  ) {
    super(serverAddress, useMutualizedProxy, sessionId)
  }

  async getSecurityToken(): Promise<string> {
    const response = await fetch(`/${this.padSlug!.replace('sandbox-', '')}/language_server_token`)
    if (!response.ok) {
      throw new Error('Something went wrong.')
    }
    const json: { token: string } = await response.json()
    return json.token
  }
}

class CoderPadLSPInfrastructure extends AbstractCoderPadLSPInfrastructure {
  constructor(padSlug: string) {
    super(padSlug, 'wss://lsp-mutualized.coderpad.io', true)
  }
}

class CoderPadSessionLSPInfrastructure extends AbstractCoderPadLSPInfrastructure {
  constructor(padSlug: string) {
    super(padSlug, 'wss://lsp-session.coderpad.io', true, padSlug)
  }
}

const projectLSPInfrastructure = new CoderPadProjectLSPInfrastructure()

function LanguageClient() {
  const { activeLanguageSettings, firepadReady } = useMonacoContext()
  const [completionStatus, setCompletionStatus] = useState<StatusChangeEvent['status'] | null>(null)
  const { environment, projectTemplate } = useActiveEnvironment()
  const { slug, isSandbox } = usePadConfigValues('slug', 'isSandbox')
  useMonacoOpenHandler()

  const languageClientId = activeLanguageSettings?.language_client_id

  const lastStartTimeRef = useRef<Date>()
  const onDidChangeStatus = useCallback(
    (event: StatusChangeEvent) => {
      if (event.status === 'connecting') {
        lastStartTimeRef.current = new Date()
      } else if (event.status === 'ready') {
        track('Language Server Ready', {
          duration: Date.now() - lastStartTimeRef.current!.getTime(),
          languageClient: languageClientId,
        })
      }
      setCompletionStatus(event.status)
    },
    [languageClientId]
  )

  const { autocomplete } = useSelector(selectEditorSettings)

  const isError = completionStatus != null && ['error', 'closed'].includes(completionStatus)
  const statusBarBackground = useThemeColor('statusBar.background')
  const statusBarForeground = useThemeColor(isError ? 'errorForeground' : 'statusBar.foreground')
  const statusBarBorder = useThemeColor('statusBar.border')

  const style = useMemo(
    () => ({
      backgroundColor: statusBarBackground,
      color: statusBarForeground,
      borderTop: `1px solid ${statusBarBorder}`,
    }),
    [statusBarBackground, statusBarForeground, statusBarBorder]
  )

  const classicLSPInfrastructure = useMemo(() => {
    // No need to use the lsp-session on a sandbox and it would keep the session alive 30 seconds after the user is gone for nothing
    if ((isSandbox ?? true) || !checkLspSessionFlag()) {
      return new CoderPadLSPInfrastructure(slug!)
    } else {
      return new CoderPadSessionLSPInfrastructure(slug!)
    }
  }, [slug, isSandbox])

  const { languageClients, infrastructure, clientManagerOptions } = useMemo<{
    languageClients: Record<string, LanguageClientOptions | undefined>
    infrastructure: Infrastructure | null
    clientManagerOptions?: LanguageClientManagerOptions
  }>(() => {
    if (environment?.projectTemplateSlug != null && projectTemplate?.settings.lsp != null) {
      return {
        languageClients: mapValues(
          projectTemplate.settings.lsp,
          ({ clientSettings: { defaultOptionsLanguageId, ...settings } }) => ({
            ...defaultLanguageClientOptions[defaultOptionsLanguageId],
            ...settings,
          })
        ),
        infrastructure: projectLSPInfrastructure,
        clientManagerOptions: { maxStartAttemptCount: 3 },
      }
    } else if (languageClientId != null) {
      return {
        languageClients: { [languageClientId]: undefined },
        infrastructure: classicLSPInfrastructure,
      }
    } else {
      return {
        languageClients: {},
        infrastructure: null,
      }
    }
  }, [
    environment?.projectTemplateSlug,
    languageClientId,
    classicLSPInfrastructure,
    projectTemplate?.settings.lsp,
  ])

  useEffect(() => {
    setCompletionStatus(null)
  }, [languageClients, autocomplete])

  if (
    Object.keys(languageClients).length === 0 ||
    !autocomplete ||
    infrastructure == null ||
    !firepadReady
  ) {
    return null
  }

  const label =
    environment?.projectTemplateSlug != null ? 'Project' : activeLanguageSettings?.display ?? ''
  const statusLabels: Record<string, string> = {
    connecting: `Activating ${label} IntelliSense`,
    connected: `Activating ${label} IntelliSense`,
    error: `Unable to activate ${label} IntelliSense`,
    closed: `${label} IntelliSense interrupted`,
    inactivityShutdown: `${label} IntelliSense interrupted`,
  }

  return (
    <>
      {Object.entries(languageClients).map(([id, options]) => (
        <MonacoLanguageClient
          key={id}
          id={id}
          clientOptions={options}
          clientManagerOptions={clientManagerOptions}
          infrastructure={infrastructure}
          onDidChangeStatus={onDidChangeStatus}
          userInactivityDelay={5 * 60 * 1000} // 5 minutes
          userInactivityShutdownDelay={5 * 60 * 1000} // 5 minutes
        />
      ))}
      {completionStatus != null && completionStatus !== 'ready' && (
        <div className="monaco-editor-status-bar" style={style}>
          {['connecting', 'connected'].includes(completionStatus) && (
            <CircularProgress
              className="monaco-editor-status-bar-spinner"
              size={16}
              color="primary"
            />
          )}
          {statusLabels[completionStatus]}
        </div>
      )}
    </>
  )
}

export default LanguageClient
