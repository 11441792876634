import { io, Socket } from 'socket.io-client'

// Socket creation wrapper to have an async way of retrieving the
// socket.io connection (useful for Project LSP Infrastructure)
export function createExecuteSocket(padSlug: string, initialExecSettings: string) {
  const socket = io(window.CoderPad.EXECUTE_URL, {
    withCredentials: false,
    query: {
      pad: padSlug,
      initialExecSettings,
    },
    reconnectionDelayMax: 10000,
    reconnectionDelay: 2000,
    transports: ['websocket', 'polling'],
  })
  socket.on('connect', () => {
    // Resolving when the socket is ready
    socketResolve(socket)
  })
  socket.on('disconnect', () => {
    // Creating a new promise for when the socket reconnects
    resetPromise()
  })
  socket.on('connect_error', () => {
    // Websocket connection failed, return with polling.
    socket.io.opts.transports = ['polling', 'websocket']
  })
  return socket
}

export function getReadyExecuteSocket() {
  return socketPromise
}

let socketResolve: (socket: Socket) => void
let socketPromise: Promise<Socket>

function resetPromise() {
  socketPromise = new Promise((resolve, reject) => {
    socketResolve = resolve
  })
}
resetPromise()
