import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider } from '@mui/material'
import * as Sentry from '@sentry/browser'
import CodeMirror from 'codemirror'
import { differenceInMilliseconds } from 'date-fns'
import Cookies from 'js-cookie'
import _, { isEqual } from 'lodash'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { select, spawn, takeEvery, takeLeading } from 'redux-saga/effects'

import * as queryStates from '../../graphql/queryStates'
import { ThemeProvider, withTheme } from '../../theme/theme'
import { buildApolloClient } from '../../utils/buildApolloClient/buildApolloClient'
import { createFetcher } from '../../utils/fetch/fetch'
import { FetchProvider } from '../../utils/fetch/FetchProvider'
import { ErrorBoundary } from '../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../dashboard/components/GenericErrorView/GenericErrorView'
import { WindowPadContextProvider } from '../dashboard/components/PadContext/WindowPadContext'
import { setup as setupAnalytics, track as trackEvent } from './coderpad_analytics'
import getDemoTimeLimit from './demo_time_limit'
import * as EditorSettingsCookie from './editor_settings_cookie'
import { ActiveEnvironmentProvider } from './Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { EnvironmentsProvider } from './Environments/EnvironmentsContext/EnvironmentsContext'
import { LanguageInfoProvider } from './EnvironmentSelector/LanguageInfoProvider'
import { EnvironmentsPlaybackMockProvider } from './EnvironmentsPlayback/EnvironmentsPlaybackMock'
import { PlaybackFramesProvider } from './EnvironmentsPlayback/PlaybackFramesProvider'
import FocusTimeIntroModal from './focus_time_intro_modal'
import InPadQuestionLibrary from './in_pad_question_library'
import logEvent from './log_pad_event'
import { Pad } from './pad_component'
import padConfig from './pad_config'
import { PlaybackProvider } from './playback/PlaybackContext'
import { HIDE_CANDIDATE_OVERLAY_COOKIE_NAME } from './reducers/user_state'
import codeFormatterSaga from './sagas/code_formatter'
import consoleSaga from './sagas/console'
import editorSaga from './sagas/editor'
import executionSaga from './sagas/execution'
import filesSaga from './sagas/files'
import notificationsSaga from './sagas/notifications'
import playbackSaga from './sagas/playback'
import questionsSaga from './sagas/questions'
import takeHomeSaga from './sagas/take_home'
import testCasesSaga from './sagas/test_cases'
import twilioSaga from './sagas/twilio'
import usersSaga from './sagas/users'
import { selectExampleDatabasePresent, selectMyUserInfo } from './selectors'
import configureStore from './store'
import SyncHandle from './sync_handle'
import { TranscriberContextProvider } from './Transcriber/ThanscriberContext/TranscriberContext'
import { TranscriptEntryKind } from './Transcriber/types'
import { SoundProvider } from './video/SoundProvider'

const authToken =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(authToken)

// TODO Include `true` to get the proper theme. Remove once our themes have converged.
const MUIEnabledQuestionLibrary = withTheme(InPadQuestionLibrary, true)

const apolloClient = buildApolloClient()

export default function setupPad() {
  Sentry.configureScope((scope) => {
    scope.setExtra('padCreatedAt', padConfig.padCreatedAt)
  })

  const userId = (padConfig.firebaseAuthorId || Date.now().toString().substr(5)).toString()
  let startTime = new Date()

  const initialLanguageName = padConfig.lang

  // In sandboxes, default username to Guest so you don't get prompted for a name.
  const initialUserInfo = {
    [userId]: {},
  }

  const initialUsername =
    padConfig.username ||
    Cookies.get(`pad_${padConfig.slug}_username`) ||
    (padConfig.isSandbox ? 'Guest' : null)

  if (padConfig.isSandbox) {
    // In sandbox showcase, we will need to populate some fake user data into the pad's user list.
    initialUserInfo[userId] = {
      id: userId,
      self: true,
      name: initialUsername,
      isOwner: false,
      color: '#268bd2',
      isOnline: true,
    }
    const initialCandidate = {
      id: '503601265271397',
      self: false,
      name: 'Candidate',
      color: '#dc322f',
      isOwner: false,
      isOnline: true,
    }
    initialUserInfo[initialCandidate.id] = initialCandidate
  }

  // If there is no title, this is a sandbox, and we are in a sandbox showcase, then provide a sample pad title.
  const initialPadTitle =
    !padConfig.title && padConfig.isSandbox && !!(padConfig.sandboxModal || padConfig.sandboxView)
      ? 'Pad Title: Candidate Name'
      : padConfig.title

  const showCandidateOverlay =
    !padConfig.iioLayout &&
    !padConfig.isSandbox &&
    (padConfig.takeHome
      ? !padConfig.isOwner && !padConfig.padOpenedAt && !padConfig.padStartedAt
      : !padConfig.isLoggedIn && !Cookies.get(HIDE_CANDIDATE_OVERLAY_COOKIE_NAME))

  let editorSettings = _.mapKeys(EditorSettingsCookie.currentConfig(), (v, k) => _.camelCase(k))
  if (padConfig.intellisenseDisabledByOrganization) {
    editorSettings.autocomplete = false
  }

  const store = configureStore({
    editorSettings,
    padSettings: {
      takeHome: padConfig.takeHome,
      takeHomeTimeLimit: padConfig.takeHomeTimeLimit,
      publicTakeHome: padConfig.publicTakeHome,
      publicTakeHomeThreshold: padConfig.publicTakeHomeThreshold,
      execEnabled: padConfig.execEnabled,
      isPublic: padConfig.isPublic,
      openedAt: padConfig.padOpenedAt,
      startedAt: padConfig.padStartedAt,
      serverTimeOffset: parseInt(padConfig.serverTime - new Date().getTime() / 1000),
      language: initialLanguageName,
      title: initialPadTitle,
      sandboxView: padConfig.sandboxView,
      sandboxSwitcher: padConfig.sandboxSwitcher,
      sandboxModal: padConfig.sandboxModal,
      serviceStatus: 'unknown',

      // Environments will load their own question info. No need to do this at startup.
      ...(padConfig.hasEnvironments
        ? {}
        : {
            questionId: padConfig.question?.questionId,
            customDatabaseId: padConfig.question?.customDatabaseId,
            customDatabaseLanguage: padConfig.question?.customDatabaseLanguage,
            customDatabaseSchema: padConfig.question?.customDatabaseSchema,
            customDatabaseSchemaJson: padConfig.question?.customDatabaseSchemaJson,
          }),

      focusTimeEnabled: padConfig.focusTimeEnabled,
      takeHomeOpened: false,
      takeHomeStarted: false,
    },
    userState: {
      isOnline: false, // Refers to Firebase connection
      showCandidateOverlay,
      // User info as received from server
      userInfo: initialUserInfo,
      userId,
      // This is called "uncommitted" because if you're in the process of changing your
      // name or logging in, this will be up-to-date, but userInfo won't be updated until
      // the server acknowledges the change.
      uncommittedUsername: initialUsername,
      uncommittedEmail: Cookies.get(`pad_${padConfig.slug}_email`) || '',
    },
    project: {
      autoSave: true,
      saving: false,
    },
    question:
      // Environments will load their own question info. No need to do this at startup.
      !padConfig.hasEnvironments && padConfig.question
        ? {
            ..._.pick(padConfig.question, [
              'questionId',
              'customFiles',
              'language',
              'testCasesEnabled',
              'starterCodeByLanguage',
              'candidateInstructions',
              'visibleTestCases',
              'solution',
            ]),
            createQuestionFromPadStatus: queryStates.initial(),
          }
        : { createQuestionFromPadStatus: queryStates.initial() },
  })

  // If candidate overlay is hidden, refresh the cookie that hides it
  if (!padConfig.takeHome && Cookies.get(HIDE_CANDIDATE_OVERLAY_COOKIE_NAME)) {
    Cookies.set(HIDE_CANDIDATE_OVERLAY_COOKIE_NAME, 'true', { expires: 14 })
  }

  const syncHandle = SyncHandle.init(userId)

  // ensure that Drawing Mode is closed when first connecting to a sandbox pad
  if (padConfig.isSandbox) {
    syncHandle.update_user({ drawingModeOpen: false })
  }

  // Do not inject sandbox starter code for scratch pads.
  if (padConfig.isSandbox && !padConfig.isScratchPad) {
    const mode = CodeMirror.getMode(
      {},
      CodeMirror.resolveMode(CoderPad.LANGUAGES[initialLanguageName].mode)
    )
    let { sandboxCode, question } = padConfig
    if (!sandboxCode) {
      const commentString = mode.lineComment ? mode.lineComment + ' ' : ''
      if (question) {
        sandboxCode = question.contents || question.starterCodeByLanguage[initialLanguageName]
      } else {
        const sandboxIntroCode = CoderPad.SANDBOX_INTROS.map((string) => {
          return `${commentString}${string}`.trim()
        }).join('\n')
        const language = CoderPad.LANGUAGES[initialLanguageName]

        const multifileExample = language.multifile_example
        const packageExample =
          padConfig.preloadedPackage && language.packages[padConfig.preloadedPackage].example
        const defaultExample = language.example

        const example = multifileExample || packageExample || defaultExample

        sandboxCode = [sandboxIntroCode, example].join('\n\n')
      }
    }

    syncHandle.setPadText(sandboxCode)
    // PadCreator normally handles this, but not for sandbox pads
    syncHandle.set('customDatabaseId', padConfig.question?.customDatabaseId || null)
    syncHandle.set('customDatabaseLanguage', padConfig.question?.customDatabaseLanguage || null)
  }

  const updatePadFirebase = function () {
    if (padConfig.isSandbox) return
    fetch(`/${padConfig.slug}/update_firebase`, { method: 'post' })
  }
  const debouncedUpdatePadFirebase = _.debounce(updatePadFirebase, 10000)

  window.addEventListener(
    'message',
    function (evt) {
      if (evt.data && evt.data.isIframeError)
        store.dispatch({
          type: 'html_iframe_errored',
          error: evt.data,
        })
    },
    false
  )

  window.addEventListener('unload', function () {
    const myUserInfo = selectMyUserInfo(store.getState())
    if (myUserInfo && myUserInfo.name) {
      // This log cannot be done in a saga because asynchrony would mean the page is
      // already unloaded
      logEvent('left', { username: myUserInfo.name })
    }
  })

  const updatePad = function (data) {
    if (padConfig.isSandbox) return
    fetcher(`/${padConfig.slug}.json`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  const loadPadQuestion = async function (id, autofocus = false) {
    // Retry in case there's delay syncing the pad's question id
    // between Firebase and the Rails DB.
    for (let i = 10; i >= 0; i--) {
      try {
        const res = await fetcher(`/${padConfig.slug}/question/${id}`)
        if (!res.ok) {
          throw new Error()
        }

        const questionInfo = await res.json()
        store.dispatch({
          type: 'question_data_loaded',
          questionId: id,
          customDatabaseId: questionInfo.custom_database_id,
          customDatabaseLanguage: questionInfo.custom_database_language,
          customDatabaseSchema: questionInfo.custom_database_schema,
          customDatabaseSchemaJson: questionInfo.custom_database_schema_json,
          customFiles: questionInfo.custom_files,
          language: questionInfo.language,
          testCasesEnabled: questionInfo.test_cases_enabled,
          starterCodeByLanguage: questionInfo.starter_code_by_language,
          candidateInstructions: questionInfo.candidate_instructions,
          visibleTestCases: questionInfo.visible_test_cases,
          solution: questionInfo.solution,
          autofocus: autofocus,
        })
        // customDbId and DbLanguage are synced through Firebase, but not
        // DbSchema so manually update it in the local store.
        store.dispatch({
          type: 'pad_setting_changed',
          key: 'customDatabaseSchema',
          value: questionInfo.custom_database_schema,
          remote: true,
        })
        store.dispatch({
          type: 'pad_setting_changed',
          key: 'customDatabaseSchemaJson',
          value: questionInfo.custom_database_schema_json,
          remote: true,
        })
      } catch (err) {
        if (i === 0) {
          alert(
            'Something went wrong when loading question metadata! Sorry about this, please reload the page.'
          )
        } else {
          await new Promise((r) => setTimeout(r, 3000))
        }
      }
    }
  }

  $(function () {
    const container = document.getElementById('workspace')
    container.id = 'workspace-container'
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.defer = 'defer'
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
    head.appendChild(script)
    const InnerPad = (
      <Pad
        iioLayout={padConfig.iioLayout}
        isDemoPad={padConfig.isDemoPad}
        isExpired={padConfig.isExpired}
        isLoggedIn={padConfig.isLoggedIn}
        isOwner={padConfig.isOwner}
        isPlayback={padConfig.isPlayback}
        isSandbox={padConfig.isSandbox}
        hasEnvironments={padConfig.hasEnvironments}
      />
    )

    const wrapPlayback = (inner) => {
      if (padConfig.isPlayback) {
        return (
          <PlaybackProvider>
            <EnvironmentsPlaybackMockProvider>
              <EnvironmentsProvider>
                <ActiveEnvironmentProvider>
                  <PlaybackFramesProvider>{inner}</PlaybackFramesProvider>
                </ActiveEnvironmentProvider>
              </EnvironmentsProvider>
            </EnvironmentsPlaybackMockProvider>
          </PlaybackProvider>
        )
      } else {
        return (
          <EnvironmentsProvider>
            <ActiveEnvironmentProvider>
              <LanguageInfoProvider>{inner}</LanguageInfoProvider>
            </ActiveEnvironmentProvider>
          </EnvironmentsProvider>
        )
      }
    }

    render(
      <WindowPadContextProvider config={window.padConfig}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider>
              <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
                <FetchProvider fetcher={fetcher}>
                  <ApolloProvider client={apolloClient}>
                    <SoundProvider>
                      <TranscriberContextProvider>
                        {wrapPlayback(InnerPad)}
                      </TranscriberContextProvider>
                    </SoundProvider>
                  </ApolloProvider>
                </FetchProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </WindowPadContextProvider>,
      container
    )

    $('#end-interview-form .btn-default').click(function () {
      $('#end-interview-modal').modal('hide')
      return false
    })

    if (padConfig.isPlayback) return

    render(
      <Provider store={store}>
        <FocusTimeIntroModal />
      </Provider>,
      document.getElementById('focus-time-intro-modal-content')
    )

    const questionsContainer = document.getElementById('questions-container')
    // No need for the legacy question bank in environments pads.
    if (questionsContainer != null && !padConfig.hasEnvironments)
      render(
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <ThemeProvider>
              <MUIEnabledQuestionLibrary
                allowSharedEdit={padConfig.questionLibraryAllowSharedEdit}
                languagesUsed={padConfig.questionLibraryLanguagesUsed}
                questionsExist={padConfig.questionLibraryQuestionsExist}
                exampleQuestionsExist={padConfig.questionLibraryExamplesExist}
                padSlug={padConfig.slug}
                organizationName={padConfig.padOrg?.name || ''}
                skipSplash={!!Cookies.get('skip_question_splash')}
                parentAndChildOrganizations={padConfig.parentAndChildOrganizations}
              />
            </ThemeProvider>
          </ApolloProvider>
        </Provider>,
        questionsContainer
      )

    syncHandle.watch('isPublic', (isP) => {
      // Don't accept `null` as a valid value for this property.
      if (isP !== null) {
        store.dispatch({
          type: 'pad_setting_changed',
          key: 'isPublic',
          value: isP,
          remote: true,
        })
      }
    })

    if (!padConfig.hasPlaybackTranscription) {
      const transcriptWatcher = syncHandle.watch('transcript', (transcripts) => {
        const hasTranscripts = transcripts != null
        if (
          hasTranscripts &&
          hasTranscripts !== store.getState().padSettings.hasPlaybackTranscription &&
          // Only count transcripts, not system messages.
          Object.values(transcripts).find((t) => t.kind === TranscriptEntryKind.Transcript)
        ) {
          store.dispatch({
            type: 'pad_setting_changed',
            key: 'hasPlaybackTranscription',
            value: true,
            remote: true,
          })
          syncHandle.off(transcriptWatcher)
        }
      })
    }

    syncHandle.watch('opened_take_home', (nowOpened) => {
      if (nowOpened !== store.getState().padSettings.takeHomeOpened) {
        store.dispatch({
          type: 'pad_setting_changed',
          key: 'takeHomeOpened',
          value: nowOpened,
          remote: true,
        })
      }
    })

    syncHandle.watch('started', (nowStarted) => {
      const padSettings = store.getState().padSettings
      if (nowStarted !== padSettings.takeHomeStarted) {
        store.dispatch({
          type: 'pad_setting_changed',
          key: 'takeHomeStarted',
          value: nowStarted,
          remote: true,
        })

        if (nowStarted) {
          trackEvent('Pad Started', { takeHome: padSettings.takeHome })
        }
      }
    })

    syncHandle.watch('ended', (endedSnap) => {
      if (endedSnap) {
        window.location = padConfig.isOwner
          ? `/${padConfig.slug}/playback`
          : `/${padConfig.slug}/thanks`
      }
    })

    $('#take-home-submit-form .btn-default').click(function () {
      $('#take-home-submit-modal').modal('hide')
      return false
    })

    // Environment pads don't respect the language in the pad config. Each environment will trigger the appropriate updates
    // to the Redux store for the given language when the environment is activated.
    if (!padConfig.hasEnvironments) {
      syncHandle.watch(
        'language',
        function (language) {
          const currentLang = store.getState().padSettings.language
          if (language != null && language !== currentLang)
            store.dispatch({
              type: 'pad_setting_changed',
              key: 'language',
              value: language,
              previousValue: currentLang,
              remote: true,
            })
        },
        initialLanguageName // TODO why is this param necessary
      )
    }

    syncHandle.watch(
      'execution_enabled',
      (nowEnabled) => {
        if (nowEnabled !== store.getState().padSettings.execEnabled) {
          store.dispatch({
            type: 'pad_setting_changed',
            key: 'execEnabled',
            value: nowEnabled,
            remote: true,
          })
        }
      },
      padConfig.execEnabled
    )

    syncHandle.watch(
      'project',
      (val) => {
        if (!isEqual(val, store.getState().project)) {
          store.dispatch({
            type: 'project/updated',
            value: val,
          })
        }
      },
      store.getState().project
    )

    syncHandle.watch(
      'focusTimeEnabled',
      (nowEnabled) => {
        if (nowEnabled !== store.getState().padSettings.focusTimeEnabled) {
          store.dispatch({
            type: 'focus_time_toggled',
            value: nowEnabled,
            remote: true,
          })
        }
      },
      padConfig.focusTimeEnabled
    )

    syncHandle.watch(
      'focusTimeStartedAt',
      (startedAt) => {
        if (startedAt !== store.getState().padSettings.focusTimeStartedAt) {
          store.dispatch({
            type: 'focus_time_started_at',
            value: startedAt,
            remote: true,
          })
        }
      },
      padConfig.focusTimeStartedAt
    )

    // Question and database info will be loaded per-environment for environments pads. No need to watch the global
    // properties, unless we are in a classic pad.
    if (!padConfig.hasEnvironments) {
      syncHandle.watch(
        'questionId',
        (newQuestionId) => {
          if (newQuestionId !== store.getState().padSettings.questionId) {
            store.dispatch({
              type: 'pad_setting_changed',
              key: 'questionId',
              value: newQuestionId,
              remote: true,
            })
          }
        },
        padConfig.question ? padConfig.question.questionId : null
      )

      syncHandle.watch('customDatabaseId', (newCustomDatabaseId) => {
        if (newCustomDatabaseId !== store.getState().padSettings.customDatabaseId) {
          store.dispatch({
            type: 'pad_setting_changed',
            key: 'customDatabaseId',
            value: newCustomDatabaseId,
            remote: true,
          })
        }
      })

      syncHandle.watch('customDatabaseLanguage', (newCustomDatabaseLanguage) => {
        if (newCustomDatabaseLanguage !== store.getState().padSettings.customDatabaseLanguage) {
          store.dispatch({
            type: 'pad_setting_changed',
            key: 'customDatabaseLanguage',
            value: newCustomDatabaseLanguage,
            remote: true,
          })
        }
      })
    }

    syncHandle.on(':connected', function (online) {
      if (online) {
        console.log(`connected - ${new Date() - startTime}ms`)
        startTime = new Date()
      }
    })

    if (padConfig.isDemoPad) {
      setTimeout(() => {
        $('.Modal').hide()
        $('#overlay').hide()
        $('#lock-demo-modal').modal({ keyboard: false, backdrop: 'static' })
      }, Math.max(differenceInMilliseconds(getDemoTimeLimit(), new Date()), 0))
    }
  })
  ;(function () {
    const updatePadTitle = _.debounce(function (title) {
      updatePad({ title })
    }, 200)

    store.runSaga(function* () {
      if (padConfig.isPlayback) {
        if (!padConfig.hasEnvironments) {
          yield spawn(filesSaga)
        }
        yield spawn(playbackSaga)
        // If we're in a projects playback, we want to spin up the execution saga
        if (padConfig.hasEnvironments) {
          yield takeLeading('active_environment_initialized', function* (value) {
            yield spawn(executionSaga, padConfig.lang)
            yield spawn(consoleSaga)
          })
        }
      } else {
        if (padConfig.takeHome) yield spawn(takeHomeSaga)
        yield spawn(codeFormatterSaga)
        yield spawn(consoleSaga)
        if (padConfig.hasEnvironments) {
          yield takeLeading('active_environment_initialized', function* (value) {
            yield spawn(executionSaga, padConfig.lang)
          })
        } else {
          yield spawn(executionSaga, padConfig.lang)
        }

        yield spawn(testCasesSaga)
        yield spawn(editorSaga, padConfig.lang)
        yield spawn(filesSaga)
        yield spawn(twilioSaga)
        yield spawn(usersSaga)
        yield spawn(notificationsSaga)
        yield spawn(questionsSaga, apolloClient)
      }

      yield takeEvery('pad_take_home_toggled', function* ({ value }) {
        let updates = { take_home: value }
        if (value) {
          const take_home_time_limit = yield select((state) => state.padSettings.takeHomeTimeLimit)

          updates.private = false
          updates.take_home_time_limit =
            typeof take_home_time_limit === 'number' ? take_home_time_limit : 120

          syncHandle.set('execution_enabled', true)
        }

        updatePad(updates)
      })

      yield takeEvery('drawing_modified', function* () {
        if (!padConfig.isSandbox) {
          syncHandle.set('drawingModified', true)
        }
      })

      yield takeEvery('pad_setting_changed', function* ({ key, value, remote }) {
        if (key === 'language' && !remote) {
          if (!padConfig.isSandbox) {
            syncHandle.set('language', value)
          }

          // If a database language was selected and there's no database present,
          // add the example database (DB lang is set, but DB id is null}.
          if (
            CoderPad.LANGUAGES[value].custom_database &&
            !store.getState().padSettings.customDatabaseId
          ) {
            syncHandle.set('customDatabaseLanguage', value)
          }

          // If the new language doesn't support DBs, detach the example DB.
          if (
            !CoderPad.LANGUAGES[value].database_allowed &&
            selectExampleDatabasePresent(store.getState())
          ) {
            syncHandle.set('customDatabaseLanguage', null)
          }
        } else if (key === 'execEnabled') {
          if (!remote && !padConfig.isSandbox) syncHandle.set('execution_enabled', !!value)
          const username = yield select((state) => state.userState.uncommittedUsername)
          logEvent(value ? 'enabled' : 'disabled', { username })
        } else if (key === 'takeHome') {
          updatePad({ take_home: value })
        } else if (key === 'takeHomeTimeLimit') {
          updatePad({ take_home_time_limit: value })
        } else if (key === 'openedAt') {
          if (value) {
            const questionId = store.getState().padSettings.questionId
            if (questionId) {
              loadPadQuestion(questionId, true)
            }
          }
        } else if (key === 'isPublic' && !remote) {
          updatePad({ private: !value })
        } else if (key === 'hasPlaybackTranscription') {
          updatePad({ has_transcription: value })
        } else if (key === 'title') {
          updatePadTitle(value)
        } else if (key === 'questionId' && value) {
          // Environment contexts will load their own question info, no need to do this at pad startup.
          if (padConfig.hasEnvironments) {
            return
          }

          // If this is an unopened take-home, we also do not want to load the question yet. This is ok, because
          // when `openedAt` is set, the question will be loaded at that time.
          const { openedAt, takeHome } = yield select((state) => state.padSettings)
          if (!padConfig.isOwner && takeHome && !openedAt) {
            return
          }

          // NOTE: You can't remove a question right now.
          loadPadQuestion(value)
        }
      })

      yield takeEvery('project/updateAutoSave', function* ({ value }) {
        syncHandle.set(`project/autoSave`, value)
      })

      yield takeEvery('editor_setting_changed', function* ({ key, value }) {
        if (padConfig.isLoggedIn) {
          let savedKey = _.snakeCase(key)
          let savedValue = value
          if (key.startsWith('tabSizes.')) {
            // send ALL the tab sizes as a dict
            savedKey = 'tab_sizes'
            savedValue = store.getState().editorSettings.tabSizes
          }
          fetcher('/user.json', {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user: { [`editor_${savedKey}`]: savedValue } }),
          })
        }
        EditorSettingsCookie.set(key, value)

        // Dumb presentation stuff.
        // TODO: Make React components so this can be removed.
        if (key === 'darkColorScheme') {
          const colorScheme = value ? 'dark' : 'light'

          $('body').removeClass(['pad--dark', 'pad--light']).addClass(`pad--${colorScheme}`)
        }
      })

      yield takeEvery('question_selected_by_local_user', function* (action) {
        syncHandle.set('customDatabaseId', action.customDatabaseId || null)
        syncHandle.set('customDatabaseLanguage', action.customDatabaseLanguage || null)
        syncHandle.set('questionId', action.questionId || null)
        syncHandle.set('language', action.language)
        if (!padConfig.isSandbox) {
          fetch(`/${padConfig.slug}/add_question/${action.questionId}`, { method: 'post' })
        }
      })
      yield takeEvery('focus_time_toggled', function* ({ value, remote, auto }) {
        if (!remote && !padConfig.isSandbox) {
          syncHandle.set('focusTimeEnabled', !!value)
          syncHandle.set('focusTimeStartedAt', Date.now())

          fetch(`/${padConfig.slug}/focus_time_stat`, {
            method: 'post',
            body: JSON.stringify({
              state: value ? 'started' : 'ended',
              auto,
            }),
          })
        }
      })
      yield takeEvery('interviewer_notes_changed', function* (action) {
        debouncedUpdatePadFirebase()
      })
      yield takeEvery('package_changed', function* ({ databaseLanguage }) {
        // If the package needs a database and the pad doesn't have one,
        // add the example database {id: null, language}.
        if (
          databaseLanguage &&
          databaseLanguage !== store.getState().padSettings.customDatabaseLanguage
        ) {
          syncHandle.set('customDatabaseLanguage', databaseLanguage)
        }
      })
    })
  })()

  $(function () {
    // In dark theme, makes the "Settings" and "End Interview" buttons look different
    // when the relevant modals are active.
    // TODO: React should control this
    $('body').on('show.bs.modal', (e) => $(`[data-target='#${e.target.id}']`).addClass('active'))
    $('body').on('hide.bs.modal', (e) => $(`[data-target='#${e.target.id}']`).removeClass('active'))
  })

  setupAnalytics({
    'cp-drawing-mode': true,
    pad_id: padConfig.slug,
    timestamp: Date.now(),
  })
  trackEvent('Joined Pad', { slug: padConfig.slug, takeHome: padConfig.takeHome })
}
