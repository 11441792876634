import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'

import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import SyncHandle from '../../../sync_handle'
import { RequestStatus, RequestSummary } from '../types'

export function useRequestHistory() {
  const [requestHistory, setRequestHistory] = useState<RequestSummary[]>([])
  const { activeEnvironment } = useEnvironments()

  useEffect(() => {
    let watcher: (snap: firebase.database.DataSnapshot) => void
    if (activeEnvironment?.slug) {
      watcher = SyncHandle().watch(
        `environmentsData/${activeEnvironment.slug}/requestSummaries`,
        (summaries: Record<string, RequestSummary>) => {
          if (summaries) {
            const sortedSummaries = Object.values(summaries)
              .filter((summary) => summary.status !== RequestStatus.Aborted)
              .sort((a, b) => (a.startedAt < b.startedAt ? 1 : -1))
            setRequestHistory(sortedSummaries)
          } else {
            setRequestHistory([])
          }
        }
      )
    }
    return () => {
      if (watcher) {
        SyncHandle().off(`environmentsData/${activeEnvironment?.slug}/requestSummaries`, watcher)
      }
    }
  }, [activeEnvironment?.slug])

  return requestHistory
}
