import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, styled, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranscriberContext } from '../Transcriber/ThanscriberContext/TranscriberContext'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function TranscriptConsent() {
  const fromInvite = useSelector((state) => state.call.fromInvite)
  const dispatch = useDispatch()

  const handleContinue = useCallback(() => {
    dispatch({
      type: 'call_join_requested',
      _analytics: {
        name: 'Call Join Attempted',
        params: {
          from_invite: fromInvite,
        },
      },
    })
  }, [dispatch, fromInvite])

  const { acceptTranscription, denyTranscription, startTranscription } = useTranscriberContext()

  const decline = useCallback(() => {
    denyTranscription()
    handleContinue()
  }, [denyTranscription, handleContinue])

  const accept = useCallback(() => {
    acceptTranscription()
    startTranscription(true)
    handleContinue()
  }, [acceptTranscription, handleContinue, startTranscription])

  return (
    <Dialog
      open={true}
      dialogTitle="This meeting will be recorded to a text transcript"
      content={
        <div>
          <ContentBlock variant="body1">
            No audio or video of this call will be saved, only a text transcription of the
            conversation. This improves accessibility, and helps the interviewer focus on the
            interview instead of taking notes.
          </ContentBlock>
          <ContentBlock variant="body1">
            Opting in to this feature also provides closed captioning for all call participants.
          </ContentBlock>
          <ContentBlock variant="body1">
            By clicking "Got it" below, you consent to having your conversation recorded to text.
            Otherwise click "Continue without transcription" to continue the call without recording.
          </ContentBlock>
        </div>
      }
      actions={[
        <Button key="decline" onClick={decline} variant="outlined" color="inherit">
          Continue without transcription
        </Button>,
        <Button key="accept" onClick={accept} variant="contained" color="primary">
          Got it
        </Button>,
      ]}
    />
  )
}
