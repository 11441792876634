import { useEffect } from 'react'
import { Cookie, CookieJar } from 'tough-cookie'

import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import SyncHandle from '../../../sync_handle'
import { Request } from '../types'

/**
 * The primary purpose of this hook is to watch for updates to children of the environment's requests. If an update
 * to a request contains a response object with a set-cookie header, that header value is used to update the cookie
 * jar. Then the cookie jar is JSON-ified and written to Firebase. Writing the jar to Firebase will cause the
 * useCookieJar hook to see the update and rewrite the context's cookie jar.
 */
export function useIncomingRequestUpdates(cookieJar: CookieJar, baseUrl: string) {
  const { activeEnvironment } = useEnvironments()

  useEffect(() => {
    let watcher: any = null
    if (activeEnvironment?.slug) {
      watcher = SyncHandle().watchChildrenUpdates(
        `environmentsData/${activeEnvironment.slug}/requests`,
        (incomingRequest: Request) => {
          if (
            incomingRequest?.response?.headers &&
            incomingRequest.response.headers['set-cookie']
          ) {
            const setCookieHeader = incomingRequest.response.headers['set-cookie']
            if (typeof setCookieHeader === 'string') {
              const cookie = Cookie.parse(setCookieHeader)
              if (cookie) {
                try {
                  cookieJar.setCookieSync(cookie, `${baseUrl}/${incomingRequest.config.path}`)
                } catch (err) {
                  // Catch cookie jar related errors. These are going to be mostly about cookies on the wrong domain.
                  // Catching will prevent these errors from getting recorded in Sentry.
                }
              }
            } else {
              setCookieHeader.forEach((cookieStr: string) => {
                const cookie = Cookie.parse(cookieStr)
                if (cookie) {
                  try {
                    cookieJar.setCookieSync(cookie, `${baseUrl}/${incomingRequest.config.path}`)
                  } catch (err) {
                    // Catch cookie jar related errors. These are going to be mostly about cookies on the wrong domain.
                    // Catching will prevent these errors from getting recorded in Sentry.
                  }
                }
              })
            }
            SyncHandle().set(
              `environmentsData/${activeEnvironment.slug}/cookieJar`,
              cookieJar.toJSON()
            )
          }
        }
      )
    }
    return () => {
      if (watcher) {
        SyncHandle().off(`environmentsData/${activeEnvironment?.slug}/requests`, watcher)
      }
    }
  }, [activeEnvironment?.slug, cookieJar, baseUrl])
}
