import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { enqueueNotif, INotification } from '../../reducers/notifications'
import { SingleUserInfo } from '../../reducers/user_state'
import SyncHandle from '../../sync_handle'
import { StateNotifierProps } from './StateNotifier'

interface UserStateChangedNotificationProps extends StateNotifierProps<SingleUserInfo> {
  watchValues: (keyof SingleUserInfo)[]
}

export const UserStateChangedNotification: FC<UserStateChangedNotificationProps> = ({
  watchValues,
  notification,
  shouldNotify,
}) => {
  const cachedValues = useRef<Record<string, SingleUserInfo>>({})
  const dispatch = useDispatch()

  const debouncedDispatch = useCallback(
    debounce((notif: INotification) => {
      dispatch(enqueueNotif(notif))
    }, 300),
    [dispatch]
  )

  useEffect(() => {
    const watcher = SyncHandle().watch(
      '/users',
      (allUsers: Record<string, Omit<SingleUserInfo, 'id'>>) => {
        for (const [id, singleUser] of Object.entries(allUsers)) {
          const data = { ...singleUser, id } as SingleUserInfo

          const cached = cachedValues.current[data.id]

          if (cached) {
            const changes = watchValues.filter((key) => cached[key] !== data[key])

            if (changes.length > 0) {
              if (!shouldNotify || shouldNotify(data)) {
                debouncedDispatch(notification(data))
              }
            }
          }

          cachedValues.current[data.id] = data
        }
      }
    )

    return () => {
      SyncHandle().off('/users', watcher)
    }
  }, [debouncedDispatch, notification, shouldNotify, watchValues])

  return null
}
