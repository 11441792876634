import { Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'
import {
  AnimatedActionButton,
  ButtonActions,
} from '../components/AnimatedActionButton/AnimatedActionButton'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { shortcutPrefix } from '../util'

const RunButton = () => {
  const { language, execEnabled, projectTemplateSlug, running } = useSelector((state) => {
    const { language, execEnabled, projectTemplateSlug } = state.padSettings
    const running = state.execution.running
    return { language, execEnabled, running, projectTemplateSlug }
  })

  const shouldExist =
    projectTemplateSlug != null ||
    (window.CoderPad.LANGUAGES[language]?.execution &&
      execEnabled &&
      language !== 'markdown' &&
      language !== 'plaintext')

  const dispatch = useDispatch()
  const hasEnvironments = usePadConfigValue('hasEnvironments')
  const { environment, files } = useActiveEnvironment()
  const toggleRun = useCallback(
    (selectionOnly = false) => {
      if (hasEnvironments) {
        dispatch({
          type: 'run_clicked',
          payload: {
            environment,
            files,
            selectionOnly,
          },
          meta: {
            isEnvironment: true,
          },
        })
      } else {
        dispatch({
          type: 'run_clicked',
          _analytics: { name: 'Run Clicked' },
        })
      }
    },
    [dispatch, environment, files, hasEnvironments]
  )

  const disabled = !execEnabled

  useEffect(() => {
    const runOnHotkeys = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'Enter' || e.key === 's') {
          // Note that we are not going to to a run action if the button will not actually be rendered.
          // We are, however, still setting up this listener so that we can stop the propagation of the keyboard event
          // and prevent the browser "save" dialog from appearing.
          if (shouldExist) {
            if (e.shiftKey) {
              toggleRun(true)
            } else {
              toggleRun()
            }
          }
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
    window.addEventListener('keydown', runOnHotkeys, { capture: true })
    return () => {
      window.removeEventListener('keydown', runOnHotkeys, { capture: true })
    }
  }, [toggleRun, shouldExist])

  const actions = useMemo<ButtonActions>(() => {
    return [{ key: 'run', label: 'Run', busyLabel: 'Stop', perform: toggleRun }]
  }, [toggleRun])

  if (!shouldExist) return null

  return (
    <Tooltip
      title={
        disabled ? (
          'The interviewer has disabled execution.'
        ) : (
          <>
            {'Shortcut: '}
            {shortcutPrefix}
            {' + enter to run code'}
          </>
        )
      }
      arrow
    >
      <span>
        <AnimatedActionButton disabled={disabled} isBusy={running} actions={actions} />
      </span>
    </Tooltip>
  )
}

export default RunButton
