import { useDraggable } from '@dnd-kit/core'
import { Box, Menu, MenuItem, styled } from '@mui/material'
import clsx from 'classnames'
import path from 'path-browserify'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as LockIcon } from '../../../../../../assets/images/projects/lock.svg'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { ConfirmDeleteDialog } from '../ConfirmDeleteDialog'
import { FileIcon } from '../FileIcon'
import { DroppableData } from '../FolderDropZone/types'
import { useContextMenu } from '../useContextMenu/useContextMenu'
import UsersTracker from '../UsersTracker'
import { MonacoFile } from '../utils/types'
import { WrappedToDepth } from '../utils/WrappedToDepth'
import { RenameFile } from './RenameFile'

interface IFileLine {
  depth: number
  file?: MonacoFile
  onClick?: () => void
  onShiftClick?: () => void
  onCommandClick?: () => void
  selected?: boolean
  showHighlight?: boolean
  showSubHighlights?: boolean
  onDeleteFile: (fileId: string) => void
}

const File = styled(Box)(({ theme }) => ({
  '&.active': {
    backgroundColor: theme.palette.filePane?.activeBackground,
    color: theme.palette.filePane?.activeText,
  },
  '&.inactive': {
    '&:hover': {
      backgroundColor: theme.palette.filePane?.highlightBackground,
    },
  },
  '&.selected': {
    backgroundColor: theme.palette.filePane?.highlightBackground,
  },
}))

export const FileLine = ({
  depth,
  file,
  showHighlight = false,
  showSubHighlights = false,
  onDeleteFile,
  selected = false,
  onClick,
  onShiftClick,
  onCommandClick,
}: IFileLine) => {
  const { environment, activeFile } = useActiveEnvironment()
  const { contextMenu, handleContextMenu, handleContextMenuClose } = useContextMenu()
  const prettyName = file == null ? '' : file.name.split('/').reverse()[0]
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const isDarkTheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const isActive = activeFile?.id === file?.fileId

  const [isRenaming, setIsRenaming] = useState(false)

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: file?.fileId ?? '',
    data: {
      type: 'file',
      path: file?.path ?? '',
      file,
    } as DroppableData,
  })

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey) {
        onCommandClick?.()
      } else if (e.shiftKey) {
        onShiftClick?.()
      } else {
        onClick?.()
      }
    },
    [onClick, onShiftClick, onCommandClick]
  )

  const handleContextMenuRenameClick = useCallback(() => {
    handleContextMenuClose()
    setIsRenaming(true)
  }, [handleContextMenuClose])

  const handleDeleteMenuClick = useCallback(() => {
    handleContextMenuClose()
    setConfirmDialogOpen(true)
  }, [handleContextMenuClose])

  const highlightDepth = activeFile == null ? 0 : activeFile.path.split('/').length - 1

  if (file == null) {
    return null
  }

  if (isRenaming) {
    return (
      <RenameFile
        filePath={path.dirname(file.path)}
        depth={depth}
        oldFileName={file.name}
        onExit={() => setIsRenaming(false)}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
      />
    )
  }

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <File
        className={clsx('file', {
          selected,
          active: isActive || contextMenu != null,
          inactive: !isActive,
        })}
        onClick={handleClick}
        data-testid="file"
        onContextMenu={environment?.projectTemplateSlug != null ? handleContextMenu : () => {}}
      >
        <WrappedToDepth
          active={selected}
          depth={depth}
          highlightDepth={highlightDepth}
          showHighlight={showHighlight}
          showSubHighlights={showSubHighlights}
        >
          <div className="file-wrapper">
            <FileIcon fileName={file.name} isDarkTheme={isDarkTheme} />
            <div className="file-name">{prettyName}</div>
            {file.isLocked ? <LockIcon /> : <UsersTracker users={file.users} />}
            {!file.isImmutable && (
              <Menu
                open={contextMenu != null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
              >
                <MenuItem dense onClick={handleContextMenuRenameClick}>
                  Rename
                </MenuItem>
                <MenuItem dense onClick={handleDeleteMenuClick}>
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        </WrappedToDepth>
      </File>
      <ConfirmDeleteDialog
        fileName={file.name}
        open={confirmDialogOpen}
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={() => onDeleteFile(file.fileId)}
      />
    </div>
  )
}
