import PropTypes from 'prop-types'
import React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

// Drop this component in to notify when its parent element's size changes.
// Library docs: https://github.com/que-etc/resize-observer-polyfill#readme

export default class ResizeDetector extends React.Component {
  static propTypes = {
    ignoreHeight: PropTypes.bool,
    ignoreWidth: PropTypes.bool,
    onResize: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this._root = React.createRef()
  }

  componentDidMount() {
    this._observer = new ResizeObserver(this._observerCallback)
    this._observer.observe(this._root.current.parentElement)
  }

  componentWillUnmount() {
    this._observer.disconnect()
  }

  _observerCallback = (entries, observer) => {
    const entry = entries[0]
    if (!entry) return
    const { width, height } = entry.contentRect
    if (
      (width !== this._lastWidth && !this.props.ignoreWidth) ||
      (height !== this._lastHeight && !this.props.ignoreHeight)
    ) {
      this.props.onResize(width, height)
    }
    this._lastWidth = width
    this._lastHeight = height
  }

  render() {
    return <span ref={this._root} />
  }
}
