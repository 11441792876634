import '@codinpad/db-viewer-component'

import { Schema, Viewport } from '@codinpad/db-viewer-component'
import { useTheme } from '@mui/material'
import React, { useEffect, useRef } from 'react'

import { DbViewerElement } from '../../../../@types/db-viewer'

export const SchemaExplorer = ({
  schemaJson,
  onTableMove,
}: {
  schemaJson: Schema
  onTableMove?: () => void
}) => {
  const theme = useTheme()
  const dbViewerRef = useRef<DbViewerElement>(null)

  useEffect(() => {
    const dbViewer = dbViewerRef.current
    if (dbViewer != null && schemaJson != null) {
      const schemaJsonFull = {
        ...schemaJson,
        viewport: 'centerByTables' as Viewport,
      }
      dbViewer.schema = schemaJsonFull
      if (onTableMove != null) {
        dbViewer.addEventListener('tableMove', onTableMove)
      }
    }

    return () => {
      if (onTableMove != null) {
        dbViewer?.removeEventListener('tableMove', onTableMove)
      }
    }
  }, [onTableMove, schemaJson])

  useEffect(() => {
    const cssVars = [
      ['font-family', 'Roboto Mono'],
      ['color', theme.palette.filePane.activeText],
      [
        'viewer-border-color',
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)',
      ],
      ['viewer-background-color', theme.palette.editor.footer],
      ['table-border-color', theme.palette.programOutput.console.background],
      ['table-background-color', theme.palette.filePane.background],
      ['relation-color', theme.palette.editor.iconStroke],
      ['relation-color-highlight', theme.palette.programOutput.console.background],
      ['scrollbar-thumb-background', theme.palette.scrollbar.color],
      ['scrollbar-thumb-hover', theme.palette.scrollbar.hover],
      ['scrollbar-thumb-active', theme.palette.scrollbar.active],
    ]
    cssVars.forEach((tuple) => {
      document.documentElement.style.setProperty(`--dbv-${tuple[0]}`, tuple[1])
    })
  }, [theme])

  return <db-viewer ref={dbViewerRef} style={{ height: '100%' }}></db-viewer>
}
