import _ from 'lodash'
import { useEffect, useRef } from 'react'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../sync_handle'
import { IUser } from '../FilePane/utils/types'
import { useMonacoContext } from '../MonacoContext'

/**
 * Goldman Sachs has the stipulation that only one non-owner can enter a pad at a time.
 * This hook queries the users in Firebase and redirects if there is more than one non-owner that is online in the pad.
 */
export function useGoldmanRedirect() {
  const { firepadReady } = useMonacoContext()
  const { goldman, isOwner } = usePadConfigValues('goldman', 'isOwner')

  // This ref is used to shortcut checking the users if we have been admitted to the pad before.
  const admittedRef = useRef(false)
  useEffect(() => {
    if (!admittedRef.current && firepadReady) {
      SyncHandle().get('users', (users: Record<number, IUser>) => {
        if (
          goldman &&
          !isOwner &&
          _.filter(users, (user) => !user.isOwner && !!user.connections).length > 1
        ) {
          window.location.assign('/pad-full')
          return
        }
        admittedRef.current = true
      })
    }
  }, [firepadReady])
}
