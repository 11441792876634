import { monaco } from '@codingame/monaco-editor-wrapper'
import { RemoteCursorManager, RemoteSelectionManager } from '@convergencelabs/monaco-collab-ext'
import { RemoteCursor } from '@convergencelabs/monaco-collab-ext/typings/RemoteCursor'
import { RemoteSelection } from '@convergencelabs/monaco-collab-ext/typings/RemoteSelection'
import { useCallback, useEffect, useRef } from 'react'

import { PlaybackParticipant } from '../playback/types'

export interface Cursors {
  [key: string]: {
    lastPosition: number
    ref?: RemoteCursor
  }
}

export interface Selections {
  [key: string]: RemoteSelection
}

/**
 * React hook that returns a collection of convenience-functions for handling
 * mutations on the monaco editor. Handles things like auto-locking the editor
 * when in readOnly mode, inserting/replacing/deleting using index-based offsets,
 * instead of using monaco's built-in line/column based offsets.
 */
export const useMonacoMutations = (
  editor: monaco.editor.IStandaloneCodeEditor | null,
  options?: {
    readOnly?: boolean
  }
) => {
  const { readOnly } = { readOnly: false, ...options }
  const remoteCursorManager = useRef<RemoteCursorManager>()
  const remoteSelectionManager = useRef<RemoteSelectionManager>()
  const cursors = useRef<Cursors>({})
  const selections = useRef<Selections>({})

  /**
   * Executes an array of edits on the editor, while preserving the hook's readOnly state.
   */
  const executeEdits = useCallback(
    (edits: monaco.editor.IIdentifiedSingleEditOperation[]) => {
      if (editor != null) {
        if (readOnly) {
          // Since monaco doesn't allow any mutations when in readOnly mode, we
          // need to manually unlock the editor before applying the edits.
          // The editor has a listener that will automatically re-lock the editor
          // after the contents have been updated.
          editor.updateOptions({ readOnly: false })
        }
        editor.executeEdits('', edits)
      }
    },
    [editor, readOnly]
  )

  /**
   * Inserts a string at the given index-offset.
   */
  const insertText = useCallback(
    (text: string, offset: number) => {
      const model = editor?.getModel()
      if (editor != null && model != null) {
        const position = model.getPositionAt(offset)
        executeEdits([
          {
            range: new monaco.Range(
              position.lineNumber,
              position.column,
              position.lineNumber,
              position.column
            ),
            text,
            forceMoveMarkers: true,
          },
        ])
      }
    },
    [editor, executeEdits]
  )

  /**
   * Replaces all text between the given index-offsets with the given string.
   */
  const replaceRange = useCallback(
    (text: string, start: number, end: number) => {
      const model = editor?.getModel()
      if (editor != null && model != null) {
        const startPos = model.getPositionAt(start)
        const endPos = model.getPositionAt(end)
        executeEdits([
          {
            range: new monaco.Range(
              startPos.lineNumber,
              startPos.column,
              endPos.lineNumber,
              endPos.column
            ),
            text,
            forceMoveMarkers: true,
          },
        ])
      }
    },
    [editor, executeEdits]
  )

  /**
   * Replaces everything in the editor with the given string.
   */
  const replaceAll = useCallback(
    (text: string) => {
      const model = editor?.getModel()
      if (editor != null && model != null) {
        executeEdits([
          {
            range: model.getFullModelRange(),
            text,
            forceMoveMarkers: true,
          },
        ])
      }
    },
    [editor, executeEdits]
  )

  /**
   * Deletes all text between the given index-offsets.
   */
  const deleteRange = useCallback(
    (start: number, end: number) => {
      if (editor != null) {
        replaceRange('', start, end)
      }
    },
    [editor, replaceRange]
  )

  /**
   * Centers the given offset within the viewport if it's outside of view.
   * Has no effect if the position is within the current editor viewport.
   */
  const scrollToPosition = useCallback(
    (offset: number) => {
      const model = editor?.getModel()
      if (editor != null && model != null) {
        const position = model.getPositionAt(offset)
        editor.revealPositionInCenterIfOutsideViewport(position)
      }
    },
    [editor]
  )

  /**
   * Moves an author's cursor to the given index-offset. When a new author is added,
   * their cursor will be automatically created and stored in internal cursor array.
   */
  const moveCursor = useCallback(
    (authorId: string, author: PlaybackParticipant, position: number) => {
      if (
        editor?.getModel() != null &&
        remoteCursorManager.current != null &&
        author?.name != null
      ) {
        const cursor = cursors.current[authorId]
        const selection = selections.current[authorId]
        if (cursor?.ref != null) {
          cursor.ref.setOffset(position)
          cursor.lastPosition = position
        } else {
          const cursor = remoteCursorManager.current.addCursor(authorId, author.color, author.name)
          cursor.setOffset(position)
          cursors.current[authorId] = {
            lastPosition: position,
            ref: cursor,
          }
        }
        if (selection != null) {
          selection.dispose()
        }
        scrollToPosition(position)
      }
    },
    [editor, scrollToPosition]
  )

  const showCursor = useCallback(
    (authorId: string, author: PlaybackParticipant) => {
      if (
        editor?.getModel() != null &&
        remoteCursorManager.current != null &&
        author?.name != null
      ) {
        const cursor = cursors.current[authorId]
        if (cursor?.ref == null) {
          const lastPosition = cursor?.lastPosition ?? 0
          const ref = remoteCursorManager.current.addCursor(authorId, author.color, author.name)
          ref.setOffset(lastPosition)
          cursors.current[authorId] = {
            lastPosition,
            ref,
          }
        }
      }
    },
    [editor]
  )

  /**
   * Hides an author's cursor if it's currently visible.
   */
  const hideCursor = useCallback(
    (authorId: string, author: PlaybackParticipant) => {
      if (
        editor?.getModel() != null &&
        remoteCursorManager.current != null &&
        author?.name != null
      ) {
        const cursor = cursors.current[authorId]
        if (cursor?.ref != null) {
          cursor.ref.dispose()
          delete cursor.ref
        }
      }
    },
    [editor]
  )

  const hideAllCursors = useCallback(() => {
    if (editor?.getModel() != null && remoteCursorManager.current != null) {
      Object.values(cursors.current).forEach((cursor) => {
        cursor.ref?.dispose()
        delete cursor.ref
      })
    }
  }, [editor])

  /**
   * Highlights a range of text between the given index-offsets. The author's
   * cursor will be automatically moved to the end of the range.
   */
  const highlightRange = useCallback(
    (authorId: string, author: PlaybackParticipant, start: number, end: number) => {
      if (
        editor?.getModel() != null &&
        remoteSelectionManager.current != null &&
        author?.name != null
      ) {
        let selection = selections.current[authorId]
        if (selection != null) {
          selection.setOffsets(start, end)
        } else {
          selection = remoteSelectionManager.current.addSelection(authorId, author.color)
          selection.setOffsets(start, end)
          selection.show()
        }
        moveCursor(authorId, author, end)
        return selection
      }
      return null
    },
    [editor, moveCursor]
  )

  /**
   * Highlights a range, pauses, and then deletes it.
   * TODO: make timeouts more hook-safe. The current implementation works
   * because our sagas are also configured to pause for the same duration.
   * This isn't a good solution, but it works for now.
   */
  const highlightRangeAndDelete = useCallback(
    (
      startOffset: number,
      endOffset: number,
      delay: number,
      authorId: string,
      author: PlaybackParticipant,
      onDone: () => void
    ) => {
      const selection = highlightRange(authorId, author, startOffset, endOffset)
      return setTimeout(() => {
        deleteRange(startOffset ?? 0, endOffset ?? 0)
        selection?.dispose()
        onDone()
      }, delay)
    },
    [highlightRange, deleteRange]
  )

  /**
   * Perform all setup logic (cursors, listeners)
   */
  useEffect(() => {
    if (editor != null) {
      remoteCursorManager.current = new RemoteCursorManager({
        editor: editor,
        tooltips: true,
        tooltipDuration: 5,
      })

      remoteSelectionManager.current = new RemoteSelectionManager({ editor })

      /**
       * If we're in readOnly mode, we need to set up an editor hook
       * that immediately locks editor into readOnly mode after making a
       * mutation. This is required, because monaco doesn't allow mutations
       * when in readOnly mode.
       */
      if (readOnly) {
        editor.onDidChangeModelContent(() => {
          editor.updateOptions({ readOnly: true })
        })
      }
    }
  }, [editor, readOnly])

  return {
    executeEdits,
    insertText,
    replaceRange,
    replaceAll,
    deleteRange,
    moveCursor,
    hideCursor,
    showCursor,
    hideAllCursors,
    highlightRange,
    highlightRangeAndDelete,
    scrollToPosition,
  }
}
