import { Grid, Typography, useTheme } from '@mui/material'
import { getReasonPhrase } from 'http-status-codes'
import { useCallback, useMemo } from 'react'
import React from 'react'

import { useRequestClientSelected } from '../RequestClientSelectedContext/RequestClientSelectedContext'

export const RequestTimingHeader: React.FC = () => {
  const theme = useTheme()
  const {
    currentlyViewingRequestResponse,
    currentlyViewingRequestTimings,
  } = useRequestClientSelected()

  const statusCodeColor = useMemo(() => {
    if (currentlyViewingRequestResponse?.statusCode) {
      const statusCode = currentlyViewingRequestResponse?.statusCode
      if (statusCode >= 200 && statusCode < 300) {
        return theme.palette.success.main
      } else if (statusCode >= 300 && statusCode < 400) {
        return theme.palette.info.main
      } else if (statusCode >= 400 && statusCode < 500) {
        return theme.palette.warning.main
      } else if (statusCode >= 500 && statusCode < 600) {
        return theme.palette.error.main
      }
    }
    return theme.palette.text.primary
  }, [theme.palette, currentlyViewingRequestResponse])

  const statusText = useMemo(() => {
    if (currentlyViewingRequestResponse?.statusCode) {
      const statusCode = currentlyViewingRequestResponse.statusCode
      const statusText = getReasonPhrase(statusCode)
      return `${statusCode} ${statusText}`
    }
    return ''
  }, [currentlyViewingRequestResponse])

  const getTotalRequestTime = useCallback((startedAt: string, endedAt: string) => {
    const startedAtDate = new Date(startedAt)
    const endedAtDate = new Date(endedAt)
    return endedAtDate.getTime() - startedAtDate.getTime()
  }, [])

  const isResolved = useMemo(
    () => currentlyViewingRequestTimings?.finishedAt && currentlyViewingRequestTimings?.startedAt,
    [currentlyViewingRequestTimings]
  )

  const requestTimeString = useMemo(() => {
    if (isResolved) {
      const requestTimeMs = getTotalRequestTime(
        currentlyViewingRequestTimings!.startedAt,
        currentlyViewingRequestTimings!.finishedAt!
      )
      if (requestTimeMs > 1000) {
        return `${(requestTimeMs / 1000).toFixed(2)} s`
      } else {
        return `${requestTimeMs} ms`
      }
    }
    return ''
  }, [currentlyViewingRequestTimings, isResolved, getTotalRequestTime])

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: 1.5,
      }}
    >
      <Grid item xs={8}>
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          Response
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign="right">
        {isResolved && (
          <>
            <Typography variant="body2" color={statusCodeColor} component="span">
              {statusText}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              sx={{
                marginLeft: 2,
              }}
            >
              {requestTimeString}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}
