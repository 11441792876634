import { Alert, Box, Button, CircularProgress, Stack, Theme } from '@mui/material'
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as QuestionBubbleIcon } from '../../../../assets/images/QuestionBubble.svg'
import * as queryStates from '../../../graphql/queryStates'
import { Language, LanguageConfiguration, useAnalytics, useWindow } from '../../../utils'
import {
  usePadConfigValue,
  usePadConfigValues,
} from '../../dashboard/components/PadContext/PadContext'
import { PadAnalyticsEvent } from '../constants'
import {
  EnvironmentSummary,
  useEnvironments,
} from '../Environments/EnvironmentsContext/EnvironmentsContext'
import { useEnvironmentsPlaybackMock } from '../EnvironmentsPlayback/EnvironmentsPlaybackMock'
import DuplicatePadButton from '../PadDuplication/DuplicatePadButton'
import { PadSettings } from '../PadSettings/PadSettings'
import { PadSettingsButton } from '../PadSettings/PadSettingsButton'
import { useGlobalEvents } from '../playback/GlobalEvents/useGlobalEvents'
import { usePlayback } from '../playback/PlaybackContext'
import { enqueueNotif } from '../reducers/notifications'
import { ScrollView } from '../ScrollView/ScrollView'
import { selectDrawingModeOpen, selectLanguagesAvailable, selectPadSettings } from '../selectors'
import { CreationOverlay, HintTitle, Menu, Selector } from './EnvironmentSelector.style'
import {
  EnvironmentSelectorMenuTabs,
  useEnvironmentSelectorMenuContext,
} from './EnvironmentSelectorMenuContext'
import { DeleteEnvironmentDialog } from './EnvSettingsMenu/DeleteEnvironmentDialog'
import { EnvSettingsMenu } from './EnvSettingsMenu/EnvSettingsMenu'
import { FocusTimeButton } from './FocusTime/FocusTimeButton'
import { NewEnvironmentMenu } from './NewEnvironmentMenu/NewEnvironmentMenu'
import { SELECTOR_ITEM_SIZE, SelectorItem } from './SelectorItem/SelectorItem'

export const EnvironmentSelector: React.FC<{
  handleOpenDrawingMode: () => void
}> = ({ handleOpenDrawingMode }) => {
  const { track } = useAnalytics()
  const drawingModeOpen = useSelector(selectDrawingModeOpen)
  const { focusTimeEnabled, focusTimeStartedAt } = useSelector(selectPadSettings)
  const {
    focusTimeSupported,
    iioLayout,
    isOwner,
    isPlayback,
    isSandbox,
    sandboxEnvironmentPreviewSlug,
    takeHome,
    questionsEnabled,
  } = usePadConfigValues(
    'focusTimeSupported',
    'iioLayout',
    'isOwner',
    'isPlayback',
    'isSandbox',
    'sandboxEnvironmentPreviewSlug',
    'takeHome',
    'questionsEnabled'
  )
  const [isPadSettingsOpen, setIsPadSettingsOpen] = useState(false)

  /**
   * Interpret the candidate overlay being shown as meaning that the user is an un-logged-in candidate. In this case,
   * the settings menu should be open by default and the user should only be able to close it by clicking the "close"
   * icon button in the settings menu. This is intended to increase visibility of the pad settings for candidates that
   * may be unfamiliar with the product.
   */
  const showCandidateOverlay = useSelector((state) => state.userState.showCandidateOverlay)
  const [forceSettingsOpen, setForceSettingsOpen] = useState(false)
  useEffect(() => {
    if (showCandidateOverlay) {
      setForceSettingsOpen(true)
    }
  }, [showCandidateOverlay])

  const { visibleEnvironments: playbackVisibleEnvironments } = useEnvironmentsPlaybackMock()

  const { environments, createStatus, clearCreationStatus } = useEnvironments()
  const window = useWindow()
  const { closeMenu, currentTab, isOpen, openMenuAndSetTab } = useEnvironmentSelectorMenuContext()

  const handlePadSettingsButtonClick = useCallback(() => {
    closeMenu()
    setIsPadSettingsOpen((prevIsPadSettingsOpen) => {
      if (!prevIsPadSettingsOpen) {
        track(PadAnalyticsEvent.PadSettingsOpened)
      }

      return !prevIsPadSettingsOpen
    })
  }, [closeMenu, track])

  const handlePadSettingsClose = useCallback(() => {
    setIsPadSettingsOpen(false)
    setForceSettingsOpen(false)
  }, [])

  const visibleEnvironments = useMemo(() => {
    if (isPlayback) {
      return environments.filter(
        (env, i) => i === 0 || playbackVisibleEnvironments.includes(env.slug)
      )
    } else if (sandboxEnvironmentPreviewSlug == null) {
      return environments.filter((env) => env.visible)
    } else if (sandboxEnvironmentPreviewSlug.match(/-variant-base/)) {
      const environmentSlugStem = sandboxEnvironmentPreviewSlug.split('-variant-')[0]
      return environments.filter((env) => env.slug.match(environmentSlugStem))
    } else {
      return environments.filter((env) => env.slug === sandboxEnvironmentPreviewSlug)
    }
  }, [environments, sandboxEnvironmentPreviewSlug, isPlayback, playbackVisibleEnvironments])

  const handleMenuOpenerClick = useCallback(
    (tab: EnvironmentSelectorMenuTabs) => {
      if (isOpen && currentTab === tab) {
        closeMenu()
      } else {
        openMenuAndSetTab(tab)
      }
    },
    [closeMenu, currentTab, isOpen, openMenuAndSetTab]
  )

  const focusTimeAvailable =
    !isSandbox &&
    focusTimeSupported &&
    !takeHome &&
    (!focusTimeEnabled || isOwner) &&
    // Check if FT has been used
    !(focusTimeStartedAt === undefined || !!focusTimeStartedAt)

  useEffect(() => {
    clearCreationStatus()
  }, [clearCreationStatus, isOpen])

  const [envToDelete, setEnvToDelete] = useState<EnvironmentSummary | null>(null)

  return (
    <Box position="relative" height="100%" width={SELECTOR_ITEM_SIZE}>
      <Selector>
        <ScrollView
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          flexGrow={1}
          pb={2}
          overflow="auto"
        >
          {visibleEnvironments.map((env) => (
            <EnvItem environment={env} key={env.slug} requestEnvironmentDelete={setEnvToDelete} />
          ))}
          <Box
            sx={(theme) => ({
              borderTop: `2px solid ${theme.palette.mode === 'dark' ? '#26282A' : '#DADADA'}`,
            })}
          ></Box>
          {!isPlayback && !sandboxEnvironmentPreviewSlug && !takeHome && questionsEnabled && (
            <div>
              <SelectorItem
                iconName="questions"
                onClick={() => handleMenuOpenerClick(EnvironmentSelectorMenuTabs.Questions)}
                hint={<HintTitle>Add a question tab</HintTitle>}
                label="Questions"
              />
            </div>
          )}
          {!isPlayback && !sandboxEnvironmentPreviewSlug && !takeHome && (
            <div>
              <SelectorItem
                iconName="languages"
                onClick={() => handleMenuOpenerClick(EnvironmentSelectorMenuTabs.Languages)}
                hint={<HintTitle>Add a language tab</HintTitle>}
                label="Languages"
              />
            </div>
          )}
          {!iioLayout && !isPlayback && !sandboxEnvironmentPreviewSlug && !takeHome && (
            <div>
              <SelectorItem
                iconName="drawing"
                label="Drawing"
                onClick={handleOpenDrawingMode}
                isActive={drawingModeOpen}
                hint={<HintTitle>Open Drawing Mode</HintTitle>}
              />
            </div>
          )}
          {!isPlayback && focusTimeAvailable && <FocusTimeButton />}
        </ScrollView>
        <Box ml="auto" mr="auto" mb={2}>
          <Stack alignItems="center" spacing={1}>
            {window.CoderPad.FLAGS.showDuplicatePad &&
              !isSandbox &&
              !sandboxEnvironmentPreviewSlug && <DuplicatePadButton tooltipPlacement="right" />}
            <PadSettingsButton onClick={handlePadSettingsButtonClick} />
          </Stack>
        </Box>
      </Selector>
      <Box sx={{ bottom: 0, position: 'absolute', left: (theme) => theme.spacing(10), zIndex: 12 }}>
        <PadSettings
          isOpen={isPadSettingsOpen || forceSettingsOpen}
          onClose={handlePadSettingsClose}
          allowClickAway={!forceSettingsOpen}
        />
      </Box>
      {/* Note(BW): visibility="hidden" does not trigger .not.toBeVisible() in tests for some reason, but style={...} does */}
      <Menu isMenuOpen={isOpen} style={{ opacity: isOpen ? 1 : 0 }}>
        {isOpen && (
          <Box height="100%">
            {queryStates.isLoadingState(createStatus) && (
              <CreationOverlay>
                <Box mt={1}>
                  <Alert severity="info" icon={<CircularProgress size={24} />}>
                    Creating Environment...
                  </Alert>
                </Box>
              </CreationOverlay>
            )}
            {queryStates.isErrorState(createStatus) && (
              <CreationOverlay>
                <Alert severity="error">
                  <Box display="flex" flexDirection="column">
                    <div>An error occurred while creating your new environment.</div>
                    <Box alignSelf="flex-end" mt={2}>
                      <Button onClick={clearCreationStatus} variant="text">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Alert>
              </CreationOverlay>
            )}
            {!isPlayback && (
              <NewEnvironmentMenu handleOpenPadSettingsMenu={handlePadSettingsButtonClick} />
            )}
          </Box>
        )}
      </Menu>
      <DeleteEnvironmentDialog
        environment={envToDelete}
        onRequestClose={() => setEnvToDelete(null)}
      />
    </Box>
  )
}

const EnvItem: React.FC<{
  environment: EnvironmentSummary
  requestEnvironmentDelete: (env: EnvironmentSummary) => void
}> = ({ environment, requestEnvironmentDelete }) => {
  const { isPlayback, isQuestionDraftPreview } = usePadConfigValues(
    'isPlayback',
    'isQuestionDraftPreview'
  )
  const { activateEnvironment, activeEnvironment, activeQuestionVariants } = useEnvironments()
  const sandboxEnvironmentPreviewSlug = usePadConfigValue('sandboxEnvironmentPreviewSlug')
  const { getEnvironmentLanguageChange } = useGlobalEvents()
  const availableLanguages = useSelector(selectLanguagesAvailable)
  const dispatch = useDispatch()
  const { scrubbing } = usePlayback()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isMouseOnTab, setIsMouseOnTab] = useState(false)

  const onEnvSelect = useCallback(
    (envId: string) => {
      // For scratch pad preview environments, there is only one env shown. No need to activate that environment when
      // clicked. This also should prevent the active environment property in Firebase from getting set to an
      // environment other than the environment's shown in the user's normal sandbox.
      if (!sandboxEnvironmentPreviewSlug) {
        activateEnvironment(envId)
      }
    },
    [sandboxEnvironmentPreviewSlug, activateEnvironment]
  )

  const language = useMemo(() => {
    if (isPlayback) {
      const languageChange = getEnvironmentLanguageChange(environment.id)
      if (languageChange) {
        const data = languageChange.data
        return data.language
      }
    }
    return environment.language
  }, [isPlayback, environment, getEnvironmentLanguageChange])

  useEffect(() => {
    if (isPlayback) {
      const languageChange = getEnvironmentLanguageChange(environment.id)
      if (languageChange) {
        const data = languageChange.data
        if (!scrubbing) {
          const language = (availableLanguages || {})[
            data.language as Language
          ] as LanguageConfiguration
          const prettyLanguage = language?.display ?? data.language
          if (prettyLanguage) {
            const message = `Question language changed to ${prettyLanguage}`
            dispatch(
              enqueueNotif({
                message,
                key: `playbackLanguageChange-${data.language}`,
                variant: 'info',
                autoDismissMs: 1500,
                onClick: () => {},
              })
            )
          }
        }
      }
    }
  }, [
    isPlayback,
    environment,
    getEnvironmentLanguageChange,
    availableLanguages,
    dispatch,
    scrubbing,
  ])

  const handleMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget as HTMLButtonElement)
    setIsMouseOnTab(true)
  }

  const handleMouseLeave = (e: MouseEvent<HTMLButtonElement>) => {
    setTimeout(() => {
      setIsMouseOnTab(false)
    }, 50)
  }

  if (
    environment.isQuestionWithVariants &&
    environment.questionId != null &&
    (isPlayback
      ? environment.slug !== activeEnvironment?.slug
      : activeQuestionVariants[environment.slug.split('-variant')[0]] !== environment.slug)
  ) {
    return null
  }

  return (
    <Box
      position="relative"
      key={environment.id}
      data-testid={`envselector-itemcontainer-${environment.id}`}
    >
      <SelectorItem
        iconName={
          language ||
          environment.projectTemplateSlug ||
          (environment.spreadsheet != null && 'gsheets') ||
          (environment.isQuestionWithVariants && 'universal') || // FIXME: just using the universal pads icon for now...
          ''
        }
        label={environment.nameDisplayable}
        onClick={() => onEnvSelect(environment.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isActive={environment.id === activeEnvironment?.id}
      />
      {!isQuestionDraftPreview && (
        <EnvSettingsMenu
          requestEnvironmentDelete={requestEnvironmentDelete}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          environment={environment}
          isMouseOnTab={isMouseOnTab}
        />
      )}
      {environment.questionId && (
        <Box
          sx={(theme: Theme) => ({
            position: 'absolute',
            top: '6px',
            right: '22px',
            height: '16px',
            width: '16px',
            '& path': {
              fill: theme.palette.mode === 'dark' ? '#E5E2E2' : '#353C4E',
            },
            pointerEvents: 'none',
          })}
        >
          <QuestionBubbleIcon />
        </Box>
      )}
    </Box>
  )
}
