import { MonacoFile, PadUser } from './FilePane/utils/types'

export enum ActionsKeys {
  CREATE_FILE = 'create_file',
  REORDER_FILE = 'reorder_file',
  SET_ACTIVE_FILE = 'set_active_file',
  UPDATE_STATE = 'update_state',
  UPDATE_FILE_USERS = 'update_file_users',
  UPDATE_FILE_USER = 'update_file_user',
}

export type MonacoState = {
  files: MonacoFile[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeFileId: string
}

type MonacoAction =
  | {
      type: ActionsKeys.CREATE_FILE
      value: { file: MonacoFile; activate?: boolean }
    }
  | {
      type: ActionsKeys.REORDER_FILE
      value: MonacoFile[]
    }
  | {
      type: ActionsKeys.SET_ACTIVE_FILE
      value: string
    }
  | {
      type: ActionsKeys.UPDATE_STATE
      value: Partial<MonacoState>
    }
  | {
      type: ActionsKeys.UPDATE_FILE_USERS
      value: { fileId: string; fileUsers: PadUser[] }
    }
  | {
      type: ActionsKeys.UPDATE_FILE_USER
      value: { fileId: string; fileUser: PadUser }
    }

export const defaultMonacoState: MonacoState = {
  files: [],
  activeFileId: '',
}

export const MonacoReducer = (state: MonacoState, action: MonacoAction): MonacoState => {
  switch (action.type) {
    case ActionsKeys.CREATE_FILE: {
      const { file, activate } = action.value

      if (
        state.files.some(
          (existingFile) => existingFile.fileId === file.fileId && existingFile.path === file.path
        )
      ) {
        return state
      }

      // if file already exists in files, but the language is different, remove it and replace it with the new version
      const existingFiles = state.files.filter(
        (existingFile) => existingFile.fileId !== file.fileId
      )

      const newFiles = [...existingFiles, file]

      const newState = { ...state, files: newFiles }
      if (activate ?? false) {
        newState.activeFileId = file.fileId
      }
      return newState
    }
    case ActionsKeys.REORDER_FILE: {
      return { ...state, files: [...state.files, ...action.value] }
    }
    case ActionsKeys.SET_ACTIVE_FILE: {
      return { ...state, activeFileId: action.value }
    }
    case ActionsKeys.UPDATE_STATE: {
      return { ...state, ...action.value }
    }
    case ActionsKeys.UPDATE_FILE_USERS: {
      const newFiles = state.files.map((file) => {
        if (file.fileId === action.value.fileId) {
          return { ...file, users: action.value.fileUsers }
        } else {
          // it is possible for a user to be represented as "connected" to multiple files at once,
          // (aka, has a cursor property for multiple files) if the user has a range selected in the
          // editor when they switch files.  handle that case here, by removing any user connections
          // to files that were not the one with the most recent update
          const newUsers = file.users.filter(
            (user) => !action.value.fileUsers.some((u) => u.id === user.id)
          )
          return { ...file, users: newUsers }
        }
      })
      return { ...state, files: newFiles }
    }
    // Updates a single user's file location, while preserving the other users locations
    case ActionsKeys.UPDATE_FILE_USER: {
      const newFiles = state.files.map((file) => {
        if (file.fileId === action.value.fileId) {
          return {
            ...file,
            users: [
              ...file.users.filter((user) => user.id !== action.value.fileUser.id),
              action.value.fileUser,
            ],
          }
        } else {
          // it is possible for a user to be represented as "connected" to multiple files at once,
          // (aka, has a cursor property for multiple files) if the user has a range selected in the
          // editor when they switch files.  handle that case here, by removing any user connections
          // to files that were not the one with the most recent update
          const newUsers = file.users.filter((user) => user.id !== action.value.fileUser.id)
          return { ...file, users: newUsers }
        }
      })
      return { ...state, files: newFiles }
    }
  }
}
