import React from 'react'

import { PadUser } from './utils/types'

/**
 * NOT YET FUNCTIONAL
 * Produces a list of users that are currently focused on the given file.
 */
const UsersTracker = ({ users }: { users: PadUser[] }) => {
  return (
    <div className="users-list">
      {users.map(({ id, color }) => {
        return <div key={id} className="user-dot" style={{ backgroundColor: color }} />
      })}
    </div>
  )
}

export default UsersTracker
