import { useCallback, useEffect, useState } from 'react'

// lifted from https://usehooks-typescript.com/react-hook/use-local-storage
export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  const readValue = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }, [initialValue])

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback((value: T) => {
    // Allow value to be a function so we have the same API as useState
    const newValue = value instanceof Function ? value(storedValue) : value

    try {
      window.localStorage.setItem(key, JSON.stringify(newValue))

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('used-local-storage'))
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    } finally {
      setStoredValue(newValue)
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue())
    }

    // initial read of the value
    handleStorageChange()

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange)

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('used-local-storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('used-local-storage', handleStorageChange)
    }
  }, [])

  return [storedValue, setValue]
}

export default useLocalStorage
