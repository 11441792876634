import { Avatar, Box, CSSObject, keyframes, styled, SvgIcon, Theme, Tooltip } from '@mui/material'
import React, { FC } from 'react'

import { ReactComponent as GptLogo } from '../../../../../../assets/images/gpt.svg'

const darkThemeDotColor = '#ffffff'
const lightThemeDotColor = '#34495e'
const dotFlashingDark = keyframes`
  0% {
    background-color: ${darkThemeDotColor}99;
  }
  50%, 100% {
    background-color: ${darkThemeDotColor}33;
  }
`

const dotFlashingLight = keyframes`
  0% {
    background-color: ${lightThemeDotColor}99;
  }
  50%, 100% {
    background-color: ${lightThemeDotColor}33;
  }
`

const dotMixin = (theme: Theme): CSSObject => ({
  width: '6px',
  height: '6px',
  borderRadius: '3px',
  backgroundColor: `${theme.palette.mode === 'dark' ? darkThemeDotColor : lightThemeDotColor}33`,
})

const BlinkingCursor = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(3),
  position: 'relative',
  animation: `${
    theme.palette.mode === 'dark' ? dotFlashingDark : dotFlashingLight
  } 1s infinite linear alternate`,
  animationDelay: '0.5s',
  ...dotMixin(theme),

  '&::before, &::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
  },
  '&::before': {
    left: '-10px',
    animation: `${
      theme.palette.mode === 'dark' ? dotFlashingDark : dotFlashingLight
    } 1s infinite alternate`,
    animationDelay: '0s',
    ...dotMixin(theme),
  },
  '&::after': {
    left: '10px',
    animation: `${
      theme.palette.mode === 'dark' ? dotFlashingDark : dotFlashingLight
    } 1s infinite alternate`,
    animationDelay: '1s',
    ...dotMixin(theme),
  },
}))

export const PendingMessage: FC = () => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowAnchor: 'none' }}>
    <Box sx={{ padding: 1 }}>
      <Tooltip title="" placement="top" arrow>
        <Avatar sx={{ backgroundColor: '#10a37e', color: '#000', fontSize: '1.125rem' }}>
          <SvgIcon sx={{ width: '32px', height: '32px' }} inheritViewBox component={GptLogo} />
        </Avatar>
      </Tooltip>
    </Box>
    <BlinkingCursor />
  </Box>
)

export default PendingMessage
