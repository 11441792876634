import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import RunTestCaseButton from './run_test_case_button'

// Show candidates a question guide for take-homes.
class _TestsTab extends React.Component {
  static propTypes = {
    hidden: PropTypes.bool.isRequired,
    testCases: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        arguments: PropTypes.any,
        return_value: PropTypes.any,
      })
    ).isRequired,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        test_case_id: PropTypes.any,
        passed: PropTypes.bool,
      })
    ),
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedTestCases: [],
    }
  }

  render() {
    const { testCases, results } = this.props
    return (
      <div className={classNames({ hidden: this.props.hidden, TestsTab: true })} readOnly={true}>
        <div className="TestsTab-header">
          Test cases verify your program's functionality. When you press Run, each set of arguments
          is passed into your program and the output is compared with the expected result.
        </div>
        <table className="TestsTab-testsTable table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={testCases.length === this.state.selectedTestCases.length}
                  onChange={(e) =>
                    this.setState({
                      selectedTestCases: e.target.checked ? testCases.map((tc) => tc.id) : [],
                    })
                  }
                />
              </th>
              <th>Arguments</th>
              <th>Expected result</th>
              {results && results.length > 0 && <th>Pass</th>}
            </tr>
          </thead>
          <tbody>
            {testCases.map((tc, index) => {
              let result = null
              if (results && results.length > 0) {
                result = _.find(results, (result) => {
                  return tc.id === result.test_case_id
                })
              }
              return (
                <tr key={tc.id || index} className={'TestsTab-testDetail'}>
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedTestCases.includes(tc.id)}
                      onChange={(e) => {
                        if (e.target.checked)
                          this.setState({
                            selectedTestCases: this.state.selectedTestCases.concat([tc.id]),
                          })
                        else
                          this.setState({
                            selectedTestCases: this.state.selectedTestCases.filter(
                              (i) => i !== tc.id
                            ),
                          })
                      }}
                    />
                  </td>
                  <td>
                    <pre>
                      {typeof tc.arguments === 'object' ? tc.arguments.join(', ') : tc.arguments}
                    </pre>
                  </td>
                  <td
                    className={classNames({
                      'TestsTab-testCaseResult--pass': result && result.passed,
                      'TestsTab-testCaseResult--fail': result && !result.passed,
                    })}
                  >
                    <pre>{tc.return_value}</pre>
                  </td>
                  {result && (
                    <td className={`TestsTab-testCaseResult--${result.passed ? 'pass' : 'fail'}`}>
                      <pre>{result.passed ? '✓' : '✗'}</pre>
                    </td>
                  )}
                  {results && results.length > 0 && !result && <td></td>}
                </tr>
              )
            })}
            <tr>
              <td colSpan={results && results.length > 0 ? 4 : 3}>
                <RunTestCaseButton testCaseIds={this.state.selectedTestCases} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    testCases: state.question.visibleTestCases,
    results: state.question.visibleTestCaseResults,
  }
}

const TestsTab = connect(mapStateToProps)(_TestsTab)

export default TestsTab
