import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { LanguageConfiguration } from 'utils/languages'

import * as queryStates from '../../../../graphql/queryStates'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { useEnvironmentsPlaybackMock } from '../../EnvironmentsPlayback/EnvironmentsPlaybackMock'
import SyncHandle from '../../sync_handle'
import { useActivateEnvironment } from './actions/useActivateEnvironment'
import { useAnalyticsProperties } from './actions/useAnalyticsProperties'
import { useCreateEnvironment } from './actions/useCreateEnvironment'
import { useDeleteEnvironment } from './actions/useDeleteEnvironment'
import { useResetEnvironment } from './actions/useResetEnvironment'
import { useSetSandboxExecEnv } from './actions/useSetSandboxExecEnv'
import { EnvironmentSummary, EnvironmentTypes } from './types'
import { useFirebaseActiveEnvironment } from './watchers/useFirebaseActiveEnvironment'
import { useFirebaseActiveQuestionVariants } from './watchers/useFirebaseActiveQuestionVariants'
import { useFirebaseActiveSandboxExecEnv } from './watchers/useFirebaseActiveSandboxExecEnv'
import { useFirebaseEnvironments } from './watchers/useFirebaseEnvironments'
import { useFirebaseEnvsEnabled } from './watchers/useFirebaseEnvsEnabled'

// For convenience in imports in consumer components.
export * from './types'

export interface EnvironmentsContextContract {
  activeEnvironment: EnvironmentSummary | null
  environments: EnvironmentSummary[]
  createEnvironment: (
    type: EnvironmentTypes,
    id: string | number,
    envOpts?: Record<string, any>
  ) => void
  activateEnvironment: (environmentId: string) => void
  environmentsEnabled: boolean
  createStatus: queryStates.QueryState
  clearCreationStatus: () => void
  createOrSelectEnvironment: (language: LanguageConfiguration, onSelect?: () => void) => void
  deleteEnvironment: (environmentSlug: string) => Promise<boolean>
  deleteStatus: queryStates.QueryState
  clearDeleteStatus: () => void
  resetEnvironment: (environmentSlug: string) => Promise<boolean>
  resetStatus: queryStates.QueryState
  clearResetStatus: () => void
  activeSandboxExecutionEnvironment: string
  ready: boolean
  setSandboxExecutionEnvironment: (slug: string) => void
  isExecPossible: boolean
  activeQuestionVariants: { [environmentSlugStem: string]: string }
}

export const environmentsContext = createContext<EnvironmentsContextContract>({
  activeEnvironment: null,
  environments: [],
  createEnvironment: () => null,
  activateEnvironment: () => null,
  environmentsEnabled: false,
  createStatus: queryStates.initial(),
  clearCreationStatus: () => null,
  createOrSelectEnvironment: () => null,
  deleteEnvironment: () => Promise.resolve(false),
  deleteStatus: queryStates.initial(),
  clearDeleteStatus: () => null,
  resetEnvironment: () => Promise.resolve(false),
  resetStatus: queryStates.initial(),
  clearResetStatus: () => Promise.resolve(false),
  ready: false,
  activeSandboxExecutionEnvironment: '',
  setSandboxExecutionEnvironment: (slug: string) => null,
  isExecPossible: true,
  activeQuestionVariants: {},
})

export const EnvironmentsProvider: React.FC = ({ children }) => {
  const {
    hasEnvironments,
    sandboxEnvironmentPreviewSlug,
    isPlayback,
    isSandbox,
  } = usePadConfigValues(
    'hasEnvironments',
    'sandboxEnvironmentPreviewSlug',
    'isPlayback',
    'isSandbox'
  )
  const dispatch = useDispatch()

  const {
    activeEnvironmentId: playbackActiveEnvironmentId,
    setActiveEnvironmentId: setPlaybackActiveEnvironmentId,
    setEnvironmentVisibility: setPlaybackEnvironmentVisibility,
  } = useEnvironmentsPlaybackMock()

  // Assorted data points from listeners on the pad's Firebase document.
  const { environments, hasLoadedEnvironments } = useFirebaseEnvironments()
  const { activeEnvSlug, hasLoadedActiveEnv } = useFirebaseActiveEnvironment()
  const activeSandboxExecutionEnvironment = useFirebaseActiveSandboxExecEnv()
  const { activeQuestionVariants } = useFirebaseActiveQuestionVariants()
  useFirebaseEnvsEnabled()

  // Assorted actions that can be taken on the collection of environments.
  const {
    createEnvironment,
    envCreateStatus: createStatus,
    clearEnvCreateStatus: clearCreationStatus,
  } = useCreateEnvironment()
  const activateEnvironment = useActivateEnvironment(environments)
  const setSandboxExecutionEnvironment = useSetSandboxExecEnv()
  const { deleteEnvironment, deleteStatus, clearDeleteStatus } = useDeleteEnvironment(
    environments,
    activateEnvironment
  )
  const { resetEnvironment, resetStatus, clearResetStatus } = useResetEnvironment(environments)

  const defaultEnvironment = environments[0]

  useEffect(() => {
    if (defaultEnvironment) {
      setPlaybackEnvironmentVisibility(defaultEnvironment.slug, true)
      setPlaybackActiveEnvironmentId(defaultEnvironment.id)
    }
  }, [defaultEnvironment, setPlaybackEnvironmentVisibility, setPlaybackActiveEnvironmentId])

  // This memoized values attempts to return the activeEnvironment, that is
  // context-dependant. If we're in playback, we want to retrieve from the
  // EnvironmentsPlaybackProvider, if we're in a regular pad, pull the
  // `activeEnvSlug` from firebase, and if we're in sandbox, pull the
  // `sandboxEnvironmentPreviewSlug` from the pad config.
  const activeEnvironment = useMemo(() => {
    if (isPlayback) {
      return environments.find((e) => e.id === playbackActiveEnvironmentId) || defaultEnvironment
    }
    if (sandboxEnvironmentPreviewSlug) {
      const previewEnv = environments.find((e) => e.slug === sandboxEnvironmentPreviewSlug) ?? null
      if (previewEnv?.isQuestionWithVariants) {
        const environmentSlugStem = previewEnv.slug.split('-variant-')[0]
        const activeQuestionVariant = activeQuestionVariants[environmentSlugStem] ?? null
        if (activeQuestionVariant == null) {
          return previewEnv
        } else {
          return environments.find((e) => e.slug === activeQuestionVariant) ?? null
        }
      } else {
        return previewEnv
      }
    }
    return environments.find((e) => e.slug === activeEnvSlug) ?? null
  }, [
    isPlayback,
    sandboxEnvironmentPreviewSlug,
    environments,
    defaultEnvironment,
    playbackActiveEnvironmentId,
    activeQuestionVariants,
    activeEnvSlug,
  ])

  useAnalyticsProperties(activeEnvironment)

  useEffect(() => {
    // force executionEnabled to be true for project environments
    if (activeEnvironment?.projectTemplateSlug != null) {
      SyncHandle().set('execution_enabled', true)
    }
  }, [activeEnvironment])

  const isInitialized = activeEnvironment != null
  useEffect(() => {
    if (isInitialized) {
      dispatch({ type: 'active_environment_initialized' })
    }
  }, [isInitialized, dispatch])

  // This effect injects the active environment's id into our redux state
  // This is required so that console logs are injected at the correct path
  // in firebase.
  useEffect(() => {
    dispatch({
      type: 'activate_environment',
      environmentId: activeEnvironment?.id,
    })
  }, [dispatch, activeEnvironment?.id])

  // This effect covers the case where for some reason there is no active environment value in firebase or that
  // corresponding environment isn't there.
  useEffect(() => {
    if (environments.length > 0 && !activeEnvSlug && hasLoadedActiveEnv) {
      activateEnvironment(environments[0].id)
    }
  }, [environments, activeEnvSlug, activateEnvironment, hasLoadedActiveEnv])

  const createOrSelectEnvironment = useCallback(
    (lang: LanguageConfiguration) => {
      const existingEnv = environments.find(
        (env) =>
          env.slug === lang.name ||
          (env.questionId == null && env.projectTemplateSlug === lang.name)
      )
      if (existingEnv != null) {
        activateEnvironment(existingEnv.id)
      } else {
        if (lang.type === 'project') {
          createEnvironment(EnvironmentTypes.Project, lang.name)
        } else if (lang.category === 'spreadsheets') {
          createEnvironment(EnvironmentTypes.Spreadsheet, lang.name)
        } else {
          createEnvironment(EnvironmentTypes.Language, lang.name)
        }
      }
    },
    [environments, activateEnvironment, createEnvironment]
  )
  const isExecPossible = !(
    isSandbox &&
    !!hasEnvironments &&
    activeEnvironment?.slug !== activeSandboxExecutionEnvironment
  )

  const ctxVal = {
    activeEnvironment,
    environments,
    activateEnvironment,
    createEnvironment,
    environmentsEnabled: !!hasEnvironments,
    createStatus,
    clearCreationStatus,
    createOrSelectEnvironment,
    deleteEnvironment,
    deleteStatus,
    clearDeleteStatus,
    resetEnvironment,
    resetStatus,
    clearResetStatus,
    ready: hasLoadedActiveEnv && hasLoadedEnvironments,
    activeSandboxExecutionEnvironment,
    setSandboxExecutionEnvironment,
    isExecPossible,
    activeQuestionVariants,
  }

  return <environmentsContext.Provider value={ctxVal}>{children}</environmentsContext.Provider>
}

export function useEnvironments() {
  const contextVal = useContext(environmentsContext)

  if (contextVal == null) {
    throw new Error(
      '`useEnvironmentsContext` hook must be a descendant of a `EnvironmentsProvider`'
    )
  }

  return contextVal
}
