import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { LeverStageOption, Maybe } from '../../../types'

const LEVER_STAGE_OPTIONS_QUERY = gql`
  query LeverStageOptions {
    leverStageOptions {
      id
      text
    }
  }
`

export interface LeverStageOptionsData {
  leverStageOptions: Maybe<Array<LeverStageOption>>
}

export function useLeverStageOptions() {
  const { data, error, loading, refetch } = useQuery<LeverStageOptionsData>(
    LEVER_STAGE_OPTIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(
        error.message || 'There was an error while fetching lever stage options.'
      )
    } else {
      return queryStates.success()
    }
  }, [error, loading])

  return useMemo(
    () => ({ leverStageOptions: data?.leverStageOptions || undefined, refetch, status }),
    [data, refetch, status]
  )
}
