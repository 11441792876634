import { DefaultRootState } from 'react-redux'

const MAX_PLAYBACK_SPEED = 8

const DEFAULT_STATE: DefaultRootState['playbackHistory'] = {
  frames: [],
  frameIndex: 0,
  frameLength: 0,
  frameDelay: 120,
  playing: false,
  playbackSpeed: 1,
  trackedUserId: undefined,
  globalEvents: {},
  fastforward: false,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function playbackHistoryReducer(
  state: DefaultRootState['playbackHistory'] = DEFAULT_STATE,
  action: any
) {
  switch (action.type) {
    case 'playback_global_events_history_received': {
      const globalEvents = action.globalEvents
      return {
        ...state,
        globalEvents,
      }
    }
    case 'playback_set_frames': {
      return {
        ...state,
        frames: action.frames,
        frameLength: (action.frames || []).length,
      }
    }
    case 'playback_track_user': {
      return {
        ...state,
        trackedUserId: action.userId,
      }
    }
    case 'playback_advance_frame':
      if (state.frames && state.frameIndex < state.frameLength - 1)
        return {
          ...state,
          frameDelay: action.frameDelay, // calculated in playback saga
          frameIndex: state.frameIndex + 1,
        }
      else return { ...state, playing: false }
    case 'playback_seek_to_frame':
      if (state.frames && action.index >= 0 && action.index < state.frameLength)
        return {
          ...state,
          frameIndex: action.index,
        }
      break
    case 'playback_seek_to_time':
      if (state.frames && state.frames.length > 1) {
        const firstTimestamp = state.frames[1].timestamp
        let i = 1
        for (; i < state.frames.length; i++) {
          if (firstTimestamp + action.ms < state.frames[i].timestamp) {
            break
          }
        }
        return {
          ...state,
          frameIndex: i - 1,
        }
      }
      break
    case 'playback_play':
      if (state.frames && state.frameLength > 1)
        return {
          ...state,
          playing: true,
        }
      break
    case 'playback_pause':
      return { ...state, playing: false }
    case 'playback_speed_shifted':
      // Playback speeds are powers of 2 up to the max speed. Reset to 1 if the max speed has been reached.
      return {
        ...state,
        playbackSpeed: state.playbackSpeed >= MAX_PLAYBACK_SPEED ? 1 : state.playbackSpeed * 2,
      }
    case 'set_playback_speed':
      // Sets the playback speed to an exact value, up to the max speed.
      return {
        ...state,
        playbackSpeed: action.speed <= MAX_PLAYBACK_SPEED ? action.speed : MAX_PLAYBACK_SPEED,
      }
    case 'playback_fastforward_start':
      return {
        ...state,
        fastforward: true,
      }
    case 'playback_fastforward_stop':
      return {
        ...state,
        fastforward: false,
      }
    case 'playback_rewound_ms':
      if (state.frames && state.frames.length > 1) {
        // The target timestamp will be the timestamp of the current frame minus the rewind ms specified in the action.
        const targetTimestamp = state.frames[state.frameIndex].timestamp - action.ms
        // Start at the current frame and work backward until a frame is found with timestamp < target timestamp.
        // By using the condition timestamp >= targetTimestamp while iterating backward from the current frame, the
        // closest frame to the current frame with a timestamp difference of at least action.ms.
        let targetFrameIdx = state.frameIndex
        while (targetFrameIdx > 1 && state.frames[targetFrameIdx].timestamp >= targetTimestamp) {
          targetFrameIdx--
        }
        return { ...state, frameIndex: targetFrameIdx }
      }
      break
  }
  return state
}
