import { Button, ButtonProps, CircularProgress } from '@mui/material'
import React, { useMemo } from 'react'

interface CancelableButtonProps extends ButtonProps {
  isWorking: boolean
  onCancel: () => void
  workingText?: string
}

export const CancelableButton: React.FC<CancelableButtonProps> = ({
  children,
  isWorking,
  workingText,
  onClick,
  onCancel,
  color,
  size,
  ...rest
}) => {
  const onBtnClick = useMemo(() => {
    return isWorking ? onCancel : onClick
  }, [isWorking, onCancel, onClick])

  const adornment = useMemo(() => {
    const progSize = size === 'small' ? 10 : size === 'large' ? 20 : 16
    return isWorking ? <CircularProgress color="inherit" size={progSize} /> : null
  }, [isWorking, size])

  const btnColor = isWorking ? 'error' : color ?? 'primary'
  const btnText = isWorking ? workingText ?? 'Cancel' : children

  return (
    <Button {...rest} size={size} color={btnColor} onClick={onBtnClick} endIcon={adornment}>
      {btnText}
    </Button>
  )
}
