import { Box } from '@mui/material'
import React from 'react'

import { useCurrentUser } from '../../../../graphql/hooks/users/useCurrentUser/useCurrentUser'
import {
  InterviewNotesContextProvider,
  useInterviewNotesContext,
} from '../InterviewNotesContextProvider/InterviewNotesContextProvider'
import { InterviewNotesEditor } from '../InterviewNotesEditor/InterviewNotesEditor'
import { InterviewNotesEditorAccessDenied } from '../InterviewNotesEditorAccessDenied'
import { InterviewNotesEditorDisabled } from '../InterviewNotesEditorDisabled'
import { InterviewNotesPrivacyControlBar } from '../InterviewNotesPrivacyControlBar/InterviewNotesPrivacyControlBar'
import { InterviewNotesPrivacyStatusUnknown } from '../InterviewNotesPrivacyStatusUnknown'

export const InterviewNotesTabBody = ({ hidden }: { hidden: boolean }) => {
  if (window.CoderPad.FLAGS.disableNotes) {
    return hidden ? null : <InterviewNotesEditorDisabled />
  } else {
    return (
      <InterviewNotesContextProvider>
        {/*using intermediary here because the component rendered is dependant on values from the provider*/}
        <_InterviewNotesTabBody hidden={hidden} />
      </InterviewNotesContextProvider>
    )
  }
}

// acts as the intermediary component to render components based on context,
// and makes mocking the context provider in tests simpler
export const _InterviewNotesTabBody = ({ hidden }: { hidden: boolean }) => {
  const { currentUser } = useCurrentUser()
  const { loading, accessAllowed, interviewNotesArePrivate } = useInterviewNotesContext()

  if (currentUser == null) {
    return null
  }

  if (currentUser?.organization && (interviewNotesArePrivate || accessAllowed)) {
    return (
      <>
        {loading ? (
          <InterviewNotesPrivacyStatusUnknown />
        ) : accessAllowed ? (
          <Box
            id="notes-with-privacy-control-container"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            {!hidden && <InterviewNotesPrivacyControlBar />}
            <InterviewNotesEditor hidden={hidden} />
          </Box>
        ) : (
          <InterviewNotesEditorAccessDenied />
        )}
      </>
    )
  } else {
    return <InterviewNotesEditor hidden={hidden} />
  }
}
