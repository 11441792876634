export enum Role {
  user = 'user',
  assistant = 'assistant',
}

export enum FinishReasonCode {
  STOP = 'stop', // API returned complete model output
  LENGTH = 'length', // Incomplete model output due to max_tokens parameter or token limit
  CONTENT_FILTER = 'content_filter', // Omitted content due to a flag from OpenAI's content filters
  CLIENT_ABORTED = 'client_aborted', // A user stopped the request while the response was streaming
  UNKNOWN = 'unknown',
  PAD_TOKEN_LIMIT_LIMIT_REACHED = 'pad_token_limit_reached',
}

export interface ConversationMessage {
  content: string
  messageId: string
  role: Role
  messageTimestamp: number
  authorId: string
  incomplete?: boolean
  conversationId: string
  finishReason: FinishReasonCode
}

export interface PartialMessageOutput {
  message: ConversationMessage
  messageToken: string
}

export interface PartialMessageOutputDone {
  message: ConversationMessage
}
