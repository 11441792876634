import { Launch } from '@mui/icons-material'
import { Box, Button, styled } from '@mui/material'
import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import React, { ReactNode, useCallback, useEffect, useRef } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { PadAnalyticsEvent } from '../../../constants/PadAnalyticsEvent'
import trackEvent from '../../../track_event'
import { isFullScreenInstructionsUrl } from '../../../Workspace/util'

const LaunchIcon = styled(Launch)({
  height: '16px',
  width: '16px',
})

export const InstructionsContainer = ({ children }: { children: ReactNode }) => {
  const instructionsWindowRef = useRef<Window | null>(null)
  const { slug, iioLayout } = usePadConfigValues('slug', 'iioLayout')
  const { environment } = useActiveEnvironment()

  const handleOpenNewWindow = useCallback(() => {
    if (instructionsWindowRef.current && !instructionsWindowRef.current.closed) {
      instructionsWindowRef.current.focus()
      trackEvent(PadAnalyticsEvent.InstructionsWindowRefocus)
    } else {
      let url = '?instructions'
      if (iioLayout) {
        url += '&iio'
      }
      instructionsWindowRef.current = window.open(url, `instructions_${slug}`, `location=0`)

      instructionsWindowRef.current?.addEventListener(
        'beforeunload',
        () => (instructionsWindowRef.current = null)
      )

      trackEvent(PadAnalyticsEvent.InstructionsWindowOpen)
    }
  }, [slug, iioLayout])

  useEffect(() => {
    if (!environment?.questionId && instructionsWindowRef.current) {
      instructionsWindowRef.current.close()
    }
  }, [environment])

  const isFullScreenInstructions = isFullScreenInstructionsUrl()

  return (
    <Box
      pl={0.5}
      sx={(theme) => ({
        height: `calc(100% - ${theme.spacing(5)})`,
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      {!isFullScreenInstructions ? (
        <Box flex="none" mt={2} mb={1} ml={2}>
          <Button
            onClick={handleOpenNewWindow}
            color="inherit"
            variant="text"
            size="small"
            fullWidth={false}
            endIcon={<LaunchIcon />}
          >
            Open instructions in new window
          </Button>
        </Box>
      ) : null}
      {children}
    </Box>
  )
}
