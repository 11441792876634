import { Socket } from 'socket.io-client'
import { monaco } from '@codingame/monaco-editor-wrapper'
import { Infrastructure, LanguageClientId } from '@codingame/monaco-languageclient-react'
import { LanguageClientOptions, MessageTransports } from '@codingame/monaco-languageclient-wrapper'
import {
  AbstractMessageReader,
  AbstractMessageWriter,
  DataCallback,
  Disposable,
  Message,
  MessageWriter,
} from 'vscode-jsonrpc'

import { timeoutPromise } from '../../../utils/promiseHelper'
import { projectRootFolder } from '../Environments/ActiveEnvironmentContext/useEnvironmentFiles'
import { getReadyExecuteSocket } from '../executeSocket'

export class CoderPadProjectLSPInfrastructure implements Infrastructure {
  private readonly rootMonacoUri = monaco.Uri.file(projectRootFolder)
  public readonly automaticTextDocumentUpdate = false
  public readonly rootUri = this.rootMonacoUri.toString()
  public readonly workspaceFolders = [
    {
      uri: this.rootMonacoUri,
      index: 0,
      name: 'main',
    },
  ]

  async openConnection(languageId: LanguageClientId): Promise<MessageTransports> {
    const socket = await getReadyExecuteSocket()
    const event = await emitLspEventRequest(socket, {
      type: LspEventType.InitializeServer,
      languageId,
    })
    if (event.languageId !== languageId) {
      throw new Error(
        `Expected '${languageId}' but got '${event.languageId}' languageId as server initialization response`
      )
    }
    if (event.type === LspEventType.ServerInitialized) {
      return {
        reader: new SocketIOMessageReader(socket, languageId),
        writer: new SocketIOMessageWriter(socket, languageId),
      }
    } else if (event.type === LspEventType.ServerInitializationError) {
      throw new Error(`Failed to start LSP server: ${event.reason}`)
    } else {
      throw new Error(`Unexpected server initialization response type`)
    }
  }

  useMutualizedProxy(languageClientId: string, options: LanguageClientOptions): boolean {
    return options.mutualizable
  }

  public async getFileContent(resource: monaco.Uri): Promise<monaco.editor.ITextModel | null> {
    const socket = await getReadyExecuteSocket()
    try {
      const event = await timeoutPromise(
        emitLspEventRequest(socket, { type: LspEventType.FileRequest, path: resource.path }),
        3000
      )
      if (event.type !== LspEventType.FileResponse) {
        console.error('[LSP] Unexpected file request response type', resource.toString())
        return null
      } else if (event.content == null) {
        console.error('[LSP] File not found', resource.toString())
        return null
      } else {
        return monaco.editor.createModel(event.content, undefined, resource)
      }
    } catch (e) {
      console.error('[LSP] Timeout retrieving file', resource.toString())
      return null
    }
  }
}

// Should be the same as in execute
const socketIOEvent = 'lsp'
enum LspEventType {
  InitializeServer = 'initializeServer',
  ServerInitialized = 'serverInitialized',
  ServerInitializationError = 'serverInitializationError',
  NativeMessage = 'nativeMessage',
  ServerClose = 'serverClose',
  FileRequest = 'fileRequest',
  FileResponse = 'fileResponse',
}

type LspEvent =
  | {
      type: LspEventType.InitializeServer
      languageId: LanguageClientId
    }
  | {
      type: LspEventType.ServerInitialized
      languageId: LanguageClientId
    }
  | {
      type: LspEventType.ServerInitializationError
      languageId: LanguageClientId
      reason: string
    }
  | {
      type: LspEventType.NativeMessage
      languageId: LanguageClientId
      message: Message
    }
  | {
      type: LspEventType.ServerClose
      languageId: LanguageClientId
      error?: string
    }
  | {
      type: LspEventType.FileRequest
      languageId?: undefined
      path: string
    }
  | {
      type: LspEventType.FileResponse
      languageId?: undefined
      content: string
    }

function emitLspEvent(socket: Socket, event: LspEvent) {
  socket.emit(socketIOEvent, event)
}

function emitLspEventRequest(socket: Socket, event: LspEvent) {
  return new Promise<LspEvent>((resolve) => {
    socket.emit(socketIOEvent, event, (event: LspEvent) => {
      resolve(event)
    })
  })
}

class SocketIOMessageReader extends AbstractMessageReader {
  private listenDisposable?: Disposable

  constructor(private socket: Socket, private languageId: LanguageClientId) {
    super()
  }

  listen(callback: DataCallback): Disposable {
    const onLspEvent = (event: LspEvent) => {
      if (event.languageId !== this.languageId) {
        return
      }
      switch (event.type) {
        case LspEventType.NativeMessage:
          callback(event.message)
          break
        case LspEventType.ServerClose:
          if (event.error != null) {
            this.fireError(event.error)
          }
          this.fireClose()
          break
      }
    }
    this.socket.on(socketIOEvent, onLspEvent)

    const onDisconnect = () => {
      this.fireClose()
    }
    this.socket.once('disconnect', onDisconnect)

    this.listenDisposable = Disposable.create(() => {
      this.socket.off(socketIOEvent, onLspEvent)
      this.socket.off('disconnect', onDisconnect)
    })
    return this.listenDisposable
  }

  dispose(): void {
    this.listenDisposable?.dispose()
  }
}

class SocketIOMessageWriter extends AbstractMessageWriter implements MessageWriter {
  constructor(private socket: Socket, private languageId: LanguageClientId) {
    super()
  }

  async write(message: Message): Promise<void> {
    if (this.socket.connected) {
      emitLspEvent(this.socket, {
        type: LspEventType.NativeMessage,
        languageId: this.languageId,
        message,
      })
    }
  }

  end(): void {
    // do nothing
  }
}
