import React from 'react'

/**
 * This is a band-aid fix for orgs that want to disable notes until we have better notes security.
 */
export const InterviewNotesEditorDisabled = () => {
  return (
    <div className="InterviewNotesEditor-disabled">
      <p>
        Interviewer notes have been disabled by your organization. Please coordinate with your team
        to decide on an alternative platform for interviewer notes.
      </p>
    </div>
  )
}
