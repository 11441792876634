import React from 'react'

import highlightText from './highlight_text'

export default function QuestionListItem({
  question,
  query,
  isSelected,
  isFavorite,
  onSelect,
  onFavorite,
}) {
  return (
    <li onClick={() => onSelect(question)} className={`result ${isSelected && 'selected'}`}>
      <h4 dangerouslySetInnerHTML={{ __html: highlightText(question.title, query) }} />

      {question.description && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: highlightText(question.description, query) }}
        />
      )}

      <p className="metadata">
        {question.test_cases_enabled ? 'Test Cases' : CoderPad.LANGUAGES[question.language].display}
        <span>
          · by{' '}
          <span dangerouslySetInnerHTML={{ __html: highlightText(question.author_name, query) }} />
        </span>
      </p>

      {isFavorite ? (
        <span
          className="star filled"
          onClick={() => onFavorite(question, true)}
          title="Remove this question from your favorites."
        />
      ) : (
        <span
          className="star empty"
          onClick={() => onFavorite(question, false)}
          title="Add this question to your favorites."
        />
      )}
    </li>
  )
}
