import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

export default class Modal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onBackgroundClick: PropTypes.func,
    padding: PropTypes.number,
  }

  handleClick = (evt) => {
    if (this.props.onBackgroundClick && evt.target === this._backdrop) {
      evt.stopPropagation()
      this.props.onBackgroundClick()
    }
  }

  render() {
    const styleObj =
      this.props.padding != null
        ? {
            padding: this.props.padding,
          }
        : undefined
    return (
      <div
        className="Modal"
        onClick={this.handleClick}
        role="dialog"
        tabIndex="-1"
        ref={(el) => (this._backdrop = el)}
      >
        <div className={classNames(this.props.className, 'Modal-contents')} style={styleObj}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
