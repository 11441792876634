import PropTypes from 'prop-types'
import React from 'react'

export default class HTMLErrorBox extends React.Component {
  static propTypes = {
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
        parsedStack: PropTypes.arrayOf(
          PropTypes.shape({
            functionName: PropTypes.string,
            args: PropTypes.arrayOf(PropTypes.string),
            fileName: PropTypes.string,
            lineNumber: PropTypes.number,
            columnNumber: PropTypes.number,
            isEval: PropTypes.bool,
            isNative: PropTypes.bool,
          })
        ),
      })
    ).isRequired,
    onDismissErrors: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    // Keep track of which errors you've expanded to see the stack
    this.state = { expandedErrors: new Set() }
    // To scroll to newest error
    this._list = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors.length === 0 && this.state.expandedErrors.size > 0) {
      // Just to prevent leaking memory, clear the set of expanded errors
      this.setState({ expandedErrors: new Set() })
    } else if (this.props.errors.length > prevProps.errors.length) {
      // Scroll to new error
      const listElement = this._list.current
      listElement.scrollTo({ top: 999999, behavior: 'smooth' })
    }
  }

  handleDismissClick = (evt) => {
    evt.preventDefault()
    this.props.onDismissErrors()
  }

  handleExpandErrorClick = (key, evt) => {
    evt.preventDefault()
    this.setState((state) => ({
      expandedErrors: new Set(state.expandedErrors).add(key),
    }))
  }

  _renderError(err, index) {
    const stack = err.parsedStack

    // Don't show a stack that's just "[pad]" on a line by itself:
    const stackIsBoring =
      stack && stack.length === 1 && !stack[0].functionName && fileNameIsPad(stack[0].fileName)

    const expand = this.state.expandedErrors.has(err.key) || (stack && stack.length < 2)
    return (
      <li className="HTMLErrorBox-error" key={'e' + index}>
        <span className="HTMLErrorBox-name">{err.name}</span>
        {': '}
        <span className="HTMLErrorBox-message">{err.message}</span>
        {stack && !stackIsBoring && (
          <ul className="HTMLErrorBox-stack">
            {expand ? (
              stack.map((frame, index) => this._renderErrorStackLine(frame, index))
            ) : (
              <>
                {this._renderErrorStackLine(stack[0], 0)}
                <li className="HTMLErrorBox-stackLine" key="showMore">
                  <a
                    className="HTMLErrorBox-showMore"
                    href="#"
                    onClick={this.handleExpandErrorClick.bind(this, err.key)}
                  >
                    Show more...
                  </a>
                </li>
              </>
            )}
          </ul>
        )}
      </li>
    )
  }

  _renderFileName(fileName, isNative) {
    if (isNative) return <span className="HTMLErrorBox-filenameNative">[native code]</span>
    if (fileNameIsPad(fileName)) return <span className="HTMLErrorBox-filenamePad">[pad]</span>
    if (fileName && fileName.includes('://'))
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={fileName}
          className="HTMLErrorBox-filenameUrl"
        >
          <span className="HTMLErrorBox-filenameUrlDirname">...</span>
          <span className="HTMLErrorBox-filenameUrlBasename">
            {fileName.substr(fileName.lastIndexOf('/'))}
          </span>
        </a>
      )
    return <span className="HTMLErrorBox-filename">{fileName}</span>
  }

  _renderErrorStackLine(frame, index) {
    const showLineNumber = !fileNameIsPad(frame.fileName) && frame.lineNumber != null
    return (
      <li className="HTMLErrorBox-stackLine" key={`sl${index}`}>
        {this._renderFileName(frame.fileName, frame.isNative)}
        {frame.functionName && (
          <>
            {' '}
            in <span className="HTMLErrorBox-functionName">{frame.functionName}</span>
          </>
        )}
        {showLineNumber && (
          <>
            {' '}
            at{' '}
            <span className="HTMLErrorBox-lineAndColumn">
              {frame.lineNumber}
              {frame.columnNumber != null && `:${frame.columnNumber}`}
            </span>
          </>
        )}
      </li>
    )
  }

  render() {
    const { errors } = this.props
    if (errors.length === 0) return null
    return (
      <div className="HTMLErrorBox">
        <h3 className="HTMLErrorBox-header">
          <span className="HTMLErrorBox-headerText">Errors</span>
          <a className="HTMLErrorBox-dismiss" href="#" onClick={this.handleDismissClick}>
            Dismiss
          </a>
        </h3>
        <ul className="HTMLErrorBox-list" ref={this._list}>
          {errors.map((err, idx) => this._renderError(err, idx))}
        </ul>
      </div>
    )
  }
}

function fileNameIsPad(fileName) {
  // Usually the filename is "about:srcdoc" if the error came from the pad contents.
  //
  // We want to test for this so we can show "[pad]" instead, and hide the line number,
  // which will be wrong due to our HTML and JS transforms.
  //
  // Also, when using Babel, we end up with something like
  // "about:srcdoc line 24 > scriptElement" for the filename.
  return fileName && fileName.startsWith('about:srcdoc')
}
