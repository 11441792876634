export default {
  dark: {
    background: '#1d2126',
  },
  light: {
    foreground: '#383a42',
    background: '#fafafa',
    cursor: '#383a42',
    cursorAccent: '#ff00ff',
    selection: '#e5e5e685',
    black: '#232324',
    red: '#e45649',
    green: '#50a14f',
    yellow: '#c18401',
    blue: '#4078f2',
    magenta: '#a626a4',
    cyan: '#0184bc',
    brightCyan: '#0184bc',
    brightRed: '#e45649',
    brightGreen: '#50a14f',
    brightYellow: '#c18401',
    brightBlue: '#4078f2',
    brightMagenta: '#a626a4',
  },
}
