import { MoreHoriz } from '@mui/icons-material'
import { Divider, IconButton, Menu, Stack, Theme, useMediaQuery } from '@mui/material'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { Children, ReactNode, RefObject, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectIsSandboxShowcaseInterviewer } from './selectors'
import TitlePrompt from './title_prompt'

const PadFooterRight = ({
  footerRef,
  children,
}: {
  footerRef: RefObject<HTMLDivElement>
  children?: ReactNode
}) => {
  const { isOwner, isSandbox } = usePadConfigValues(
    'invisible',
    'isOwner',
    'hasEnvironments',
    'isSandbox'
  )
  const isSandboxShowcaseInterviewer = useSelector(selectIsSandboxShowcaseInterviewer)
  const medium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null)
  const open = Boolean(anchorEl)
  const showTitle = (!isSandbox && isOwner) || isSandboxShowcaseInterviewer
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(footerRef.current)
  }
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {(showTitle ? medium : small) ? (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            data-testid="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ position: 'sticky', left: 0 }}
          >
            <MoreHoriz sx={{ width: 20, height: 20 }} />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            PaperProps={{
              sx: {
                background: (theme) => theme.palette.footer.backgroundColor,
                boxShadow: 'none',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: -4,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" mx={2.5}>
              {showTitle && <TitlePrompt />}
              {small ? (
                <>
                  {showTitle && <Divider orientation="vertical" flexItem />}
                  {children}
                </>
              ) : null}
            </Stack>
          </Menu>
        </>
      ) : (
        <>{showTitle ? <TitlePrompt /> : null}</>
      )}

      {small
        ? null
        : Children.map(children, (child) => {
            return child
          })}
    </Stack>
  )
}

export default PadFooterRight
