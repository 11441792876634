import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { put, select, takeEvery } from 'redux-saga/effects'

import { fileIdToPath, pathToFileId } from '../../../utils/multifile'
import padConfig from '../pad_config'
import { selectMultifileEnabled } from '../selectors'
import SyncHandle from '../sync_handle'

export default function* filesSaga() {
  const syncHandle = SyncHandle.findOrInit()
  const fileIdsRef = syncHandle.firepadRef.child('fileIds')
  const filesRef = syncHandle.firepadRef.child('files')

  const fileEventsChan = eventChannel((emit) => {
    const handleFileIdChange = (fileIdSnap) => {
      const visible = fileIdSnap.val()
      if (visible) {
        emit(['file_added', fileIdSnap.key])
      } else {
        emit(['file_removed', fileIdSnap.key])
      }
    }
    fileIdsRef.on('child_added', handleFileIdChange)
    fileIdsRef.on('child_changed', handleFileIdChange)
    return () => {} // This channel remains open for entire session, don't bother to cleanup
  })

  function* addLanguageFiles(language, useDefaultText = true) {
    const langFiles = CoderPad.LANGUAGES[language]?.files || []
    for (const langFile of langFiles) {
      const fileId = pathToFileId(langFile.path)
      const existingFile = yield select((state) => state.files[fileId])
      if (existingFile) {
        continue
      }

      yield put({
        type: 'file_added',
        file: {
          ...langFile,
          fileId,
          // HACK: See explanation in pad_setting_changed language remote
          defaultText: useDefaultText ? langFile.example : null,
        },
      })
      fileIdsRef.update({ [fileId]: true })
      filesRef.update({ [`${fileId}/path`]: langFile.path })
    }
  }

  // This just hides files. Content is restored when the files are readded.
  function removeFile(fileId) {
    fileIdsRef.update({ [fileId]: false })
  }

  yield takeEvery(fileEventsChan, function* ([type, data]) {
    switch (type) {
      case 'file_added': {
        // We detected multifile content in Firebase.
        // Check to see if the current language supports multifile.
        const language = yield select((state) => state.padSettings.language)
        const langFiles = CoderPad.LANGUAGES[language].files
        if (!langFiles) {
          return
        }

        const fileId = data
        const existingFile = yield select((state) => state.files[fileId])
        if (existingFile) {
          return
        }

        // HACK: Assume each language has a hardcoded set of files.
        // Match Firebase file paths with the language's file paths, and
        // add file objects to the local store. This will mount PadFileEditor
        // which syncs Firebase file content.
        const path = fileIdToPath(fileId)
        const langFile = _.find(langFiles, ['path', path])
        yield put({
          type: 'file_added',
          file: {
            ...langFile,
            fileId,
          },
        })
        break
      }
      case 'file_removed': {
        yield put({
          type: 'file_removed',
          fileId: data,
        })
      }
    }
  })

  // Listen for client-side actions where we should add multi-files.
  // In sandbox pads we don't set Firebase multifiles in the controller,
  // so set it here
  if (padConfig.isSandbox && !padConfig.question) {
    yield addLanguageFiles(padConfig.lang)
  }

  yield takeEvery('pad_setting_changed', function* ({ key, value, remote }) {
    if (key === 'language') {
      if (remote) {
        return
      }

      // HACK: New Firepad's defaultText option (starter code) gets sent
      // from all clients, causing duplicate copies. To get around this,
      // only the original user who triggered the language change
      // sets the files's example starter codes.
      const useDefaultText = !remote

      yield addLanguageFiles(value, useDefaultText)
    }
  })

  yield takeEvery('package_changed', function* ({ fileContents, language, legacySingleFile }) {
    // Interviewer selected Legacy Single File in the modal
    const files = yield select((state) => state.files)
    _.each(files, (file, fileId) => {
      if (fileId === '0_global' || fileId === '_language') {
        return
      }
      removeFile(fileId)
    })
  })

  yield takeEvery(
    'question_selected_by_local_user',
    function* ({ fileContents, language, legacySingleFile }) {
      // Interviewer selected Legacy Single File in the modal
      if (legacySingleFile && (yield select(selectMultifileEnabled))) {
        const files = yield select((state) => state.files)
        _.each(files, (file, fileId) => {
          if (fileId === '0_global' || fileId === '_language') {
            return
          }

          // we need to clear out the contents of the non-global files here
          const firebasePath = `files/${fileId}`
          const headlessFirepad = SyncHandle().firepadHeadless(firebasePath)
          headlessFirepad.on('ready', () => {
            headlessFirepad.setText('')
            headlessFirepad.dispose()
          })
          removeFile(fileId)
        })
      }

      if (!fileContents) {
        return
      }

      const questionFiles = _.keyBy(fileContents, 'path') || {}
      const langFiles = CoderPad.LANGUAGES[language]?.files || []
      for (const langFile of langFiles) {
        const fileId = pathToFileId(langFile.path)
        const existingFile = yield select((state) => state.files[fileId])
        if (existingFile) {
          continue
        }

        yield put({
          type: 'file_added',
          file: {
            ...langFile,
            defaultText: questionFiles[langFile.path]?.contents || langFile.example,
            fileId,
          },
        })
        fileIdsRef.child(fileId).set(true)
      }
    }
  )
}
