import { MenuItem, Select, SelectProps } from '@mui/material'
import React from 'react'

import { QuestionDifficulty } from '../../../graphql/types'

const { Easy, Medium, Hard } = QuestionDifficulty
export const difficulties: QuestionDifficulty[] = [Easy, Medium, Hard]

export const DifficultySelect: React.FC<SelectProps> = function (props) {
  return (
    <Select variant="outlined" margin="dense" size="small" {...props}>
      <MenuItem value="any">Any difficulty</MenuItem>
      {difficulties.map((difficulty) => (
        <MenuItem value={difficulty} key={difficulty}>
          {difficulty}
        </MenuItem>
      ))}
    </Select>
  )
}
