import { Box, Button, FormGroup, MenuItem, Select, styled, TextField } from '@mui/material'
import React, { FC } from 'react'

import { CancelableButton } from '../../../components/CancelableButton/CancelableButton'
import { RequestMethod } from '../../RequestClientContext/types'

const StyledFormGroup = styled(FormGroup)({
  display: 'inline-flex',
  flexDirection: 'row',
  flex: 1,
})

const StyledTextField = styled(TextField)({
  flex: 1,
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledSelect = styled(Select)({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  width: 115,
})

export interface RequestClientAddressBarProps {
  method: RequestMethod
  onChangeMethod: (method: RequestMethod) => void
  url?: string
  onChangeUrl: (url: string) => void
  onSend: () => void
  onClear: () => void
  readOnly?: boolean
  isWorking?: boolean
  doRequestCancel?: () => void
}

export const RequestClientAddressBar: FC<RequestClientAddressBarProps> = ({
  method,
  url,
  onChangeMethod,
  onChangeUrl,
  onSend,
  onClear,
  readOnly = false,
  isWorking = false,
  doRequestCancel = () => {},
}) => {
  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap">
      <StyledFormGroup row>
        <StyledSelect
          value={method}
          size="small"
          onChange={(e) => onChangeMethod(e.target.value as RequestMethod)}
          readOnly={readOnly}
        >
          {Object.values(RequestMethod).map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledTextField
          size="small"
          value={url}
          onChange={(e) => onChangeUrl(e.target.value)}
          disabled={readOnly}
          aria-readonly={readOnly}
        />
      </StyledFormGroup>
      {!readOnly && (
        <>
          <CancelableButton
            variant="contained"
            color="primary"
            onClick={onSend}
            sx={{ marginLeft: 2 }}
            isWorking={isWorking}
            onCancel={doRequestCancel}
          >
            Send
          </CancelableButton>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={onClear}
            sx={{ marginLeft: 2 }}
          >
            Clear
          </Button>
        </>
      )}
    </Box>
  )
}
