import _ from 'lodash'

import { pathToFileId } from '../../../utils/multifile'
import padConfig from '../pad_config'

function getDefaultState() {
  return {
    _language: padConfig.lang,
    '0_global': {
      id: '0_global',
      editor: null,
      path: CoderPad.LANGUAGES[padConfig.lang]?.global_file_path,
      label: CoderPad.LANGUAGES[padConfig.lang]?.global_file_label,
      language: padConfig.lang,
      users: {},
    },
  }
}

export default function filesReducer(state = getDefaultState(), action) {
  switch (action.type) {
    case 'editor_mounted': {
      const fileId = action.fileId || '0_global'
      return {
        ...state,
        [fileId]: {
          ...state[fileId],
          editor: action.editor,
        },
      }
    }
    case 'file_added': {
      return sortFiles({
        ...state,
        [action.file.fileId]: action.file,
      })
    }
    case 'file_removed': {
      if (!state[action.fileId]) {
        return state
      }

      const newState = { ...state }
      delete newState[action.fileId]
      return newState
    }
    case 'pad_setting_changed': {
      if (action.key === 'language') {
        // Just change the global file data.
        // Files saga will handle multifile events.
        return updateLanguageAndGlobalFile(state, action.value)
      }
      break
    }
    case 'question_selected_by_local_user': {
      if (!action.language) {
        return state
      }

      return updateLanguageAndGlobalFile(state, action.language)
    }
    case 'file_user_cursor_updated': {
      const [userId, color] = action.eventArgs // see firepad.js
      const { displayLine } = action
      const newState = _.mapValues(state, (file) => {
        return {
          ...file,
          users: _.omit(file.users, userId),
        }
      })
      newState[action.fileId].users[userId] = { color, displayLine }
      return newState
    }
  }
  return state
}

function updateLanguageAndGlobalFile(state, language) {
  const { global_file_label: label, global_file_path: path } = CoderPad.LANGUAGES[language]
  return {
    ...state,
    _language: language,
    '0_global': {
      ...(state['0_global'] || {}),
      label,
      language,
      path,
    },
  }
}

// kind of a hack because firebase objects are unordered but we want JS to appear above CSS
// sorted files will appear at the end
function sortFiles(state) {
  const newState = { ...state }

  const langFiles = CoderPad.LANGUAGES[state._language]?.files
  _.each(langFiles, (file) => {
    const fileId = pathToFileId(file.path)
    const stateFile = newState[fileId]
    if (stateFile) {
      delete newState[fileId]
      newState[fileId] = stateFile
    }
  })

  return newState
}
