import firebase from 'firebase/compat/app'
import { isEqual, omit } from 'lodash'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { DataTableColumns } from 'packs/main/components/DataTable/DataTable'
import { useEnvironments } from 'packs/main/Environments/EnvironmentsContext/EnvironmentsContext'
import SyncHandle from 'packs/main/sync_handle'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Cookie } from 'tough-cookie'
import { v4 as uuid } from 'uuid'

import { DataTableData, useDataTableData } from '../../components/DataTable/useDataTableData'
import { useRequestClient } from '../RequestClientContext/RequestClientContext'
import {
  RequestConfig,
  RequestMethod,
  RequestStatus,
  RequestSummary,
} from '../RequestClientContext/types'
import { useRequestClientSelected } from '../RequestClientSelectedContext/RequestClientSelectedContext'
import { getContentTypeHeader } from '../utils'

export const BodyTypes = {
  JSON: 'application/json',
  Form: 'application/x-www-form-urlencoded',
  Text: 'text/plain',
} as const

interface RequestClientConfigurationContextContract {
  method: RequestMethod
  setMethod: (method: RequestMethod) => void
  path: string
  setPath: (path: string, propagateRows?: boolean) => void
  performRequest: () => void
  cookiesData: DataTableData
  headersData: DataTableData
  paramsData: DataTableData
  bodyFormData: DataTableData
  bodyText: string
  setBodyText: (body: string) => void
  bodyType: string
  setBodyType: (bodyType: string) => void
  clearForm: () => void
  defaultCookieDomain: string
}

const initialDataTableData: DataTableData = {
  columns: [],
  rows: [],
  setRows: () => {},
  handleAddRow: () => {},
  handleDeleteRow: () => {},
  onRowEdit: () => ({}),
  getRow: () => ({}),
  resetData: () => {},
}

const standardColumns: DataTableColumns = [
  { field: 'name', headerName: 'Name', width: 200, editable: true, sortable: false },
  { field: 'value', headerName: 'Value', flex: 1, editable: true, sortable: false },
]

const cookieColumns: DataTableColumns = [
  { field: 'key', headerName: 'Name', editable: true, sortable: false },
  { field: 'value', headerName: 'Value', flex: 1, editable: true, sortable: false },
  { field: 'expires', headerName: 'Expires', editable: true, sortable: false, type: 'date' },
  {
    field: 'domain',
    headerName: 'Domain',
    editable: true,
    sortable: false,
  },
  { field: 'path', headerName: 'Path', editable: true, sortable: false },
]

export const RequestClientConfigurationContext = React.createContext<RequestClientConfigurationContextContract>(
  {
    method: RequestMethod.Get,
    setMethod: () => {},
    path: '',
    setPath: () => {},
    performRequest: () => {},
    cookiesData: initialDataTableData,
    headersData: initialDataTableData,
    paramsData: initialDataTableData,
    bodyFormData: initialDataTableData,
    bodyText: '',
    setBodyText: () => {},
    bodyType: 'JSON',
    setBodyType: () => {},
    clearForm: () => {},
    defaultCookieDomain: '',
  }
)
const initialRows = [{ id: uuid(), name: '', value: '' }]

export const RequestClientConfigurationContextProvider: React.FC = ({ children }) => {
  const isPlayback = usePadConfigValue('isPlayback')
  const { activeEnvironment } = useEnvironments()
  const {
    sendRequest,
    cookieJar,
    setCookie,
    clearExpiredCookies,
    requestBaseUrl,
    onSelectHistoricalRequest,
    currentlyViewingRequestId,
  } = useRequestClient()
  const [method, setMethod] = useState<RequestMethod>(RequestMethod.Get)
  const [path, setRawPath] = useState('')
  const [bodyType, setBodyType] = useState('JSON')
  const [bodyText, setBodyText] = useState('')
  const lastViewedRequest = useRef<RequestConfig | undefined>()
  const { currentlyViewingRequest, isDirty, setIsDirty } = useRequestClientSelected()
  const [cookieJarNeedsSync, setCookieJarNeedsSync] = useState(true)

  const defaultCookieDomain = useMemo(() => requestBaseUrl.replace('https://', '') || '', [
    requestBaseUrl,
  ])

  const defaultTableOptions = useMemo(
    () => ({
      onMutation: () => setIsDirty(true),
    }),
    [setIsDirty]
  )

  const cookiesData = useDataTableData(cookieColumns, [
    {
      id: uuid(),
      key: '',
      value: '',
      expires: undefined,
      domain: defaultCookieDomain,
      path: '',
    },
  ])
  const bodyFormData = useDataTableData(standardColumns, initialRows, defaultTableOptions)
  const headersData = useDataTableData(
    standardColumns,
    [{ id: 'content-type', name: 'Content-Type', value: 'application/json' }],
    defaultTableOptions
  )
  const paramsData = useDataTableData(standardColumns, initialRows, defaultTableOptions)
  const bodyHeader = useMemo(() => BodyTypes[bodyType], [bodyType])

  /**
   * Watch the currently selected request summary, and
   * enqueues cookie jar syncs when the request is done.
   */
  useEffect(() => {
    if (isPlayback) return
    let watcher: (snap: firebase.database.DataSnapshot) => void
    if (activeEnvironment?.slug && currentlyViewingRequestId) {
      watcher = SyncHandle().watch(
        `environmentsData/${activeEnvironment.slug}/requestSummaries/${currentlyViewingRequestId}`,
        (summary: RequestSummary) => {
          if (summary?.status === RequestStatus.Done) {
            setCookieJarNeedsSync(true)
          }
        }
      )
    }

    return () => {
      if (watcher) {
        SyncHandle().off(
          `environmentsData/${activeEnvironment?.slug}/requestSummaries/${currentlyViewingRequestId}`,
          watcher
        )
      }
    }
  }, [activeEnvironment?.slug, currentlyViewingRequestId, isPlayback])

  const filteredCookies = useMemo(() => {
    return cookieJar
      .toJSON()
      .cookies.filter(
        (cookie) => !cookie.expires || new Date(cookie.expires).getTime() > Date.now()
      )
  }, [cookieJar])

  /**
   * If the state is dirty, we want to clear the last viewed request
   * so that we don't accidentally overwrite the user's changes when
   * they switch back to the request.
   */
  useEffect(() => {
    if (isDirty && lastViewedRequest.current) {
      lastViewedRequest.current = undefined
    }
  }, [isDirty])

  /**
   * Listens for cookie jar changes and updates the cookies table.
   * We're also ignoring expired cookies here, since they're functionally
   * deleted.
   */
  useEffect(() => {
    if (cookieJarNeedsSync) {
      if (filteredCookies.length) {
        cookiesData.setRows(
          filteredCookies.map((cookie) => ({
            ...cookie,
            id: uuid(),
            // The date picker is expecting a real Date object, not a string
            expires: cookie.expires ? new Date(cookie.expires) : undefined,
          }))
        )
      } else {
        // If there's no cookies in the jar, show the default rows
        cookiesData.resetData()
      }
      setCookieJarNeedsSync(false)
    }
  }, [filteredCookies, cookiesData, cookieJarNeedsSync])

  /**
   * This effect keeps the content-type header in sync with the body type select
   */
  useEffect(() => {
    const contentTypeHeader = headersData.getRow({
      name: 'Content-Type',
    })
    if (contentTypeHeader?.value !== bodyHeader) {
      // TODO: replace this insanity with something sensible
      setBodyType(
        Object.keys(BodyTypes).find((key) => BodyTypes[key] === contentTypeHeader?.value) || 'Text'
      )
    }
  }, [bodyHeader, headersData])

  /**
   * Resets all inputs and data tables to their default values.
   */
  const clearForm = useCallback(() => {
    setMethod(RequestMethod.Get)
    setRawPath('')
    cookiesData.resetData()
    headersData.resetData()
    paramsData.resetData()
    bodyFormData.resetData()
    setBodyText('')
    setBodyType('JSON')
    onSelectHistoricalRequest(null)
  }, [cookiesData, headersData, paramsData, bodyFormData, onSelectHistoricalRequest])

  const headers = useMemo(() => {
    const headers = headersData.rows.reduce((acc, row) => {
      acc[row.name] = row.value
      return acc
    }, {} as Record<string, string>)
    return headers
  }, [headersData.rows])

  const formDataString = useMemo(
    () =>
      bodyFormData.rows
        .map((row) => `${encodeURIComponent(row.name)}=${encodeURIComponent(row.value)}`)
        .join('&'),
    [bodyFormData.rows]
  )

  const body = useMemo(() => (bodyType === 'Form' ? formDataString : bodyText), [
    bodyType,
    bodyText,
    formDataString,
  ])

  const setPath = useCallback(
    (path: string, propagateRows = true) => {
      setRawPath(path)
      if (propagateRows) {
        if (path.includes('?')) {
          const queryString = path.replace(/.*\?/, '')
          const params = new URLSearchParams(queryString)
          const rows = Array.from(params.entries()).map(([name, value]) => ({
            id: uuid(),
            name,
            value,
          }))
          paramsData.setRows(rows)
        } else {
          paramsData.resetData()
        }
      }
    },
    [paramsData]
  )

  /**
   * Function for compiling and submitting the request. This code is responsible
   * for a few different things:
   *
   * - Writing the cookies to the cookie jar
   * - Enqueuing the request
   * - Clearing out all expired cookies from the cookie jar
   *
   * In tough-cookie, the concept of "deleting" a cookie, works by setting the
   * expiration date to a date in the past. This is not ideal for our use case,
   * since it's then possible to create a cookie with the same name and domain
   * as a deleted cookie, and it will mutate the deleted cookie instead of
   * creating a new one.
   *
   * To work around this, we first delete the cookies via tough-cookie before
   * sending the request, and then wiping all of the expired cookies from the
   * cookie jar after the request is sent.
   */
  const performRequest = useCallback(() => {
    cookiesData.rows.forEach((row) => {
      const cookieProps = omit(row, ['id'])
      const cookie = new Cookie(cookieProps)

      // This fixes the `toISOString` error when serializing the cookie.
      if (cookieProps.expires) {
        cookie.setExpires(cookieProps.expires)
      } else {
        cookie.setExpires('Infinity')
      }

      setCookie(cookie)
    })
    sendRequest({
      path,
      method,
      headers,
      body,
    })
    // clear all expired cookies after the requeset is enqueued
    clearExpiredCookies()
    setCookieJarNeedsSync(true)
    setIsDirty(false)
  }, [
    sendRequest,
    path,
    method,
    headers,
    body,
    cookiesData,
    setCookie,
    clearExpiredCookies,
    setIsDirty,
  ])

  /**
   * Checks for changes in the currently viewing request and updates the form if needed.
   * This effect checks for equality between the currently viewing request and the last
   * viewed request, to allow for mutations to the form even when a historical request
   * is submitted. These mutations do not modify the historical data.
   */
  useEffect(() => {
    if (
      !isDirty &&
      currentlyViewingRequest &&
      !isEqual(currentlyViewingRequest, lastViewedRequest.current)
    ) {
      lastViewedRequest.current = currentlyViewingRequest
      setMethod(currentlyViewingRequest.method)
      setPath(currentlyViewingRequest.path)
      headersData.setRows(
        Object.entries(currentlyViewingRequest.headers || {})
          .filter(([key]) => key !== 'cookie')
          .map(([key, value]) => ({
            id: uuid(),
            name: key,
            value,
          }))
      )
      const contentType = getContentTypeHeader(currentlyViewingRequest)
      // If the request has a form body, parse the url encoded body params into rows
      if (contentType === BodyTypes['Form']) {
        setBodyType('Form')
        const params = new URLSearchParams(currentlyViewingRequest.body || '')
        const rows = Array.from(params.entries()).map(([name, value]) => ({
          id: uuid(),
          name,
          value,
        }))
        bodyFormData.setRows(rows)
      } else {
        setBodyText(currentlyViewingRequest.body || '')
      }
      setIsDirty(false)
    }
  }, [currentlyViewingRequest, headersData, setPath, bodyFormData, setIsDirty, isDirty])

  const contextValue = useMemo(() => {
    return {
      method,
      setMethod,
      path,
      setPath,
      performRequest,
      cookiesData,
      headersData,
      paramsData,
      bodyFormData,
      bodyText,
      setBodyText,
      bodyType,
      setBodyType,
      clearForm,
      defaultCookieDomain,
    }
  }, [
    method,
    setMethod,
    path,
    setPath,
    performRequest,
    cookiesData,
    headersData,
    paramsData,
    bodyFormData,
    bodyText,
    setBodyText,
    bodyType,
    setBodyType,
    clearForm,
    defaultCookieDomain,
  ])

  return (
    <RequestClientConfigurationContext.Provider value={contextValue}>
      {children}
    </RequestClientConfigurationContext.Provider>
  )
}

export function useRequestClientConfiguration() {
  const contextVal = useContext(RequestClientConfigurationContext)

  if (contextVal == null) {
    throw new Error(
      '`useRequestClientConfiguration` hook must be a descendant of a `RequestClientConfigurationContextProvider`'
    )
  }

  return contextVal
}
