import * as Sentry from '@sentry/browser'
import _ from 'lodash'

/*
Possible call statuses:
  no_call
    There isn't a call, nor are you considering an invite to one, nor is one
    pending, nor is an error being displayed related to a call.
    May transition to: invited, pending
  invited
    You got invited to a call and are being prompted to join or decline.
    May transition to: pending, error(?), no_call
  pending
    You either asked to start a call or accepted an invite. You are on a screen
    which lets you select devices and preview what your webcam and mic are
    picking up.
    We might not yet have permissions to access the webcam/mic, and if those
    aren't granted, or if we encounter an error once we get them, you'll go to
    the error state.
    May transition to: no_call, in_call, error, transcription_consent
  transcription_consent
    You have attempted to join the call, but transcription consent is required.
    May transition to: in_call
  in_call
    You're in a call. Display can be maximized (modal that blocks whole screen)
    or minimized (smaller, floating window that can be dragged and
    repositioned).
    May transition to: no_call, error
  error
    An error is being displayed. Depending on the type of error, there may be a
    button to attempt to rejoin the call.
    May transition to: no_call, in_call
*/

const DEFAULT_STATE = {
  status: 'no_call',
  twilioRoomId: null, // A string that Twilio calls an "SID", for its room instance.

  // Only for status 'pending'
  fromInvite: false, // Did local user accept an invite? (Vs "start call")
  requestingDevices: false, // Are we currently requesting A/V device access?
  enumeratedDevices: [], // Caches result of navigator.mediaDevices.enumerateDevices()
  enumerateFailed: false, // Failed to enumerate devices

  // Only for statuses 'pending' and 'error'
  isJoining: false, // Are we in the process of (re)joining/creating the call?

  // Only for statuses 'pending' and 'in_call'
  // todo: How do we persist preferred devices in a cookie or similar?
  // and should this remain valid for all statuses so we resume same devices if
  // rejoining call?
  // Note: This is *not* a Twilio SID, but a device ID specific to the local
  // machine, as enumerated and consumed by browser APIs.
  audioDeviceId: null,
  audioDeviceState: 'closed', // 'closed', 'opening', 'open', or 'error'
  videoDeviceId: null,
  videoDeviceState: 'closed', // 'closed', 'opening', 'open', or 'error'

  // Only for status 'in_call'
  maximized: true, // Modal over whole pad? (Vs floating box you can drag around)
  ccEnabled: false,
  audioMuted: false,
  videoMuted: false,
  // Maps Twilio participant "identity," which in general corresponds to the CoderPad
  // session "user ID" as seen in the user state reducer, to an object with:
  // {
  //   participantId: 'Twilio SID for participant',
  //   audioTrackId: 'Twilio SID for audio track if any',
  //   videoTrackId: 'Twilio SID for video track if any',
  // }
  twilioUsers: {},
  networkQualityLevel: 5,
  networkQualityStats: null,
  videoDegraded: false,

  // Only for status 'error'
  errorText: null, // optional string: text to show user
  errorCode: null, // internal use string that may provide extra info/links
  mayReconnect: false,

  hasConsentedToTranscription: false,
}

export default function callReducer(state = DEFAULT_STATE, action) {
  // Dev-only helper for checking valid state transition.
  const assertStatus = __DEV__
    ? (validStatuses) => {
        if (!validStatuses.includes(state.status)) {
          const e = new Error(`Invalid state ${state.status}, expected: ${validStatuses.join(',')}`)
          console.error(e)
        }
      }
    : () => null

  switch (action.type) {
    case 'invited_to_call':
      assertStatus(['no_call'])
      return {
        ...state,
        status: 'invited',
        twilioRoomId: action.twilioRoomId,
      }
    case 'invite_declined':
      assertStatus(['invited'])
      return {
        ...state,
        status: 'no_call',
      }
    case 'intent_to_start_call_expressed':
    case 'invite_accepted':
    case 'reconnect_to_call_clicked':
      assertStatus(['no_call', 'invited', 'error'])
      return {
        ...state,
        status: 'pending',
        audioDeviceId: null,
        videoDeviceId: null,
        audioDeviceState: 'closed',
        videoDeviceState: 'closed',
        enumeratedDevices: [],
        enumerateFailed: false,
        fromInvite: action.type === 'invite_accepted',
        requestingDevices: true,
        isJoining: false,
      }
    case 'local_tracks_created':
      assertStatus(['pending'])
      return {
        ...state,
        audioDeviceId: action.audioDeviceId,
        audioDeviceState: action.audioDeviceId ? 'open' : 'closed',
        videoDeviceId: action.videoDeviceId,
        videoDeviceState: action.videoDeviceId ? 'open' : 'closed',
        requestingDevices: false,
      }
    case 'devices_enumerated':
      assertStatus(['pending'])
      return {
        ...state,
        enumeratedDevices: action.devices,
        enumerateFailed: false,
      }
    case 'device_enumerate_failed':
      assertStatus(['pending'])
      return {
        ...state,
        enumerateFailed: true,
      }
    case 'device_switched': {
      assertStatus(['pending'])
      // For greppability:
      // Can modify audioDeviceId, videoDeviceId, audioDeviceState, videoDeviceState
      return {
        ...state,
        [action.kind + 'DeviceId']: action.deviceId,
        [action.kind + 'DeviceState']: 'opening',
      }
    }
    case 'device_switch_succeeded':
      assertStatus(['pending'])
      // For greppability:
      // Can modify audioDeviceState, videoDeviceState
      return {
        ...state,
        [action.kind + 'DeviceState']: action.deviceId === '____no_video' ? 'closed' : 'open',
      }
    case 'device_switch_failed':
      assertStatus(['pending'])
      // For greppability:
      // Can modify audioDeviceState, videoDeviceState
      return {
        ...state,
        [action.kind + 'DeviceState']: 'error',
      }
    case 'call_join_requested':
      assertStatus(['pending', 'transcription_consent', 'error'])
      return {
        ...state,
        isJoining: true,
      }
    case 'call_transcription_consent_requested': {
      return {
        ...state,
        status: 'transcription_consent',
      }
    }
    case 'call_joined':
      assertStatus(['pending', 'transcription_consent', 'error'])
      // Note: This action is not just used for joining from an invite, but
      // also for joining a call that the local user created via "Start Call"
      return {
        ...state,
        status: 'in_call',
        twilioRoomId: action.twilioRoomId,
        twilioUsers: action.twilioUsers,
        maximized: true,
        audioMuted: false,
        videoMuted: false,
      }
    case 'user_joined_call':
      assertStatus(['in_call'])
      return {
        ...state,
        twilioUsers: {
          ...state.twilioUsers,
          [action.userId]: {
            ...state.twilioUsers[action.userId],
            participantId: action.twilioParticipantId,
          },
        },
      }
    case 'call_track_subscribed':
      // Ensure corresponding user exists
      if (!Object.hasOwnProperty.call(state.twilioUsers, action.userId)) {
        const msg = `unexpected call participant identity ${action.userId}`
        if (__DEV__) console.error(msg)
        Sentry.captureException(new Error(msg))
        return state
      }
      // Add twilio track ID to user
      return {
        ...state,
        twilioUsers: {
          ...state.twilioUsers,
          [action.userId]: {
            ...state.twilioUsers[action.userId],
            [action.kind === 'video' ? 'videoTrackId' : 'audioTrackId']: action.twilioTrackId,
          },
        },
      }
    case 'call_track_unsubscribed':
      if (!Object.hasOwnProperty.call(state.twilioUsers, action.userId)) return state

      // Remove twilio ID and track IDs from the user
      return {
        ...state,
        twilioUsers: {
          ...state.twilioUsers,
          [action.userId]: {
            ...state.twilioUsers[action.userId],
            [action.kind === 'video' ? 'videoTrackId' : 'audioTrackId']: null,
          },
        },
      }
    case 'user_left_call':
      return {
        ...state,
        twilioUsers: _.omit(state.twilioUsers, action.userId),
      }
    case 'call_minimized':
      assertStatus(['in_call'])
      return { ...state, maximized: false }
    case 'call_maximized':
      assertStatus(['in_call'])
      return { ...state, maximized: true }
    case 'mute_clicked':
      return {
        ...state,
        [action.kind === 'video' ? 'videoMuted' : 'audioMuted']: !action.enabled,
      }
    case 'focus_time_toggled':
      return {
        ...state,
        audioMuted: action.value,
        videoMuted: action.value,
      }
    case 'call_dropped':
      assertStatus(['in_call'])
      return {
        ...state,
        status: 'error',
        errorText: 'The call was dropped. Check your internet connection and try again.',
        errorCode: 'call_drop',
        mayReconnect: true,
        isJoining: false,
      }
    case 'permissions_denied':
      assertStatus(['pending'])
      return {
        ...state,
        status: 'error',
        errorText: "We don't have permission to access your webcam and mic.",
        errorCode: 'no_permission',
        mayReconnect: false,
        isJoining: false,
      }
    case 'video_errored': // Generic error type
      assertStatus(['invited', 'pending', 'in_call'])
      return {
        ...state,
        status: 'error',
        errorText: action.errorText || 'Sorry, something went wrong setting up video.',
        errorCode: 'other',
        mayReconnect: false,
        isJoining: false,
      }
    case 'call_canceled':
    case 'call_ended':
    case 'call_error_dismissed':
      assertStatus(['pending', 'in_call', 'error'])
      return {
        ...state,
        status: 'no_call',
        hasConsentedToTranscription: false,
      }
    case 'network_quality_level_changed':
      assertStatus(['in_call'])
      return {
        ...state,
        networkQualityLevel: action.networkQualityLevel,
        networkQualityStats: action.networkQualityStats,
      }
    case 'network_quality_video_degraded':
      assertStatus(['in_call'])
      return {
        ...state,
        videoDegraded: true,
      }
    case 'user_consented_to_transcription': {
      return {
        ...state,
        hasConsentedToTranscription: true,
      }
    }
    case 'toggle_cc':
      assertStatus(['in_call'])
      return {
        ...state,
        ccEnabled: !state.ccEnabled,
      }
  }
  return state
}
