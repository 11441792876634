import { ProjectTab, updateVisibleTabs } from 'packs/main/reducers/projectTabs'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { visibleTabRequestClientToggled } from '../../reducers/tabs'
import { useEnvironments } from '../EnvironmentsContext/EnvironmentsContext'
import { useEnvironmentProjectTemplate } from './useEnvironmentProjectTemplate'
import { QuestionSummary } from './useEnvironmentQuestion'

/**
 * This is a clever way to backfill environment question information into the Redux store. Why? Because numerous parts
 * of the application use the info in the Redux store. Specifically, the right pane tabs and the execution saga are
 * particularly important.
 *
 * This hook listens for changes to the environment question and language, and dispatches Redux actions that will
 * update the corresponding information in the Redux store.
 */
export function useReduxAdapter(envQuestion: QuestionSummary | null) {
  const dispatch = useDispatch()
  const { activeEnvironment, isExecPossible } = useEnvironments()

  useEffect(() => {
    // Question takes precedence, in terms of what needs to be updated in the Redux store.
    if (activeEnvironment) {
      if (activeEnvironment.questionId === envQuestion?.id) {
        // Should have a question, so wait for the question to become available via `envQuestion`.
        dispatch({
          type: 'environment_question_changed',
          ...envQuestion,
          language: activeEnvironment.language,
          customDatabaseLanguage:
            envQuestion?.customDatabaseLanguage ?? activeEnvironment.customDatabaseLanguage ?? null,
          customFiles: envQuestion?.customFiles ?? [],
          projectTemplateSlug: activeEnvironment.projectTemplateSlug,
          projectTemplateVersion: activeEnvironment.projectTemplateVersion,
          environmentId: activeEnvironment.id,
          environmentSlug: activeEnvironment.slug,
          autofocus: true,
          spreadsheet: activeEnvironment.spreadsheet,
        })
      } else {
        dispatch({
          type: 'environment_question_changed',
          language: activeEnvironment.language,
          questionId: null,
          projectTemplateSlug: activeEnvironment.projectTemplateSlug,
          projectTemplateVersion: activeEnvironment.projectTemplateVersion,
          environmentId: activeEnvironment.id,
          environmentSlug: activeEnvironment.slug,
          customDatabaseId: null,
          customDatabaseLanguage: activeEnvironment.customDatabaseLanguage ?? null,
          customDatabaseSchema: null,
          customFiles: [],
          testCasesEnabled: false,
          starterCodeByLanguage: {},
          candidateInstructions: [],
          visibleTestCases: [],
          autofocus: true,
          spreadsheet: activeEnvironment.spreadsheet,
        })
      }
    }
  }, [activeEnvironment, dispatch, envQuestion])

  useEffect(() => {
    if (activeEnvironment?.customDatabaseLanguage) {
      dispatch({
        type: 'pad_setting_changed',
        key: 'customDatabaseLanguage',
        value: activeEnvironment.customDatabaseLanguage,
      })
    }
  }, [activeEnvironment?.customDatabaseLanguage, dispatch])

  useEffect(() => {
    if (activeEnvironment?.language) {
      dispatch({
        type: 'pad_setting_changed',
        key: 'language',
        value: activeEnvironment.language,
      })
    }
  }, [activeEnvironment?.language, dispatch])

  useEffect(() => {
    if (activeEnvironment != null && isExecPossible) {
      dispatch({
        type: 'environment_changed',
        environment: activeEnvironment,
      })
    }
  }, [activeEnvironment, isExecPossible, dispatch])

  const { projectTemplate } = useEnvironmentProjectTemplate(activeEnvironment?.projectTemplateSlug)
  const requestClientEnabled = usePadConfigValue('requestClientEnabled')
  useEffect(() => {
    const isRunCommandProject = projectTemplate?.settings?.executionType === 'RunCommand'
    const requestClientAvailable =
      !isRunCommandProject && requestClientEnabled && projectTemplate?.category === 'backend'

    dispatch(
      updateVisibleTabs({
        [ProjectTab.MainProcess]: true,
        [ProjectTab.Shell]: !isRunCommandProject,
        [ProjectTab.Console]: !isRunCommandProject,
        [ProjectTab.RequestHistory]: requestClientAvailable,
      })
    )

    dispatch(visibleTabRequestClientToggled(requestClientAvailable))
  }, [dispatch, projectTemplate, requestClientEnabled])

  useEffect(() => {
    dispatch({
      type: 'pad_setting_changed',
      key: 'projectTemplateExecutionType',
      value: projectTemplate?.settings?.executionType ?? null,
    })
  }, [dispatch, projectTemplate])
}
