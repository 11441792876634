import { useCallback } from 'react'
import { Cookie, CookieJar } from 'tough-cookie'

import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import SyncHandle from '../../../sync_handle'

export function useSetCookie(cookieJar: CookieJar) {
  const { activeEnvironment } = useEnvironments()

  const setCookie = useCallback(
    (cookie: Cookie) => {
      if (activeEnvironment?.slug) {
        cookieJar.setCookieSync(cookie, `https://${cookie.domain}`)
        SyncHandle().set(`environmentsData/${activeEnvironment.slug}/cookieJar`, cookieJar.toJSON())
      }
    },
    [cookieJar, activeEnvironment?.slug]
  )

  return setCookie
}

export function useDeleteCookie(cookieJar: CookieJar) {
  const { activeEnvironment } = useEnvironments()

  const deleteCookie = useCallback(
    (cookie: Cookie) => {
      if (activeEnvironment?.slug) {
        cookie.setExpires(new Date(Date.now()))
        cookieJar.setCookieSync(cookie, `https://${cookie.domain}`)
        SyncHandle().set(`environmentsData/${activeEnvironment.slug}/cookieJar`, cookieJar.toJSON())
      }
    },
    [cookieJar, activeEnvironment?.slug]
  )

  return deleteCookie
}

export function useClearExpiredCookies(cookieJar: CookieJar) {
  const { activeEnvironment } = useEnvironments()

  const clearExpiredCookies = useCallback(() => {
    if (activeEnvironment?.slug) {
      cookieJar.getCookies('https://', (err, cookies) => {
        if (err) {
          console.error(err)
        } else {
          const jar = cookieJar.toJSON()
          jar.cookies = jar.cookies.filter(
            (cookie) => !cookie.expires || new Date(cookie.expires).getTime() > Date.now()
          )
          SyncHandle().set(`environmentsData/${activeEnvironment.slug}/cookieJar`, jar)
        }
      })
    }
  }, [cookieJar, activeEnvironment?.slug])

  return clearExpiredCookies
}
