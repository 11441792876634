import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../queryStates'
import { ProjectTemplate, ProjectTemplateCategory } from '../../types'

// Default fields + hook for normal project templates info. This is for backward
// compatibility and convenience to be able to get basic data for project templates.
// Note that `useProjectTemplates` now uses the `useProjectTemplatesHookBuilder`
// hook, with defaults to the default fields.
const DEFAULT_PROJECT_TEMPLATE_FIELDS = `
      id
      name
      slug
      category
      version
      latest
      settings
`

export interface IProjectTemplatesData {
  projectTemplates: ProjectTemplate[]
}

// Function to build up the query literals for use in the Apollo useQuery hooks.
export function buildProjectTemplateQuery(fieldFragment?: string) {
  return gql`
    query ProjectTemplates($padSlug: String, $category: ProjectTemplateCategory) {
      projectTemplates(padSlug: $padSlug, category: $category) {
        id
        ... on ProjectTemplate {
          ${fieldFragment ?? DEFAULT_PROJECT_TEMPLATE_FIELDS}
        }
      }
    }
  `
}

type DefaultProjectTemplateFields = Pick<
  ProjectTemplate,
  'id' | 'name' | 'slug' | 'category' | 'version' | 'latest' | 'settings'
>

type TemplatesFilters = {
  padSlug?: string
  category?: ProjectTemplateCategory
}

export function useProjectTemplates(filters?: TemplatesFilters) {
  const projectTemplatesInfo = useProjectTemplatesHookBuilder<DefaultProjectTemplateFields>(
    DEFAULT_PROJECT_TEMPLATE_FIELDS
  )(filters)

  return projectTemplatesInfo
}

// Query hook builder exported from `useProjectTemplates.ts`
export function useProjectTemplatesHookBuilder<T>(fieldFragment: string) {
  return useMemo(() => {
    const query = buildProjectTemplateQuery(fieldFragment)

    return function useProjectTemplates(filters?: TemplatesFilters) {
      const { data, error, loading } = useQuery<{ projectTemplates: T[] }>(query, {
        variables: {
          ...filters,
        },
      })

      const projectTemplates = data?.projectTemplates

      const status = useMemo(() => {
        if (loading) {
          return queryStates.loading()
        } else if (error) {
          return queryStates.error(
            `An error occurred while fetching Project Templates${
              error.message ? ': ' + error.message : ''
            }`
          )
        } else {
          return queryStates.success()
        }
      }, [error, loading])

      return useMemo(() => {
        return {
          projectTemplates: projectTemplates ?? [],
          status,
        }
      }, [projectTemplates, status])
    }
  }, [fieldFragment])
}
