import { gql, useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import * as queryStates from '../../../../../graphql/queryStates'
import { CreateQuestionFromEnvironmentPayload } from '../../../../../graphql/types'
import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { EnvironmentSummary } from '../../../Environments/EnvironmentsContext/types'
import { selectPadSettings } from '../../../selectors'

export const CREATE_QUESTION_FROM_ENVIRONMENT_MUTATION = gql`
  mutation CreateQuestionFromEnvironment($input: CreateQuestionFromEnvironmentInput!) {
    createQuestionFromEnvironment(input: $input) {
      question {
        id
      }
      errors {
        message
        path
      }
      message
    }
  }
`

export function useCreateQuestionFromEnvironment(
  environment: EnvironmentSummary | null,
  saveDrawing: boolean
) {
  const padSlug = usePadConfigValue('slug')
  const padTitle = useSelector(selectPadSettings).title
  const [create, { data, loading, error }] = useMutation<{
    createQuestionFromEnvironment: CreateQuestionFromEnvironmentPayload
  }>(CREATE_QUESTION_FROM_ENVIRONMENT_MUTATION, {
    variables: {
      input: {
        padSlug,
        environmentSlug: environment?.slug,
        title: `${environment?.nameDisplayable} question created from ${padTitle}`,
        saveDrawing,
      },
    },
  })

  const createQuestion = useCallback(() => {
    if (environment == null) {
      return
    }

    return create()
      .then(({ data }) => {
        const question = data?.createQuestionFromEnvironment?.question
        if (question != null) {
          window.open(`/dashboard/questions/drafts/${question.id}`)
          return question.id
        }
        return null
      })
      .catch(() => null)
  }, [environment, create])

  const status = useMemo(() => {
    return loading
      ? queryStates.loading()
      : error
      ? queryStates.error()
      : data?.createQuestionFromEnvironment?.question
      ? queryStates.success()
      : queryStates.initial()
  }, [loading, data, error])

  return { createQuestion, createQuestionLoading: loading, status }
}
