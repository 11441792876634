import { Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AnimatedActionButton,
  ButtonActions,
} from '../../../../components/AnimatedActionButton/AnimatedActionButton'
import { shortcutPrefix } from '../../../../util'

const SaveButton = () => {
  const { saving, serviceReady, autoSave } = useSelector((state) => {
    const saving = state.project.saving
    const autoSave = state.project.autoSave
    const serviceStarting = state.padSettings.serviceStatus === 'starting'
    const serviceReady = state.padSettings.serviceStatus === 'ready'
    return { saving, serviceStarting, serviceReady, autoSave }
  })

  const dispatch = useDispatch()
  const saveClick = useCallback(() => {
    dispatch({ type: 'saveFiles' })
  }, [dispatch])

  useEffect(() => {
    const saveOnHotkeys = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 's' || e.key === 'Enter') {
          saveClick()
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
    window.addEventListener('keydown', saveOnHotkeys, { capture: true })
    return () => {
      window.removeEventListener('keydown', saveOnHotkeys, { capture: true })
    }
  }, [saveClick])

  const actions = useMemo<ButtonActions>(() => {
    return [{ key: 'save', label: 'Save', perform: saveClick }]
  }, [saveClick])

  return (
    <Tooltip
      title={
        <>
          {'Shortcut: '}
          {shortcutPrefix}
          {' Enter or '}
          {shortcutPrefix}
          {' + S to save files'}
        </>
      }
      arrow
    >
      <span>
        <AnimatedActionButton
          actions={actions}
          isBusy={saving}
          disabled={saving || !serviceReady || autoSave}
        />
      </span>
    </Tooltip>
  )
}

export default SaveButton
