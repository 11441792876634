import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React, { memo } from 'react'

export interface PadSettingsLinkProps {
  buttonLabel: string
  href: string
  label: string
}

export const PadSettingsLink = memo<PadSettingsLinkProps>((props) => {
  const { buttonLabel, href, label } = props

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
      <Typography>{label}</Typography>
      <Box component="a" href={href} rel="noreferrer" target="_blank">
        <Button
          color="secondary"
          endIcon={<OpenInNew sx={{ height: 14, width: 14 }} />}
          size="small"
          variant="outlined"
        >
          {buttonLabel}
        </Button>
      </Box>
    </Stack>
  )
})
