import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff'
import _ from 'lodash'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC } from 'react'

import Modal from './modal'
import TwilioTrack from './twilio_track'
import CallQuality from './video/call_quality'
import { TwilioCaller } from './video/call_root'
import { CC } from './video/ClosedCaptions'
import VideoThumbnail from './video_thumbnail'

interface MaximizedCallProps {
  endCall: () => void
  minimize: () => void
  toggleCC: () => void
  muteAudio: () => void
  unmuteAudio: () => void
  muteVideo: () => void
  unmuteVideo: () => void
  ccEnabled: boolean
  callers: TwilioCaller[]
  audioMuted: boolean
  videoMuted: boolean
  networkQualityLevel: 1 | 2 | 3 | 4 | 5
}

const MaximizedCall: FC<MaximizedCallProps> = ({
  endCall,
  minimize,
  toggleCC,
  muteAudio,
  unmuteAudio,
  muteVideo,
  unmuteVideo,
  ccEnabled,
  callers,
  audioMuted,
  videoMuted,
  networkQualityLevel,
}) => {
  const hasTranscriptions = usePadConfigValue('hasTranscriptions')
  const [[currentUser], otherUsers] = _.partition(callers, 'self')

  //TODO think more carefully about what the correct behavior is here
  const maximizedUser = otherUsers[0] || currentUser
  const thumbnailUsers = callers.filter((u) => u !== maximizedUser)

  const rightSidebar = (
    <div className="right-sidebar">
      {thumbnailUsers.map((user) => (
        <VideoThumbnail user={user} key={user.id} />
      ))}
    </div>
  )

  const footer = (
    <div className="footer">
      <button onClick={minimize} className="minimize-call">
        Minimize
      </button>

      {otherUsers.length === 0 && <span>You're the only one on this call right now</span>}

      <div className="controls">
        {hasTranscriptions && (
          <button className={`cc ${ccEnabled ? 'enabled' : ''}`} onClick={toggleCC}>
            {ccEnabled ? <ClosedCaptionIcon /> : <ClosedCaptionOffIcon />}
          </button>
        )}

        {currentUser &&
          currentUser.audio &&
          (audioMuted ? (
            <button className="mute unmute" onClick={unmuteAudio}>
              Unmute
            </button>
          ) : (
            <button className="mute" onClick={muteAudio}>
              Mute
            </button>
          ))}

        {currentUser &&
          currentUser.video &&
          (videoMuted ? (
            <button className="hide unhide" onClick={unmuteVideo}>
              Show Video
            </button>
          ) : (
            <button className="hide" onClick={muteVideo}>
              Hide Video
            </button>
          ))}

        <button onClick={endCall} className="end-call">
          Leave Call
        </button>
      </div>
    </div>
  )

  return (
    <Modal padding={0} className="MaximizedCall-modalParent">
      <div className="MaximizedCall">
        <CC />
        {maximizedUser &&
          (maximizedUser.video ? (
            <TwilioTrack
              className="MaximizedCall-maximizedTrack"
              track={maximizedUser.video}
              kind="video"
            />
          ) : (
            <div className="MaximizedCall-noMaximizedVideoMessage">No Video</div>
          ))}
        {networkQualityLevel && (
          <span className="MaximizedCall-qualityBackground">
            <CallQuality size={40} quality={networkQualityLevel} />
          </span>
        )}
        {maximizedUser?.audio && <TwilioTrack track={maximizedUser.audio} kind="audio" />}
        {rightSidebar}
        {footer}
      </div>
    </Modal>
  )
}

export default MaximizedCall
