import React, { createContext, useContext, useMemo } from 'react'

import { useGetResourcesAuthToken } from './actions/useGetResourcesAuthToken'
import { defaultPadConfig, IPadConfig, PadConfigKeys } from './padConfig'

export interface IPadContext {
  config: IPadConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getConfigValue<K extends PadConfigKeys>(key: K): IPadConfig[K]
  getConfig: () => IPadConfig
  isDrawingPad: boolean
  getToken: (force?: boolean) => Promise<string>
}

export const PadContext = createContext<IPadContext>({
  config: defaultPadConfig,
  getConfigValue: (key) => defaultPadConfig[key],
  getConfig: () => defaultPadConfig,
  isDrawingPad: false,
  getToken: () => Promise.resolve(''),
})

export const PadContextProvider: React.FC<{ config: IPadConfig }> = ({ config, children }) => {
  const getToken = useGetResourcesAuthToken(config.slug)

  const contextVal = useMemo<IPadContext>(() => {
    return {
      config,
      getConfigValue: (key) => config[key],
      getConfig: () => config,
      isDrawingPad: config.uiType === 'drawing_only',
      getToken: (force?: boolean) => (force == null ? getToken() : getToken(true)),
    }
  }, [config, getToken])

  return <PadContext.Provider value={contextVal}>{children}</PadContext.Provider>
}

// Custom hook to get the context properties for the pad.
export function usePadContext() {
  const contextVal = useContext(PadContext)

  if (contextVal == null) {
    throw new Error('`usePadContext` hook must be a descendant of a `PadContextProvider`')
  }

  return contextVal
}

// Convenience hook for getting a singular pad config value from the pad context.
export function usePadConfigValue<K extends PadConfigKeys>(key: K): IPadConfig[K] {
  const { getConfigValue } = usePadContext()

  return getConfigValue(key)
}

export function usePadConfigValues<T extends PadConfigKeys>(...keys: T[]): Pick<IPadConfig, T> {
  const { config } = usePadContext()

  return keys.reduce((acc, key) => {
    acc[key] = config[key]
    return acc
  }, {} as Pick<IPadConfig, T>)
}
