import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionSummary, Box, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { CandidateInstructions } from '../../../../../graphql/types'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { InstructionDetails } from './InstructionDetails'
import { InstructionsContainer } from './InstructionsContainer'
import { InstructionStepAccordion } from './InstructionStepAccordion'
import { useCandidateInstructions } from './useCandidateInstructions'

export const CandidateInstructionsCandidate = ({
  hidden,
  candidateInstructions,
}: {
  hidden: boolean
  candidateInstructions: CandidateInstructions[]
}) => {
  const { candidateVisibilitySettings, expanded, handleExpand } = useCandidateInstructions(
    candidateInstructions
  )
  const { takeHome } = usePadConfigValues('takeHome')
  const dispatch = useDispatch()

  const candidateVisibilitySettingsWas = useRef<boolean[]>()
  useEffect(() => {
    if (!isEqual(candidateVisibilitySettingsWas.current, candidateVisibilitySettings)) {
      const lastVisibleStep = candidateVisibilitySettings.findLastIndex((setting) => setting)
      handleExpand(lastVisibleStep ?? 0)
      dispatch({ type: 'instructions_added', candidateVisibilitySettings })
      candidateVisibilitySettingsWas.current = candidateVisibilitySettings
    }
  }, [candidateVisibilitySettings, dispatch, handleExpand])

  const hideInstructionStep = useCallback(
    (stepIndex: number) => {
      if (takeHome) return false
      if (stepIndex === 0) return false
      if (candidateVisibilitySettings.length === 0) return false
      if (candidateVisibilitySettings[stepIndex]) return false

      return true
    },
    [candidateVisibilitySettings, takeHome]
  )

  if (hidden) {
    return null
  }

  return (
    <InstructionsContainer>
      {candidateInstructions.map((step: CandidateInstructions, index: number) => {
        if (hideInstructionStep(index)) {
          return null
        }

        return (
          <InstructionStepAccordion
            key={index}
            expanded={expanded[index] || false}
            handleExpand={() => handleExpand(index)}
          >
            {candidateInstructions.length === 1 ? (
              <Box mt={2} />
            ) : (
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={(theme) => ({
                  minHeight: theme.spacing(6),
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    marginTop: theme.spacing(1.5),
                    marginBottom: theme.spacing(1.5),
                  },
                  '&.MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: theme.spacing(6),
                  },
                })}
              >
                <Typography variant="body1" sx={{ lineHeight: '1.65em' }}>
                  Instructions Part {index + 1}
                </Typography>
              </AccordionSummary>
            )}

            <InstructionDetails hidden={hidden} instructions={step.instructions || ''} />
          </InstructionStepAccordion>
        )
      })}
    </InstructionsContainer>
  )
}
