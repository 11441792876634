import Monaco from '@codingame/monaco-editor-react'
import { Box, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback } from 'react'

import { DataTable } from '../../../components/DataTable/DataTable'
import { useRequestClientSelected } from '../../RequestClientSelectedContext/RequestClientSelectedContext'
import { BodyTypes, useRequestClientConfiguration } from '../RequestClientConfigurationContext'

const StyledSelect = styled(Select)({
  width: '100%',
  maxWidth: 116,
})

export const RequestClientBody: React.FC = () => {
  const {
    bodyType,
    setBodyType,
    bodyText,
    setBodyText,
    headersData,
    bodyFormData,
  } = useRequestClientConfiguration()
  const isPlayback = usePadConfigValue('isPlayback')
  const { setIsDirty } = useRequestClientSelected()

  const handleBodyTypeChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newBodyType = event.target.value as string
      setBodyType(newBodyType)

      const bodyHeader = BodyTypes[newBodyType]
      const contentTypeHeader = headersData.getRow({
        name: 'Content-Type',
      })
      if (contentTypeHeader) {
        if (contentTypeHeader.value !== bodyHeader) {
          headersData.onRowEdit({ ...contentTypeHeader, value: bodyHeader })
        }
      } else {
        // If the header row doesn't exist, we need to add a new one and set it's values
        headersData.handleAddRow({ name: 'Content-Type', value: bodyHeader })
      }
    },
    [headersData, setBodyType]
  )

  return (
    <>
      <StyledSelect size="small" value={bodyType} onChange={handleBodyTypeChange}>
        {Object.keys(BodyTypes).map((bodyType) => (
          <MenuItem key={bodyType} value={bodyType}>
            {bodyType}
          </MenuItem>
        ))}
      </StyledSelect>
      {bodyType === 'Form' ? (
        <div data-testid="request-client-body-form-data">
          <DataTable
            columns={bodyFormData.columns}
            rows={bodyFormData.rows}
            onRowEdit={bodyFormData.onRowEdit}
            onAddRow={bodyFormData.handleAddRow}
            onDeleteRow={bodyFormData.handleDeleteRow}
            readOnly={isPlayback}
          />
        </div>
      ) : (
        <Box
          sx={{
            flex: '1 1 auto',
          }}
          mt={2}
          data-testid="request-client-body-text"
        >
          <Monaco
            ref={(editor) => {
              if (editor && isPlayback) {
                editor.updateOptions({ readOnly: true })
              }
            }}
            programmingLanguage={bodyType === 'JSON' ? 'json' : 'text'}
            value={bodyText}
            onChange={(value) => {
              setBodyText(value)
              setIsDirty(true)
            }}
          />
        </Box>
      )}
    </>
  )
}
