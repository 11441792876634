import { ApolloProvider } from '@apollo/client'
import { Box } from '@mui/material'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/browser'
import { Organization, User } from 'graphql/types'
import cookies from 'js-cookie'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { buildApolloClient } from 'utils/buildApolloClient/buildApolloClient'

import { ThemeMode, useTheme } from '../../../theme/theme'
import { checkDashboardDarkMode, checkSharedNavFlag } from '../../../utils/checkFeatureFlag'
import { createFetcher } from '../../../utils/fetch/fetch'
import { FetchProvider } from '../../../utils/fetch/FetchProvider'
import { AppNotice } from '../../dashboard/components/AppNotice/AppNotice'
import {
  AppStateProvider,
  useAppStateProperty,
} from '../../dashboard/components/AppState/AppStateProvider'
import Footer from '../../dashboard/components/Footer/Footer'
import { Header } from '../../dashboard/components/Header/Header'
import SharedHeader from '../../dashboard/components/Header/SharedHeader'
import { PlanUpgradeDisplay } from '../../dashboard/components/PlanUpgradeDisplay/PlanUpgradeDisplay'
import { FullNav } from '../../dashboard/components/SideNav/FullNav/FullNav'
import SharedSidebar from '../../dashboard/components/SideNav/SharedSideNav'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const initiallyDarkMode =
  checkDashboardDarkMode() &&
  (JSON.parse(cookies.get('dashboard_config') ?? '{}').dark_color_scheme ?? false)

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(token)

const initialAppState = {
  appNavOpen: !cookies.get('app_nav_state'),
  darkMode: initiallyDarkMode,
}

const Themer: React.FC = ({ children }) => {
  const theme = useTheme(initiallyDarkMode == true ? ThemeMode.Dark : ThemeMode.Light)

  const client = buildApolloClient()

  return (
    <ApolloProvider client={client}>
      <FetchProvider fetcher={fetcher}>
        <BrowserRouter>
          <AppStateProvider initialState={initialAppState}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Box sx={{ background: 'tokens.surface.page' }}>{children}</Box>
              </ThemeProvider>
            </StyledEngineProvider>
          </AppStateProvider>
        </BrowserRouter>
      </FetchProvider>
    </ApolloProvider>
  )
}

const SideNav: React.FC<{ user: User; organization?: Organization }> = ({ user, organization }) => {
  const { value } = useAppStateProperty('appNavOpen')
  useEffect(() => {
    if (value) {
      document.getElementById('content-container')?.classList.remove('navClosed')
    } else {
      document.getElementById('content-container')?.classList.add('navClosed')
    }
  }, [value])

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <FullNav currentUser={user} organization={organization} noRouter={true} />
      <PlanUpgradeDisplay currentUser={user} />
    </Box>
  )
}

export function mount() {
  const preloadedData = window.CP_GQL_HYDRATE?.data
  const headerElem = document.getElementById('rails-react-header')
  const sidenavElem = document.getElementById('sidebar')
  const newSidenavElem = document.getElementById('new-sidebar')

  const useSharedNav = checkSharedNavFlag()

  const footerElem = document.getElementById('rails-react-footer')
  if (useSharedNav && footerElem) {
    ReactDOM.render(
      <Themer>
        <Footer />
      </Themer>,
      footerElem
    )
  }

  // Always render a header, even if partially missing user data.
  if (headerElem) {
    if (useSharedNav) {
      ReactDOM.render(
        <Themer>
          <SharedHeader
            currentUser={preloadedData?.user}
            organization={preloadedData?.organization}
            noRouter={true}
          />
        </Themer>,
        headerElem
      )
    } else {
      ReactDOM.render(
        <Themer>
          <Header
            currentUser={preloadedData?.user}
            organization={preloadedData?.organization}
            noRouter={true}
          />
        </Themer>,
        headerElem
      )
    }
  }

  // Only render side nav if user data present, since it has many dependencies on user data.
  if (preloadedData?.user) {
    if (newSidenavElem && useSharedNav) {
      ReactDOM.render(
        <Themer>
          <SharedSidebar
            currentUser={preloadedData?.user}
            organization={preloadedData?.organization}
            noRouter={true}
          />
        </Themer>,
        newSidenavElem
      )
    } else if (sidenavElem) {
      ReactDOM.render(
        <Themer>
          <SideNav user={preloadedData.user} organization={preloadedData.organization} />
        </Themer>,
        sidenavElem
      )
      // Rails stylesheet has set width for side nav, remove that once the React sidebar is rendered.
      sidenavElem.style.width = 'auto'
    }
  } else if (headerElem || sidenavElem) {
    // Presence of pad config means in pad view, where this log is not relevant.
    if (!window.padConfig) {
      // No preloaded user/org information found. Log to Sentry.
      const extra: { [key: string]: any } = {
        path: window.location.pathname,
        hasFlash: !!window.CP_APP_NOTICE || !!window.CP_APP_ERRORS,
      }
      const preloadErrors: Error[] = window.CP_GQL_HYDRATE?.errors
      if (preloadErrors) {
        preloadErrors.forEach((err, idx) => {
          extra[`error_${idx}`] = err.message
        })
      }
      if (window.CoderPad?.ENVIRONMENT === 'development') {
        console.error('GraphQL Rails preload user not found', extra)
      } else {
        Sentry.captureException(new Error('User info not found for React header/sidenav'), {
          tags: {
            layer: 'react',
          },
          extra,
        })
      }
    }
  }

  const appNotice = window.CP_APP_NOTICE
  const appErrors = window.CP_APP_ERRORS
  const appNoticeElem = document.getElementById('rails-react-app-notice')
  if (appNoticeElem && (appNotice || appErrors)) {
    ReactDOM.render(
      <Themer>
        <AppNotice />
      </Themer>,
      appNoticeElem
    )
  }
}

mount()
