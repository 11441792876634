import firebase from 'firebase/compat/app'
import { useEffect } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'

export function useFirebaseEnvsEnabled() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  useEffect(() => {
    let envsEnabledWatcher: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      envsEnabledWatcher = SyncHandle().watch('environmentsStatus', (status: string) => {
        // if the Pad switches to legacy, force everyone in the Pad to refresh the page
        if (status === 'disabled') {
          window.location.reload()
        }
      })
    }
    return () => {
      if (envsEnabledWatcher != null) {
        SyncHandle().off('environmentsEnabled', envsEnabledWatcher)
      }
    }
  }, [hasEnvironments])
}
