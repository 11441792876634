import { Divider, Stack } from '@mui/material'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { PadSlidingPanel, PadSlidingPanelProps } from '../components/PadSlidingPanel'
import { selectPadSettings } from '../selectors'
import { PadInvitesEmail } from './PadInvitesEmail'
import { PadInvitesPermalink } from './PadInvitesPermalink'
import { PadInvitesShortlink } from './PadInvitesShortlink'

export type PadInvitesProps = Omit<PadSlidingPanelProps, 'children'>

export const PadInvites = memo<PadInvitesProps>((props) => {
  const { isOpen, ...rest } = props
  const { takeHome } = useSelector(selectPadSettings)

  return (
    <PadSlidingPanel direction="up" isOpen={isOpen} {...rest}>
      <Stack divider={<Divider>or</Divider>} spacing={3}>
        {!takeHome && <PadInvitesEmail isOpen={isOpen} />}
        {!takeHome && <PadInvitesShortlink />}
        {<PadInvitesPermalink />}
      </Stack>
    </PadSlidingPanel>
  )
})
