import { monaco } from '@codingame/monaco-editor-wrapper'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export function useMarkdownRenderEvent(
  editor: monaco.editor.IStandaloneCodeEditor | null,
  activeFileName?: string
) {
  const dispatch = useDispatch()
  const isMarkdown = activeFileName?.endsWith('.md') ?? false

  useEffect(() => {
    let contentChangeDisposable: monaco.IDisposable | null = null
    const modelChangeDisposable = editor?.onDidChangeModel((e) => {
      contentChangeDisposable?.dispose()
      contentChangeDisposable = null

      const model = editor.getModel()
      if (isMarkdown && model != null) {
        const dispatchChange = () =>
          dispatch({ type: 'markdown_content_changed', content: model.getValue() })
        dispatchChange()
        contentChangeDisposable = model.onDidChangeContent(dispatchChange)
      }
    })

    return () => {
      modelChangeDisposable?.dispose()
      contentChangeDisposable?.dispose()
    }
  }, [dispatch, editor, isMarkdown])
}
