import * as _ from 'lodash'
import path from 'path'
import { basename } from 'path-browserify'
import useStateEffect from 'utils/hooks/useStateEffect'

import { fileIdToPath } from '../../../../utils/multifile'
import { isNotNull } from '../../../../utils/types'
import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../sync_handle'
import { EnvironmentSummary, IFile } from '../EnvironmentsContext/EnvironmentsContext'

const READ_ONLY_FILES = ['package-lock.json']
// "Immutable" files can not be deleted or renamed, but can be edited (if they aren't included in READ_ONLY_FILES)
const IMMUTABLE_FILES = ['package.json', 'package-lock.json', 'vite.config.ts']

export const projectRootFolder = '/home/coderpad/app'

function getMonacoFilePath(
  environment: EnvironmentSummary,
  filePath: string,
  isPlayback?: boolean
): string {
  if (environment.projectTemplateSlug != null) {
    return path.join(projectRootFolder, filePath)
  } else if (!environment.allowMultipleFiles && environment.language != null) {
    if (isPlayback) {
      return path.join(projectRootFolder, filePath)
    }
    return window.CoderPad.LANGUAGES[environment.language]?.default_path ?? filePath
  } else {
    /*
     * HACK: For 3-file env we cannot safely retrieve the `default_path` for the css & js so we don't
     * use the `/tmp/project` prefix with is ok because we don't have LSP on those envs
     */
    return filePath
  }
}

const emptyFileArray: IFile[] = []
export function useEnvironmentFiles(environment: EnvironmentSummary | null) {
  const isPlayback = usePadConfigValue('isPlayback')

  const files = useStateEffect(
    (setFiles) => {
      if (environment?.id != null) {
        // A change in environment id, so clear the files until we get the new env's files.
        setFiles([])

        const debouncedFilesWatcherFunc = _.debounce(
          async (fileIds: Record<string, boolean> = {}) => {
            const envFiles: IFile[] = (
              await Promise.all(
                _.uniq(Object.keys(fileIds)).map(async (fileId) => {
                  // False value here means that file is "deleted".
                  if (!fileIds[fileId] && !isPlayback) {
                    return
                  }

                  const path = fileIdToPath(fileId)
                  const name = basename(path)

                  const isLocked = READ_ONLY_FILES.includes(path)
                  const isImmutable = IMMUTABLE_FILES.includes(path)
                  const firebasePath = `environmentFiles/${environment.slug}/${fileId}`
                  const monacoPath = getMonacoFilePath(environment, path, isPlayback)

                  const isBinary: boolean = await new Promise((resolve, reject) => {
                    SyncHandle().get(
                      `environmentFiles/${environment?.slug}/${fileId}/binary`,
                      (isBinary: boolean) => {
                        resolve(isBinary)
                      }
                    )
                  })

                  const isLargeFile: boolean = await new Promise((resolve, reject) => {
                    SyncHandle().get(
                      `environmentFiles/${environment?.slug}/${fileId}/large`,
                      (isLarge: boolean) => {
                        resolve(isLarge)
                      }
                    )
                  })

                  const file: IFile = {
                    id: fileId,
                    name,
                    path,
                    firebasePath,
                    monacoPath,
                    isLocked,
                    isImmutable,
                    isBinary,
                    isLargeFile,
                  }

                  return file
                })
              )
            ).filter(isNotNull)
            setFiles(envFiles)
          },
          500,
          { leading: false }
        )

        const filesWatcher = SyncHandle().watch(
          `environmentFileIds/${environment.slug}`,
          debouncedFilesWatcherFunc,
          {}
        )
        return () => {
          SyncHandle().off(`environmentFileIds/${environment.slug}`, filesWatcher)
        }
      }
      return () => {}
    },
    [environment, isPlayback],
    emptyFileArray
  )

  return files
}
