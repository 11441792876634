import { put, select, takeEvery } from 'redux-saga/effects'
import { createFetcher } from 'utils/fetch/fetch'

import logEvent from '../log_pad_event'
import padConfig from '../pad_config'
import { selectExecEnabled, selectTestCasesEnabled } from '../selectors'
import SyncHandle from '../sync_handle'

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(token)

// Async task to handle running test cases with candidate code.
export default function* testCasesSaga() {
  let editor = null
  let runId = 0

  yield takeEvery('editor_mounted', function* (action) {
    if (!action.fileId || action.fileId === '0_global') {
      editor = action.editor
    }
  })

  // TODO: This should not re-emit a different action. Just handle the
  // reset_clicked so it clears the editor when there's no socket.
  yield takeEvery('reset_clicked', function* () {
    if (yield shouldBypass()) {
      return
    }

    yield put({ type: 'output_reset' })
  })

  yield takeEvery('run_clicked', function* (action) {
    if (yield shouldBypass()) {
      return
    }

    // If program is running, then this is a cancel.
    const running = yield select((state) => state.execution.running)
    if (running) {
      return
    }

    let code = ''

    if (action.meta?.isEnvironment && action.payload?.environment && action.payload?.files) {
      /**
       * Pad environments do not store their file/code info in the Redux store. When a pad environment is ran,
       * extra info is included in the `run_clicked` action denoting the environment, as well as its files, so that
       * we can query for the code/file contents to send to the execution service.
       */
      const { files: envFiles } = action.payload

      // Get the code from the single file in the environment.
      const headlessFirepad = SyncHandle().firepadHeadless(envFiles[0].firebasePath)

      try {
        code = yield headlessFirepad.getText()
      } finally {
        headlessFirepad.dispose()
      }
    } else {
      code = editor.getValue()
    }

    const monacoLineCount = editor.getValue().split('\n').length
    const lines = monacoLineCount ?? 'a few'

    if (!code.trim()) {
      yield put({
        type: 'console_output_produced',
        output: '\r\nPlease enter some code to compile, first.\r\n',
      })
      return
    }

    const languageName =
      action?.payload?.environment != null
        ? action.payload.environment.language
        : yield select((state) => state.padSettings.language)
    const language = CoderPad.LANGUAGES[languageName]
    if (!language.test_case_grading) return

    // TODO: Extract/Share between execution.js
    const { userId, userInfo, uncommittedUsername: username } = yield select(
      (state) => state.userState
    )
    const runningMsg = `${'-'.repeat(50)}\r\n${username} running tests on ${lines} line${
      lines > 1 ? 's' : ''
    } of ${language.display}...`
    const ranMsg = `${username} finished running tests:`
    const color = (userInfo[userId] && userInfo[userId].color) || CoderPad.COLORS[0]
    const data = { code }

    const question = yield select((state) => state.question)
    data['question_id'] = question.questionId
    data['language'] = languageName

    if (action.testCaseIds) {
      data['test_case_ids'] = action.testCaseIds
    }

    const id = ++runId
    yield put({ type: 'execution_started', id, msg: ranMsg, color })
    const gradePromise = new Promise((resolve, reject) => {
      const params = new URLSearchParams(window.location.search)
      const preview = params.get('preview')
      fetcher(
        `/${padConfig.isSandbox ? 'sandbox' : padConfig.slug}/grade_test_cases?${
          preview ? 'preview=true' : ''
        }`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            reject()
          }
        })
        .then((data) => {
          resolve(data)
        })
    })

    try {
      const syncHandle = SyncHandle()
      const consolePath =
        padConfig.hasEnvironments && action?.payload?.environment
          ? `console/${action?.payload?.environment.id}`
          : 'console'
      yield put({
        type: 'console_output_produced',
        output: `\r\n${runningMsg}\r\n`,
      })
      syncHandle.push(consolePath, {
        color,
        timestamp: syncHandle.TIMESTAMP_SENTINEL,
        text: runningMsg,
        type: 'execution',
        authorId: userId,
      })

      const result = yield gradePromise
      yield put({
        type: 'console_output_produced',
        output: `\r\n${ranMsg}\r\n`,
      })
      yield put({
        type: 'test_cases_graded',
        visibleTestCaseResults: result['visible_test_case_results'],
      })
      yield put({ type: 'console_output_produced', output: result['text'] })
      yield put({ type: 'execution_finished', id })

      syncHandle.push(consolePath, {
        color,
        timestamp: syncHandle.TIMESTAMP_SENTINEL,
        text: ranMsg,
        type: 'execution',
        authorId: userId,
      })
      syncHandle.push(consolePath, {
        color,
        timestamp: syncHandle.TIMESTAMP_SENTINEL,
        text: result['text'],
        type: 'output',
        authorId: userId,
      })
    } catch (e) {
      console.error(e)
      const text = e.responseJSON && e.responseJSON.text
      const msg = text
        ? text === 'timeout'
          ? 'Tests halted for running too long, sorry!'
          : text
        : 'Could not run tests due to a platform error. Sorry about that!'
      yield put({ type: 'execution_platform_errored', msg })
    }

    logEvent('ran', { username, metadata: language.name })
    yield put({ type: 'local_user_ran_code' })
  })
}

// If execution is disabled or test cases aren't present, then bypass
// action handlers.
function* shouldBypass() {
  return !(yield select(selectExecEnabled)) || !(yield select(selectTestCasesEnabled))
}
