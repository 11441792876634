import { Feedback } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Tooltip } from '@mui/material'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback } from 'react'

import { FooterButton } from './FooterButton'
import PadFeedback from './PadFeedback'
import trackEvent from './track_event'

export function PadFeedbackButton() {
  const { isPlayback } = usePadConfigValues('isPlayback')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    trackEvent('Pad Feedback Opened')
  }

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    trackEvent('Pad Feedback Dismissed')
  }, [])

  return (
    <>
      <Tooltip
        placement="top"
        arrow
        title={<Box textAlign="center">Report any bugs or pad issues to the CoderPad team</Box>}
      >
        <span>
          <FooterButton
            onClick={handleClick}
            iconOnly={isPlayback}
            startIcon={<Feedback sx={{ width: 20, height: 20 }} />}
          >
            <Box sx={{ display: isPlayback ? 'none' : { xs: 'none', lg: 'initial' } }}>
              Feedback
            </Box>
          </FooterButton>
        </span>
      </Tooltip>
      <PadFeedback anchorEl={anchorEl} onHide={handleClose} data-testid="pad-feedback-form" />
    </>
  )
}
