import searchFuzzy from 'approx-string-match'
import _ from 'lodash'

export default function highlightText(text, query, maxHighlights) {
  if (query.length === 0) {
    return _.escape(text)
  }
  if (!maxHighlights) maxHighlights = 20

  let ret = ''
  const allowedErrors = Math.floor(query.length * 0.5)
  // matches = [{ start: 11, end: 14, errors: 1 }]
  const matches = searchFuzzy(text.toLowerCase(), query.toLowerCase(), allowedErrors)
  let textCursor = 0
  for (let i = 0; i < matches.length && i < maxHighlights; i++) {
    const match = matches[i]
    if (match.start < textCursor) continue

    ret += _.escape(text.substring(textCursor, match.start))
    ret += `<em>${_.escape(text.substring(match.start, match.end))}</em>`
    textCursor = match.end
  }
  ret += _.escape(text.substring(textCursor))
  return ret
}
