import _ from 'lodash'
import React, { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from './button'
import { useActiveEnvironment } from './Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'

interface RunTestCaseButtonProps {
  testCaseIds: number[]
}

const RunTestCaseButton: FC<RunTestCaseButtonProps> = ({ testCaseIds }) => {
  const buttonRef = useRef<Button>(null)
  const { environment, files } = useActiveEnvironment()

  const dispatch = useDispatch()
  const running = useSelector((state) => state.execution.running)
  const darkBg = useSelector((state) => state.editorSettings.darkColorScheme)

  const text = running ? 'Stop' : 'Run selected test cases'

  const handleClick = _.debounce(() => {
    if (buttonRef.current) {
      dispatch({
        type: 'run_clicked',
        payload: {
          environment,
          files,
        },
        testCaseIds: testCaseIds,
        _analytics: { name: 'Run Test Case Clicked' },
      })
    }
  }, 500)

  return (
    <Button
      darkBg={darkBg}
      disabled={testCaseIds.length === 0}
      onClick={handleClick}
      ref={buttonRef}
      type={running ? 'danger' : 'primary'}
      className={'TestsTab-runButton'}
    >
      {text}
    </Button>
  )
}

export default RunTestCaseButton
