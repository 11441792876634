import { Alert, Box, Button, styled, Typography } from '@mui/material'
import { ProjectOutput } from 'packs/main/ProjectOutput'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { Pane, SplitPane } from '../../../../dashboard/components/SplitPane/SplitPane'
import CodePane from '../../../code_pane'
import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentEditor } from '../../../EnvironmentsPane/EnvironmentEditor/EnvironmentEditor'
import { FileExplorer } from '../../../EnvironmentsPane/EnvironmentEditor/FileExplorer/FileExplorer'
import { RightPane } from '../../../RightPane/RightPane'
import { selectHasRightPane } from '../../../selectors'
import { GSheetsIframe } from '../GSheetsIframe'

const HackForRepositioningProjectOutput = styled('div')({
  position: 'absolute',
  top: '-53px',
  height: 'calc(100% + 53px)',
  left: 0,
  right: 0,
})

/**
 * A workspace content containing a left code pane and resources/notes right pane. Includes the "floating" resizable,
 * movable Drawing Mode and the methods necessary to support that.
 */
export const Stacked: React.FC<{ handleOpenDrawingMode: () => void }> = ({
  handleOpenDrawingMode,
}) => {
  const hasRightPane = useSelector(selectHasRightPane)
  const {
    activeEnvironment,
    ready,
    setSandboxExecutionEnvironment,
    isExecPossible,
    environments,
  } = useEnvironments()

  const { hasEnvironments, isPlayback } = usePadConfigValues('hasEnvironments', 'isPlayback')

  const isPadReady =
    (hasEnvironments && ready && activeEnvironment) ||
    !hasEnvironments ||
    (environments.length === 0 && isPlayback)
  const shouldShowRightPane = hasRightPane && isPadReady
  const isJupyter = activeEnvironment?.projectTemplateSlug?.startsWith('jupyter')
  const isGsheets = activeEnvironment?.spreadsheet != null
  const noEnvironmentsPlayback = ready && environments.length === 0 && isPlayback

  const topPaneEditor = useMemo(() => {
    return noEnvironmentsPlayback ? (
      <Box display="flex" justifyContent="center" pt="80px">
        <Alert severity="warning">
          No code playback is available; all code environments were deleted before this pad was
          ended.
        </Alert>
      </Box>
    ) : isGsheets ? (
      <GSheetsIframe spreadsheetId={activeEnvironment.spreadsheet} />
    ) : isJupyter ? (
      <HackForRepositioningProjectOutput>
        <ProjectOutput hidden={false} />
      </HackForRepositioningProjectOutput>
    ) : hasEnvironments ? (
      <EnvironmentEditor />
    ) : (
      <CodePane handleOpenDrawingMode={handleOpenDrawingMode} />
    )
  }, [
    activeEnvironment?.spreadsheet,
    handleOpenDrawingMode,
    hasEnvironments,
    isGsheets,
    isJupyter,
    noEnvironmentsPlayback,
  ])

  return (
    <SplitPane
      orientation="y"
      // Since the right pane is omitted until the pad is ready (envs loaded or envs disabled), disable the
      // transitions on the split pane so that there isn't a growing/shrinking pane when the pad first loads.
      enablePaneSizeTransitions={isPadReady ? undefined : false}
    >
      <Pane id="editor-and-explorer">
        <SplitPane orientation="x">
          <Pane
            id="file-explorer"
            hidden={!activeEnvironment?.allowMultipleFiles}
            initialSizePct={15}
          >
            <FileExplorer />
          </Pane>
          <Pane id="editor">{topPaneEditor}</Pane>
        </SplitPane>
      </Pane>
      <Pane id="output" hidden={!shouldShowRightPane} minSize={60}>
        {!isExecPossible ? (
          <Box display="flex" justifyContent="center" pt="80px">
            <div>
              <Typography variant="h2" sx={{ color: 'text.primary' }}>
                Click to activate execution for this environment!
              </Typography>
              <Box display="flex" justifyContent="center" mt="24px">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    activeEnvironment && setSandboxExecutionEnvironment(activeEnvironment.slug)
                  }
                >
                  Activate Execution
                </Button>
              </Box>
            </div>
          </Box>
        ) : (
          <RightPane />
        )}
      </Pane>
    </SplitPane>
  )
}
