import { formatDistanceToNow, parseISO } from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'

import { createFetcher } from '../../utils/fetch/fetch'
import {
  useCanEditQuestionCopies,
  useIsExampleQuestionEditable,
  useIsQuestionEditable,
} from '../dashboard/Questions/hooks'
import QuestionSelectedTestCases from './question_selected_testcases'
import withMarkdownCodeMirror from './with_markdown_code_mirror'

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(token)

const highlight = (code, language) => {
  const dummy = document.createElement('div')
  CodeMirror.runMode(code, CoderPad.LANGUAGES[language].mode, dummy)
  return dummy.innerHTML
}

export default function QuestionSelected(props) {
  const isExampleQuestionEditable = useIsExampleQuestionEditable()
  const isQuestionEditable = useIsQuestionEditable(props.question)
  const canEditQuestionCopies = useCanEditQuestionCopies()
  return (
    <_QuestionSelected
      {...props}
      isQuestionEditable={isNaN(props.question.id) ? isExampleQuestionEditable : isQuestionEditable}
      canEditQuestionCopies={canEditQuestionCopies}
    />
  )
}

QuestionSelected.propTypes = {
  question: PropTypes.shape({
    author_name: PropTypes.string.isRequired,
    contents: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.any,
    language: PropTypes.string.isRequired,
    custom_database: PropTypes.shape({
      id: PropTypes.any.isRequired, // TODO: Is this string or number?
      // TODO: Maybe there are other fields in here that should be listed?
    }),
    custom_files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    starter_code_by_language: PropTypes.shape(),
    take_home: PropTypes.boolean,
    public_take_home_setting_id: PropTypes.number,
    test_cases: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        arguments: PropTypes.any,
        return_value: PropTypes.any,
        visible: PropTypes.boolean,
      })
    ),
    test_cases_enabled: PropTypes.boolean,
  }).isRequired,
  variations: PropTypes.arrayOf(PropTypes.any),
  activeVariation: PropTypes.any,
  allowPadCreation: PropTypes.bool.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  padSlug: PropTypes.string,
  padLanguage: PropTypes.string,
  onDeselectQuestion: PropTypes.func,
  onSetQuestion: PropTypes.func,
  onSelectQuestionVariation: PropTypes.func,
}

class _QuestionSelected extends React.PureComponent {
  static propTypes = {
    isQuestionEditable: PropTypes.bool,
    isQuestionCopyEditable: PropTypes.bool,
    question: PropTypes.shape({
      author_name: PropTypes.string.isRequired,
      contents: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.any,
      language: PropTypes.string.isRequired,
      custom_database: PropTypes.shape({
        id: PropTypes.any.isRequired, // TODO: Is this string or number?
        // TODO: Maybe there are other fields in here that should be listed?
      }),
      custom_files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          filename: PropTypes.string.isRequired,
        })
      ).isRequired,
      title: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      starter_code_by_language: PropTypes.shape(),
      take_home: PropTypes.boolean,
      public_take_home_setting_id: PropTypes.number,
      test_cases: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.any,
          arguments: PropTypes.any,
          return_value: PropTypes.any,
          visible: PropTypes.boolean,
        })
      ),
      test_cases_enabled: PropTypes.boolean,
    }).isRequired,
    variations: PropTypes.arrayOf(PropTypes.any),
    activeVariation: PropTypes.any,
    allowPadCreation: PropTypes.bool.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    padSlug: PropTypes.string,
    padLanguage: PropTypes.string,
    onDeselectQuestion: PropTypes.func,
    onSetQuestion: PropTypes.func,
    onSelectQuestionVariation: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.abortController = new AbortController()
    this.state = this.getInitStateFromProps(props)
    this.getQuestionData()
    this._cloneExampleRef = React.createRef()
  }

  async getQuestionData() {
    // Example question ids are "examples/001-slug"
    if (typeof this.props.question.id === 'number') {
      const signal = this.abortController.signal
      const res = await fetcher(`/questions/${this.props.question.id}.json`, { signal: signal })
      if (res.ok) {
        const data = await res.json()
        const contents = data.test_cases_enabled ? data.contents_for_test_cases : data.contents
        this.setState({
          contents,
          starterCodeByLanguage: data.starter_code_by_language,
        })
      }
    }
  }

  getActiveVariationContents() {
    const question = this.getActiveQuestionVariation()
    return question.test_cases_enabled ? question.contents_for_test_cases : question.contents
  }

  getInitStateFromProps(props) {
    const question = props.activeVariation
      ? _.find(this.props.variations, { variation: props.activeVariation }) || this.props.question
      : this.props.question
    return {
      starterCodeByLanguage: props.starter_code_by_language,
      activeDetail: question.candidate_instructions?.[0]?.['instructions']
        ? 'candidateInstructions'
        : 'contents',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.question !== prevProps.question) {
      this.setState(this.getInitStateFromProps(this.props))
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  getQuestionsIndex = () => {
    return this.props.question.take_home === true ? '/questions/take_home' : '/questions'
  }

  handleVariationChange = ({ value }) => {
    const selectedVariation =
      _.find(this.props.variations, { variation: value }) ?? this.props.question

    if (this.props.onSelectQuestionVariation != null) {
      this.props.onSelectQuestionVariation(selectedVariation)
    }
    if (!this.props.padSlug) {
      history.replaceState(null, '', `${this.getQuestionsIndex()}/${selectedVariation.id}`)
    }
  }

  setQuestion(append) {
    const question = this.getActiveQuestionVariation()
    this.props.onSetQuestion({
      language: question.language,
      padLanguage: this.props.padLanguage,
      contents: this.getActiveVariationContents(),
      candidateInstructions: question.candidate_instructions,
      customDatabaseId: question.custom_database?.id,
      customDatabaseLanguage: question.custom_database?.language,
      customDatabaseSchema: question.custom_database?.schema,
      customDatabaseSchemaJson: question.custom_database?.schemaJson,
      customFiles: question.custom_files,
      fileContents: question.file_contents,
      append,
      questionId: question.id,
      testCasesEnabled: question.test_cases_enabled,
      testCases: question.test_cases,
      starterCodeByLanguage: this.state.starterCodeByLanguage,
      solution: question.solution,
    })
    $('#questions-modal').modal('hide')
  }

  getActiveQuestionVariation = () => {
    return this.props.activeVariation ?? this.props.question
  }

  getActivateDetailHandler = (detail) => {
    return () => {
      this.setState({ activeDetail: detail })
    }
  }

  get isExample() {
    return (
      typeof this.props.question.id === 'string' &&
      this.props.question.id.indexOf('examples/') !== -1
    )
  }

  isQuestionAppendAllowed = (question) => {
    if (!this.props.padSlug) {
      return false
    } // gotta have a pad
    if (question.take_home) {
      return false
    } // append not supported for take homes
    if (question.language === 'html') {
      return false
    } // html questions only support replace pad
    if (question.language === this.props.padLanguage) {
      return true
    } // allow append if the pad is already set to the right lang
    return false
  }

  componentDidMount() {
    if (this.isExample) {
      $(this._cloneExampleRef.current).tooltip()
    }
  }

  render() {
    const contents = this.getActiveVariationContents()
    if (contents != null) {
      const { allowEdit, padSlug, onDeselectQuestion } = this.props
      const question = this.getActiveQuestionVariation()
      const testCases = question.test_cases
      const testCasesTotal = testCases ? testCases.length : 0
      const visibleTabs = {
        candidateInstructions: !!question.candidate_instructions?.[0]?.['instructions'],
        contents: contents != null,
        customFiles: question.custom_files.length > 0,
        testCases: question.test_cases_enabled && testCasesTotal > 0,
        solution: !!question.solution,
      }
      const variationOptions = _.map(this.props.variations, (questionVariation) => {
        const label = questionVariation.language
          ? window.CoderPad.LANGUAGES[questionVariation.language].display
          : questionVariation.variation

        return {
          label,
          value: questionVariation.variation ?? questionVariation.language,
        }
      })
      const activeVariationOption = _.find(variationOptions, {
        value: this.props.activeVariation?.language,
      })
      return (
        <div>
          <div className="question-scroll">
            <button className="back" onClick={onDeselectQuestion}>
              <span className="fui-arrow-left" />
              Back
            </button>
            <div className="question-header">
              <h4>{question.title}</h4>
              {variationOptions.length > 0 && (
                <Select
                  className="QuestionSelected-variationPicker"
                  escapeClearsValue={false}
                  isSearchable={false}
                  onChange={this.handleVariationChange}
                  options={variationOptions}
                  placeholder=""
                  value={activeVariationOption}
                />
              )}
              <pre className="description">
                {question.description ? (
                  question.description +
                  (question.difficulty ? ` (Difficulty: ${_.capitalize(question.difficulty)})` : '')
                ) : (
                  <span className="missing">No Description</span>
                )}
              </pre>
              <p className="metadata">
                {question.test_cases_enabled
                  ? `Test Cases (Initial language: ${
                      CoderPad.LANGUAGES[question.language].display
                    })`
                  : CoderPad.LANGUAGES[question.language].display}
                {question.custom_database && (
                  <span>
                    {' '}
                    (Custom Database: <strong>{question.custom_database.title}</strong>){' '}
                  </span>
                )}{' '}
                Created by
                {' ' + question.author_name}
                {' ' + formatDistanceToNow(parseISO(question.created_at), { addSuffix: true })}
              </p>
            </div>
            {_.filter(visibleTabs).length > 1 && (
              <header className="QuestionSelected-detailPicker">
                {visibleTabs.candidateInstructions && (
                  <a
                    className={`QuestionSelected-detailPickerOption ${
                      this.state.activeDetail === 'candidateInstructions' &&
                      'QuestionSelected-detailPickerOption--active'
                    }`}
                    onClick={this.getActivateDetailHandler('candidateInstructions')}
                  >
                    Candidate Instructions
                  </a>
                )}
                {visibleTabs.contents && (
                  <a
                    className={`QuestionSelected-detailPickerOption ${
                      this.state.activeDetail === 'contents' &&
                      'QuestionSelected-detailPickerOption--active'
                    }`}
                    onClick={this.getActivateDetailHandler('contents')}
                  >
                    Starter Code
                  </a>
                )}
                {visibleTabs.testCases && (
                  <a
                    className={`QuestionSelected-detailPickerOption ${
                      this.state.activeDetail === 'testCases' &&
                      'QuestionSelected-detailPickerOption--active'
                    }`}
                    onClick={this.getActivateDetailHandler('testCases')}
                  >
                    Test Cases ({testCasesTotal})
                  </a>
                )}
                {visibleTabs.customFiles && (
                  <a
                    className={`QuestionSelected-detailPickerOption ${
                      this.state.activeDetail === 'customFiles' &&
                      'QuestionSelected-detailPickerOption--active'
                    }`}
                    onClick={this.getActivateDetailHandler('customFiles')}
                  >
                    Custom File
                  </a>
                )}
                {visibleTabs.solution && (
                  <a
                    className={`QuestionSelected-detailPickerOption ${
                      this.state.activeDetail === 'solution' &&
                      'QuestionSelected-detailPickerOption--active'
                    }`}
                    onClick={this.getActivateDetailHandler('solution')}
                  >
                    Solutions
                  </a>
                )}
              </header>
            )}
            {visibleTabs.candidateInstructions && (
              <div
                className={`QuestionSelected-candidateInstructions ${
                  this.state.activeDetail === 'candidateInstructions' ? '' : 'hidden'
                }`}
              >
                {question.candidate_instructions.map((step, index) => (
                  <>
                    <h4>Instructions Part {index + 1}</h4>
                    <CandidateInstructions key={index} value={step.instructions} />
                    <hr />
                  </>
                ))}
              </div>
            )}
            {visibleTabs.contents && (
              <pre
                className={`cm-s-one-light QuestionSelected-contents ${
                  this.state.activeDetail === 'contents' ? '' : 'hidden'
                }`}
                dangerouslySetInnerHTML={{
                  __html: highlight(contents, question.language),
                }}
              />
            )}
            {visibleTabs.customFiles && (
              <div
                className={`cm-s-one-light QuestionSelected-customFiles ${
                  this.state.activeDetail === 'customFiles' ? '' : 'hidden'
                }`}
              >
                <ul>
                  {question.custom_files.map((custom_file) => (
                    <li key={custom_file.id}>
                      <a href={`/dashboard/files/${custom_file.id}`}>
                        <span className="icon" />
                        {custom_file.filename} ({custom_file.filesize})
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {visibleTabs.testCases && (
              <div className={this.state.activeDetail === 'testCases' ? '' : 'hidden'}>
                <QuestionSelectedTestCases testCases={testCases} />
              </div>
            )}
            {visibleTabs.solution && (
              <div
                className={`QuestionSelected-solution ${
                  this.state.activeDetail === 'solution' ? '' : 'hidden'
                }`}
              >
                <Solution value={question.solution} />
              </div>
            )}
          </div>
          <div className="question-actions">
            {(this.isExample && this.props.canEditQuestionCopies && (
              <a
                href={`/questions/new?example_question_id=${question.id}${
                  question.take_home ? '&take_home=1' : ''
                }`}
                className="outline-button QuestionSelected-copyExample"
                target="_blank"
                rel="noreferrer"
                data-placement="top"
                ref={this._cloneExampleRef}
                title="Click here to edit a copy of this example and save it to your library."
              >
                Edit a Copy
              </a>
            )) ||
              (allowEdit && this.props.isQuestionEditable && (
                <a
                  href={`/questions/${question.id}/edit`}
                  className="outline-button"
                  target={padSlug ? '_blank' : '_self'}
                >
                  Edit
                </a>
              ))}

            {this.isQuestionAppendAllowed(question) && (
              <button
                key="append"
                className="outline-button"
                onClick={() => this.setQuestion(true)}
              >
                Append to Pad
              </button>
            )}
            {padSlug && (
              <button
                key="replace"
                className="outline-button"
                onClick={() => this.setQuestion(false)}
              >
                Replace Pad
              </button>
            )}

            {this.props.allowPadCreation && (
              <>
                <a
                  className="outline-button"
                  href={`/sandbox?question_id=${question.id}`}
                  target={this.isExample ? '_blank' : '_self'}
                >
                  Preview Question
                </a>
                {question.take_home ? (
                  <>
                    <a
                      className={`outline-button QuestionSelected-createPad${
                        this.isExample ? '--fromExample' : ''
                      }`}
                      href={
                        this.isExample
                          ? `/dashboard/pad_sets/new?pad_set[example_question_id]=${question.id}`
                          : `/dashboard/pad_sets/new?pad_set[question_id]=${question.id}`
                      }
                      target={this.isExample ? '_blank' : '_self'}
                    >
                      Create Take-Homes
                    </a>

                    {question.test_cases_enabled && !this.isExample && (
                      <>
                        {question.public_take_home_setting_id ? (
                          <a
                            className="outline-button QuestionSelected-createPad"
                            href={`/public_take_home_settings/${question.public_take_home_setting_id}/edit`}
                          >
                            Edit Public Take-Home URL
                          </a>
                        ) : (
                          <a
                            className="outline-button QuestionSelected-createPad"
                            href={`/public_take_home_settings/new?public_take_home_setting[question_id]=${question.id}`}
                          >
                            Create Public Take-Home URL
                          </a>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <form
                    method="post"
                    action="/pads"
                    acceptCharset="UTF-8"
                    target={this.isExample ? '_blank' : '_self'}
                  >
                    <input name="utf8" type="hidden" value="✓" />
                    {/* temporary hack until we figure out a better react/rails form solution */}
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={$('meta[name=csrf-token]').attr('content')}
                    />
                    <input type="hidden" name="pad[language]" value={question.language} />
                    <input type="hidden" name="pad[question_id]" value={question.id} />
                    <input
                      className={`outline-button QuestionSelected-createPad${
                        this.isExample ? '--fromExample' : ''
                      }`}
                      type="submit"
                      value="Create Pad With Question"
                    />
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="question-header">
            <h4>Loading &hellip;</h4>
          </div>
        </div>
      )
    }
  }
}

class _CandidateInstructions extends React.Component {
  static propTypes = {
    codeMirror: PropTypes.instanceOf(CodeMirror),
    value: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    // HACK: There's probably a better way to update the CM content
    // from props directly in withMarkdownCodeMirror
    if (this.props.codeMirror && (!prevProps.codeMirror || this.props.value !== prevProps.value)) {
      this.props.codeMirror.setValue(this.props.value)
    }
  }

  render() {
    // withMarkdownCodeMirror adds CodeMirror DOM elements as children.
    return <>{this.props.children}</>
  }
}

const CandidateInstructions = withMarkdownCodeMirror(_CandidateInstructions)

CandidateInstructions.defaultProps = {
  readOnly: true,
}

class _Solution extends React.Component {
  static propTypes = {
    codeMirror: PropTypes.instanceOf(CodeMirror),
    value: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    // HACK: There's probably a better way to update the CM content
    // from props directly in withMarkdownCodeMirror
    if (this.props.codeMirror && (!prevProps.codeMirror || this.props.value !== prevProps.value)) {
      this.props.codeMirror.setValue(this.props.value)
    }
    this.props.codeMirror.refresh()
  }

  render() {
    // withMarkdownCodeMirror adds CodeMirror DOM elements as children.
    return <>{this.props.children}</>
  }
}

const Solution = withMarkdownCodeMirror(_Solution)

Solution.defaultProps = {
  readOnly: true,
}
