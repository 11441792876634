import PropTypes from 'prop-types'
import React from 'react'

// Simple helper to manage the document title in React

export default class Title extends React.Component {
  static propTypes = {
    // Using children instead of a named prop so you can do <Title>my title</Title>
    children: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this._formerTitle = document.title
    document.title = this.props.children
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) document.title = this.props.children
  }

  componentWillUnmount() {
    document.title = this._formerTitle
  }

  render() {
    return null
  }
}
