import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { cancel, delay, fork, put, select, takeEvery, throttle } from 'redux-saga/effects'

export default function* playbackSaga() {
  // After we first load the playback frames, check for a user-requested frame
  // index in the URL hash and seek to the frame if present. This allows
  // linking to a specific frame.
  const urlFrameIndex = parseInt(location.hash.substr(1))

  yield takeEvery('playback_set_frames', function* initURLHash() {
    const frameLength = yield select((state) => state.playbackHistory.frameLength)
    const frameIndex = yield select((state) => state.playbackHistory.frameIndex)
    yield put({ type: 'playback_seek_to_frame', index: frameLength - 1 })
    if (urlFrameIndex > 0 && urlFrameIndex < frameLength) {
      yield put({ type: 'playback_seek_to_frame', index: urlFrameIndex })
    } else if (frameIndex < frameLength - 1) {
      yield updateURLHash({})
    }
  })

  yield throttle(500, 'playback_advance_frame', updateURLHash)
  yield throttle(500, 'playback_seek_to_frame', updateURLHash)
  yield throttle(500, 'playback_rewound_ms', updateURLHash)

  // Listen for hash changes and messages that cause us to seek
  const chan = eventChannel((emitter) => {
    const onHashChange = () => emitter(['hashchange'])
    const onMessage = (evt) => emitter(['message', evt.data])
    window.addEventListener('hashchange', onHashChange)
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('hashchange', onHashChange)
      window.removeEventListener('message', onMessage)
    }
  })
  yield takeEvery(chan, function* ([type, data]) {
    if (type === 'hashchange') {
      const frame = Number(location.hash.split('#')[1])
      if (_.isInteger(frame)) {
        yield put({
          type: 'playback_seek_to_frame',
          index: frame,
          fromHashChange: true,
        })
      }
    } else if (type === 'message' && _.isInteger(data)) {
      yield put({
        type: 'playback_seek_to_time',
        ms: data,
      })
    }
  })

  let playbackTask = null
  yield takeEvery('playback_play', function* playbackPlay() {
    // If at end, reset to beginning first, delay, then initiate playback loop
    const { frameIndex, frameLength } = yield select((state) => state.playbackHistory)
    if (frameIndex >= frameLength - 1) {
      yield put({
        type: 'playback_seek_to_frame',
        index: 1,
      })
      yield delay(800)
      if (!(yield select((state) => state.playbackHistory.playing))) {
        return
      }
    }

    playbackTask = yield fork(playbackLoop)
  })
  yield takeEvery('playback_pause', function* playbackPause() {
    if (playbackTask) {
      yield cancel(playbackTask)
      playbackTask = null
    }
  })
}

function* updateURLHash({ fromHashChange }) {
  if (!fromHashChange) {
    const index = yield select((state) => state.playbackHistory.frameIndex)
    history.replaceState(null, '', `#${index}`)
  }
}

function* playbackLoop() {
  while (true) {
    const { frameIndex, playbackSpeed, playing, fastforward, frames } = yield select((state) => {
      return {
        frameIndex: state.playbackHistory.frameIndex,
        playbackSpeed: state.playbackHistory.playbackSpeed,
        playing: state.playbackHistory.playing,
        fastforward: state.playbackHistory.fastforward,
        frames: state.playbackHistory.frames,
      }
    })

    if (!playing) {
      return
    }

    if (frames[frameIndex].type === 'ai-chat') {
      yield put({
        type: 'tab_selected',
        tab: 'ai',
      })
    }

    // Calculate delays for showing each file's next frame and use the biggest delay.
    let biggestDelay = 0

    // Adjust playback base speed based on FF state. Capping the FF speed to 8x
    const adjustedPlaybackSpeed = fastforward ? Math.min(playbackSpeed * 4, 8) : playbackSpeed

    const frameDelay = getFrameDelay(null, frames[frameIndex + 1])
    if (frameDelay > biggestDelay) {
      biggestDelay = frameDelay
    }

    // Scale the frame's delay according to the playback speed.
    biggestDelay = biggestDelay / adjustedPlaybackSpeed

    const nextFrame = Date.now() + biggestDelay

    yield put({
      type: 'playback_advance_frame',
      frameDelay: biggestDelay,
    })
    yield delay(Math.max(0, nextFrame - Date.now()))
  }
}

// Delay to highlight deletes before updating editor to a delete frame's value.
export const PLAYBACK_DELAY_DELETE_FRAME_HIGHLIGHT = 300
export const PLAYBACK_DELAY_MAX_VALUE = 1000
export const PLAYBACK_DELAY_EDITOR_SCROLL = 1300

function getFrameDelay(codeMirror, frame) {
  if (!frame) {
    return 0
  }

  // Base delay relates to edit complexity. See playback_history.js
  let delay = frame.delay

  // Delete frames take more time as we pause to highlight ranges before deletion.
  if (frame.type === 'delete' && frame.highlightRanges.length > 0) {
    delay += PLAYBACK_DELAY_DELETE_FRAME_HIGHLIGHT
  }

  return Math.min(PLAYBACK_DELAY_MAX_VALUE, delay)
}
