import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  ClickAwayListener,
  ClickAwayListenerProps,
  IconButton,
  Slide,
  SlideProps,
  SxProps,
} from '@mui/material'
import React, { memo, useCallback } from 'react'

import { ScrollView } from '../../ScrollView/ScrollView'

export interface PadSlidingPanelProps {
  children: ClickAwayListenerProps['children']
  direction?: SlideProps['direction']
  isOpen: boolean
  onClose: () => void
  sx?: SxProps
  allowClickAway?: boolean
}

const externalPaddingInPx = 4

export const PadSlidingPanel = memo<PadSlidingPanelProps>((props) => {
  const { children, direction = 'right', isOpen, onClose, sx = {}, allowClickAway } = props

  const handleClickAway = useCallback(() => {
    if (allowClickAway) {
      onClose()
    }
  }, [allowClickAway, onClose])

  return (
    <Slide direction={direction} in={isOpen} mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 1,
          maxWidth: 400 + externalPaddingInPx * 2,
          overflow: 'hidden',
          padding: externalPaddingInPx / 8,
          width: '100vw',
          zIndex: 0,
          ...sx,
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.background[500]
                : theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[2],
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 1,
            marginTop: 20,
            maxHeight: (theme) => `calc(100vh - ${theme.spacing(20)})`,
            position: 'relative',
            zIndex: 2,
          }}
        >
          <IconButton
            data-testid="PadSlidingPanelCloseButton"
            onClick={onClose}
            size="small"
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ScrollView
            sx={{
              overflowY: 'auto',
              paddingY: 3,
            }}
          >
            <ClickAwayListener onClickAway={handleClickAway}>{children}</ClickAwayListener>
          </ScrollView>
        </Box>
      </Box>
    </Slide>
  )
})
