import ErrorIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'

interface DeviceSelectorProps {
  handleDeviceChange: (event: SelectChangeEvent<string>) => void
  noDeviceListAvailable: boolean
  kind: string
  selectedId?: string
  deviceState?: string
  deviceList: MediaDeviceInfo[]
}
export function DeviceSelector(props: DeviceSelectorProps) {
  const {
    handleDeviceChange,
    noDeviceListAvailable,
    kind,
    selectedId,
    deviceState,
    deviceList,
  } = props
  const options = []
  let selectedOption
  for (const deviceInfo of deviceList) {
    const option = {
      label: deviceInfo.label,
      value: deviceInfo.deviceId,
    }
    options.push(option)
    if (deviceInfo.deviceId === selectedId) selectedOption = option
  }
  if (kind === 'video') {
    options.unshift({
      label: 'No Video',
      value: '____no_video',
    })
    if (selectedId === '____no_video') selectedOption = options[0]
  }
  if (noDeviceListAvailable) {
    // In case enumerating devices doesn't work in your browser, you'll be stuck on the
    // default device. Reflect that in the dropdown.
    const fakeDefaultDeviceOption = {
      label: kind === 'video' ? 'Default Webcam' : 'Default Microphone',
      value: '____default',
    }
    options.push(fakeDefaultDeviceOption)
    if (selectedId != null) selectedOption = fakeDefaultDeviceOption
  }

  const statusIcon =
    deviceState === 'error' ? (
      <ErrorIcon color="error" fontSize="small" />
    ) : deviceState === 'opening' ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 'calc(50% - 0.5rem)',
          right: 7,
        }}
      >
        <CircularProgress size={16} color="inherit" />
      </Box>
    ) : undefined

  return (
    <div>
      <FormControl
        fullWidth
        disabled={deviceList.length === 0 || deviceState === 'opening' || noDeviceListAvailable}
        variant="outlined"
        color="primary"
      >
        <InputLabel id={`media-input-kind-${kind}`}>
          {kind === 'video' ? 'Webcam' : 'Microphone'}
        </InputLabel>
        <Select
          labelId={`media-input-kind-${kind}`}
          onChange={handleDeviceChange}
          label={kind === 'video' ? 'Webcam' : 'Microphone'}
          value={selectedOption?.value ?? ''}
          endAdornment={statusIcon}
          IconComponent={statusIcon ? 'span' : undefined}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
