import { ArrowDropDown } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import React, { useCallback, useState } from 'react'

export const TabMenu: React.FC<{
  onSelection: (dataMap: Record<string, unknown>) => void
  dataKey: string
  options: { label: string; value: unknown }[]
}> = ({ onSelection, dataKey, options }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const open = useCallback((e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }, [])

  const handleSelection = useCallback(
    (e, val: unknown) => {
      e.stopPropagation()
      onSelection({ [dataKey]: val })
      setAnchorEl(null)
    },
    [onSelection, dataKey]
  )

  const isOpen = !!anchorEl
  const openerId = `header-tab-menu-${dataKey}`
  const menuId = `${openerId}-menu`
  return (
    <div>
      <ArrowDropDown
        onClick={open}
        sx={(theme) => ({
          transform: isOpen ? 'scaleY(-1)' : undefined,
          margin: '-10px 0 -8px',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        })}
        id={openerId}
        aria-controls={isOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      />
      <Menu
        id={menuId}
        MenuListProps={{
          'aria-labelledby': openerId,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((o) => (
          <MenuItem key={o.label} onClick={(e) => handleSelection(e, o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
