import { Button, styled } from '@mui/material'
import Box from '@mui/material/Box'
import React, { FC } from 'react'

const StyledButton = styled(Button)({
  fontSize: '0.75rem',
})

export interface DataTableFooterProps {
  onAddRow?: () => void
}

export const DataTableFooter: FC<DataTableFooterProps> = ({ onAddRow }) => {
  return (
    <Box>
      {onAddRow && (
        <StyledButton
          size="small"
          variant="text"
          color="primary"
          onClick={onAddRow}
          data-testid="add-row"
        >
          + Add
        </StyledButton>
      )}
    </Box>
  )
}
