import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import cookies from 'js-cookie'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useFetch } from '../../../../../utils/fetch/useFetch'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { EnvironmentSummary } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import { addGlobalEvent } from '../../../playback/GlobalEvents/addGlobalEvent'
import { LanguageChangeGlobalEvent } from '../../../playback/GlobalEvents/GlobalEventTypes'
import { enqueueNotif } from '../../../reducers/notifications'

enum EscapeStep {
  ProductInfo,
  EscapeWithFeedback,
  FeedbackOnly,
  Submitted,
}

const DarkTypography = styled(Typography)({
  color: '#000',
})

const Closer = ({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        color: '#000',
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  )
}

const EscapeHatchDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#fff',
  },
}))

const EscapeHatchEmphasizedListItem = ({ text }: { text: ReactNode | ReactNode[] }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon sx={{ minWidth: '30px' }}>
        <CircleIcon sx={{ color: '#37C773', fontSize: '1rem' }} />
      </ListItemIcon>
      <DarkTypography variant="subtitle1">{text}</DarkTypography>
    </ListItem>
  )
}

const EscapeHatchDeemphasizedListItem = ({ text }: { text: ReactNode | ReactNode[] }) => {
  return (
    <ListItem alignItems="flex-start" sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
      <ListItemIcon sx={{ minWidth: '20px' }}>
        <CircleIcon sx={{ color: '#000', fontSize: '0.5rem' }} />
      </ListItemIcon>
      <DarkTypography variant="subtitle1">{text}</DarkTypography>
    </ListItem>
  )
}

const FeedbackInput = styled(TextField)({
  marginTop: '0.4rem',
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000',
    '&.Mui-focused': {
      borderColor: '#000',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000',
    '&.Mui-focused': {
      color: '#000',
    },
  },
})

export const EscapeHatch = () => {
  const hasSeenDialog = useRef<boolean | null>(null)
  const [isShowingDialog, setIsShowingDialog] = useState(false)
  const [escapeStep, setEscapeStep] = useState<EscapeStep>(EscapeStep.ProductInfo)
  const [feedback, setFeedback] = useState('')
  const { firebaseAuthorId, isOwner, slug } = usePadConfigValues(
    'firebaseAuthorId',
    'isOwner',
    'slug'
  )
  const fetch = useFetch()
  const { environment: activeEnvironment } = useActiveEnvironment()
  const dispatch = useDispatch()

  // show the dialog the first time a user encounters it, and remember that they've seen it
  useEffect(() => {
    if (
      hasSeenDialog.current === null &&
      isOwner &&
      cookies.get('environmentsAnnouncement') !== '1'
    ) {
      setIsShowingDialog(true)
      cookies.set('environmentsAnnouncement', '1')
    }
  }, [isOwner])

  const handleDialogOpen = useCallback(() => {
    setEscapeStep(EscapeStep.ProductInfo)
    setFeedback('')
    setIsShowingDialog(true)
  }, [])

  const onEscape = useCallback(
    async (environment?: null | EnvironmentSummary) => {
      setEscapeStep(EscapeStep.Submitted)

      // if the user changed the language of the pad at any time, then we need to emit
      // a change-active-language event so playback is able to assign the correct file
      // to the changes
      if (environment?.language != null) {
        addGlobalEvent<LanguageChangeGlobalEvent>({
          type: 'change-active-language',
          user: firebaseAuthorId?.toString() ?? 'unknown',
          data: {
            language: environment.language,
            multifile: false,
            initialLanguage: false,
          },
        })
      }

      // POSTing to this endpoint triggers a page reload after it's done processing
      const response = await fetch(`/${slug}/escape_environments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ environment: environment?.slug, feedback }),
      })

      if (response.ok) {
        if (!environment) {
          dispatch(
            enqueueNotif({
              message: 'Thank you for submitting your feedback!',
              key: 'feedback-submitted',
              variant: 'success',
              autoDismissMs: 5000,
            })
          )
          setIsShowingDialog(false)
        }
      } else {
        setEscapeStep(EscapeStep.ProductInfo)
        setIsShowingDialog(false)
        dispatch(
          enqueueNotif({
            message:
              'There was an error reverting your pad to the classic UI.  Please try again, or contact support@coderpad.io if the problem persists.',
            key: 'escape-environments-error',
            variant: 'error',
            autoDismissMs: 10000,
          })
        )
      }
    },
    [dispatch, feedback, fetch, firebaseAuthorId, slug]
  )

  if (!activeEnvironment) {
    return null
  }

  return (
    <>
      <Button
        variant="text"
        sx={(theme) => ({ color: theme.palette.mode === 'dark' ? '#F6C442' : '#FF9900' })}
        startIcon={
          <NotificationsNoneIcon
            sx={(theme) => ({ color: theme.palette.mode === 'dark' ? '#F6C442' : '#FF9900' })}
          />
        }
        onClick={() => handleDialogOpen()}
      >
        Review New UI Options
      </Button>
      <EscapeHatchDialog open={isShowingDialog}>
        {escapeStep === EscapeStep.ProductInfo ? (
          <>
            <DialogTitle>
              We've made some updates to the product.
              <Closer onClick={() => setIsShowingDialog(false)} />
            </DialogTitle>
            <DialogContent>
              <DarkTypography variant="subtitle1">
                You can now:
                <List>
                  <EscapeHatchEmphasizedListItem text="Add questions, languages, or frameworks by clicking the + on the left to create new tabs." />
                  <EscapeHatchEmphasizedListItem
                    text={[
                      'Use more realistic interview environments with multi-file support for ',
                      <b key="supportedLangs">React, Angular, Vue, Svelte</b>,
                      ' and ',
                      <b key="supportedLangs2">HTML/CSS/JS.</b>,
                    ]}
                  />
                </List>
                Try our{' '}
                <a href="/sandbox" rel="noreferrer" target="_blank">
                  sandbox
                </a>{' '}
                or read the{' '}
                <a href="https://coderpad.io/resources/docs" rel="noreferrer" target="_blank">
                  documentation
                </a>{' '}
                to learn more about these new features.
              </DarkTypography>
              {isOwner && (
                <>
                  <DarkTypography variant="h3" pt={2} pb={2}>
                    Need the classic UI for this interview?
                  </DarkTypography>
                  <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ textTransform: 'none', fontSize: '1rem' }}
                      onClick={() => setEscapeStep(EscapeStep.EscapeWithFeedback)}
                    >
                      Next
                    </Button>
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', fontSize: '1rem' }}
                      onClick={() => setEscapeStep(EscapeStep.FeedbackOnly)}
                    >
                      Send feedback only
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          </>
        ) : escapeStep === EscapeStep.EscapeWithFeedback ? (
          <>
            <DialogTitle>
              <Box display="flex">Need to go back to the classic UI?</Box>
              <Closer onClick={() => setIsShowingDialog(false)} />
            </DialogTitle>
            <DialogContent>
              <FeedbackInput
                label="Please tell us why?"
                variant="outlined"
                fullWidth
                multiline
                value={feedback}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                  setFeedback(event.target.value)
                }
                rows={5}
              />
              <DarkTypography variant="subtitle1">
                <Box display="flex" mt={1}>
                  <ReportProblemIcon htmlColor="#F6C442" sx={{ paddingRight: '0.5rem' }} />
                  Please Proceed with Caution:
                </Box>
                <List>
                  <EscapeHatchDeemphasizedListItem text="The pad will reload immediately for all participants, please give them a verbal heads up." />
                  <EscapeHatchDeemphasizedListItem text="You will return to the active tab's language or the interviewer's default language." />
                  <EscapeHatchDeemphasizedListItem text="All Multi-file and unselected tabs will be deleted." />
                  <EscapeHatchDeemphasizedListItem text="Playback will not show work from any deleted tabs." />
                  <EscapeHatchDeemphasizedListItem text="Frontend frameworks are not available in the Classic UI." />
                </List>
              </DarkTypography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button variant="contained" color="error" onClick={() => onEscape(activeEnvironment)}>
                Reload to Classic UI
              </Button>
              <Button onClick={() => onEscape()}>Submit Feedback Only</Button>
            </DialogActions>
            <DarkTypography py={1} sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              As of November 30th, the UI updates will be permanent.
            </DarkTypography>
          </>
        ) : escapeStep === EscapeStep.FeedbackOnly ? (
          <>
            <DialogTitle>
              <Box display="flex" sx={{ paddingRight: '2rem' }}>
                How are the UI updates impacting your interviews?
              </Box>
              <Closer onClick={() => setIsShowingDialog(false)} />
            </DialogTitle>
            <DialogContent>
              <FeedbackInput
                label="Write Feedback"
                variant="outlined"
                fullWidth
                multiline
                value={feedback}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                  setFeedback(event.target.value)
                }
                rows={5}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onEscape()}>Send Feedback</Button>
            </DialogActions>
          </>
        ) : escapeStep === EscapeStep.Submitted ? (
          <DialogContent>
            <Box display="flex">
              <CircularProgress color="secondary" size={24} />
              <DarkTypography variant="subtitle1" pl={1}>
                Switching off the new features...
              </DarkTypography>
            </Box>
          </DialogContent>
        ) : null}
      </EscapeHatchDialog>
    </>
  )
}
