import React from 'react'

import makePromiseCancelable from '../make_promise_cancelable'
import renderTerminalHTML from '../terminal_html_renderer'
import executionPropType from './execution_prop_type'

export default class PlaybackSingleExecution extends React.PureComponent {
  static renderTerminalHTMLCache = {}
  static propTypes = {
    execution: executionPropType.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { html: null }
  }

  componentDidMount() {
    this.renderPromise = makePromiseCancelable(
      this.renderTerminalHTMLWithCache(this.props.execution.text)
    )
    this.renderPromise.promise
      .then((html) => this.setState({ html }))
      .catch((e) => {
        if (!e.isCanceled) throw e
      })
  }

  componentWillUnmount() {
    // In case renderPromise didn't resolve yet, cancel it.
    // This ensures we don't alter state of an unmounted component.
    this.renderPromise.cancel()
  }

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: this.state.html }}
        className={this.props.execution.type}
      />
    )
  }

  renderTerminalHTMLWithCache(input) {
    PlaybackSingleExecution.renderTerminalHTMLCache[input] =
      PlaybackSingleExecution.renderTerminalHTMLCache[input] || renderTerminalHTML(input)
    return PlaybackSingleExecution.renderTerminalHTMLCache[input]
  }
}
