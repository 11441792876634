import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

// DEPRECATED, use Button instead

export default class PadButton extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    danger: PropTypes.bool,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    modalId: PropTypes.string,
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    tooltip: PropTypes.shape({
      placement: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      trigger: PropTypes.string,
    }),
  }

  componentDidMount() {
    const tooltipOptions = this.props.tooltip

    if (tooltipOptions && this._el) $(this._el).tooltip(tooltipOptions)
  }

  componentWillUnmount() {
    if (this.props.tooltip) $(this._el).tooltip('destroy')
  }

  componentDidUpdate(prevProps, prevState) {
    const newTooltip = this.props.tooltip
    const oldTooltip = prevProps.tooltip

    if (oldTooltip && !newTooltip) {
      $(this._el).tooltip('destroy')
    } else if (newTooltip) {
      $(this._el).tooltip(newTooltip)
    }
  }

  render() {
    const {
      active,
      children,
      className,
      danger,
      disabled,
      onClick,
      href,
      modalId,
      primary,
    } = this.props

    const buttonProps = {
      className: classNames(className, {
        'pad-outline-button': true,
        active,
        danger,
        'btn-primary': primary,
      }),
      disabled: disabled && 'disabled',
      ['data-toggle']: modalId && 'modal',
      ['data-target']: modalId && `#${modalId}`,
      onClick: onClick || undefined,
      href: href || undefined,
    }
    if (href) return <a {...buttonProps}>{children}</a>
    else
      return (
        <button ref={(el) => (this._el = el)} {...buttonProps}>
          {children}
        </button>
      )
  }
}
