import classNames from 'classnames'
import React, { CSSProperties, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues, usePadContext } from '../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import FocusTimeOverlay from '../focus_time_overlay'
import { selectEditorSettings, selectPadSettings } from '../selectors'
import Title from '../title'
import { CodeSpace } from './CodeSpace/CodeSpace'
import { DrawingSpace } from './DrawingSpace/DrawingSpace'
import { FullScreenDrawing } from './FullScreenDrawing/FullScreenDrawing'
import { FullScreenInstructions } from './FullScreenInstructions/FullScreenInstructions'
import { isFullScreenInstructionsUrl } from './util'

/**
 * Provides the basic layout for the pad view and handles showing/hiding the Focus Time overlay.
 *
 * |--------------------------------------|
 * |       <pad view content here />      |
 * |                                      |
 * |--------------------------------------|
 * |       <pad footer here />            |
 * |--------------------------------------|
 *
 * The pad view content can be one of three things:
 * 1. CodeSpace: The traditional left code pane + resources/notes right pane.
 * 2. FullScreenDrawing: When a CodeSpace opens a new window with a fullscreen Drawing Mode.
 * 3. DrawingSpace: Left Drawing Mode Pane + resources/notes right pane.
 *
 * If the candidate or interviewer enables Focus Time, the FT overlay will be shown, obscuring the view on
 * the interviewer's side.
 */

export function Workspace() {
  const { fontSize } = useSelector(selectEditorSettings)
  const { focusTimeEnabled, language, title } = useSelector(selectPadSettings)
  const isUnderOverlay = useSelector((state) => state.userState.showCandidateOverlay)

  const { isDrawingPad } = usePadContext()
  const {
    iioLayout,
    takeHome,
    isPlayback,
    isOwner,
    isQuestionDraftPreview,
    isSandbox,
    slug,
  } = usePadConfigValues(
    'iioLayout',
    'takeHome',
    'isPlayback',
    'isOwner',
    'isQuestionDraftPreview',
    'slug',
    'isSandbox'
  )

  const styles: CSSProperties = {
    fontSize,
  }
  if (iioLayout || isQuestionDraftPreview) styles.bottom = 0

  const isFullScreenDrawing =
    !takeHome && !isPlayback && window.location.search.indexOf('drawing') > 0
  const showFocusTimeOverlay = focusTimeEnabled && isOwner
  const isFullScreenInstructions = isFullScreenInstructionsUrl()

  const workspaceContent = useMemo(() => {
    return isFullScreenDrawing ? (
      <FullScreenDrawing />
    ) : isFullScreenInstructions ? (
      <FullScreenInstructions />
    ) : isDrawingPad ? (
      <DrawingSpace />
    ) : (
      <CodeSpace />
    )
  }, [isFullScreenDrawing, isFullScreenInstructions, isDrawingPad])

  const { environment } = useActiveEnvironment()

  const documentTitle = useMemo(() => {
    const prefix = window.CoderPad.ENVIRONMENT === 'development' ? '[DEV] ' : ''

    if (isSandbox) {
      return prefix + 'Sandbox | CoderPad'
    }

    const displayLang = isDrawingPad
      ? 'Drawing'
      : environment
      ? environment?.nameDisplayable
      : window.CoderPad.LANGUAGES[language]?.display

    if (title && title !== `Untitled Pad - ${slug}`) {
      return prefix + title.trim()
    } else {
      return prefix + `${displayLang} | CoderPad`
    }
  }, [language, title, isDrawingPad, isSandbox, slug, environment])

  return (
    <div
      className={classNames('Workspace', {
        'is-fullScreenView': isFullScreenDrawing || isFullScreenInstructions,
      })}
      style={styles}
      aria-hidden={isUnderOverlay}
    >
      <Title>{documentTitle}</Title>
      <div className={classNames('Workspace-content', { 'is-blurred': showFocusTimeOverlay })}>
        {workspaceContent}
      </div>
      {showFocusTimeOverlay && <FocusTimeOverlay />}
    </div>
  )
}
