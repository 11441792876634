import { gql, useQuery } from '@apollo/client'

import * as queryStates from '../../queryStates'
import { ProjectTemplate } from '../../types'

export const GET_PROJECT_TEMPLATE_QUERY = gql`
  query ProjectTemplate($slug: String!) {
    projectTemplate(slug: $slug) {
      id
      name
      slug
      category
      version
      latest
      settings
    }
  }
`

export interface IUseProjectTemplateData {
  projectTemplate: ProjectTemplate
}

export function useProjectTemplate(slug: string) {
  const { data, loading, error, refetch } = useQuery<IUseProjectTemplateData>(
    GET_PROJECT_TEMPLATE_QUERY,
    {
      variables: {
        slug,
      },
      context: {
        source: 'useProjectTemplate.ts',
      },
      fetchPolicy: 'cache-first',
      skip: slug == null,
    }
  )

  // Discern the status of the query.
  let status: queryStates.QueryState = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error('There was an error while fetching the project template.', error)
  } else if (data?.projectTemplate === null) {
    status = queryStates.error('Unable to find project template.')
  } else {
    status = queryStates.success()
  }

  return {
    projectTemplate: data?.projectTemplate,
    status,
    refetch,
  }
}
