import { Box, styled } from '@mui/material'
import { Tab, TabList, TabPanel, Tabs } from 'packs/dashboard/components/Tabs'
import React, { useMemo } from 'react'

import { ScrollView } from '../../ScrollView/ScrollView'
import { useRequestClientSelected } from '../RequestClientSelectedContext/RequestClientSelectedContext'
import { ResponseBody } from './tabs/ResponseBody'
import { ResponseHeaders } from './tabs/ResponseHeaders'

const StyledTabPanel = styled(TabPanel)({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  marginTop: 16,
  padding: 0,
  minHeight: 0,
})

const contentTypeToMonaco = (contentType: string) => {
  switch (contentType) {
    case 'application/json':
      return 'json'
    case 'application/xml':
      return 'xml'
    case 'text/html':
      return 'html'
    case 'text/css':
      return 'css'
    case 'text/javascript':
      return 'javascript'
    case 'text/plain':
      return 'text'
    default:
      return 'text'
  }
}

export const RequestClientResponse: React.FC = () => {
  const { currentlyViewingRequestResponse } = useRequestClientSelected()

  const responseBodyTypes = useMemo(() => {
    const contentType = currentlyViewingRequestResponse?.headers['content-type']?.[0]
    const types = (contentType?.split(';') || []).map((t) => t.trim())
    return types
  }, [currentlyViewingRequestResponse])

  return (
    <Box mt={1} display="flex" flexDirection="column" flex="1 1 auto" height="100%" minHeight={0}>
      <Tabs size="small">
        <TabList>
          <Tab value="body" label="Body" />
          <Tab value="headers" label="Headers" />
        </TabList>
        <StyledTabPanel value="body">
          <ResponseBody
            bodyType={contentTypeToMonaco(responseBodyTypes[0])}
            bodyText={currentlyViewingRequestResponse?.body || ''}
          />
        </StyledTabPanel>
        <StyledTabPanel value="headers">
          <ScrollView>
            <ResponseHeaders headers={currentlyViewingRequestResponse?.headers || {}} />
          </ScrollView>
        </StyledTabPanel>
      </Tabs>
    </Box>
  )
}
