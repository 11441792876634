import _ from 'lodash'

// Editor settings (which are specific to a user).
//
// Not to be confused with pad-wide settings like language and isPublic.
//
// This reducer is very simple, because it trusts the actions fired to provide
// reasonable keys and values.
//
// The expected contents are:
//
//   autoCloseBrackets: boolean
//   autocomplete: boolean
//   darkColorScheme: boolean
//   fontSize: number
//   keymap: string, one of "sublime", "vim", "emacs"
//   tabSizes: object, mapping strings like "c" and "python3" to numbers

export default function editorSettingsReducer(state = {}, action) {
  switch (action.type) {
    case 'editor_setting_changed': {
      const { key, value } = action

      // use _.set to allow setting tabSizes.c, tabSizes.erlang, etc
      const diff = _.set({}, key, value)
      return _.merge({}, state, diff)
    }
  }
  return state
}

export function editorSettingChanged(key, value) {
  return {
    type: 'editor_setting_changed',
    key,
    value,
  }
}
