import _ from 'lodash'
import { select, takeEvery } from 'redux-saga/effects'

import { pathToFileId } from '../../../utils/multifile'
import { monacoContextEmitter } from '../Monaco/MonacoContext'
import { selectExecEnabled } from '../selectors'
import SyncHandle from '../sync_handle'

// Async task to handle updating contents of editor

export default function* editorSaga(initialLanguage) {
  let lastFileId = null

  yield takeEvery('pad_setting_changed', function* ({ key, value, remote, userId }) {
    if (key === 'language') {
      monacoContextEmitter.emit('languageChange', { language: value, fromRemote: !!remote })

      const execEnabled = yield select(selectExecEnabled)
      if (remote || !execEnabled) {
        return
      }

      const langFiles = CoderPad.LANGUAGES[value]?.files || []
      for (const langFile of langFiles) {
        const fileId = pathToFileId(langFile.path)
        const existingFile = (yield select((state) => state.files[fileId])) || {}
        const fileEditor = existingFile.editor
        if (!fileEditor) {
          continue
        }

        // A multifile file may be blank if it was generated while adding
        // an HTML question with empty multifiles.
        // TODO: Comment out existing code when moving between languages
        // with multifiles sharing the same paths.
        const editorValue = fileEditor.getValue().trim()
        if (!editorValue) {
          fileEditor.setValue(langFile.example)
        }
      }
    }
  })

  // TODO: kill this along with CodeMirror
  yield takeEvery('global_event_active_file', function* ({ globalEvent }) {
    // only save the active file state if it's changed.
    if (globalEvent.data.file.fileId !== lastFileId) {
      SyncHandle().push('globalEvents', globalEvent)
      lastFileId = globalEvent.data.file.fileId
    }
  })

  yield takeEvery(
    'question_selected_by_local_user',
    function* ({ append, contents, fileContents, questionId, language, starterCodeByLanguage }) {
      monacoContextEmitter.emit('questionSelectedByLocalUser', {
        language,
        contents,
        questionId,
        append,
        starterCodeByLanguage,
      })

      if (fileContents) {
        const questionFiles = _.keyBy(fileContents, 'path') || {}

        const langFiles = CoderPad.LANGUAGES[language]?.files || []
        for (const langFile of langFiles) {
          const fileId = pathToFileId(langFile.path)

          const langFileContents = questionFiles[langFile.path]?.contents || ''
          // for monaco users, we won't load three editors at once that are connected to three different
          // firebase locations.  so, we need to update firebase with the question's contents for the
          // non-global files
          const headlessFirepad = SyncHandle().firepadHeadless(`files/${fileId}`)
          headlessFirepad.setText(langFileContents)
        }
      }
    }
  )
}
