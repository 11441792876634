import React, { createContext, FC, useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

export interface EnvironmentFileState {
  [environment: string]: {
    [file: string]: boolean
  }
}

export interface IEnvironmentsPlaybackMockContext {
  activeEnvironmentId: string | null
  setActiveEnvironmentId: (id: string | null) => void
  frameIndex: number

  visibleEnvironments: string[]
  setVisibleEnvironments: React.Dispatch<React.SetStateAction<string[]>>
  setEnvironmentVisibility: (id: string, visible: boolean) => void
}

const defaultPlaybackContext: IEnvironmentsPlaybackMockContext = {
  activeEnvironmentId: null,
  setActiveEnvironmentId: () => null,
  frameIndex: 0,

  visibleEnvironments: [],
  setVisibleEnvironments: () => null,
  setEnvironmentVisibility: () => null,
}

const PlaybackContext = createContext<IEnvironmentsPlaybackMockContext>(defaultPlaybackContext)

/**
 * Convenience hook for accessing the playback context.
 */
export function useEnvironmentsPlaybackMock() {
  const context = useContext(PlaybackContext)

  if (!context) {
    throw new Error(
      '`useEnvironmentsPlaybackMock` hook must be a descendant of a `EnvironmentsPlaybackMockProvider`'
    )
  }

  return context
}

/**
 * This is a high-level component that should wrap all over environments
 * during playback. It mocks data the active and visible environments.
 *
 * This will act as the single source of truth for environment data in
 * playback.
 */
export const EnvironmentsPlaybackMockProvider: FC = ({ children }) => {
  const [activeEnvironmentId, setActiveEnvironmentId] = useState<string | null>(null)
  const [visibleEnvironments, setVisibleEnvironments] = useState<string[]>([])

  const setEnvironmentVisibility = useCallback((environmentId: string, visible: boolean) => {
    if (visible) {
      setVisibleEnvironments((prev) => [...prev, environmentId])
    } else {
      setVisibleEnvironments((prev) => prev.filter((id) => id !== environmentId))
    }
  }, [])

  // For now, we fetch this from the redux store.
  // TODO: store this state value in this component as we phase out legacy playback components
  const frameIndex = useSelector((state) => state.playbackHistory.frameIndex)
  // TODO: store playing, frameIndex, speed, frameDelay, etc in this component instead of redux

  return (
    <PlaybackContext.Provider
      value={{
        ...defaultPlaybackContext,
        activeEnvironmentId,
        setActiveEnvironmentId,
        frameIndex,
        visibleEnvironments,
        setVisibleEnvironments,
        setEnvironmentVisibility,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  )
}
