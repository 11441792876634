import _ from 'lodash'

import * as queryStates from '../../../graphql/queryStates'

// Active pad question info
export default function questionReducer(
  state = { createQuestionFromPadStatus: queryStates.initial() },
  action
) {
  switch (action.type) {
    case 'environment_question_changed':
    case 'question_data_loaded':
    case 'question_selected_by_local_user': {
      const visibleTestCases =
        action.visibleTestCases || _.filter(action.testCases || [], 'visible')

      return {
        ...state,
        candidateInstructions: action.candidateInstructions,
        customFiles: action.customFiles,
        language: action.language,
        questionId: action.questionId,
        testCasesEnabled: action.testCasesEnabled,
        starterCodeByLanguage: action.starterCodeByLanguage,
        solution: action.solution,
        visibleTestCases,
      }
    }
    case 'test_cases_graded': {
      return {
        ...state,
        visibleTestCaseResults: action.visibleTestCaseResults,
        codeTestedAt: Date.now(),
      }
    }
    case 'editor_modified': {
      return {
        ...state,
        codeModifiedAt: Date.now(),
      }
    }

    case 'create_question_from_pad_status':
      return { ...state, createQuestionFromPadStatus: action.payload.status }
  }
  return state
}

export function createQuestionFromPadStatus(status) {
  return {
    type: 'create_question_from_pad_status',
    payload: {
      status,
    },
  }
}
