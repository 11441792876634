import { monaco, registerDefaultConfigurations } from '@codingame/monaco-editor-wrapper'

import { currentConfig } from '../editor_settings_cookie'

export function getMonacoTheme(isDarkMode = true) {
  return isDarkMode ? 'Default Dark+' : 'Default Light+'
}

export function setCoderpadTheme(isDarkMode = true) {
  monaco.editor.setTheme(getMonacoTheme(isDarkMode))
}

// Set the default theme to the one stored in cookies
setCoderpadTheme(currentConfig()['dark_color_scheme'])

setTimeout(() => {
  // Not sure why it only works in a setTimeout
  // It looks like it gets overwritten otherwise
  registerDefaultConfigurations([
    {
      'workbench.colorCustomizations': {
        '[Default Dark+]': {
          'editor.background': '#1E2126',
          'editor.titlebar.background': '#22282d',
          'editor.titlebar.foreground': '#BDBDBD',
          'editor.titlebar.userIndicatorBackground': '#313439',
          'editor.titlebar.userIndicatorForeground': '#BDBDBD',
          'sidebar.background': '#313439',
          'sidebar.foreground': '#9A9A9A',
          'sidebar.activeSelectionBackground': '#22282D',
          'sidebar.activeSelectionForeground': '#FFFFFF',
          'statusBar.background': '#22282e',
          'statusBar.foreground': '#FFFFFF',
          'statusBar.border': '#2d353c',
          errorForeground: '#f1c40f',
        },
        '[Default Light+]': {
          'editor.titlebar.background': '#fafafa',
          'editor.titlebar.foreground': '#000000',
          'editor.titlebar.userIndicatorBackground': '#ededed',
          'editor.titlebar.userIndicatorForeground': '#21252A',
          'sidebar.background': '#ededed',
          'sidebar.foreground': '#20252a',
          'sidebar.activeSelectionBackground': '#dadada',
          'sidebar.activeSelectionForeground': '#21252a',
          'statusBar.background': '#ffffff',
          'statusBar.foreground': '#20252a',
          'statusBar.border': '#dadada',
          errorForeground: '#f1c40f',
        },
      },
    },
  ])
}, 0)
