import { createSelector } from 'reselect'

import { REWIND_MS } from '../playback/constants'

export const getCanRewind = createSelector(
  (state) => state.playbackHistory,
  (playbackHistory) => {
    let canRewind = false

    if (playbackHistory.frames) {
      const firstFrame = playbackHistory.frames[1]
      const currentFrame = playbackHistory.frames[playbackHistory.frameIndex]

      // Rewind is only available if the current frame is at least the rewind ms after the first frame's timestamp
      canRewind = !!(
        firstFrame &&
        currentFrame &&
        currentFrame.timestamp - firstFrame.timestamp > REWIND_MS
      )
    }

    return canRewind
  }
)

export const getIsPlaybackAccelerated = createSelector(
  (state) => state.playbackHistory.playbackSpeed,
  (speed) => speed > 1
)
