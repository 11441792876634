import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Snippet } from 'graphql/types'
import React from 'react'

export const SnippetInfoDialog = ({
  snippet,
  onRequestClose,
}: {
  snippet: Snippet | null
  onRequestClose: () => void
}) => {
  if (snippet == null) {
    return null
  }

  return (
    <Dialog open={true} onClose={onRequestClose}>
      <DialogTitle variant="h2" component="div">
        {snippet.title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{snippet.description}</Typography>
      </DialogContent>
    </Dialog>
  )
}
