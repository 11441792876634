import { gql } from '@apollo/client'
import { channel } from 'redux-saga'
import { put, select, takeEvery } from 'redux-saga/effects'

import * as queryStates from '../../../graphql/queryStates'
import { monacoContextEmitter } from '../Monaco/MonacoContext'
import { createQuestionFromPadStatus } from '../reducers/question'

// This is a channel being used to get around supporting dispatching an action from within a Promise callback.
const statusProxy = channel()

export default function* questionsSaga(apolloClient) {
  let editor = null

  yield takeEvery('editor_mounted', function* (action) {
    if (!action.fileId || action.fileId === '0_global') {
      editor = action.editor
    }
  })

  yield takeEvery('question_data_loaded', function* ({ starterCodeByLanguage }) {
    monacoContextEmitter.emit('questionDataLoaded', { starterCodeByLanguage })
  })

  // For any event on this channel, dispatch its payload as a redux action.
  yield takeEvery(statusProxy, function* (statusAction) {
    yield put(statusAction)
  })

  yield takeEvery('create_question_from_pad', function* (action) {
    if (editor) {
      const language = yield select((state) => state.padSettings.language)

      yield put(createQuestionFromPadStatus(queryStates.loading()))

      apolloClient
        .mutate({
          mutation: gql`
            mutation CreateQuestion($input: CreateQuestionInput!) {
              createQuestion(input: $input) {
                question {
                  id
                }
                errors {
                  message
                  path
                }
                message
              }
            }
          `,
          variables: {
            input: {
              questionAttributes: {
                isDraft: true,
                language,
                contents: editor.getValue(),
              },
            },
          },
        })
        .then(({ data }) => {
          if (data.createQuestion?.question?.id) {
            statusProxy.put(createQuestionFromPadStatus(queryStates.success()))
            window.open(`/dashboard/questions/edit/${data.createQuestion.question.id}`)
          } else {
            statusProxy.put(
              createQuestionFromPadStatus(
                queryStates.error(data?.createQuestion?.message || 'Unable to create question.')
              )
            )
          }
        })
        .catch((e) => {
          statusProxy.put(
            createQuestionFromPadStatus(queryStates.error('Unable to create question.', e))
          )
        })
    }
  })
}
