import { Box, Button, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCurrentUser } from '../../graphql/hooks/users/useCurrentUser/useCurrentUser'
import * as queryStates from '../../graphql/queryStates'
import { selectMultifileEnabled } from './selectors'

function QuestionFromPadButton() {
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const questionCreateStatus = useSelector((state) => state.question.createQuestionFromPadStatus)

  const is3Pane = useSelector(selectMultifileEnabled)
  if (is3Pane) {
    return <></>
  }

  const onClick = () => {
    dispatch({ type: 'create_question_from_pad' })
  }

  return (
    <div>
      <Tooltip
        arrow
        placement="top"
        title={
          !currentUser?.allowQuestionCreation
            ? 'Only admins have access to create and edit questions with the ' +
              currentUser?.organization?.name +
              ' question bank'
            : ''
        }
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={onClick}
            disabled={
              queryStates.isLoadingState(questionCreateStatus) ||
              !currentUser?.allowQuestionCreation
            }
            data-testid="qb-question-from-pad"
          >
            Create Question From This Pad
          </Button>
        </span>
      </Tooltip>
      <Box mt={1}>
        {queryStates.isErrorState(questionCreateStatus) && (
          <Typography color="error" component="div">
            An error occurred while creating your question.
          </Typography>
        )}
      </Box>
    </div>
  )
}

export default QuestionFromPadButton
