export interface Request {
  id: string
  status: RequestStatus
  error?: string
  timings: RequestTimings
  config: RequestConfig
  response?: RequestResponse
}

export enum RequestStatus {
  Pending = 'pending',
  Done = 'done',
  Aborted = 'aborted',
  Timeout = 'timeout',
  BodyTooLarge = 'body_too_large',
  RequestError = 'request_error',
  UnknownError = 'unknown_error',
}

export enum RequestMethod {
  Get = 'GET',
  Delete = 'DELETE',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Purge = 'PURGE',
  Link = 'LINK',
  Unlink = 'UNLINK',
}

export interface RequestConfig {
  path: string
  method: RequestMethod
  headers: Record<string, string | string[]>
  body: string | null
  bodyTruncated?: boolean
}

export interface RequestTimings {
  startedAt: string
  headersReceivedAt?: string
  finishedAt?: string
}

export interface RequestResponse {
  statusCode: number
  headers: Record<string, string | string[]>
  contentSize?: number
  body?: string
  bodyTruncated?: boolean
}

export interface RequestSummary {
  id: string
  path: string
  method: RequestMethod
  status: RequestStatus
  startedAt: string
}
