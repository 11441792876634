import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { ProjectOutput } from '../ProjectOutput'
import { AutoscrollingExecutionList } from './Execution/AutoscrollingExecutionList'
import { PlaybackExecutionHTML } from './Execution/PlaybackExecutionHTML'
import { useGlobalEvents } from './GlobalEvents/useGlobalEvents'
import { useConsoleHistory } from './hooks/useConsoleHistory'
import { usePlayback } from './PlaybackContext'
import { Execution } from './types'

export interface PlaybackOutputProps {
  hidden: boolean
}

export const PlaybackOutput: FC<PlaybackOutputProps> = ({ hidden }) => {
  const language = useSelector((state) => state.padSettings.language)
  const frameLength = useSelector((state) => state.playbackHistory.frameLength)
  const { currentTimestamp } = useGlobalEvents()
  const { environment, projectTemplate } = useActiveEnvironment()
  const { frameIndex } = usePlayback()
  const consoleHistory = useConsoleHistory()
  const [visibleExecutions, setVisibleExecutions] = useState<Execution[]>([])

  useEffect(() => {
    if (!currentTimestamp || !consoleHistory) return
    const executions = Object.values(consoleHistory)
    // If we're at the end of the timeline, don't filter executions.
    if (frameIndex === frameLength - 1) {
      setVisibleExecutions(executions)
    } else {
      const newVisibleExecutions = executions.filter(
        (execution) => execution.timestamp <= currentTimestamp
      )
      if (newVisibleExecutions.length !== visibleExecutions.length) {
        setVisibleExecutions(newVisibleExecutions)
      }
    }
  }, [currentTimestamp, consoleHistory, frameIndex, frameLength, visibleExecutions.length])

  if (hidden || !currentTimestamp) {
    return null
  } else if (environment?.projectTemplateSlug != null) {
    return projectTemplate?.settings?.executionType !== 'RunCommand' ? (
      <ProjectOutput hidden={hidden} />
    ) : (
      <AutoscrollingExecutionList executions={visibleExecutions} />
    )
  } else if (visibleExecutions.length === 0) {
    return null
  } else {
    const ExecutionRenderer =
      language === 'html' ? PlaybackExecutionHTML : AutoscrollingExecutionList

    return (
      <>
        <ExecutionRenderer executions={visibleExecutions} />
      </>
    )
  }
}
