import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { makeUseOrganizationHook } from '../../../../../graphql/hooks/organization/useOrganization/useOrganization'
import { useQuestionCounts } from '../../../../../graphql/hooks/question/useQuestionCounts/useQuestionCounts'
import { useQuestionFilters } from '../../../../../graphql/hooks/question/useQuestionFilters/useQuestionFilters'
import {
  ExampleQuestionSort,
  QueryExampleQuestionsSearchArgs,
  QuestionPadTypes,
  QuestionSort,
} from '../../../../../graphql/types'
import { LoadingBlock } from '../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { ScrollView } from '../../../ScrollView/ScrollView'
import { ExampleDetail } from './ExampleDetail/ExampleDetail'
import { ExampleList } from './ExampleList'
import { QuestionClassificationToggle } from './QuestionClassificationToggle'
import { QuestionDetail } from './QuestionDetail/QuestionDetail'
import { QuestionFilters } from './QuestionFilters'
import { QuestionList } from './QuestionList'

interface IQuestionDetailMeta {
  id: string | number
  isExampleQuestion: boolean
}

export const QuestionEnvironments: React.FC<{
  onSelect: (id: number | string, envOpts?: Record<string, any>) => void
}> = ({ onSelect }) => {
  const { organization } = makeUseOrganizationHook(`name`)()
  const { filters: questionFilters, setFilter: setQuestionFilter } = useQuestionFilters({
    text: '',
    userOnly: false,
    perPage: 100,
    padTypes: [QuestionPadTypes.Live, QuestionPadTypes.Any],
    sort: [QuestionSort.FavoritedAsc, QuestionSort.LastUsedAtDesc],
    // Not addressing test cases with environments at this time, so filter those out.
    testCasesEnabled: false,
  })
  const { loading: loadingCounts, counts } = useQuestionCounts()
  const [exampleFilters, setExampleFilters] = useState<QueryExampleQuestionsSearchArgs>({
    text: '',
    perPage: 100,
    // Not addressing test cases with environments at this time, so filter those out.
    testCasesEnabled: false,
    sort: [ExampleQuestionSort.SortPrecedenceDescThenTitleAsc],
    language: null,
  })
  const [isExampleList, setIsExampleList] = useState(false)

  const [detailMeta, setdetailMeta] = useState<IQuestionDetailMeta | null>(null)
  const tabsInitializedRef = useRef(false)

  useEffect(() => {
    if (!loadingCounts && !tabsInitializedRef.current) {
      if (counts.user > 0) {
        setIsExampleList(false)
        setQuestionFilter('userOnly', true)
      } else if (counts.all > 0) {
        setIsExampleList(false)
        setQuestionFilter('userOnly', false)
      } else {
        setIsExampleList(true)
        setQuestionFilter('userOnly', false)
      }
      tabsInitializedRef.current = true
    }
  }, [counts, loadingCounts, setQuestionFilter])

  const onQuestionClassificationToggle = useCallback(
    (isExample: boolean, userOnly: boolean) => {
      if (isExample) {
        setIsExampleList(true)
      } else {
        setQuestionFilter('userOnly', userOnly)
        setIsExampleList(false)
      }
    },
    [setQuestionFilter]
  )

  let detailContents
  if (!detailMeta) {
    detailContents = (
      <Box whiteSpace="break-spaces" textAlign="center" mt={1.5} maxWidth="490px" margin="auto">
        <Typography variant="h4" component="div">
          Welcome to your question bank
        </Typography>
        <div>
          <p>
            Questions you
            {organization && ' and your colleagues'} have written, as well as example questions, are
            listed on the left.
          </p>
          <p>Search for questions to use in live interviews.</p>
        </div>
      </Box>
    )
  } else if (detailMeta.isExampleQuestion) {
    detailContents = (
      <ExampleDetail exampleQuestionId={detailMeta.id as string} onSelect={onSelect} />
    )
  } else {
    detailContents = <QuestionDetail questionId={detailMeta.id as number} onSelect={onSelect} />
  }

  return (
    <LoadingBlock isLoading={loadingCounts} message="Searching questions" minDisplayMS={0}>
      <Box display="flex" height="100%">
        <Box flex={1} p={3} minWidth="320px">
          <Box display="flex" flexDirection="column" width="100%" height="100%">
            {isExampleList ? (
              <QuestionFilters
                key="exmapleFilters"
                textSearchVal={exampleFilters.text!}
                langSearchVal={exampleFilters.language || 'any'}
                onTextChange={(val) => setExampleFilters({ ...exampleFilters, text: val })}
                onLangChange={(val) => setExampleFilters({ ...exampleFilters, language: val })}
              />
            ) : (
              <QuestionFilters
                key="questionFilters"
                textSearchVal={questionFilters.text!}
                langSearchVal={questionFilters.language || 'any'}
                onTextChange={(val) => setQuestionFilter('text', val)}
                onLangChange={(val) => setQuestionFilter('language', val)}
              />
            )}
            <Box display="flex" justifyContent="center" mt={1}>
              <QuestionClassificationToggle
                example={isExampleList}
                onToggle={onQuestionClassificationToggle}
                userOnly={!!questionFilters.userOnly}
              />
            </Box>
            <Box flex={1} mt={3} minHeight={0}>
              {isExampleList ? (
                <ExampleList
                  filters={exampleFilters as QueryExampleQuestionsSearchArgs}
                  setFilter={(name, value) =>
                    setExampleFilters({ ...exampleFilters, [name]: value })
                  }
                  onSelect={(exampleId: string) =>
                    setdetailMeta({ id: exampleId, isExampleQuestion: true })
                  }
                  selectedExampleId={detailMeta?.id as string}
                />
              ) : (
                <QuestionList
                  filters={questionFilters}
                  setFilter={setQuestionFilter}
                  onSelect={(questionId: number) =>
                    setdetailMeta({ id: questionId, isExampleQuestion: false })
                  }
                  selectedQuestionId={detailMeta?.id as number}
                />
              )}
            </Box>
          </Box>
        </Box>
        <ScrollView
          sx={{
            width: 0,
            minWidth: 0,
            overflow: 'auto',
            transition: '0.25s all',
            flex: 2,
            padding: 3,
            borderLeft: '2px solid',
            borderColor: 'background.default',
          }}
        >
          {detailContents}
        </ScrollView>
      </Box>
    </LoadingBlock>
  )
}
