import PropTypes from 'prop-types'
import React from 'react'

export default class SpinnerPulse extends React.Component {
  static propTypes = {
    delay: PropTypes.number, // delay in msec before showing
  }

  constructor(props) {
    super(props)
    this.state = { hidden: props.delay > 0 }
  }

  componentDidMount() {
    if (this.props.delay > 0)
      this._showTimer = setTimeout(() => this.setState({ hidden: false }), this.props.delay)
  }

  componentWillUnmount() {
    clearTimeout(this._showTimer)
  }

  render() {
    return this.state.hidden ? null : (
      <div className="ball-pulse">
        <div />
        <div />
        <div />
      </div>
    )
  }
}
