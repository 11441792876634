import ErrorStackParser from 'error-stack-parser'
import _ from 'lodash'

// This 3rd-party lib ^ handles browser differences re: how the error stack
// is formatted, but has an annoying interface that creates mutable class instances
// for no reason.
//
// Here's a helper function to return plain objects instead.
// It'll throw if it can't parse the error.

export default function parseErrorStack(errorObject) {
  return ErrorStackParser.parse(errorObject).map(f =>
    _.pick(f, [
      'functionName',
      'args',
      'fileName',
      'lineNumber',
      'columnNumber',
      'isEval',
      'isNative',
    ])
  )
}
