import SyncHandle from 'packs/main/sync_handle'
import { useEffect, useState } from 'react'

import { TranscriptEntry, TranscriptEntryKind } from '../types'

export const useTranscripts = () => {
  const [transcripts, setTranscripts] = useState<TranscriptEntry[]>([])

  useEffect(() => {
    const watcher = SyncHandle().watch(
      'transcript',
      (data: { [userId: string]: Omit<TranscriptEntry, 'id'> }) => {
        const newTranscripts = Object.entries(data ?? {})
          .sort(([, a], [, b]) => a.timestamp - b.timestamp)
          .map(
            ([id, transcript]) =>
              ({
                ...transcript,
                id,
              } as TranscriptEntry)
          )
          .filter((t) => {
            if (t.kind === TranscriptEntryKind.Transcript) {
              return t.transcripts.length > 0 && t.transcripts[t.preferredTranscript] != null
            }
            // Safe to assume that if it's not a transcript, it's a system message and should be included.
            return true
          })
        setTranscripts(newTranscripts)
      },
      {}
    )

    return () => {
      SyncHandle().off('transcript', watcher)
    }
  }, [])

  return transcripts
}
