import React, { useMemo } from 'react'

import { useActiveEnvironment } from '../../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import RunButton from '../../../../Run/RunButton'
import { ProjectRunAction } from './ProjectRunAction'
import { ProjectSaveAction } from './ProjectSaveAction'

export function PrimaryAction() {
  const { environment, projectTemplate } = useActiveEnvironment()

  const btn = useMemo(() => {
    if (projectTemplate?.settings?.executionType === 'RunCommand') {
      return <ProjectRunAction />
    } else if (environment?.projectTemplateSlug) {
      return <ProjectSaveAction />
    } else {
      return <RunButton />
    }
  }, [projectTemplate, environment])

  return btn
}
