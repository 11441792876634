import { put, select, takeEvery } from 'redux-saga/effects'

// For take-home pads with a timer, this saga updates the minutes elapsed,
// and checks when the pad must be submitted.

export default function* takeHomeSaga() {
  // Start the timer after the candidate reads the instructions overlay.
  yield takeEvery('candidate_overlay_hidden', openTakeHome)

  // If the user logs in and there's no candidate overlay because they've
  // previously seen it, then start the take-home pad.
  yield takeEvery('logged_in', function*({ username }) {
    if (yield select(state => (state.padSettings.openedAt || state.padSettings.startedAt ) || state.userState.showCandidateOverlay)) { return }
    yield openTakeHome()
  })
}

function* openTakeHome() {
  try {
    const result =
      yield fetch(`/${window.padConfig.slug}/open_take_home`, { method: 'POST' })
      .then(response => response.json())
      yield put({
        type: 'pad_setting_changed',
        key: 'openedAt',
        value: result.opened_at || result.started_at,
      })    
     } catch (error) {
       // TODO: Better error messaging
      console.log(error)
      alert("Couldn't open the pad. Please check your network connection, reload the page and try again.")
      console.log('error', error)
     }
   }
