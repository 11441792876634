import { Box, Stack, TextField, Typography } from '@mui/material'
import React, { memo, useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { usePadEmailInviteSend } from '../../../graphql/hooks'
import { isLoadingState } from '../../../graphql/queryStates'
import { useAnalytics, validateEmail } from '../../../utils'
import { LoadingButton } from '../../dashboard/components/LoadingButton/LoadingButton'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { PadAnalyticsEvent, PadTranslation } from '../constants'
import { enqueueNotif } from '../reducers/notifications'

interface PadInvitesEmailFormData {
  email: string
}

export interface PadInvitesEmailProps {
  isOpen: boolean
}

export const PadInvitesEmail = memo<PadInvitesEmailProps>((props) => {
  const { isOpen } = props
  const { track } = useAnalytics()
  const dispatch = useDispatch()
  const { control, errors, handleSubmit, reset } = useForm<PadInvitesEmailFormData>({
    defaultValues: {
      email: '',
    },
  })
  const { slug } = usePadConfigValues('slug')
  const { padEmailInviteSend, status: padEmailInviteSendStatus } = usePadEmailInviteSend()

  const handleSubmitCallback = useCallback<SubmitHandler<PadInvitesEmailFormData>>(
    async ({ email }) => {
      try {
        await padEmailInviteSend({
          email,
          padSlug: slug,
        })
        track(PadAnalyticsEvent.PadInvitesEmailInviteSent)
        dispatch(
          enqueueNotif({
            autoDismissMs: 5000,
            key: 'pad-invites-email-send-success',
            message: PadTranslation.padInvitesEmailSendSuccess,
            variant: 'success',
          })
        )
        reset()
      } catch (error) {
        console.error(error)
        dispatch(
          enqueueNotif({
            autoDismissMs: 5000,
            key: 'pad-invites-email-send-error',
            message: PadTranslation.padInvitesEmailSendError,
            variant: 'error',
          })
        )
      }
    },
    [dispatch, padEmailInviteSend, reset, slug, track]
  )

  useEffect(() => {
    if (!isOpen) return

    reset()
  }, [isOpen, reset])

  return (
    <Box px={3}>
      <Stack spacing={2}>
        <Typography
          color="inherit"
          component="h1"
          sx={{ alignSelf: 'center', fontSize: '1.125rem', fontWeight: 500 }}
        >
          {PadTranslation.padInvitesEmailTitle}
        </Typography>
        <Typography color="secondary" component="p">
          {PadTranslation.padInvitesEmailDescription}
        </Typography>
        <Stack
          alignItems="flex-start"
          component="form"
          direction="row"
          onSubmit={handleSubmit(handleSubmitCallback)}
          spacing={1}
        >
          <Controller
            control={control}
            name="email"
            render={(props) => (
              <TextField
                {...props}
                error={!!errors?.email}
                helperText={errors?.email ? PadTranslation.padInvitesEmailInvalidEmail : ''}
                label={PadTranslation.padInvitesEmailFieldLabel}
                size="small"
                sx={{ flexGrow: 1 }}
              />
            )}
            rules={{ required: true, validate: validateEmail }}
          />

          <LoadingButton
            disableElevation
            disabled={isLoadingState(padEmailInviteSendStatus)}
            isLoading={isLoadingState(padEmailInviteSendStatus)}
            type="submit"
            variant="contained"
          >
            {PadTranslation.padInvitesEmailSendButtonLabel}
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  )
})
