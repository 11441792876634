import '../../utils/appInitializers/googleTranslateHack'

// @ts-ignore no type defs for this package.
import Rails from '@rails/ujs'

import initAnalytics from '../../utils/appInitializers/analytics'
import initCodeMirror from '../../utils/appInitializers/codeMirror'
import initJQuery from '../../utils/appInitializers/jquery'
import initSentry from '../../utils/appInitializers/sentry'
;(function () {
  initSentry()
  initCodeMirror()
  initJQuery()
  initAnalytics()
})()

Rails.start()
