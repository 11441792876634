import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { Box, Paper, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Assistant } from '../../../../assets/images/Assistant.svg'
import { padSettingChanged } from '../reducers/pad_settings'
import { setSlectedTab, visibleTabNoteToggled } from '../reducers/tabs'
import { selectPadSettings } from '../selectors'
import { AVAILABLE_SANDBOX_VIEWS, SandboxShowcaseTypes } from './constants'

const useStyles = makeStyles({
  root: {
    width: '141px',
    height: '40px',
    position: 'absolute',
    right: '38px',
    bottom: '62px',
    zIndex: 2,
    display: 'flex',
    background: '#363C4D',
  },
  logo: {
    position: 'absolute',
    top: '-56px',
    left: '40px',
  },
  title: {
    fontSize: '0.75rem',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  subtitle: {
    fontSize: '0.75rem',
    textAlign: 'center',
    color: '#32CEAF',
    textTransform: 'capitalize',
  },
  btnRoot: {
    color: '#FFFFFF',
  },
  btnSelected: {
    color: '#FFFFFF!important',
    background: '#2D72CC!important',
  },
})

export default function SandboxSwitcher() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { sandboxView, sandboxSwitcher } = useSelector(selectPadSettings)
  const [selected, setSelected] = useState(false)

  const switcherLabel = useMemo(() => {
    return AVAILABLE_SANDBOX_VIEWS.find((view) => view === sandboxView)
  }, [sandboxView])

  const handleSelected = useCallback(() => {
    const newView = AVAILABLE_SANDBOX_VIEWS.find((view) => view !== switcherLabel)

    dispatch(padSettingChanged('sandboxView', newView))
    dispatch(setSlectedTab('output'))
    dispatch(visibleTabNoteToggled(newView === SandboxShowcaseTypes.Interviewer))
    setSelected(!selected)
  }, [switcherLabel])

  if (!sandboxView || !sandboxSwitcher) return <></>

  return (
    <Paper elevation={0} className={classes.root}>
      <Assistant className={classes.logo} />
      <Box my={0.5} mx={1.25}>
        <Typography className={classes.title}>Current View:</Typography>
        <Typography className={classes.subtitle} gutterBottom>
          {switcherLabel}
        </Typography>
      </Box>
      <ToggleButton
        value="check"
        classes={{
          root: classes.btnRoot,
          selected: classes.btnSelected,
        }}
        selected={selected}
        onChange={handleSelected}
      >
        <SwapHorizIcon />
      </ToggleButton>
    </Paper>
  )
}
