import Cookies from 'js-cookie'
import _ from 'lodash'

// This module is used both in pads *and* on the dashboard.

// Updates cookie to set editor setting key to value.
// Example keys: fontSize, tabSizes.c, darkColorScheme
export function set(key, value) {
  const cookie = currentConfig()
  let m
  if ((m = key.match(/^tab_?[Ss]izes\.(.+)$/))) {
    cookie.tab_sizes[m[1]] = value
  } else {
    cookie[_.snakeCase(key)] = value
  }
  cookie.tab_sizes = _.pickBy(
    cookie.tab_sizes,
    (v, k) => window.CoderPad?.LANGUAGES[k].tab_size !== v
  )
  Cookies.set('editor_config', JSON.stringify(cookie), { expires: 90 })
}

export function currentConfig() {
  let config = {}
  try {
    config = JSON.parse(Cookies.get('editor_config'))
  } catch (e) {
    config = {}
  }
  _.defaults(config, {
    auto_close_brackets: true,
    autocomplete: true,
    font_size: 14,
    keymap: 'sublime',
    tab_sizes: {},
    dark_color_scheme: true,
  })
  _.defaults(
    config.tab_sizes,
    _.mapValues(window.CoderPad?.LANGUAGES, (lang) => lang.tab_size)
  )
  return config
}
