import { TabList } from '@mui/lab'
import { Box, ClickAwayListener, styled, ThemeProvider } from '@mui/material'
import clsx from 'classnames'
import _ from 'lodash'
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ThemeMode, useTheme } from '../../../../theme/theme'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { Tab as _Tab } from '../../../dashboard/components/Tabs'
import { PadAnalyticsEvent } from '../../constants'
import padConfig from '../../pad_config'
import { ShowcaseTooltip, ShowcaseTooltipProps } from '../../Sandbox/ShowcaseTooltip'
import { selectIsSandboxShowcaseInterviewer } from '../../selectors'
import trackEvent from '../../track_event'
import { TABS } from './constants'
import { TabMenu } from './TabMenu'

interface QuestionCustomFile {
  id: string
  filesize: string
  filename: string
  title: string
}
interface HeaderTabProps {
  name: string
  label: string
  hasUnseenContent: boolean
  onClick: (data?: Record<string, string>) => void
  tooltip?: Pick<ShowcaseTooltipProps, 'sectionTitle' | 'contents'>
  selection?: {
    dataName: string
    list: { label: string; value: unknown }[]
  }
  index: number
}

function getFileExtension(file: QuestionCustomFile) {
  const idxExt = file.filename?.search(/\.[^.]*$/)
  const extension =
    idxExt != null && idxExt !== -1 && file.filename != null
      ? file.filename.substring(idxExt)
      : null
  return extension
}

const Tab = styled(_Tab)({
  fontSize: '0.875rem',
})

const HeaderBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'hasTabs' })<{
  hasTabs: boolean
}>(({ theme, hasTabs }) => ({
  zIndex: 11,
  width: '100%',
  height: '40px',
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.25),
  paddingRight: theme.spacing(1),
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: hasTabs ? 'space-between' : 'flex-end',
}))

export const Header = forwardRef((props, ref) => {
  const { activeTab, unseenContent, visibleTabs } = useSelector((state) => state.tabs)
  const { focusTimeEnabled } = useSelector((state) => state.padSettings)
  const isSandboxShowcaseInterviewer = useSelector(selectIsSandboxShowcaseInterviewer)
  const customFiles = useSelector((state) => state.question.customFiles)
  const { isPlayback } = usePadConfigValues('isPlayback')

  const dispatch = useDispatch()

  const onTab = useCallback(
    (tab: string, data?: Record<string, unknown>) => {
      dispatch({ type: 'tab_selected', tab, ...data })
    },
    [dispatch]
  )

  // Show the tab interface if tabs besides Output are visible.
  // If Output is the only tab, hide the tabbing interface.
  const hasTabs = !!_.find(visibleTabs, (visible, name) => visible && name !== 'output')

  const [shouldCaptureKeyboardEvents, setShouldCaptureKeyboardEvents] = useState(false)

  let tabIndex = 0

  const handleTabClick = useCallback(
    (name: string, index: number, data?: Record<string, unknown>) => {
      onTab(name, data)
      setShouldCaptureKeyboardEvents(true)
      trackEvent(PadAnalyticsEvent.RightPaneTabClicked, { target: name })
    },
    [onTab]
  )

  const tabDefs: HeaderTabProps[] = useMemo(() => {
    const defs: HeaderTabProps[] = []
    Object.keys(TABS).forEach((name, idx) => {
      if (visibleTabs[name]) {
        const def: HeaderTabProps = {
          name,
          label: TABS[name],
          index: idx,
          hasUnseenContent: unseenContent[name],
          onClick: (data?: Record<string, string>) => handleTabClick(name, tabIndex++, data),
        }
        if (name === 'files') {
          def.label += ` (${customFiles.length})`
          if (customFiles.length > 1) {
            def.selection = {
              list: customFiles.map((cf: QuestionCustomFile) => {
                const fileExt = getFileExtension(cf)
                const label = fileExt ? `${cf.filename} (${fileExt})` : cf.filename
                return {
                  label,
                  value: cf,
                }
              }),
              dataName: 'customFileSelected',
            }
          }
          if (customFiles.length > 0) {
            def.onClick = () => {
              handleTabClick(name, tabIndex++, { customFileSelected: customFiles[0] })
            }
          }
        } else if (isSandboxShowcaseInterviewer && name === 'notes') {
          def.tooltip = {
            sectionTitle: 'Private Interviewer Notes',
            contents:
              'Take private notes during an interview. These notes will only be visible to internal team members.',
          }
        }
        defs.push(def)
      }
    })
    return defs
  }, [
    visibleTabs,
    unseenContent,
    isSandboxShowcaseInterviewer,
    customFiles,
    handleTabClick,
    tabIndex,
  ])

  const currentFocusedTabIdx = tabDefs.findIndex((t) => t.name === activeTab)
  const handleInput = useCallback(
    (event) => {
      if (currentFocusedTabIdx !== -1 && ['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        const delta = event.key === 'ArrowLeft' ? -1 : 1
        const nextIdx = (currentFocusedTabIdx + tabDefs.length + delta) % tabDefs.length
        tabDefs[nextIdx].onClick()
      }
    },
    [currentFocusedTabIdx, tabDefs]
  )

  useEffect(() => {
    if (shouldCaptureKeyboardEvents) {
      document.addEventListener('keydown', handleInput)
    }
    return () => {
      document.removeEventListener('keydown', handleInput)
    }
  }, [handleInput, shouldCaptureKeyboardEvents])

  const focusTimeTheme = useTheme(ThemeMode.Light)

  // Edge case: don't render an empty header that obscures execution output.
  if (isPlayback && !hasTabs) return null

  const isFocusTimeThemedHeader = focusTimeEnabled && !padConfig.isOwner

  return (
    // When Focus Time is on, the header background color becomes such that the tabs and buttons become unreadable
    // in dark mode. We force the header to be in light mode when Focus Time is on so that the tabs and buttons
    // will always be legible.
    <ThemeProvider theme={(theme) => (isFocusTimeThemedHeader ? focusTimeTheme : theme)}>
      <ClickAwayListener onClickAway={() => setShouldCaptureKeyboardEvents(false)}>
        <HeaderBox
          className={clsx({ 'RightHeader-focusTime': isFocusTimeThemedHeader })}
          hasTabs={hasTabs}
        >
          {hasTabs && (
            <TabList sx={{ minHeight: '26px', flex: 1 }} variant="scrollable" scrollButtons="auto">
              {/*
                The specialized MUI TabList depends on the `Tab` components being rendered as direct children. While
                the additional logic to add tooltips and tab menus would ideally be encapsulated in a separate
                component, it must be done here so that the `TabList` is satisfied with its children.
              */}
              {tabDefs.map((tabDef) => {
                const { name, label, tooltip, hasUnseenContent, onClick } = tabDef
                const tabText = tooltip ? (
                  <ShowcaseTooltip {...tooltip}>
                    <span>{label}</span>
                  </ShowcaseTooltip>
                ) : (
                  label
                )
                const selectionMenu = tabDef.selection ? (
                  <TabMenu
                    dataKey="customFileSelected"
                    options={tabDef.selection.list}
                    onSelection={(data) => onTab(name, data)}
                  />
                ) : undefined
                return (
                  <Tab
                    key={name}
                    value={name}
                    label={tabText}
                    onClick={() => onClick()}
                    cta={hasUnseenContent}
                    icon={selectionMenu}
                    iconPosition="end"
                  />
                )
              })}
            </TabList>
          )}
          <Box ref={ref} />
        </HeaderBox>
      </ClickAwayListener>
    </ThemeProvider>
  )
})
