import { Box, Stack } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { useSplitPaneContext } from 'packs/dashboard/components/SplitPane/SplitPane'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useChangeQuestionVariant } from '../EnvironmentSelector/EnvSettingsMenu/actions/useChangeQuestionVariant'
import { LangItem } from '../EnvironmentSelector/NewEnvironmentMenu/LanguageEnvironments/LangItem'
import {
  isProjectTemplateVariation,
  useSupportedLanguages,
} from '../EnvironmentSelector/NewEnvironmentMenu/LanguageEnvironments/useSupportedLanguages'

export const QuestionWithVariantsEntry = () => {
  const { question } = useActiveEnvironment()
  const slug = usePadConfigValue('slug')
  const { supportedLanguages } = useSupportedLanguages(slug)
  const changeVariant = useChangeQuestionVariant()
  const containerRef = useRef<HTMLDivElement>()
  const [columnCount, setColumnCount] = useState(4)

  const handleSelection = useCallback(
    (selection) => {
      const targetVariant = question!.questionVariants?.find(
        (v) => v.language === selection.name || v.project_template_slug === selection.name
      )
      if (targetVariant != null) {
        changeVariant(targetVariant.id)
      }
    },
    [changeVariant, question]
  )

  const { dimPcts } = useSplitPaneContext()
  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth ?? 0

    if (containerWidth > 500) {
      setColumnCount(3)
    } else if (containerWidth > 300) {
      setColumnCount(2)
    } else {
      setColumnCount(1)
    }
  }, [dimPcts])

  return (
    <Box
      ref={containerRef}
      sx={(theme) => {
        return {
          color: theme.palette.editor.iconStroke,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: 4,
        }
      }}
    >
      <Stack sx={{ width: '80%' }}>
        <Box pb={2}>Choose a language to get started...</Box>
        <Box sx={{ columnGap: 0, columnCount: columnCount }}>
          {question?.questionVariants?.map((variant) => {
            const supportedLanguage = supportedLanguages.find((sl) => {
              if (isProjectTemplateVariation(sl)) {
                return sl.slug === variant.project_template_slug
              } else {
                return sl.id === variant.language
              }
            })
            if (supportedLanguage == null) {
              return null
            }
            return (
              <Box key={supportedLanguage.id}>
                <LangItem lang={supportedLanguage} onClick={handleSelection} />
              </Box>
            )
          })}
        </Box>
      </Stack>
    </Box>
  )
}
