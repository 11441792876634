import { useCallback, useRef } from 'react'

import { fetchAuthToken } from '../../../../../utils/padAuthToken/padAuthToken'

const AUTH_TOKEN_CACHE_DURATION_MS = 30000

export function useGetResourcesAuthToken(slug: string) {
  const token = useRef('')
  const expirationTime = useRef(0)
  const fetchPromise = useRef<null | Promise<string>>(null)

  const getToken = useCallback(
    (force = false) => {
      if (!force && token.current !== '' && expirationTime.current > Date.now()) {
        return Promise.resolve(token.current)
      }

      if (fetchPromise.current) {
        return fetchPromise.current
      }

      fetchPromise.current = fetchAuthToken(slug)
        .then((newToken: string) => {
          const newExpirationTime = Date.now() + AUTH_TOKEN_CACHE_DURATION_MS
          token.current = newToken
          expirationTime.current = newExpirationTime
          return token.current
        })
        .finally(() => {
          fetchPromise.current = null
        })
      return fetchPromise.current
    },
    [slug]
  )

  return getToken
}
