import React, { useCallback } from 'react'

import { useLanguageInfo } from './EnvironmentSelector/LanguageInfoProvider'
import trackEvent from './track_event'

export const LanguageSelectorInfoButton = ({ language }: { language: string }) => {
  const { setLanguage } = useLanguageInfo()

  const handleClick = useCallback(() => {
    setLanguage(window.CoderPad.LANGUAGES[language])
    trackEvent('Language Info Clicked', {
      language: language,
      from: 'header',
    })
  }, [language, setLanguage])

  return (
    <button className="btn btn-inverse LanguageSelector-infoButton" onClick={handleClick}>
      Info
    </button>
  )
}
