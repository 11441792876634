import firebase from 'firebase/compat/app'
import { useCallback, useEffect, useState } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'

export function useFirebaseActiveQuestionVariants() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  const [activeQuestionVariants, setActiveQuestionVariants] = useState<{
    [environmentSlugStem: string]: string
  }>({})

  useEffect(() => {
    let activeQuestionVariantsWatcher: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      activeQuestionVariantsWatcher = SyncHandle().watch(
        'activeQuestionVariants',
        (activeVariants: { [environmentSlugStem: string]: string }) =>
          setActiveQuestionVariants(activeVariants),
        {}
      )
    }
    return () => {
      if (activeQuestionVariantsWatcher != null) {
        SyncHandle().off('activeQuestionVariants', activeQuestionVariantsWatcher)
      }
    }
  }, [hasEnvironments])

  const setActiveQuestionVariant = useCallback(
    (environmentSlugStem: string, activeVariantEnvironmentSlug: string) => {
      setActiveQuestionVariants((existing) => ({
        ...existing,
        [environmentSlugStem]: activeVariantEnvironmentSlug,
      }))
    },
    []
  )

  return { activeQuestionVariants, setActiveQuestionVariant }
}
