import { RestorePage } from '@mui/icons-material'
import { Box, FilledInput, IconButton } from '@mui/material'
import React, {
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

interface UrlInputProps {
  url: string | null
  onSubmit: (url: string) => void
  onReset: () => void
}

export const UrlInput: React.FC<UrlInputProps> = ({ url, onSubmit, onReset }) => {
  const [displayedUrl, setDisplayedUrl] = useState(url)
  const focusingRef = useRef(false)
  const currentUrlRef = useRef(url)
  const urlInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    const prevUrl = currentUrlRef.current
    currentUrlRef.current = url

    // When the real url changes, we change the displayed URL only if the current displayed URL
    // equals the previous real url OR we are not focusing the input
    setDisplayedUrl((displayedUrl) =>
      displayedUrl === prevUrl || !focusingRef.current ? url : displayedUrl
    )
  }, [url])

  const handleFocus = useCallback(() => {
    focusingRef.current = true
  }, [])

  const handleBlur = useCallback(() => {
    focusingRef.current = false
  }, [])

  const handleUrlSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault()
      const url = new FormData(event.currentTarget).get('url')! as string
      onSubmit(url)
      urlInputRef.current?.blur()
    },
    [onSubmit]
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
    if (event.key === 'Escape') {
      urlInputRef.current?.blur()
      setDisplayedUrl(currentUrlRef.current)
    }
  }, [])

  return (
    <Box component="form" flex="1 1" onSubmit={handleUrlSubmit}>
      <FilledInput
        inputRef={urlInputRef}
        name="url"
        error={displayedUrl == null}
        value={displayedUrl ?? ''}
        placeholder={
          displayedUrl == null
            ? 'Cannot access external URL, reset the URL with the button on right'
            : 'You can reset the URL with the button on the right'
        }
        onChange={(e) => setDisplayedUrl(e.target.value)}
        fullWidth
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        size="small"
        inputProps={{
          'aria-label': 'Iframe URL',
          style: { paddingTop: '8px', paddingBottom: '8px' },
        }}
        endAdornment={
          <IconButton onClick={onReset} title="Reset URL" style={{ padding: 6 }}>
            <RestorePage />
          </IconButton>
        }
      />
    </Box>
  )
}
