import { ContentCopy } from '@mui/icons-material'
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { memo, useCallback, useMemo, useState } from 'react'

import { usePadShortlinkGenerate } from '../../../graphql/hooks'
import { isLoadingState } from '../../../graphql/queryStates'
import { useWindow } from '../../../utils'
import { LoadingButton } from '../../dashboard/components/LoadingButton/LoadingButton'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { PadTranslation } from '../constants'

type PadInvitesShortlinkProps = Record<string, unknown>

export const PadInvitesShortlink = memo<PadInvitesShortlinkProps>(() => {
  const { slug } = usePadConfigValues('slug')
  const { padShortlinkGenerate, status: padShortlinkGenerateStatus } = usePadShortlinkGenerate()
  const [shortlink, setShortlink] = useState<string>('')
  const [didJustCopyLink, setDidJustCopyLink] = useState(false)
  const window = useWindow()

  const shortlinkUrl = useMemo(() => {
    const [protocol, domain] = window.CoderPad.SHORTLINK_URL.split('//')

    return `${protocol}//${domain}/${shortlink}`
  }, [shortlink, window])

  const handleCopyButtonClick = useCallback(() => {
    window.navigator.clipboard?.writeText(shortlinkUrl)

    setDidJustCopyLink(true)

    setTimeout(() => {
      setDidJustCopyLink(false)
    }, 1000)
  }, [shortlinkUrl, window])

  const handleGenerateButtonClick = useCallback(async () => {
    try {
      const { data } = await padShortlinkGenerate({ padSlug: slug })
      const shortlink = data?.padShortlinkGenerate?.shortlink

      if (!shortlink) {
        throw new Error(PadTranslation.padInvitesShortlinkGenerateError)
      }

      setShortlink(shortlink)
    } catch (error) {
      console.error(error)
    }
  }, [padShortlinkGenerate, slug])

  return (
    <Box sx={{ paddingX: 3 }}>
      <Stack spacing={2}>
        <Typography
          color="inherit"
          component="h1"
          sx={{ alignSelf: 'center', fontSize: '1.125rem', fontWeight: 500 }}
        >
          {PadTranslation.padInvitesShortlinkTitle}
        </Typography>
        <Typography
          color="secondary"
          component="p"
          dangerouslySetInnerHTML={{ __html: PadTranslation.padInvitesShortlinkDescription }}
        />
        <Stack alignItems="flex-start" direction="row" spacing={1}>
          {!shortlink && (
            <LoadingButton
              disableElevation
              disabled={isLoadingState(padShortlinkGenerateStatus)}
              isLoading={isLoadingState(padShortlinkGenerateStatus)}
              onClick={handleGenerateButtonClick}
              sx={{ marginX: 'auto' }}
              variant="contained"
            >
              {PadTranslation.padInvitesShortlinkGenerateButtonLabel}
            </LoadingButton>
          )}
          {!!shortlink && (
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip
                    placement="top-end"
                    title={
                      didJustCopyLink
                        ? PadTranslation.padInvitesShortlinkCopyButtonTitleCopying
                        : PadTranslation.padInvitesShortlinkCopyButtonTitleDefault
                    }
                  >
                    <IconButton
                      aria-label={PadTranslation.padInvitesShortlinkCopyButtonTitleDefault}
                      edge="end"
                      onClick={handleCopyButtonClick}
                    >
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              inputProps={{ readOnly: true }}
              size="small"
              sx={{ flexGrow: 1 }}
              value={shortlinkUrl}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
})
