import { monaco } from '@codingame/monaco-editor-wrapper'
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { MonacoFile } from '../Monaco/FilePane/utils/types'
import { useMonacoContext } from '../Monaco/MonacoContext'
import { useMonacoMutations } from '../Monaco/useMonacoMutations'
import { enqueueNotif } from '../reducers/notifications'
import { PLAYBACK_DELAY_DELETE_FRAME_HIGHLIGHT } from '../sagas/playback'
import { selectLanguagesAvailable } from '../selectors'
import SyncHandle from '../sync_handle'
import { GlobalEvents, LanguageChangeGlobalEvent } from './GlobalEvents/GlobalEventTypes'
import { useGlobalEvents } from './GlobalEvents/useGlobalEvents'
import { useFrames } from './hooks/useFrames'
import { usePlaybackParticipants } from './hooks/usePlaybackParticipants'
import { usePlayback } from './PlaybackContext'
import { FlatFrames, Frame, PlaybackParticipant, PlaybackParticipants } from './types'

export interface FileModels {
  [key: string]: monaco.editor.ITextModel | null
}

export interface IMonacoPlayback {
  frames: FlatFrames
  frameIndex: number
  playing: boolean
  participants: PlaybackParticipants
  frameDelay: number
}

const defaultMonacoPlayback: IMonacoPlayback = {
  frames: [],
  frameIndex: 0,
  playing: false,
  participants: {},
  frameDelay: 0,
}

const MonacoPlayback = createContext<IMonacoPlayback>(defaultMonacoPlayback)

/**
 * Convenience hook for accessing the playback context.
 */
export function useMonacoPlayback() {
  const context = useContext(MonacoPlayback)

  if (context == null) {
    throw new Error('`useMonacoPlayback` hook must be a descendant of a `MonacoPlaybackProvider`')
  }

  return context
}

const emptyDictionnary = {}

export const MonacoPlaybackProvider: FC = ({ children }) => {
  const dispatch = useDispatch()
  const participants = usePlaybackParticipants()
  const availableLanguages = useSelector(selectLanguagesAvailable) ?? emptyDictionnary
  const {
    getEditor,
    files,
    activeFile,
    setActiveFile,
    updateFileUser,
    setSingleFilePadLanguage,
  } = useMonacoContext()
  const { isPlayback, hasEnvironments } = usePadConfigValues('isPlayback', 'hasEnvironments')
  const lastFrameRef = useRef<number | null>(null)
  const lastFileRef = useRef<string | null>(null)
  const fileModelsRef = useRef<FileModels>({})
  const currentLanguageRef = useRef<string>()
  const { environmentSlug } = useSelector((state) => state.padSettings)

  const { lastLanguageEvent } = useGlobalEvents()
  const { frameIndex, frameDelay, frames, userFrames } = useFrames()
  const editor = useMemo(() => getEditor(), [getEditor])
  const {
    deleteRange,
    moveCursor,
    hideAllCursors,
    showCursor,
    insertText,
    replaceAll,
    highlightRangeAndDelete,
  } = useMonacoMutations(editor, { readOnly: isPlayback })
  const { playing, paused } = usePlayback()

  const getAuthor = useCallback((authorId = ''): PlaybackParticipant => participants[authorId]!, [
    participants,
  ])

  /**
   * Iterates through the frames from the current position to the end of the
   * timeline or start of next activity, and returns the amount of inactive
   * frames for the current user. This is optimized as best as I can, it uses
   * a subsection of the frames array and breaks at the first occurance of activity.
   *
   * TODO: get this to work in environments. This requires the flatFrame optimization
   */
  const framesOfInactivity = useMemo(() => {
    if (userFrames == null) return 0
    let inactiveFrames = 0
    const framesToPlay = userFrames.slice(frameIndex)

    for (let i = 0; i < framesToPlay.length; i++) {
      const frame = framesToPlay[i]
      if (frame.type === 'retain') {
        inactiveFrames++
        if (inactiveFrames > 5) break
      } else {
        break
      }
    }
    return inactiveFrames
  }, [userFrames, frameIndex])

  /**
   * Auto-sets playback speed based on the amount of inactive frames.
   */
  useEffect(() => {
    if (framesOfInactivity > 5) {
      dispatch({
        type: 'playback_fastforward_start',
      })
    } else {
      dispatch({
        type: 'playback_fastforward_stop',
      })
    }
  }, [framesOfInactivity, dispatch])

  /**
   * Fetch globalEvents from firebase and insert them to our redux store.
   */
  useEffect(() => {
    if (!isPlayback) return
    SyncHandle().get('globalEvents', (globalEvents: GlobalEvents) => {
      if (globalEvents != null) {
        dispatch({
          type: 'playback_global_events_history_received',
          globalEvents,
        })
      }
    })
  }, [dispatch, isPlayback])

  /**
   * This effect is responsible for creating monaco models for each file.
   * Creating all the necessary models here first removes a race condition
   * when switching files for the first time during playback. This setup
   * effect guarantees that file switching will work the first time.
   */
  useEffect(() => {
    if (isPlayback && editor != null) {
      for (const file of files) {
        const modelUri = monaco.Uri.file(file.name)
        const monacoModel = monaco.editor.getModel(modelUri)
        if (monacoModel == null) {
          fileModelsRef.current[file.fileId || '0_global'] = monaco.editor.createModel(
            '',
            undefined,
            modelUri
          )
        }
      }
    }
  }, [editor, files, isPlayback])

  /**
   * This function returns the latest frame of a given file.
   * Warning: this function iterates backwards through the entire frame array,
   * starting with the current frame index. It auto-breaks and is fairly optimized,
   * but it should be called on a JIT basis. Don't auto-update any of the background
   * files.
   */
  const getLatestFrameForFile = useCallback(
    (targetFile: MonacoFile): Frame | null => {
      const searchPeripheralFrames = (frame: Frame): Frame | undefined =>
        frame.peripheralFrames.reverse().find((f) => f.fileId === targetFile.fileId)

      // last known state of the file
      let lastFrame: Frame | null = null

      if (frames.length === 0) {
        return null
      }

      for (let i = frameIndex; i >= 0; i--) {
        const frame = frames[i]
        // Skip frames that are not in the current environment
        if (frame.environmentSlug !== environmentSlug) continue
        if (frame.fileId === targetFile.fileId) {
          lastFrame = frame
          if (frame.peripheralFrames.length > 0) {
            const peripheralFrame = searchPeripheralFrames(frame)
            if (peripheralFrame != null) {
              lastFrame = peripheralFrame
            }
          }
          break
        }
        const peripheralFrame = searchPeripheralFrames(frame)
        if (peripheralFrame != null) {
          lastFrame = peripheralFrame
          break
        }
      }
      return lastFrame
    },
    [frameIndex, frames, environmentSlug]
  )

  /**
   * Event that fires when changing languages, files, or environments.
   * Currently, this functions only purpose is to hide the cursors of
   * every user that is not in the current file.
   */
  const onDidChangeModel = useCallback(() => {
    /**
     * TODO: Instead of hiding all cursors, just to reshow any users in the
     * active file, maybe we should add a filterable `hideCursors` function?
     */
    hideAllCursors()

    if (activeFile) {
      const frame = getLatestFrameForFile(activeFile)
      replaceAll(frame?.value || '')
    }

    for (const id in activeFile?.users) {
      const user = activeFile?.users[id]
      if (user != null) {
        showCursor(id, user)
      }
    }
  }, [activeFile, replaceAll, hideAllCursors, showCursor, getLatestFrameForFile])

  /**
   * Effect for setting up monaco event listeners.
   */
  useEffect(() => {
    if (editor == null) return
    const listener = editor.onDidChangeModel(onDidChangeModel)
    return () => {
      listener.dispose()
    }
  }, [editor, onDidChangeModel])

  useEffect(() => {
    if (lastLanguageEvent != null) {
      const { user, data } = lastLanguageEvent as LanguageChangeGlobalEvent
      if (data.language !== currentLanguageRef.current) {
        const author = getAuthor(user)
        setSingleFilePadLanguage(data.language)
        const { display } = availableLanguages[data.language]
        const prettyLanguage = display ?? data.language
        const message = data.initialLanguage
          ? `Pad started in ${prettyLanguage}`
          : `${author?.name || 'A user'} changed the language to ${prettyLanguage}`
        dispatch(
          enqueueNotif({
            message,
            key: `playbackLanguageChange-${data.language}`,
            variant: 'info',
            autoDismissMs: 1500,
            onClick: () => {},
          })
        )
        currentLanguageRef.current = data.language
      }
    }
  }, [editor, lastLanguageEvent, availableLanguages, getAuthor, setSingleFilePadLanguage, dispatch])

  /**
   * Effect for consuming file change events.
   *
   * We only want to apply this effect when we're paused, that way the viewer
   * is able to manually navigate through the file tree. If the playback is
   * not paused, they will be unable to navigate through the file tree.
   */
  useEffect(() => {
    const lastUserFrame = userFrames[frameIndex]
    if (lastUserFrame != null && !paused) {
      const { fileId, authorId } = lastUserFrame
      const author = getAuthor(authorId)
      const activeFileId = activeFile?.fileId ?? '0_global'
      const newActiveFileId = fileId === '0_global' ? '' : fileId
      // TODO: make `updateFileUser` work in environment playback
      if (!hasEnvironments && authorId && author && newActiveFileId !== activeFileId) {
        updateFileUser(newActiveFileId ?? '', {
          id: authorId,
          name: author.name,
          color: author.color,
          cursorPosition: 0,
        })
      }
      // Follow the latest file-changes
      if (fileId !== activeFileId) {
        const file = files.find((f) => f.fileId === fileId)
        if (file != null) {
          setActiveFile(file)
        }
      }
    }
  }, [
    playing,
    paused,
    activeFile?.fileId,
    getAuthor,
    setActiveFile,
    updateFileUser,
    dispatch,
    hasEnvironments,
    userFrames,
    frameIndex,
    files,
  ])

  /**
   * The purpose of this callback is to patch the edits contained in the passed
   * frame onto the editor. This will also patch the peripheral frames immediately
   * after the main frame.
   */
  const executeFrame = useCallback(
    (frame: Frame) => {
      // Guard against patching edits in the wrong model
      if (frame.environmentSlug !== environmentSlug) return

      const executePeripheralFrames = () => {
        frame.peripheralFrames.forEach((frame) => {
          executeFrame(frame)
        })
      }

      // if the active file is not the file that the frame is for, we don't want
      // to execute it. This is a performance optimization, so that we don't have
      // to patch edits for backround files.
      if (activeFile?.fileId !== frame.fileId) {
        executePeripheralFrames()
        return
      }

      const { value, type, editStartOffset, editEndOffset, highlightRanges } = frame
      const changes = value.substring(editStartOffset ?? 0, editEndOffset)
      const author = getAuthor(frame.authorId)

      if (type === 'delete') {
        if (highlightRanges != null && highlightRanges.length > 0) {
          // If we're deleting a range: highlight it first, then delete it.
          // We pass executePeripheralFrames as a callback to the highlight function,
          // so that the peripheral frames are executed after the highlight delay.
          highlightRanges.forEach((range) => {
            highlightRangeAndDelete(
              range.startOffset,
              range.endOffset,
              frameDelay - PLAYBACK_DELAY_DELETE_FRAME_HIGHLIGHT,
              frame.authorId!,
              author,
              executePeripheralFrames
            )
          })
        } else {
          // If it's a single-character deletion: delete it immediately.
          deleteRange(editStartOffset ?? 0, editEndOffset ?? 0)
          moveCursor(frame.authorId!, author, editStartOffset ?? 0)
          executePeripheralFrames()
        }
      } else if (type === 'insert') {
        if (lastFrameRef.current !== frameIndex - 1) {
          // If the last frame played in not the previous sequential frame,
          // we cannot apply progressive patching. So we must replace the
          // entire editor contents.
          replaceAll(value)
          moveCursor(frame.authorId!, author, editEndOffset ?? 0)
        } else {
          if (highlightRanges != null && highlightRanges.length > 0) {
            // Handle multiple highlight ranges in a single insert frame.
            highlightRanges.forEach((range) => {
              const change = value.substring(range.startOffset, range.endOffset)
              insertText(change, range.startOffset ?? 0)
              moveCursor(frame.authorId!, author, range.endOffset ?? 0)
            })
          } else {
            insertText(changes, editStartOffset ?? 0)
            moveCursor(frame.authorId!, author, editEndOffset ?? 0)
          }
        }
        executePeripheralFrames()
      }
    },
    [
      environmentSlug,
      activeFile?.fileId,
      getAuthor,
      highlightRangeAndDelete,
      frameDelay,
      deleteRange,
      moveCursor,
      frameIndex,
      replaceAll,
      insertText,
    ]
  )

  /**
   * This is the main playback loop. This effect should get run every time
   * the frameIndex changes. There should only be two code pathways here,
   * for when playback is paused or playing. When playback is paused, the
   * entire editor contents get replaced when the frameIndex changes. When
   * playback is playing, we run the executeFrame function, which is patches
   * each frame's changes onto the editor sequentially.
   */
  useEffect(() => {
    if (frameIndex === lastFrameRef.current) return
    if (editor != null && frames != null && activeFile != null) {
      const frame = frames[frameIndex]
      if (frame != null) {
        if (playing) {
          executeFrame(frame)
        } else {
          if (lastFrameRef.current === frameIndex) return
          const latestFrame = getLatestFrameForFile(activeFile)
          if (latestFrame) {
            // This is what gets triggered during manual timeline scrubbing or seeking
            // TODO: if we're scrubbing, we should be able to progressively patch the editor
            const { value, type, editStartOffset, editEndOffset } = latestFrame
            const cursorPos = (type === 'delete' ? editStartOffset : editEndOffset) ?? 0
            replaceAll(value)
            if (frame.authorId != null) {
              moveCursor(frame.authorId, getAuthor(frame.authorId), cursorPos)
            }
          }
        }
        lastFrameRef.current = frameIndex
        lastFileRef.current = activeFile?.fileId ?? null
      }
    }
  }, [
    activeFile,
    editor,
    playing,
    frameIndex,
    frames,
    getAuthor,
    replaceAll,
    moveCursor,
    executeFrame,
    getLatestFrameForFile,
  ])

  return (
    <MonacoPlayback.Provider
      value={{
        ...defaultMonacoPlayback,
        frames,
        frameIndex,
        playing,
        participants,
        frameDelay,
      }}
    >
      {children}
    </MonacoPlayback.Provider>
  )
}
