import { Button, ButtonGroup } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const ToggleButton = withStyles((theme) => {
  const isDark = theme.palette.mode === 'dark'
  return {
    root: {
      color: isDark ? '#fff' : '#2D72CC',
      backgroundColor: theme.palette.editor?.sidebar.background,
      borderColor: isDark ? theme.palette.editor?.sidebar.active : '#2D72CC80',
      '&:hover': {
        borderColor: isDark ? theme.palette.editor?.sidebar.active : '#2D72CC',
      },
      '&.isSelected': {
        backgroundColor: theme.palette.editor?.sidebar.active,
        color: isDark ? '#fff' : '#00000080',
        borderColor: theme.palette.editor?.sidebar.active,
      },
    },
  }
})(Button)

export const QuestionClassificationToggle: React.FC<{
  onToggle: (isExample: boolean, userOnly: boolean) => void
  example: boolean
  userOnly: boolean
}> = ({ example, onToggle, userOnly }) => {
  return (
    <ButtonGroup variant="outlined">
      <ToggleButton
        className={!example && !userOnly ? 'isSelected' : ''}
        onClick={() => onToggle(false, false)}
        disabled={!example && !userOnly}
        data-testid="questionClassificationToggle-Organization"
      >
        Organization
      </ToggleButton>
      <ToggleButton
        className={!example && userOnly ? 'isSelected' : ''}
        onClick={() => onToggle(false, true)}
        disabled={!example && userOnly}
        data-testid="questionClassificationToggle-MyContent"
      >
        My Content
      </ToggleButton>
      <ToggleButton
        className={example ? 'isSelected' : ''}
        onClick={() => onToggle(true, userOnly)}
        disabled={example}
        data-testid="questionClassificationToggle-Examples"
      >
        Examples
      </ToggleButton>
    </ButtonGroup>
  )
}
