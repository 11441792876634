import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFrames } from '../hooks/useFrames'
import { FlatFrames } from '../types'
import {
  AddEnvironmentGlobalEvent,
  ChangeEnvironmentLanguageGlobalEvent,
  CreatePadGlobalEvent,
  GlobalEvent,
  GlobalEventTypes,
} from './GlobalEventTypes'

export const useGlobalEvents = (overrideFrames?: FlatFrames) => {
  const trackedUserId = useSelector((state) => state.playbackHistory.trackedUserId)
  const globalEvents = useSelector((state) => state.playbackHistory.globalEvents)
  const globalEventFrames = useMemo(
    () => Object.values(globalEvents || {}).sort((a, b) => b.time - a.time) as GlobalEvent[],
    [globalEvents]
  )

  const { frames } = useFrames(overrideFrames)
  const frameIndex = useSelector((state) => state.playbackHistory.frameIndex)
  const currentTimestamp = useMemo(() => frames?.[frameIndex]?.timestamp ?? null, [
    frames,
    frameIndex,
  ])

  const lastTimestamp = useMemo(() => frames?.[frames.length - 1]?.timestamp ?? null, [frames])

  const getFilteredGlobalEvents = useCallback(
    (types: GlobalEventTypes[], onlyTracked?: boolean) => {
      if (currentTimestamp && lastTimestamp) {
        if (currentTimestamp < lastTimestamp) {
          return globalEventFrames.filter(
            (event) =>
              types.includes(event.type as GlobalEventTypes) &&
              event.time <= currentTimestamp &&
              (!onlyTracked || event.user === trackedUserId)
          )
        } else {
          return globalEventFrames.filter(
            (event) =>
              types.includes(event.type as GlobalEventTypes) &&
              (!onlyTracked || event.user === trackedUserId)
          )
        }
      }
      return []
    },
    [globalEventFrames, currentTimestamp, trackedUserId, lastTimestamp]
  )

  /**
   * Retries the latest global event of a given type.
   */
  const getLatestGlobalEvent = useCallback(
    (type: GlobalEventTypes, onlyTracked?: boolean) => {
      const events = getFilteredGlobalEvents([type], onlyTracked)
      return events.length > 0 ? events[0] : null
    },
    [getFilteredGlobalEvents]
  )

  const lastLanguageEvent = useMemo(() => getLatestGlobalEvent('change-active-language'), [
    getLatestGlobalEvent,
  ])
  const lastAddEnvironmentEvent = useMemo(() => getLatestGlobalEvent('add-environment'), [
    getLatestGlobalEvent,
  ])
  const lastChangeEnvironmentEvent = useMemo(() => getLatestGlobalEvent('change-environment'), [
    getLatestGlobalEvent,
  ])
  const lastAddFileEvent = useMemo(() => getLatestGlobalEvent('add-file'), [getLatestGlobalEvent])
  const lastDeleteFileEvent = useMemo(() => getLatestGlobalEvent('delete-file'), [
    getLatestGlobalEvent,
  ])
  const lastRenameFileEvent = useMemo(() => getLatestGlobalEvent('rename-file'), [
    getLatestGlobalEvent,
  ])
  const lastCreatePadEvent = useMemo(() => getLatestGlobalEvent('create-pad'), [
    getLatestGlobalEvent,
  ])
  const lastRequestClientRequestEvent = useMemo(
    () => getLatestGlobalEvent('request-client-request'),
    [getLatestGlobalEvent]
  )

  const getEnvironmentLanguageChange = useCallback(
    (environmentId: string) => {
      if (currentTimestamp) {
        const lastEvent = globalEventFrames.find(
          (event) =>
            event.type === 'change-environment-language' &&
            event.time <= currentTimestamp &&
            event.data.environment === environmentId
        )
        if (lastEvent) return lastEvent as ChangeEnvironmentLanguageGlobalEvent
      }
      return null
    },
    [globalEventFrames, currentTimestamp]
  )

  /**
   * Returns the visibiliy state of the given file id at the current timestamp.
   */
  const isFileVisible = useCallback(
    (fileId: string): boolean => {
      const fileStates: Record<
        string,
        {
          addedAt: number
          deletedAt?: number
        }
      > = {}
      const visibleFileIds: string[] = []

      const globalEvents = getFilteredGlobalEvents(
        ['add-file', 'delete-file', 'create-pad', 'add-environment'],
        false
      )

      const initialVisibleFileIds = new Set<string>()
      // The global events array is ordered by time descending,
      // so we iterate backwards to find the latest state of each file.
      for (let i = globalEvents.length - 1; i >= 0; i--) {
        const event = globalEvents[i]
        if (event.time > currentTimestamp) {
          break
        }

        if (event.type === 'create-pad' || event.type === 'add-environment') {
          const snapshot = event.data.snapshot || {}
          if (snapshot[fileId]) {
            initialVisibleFileIds.add(fileId)
          }
        }

        if (event.type === 'add-file' && event.data.fileId === fileId) {
          fileStates[fileId] = { addedAt: event.time }
        }

        if (
          event.type === 'delete-file' &&
          event.data.fileId === fileId &&
          fileStates[fileId] &&
          !fileStates[fileId].deletedAt
        ) {
          fileStates[fileId].deletedAt = event.time
        }
      }

      for (const [fileId, fileState] of Object.entries(fileStates)) {
        if (!fileState.deletedAt || fileState.deletedAt > currentTimestamp) {
          visibleFileIds.push(fileId)
        }
      }

      initialVisibleFileIds.forEach((fileId) => {
        if (!visibleFileIds.includes(fileId)) {
          visibleFileIds.push(fileId)
        }
      })

      return visibleFileIds.includes(fileId)
    },
    [currentTimestamp, getFilteredGlobalEvents]
  )

  const getVisibleEnvironments = useCallback((): string[] => {
    if (currentTimestamp != null) {
      // We are only expecting there to be a single create-pad global event,
      // but we will operate under the assumption that there might be multiple
      // in the future. Also, it's free to support that case now, so we may as
      // well. Who knows what funky stuff we might do in the future.
      const createPadEvents = getFilteredGlobalEvents(['create-pad']) as CreatePadGlobalEvent[]
      const initialEnvironments = createPadEvents.reduce(
        (acc, event) => [...acc, ...event.data.initialEnvironments],
        [] as string[]
      )

      const environments = getFilteredGlobalEvents([
        'add-environment',
      ]) as AddEnvironmentGlobalEvent[]

      return _.uniq([
        ...initialEnvironments,
        ...environments.map((event) => event.data.environmentSlug),
      ])
    }
    return []
  }, [getFilteredGlobalEvents, currentTimestamp])

  return {
    currentTimestamp,
    globalEventFrames,
    lastLanguageEvent,
    lastChangeEnvironmentEvent,
    lastAddEnvironmentEvent,
    lastCreatePadEvent,
    isFileVisible,
    getEnvironmentLanguageChange,
    getVisibleEnvironments,
    lastAddFileEvent,
    lastDeleteFileEvent,
    lastRenameFileEvent,
    lastRequestClientRequestEvent,
  }
}
