import clsx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

export default class BannerButton extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
  }

  handleClick = (evt) => {
    evt.preventDefault()
    this.props.onClick()
  }

  render() {
    const { style, ...otherProps } = this.props

    let opts = {}
    if (this.props.target) {
      opts['target'] = this.props.target
    }
    return (
      <a
        className={clsx('BannerButton', this.props.className)}
        href={this.props.href || '#'}
        onClick={this.props.onClick && this.handleClick}
        {...opts}
        style={style}
        {...otherProps}
      >
        {this.props.children}
      </a>
    )
  }
}
