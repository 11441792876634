import { Stack, Switch, Typography } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import { SchemaExplorer } from 'packs/dashboard/CustomDatabase/components/SchemaExplorer'
import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { padSettingChanged } from '../../../reducers/pad_settings'
import {
  selectDatabaseEnabled,
  selectLanguagesAvailable,
  selectMyUserId,
  selectPadSettings,
} from '../../../selectors'
import DATABASE_INFO from './database_info'

interface IDatabaseTabProps {
  hidden: boolean
}

export enum SchemaView {
  Explorer = 'explorer',
  Raw = 'raw',
}

export const DatabaseTab: React.FC<IDatabaseTabProps> = ({ hidden }) => {
  const dispatch = useDispatch()
  const { projectTemplate } = useActiveEnvironment()
  const databaseEnabled =
    useSelector(selectDatabaseEnabled) || projectTemplate?.settings?.databaseAllowed
  const {
    customDatabaseId,
    customDatabaseLanguage,
    customDatabaseSchema,
    customDatabaseSchemaJson,
    language,
  } = useSelector(selectPadSettings)

  const languagesAvailable = useSelector(selectLanguagesAvailable)
  const userId = useSelector(selectMyUserId)
  const languages = _.pickBy(languagesAvailable, (lang) => lang.database_allowed)
  const isDatabaseLanguage = language === 'mysql' || language === 'postgresql'

  const schema = useMemo(() => {
    if (customDatabaseSchema) {
      return customDatabaseSchema
    } else if (customDatabaseLanguage && !customDatabaseId) {
      return DATABASE_INFO[customDatabaseLanguage]?.exampleSchema
    } else if (isDatabaseLanguage) {
      return DATABASE_INFO[language]?.exampleSchema
    }
    return ''
  }, [customDatabaseSchema, customDatabaseLanguage, customDatabaseId, isDatabaseLanguage, language])

  const schemaJson = useMemo(() => {
    if (customDatabaseSchemaJson == null && isDatabaseLanguage) {
      return DATABASE_INFO[language].schemaJson
    } else if (
      customDatabaseSchemaJson == null &&
      customDatabaseId == null &&
      !!customDatabaseLanguage
    ) {
      return DATABASE_INFO[customDatabaseLanguage]?.schemaJson
    } else {
      return customDatabaseSchemaJson
    }
  }, [
    customDatabaseId,
    customDatabaseLanguage,
    customDatabaseSchemaJson,
    isDatabaseLanguage,
    language,
  ])

  const databasePath = DATABASE_INFO[customDatabaseLanguage]?.path
  const databaseName = DATABASE_INFO[customDatabaseLanguage]?.name
  const [schemaView, setSchemaView] = useState(SchemaView.Explorer)

  useEffect(() => {
    setSchemaView(schemaJson == null ? SchemaView.Raw : SchemaView.Explorer)
  }, [schemaJson])

  return (
    <div
      className={classNames({ hidden, DatabaseTab: true })}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {databaseEnabled && !hidden ? (
        <>
          <div className="DatabaseTab-header">
            A {databaseName} database is available to your program.
            {databasePath && (
              <>
                <span> Your code can connect to it at this socket: </span>
                <pre className="DatabaseTab-databasePath">{databasePath}</pre>
              </>
            )}
            <p>
              Database state persists across code runs. To reset the state, press Reset or change
              the language.
            </p>
          </div>
          {schemaJson != null && (
            <div>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Schema:</Typography>
                <Typography>Raw</Typography>
                <Switch
                  checked={schemaView === SchemaView.Explorer}
                  onChange={() =>
                    setSchemaView(
                      schemaView === SchemaView.Raw ? SchemaView.Explorer : SchemaView.Raw
                    )
                  }
                />
                <Typography>Explorer</Typography>
              </Stack>
            </div>
          )}
          {schemaJson == null || schemaView === SchemaView.Raw ? (
            <div className="DatabaseTab-schema">
              <pre>
                {schema ||
                  'The schema is temporarily unavailable. You may still be able to access the database by writing code.'}
              </pre>
            </div>
          ) : schemaView === SchemaView.Explorer ? (
            <div style={{ flexGrow: 2, height: '200px', width: '100%' }}>
              <SchemaExplorer schemaJson={schemaJson} />
            </div>
          ) : null}
        </>
      ) : (
        <div className="DatabaseTab-header">
          A {databaseName} database exists but the current language does not support it. To access
          it, please change to one of these languages:
          <ul>
            {_.sortBy(Object.values(languages), (lang) => lang.display).map(({ display, name }) => (
              <li
                key={name}
                className="DatabaseTab-languageButton"
                onClick={() => {
                  dispatch(
                    padSettingChanged('language', name, {
                      userId,
                      _analytics: {
                        name: 'Language Changed (DatabaseTab)',
                        params: { to_language: name },
                      },
                    })
                  )
                }}
              >
                {display}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
