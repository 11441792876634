// Legacy DOM renderer code ported from https://github.com/xtermjs/xterm.js/blob/2.9.2/src/Renderer.ts#L123-L323
// with some support for xterm 4.x coming from https://github.com/xtermjs/xterm.js/pull/2201
import { Terminal } from 'xterm'

// https://github.com/xtermjs/xterm.js/blob/2b39887a3771950ba59a4e21dd1820fdb8fa5ff1/src/browser/renderer/atlas/Constants.ts#L6
const INVERTED_DEFAULT_COLOR = 257

function lastPresentLine(buffer) {
  for (let i = buffer.lines.length - 1; i >= 0; i--) {
    const line = buffer.lines.get(i)
    for (let j = 0; j < line.length; j++) {
      if (line.get(j)[1] !== '') return i
    }
  }
  return buffer.lines.length
}

function spanToHTML(element) {
  return `<span class="${element.classes.join(' ')}">${element.innerHTML}</span>`
}

function renderTerminal(terminal) {
  const buffer = terminal._core.buffer
  // CellData classes are difficult to initialize and xterm recommends you just keep
  // one around as a buffer, to be serialized from the packed internal array impl
  // of the buffer (see https://github.com/xtermjs/xterm.js/blob/master/src/common/buffer/BufferLine.ts)
  const workCell = new buffer._nullCell.constructor() // new CellData()
  const drawBoldTextInBrightColors = terminal.getOption('drawBoldTextInBrightColors')
  const end = lastPresentLine(buffer)

  let container = ''
  for (let y = 0; y <= end; y++) {
    const row = y + buffer.ydisp
    const line = buffer.lines.get(row)
    if (!line) continue

    let oldFg = 0
    let oldBg = 0
    let documentFragment = ''
    let innerHTML = ''
    let currentElement = null

    for (let i = 0; i < line.length; i++) {
      line.loadCell(i, workCell)
      const ch = workCell.getChars()
      const ch_width = workCell.getWidth()

      if (ch_width === 0) {
        continue
      }

      if (workCell.fg !== oldFg || workCell.bg !== oldBg) {
        if (oldFg !== 0 || oldBg !== 0) {
          if (innerHTML) {
            currentElement.innerHTML = innerHTML
            innerHTML = ''
          }
          documentFragment += spanToHTML(currentElement)
          currentElement = null
        }
        if (workCell.fg !== 0 || workCell.bg !== 0) {
          if (innerHTML && !currentElement) {
            currentElement = { innerHTML: '', classes: [] }
          }
          if (currentElement) {
            if (innerHTML) {
              currentElement.innerHTML = innerHTML
              innerHTML = ''
            }
            documentFragment += spanToHTML(currentElement)
          }
          currentElement = { innerHTML: '', classes: [] }

          if (workCell.isBold()) {
            currentElement.classes.push('xterm-bold')
          }

          if (workCell.isUnderline()) {
            currentElement.classes.push('xterm-underline')
          }

          if (workCell.isBlink()) {
            currentElement.classes.push('xterm-blink')
          }

          if (workCell.isInvisible()) {
            currentElement.classes.push('xterm-hidden')
          }

          const swapColor = workCell.isInverse()

          if (workCell.isFgPalette()) {
            let fg = workCell.getFgColor()
            if (workCell.isBold() && fg < 8 && !swapColor && drawBoldTextInBrightColors) {
              fg += 8
            }
            currentElement.classes.push(`xterm-${swapColor ? 'bg' : 'fg'}-${fg}`)
          } else if (swapColor) {
            currentElement.classes.push(`xterm-bg-${INVERTED_DEFAULT_COLOR}`)
          }

          if (workCell.isBgPalette()) {
            currentElement.classes.push(`xterm-${swapColor ? 'fg' : 'bg'}-${workCell.getBgColor()}`)
          } else if (swapColor) {
            currentElement.classes.push(`xterm-fg-${INVERTED_DEFAULT_COLOR}`)
          }
        }
      }

      if (ch_width === 2) {
        // Wrap wide characters so they're sized correctly. It's more difficult to release these
        // from the object pool so just create new ones via innerHTML.
        innerHTML += `<span class="xterm-wide-char">${ch}</span>`
      } else if (ch.charCodeAt(0) > 255) {
        // Wrap any non-wide unicode character as some fonts size them badly
        innerHTML += `<span class="xterm-normal-char">${ch}</span>`
      } else {
        switch (ch) {
          case '&':
            innerHTML += '&amp;'
            break
          case '<':
            innerHTML += '&lt;'
            break
          case '>':
            innerHTML += '&gt;'
            break
          default:
            if (ch <= ' ') {
              innerHTML += '&nbsp;'
            } else {
              innerHTML += ch
            }
            break
        }
      }
      oldFg = workCell.fg
      oldBg = workCell.bg
    }

    if (innerHTML && !currentElement) {
      currentElement = { innerHTML: '', classes: [] }
    }
    if (currentElement) {
      if (innerHTML) {
        currentElement.innerHTML = innerHTML.replace(/(&nbsp;)+$/, '&nbsp;')
        innerHTML = ''
      }
      documentFragment += spanToHTML(currentElement)
      currentElement = null
    }

    container += `<div>${documentFragment}</div>`
  }
  return container
}

export default function render(output) {
  const dummyTerm = new Terminal({
    cols: 73,
    rows: 1000,
    tabStopWidth: 4,
  })

  return new Promise(function(resolve, reject) {
    dummyTerm.write(output, function() {
      resolve(renderTerminal(dummyTerm))
    })
  })
}
