import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material'
import React from 'react'

interface IButton extends ButtonProps {
  iconOnly?: boolean
}

export const StyledFooterButton = styled(Button)<IButton>(({ theme }) => ({
  whiteSpace: 'nowrap',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  ':hover': {
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
  },
  fontWeight: 'normal',
}))

interface IIconButton extends IconButtonProps {
  href?: string
}

const StyledIconButton = styled(IconButton)<IIconButton>()

export const FooterButton = ({ iconOnly = false, children, ...props }: IButton) => {
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return large && !iconOnly ? (
    <StyledFooterButton size="small" {...props} data-testid="styled-footer-button">
      {children}
    </StyledFooterButton>
  ) : (
    <StyledIconButton onClick={props.onClick} href={props.href} data-testid="icon-button">
      {props.startIcon}
    </StyledIconButton>
  )
}
