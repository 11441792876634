import $ from 'jquery'

export default function setupJQuery() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DEFAULTS = ($.fn.tooltip as any).Constructor.DEFAULTS
  DEFAULTS.viewport = { selector: 'body', padding: 15 }
  DEFAULTS.container = 'body'

  $(function () {
    $('.tooltip-target').tooltip()
    $('input.autoselect').click(function () {
      $(this).select()
    })
  })

  $.support.cors = true
}
