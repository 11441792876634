import mailCheckSetup from './mailcheck_wrapper'

$(function() {
  if (!window.CoderPad.sso_domains) return

  const tooltip = $('#email-domain-tooltip')
  const extras = $(
    '#user_name_input, #user_password_input, #trial-notice, .btn.btn-primary, .devise-links, #user_email_opt_in_input, .login-Disclaimer, .or-container, .log-in-button'
  )
  const submit = $('.btn.btn-primary')

  mailCheckSetup($('#user_email'))

  $('#user_email').on('keyup change input', function() {
    let url
    const email = $(this).val()
    const match = /@([\w.]+)$/.exec(email)
    if (match && (url = window.CoderPad.sso_domains[match[1]])) {
      tooltip.find('a').attr('href', url)
      submit.prop('disabled', true)
      tooltip.fadeIn()
      extras.fadeOut()
    } else {
      submit.prop('disabled', false)
      tooltip.fadeOut()
      extras.fadeIn()
    }
  })
})
