import { Box, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TabsContextProvider } from 'packs/dashboard/components/Tabs/TabsContext'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorBoundary } from '../../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../../dashboard/components/GenericErrorView/GenericErrorView'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { Pane, SplitPane } from '../../dashboard/components/SplitPane/SplitPane'
import { Tab, TabList, TabPanel } from '../../dashboard/components/Tabs'
import { Console } from '../Console/index'
import { PadAnalyticsEvent } from '../constants'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import LegacyConsole from '../legacy_console'
import { ProjectTab, setSelectedTab } from '../reducers/projectTabs'
import { History as RequestHistory } from '../RequestClient/History/History'
import { RequestClientConfiguration } from '../RequestClient/RequestClientConfiguration/RequestClientConfiguration'
import { RequestClientConfigurationContextProvider } from '../RequestClient/RequestClientConfiguration/RequestClientConfigurationContext'
import { RequestClientContextProvider } from '../RequestClient/RequestClientContext/RequestClientContext'
import { RequestClientResponse } from '../RequestClient/RequestClientResponse/RequestClientResponse'
import { RequestTimingHeader } from '../RequestClient/RequestClientResponse/RequestTimingHeader'
import { RequestClientSelectedProvider } from '../RequestClient/RequestClientSelectedContext/RequestClientSelectedContext'
import { ScrollView } from '../ScrollView/ScrollView'
import trackEvent from '../track_event'
import { MainProcessActions } from './MainProcessActions'
import { ProjectBrowser } from './ProjectBrowser'
import { ProjectIframeAPIProvider } from './ProjectBrowserContext'
import { ProjectTerm } from './ProjectTerm'

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 53,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
  tabsContent: {
    flex: '1 1',
    minHeight: 0,
  },
  tabContent: {
    height: '100%',
  },
  tabContentHidden: {
    display: 'none',
  },
  commandOutput: {
    // Gets rid of wonky legacy top styling value on the legacy console component.
    top: '-10px',
  },
})

const StyledTabPanel = styled(TabPanel)({
  flex: 1,
  minHeight: 0,
})

const FlexingScrollView = styled(ScrollView)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  padding: theme.spacing(1.25),
  boxSizing: 'border-box',
}))

export interface ProjectOutputProps {
  hidden: boolean
}

const getTabLabels: (executionType?: string) => Record<ProjectTab, string> = (executionType) => ({
  [ProjectTab.MainProcess]: executionType === 'RunCommand' ? 'Shell' : 'Server',
  [ProjectTab.Shell]: 'Shell',
  [ProjectTab.Console]: 'Console',
  [ProjectTab.RequestHistory]: 'Request History',
})

export const ProjectOutput: React.FC<ProjectOutputProps> = ({ hidden }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { slug, isPlayback } = usePadConfigValues('slug', 'isPlayback')
  const { environment, projectTemplate } = useActiveEnvironment()
  const isRunCommandTemplate = projectTemplate?.settings?.executionType === 'RunCommand'
  const { activeTab, unseenContent, visibleTabs } = useSelector((state) => state.projectTabs)
  const { activeTab: activePaneTab } = useSelector((state) => state.tabs)
  const isRequestClientMode = activePaneTab === 'requestClient'

  const setCurrentTab = useCallback(
    (tab: string) => {
      dispatch(setSelectedTab(tab as ProjectTab))
      trackEvent(PadAnalyticsEvent.ProjectOutputTabClicked, { target: tab })
    },
    [dispatch]
  )

  const tabLabels = useMemo(() => getTabLabels(projectTemplate?.settings?.executionType), [
    projectTemplate?.settings?.executionType,
  ])

  const [tabs, tabPanels] = useMemo(() => {
    const ts = [
      visibleTabs[ProjectTab.MainProcess] && (
        <Tab
          value={ProjectTab.MainProcess}
          label={tabLabels[ProjectTab.MainProcess]}
          cta={unseenContent[ProjectTab.MainProcess]}
          key="tab-main-process"
          onClick={() => setCurrentTab(ProjectTab.MainProcess)}
        />
      ),
      visibleTabs[ProjectTab.Shell] && !isPlayback && (
        <Tab
          value={ProjectTab.Shell}
          label={tabLabels[ProjectTab.Shell]}
          cta={unseenContent[ProjectTab.Shell]}
          key="tab-shell"
          onClick={() => setCurrentTab(ProjectTab.Shell)}
        />
      ),
      visibleTabs[ProjectTab.Console] && (
        <Tab
          value={ProjectTab.Console}
          label={tabLabels[ProjectTab.Console]}
          cta={unseenContent[ProjectTab.Console]}
          key="tab-console"
          onClick={() => setCurrentTab(ProjectTab.Console)}
        />
      ),
      visibleTabs[ProjectTab.RequestHistory] && (
        <Tab
          value={ProjectTab.RequestHistory}
          label={tabLabels[ProjectTab.RequestHistory]}
          cta={unseenContent[ProjectTab.RequestHistory]}
          key="tab-api"
          onClick={() => setCurrentTab(ProjectTab.RequestHistory)}
        />
      ),
    ]

    const panels = [
      visibleTabs[ProjectTab.MainProcess] && (
        <StyledTabPanel
          unmountOnHidden={false}
          value={ProjectTab.MainProcess}
          key="panel-main-process"
        >
          <ProjectTerm id="MAIN_PROCESS" />
        </StyledTabPanel>
      ),
      visibleTabs[ProjectTab.Shell] && !isPlayback && (
        <StyledTabPanel unmountOnHidden={false} value={ProjectTab.Shell} key="panel-shell">
          <ProjectTerm id="DEFAULT_SHELL" />
        </StyledTabPanel>
      ),
      visibleTabs[ProjectTab.Console] && (
        <StyledTabPanel unmountOnHidden={false} value={ProjectTab.Console} key="panel-console">
          <ScrollView>
            <Console />
          </ScrollView>
        </StyledTabPanel>
      ),
      visibleTabs[ProjectTab.RequestHistory] && (
        <StyledTabPanel unmountOnHidden={false} value={ProjectTab.RequestHistory} key="panel-api">
          <ScrollView>
            <RequestHistory />
          </ScrollView>
        </StyledTabPanel>
      ),
    ]
    return [ts, panels]
  }, [visibleTabs, tabLabels, unseenContent, isPlayback, setCurrentTab])

  // Wait until the environment and projectTemplate have loaded in before rendering, in order to use
  // details about the environment to determine default pane size
  if (environment == null || (environment.projectTemplateSlug != null && projectTemplate == null)) {
    return null
  }

  return (
    <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
      <RequestClientContextProvider>
        <RequestClientSelectedProvider>
          <RequestClientConfigurationContextProvider>
            <ProjectIframeAPIProvider padSlug={slug}>
              <div className={classNames(styles.container, { [styles.hidden]: hidden })}>
                <SplitPane orientation="y">
                  <Pane id="projectoutput-iframe" minSize={60}>
                    <Box p={'10px'} height="100%" boxSizing="border-box">
                      {isRunCommandTemplate ? (
                        <LegacyConsole
                          hidden={hidden}
                          className={styles.commandOutput}
                          key={environment?.id}
                        />
                      ) : isRequestClientMode ? (
                        <RequestClientConfiguration />
                      ) : (
                        <ProjectBrowser />
                      )}
                    </Box>
                  </Pane>
                  {isRequestClientMode ? (
                    <Pane id="request-client-response" component={FlexingScrollView} minSize={60}>
                      <RequestTimingHeader />
                      <RequestClientResponse />
                    </Pane>
                  ) : null}
                  {tabs.length > 0 ? (
                    <Pane
                      id="projectoutput-console"
                      initialSizePct={projectTemplate?.slug?.startsWith('jupyter') ? 20 : 40}
                      minSize={70}
                    >
                      <Box
                        p={'10px'}
                        height="100%"
                        boxSizing="border-box"
                        display="flex"
                        flexDirection="column"
                        minHeight={0}
                      >
                        <TabsContextProvider value={{ size: 'small', value: activeTab }}>
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            <TabList
                              scrollButtons="auto"
                              variant="scrollable"
                              sx={{ minHeight: '26px' }}
                            >
                              {tabs}
                            </TabList>
                            <MainProcessActions />
                          </Box>
                          {tabPanels}
                        </TabsContextProvider>
                      </Box>
                    </Pane>
                  ) : null}
                </SplitPane>
              </div>
            </ProjectIframeAPIProvider>
          </RequestClientConfigurationContextProvider>
        </RequestClientSelectedProvider>
      </RequestClientContextProvider>
    </ErrorBoundary>
  )
}
