import _ from 'lodash'
import Mailcheck from 'mailcheck'

const domains = ['gmail.com', 'yahoo.com', 'hotmail.com']
const secondLevelDomains = ['gmail', 'yahoo', 'hotmail']
const topLevelDomains = ['ca', 'co', 'com', 'edu', 'io', 'it', 'net', 'org', 'pt', 'ru', 'tv']

// Synchronous wrapper around mailcheck. Returns a suggested domain or null.
export function autoCorrectEmailDomain(email) {
  // Mailcheck has a strange API that takes two callbacks instead of just
  // returning a value, even though it calls the callbacks synchronously.
  let suggestion
  Mailcheck.run({
    email,
    domains,
    secondLevelDomains,
    topLevelDomains,
    suggested(s) {
      suggestion = s
    },
    empty() {
      suggestion = null
    },
  })

  return suggestion
}

export default function mailCheckSetup(jQueryElement, toolTipOptions) {
  // jQueryElement is result of jquery selector
  toolTipOptions = _.extend(
    {
      animation: false,
      trigger: 'manual',
      html: true,
      placement: 'top',
    },
    toolTipOptions
  )

  jQueryElement.tooltip(toolTipOptions)

  jQueryElement.on(
    'keyup keydown change input blur',
    _.debounce(function () {
      $(this).mailcheck({
        domains,
        secondLevelDomains,
        topLevelDomains,
        suggested(element, suggestion) {
          const suggestedDomain = `Did you mean <b>${suggestion.domain}</b>?`
          $(element).attr('data-original-title', suggestedDomain).tooltip('show')
        },
        empty(element) {
          $(element).attr('data-original-title', '').tooltip('hide')
        },
      })
    }, 750)
  )
}
