import { useCallback, useEffect, useRef, useState } from 'react'

import { RiffPcmEncoder } from '../../video/RiffPcmEncoder'
import { useSound } from '../../video/SoundProvider'

type UseAudioStreamResult = {
  start: () => Promise<void>
  stop: () => void
  error: Error | null
  isListening: boolean
  isInitializing: boolean
  sampleRate: number
  channelCount: number
  audioContext: AudioContext | null
}

export const useAudioStream = (
  audioDeviceId: string,
  onAudioData: (data: Int16Array) => void
): UseAudioStreamResult => {
  const [isListening, setIsListening] = useState<boolean>(false)
  const [isInitializing, setIsInitializing] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [sampleRate, setSampleRate] = useState<number>(0)
  const [channelCount, setChannelCount] = useState<number>(0)
  const sound = useSound()

  const audioContext = useRef<AudioContext | null>(null)
  const mediaStreamSource = useRef<MediaStreamAudioSourceNode | null>(null)

  const callback = useRef<(data: Int16Array) => void>(onAudioData)

  const prevListeningState = useRef<boolean>(false)

  useEffect(() => {
    if (isListening && !prevListeningState.current) {
      sound.play('recording_in_progress.mp3')
    }
    prevListeningState.current = isListening
  }, [isListening, sound])

  /**
   * We assign this to a ref, because we don't want to trigger a re-render,
   * which would cause a complete re-initialization of the audio processing
   * node, resulting in dropped audio data.
   */
  useEffect(() => {
    callback.current = onAudioData
  }, [onAudioData])

  const createAudioContext = useCallback(() => {
    console.log('Creating new AudioContext')
    audioContext.current = new AudioContext({ sampleRate: 16000 })
    audioContext.current.suspend()
  }, [])

  useEffect(() => {
    if (!audioContext.current) {
      createAudioContext()
    }
  }, [createAudioContext])

  const start = useCallback(async () => {
    if (isInitializing || isListening || audioDeviceId == null) return
    setIsInitializing(true)
    try {
      if (!audioContext.current) {
        console.error('Audio context not initialized')
        return
      } else if (audioContext.current.state === 'suspended') {
        console.log('Resuming audio context')
        await audioContext.current.resume()
      }

      console.log('Initializing audio processing...', isInitializing, isListening)

      await audioContext.current.audioWorklet.addModule(
        window.CoderPad.ASSETS['speech-processor.js']
      )
      console.log('Audio worklet module loaded')
      const workletNode = new AudioWorkletNode(audioContext.current, 'speech-processor')

      const renderAudioFrame = async (inputFrame: Float32Array) => {
        if (audioContext.current == null) {
          console.error('Audio context is not available')
          return
        }

        const waveStreamEncoder = new RiffPcmEncoder(
          audioContext.current.sampleRate,
          16000,
          mediaStreamSource.current?.channelCount ?? 1
        )

        const waveFrame = await waveStreamEncoder.encode(inputFrame)

        if (waveFrame != null) {
          callback.current(new Int16Array(waveFrame))
        }
      }

      workletNode.port.onmessage = async (ev: MessageEvent) => {
        switch (ev.data.type) {
          case 'pcm-audio':
            await renderAudioFrame(ev.data.data as Float32Array)
            break
          case 'log':
            console.log('Audio worklet:', ev.data.message)
            break
          default:
            break
        }
      }

      setSampleRate(audioContext.current.sampleRate)

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioDeviceId },
      })
      mediaStreamSource.current = audioContext.current.createMediaStreamSource(stream)
      setChannelCount(mediaStreamSource.current.channelCount)
      mediaStreamSource.current.connect(workletNode).connect(audioContext.current.destination)

      setIsListening(true)
      console.log('Audio processing started', {
        sampleRate: audioContext.current.sampleRate,
        channelCount: mediaStreamSource.current.channelCount,
      })
      setIsInitializing(false)
    } catch (err: any) {
      console.error('Error starting audio processing:', err)
      console.error(`Detailed error: ${err.name} - ${err.message}`)
      setError(err as Error)
    } finally {
      setIsInitializing(false)
    }
  }, [audioDeviceId, isInitializing, isListening])

  const stop = useCallback(async () => {
    if (audioContext.current) {
      // clear the audio context by closing and re-creating it
      // this ensures that there are no leaky nodes.
      audioContext.current.close()
      createAudioContext()

      mediaStreamSource.current?.disconnect()
      mediaStreamSource.current = null
      setIsListening(false)
      console.log('Audio processing stopped')
    }
  }, [createAudioContext])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])

  return {
    start,
    stop,
    error,
    isListening,
    isInitializing,
    sampleRate,
    channelCount,
    audioContext: audioContext.current,
  }
}
