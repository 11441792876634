import { gql, useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import { PadTranslation } from '../../../../packs/main/constants'
import * as queryStates from '../../../queryStates'
import {
  MutationPadShortlinkGenerateArgs,
  PadShortlinkGenerateInput,
  PadShortlinkGeneratePayload,
} from '../../../types'

export const PAD_SHORTLINK_GENERATE_MUTATION = gql`
  mutation PadShortlinkGenerate($input: PadShortlinkGenerateInput!) {
    padShortlinkGenerate(input: $input) {
      errors {
        message
        path
      }
      message
      shortlink
    }
  }
`

export interface PadShortlinkGenerateData {
  padShortlinkGenerate: PadShortlinkGeneratePayload
}

export function usePadShortlinkGenerate() {
  const [padShortlinkGenerate, { data, error, loading }] = useMutation<
    PadShortlinkGenerateData,
    MutationPadShortlinkGenerateArgs
  >(PAD_SHORTLINK_GENERATE_MUTATION)

  const handlePadShortlinkGenerate = useCallback(
    async (padShortlinkGenerateInput: PadShortlinkGenerateInput) =>
      padShortlinkGenerate({
        variables: { input: padShortlinkGenerateInput },
        context: { source: 'usePadShortlinkGenerate.ts' },
      }),
    [padShortlinkGenerate]
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(error.message || PadTranslation.padInvitesShortlinkGenerateError)
    } else if (data?.padShortlinkGenerate?.errors?.length) {
      return queryStates.error(data.padShortlinkGenerate.errors[0].message)
    } else if (data?.padShortlinkGenerate) {
      return queryStates.success(
        data?.padShortlinkGenerate?.message || PadTranslation.padInvitesShortlinkGenerateSuccess
      )
    } else {
      return queryStates.initial()
    }
  }, [data, error, loading])

  return useMemo(
    () => ({
      padShortlinkGenerate: handlePadShortlinkGenerate,
      status,
    }),
    [handlePadShortlinkGenerate, status]
  )
}
