import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback, useEffect, useState } from 'react'

import TwilioTrack from './twilio_track'
import VolumeMeter from './volume_meter'

interface User {
  audio?: any // AudioTrack from Twilio
  name: string
  self?: boolean
  video?: any // VideoTrack from Twilio
}

interface VideoThumbnailProps {
  audioMuted?: boolean
  muteAudio?: () => void
  muteVideo?: () => void
  unmuteAudio?: () => void
  unmuteVideo?: () => void
  user: User
  videoMuted?: boolean
  ccEnabled?: boolean
  toggleCC?: () => void
}

const VIDEO_EVENTS_OF_INTEREST: string[] = ['disabled', 'enabled', 'started', 'stopped']

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  audioMuted,
  muteAudio,
  muteVideo,
  unmuteAudio,
  unmuteVideo,
  user,
  videoMuted,
  ccEnabled,
  toggleCC,
}: VideoThumbnailProps) => {
  const [hasVideo, setHasVideo] = useState<boolean>(false)
  const hasTranscriptions = usePadConfigValue('hasTranscriptions')

  const hasVideoCheck = useCallback(() => {
    const { video } = user
    return video && video.isStarted && video.isEnabled
  }, [user])

  const updateVideoState = useCallback(() => {
    setHasVideo(hasVideoCheck())
  }, [hasVideoCheck])

  const addVideoListeners = useCallback(
    (video: any) => {
      for (const eventName of VIDEO_EVENTS_OF_INTEREST) {
        video.on(eventName, updateVideoState)
      }
    },
    [updateVideoState]
  )

  const removeVideoListeners = useCallback((video: any) => {
    for (const eventName of VIDEO_EVENTS_OF_INTEREST) {
      video.off(eventName, updateVideoState)
    }
  }, [])

  useEffect(() => {
    if (user.video) {
      addVideoListeners(user.video)
      setHasVideo(hasVideoCheck())
    }

    return () => {
      if (user.video) {
        removeVideoListeners(user.video)
      }
    }
  }, [addVideoListeners, hasVideoCheck, removeVideoListeners, user.video])

  return (
    <div className="video-thumbnail">
      {user.audio && <VolumeMeter track={user.audio} />}
      <div className="info">
        {user.name}
        {user.self && muteAudio && (
          <div className="controls">
            {user.audio &&
              (!audioMuted ? (
                <button className="mute" onClick={muteAudio}></button>
              ) : (
                <button className="mute unmute" onClick={unmuteAudio}></button>
              ))}
            {hasTranscriptions && (
              <button className="cc" onClick={toggleCC}>
                {ccEnabled ? <ClosedCaptionIcon /> : <ClosedCaptionOffIcon />}
              </button>
            )}
            {user.video &&
              (!videoMuted ? (
                <button className="hide" onClick={muteVideo}></button>
              ) : (
                <button className="hide unhide" onClick={unmuteVideo}></button>
              ))}
          </div>
        )}
      </div>
      {user.video && hasVideo ? (
        <TwilioTrack kind="video" track={user.video} />
      ) : (
        <div className="no-video">No Video</div>
      )}
      {!user.self && user.audio && <TwilioTrack kind="audio" track={user.audio} />}
    </div>
  )
}

export default VideoThumbnail
