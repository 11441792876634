import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'

export function useFirebaseActiveEnvironment() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  const [activeEnvSlug, setActiveEnvSlug] = useState<string>()
  const [hasLoadedActiveEnv, setHasLoadedActiveEnv] = useState(false)

  useEffect(() => {
    let activeEnvWatcher: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      activeEnvWatcher = SyncHandle().watch('activeEnvironment', (envSlug: string) => {
        setActiveEnvSlug(envSlug || '')
        setHasLoadedActiveEnv(true)
      })
    }
    return () => {
      if (activeEnvWatcher != null) {
        SyncHandle().off('activeEnvironment', activeEnvWatcher)
      }
    }
  }, [hasEnvironments])

  return {
    activeEnvSlug,
    hasLoadedActiveEnv,
  }
}
