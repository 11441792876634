import { basename, dirname } from 'path-browserify'

import { DirectoryItem, DirectoryItemType } from '../useDirectory/useDirectory'
import { MonacoFile } from './types'

const directoryItemSorter = (a: DirectoryItem, b: DirectoryItem): number => {
  if (a.type === b.type) {
    // both are folders or both are files, so sort alphabetically (case insensitive)
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  } else if (a.type === DirectoryItemType.folder) {
    // a is a folder, b is a file, so sort a first
    return -1
  } else {
    // a is a file, b is a folder, so sort b first
    return 1
  }
}

// recursively sort the directory
const sortDirectory = (entries: DirectoryItem[]): DirectoryItem[] => {
  return entries
    .map(({ children, ...entry }) => ({
      ...entry,
      children: sortDirectory(children),
    }))
    .sort(directoryItemSorter)
}

export const buildDirectory = (files: MonacoFile[], emptyFolderPaths: string[] = []) => {
  // TODO: optimize so that we only sort when we need to (ie when the
  // files in a project have changed), and not when details about the file
  // (specifically the user list) change - but still show the user tracker!

  const allItems = [
    ...files.map((file) => ({
      type: DirectoryItemType.file,
      // buildDirectory is used by legacy (non-environments) HTML/JS/CSS Pads to render a file
      // pane.  the paths for the three files start with /tmp/project/, which is confusing to
      // users and also causes the logic below to break.  Once we have rolled out environments
      // pads and the HTML template to 100% of users, we can remove the below ternary and always
      // rely on file.path to be the right one.
      path: file.path.startsWith('/tmp/project/')
        ? file.path.replace('/tmp/project/', 'coderpad/')
        : file.path,
      file,
    })),
    ...emptyFolderPaths.map((folderPath) => ({
      type: DirectoryItemType.folder,
      path: folderPath,
      file: undefined,
    })),
  ]

  const result: DirectoryItem[] = []
  const directoryChildren: Record<string, DirectoryItem['children']> = {
    '.': result,
  }
  allItems.forEach(({ path, type, file }) => {
    let lastItem: DirectoryItem | null = null
    for (let currentPath = path; ; currentPath = dirname(currentPath)) {
      // If parent already exists, just add the lastItem and break
      if (directoryChildren[currentPath] != null) {
        if (lastItem != null) {
          directoryChildren[currentPath].push(lastItem)
        }
        break
      }

      // Otherwise create the new file or folder item
      const directory: DirectoryItem = {
        name: basename(currentPath),
        path: currentPath,
        ...(currentPath === path
          ? { type, file, children: [] }
          : { type: DirectoryItemType.folder, file: undefined, children: [lastItem!] }),
      }
      directoryChildren[currentPath] = directory.children
      lastItem = directory
    }
  })

  return sortDirectory(result)
}
