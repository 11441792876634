import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { LanguageSelectorInfoButton } from './LanguageSelectorInfoButton'
import { LanguageSelectorInfoLink } from './LanguageSelectorInfoLink'
import LanguageIcon from './Monaco/FilePane/LanguageIcon'
import padConfig from './pad_config'
import { selectLanguagesAvailable } from './selectors'

const BASE_STATE = {
  languagesExpanded: false,
  packagesExpanded: false,
}

class _LanguageSelector extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    execEnabled: PropTypes.bool.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onPackageChange: PropTypes.func.isRequired,
    languagesAvailable: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = BASE_STATE
  }

  resetState() {
    this.setState(BASE_STATE)
  }

  handleSwitchToLanguageClick = (evt) => {
    this.resetState()
    const lang = evt.target.dataset.language
    if (lang && lang !== this.props.language)
      this.props.onLanguageChange(lang, this.props.userId, this.props.language)
  }

  handlePackageClick = (evt) => {
    this.resetState()
    const { language, packageName, databaseLanguage } = evt.target.dataset
    this.props.onPackageChange({ language, packageName, databaseLanguage })
  }

  handleLanguageDropdownToggle = (evt) => {
    this.setState(({ languagesExpanded, packagesExpanded }) => ({
      languagesExpanded: !languagesExpanded,
      packagesExpanded: false,
    }))
    evt.preventDefault()
  }

  handlePackageDropdownToggle = (evt) => {
    this.setState(({ languagesExpanded, packagesExpanded }) => ({
      languagesExpanded: false,
      packagesExpanded: !packagesExpanded,
    }))
    evt.preventDefault()
  }

  render() {
    const { invisible } = padConfig
    const { execEnabled, language, languagesAvailable } = this.props
    const { languagesExpanded, packagesExpanded } = this.state
    const packagesAvailable = window.CoderPad.LANGUAGES[language].packages || []
    const packageCount = Object.keys(packagesAvailable).length
    const languageCount = Object.keys(languagesAvailable).length

    return (
      <div
        className={classNames({
          LanguageSelector: true,
        })}
        ref={(el) => (this._root = el)}
      >
        <LanguageSelectorInfoButton language={language} />
        {!invisible && execEnabled && packageCount > 0 && (
          <>
            <button
              className="btn btn-inverse LanguageSelector-packageButton"
              onClick={this.handlePackageDropdownToggle}
            >
              Packages
            </button>
            <span
              className={classNames({
                open: packagesExpanded,
              })}
            >
              <ul
                className="dropdown-menu dropdown-menu-right dropdown-inverse pull-right LanguageSelector-packageMenu LanguageSelector-dropdownMenu LanguageSelector-dropdownMenu--small"
                aria-label="Language Packages"
                aria-expanded={packagesExpanded}
                ref={(el) => (this._packageMenu = el)}
              >
                {_.sortBy(
                  Object.values(packagesAvailable),
                  (packageObject) => packageObject.display
                ).map(({ display, name, database }) => (
                  <li
                    className="LanguageSelector-packageName"
                    key={name}
                    data-language={language}
                    data-package-name={name}
                    data-database-language={database}
                    onClick={this.handlePackageClick}
                  >
                    {display}
                  </li>
                ))}
              </ul>
            </span>
          </>
        )}
        <button
          className={classNames('btn', 'btn-inverse', 'LanguageSelector-dropdownButton', {
            disabled: invisible,
          })}
          aria-haspopup="true"
          aria-expanded={!!languagesExpanded}
          onClick={this.handleLanguageDropdownToggle}
        >
          <LanguageIcon language={window.CoderPad.LANGUAGES[language].name} />
          <span id="chosen-language">{window.CoderPad.LANGUAGES[language].display}</span>
          &nbsp;
          {!invisible && <span className="caret LanguageSelector-dropdownButtonCaret" />}
        </button>

        <span className="dropdown-arrow dropdown-arrow-inverse" />

        {!invisible && (
          <span
            className={classNames({
              open: languagesExpanded,
            })}
          >
            <ul
              className={classNames({
                'dropdown-menu': true,
                'dropdown-inverse': true,
                'pull-right': true,
                'LanguageSelector-dropdownMenu': true,
                'LanguageSelector-languageMenu': true,
                'LanguageSelector-languageMenu--small': languageCount < 10,
              })}
              aria-label="Language"
              aria-expanded={languagesExpanded}
              ref={(el) => (this._menu = el)}
            >
              {_.sortBy(Object.values(languagesAvailable), (lang) => lang.display).map(
                ({ display, name }) => (
                  <li
                    className="LanguageSelector-languageName"
                    key={name}
                    tabIndex="0"
                    data-language={name}
                    onClick={this.handleSwitchToLanguageClick}
                    onKeyUp={(e) => {
                      if (e.key == 'Enter') this.handleSwitchToLanguageClick(e)
                    }}
                  >
                    <LanguageSelectorInfoLink language={name} />
                    <div className="icon-container">
                      <LanguageIcon language={name} />
                    </div>
                    {display}
                  </li>
                )
              )}
            </ul>
          </span>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const langsWithoutGsheets = Object.fromEntries(
    Object.entries(selectLanguagesAvailable(state)).filter(([key, val]) => key !== 'gsheets')
  )
  return {
    userId: state.userState.userId,
    execEnabled: state.padSettings.execEnabled,
    languagesAvailable: langsWithoutGsheets,
  }
}

const LanguageSelector = connect(mapStateToProps)(_LanguageSelector)

export default LanguageSelector
