import { useMemo } from 'react'

export function getFirepadCreateOptions(userId: string, defaultText: string = '') {
  return {
    userId,
    userColor: 'transparent',
    defaultText,
    cursorOptions: {
      tooltips: true,
      tooltipDuration: 1,
    },
  }
}
export function useFirepadCreateOptions(padUserId: string) {
  // Used as the options when creating a new monaco Firepad.
  const firepadOptions = useMemo(() => getFirepadCreateOptions(padUserId), [padUserId])

  return firepadOptions
}
