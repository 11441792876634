// Pad-wide settings.
//
// This reducer is very simple, because it trusts the actions fired to provide
// reasonable keys and values.
//
// The expected contents are:
//
//   execEnabled: boolean
//   isPublic: boolean
//   language: string, e.g. "javascript" or "cpp"

export default function padSettingsReducer(state = {}, action) {
  switch (action.type) {
    case 'pad_take_home_toggled': {
      const isTakeHome = action.value
      let updates = { takeHome: isTakeHome }

      if (isTakeHome) {
        updates.isPublic = true
        updates.takeHomeTimeLimit =
          typeof state.takeHomeTimeLimit === 'number' ? state.takeHomeTimeLimit : 120
      }

      return {
        ...state,
        ...updates,
      }
    }
    case 'focus_time_toggled': {
      return {
        ...state,
        focusTimeEnabled: action.value,
      }
    }
    case 'focus_time_started_at': {
      return {
        ...state,
        focusTimeStartedAt: action.value,
      }
    }
    case 'pad_setting_changed': {
      return {
        ...state,
        [action.key]: action.value,
        lastPadSettingChangeWasRemote: action.remote,
      }
    }
    case 'environment_question_changed':
    case 'question_selected_by_local_user': {
      return {
        ...state,
        language: action.language,
        questionId: action.questionId,
        questionContents: action.contents,
        questionLanguage: action.language,
        customDatabaseId: action.customDatabaseId,
        customDatabaseLanguage: action.customDatabaseLanguage,
        customDatabaseSchema: action.customDatabaseSchema,
        customDatabaseSchemaJson: action.customDatabaseSchemaJson,
        projectTemplateSlug: action.projectTemplateSlug,
        projectTemplateVersion: action.projectTemplateVersion,
        environmentSlug: action.environmentSlug,
        spreadsheet: action.spreadsheet,
      }
    }
    case 'package_changed': {
      return {
        ...state,
        packageName: action.packageName,
      }
    }
    case 'service_status': {
      return {
        ...state,
        serviceStatus: action.status,
      }
    }
    case 'activate_environment':
      return {
        ...state,
        environmentId: action.environmentId,
      }
  }
  return state
}

export function padSettingChanged(key, value, extraProperties = {}) {
  return {
    type: 'pad_setting_changed',
    key,
    value,
    ...extraProperties,
  }
}

export function padTakeHomeToggled(isTakeHome) {
  return {
    type: 'pad_take_home_toggled',
    value: isTakeHome,
  }
}
