import { cloneDeep } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useMonacoContext } from '../../MonacoContext'
import { buildDirectory as _buildDirectory } from '../utils/buildDirectory'
import { MonacoFile } from '../utils/types'

export enum DirectoryItemType {
  file = 'file',
  folder = 'folder',
  fileInput = 'fileInput',
  folderInput = 'folderInput',
}
export type DirectoryItem = {
  name: string
  path: string
  type: DirectoryItemType
  fileEntry?: boolean
  file?: MonacoFile
  children: DirectoryItem[]
}

/**
 * Takes the files for a project that are persisted in Firebase and converts it into a
 * renderable directory structure.
 * recursively sorts directory items in a custom order (folders first, case insensitive)
 * includes "empty folders", which are not persisted to Firebase, but rather stored in
 * local state
 * weaves in dummy items to represent input fields, for adding/renaming files and folders
 */
export function useDirectory(fileAddPath: string | null, folderAddPath: string | null) {
  const { files } = useMonacoContext()
  const { emptyFolderPaths } = useActiveEnvironment()

  const buildDirectory = useCallback(() => {
    return _buildDirectory(files, emptyFolderPaths)
  }, [files, emptyFolderPaths])

  /**
   * "weaves" in a dummy DirectoryItem to the file structure at the expected location,
   * to be used during the render step to handle adding/renaming a file, or adding a folder
   */
  const insertInputEntries = useCallback(
    (sortedFiles: DirectoryItem[]) => {
      const directory = cloneDeep(sortedFiles)
      if (fileAddPath != null || folderAddPath != null) {
        let targetFolder = directory
        const targetPath =
          fileAddPath != null ? fileAddPath : folderAddPath != null ? folderAddPath : ''
        targetPath.split('/').forEach((folderName) => {
          if (folderName === '') {
            return
          }
          const foundTargetFolder = targetFolder.find((item) => item.name === folderName)
          const childFolder = foundTargetFolder != null ? foundTargetFolder.children : null
          targetFolder = childFolder == null ? targetFolder : childFolder
        })

        let dummyDirectoryItem
        if (fileAddPath != null) {
          dummyDirectoryItem = { type: DirectoryItemType.fileInput, children: [] } as any
        } else {
          dummyDirectoryItem = { type: DirectoryItemType.folderInput, children: [] } as any
        }
        const indexOfFirstFile = targetFolder.findIndex((item) => item.children.length === 0)
        targetFolder.splice(indexOfFirstFile, 0, dummyDirectoryItem)
      }

      return directory
    },
    [fileAddPath, folderAddPath]
  )

  return useMemo(() => {
    const directory = buildDirectory()
    return insertInputEntries(directory)
  }, [buildDirectory, insertInputEntries])
}
