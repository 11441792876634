import { Frame } from 'packs/main/playback/types'
import { ConversationMessage } from 'packs/main/RightPane/Tabs/AiTab/Types'

export const getAiChatFrames = (chatHistory: ConversationMessage[]): Frame[] => {
  return chatHistory.map((m) => {
    return {
      type: 'ai-chat',
      value: m.content,
      authorId: m.authorId,
      timestamp: m.messageTimestamp,
      delay: 900,
      peripheralFrames: [] as Frame[],
    }
  })
}
