import _ from 'lodash'

import { FileFrames, FlatFrames, Frame } from '../../playback/types'

export const flattenPlaybackFrames = (
  fileFrames: FileFrames,
  aiChatFrames: Frame[],
  transcriptFrames: Frame[]
): FlatFrames => {
  let result = []
  for (const id of Object.keys(fileFrames)) {
    result.push(...fileFrames[id].map((frame) => ({ ...frame, peripheralFrames: [] as Frame[] })))
  }
  result.push(...aiChatFrames)
  result.push(...transcriptFrames)
  result = _.sortBy(result, 'timestamp')
  // take frames with duplicate timestamps, and add them to the `peripheralFrames` of the previous frame
  for (let i = 1; i < result.length; i++) {
    if (
      result[i].timestamp === result[i - 1].timestamp &&
      result[i].environmentSlug === result[i - 1].environmentSlug
    ) {
      result[i - 1].peripheralFrames.push(result[i])
      result.splice(i, 1)
      i--
    }
  }
  return result
}
