import { AttachFile, StorageRounded } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionSummary,
  Box,
  Chip,
  Tab as MUITab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { WhiteboardPreviewMode } from 'packs/dashboard/components/Whiteboard/WhiteboardPreviewMode'
import { ActiveQuestionVariantProvider } from 'packs/dashboard/Questions/Detail/ExampleBody/ActiveQuestionVariantContext'
import { StarterCodeForQuestionVariants } from 'packs/dashboard/Questions/Detail/QuestionBody/StarterCodeForQuestionVariants'
import { InstructionDetails } from 'packs/main/RightPane/Tabs/CandidateInstructions/InstructionDetails'
import { InstructionStepAccordion } from 'packs/main/RightPane/Tabs/CandidateInstructions/InstructionStepAccordion'
import { useCandidateInstructions } from 'packs/main/RightPane/Tabs/CandidateInstructions/useCandidateInstructions'
import { GSheetsIframe } from 'packs/main/Workspace/CodeSpace/GSheetsIframe'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { CustomFile, Question, TestCase } from '../../../../../../graphql/types'
import { ProjectFiles } from '../../../../../dashboard/Questions/Detail/QuestionBody/ProjectFiles'
import QuestionSelectedTestCases from '../../../../question_selected_testcases'
import { ScrollView } from '../../../../ScrollView/ScrollView'
import { highlight, MarkdownDisplay } from './util'

const useStyles = makeStyles({
  tabPanel: {
    height: '94%',
    '& .CodeMirror': {
      height: 'unset',
    },
  },
})

const Tab = withStyles(() => ({
  root: {
    minWidth: 72,
  },
}))(MUITab)

export const DetailBody: React.FC<{
  question: Question
}> = ({ question }) => {
  const theme = useTheme()
  const styles = useStyles()
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const { darkColorScheme } = useSelector((state) => state.editorSettings)
  const { expanded, handleExpand } = useCandidateInstructions(question.candidateInstructions ?? [])

  useEffect(() => {
    setActiveTabIdx(0)
  }, [question?.id])

  if (!question) {
    return null
  }

  const tabs = []
  const tabPanels = []
  if (
    question.candidateInstructions?.length &&
    question.candidateInstructions[0]['instructions'] !== ''
  ) {
    tabs.push('Instructions')
    tabPanels.push(
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {question.candidateInstructions.map((step, index) => {
          return (
            <InstructionStepAccordion
              key={index}
              expanded={expanded[index] || false}
              handleExpand={() => handleExpand(index)}
            >
              {question.candidateInstructions!.length === 1 ? (
                <Box mt={3} />
              ) : (
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1">
                    Instructions Part {index + 1}{' '}
                    {index > 0 && (
                      <>
                        {step.defaultVisible ? '(visible to ' : '(hidden from '}candidates by
                        default)
                      </>
                    )}
                  </Typography>
                </AccordionSummary>
              )}
              <InstructionDetails instructions={step.instructions || ''} hidden={false} />
            </InstructionStepAccordion>
          )
        })}
      </Box>
    )
  }
  if (question.questionVariants?.length > 0) {
    tabs.push('Starter Code')
    tabPanels.push(
      <ActiveQuestionVariantProvider>
        <StarterCodeForQuestionVariants question={question} />
      </ActiveQuestionVariantProvider>
    )
  } else if (question.fileContents?.length > 0) {
    tabs.push('Starter Code')
    tabPanels.push(<ProjectFiles question={question} fileUriPrefix="pad_question_bank" />)
  } else if (question.contents) {
    tabs.push('Starter Code')
    tabPanels.push(
      <pre
        className={`${
          darkColorScheme ? 'cm-s-monokai' : 'cm-s-one-light'
        } QuestionSelected-contents contents`}
        dangerouslySetInnerHTML={{
          __html: highlight(question.contents || '', question.language ?? 'plaintext'),
        }}
      />
    )
  } else if (question.spreadsheet) {
    tabs.push('Starter Code')
    tabPanels.push(<GSheetsIframe spreadsheetId={question.spreadsheet.gsheetId} preview={true} />)
  }

  if (question.solution) {
    tabs.push('Solution')
    tabPanels.push(
      <>
        <MarkdownDisplay
          darkColorScheme={darkColorScheme}
          value={question.solution}
          readOnly={true}
        />
        {question.questionVariants
          ?.filter((qv) => qv.solution != null && qv.solution !== '')
          .map((qv) => (
            <>
              <Typography variant="h4">Notes for {qv.display} solutions:</Typography>
              <MarkdownDisplay
                key={qv.id}
                value={qv.solution}
                darkColorScheme={darkColorScheme}
                readOnly={true}
              />
            </>
          ))}
      </>
    )
  }
  if (question.testCasesEnabled) {
    tabs.push('Test Cases')
    tabPanels.push(
      <QuestionSelectedTestCases
        testCases={(question.testCases || []).map((tc: TestCase) => ({
          ...tc,
          return_value: tc.returnValue,
        }))}
      />
    )
  }
  if (question.customFiles?.length || question?.customDatabase) {
    tabs.push('Resources')
    tabPanels.push(
      <>
        {question.customDatabase && (
          <Box mb={2}>
            <Chip
              label={question.customDatabase.language}
              icon={<StorageRounded />}
              size="small"
              color="primary"
            />
            <Box display="inline-flex" flexDirection="column" ml={1}>
              <Typography variant="subtitle1">
                <a
                  href={`/dashboard/databases/${question.customDatabase.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {question.customDatabase.title || 'Database'}
                </a>
              </Typography>
              {question.customDatabase.description && (
                <Typography variant="caption">{question.customDatabase.description}</Typography>
              )}
            </Box>
          </Box>
        )}
        {question.customFiles?.map((file: CustomFile) => (
          <Box key={file.id} mb={1}>
            <Chip label={file.filename} icon={<AttachFile />} size="small" color="default" />
            <Box display="inline-flex" flexDirection="column" ml={1}>
              <Typography variant="subtitle1">
                <a href={`/dashboard/files/${file.id}`} target="_blank" rel="noreferrer">
                  {file.title}
                </a>
              </Typography>
              <Typography variant="caption">{`${file.description} (${file.filesize})`}</Typography>
            </Box>
          </Box>
        ))}
      </>
    )
  }

  if (question.drawing) {
    tabs.push('Drawing')
    tabPanels.push(
      <WhiteboardPreviewMode
        drawingBoardId={question.drawing.boardId}
        darkColorScheme={darkColorScheme}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box borderBottom={`2px solid ${theme.palette.background.default}`}>
        <Tabs
          onChange={(e, idx) => setActiveTabIdx(idx)}
          value={activeTabIdx}
          data-testid="questionselector-questiondetail"
        >
          {tabs.map((tabLab, idx) => (
            <Tab label={tabLab} key={`tab-${idx}`} />
          ))}
        </Tabs>
      </Box>
      <ScrollView overflow="auto" flexGrow={1}>
        <Box p={2} className={styles.tabPanel}>
          {tabPanels[activeTabIdx]}
        </Box>
      </ScrollView>
    </Box>
  )
}
