import { useCallback, useState } from 'react'
import { useFetch } from 'utils/fetch/useFetch'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { EnvironmentSummary } from '../../../Environments/EnvironmentsContext/types'

export function useChangeEnvironmentLanguage(environment: EnvironmentSummary) {
  const fetch = useFetch()
  const { environment: activeEnvironment, activateFile } = useActiveEnvironment()
  const slug = usePadConfigValue('slug')
  const [pending, setPending] = useState(false)
  return useCallback(
    async (language: string) => {
      if (pending) {
        return
      }
      setPending(true)
      const resp = await fetch(`/${slug}/change_environment_language`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ environment_slug: environment.slug, language }),
      })
      setPending(false)

      const { new_file_id } = await resp.json()

      if (new_file_id && activeEnvironment?.slug === environment.slug) {
        activateFile(new_file_id)
      }

      return new_file_id
    },
    [pending, fetch, slug, environment.slug, activeEnvironment?.slug, activateFile]
  )
}
