import { useCallback, useState } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'
import { useFetch } from '../../../../../utils/fetch/useFetch'
import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { EnvironmentTypes } from '../types'

export function useCreateEnvironment() {
  const fetch = useFetch()
  const padSlug = usePadConfigValue('slug')

  const [envCreateStatus, setEnvCreateStatus] = useState(queryStates.initial())

  const createEnvironment = useCallback(
    (
      type: EnvironmentTypes,
      id: string | number,
      envOpts: Record<string, any> | null = null
    ): void => {
      if (queryStates.isLoadingState(envCreateStatus)) {
        return
      }

      setEnvCreateStatus(queryStates.loading())

      const fetchPath =
        type === EnvironmentTypes.Language
          ? 'add_language'
          : type === EnvironmentTypes.Question
          ? 'add_question'
          : type === EnvironmentTypes.Project
          ? 'add_project_template' //TODO: allow user to select a version
          : type === EnvironmentTypes.Spreadsheet
          ? 'add_spreadsheet'
          : ''
      const envOptions = new FormData()
      const requestOptions = { method: 'POST' }
      if (envOpts != null) {
        for (const opt in envOpts) {
          envOptions.append(opt, envOpts[opt])
        }
        requestOptions['body'] = envOptions
      }
      fetch(`/${padSlug}/${fetchPath}/${id}`, requestOptions)
        .then((resp) => {
          if (resp.ok) {
            // No need to update the envs or active environment states. The updates in Firebase from the backend will
            // trigger the updates via the Firebase listeners in this Provider.
            setEnvCreateStatus(queryStates.success())
          } else {
            setEnvCreateStatus(queryStates.error())
          }
        })
        .catch((e) => {
          setEnvCreateStatus(queryStates.error('Failed to create environment', e))
        })
    },
    [envCreateStatus, setEnvCreateStatus, fetch, padSlug]
  )

  const clearEnvCreateStatus = useCallback(() => {
    setEnvCreateStatus(queryStates.initial())
  }, [setEnvCreateStatus])

  return {
    createEnvironment,
    envCreateStatus,
    clearEnvCreateStatus,
  }
}
