import _ from 'lodash'
import { connect } from 'react-redux'

import padConfig from './pad_config'
import QuestionLibrary from './question_library'
import { MULTIFILE_ACTIONS } from './reducers/multifile'

function mapStateToProps(state) {
  return {
    language: state.padSettings.language,
    allowPadCreation: false,
    takeHome:
      state.padSettings.takeHome ||
      (padConfig.isSandbox && padConfig.question && padConfig.question.takeHome),
    initialFavorites: [],
  }
}

const ON_SET_QUESTION_PROPS = [
  'append',
  'contents',
  'candidateInstructions',
  'customDatabaseId',
  'customDatabaseLanguage',
  'customDatabaseSchema',
  'customFiles',
  'fileContents',
  'language',
  'padLanguage',
  'questionId',
  'testCasesEnabled',
  'testCases',
  'starterCodeByLanguage',
  'solution',
]

const mapDispatchToProps = {
  onSetQuestion: (args) => {
    const baseAction = {
      ..._.pick(args, ON_SET_QUESTION_PROPS),
      _analytics: {
        name: 'Question Added',
        params: {
          append: args['append'],
          question_id: args['questionId'],
        },
      },
    }
    const multifileConfirmationRequired =
      args['contents'] &&
      args['contents'].length > 0 &&
      args['fileContents'] &&
      args['fileContents'].length > 0
    return {
      ...baseAction,
      type: multifileConfirmationRequired
        ? MULTIFILE_ACTIONS.confirmationRequested
        : 'question_selected_by_local_user',
    }
  },
}

const InPadQuestionLibrary = connect(mapStateToProps, mapDispatchToProps)(QuestionLibrary)

export default InPadQuestionLibrary
