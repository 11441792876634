import $ from 'jquery'
import { debounce } from 'lodash'
import React, { useEffect } from 'react'

import padConfig from './pad_config'

const TOOLTIP_SELECTOR =
  '.LanguageSelector-infoButton, .LanguageSelector-dropdownButton, .settings-button, .CallButton-button, .TakeHomePanel-submitButton, .TakeHomePanel-timer, .FocusTime-Button'
const Z_INDEX_RESET_SELECTOR = '.Workspace, .PadFooter, .CodePane-header'

interface IWelcomeCandidateProps {
  darkBackground: boolean
  onHide: () => void
  hasEnvironments: boolean
}

export const WelcomeCandidate: React.FC<IWelcomeCandidateProps> = ({
  darkBackground,
  onHide,
  hasEnvironments,
}) => {
  const backgroundColor = darkBackground ? '#1d2126' : '#fff'
  const takeHome = padConfig.takeHome
  const takeHomeDisabled = padConfig.takeHomeTimeLimit > 0 && padConfig.timedTakeHomesDisabled

  useEffect(() => {
    const tooltipVisibility = takeHome && takeHomeDisabled ? 'hide' : 'show'

    const repositionToolTips = debounce(() => {
      $(TOOLTIP_SELECTOR).tooltip(tooltipVisibility)
    }, 300)

    const showTooltipsTimeout = setTimeout(() => {
      // We have to "undo" whatever z-indicies were set for these elements, which allows us to
      // essentially create a sandwich of layers: header/foter -> modal veil -> buttons
      $(Z_INDEX_RESET_SELECTOR).css('z-index', 'auto')

      $(TOOLTIP_SELECTOR)
        .css({
          backgroundColor,
          pointerEvents: 'none',
          position: 'relative',
          transition: 'background-color 200ms',
          zIndex: '1000',
        })
        .tooltip(tooltipVisibility)

      $('.tooltip-inner').each((idx, tooltip) => {
        $(tooltip).attr('tabIndex', idx + 1)
      })
    }, 300)

    window.addEventListener('resize', repositionToolTips)

    return () => {
      clearTimeout(showTooltipsTimeout)
      $(Z_INDEX_RESET_SELECTOR).css('z-index', '')

      window.removeEventListener('resize', repositionToolTips)

      $(TOOLTIP_SELECTOR)
        .tooltip('hide')
        .css({ backgroundColor: '', pointerEvents: 'all', zIndex: '' })
    }
  }, [])

  return (
    <div id="welcome-modal" className="full-overlay">
      <div className="welcome-text maincol">
        {takeHome ? (
          takeHomeDisabled ? (
            <TakeHomesDisabledForMaintenanceGreeting />
          ) : (
            <TakeHomeGreeting hasEnvironments={hasEnvironments} />
          )
        ) : (
          <LiveInterviewGreeting hasEnvironments={hasEnvironments} />
        )}
        {(!takeHome || !takeHomeDisabled) && (
          <div className="button-wrapper">
            <button className="btn btn-primary" onClick={onHide}>
              Let&rsquo;s Get Started!
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

type GreetingProps = {
  hasEnvironments: boolean
}
function LiveInterviewGreeting({ hasEnvironments }: GreetingProps) {
  return padConfig.uiType === 'drawing_only' ? (
    <>
      <h3>Welcome to your CoderPad interview!</h3>
      <ul>
        <li>
          At the top left, use the drawing toolbar to select drawing tools, pan, and zoom in the
          drawing board.
        </li>
        <li>At the bottom right, click the “Settings” button to change the pad color scheme.</li>
        <li>
          If you want to speak with your interviewer and don’t already have a connection, use the
          “Start Call” button in the bottom left.
        </li>
      </ul>
    </>
  ) : (
    <>
      <h3>Welcome to your CoderPad interview!</h3>
      <ul>
        {hasEnvironments ? (
          <>
            <li>Click "Languages" in the left sidebar to add a new language tab.</li>
            <li>
              On any language tab in the left sidebar, click the dots menu to see info and libraries
              for that language.
            </li>
          </>
        ) : (
          <li>
            At the top of the editor panel, click the “Info” button to see what libraries and
            testing frameworks are available for your coding language.
          </li>
        )}

        <li>
          At the bottom right, click the “Settings” button to turn on Vim mode, enable auto-closing
          brackets, and more.
        </li>
        <li>
          If you want to speak with your interviewer and don’t already have a connection, use the
          “Start Call” button in the bottom left.
        </li>
      </ul>
    </>
  )
}

function TakeHomesDisabledForMaintenanceGreeting() {
  const extraMessagDetail = padConfig.timedTakeHomesMaintenanceExtraMessage

  return (
    <>
      <h3>Maintenance</h3>
      <ul>
        <li>CoderPad.io will be undergoing scheduled maintenance soon</li>
        <li>Shortly before maintenance begins, timed take-home questions cannot be started</li>
        {extraMessagDetail && <li>{extraMessagDetail}</li>}
        <li>
          See <a href="https://status.coderpad.io/">status.coderpad.io</a> for more information
        </li>
      </ul>
    </>
  )
}

function TakeHomeGreeting({ hasEnvironments }: GreetingProps) {
  return (
    <>
      <h3>Welcome to your CoderPad Take-Home!</h3>
      <h4>Need-to-knows</h4>
      <ul>
        <li>Your solution MUST be entered in the left editor panel to be saved.</li>
        <li>
          If you run out of time, the session will end automatically, but don’t worry! Your work
          will be saved.
        </li>
      </ul>
      <h4>Tips for success</h4>
      <ul>
        {hasEnvironments ? (
          <li>
            On the language tab in the left sidebar, click the dots menu to switch languages, and
            see which libraries and testing frameworks are available for that language.
          </li>
        ) : (
          <li>
            At the top of the editor panel, click the “Info” button to see what libraries and
            testing frameworks are available for your coding language.
          </li>
        )}

        <li>
          At the bottom right, click the “Settings” button to turn on Vim mode, enable auto-closing
          brackets, and more.
        </li>
      </ul>
    </>
  )
}
