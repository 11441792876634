import React from 'react'

import { PadContextProvider } from './PadContext'

/**
 * A convenience for the pad view where padConfig is a global var. This provider will grab that global and use it
 * as the pad config value of the pad context.
 */
export const WindowPadContextProvider: React.FC = ({ children }) => {
  return <PadContextProvider config={window.padConfig!}>{children}</PadContextProvider>
}
