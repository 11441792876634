import _ from 'lodash'
import React, { createContext, FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { useEnvironments } from '../Environments/EnvironmentsContext/EnvironmentsContext'
import { useGlobalEvents } from '../playback/GlobalEvents/useGlobalEvents'
import { usePlayback } from '../playback/PlaybackContext'
import { FirepadEdits, FlatFrames, RawFrames } from '../playback/types'
import { ProjectTab, setSelectedTab as setSelectedProjectTab } from '../reducers/projectTabs'
import { setSlectedTab } from '../reducers/tabs'
import { useAiChatHistory } from '../RightPane/Tabs/AiTab/useAiChatHistory'
import SyncHandle from '../sync_handle'
import { useTranscripts } from '../Transcriber/hooks/useTranscripts'
import { useEnvironmentsPlaybackMock } from './EnvironmentsPlaybackMock'
import { calculateFrames } from './transformers/calculateFrames'
import { flattenPlaybackFrames } from './transformers/flattenPlaybackFrames'
import { getAiChatFrames } from './transformers/getAiChatFrames'
import { getDerivedFrames } from './transformers/getDerivedFrames'
import { getTranscriptFrames } from './transformers/getTranscriptFrames'

export interface PlaybackFramesContext {
  environmentFrames: FlatFrames
}

const defaultPlaybackContext: PlaybackFramesContext = {
  environmentFrames: [],
}

const PlaybackContext = createContext<PlaybackFramesContext>(defaultPlaybackContext)

/**
 * Convenience hook for accessing the playback context.
 */
export function usePlaybackFrames() {
  const context = useContext(PlaybackContext)

  if (!context) {
    throw new Error('`usePlaybackFrames` hook must be a descendant of a `PlaybackFramesProvider`')
  }

  return context
}

export const PlaybackFramesProvider: FC = ({ children }) => {
  const dispatch = useDispatch()
  const { paused } = usePlayback()
  const { environments } = useEnvironments()
  const [rawFrames, setRawFrames] = useState({})
  const { hasEnvironments, padEndedAt } = usePadConfigValues('hasEnvironments', 'padEndedAt')

  /**
   * Fetches the playback frames for either the legacy or environments playback.
   *
   * For legacy playback, we have to fetch both the single file history and the
   * synthetic 3-file history and merge them together.
   *
   * For environments playback, we have to fetch the environment files history
   * and merge them together.
   */
  useEffect(() => {
    const handleLegacyFiles = (history: FirepadEdits) => {
      const fileId = ''
      const calculatedFrames = calculateFrames(history, fileId, padEndedAt)
      const fileFrames = getDerivedFrames({ [fileId]: calculatedFrames } as RawFrames)[fileId]

      setRawFrames((frames) => ({
        ...frames,
        [fileId]: fileFrames,
      }))

      SyncHandle().get(`files`, (files: { [fileId: string]: { history: FirepadEdits } }) => {
        if (files) {
          const rawFileFrames: RawFrames = {}
          Object.entries(files).forEach(([fileId, file]) => {
            const calculatedFrames = calculateFrames(file.history, fileId, padEndedAt)
            rawFileFrames[fileId] = calculatedFrames
          })
          setRawFrames((frames) => ({
            ...frames,
            ...getDerivedFrames(rawFileFrames),
          }))
        }
      })
    }

    const handleEnvironmentFiles = (environmentFiles: {
      [slug: string]: {
        [fileId: string]: {
          history: FirepadEdits
        }
      }
    }) => {
      const rawFileFrames: RawFrames = {}
      Object.entries(environmentFiles ?? {}).forEach(([environmentSlug, files]) => {
        Object.entries(files).forEach(([fileId, file]) => {
          const calculatedFrames = calculateFrames(file.history, fileId, padEndedAt).map((f) => ({
            ...f,
            environmentSlug,
          }))
          if (rawFileFrames[fileId]) {
            // merge the new frames with existing frames, sorted by timestamp
            rawFileFrames[fileId] = _.sortBy(
              [...rawFileFrames[fileId], ...calculatedFrames],
              'timestamp'
            )
          } else {
            rawFileFrames[fileId] = calculatedFrames
          }
        })
      })

      setRawFrames((frames) => ({
        ...frames,
        ...getDerivedFrames(rawFileFrames),
      }))
    }

    if (hasEnvironments) {
      SyncHandle().get(`environmentFiles`, handleEnvironmentFiles)
    } else {
      SyncHandle().get(`history`, handleLegacyFiles)
    }
  }, [hasEnvironments, environments, padEndedAt])

  const chatHistory = useAiChatHistory()
  const transcripts = useTranscripts()
  const chatFrames = useMemo(() => getAiChatFrames(chatHistory), [chatHistory])
  const transcriptFrames = useMemo(() => getTranscriptFrames(transcripts), [transcripts])
  const environmentFrames = useMemo(
    () => flattenPlaybackFrames(rawFrames, chatFrames, transcriptFrames),
    [chatFrames, rawFrames, transcriptFrames]
  )

  const {
    getVisibleEnvironments,
    lastChangeEnvironmentEvent,
    lastCreatePadEvent,
    lastRequestClientRequestEvent,
  } = useGlobalEvents(environmentFrames)

  const dispatchedFrames = useRef<FlatFrames>([])

  /**
   * This effect is responsible for dispatching the environment frames to redux
   * once new frames are available. We are caching the last-dispatched frames
   * to prevent dispatching the same frames multiple times in the event that
   * `environmentFrames` becomes an unstable reference.
   */
  useEffect(() => {
    if (Object.keys(environmentFrames).length !== Object.keys(dispatchedFrames.current).length) {
      dispatch({
        type: 'playback_set_frames',
        frames: environmentFrames,
      })
      dispatchedFrames.current = environmentFrames
    }
  }, [dispatch, environmentFrames])

  const {
    setVisibleEnvironments,
    activeEnvironmentId,
    setActiveEnvironmentId,
  } = useEnvironmentsPlaybackMock()

  useEffect(() => {
    setVisibleEnvironments((prev) => getVisibleEnvironments())
  }, [setVisibleEnvironments, getVisibleEnvironments])

  const activeEnvironment = useMemo(() => environments.find((e) => e.id === activeEnvironmentId), [
    activeEnvironmentId,
    environments,
  ])

  useEffect(() => {
    if (lastRequestClientRequestEvent) {
      dispatch({
        type: 'project/request_sent',
        requestId: lastRequestClientRequestEvent.data.requestId,
      })
      dispatch(setSlectedTab('requestClient'))
      dispatch(setSelectedProjectTab(ProjectTab.RequestHistory))
    }
  }, [dispatch, lastRequestClientRequestEvent])

  /**
   * Effect that is responsiblef or activating environments as new global events
   * are received.
   */
  useEffect(() => {
    if (!environments[0] || !activeEnvironment || paused) return
    if (lastChangeEnvironmentEvent) {
      if (activeEnvironment.id !== lastChangeEnvironmentEvent.data.environment) {
        setActiveEnvironmentId(lastChangeEnvironmentEvent.data.environment)
      }
    } else {
      if (lastCreatePadEvent && lastCreatePadEvent.data.activeEnvironment) {
        const environmentId = environments.find(
          (e) => e.slug === lastCreatePadEvent.data.activeEnvironment
        )?.id
        if (environmentId) {
          setActiveEnvironmentId(environmentId)
        }
      } else if (activeEnvironment.id !== environments[0]?.id) {
        setActiveEnvironmentId(environments[0].id)
      }
    }
  }, [
    environments,
    activeEnvironment,
    setActiveEnvironmentId,
    lastChangeEnvironmentEvent,
    lastCreatePadEvent,
    paused,
  ])

  return (
    <PlaybackContext.Provider
      value={{
        ...defaultPlaybackContext,
        environmentFrames,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  )
}
