import { styled, Typography } from '@mui/material'

export const QuestionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14px',
  color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF',
}))

export const QuestionTitleSubtext = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '13px',
  color: theme.palette.mode === 'light' ? '#363739' : '#EAEAEA',
}))
