import { styled } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ITerminalOptions, Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'

import { ScrollView } from '../ScrollView/ScrollView'
import terminal_theme from '../terminal_theme'

// Should match the constant size provided by Execute to the container TTY
const TERM_FIXED_COLS = 90

const Container = styled(ScrollView)({
  width: '100%',
  height: '100%',
  marginTop: 4,
})

export interface ProjectTermProps {
  id: string
  xtermOptions?: ITerminalOptions
}

export const ProjectTerm: React.FC<ProjectTermProps> = ({ id, xtermOptions }) => {
  const dispatch = useDispatch()
  const termContainerRef = useRef<HTMLDivElement>(null)
  const termRef = useRef<Terminal>()
  const fontSize = useSelector((state) => state.editorSettings.fontSize)
  const darkColorScheme = useSelector((state) => state.editorSettings.darkColorScheme)

  useEffect(() => {
    const term = new Terminal({
      cursorBlink: true,
      fontFamily:
        "'Roboto Mono', Menlo, 'Ubuntu Mono', Monaco, Consolas, 'source-code-pro', monospace",
      lineHeight: 1,
      tabStopWidth: 4,
      rendererType: 'dom',
      screenReaderMode: true,
      ...xtermOptions,
    })
    const fitAddon = new FitAddon()
    term.loadAddon(fitAddon)
    termRef.current = term

    dispatch({
      type: 'term_created',
      term,
      id,
    })
    dispatch({
      type: 'project/catchupTerm',
      id,
    })
    const observer = new ResizeObserver(([{ contentRect }]) => {
      if (contentRect.width > 0 && contentRect.height > 0) {
        if (term.element == null) {
          term.open(termContainerRef.current!)
        }
        const dimensions = fitAddon.proposeDimensions()
        if (dimensions != null) {
          const { rows, cols } = dimensions
          term.resize(Math.max(cols, TERM_FIXED_COLS), rows)
        }
      }
    })
    observer.observe(termContainerRef.current!)

    return () => {
      termRef.current = undefined
      observer.disconnect()
      term.dispose()
      dispatch({ type: 'term_destroyed', id })
    }
  }, [dispatch, id, xtermOptions])

  useEffect(() => {
    termRef.current?.setOption('fontSize', fontSize)
  }, [fontSize])

  useEffect(() => {
    termRef.current?.setOption('theme', terminal_theme[darkColorScheme ? 'dark' : 'light'])
  }, [darkColorScheme])

  return <Container ref={termContainerRef} />
}
