import { keyframes } from '@mui/material'
import _ from 'lodash'
import React from 'react'

import padConfig from './pad_config'
import { UserInfo } from './reducers/user_state'

export const EMAIL_REGEX = /.+@.+\..+/

export const emptyCustomValidation = (value: string) => !!value.trim()

export const extractEmailDomain = (email: string): string | null => {
  const match = /@([A-Z0-9.-]+\.[A-Z]{2,})$/i.exec(email)
  if (match == null) {
    return null
  }
  return match[1]
}

export const getIdpUrlForDomain = (domain: string): string | null => {
  if (window.CoderPad.sso_domains == null) return null
  return domain && window.CoderPad.sso_domains[domain]
}

export const getUserColor = (users: UserInfo, userId: string) => {
  if (padConfig.color) {
    return `#${padConfig.color}`
  }

  // If the user has a color, keep it the same
  const currentColor = users[userId]?.color
  if (currentColor && currentColor !== 'transparent') {
    return currentColor
  }

  return _.first(
    _.sortBy(
      window.CoderPad.COLORS,
      (color) => _.filter(users, (user) => user.color === color).length
    )
  )
}

export const shortcutPrefix = /^mac/i.test(navigator.platform) ? (
  <span className="fui-cmd"></span>
) : (
  'ctrl'
)

export const spin = keyframes`
0% {
transform: rotate(-360deg);
}
`
