import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Modal from './modal'
import { dismissMultifileConfirmation } from './reducers/multifile'

class _MultifileModal extends React.Component {
  static propTypes = {
    analyticsParams: PropTypes.object,
    selectQuestion: PropTypes.func.isRequired,
  }

  onBackgroundClickHandler = () => {
    this.props.onBackgroundClick({
      name: 'Multifile Modal Dismissed',
      params: this.props.analyticsParams,
    })
  }

  onClickMultifileHandler = () => {
    this.props.formatAndSelectQuestion({
      ...this.props.question,
      _analytics: {
        name: 'Multifile Modal Picked Multifile',
        params: this.props.analyticsParams,
      },
      contents: null,
    })
  }

  onClickLegacyHandler = () => {
    this.props.selectQuestion({
      ...this.props.question,
      _analytics: {
        name: 'Multifile Modal Picked Legacy',
        params: this.props.analyticsParams,
      },
      fileContents: null,
      legacySingleFile: true,
    })
  }

  render() {
    return (
      <Modal className="MultifileModal" onBackgroundClick={this.onBackgroundClickHandler}>
        <h2 className="MultifileModal-header">Select code layout</h2>
        <div className="MultifileModal-buttons">
          <div className="MultifileModal-marginUnit" />
          <div className="MultifileModal-buttonsUnit">
            <div
              className="MultifileModal-button"
              onClick={this.onClickMultifileHandler}
              type="primary"
            >
              <h3 className="MultifileModal-colHeader">
                <span>Frontend Multifile *</span>
                <span className="MultifileModal-colHeaderHint">(new and recommended)</span>
              </h3>
              <div className="MultifileModal-workspace">
                <div className="MultifileModal-workspaceMultiEditor">
                  <div className="MultifileModal-workspaceMultiEditorDirectory">
                    <div className="MultifileModal-workspaceEditorHeader">Files</div>
                    <div className="MultifileModal-workspaceEditorDirectoryItem">HTML</div>
                    <div className="MultifileModal-workspaceEditorDirectoryItem">JavaScript</div>
                    <div className="MultifileModal-workspaceEditorDirectoryItem">CSS</div>
                  </div>
                </div>
                <div className="MultifileModal-workspaceCodeExecution">Rendered Output</div>
              </div>
            </div>
          </div>
          <div className="MultifileModal-marginUnit" />
          <div className="MultifileModal-buttonsUnit">
            <div
              className="MultifileModal-button"
              onClick={this.onClickLegacyHandler}
              type="regular"
            >
              <h3 className="MultifileModal-colHeader">CoderPad Classic</h3>
              <div className="MultifileModal-workspace">
                <div className="MultifileModal-workspaceMultiEditor">
                  <div className="MultifileModal-workspaceEditor">
                    <div className="MultifileModal-legacyEditorHeader">All-In-One Code</div>
                    <div className="MultifileModal-legacyEditorInfo">
                      (CSS and JavaScript inline in a single HTML file)
                    </div>
                  </div>
                </div>
                <div className="MultifileModal-workspaceCodeExecution">Rendered Output</div>
              </div>
            </div>
          </div>
          <div className="MultifileModal-marginUnit" />
        </div>
        <div className="MultifileModal-info">
          <div className="MultifileModal-marginUnit" />
          <div className="MultifileModal-infoUnit">
            * This question's code will be automatically parsed into separate HTML, CSS, and
            JavaScript files. If the parsed results aren't what you expect, reload the question in
            the CoderPad Classic layout.
          </div>
          <div className="MultifileModal-infoUnit" />
          <div className="MultifileModal-marginUnit" />
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { lastLoadedQuestionId, question, modalVisible } = state.multifile
  const { slug } = window.padConfig

  return {
    analyticsParams: {
      ...question?._analytics?.params,
      lastLoadedQuestionId,
      slug,
    },
    question,
    visible: modalVisible,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onBackgroundClick(analyticsData) {
    dispatch(dismissMultifileConfirmation(analyticsData))
  },

  selectQuestion(question) {
    dispatch({
      type: 'question_selected_by_local_user',
      ...question,
    })
    dispatch(dismissMultifileConfirmation())
  },

  formatAndSelectQuestion(question) {
    dispatch({
      type: 'question_format_and_select_requested',
      question,
    })
    dispatch(dismissMultifileConfirmation())
  },
})

const MultifileModal = connect(mapStateToProps, mapDispatchToProps)(_MultifileModal)

export default MultifileModal
