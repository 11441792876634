import { monaco } from '@codingame/monaco-editor-wrapper'
import { useEnvironments } from 'packs/main/Environments/EnvironmentsContext/EnvironmentsContext'
import { useCallback, useEffect, useRef } from 'react'

import { useFetch } from '../../../../utils/fetch/useFetch'
import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'

export function useSyncFirebase(editor: monaco.editor.IStandaloneCodeEditor | null) {
  const fetch = useFetch()
  const slug = usePadConfigValue('slug')
  const { activeEnvironment } = useEnvironments()
  const isJupyter = activeEnvironment?.projectTemplateSlug?.startsWith('jupyter')
  const isSpreadsheet = activeEnvironment?.spreadsheet != null

  const editorContents = useRef('')
  const maybeSavePadInRails = useCallback(() => {
    const tmpContents = editor?.getModel()?.getValue()

    if (isJupyter || isSpreadsheet || (tmpContents && tmpContents !== editorContents.current)) {
      editorContents.current = tmpContents ?? ''
      fetch(`/${slug}/update_firebase`, { method: 'POST' })
    }
  }, [editor, isJupyter, isSpreadsheet, fetch, slug])

  useEffect(() => {
    const interval = setInterval(maybeSavePadInRails, 60000 + Math.floor(Math.random() * 60000))
    return () => {
      clearInterval(interval)
    }
  }, [maybeSavePadInRails, slug])
}
