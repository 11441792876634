import { Box, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageSelect, LanguageSelectProps } from 'packs/dashboard/QuestionFilter'
import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useChangeQuestionVariant } from 'packs/main/EnvironmentSelector/EnvSettingsMenu/actions/useChangeQuestionVariant'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { checkEscapeHatchFlag } from '../../../../../utils/checkFeatureFlag'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import FocusTimeCountdown from '../../../focus_time_countdown'
import { useMonacoContext } from '../../../Monaco/MonacoContext'
import { EscapeHatch } from './EscapeHatch'
import { PrimaryAction } from './PrimaryAction/PrimaryAction'

const useStyles = makeStyles<Theme, { focusTimeEnabled: boolean; isOwner?: boolean }>(() => ({
  header: {
    '& .FocusTime-Button': {
      marginLeft: 0,
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        height: '16px',
        width: '16px',
      },
      '& .FocusTime-Button-text': {
        marginRight: '6px',
      },
      '& .FocusTime-Button-icon': {
        padding: '2px 0',
      },
    },
    backgroundColor: ({ focusTimeEnabled, isOwner }) =>
      focusTimeEnabled && !isOwner ? '#e1f3e0' : 'inherit',
    '& .FocusTime-headerFocusTimeInfo': {
      zIndex: 1,
    },
  },
  langVersion: {
    fontSize: '0.75rem',
    marginLeft: 12,
    color: ({ focusTimeEnabled, isOwner }) =>
      focusTimeEnabled && !isOwner ? '#34495e' : 'inherit',
  },
}))

export const EditorHeader: React.FC = () => {
  const { activeLanguageSettings } = useMonacoContext()
  const { focusTimeEnabled, focusTimeStartedAt } = useSelector((state) => state.padSettings)
  const { isExecPossible } = useEnvironments()
  const { environment, question } = useActiveEnvironment()
  const changeVariant = useChangeQuestionVariant()

  const { isOwner, isSandbox } = usePadConfigValues(
    'focusTimeSupported',
    'takeHome',
    'isOwner',
    'isSandbox'
  )
  const styles = useStyles({ focusTimeEnabled, isOwner })
  const isEscapeHatchAvailable = checkEscapeHatchFlag()

  const handleLanguageChange: LanguageSelectProps['onChange'] = useCallback(
    ({ target: { value } }) => {
      const targetVariant = question!.questionVariants?.find(
        (v) => v.language === value || v.project_template_slug === value
      )
      if (targetVariant != null) {
        changeVariant(targetVariant.id)
      }
    },
    [changeVariant, question]
  )

  const variantWhitelist = useMemo(
    () => question?.questionVariants?.map((v) => v.language ?? v.project_template_slug),
    [question?.questionVariants]
  )

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1.5}
      ml="50px"
      className={styles.header}
    >
      <Box display="flex" alignItems="center" fontSize="1.2rem">
        {/* For scratch pads, only one environment's execution is shown at a time. If the active environment is not showing
        it's execution, also hide the run button. There should be a control elsewhere to enable the run button and
        execution for the active environment. */}
        {isExecPossible && <PrimaryAction />}
        {focusTimeEnabled && !isOwner && (
          <Box display="inline-flex" marginTop="-2px">
            <FocusTimeCountdown startedAt={focusTimeStartedAt} />
          </Box>
        )}
        <div className={styles.langVersion}>
          {activeLanguageSettings?.version ? `Running ${activeLanguageSettings?.version}` : ''}
        </div>
      </Box>
      {isEscapeHatchAvailable && !isSandbox && (!focusTimeEnabled || isOwner) && (
        <Box display="flex" alignItems="center">
          <EscapeHatch />
        </Box>
      )}
      {(question?.questionVariants ?? []).length > 0 &&
        environment?.isQuestionWithVariants &&
        (environment.language != null || environment.projectTemplateSlug != null) && (
          <Box display="flex" alignItems="center">
            <LanguageSelect
              whitelist={variantWhitelist}
              value={environment.language ?? environment.projectTemplateSlug}
              onChange={handleLanguageChange}
              data-testid="question-variant-language-select"
            />
          </Box>
        )}
    </Box>
  )
}
