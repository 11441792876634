import { createFetcher } from 'utils/fetch/fetch'

import padConfig from './pad_config'

export default function logPadEvent(
  kind: string,
  {
    metadata = null,
    username,
    email,
  }: { metadata?: string | null; username: string; email?: string }
) {
  if (padConfig.isSandbox) return

  const csrfParam = document.querySelector('meta[name=csrf-param]')!.getAttribute('content')!
  const csrfToken = document.querySelector('meta[name=csrf-token]')!.getAttribute('content')!
  const fetch = createFetcher(csrfToken)
  const url = `/${padConfig.slug}/log`

  const formData = new FormData()
  formData.append('kind', kind)
  if (metadata) formData.append('metadata', metadata)
  if (username) formData.append('user_name', username)
  if (email) formData.append('user_email', email)

  if (typeof navigator.sendBeacon == 'function') {
    formData.append(csrfParam, csrfToken)
    navigator.sendBeacon(url, formData)
  } else {
    fetch(url, {
      method: 'post',
      body: formData,
    })
  }
}
