import { gql, useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import {
  CreatePadPayload,
  Maybe,
  MutationCreatePadArgs,
  Pad,
  PadCreateAttributes,
} from '../../../types'

export const PAD_CREATE_MUTATION = gql`
  mutation CreatePad($input: CreatePadInput!) {
    createPad(input: $input) {
      pad {
        slug
        team {
          id
          name
        }
      }
      message
      errors {
        path
        message
      }
    }
  }
`

export interface ICreatePadData {
  createPad: CreatePadPayload
}

export function usePadCreate() {
  const [padCreate, { data, error, loading }] = useMutation<ICreatePadData, MutationCreatePadArgs>(
    PAD_CREATE_MUTATION,
    {
      refetchQueries: ['Pads'],
    }
  )

  const handlePadCreate = useCallback<
    (padCreateAttributes?: PadCreateAttributes) => Promise<Maybe<Pad> | undefined>
  >(
    async (padCreateAttributes) => {
      const response = await padCreate({
        variables: { input: { padCreateAttributes } },
        context: { source: 'usePadCreate.ts' },
      }).catch(() => null)
      return response?.data?.createPad?.pad
    },
    [padCreate]
  )

  const handlePadCreateWithError = useCallback<
    (padCreateAttributes?: PadCreateAttributes) => ReturnType<typeof padCreate>
  >(
    (padCreateAttributes) =>
      padCreate({
        variables: { input: { padCreateAttributes } },
        context: { source: 'usePadCreate.ts' },
      }),
    [padCreate]
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(error.message || 'There was an error creating the pad.')
    } else if (data?.createPad?.errors?.length) {
      return queryStates.error(data.createPad.errors[0].message)
    } else if (data?.createPad?.pad != null) {
      return queryStates.success(data?.createPad?.message || 'Your pad has been created.')
    } else {
      return queryStates.initial()
    }
  }, [data, error, loading])

  return useMemo(
    () => ({
      padCreate: handlePadCreate,
      padCreateWithError: handlePadCreateWithError,
      status,
    }),
    [handlePadCreate, handlePadCreateWithError, status]
  )
}
