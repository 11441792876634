// Execution state

import parseErrorStack from '../parse_error_stack'

const DEFAULT_STATE = {
  htmlErrors: [],
  lastExecutionId: null, // Each execution gets a nonce; this is the most recent one.
  markdown: null,
  processedHtml: null,
  processedHtmlTimestamp: 0,
  running: false, // Currently executing something
  connectionStatus: 'CONNECTED',
  lastSentRequestId: null,
}

export default function executionReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'markdown_content_changed':
      return { ...state, markdown: action.content }
    case 'html_processed':
      return {
        ...state,
        processedHtml: action.content,
        processedHtmlTimestamp: action.time,
        htmlErrors: [],
        running: false,
      }
    case 'output_reset':
      return {
        ...state,
        processedHtml: null,
        processedHtmlTimestamp: 0,
        htmlErrors: [],
        running: false,
      }
    case 'pad_setting_changed':
      if (action.key === 'language') {
        // don't hold onto out of date Markdown or HTML content after a language change
        return { ...state, markdown: null, processedHtml: null, htmlErrors: [], running: false }
      }
      break
    case 'html_iframe_errored': {
      const e = action.error
      let parsedStack = null
      try {
        parsedStack = parseErrorStack(e)
      } catch (e) {
        // Ignore errors and resort to not displaying a stack.
        // (We could optionally, in the future, display the raw unparsed stack instead.)
      }
      return {
        ...state,
        htmlErrors: [
          ...state.htmlErrors,
          {
            name: e.name,
            message: e.message,
            parsedStack,
            // An opaque string key for this error, guaranteed to be unique
            // within this browser session.
            key: generateUniqueHtmlErrorKey(),
          },
        ],
      }
    }
    case 'html_errors_cleared':
      return {
        ...state,
        htmlErrors: [],
      }
    case 'execution_started':
      return {
        ...state,
        running: true,
        lastExecutionId: action.id,
      }
    case 'execution_finished':
      if (action.id !== state.lastExecutionId) break
      return {
        ...state,
        running: false,
      }
    case 'execution_stopped':
    case 'execution_platform_errored':
      return {
        ...state,
        running: false,
      }
    case 'execution_connected':
      return {
        ...state,
        connectionStatus: 'CONNECTED',
      }
    case 'execution_connecting':
      return {
        ...state,
        connectionStatus: 'CONNECTING',
      }
    case 'execution_connection_failed':
      return {
        ...state,
        connectionStatus: 'FAILED',
      }

    case 'project/request_sent':
      return {
        ...state,
        lastSentRequestId: action.requestId,
      }
  }

  return state
}

let htmlErrorCount = 0

function generateUniqueHtmlErrorKey() {
  return `e${++htmlErrorCount}`
}
