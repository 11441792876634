import { gql, useMutation } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import {
  MutationUpdateInterviewNotesPrivacySettingForPadArgs,
  PadInterviewNotesPrivacySettingAttributes,
  UpdateInterviewNotesPrivacySettingForPadPayload,
} from '../../../types'

export const PAD_UPDATE_INTERVIEW_NOTES_PRIVACY_MUTATION = gql`
  mutation UpdateInterviewNotesPrivacySettingForPad(
    $input: UpdateInterviewNotesPrivacySettingForPadInput!
  ) {
    updateInterviewNotesPrivacySettingForPad(input: $input) {
      message
      errors {
        path
        message
      }
      pad {
        id
        notesPrivate
      }
    }
  }
`

interface IUpdateInterviewNotesPrivacySettingForPadData {
  updateInterviewNotesPrivacySettingForPad: UpdateInterviewNotesPrivacySettingForPadPayload
}

export function usePadUpdateInterviewNotesPrivacy() {
  const [updateInterviewNotesPrivacySetting, { data, error, loading }] = useMutation<
    IUpdateInterviewNotesPrivacySettingForPadData,
    MutationUpdateInterviewNotesPrivacySettingForPadArgs
  >(PAD_UPDATE_INTERVIEW_NOTES_PRIVACY_MUTATION)

  const statusMessage = data?.updateInterviewNotesPrivacySettingForPad?.message

  let status = queryStates.initial()

  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(statusMessage || 'There was an error updating the pad.')
  } else if (data?.updateInterviewNotesPrivacySettingForPad?.pad) {
    status = queryStates.success(statusMessage || 'Your pad has been updated.')
  }

  return {
    status,
    async updateInterviewNotesPrivacySetting(
      padInterviewNotesPrivacySettingAttributes: PadInterviewNotesPrivacySettingAttributes
    ) {
      const response = await updateInterviewNotesPrivacySetting({
        variables: {
          input: {
            padInterviewNotesPrivacySettingAttributes: padInterviewNotesPrivacySettingAttributes,
          },
        },
        context: { source: 'usePadUpadteInterviewNotesPrivacy.ts' },
      }).catch(() => null)
      return {
        pad: response?.data?.updateInterviewNotesPrivacySettingForPad?.pad,
        message: response?.data?.updateInterviewNotesPrivacySettingForPad?.message,
        errors: response?.data?.updateInterviewNotesPrivacySettingForPad?.errors,
      }
    },
  }
}
