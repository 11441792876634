// This is a Higher Order Component whose purpose is to instantiate a
// CodeMirror instance with basic functionality, then pass the CodeMirror
// instance to the wrapped component which may add additional functionality.

import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export const withMarkdownCodeMirror = (WrappedComponent) => {
  class WithMarkdownCodeMirror extends Component {
    static propTypes = {
      darkColorScheme: PropTypes.bool,
      hide: PropTypes.bool,
      keymap: PropTypes.string,
      readOnly: PropTypes.bool,
    }

    constructor(props) {
      super(props)
      this.codeMirrorWrapperRef = React.createRef()
      this.state = { codeMirror: null }
    }

    componentDidMount() {
      const { darkColorScheme, keymap, readOnly } = this.props
      const textarea = document.createElement('textarea')
      this.codeMirrorWrapperRef.current.appendChild(textarea)
      this.codeMirror = CodeMirror.fromTextArea(textarea, {
        mode: 'gfm',
        theme: darkColorScheme ? 'monokai' : 'one-light',
        keyMap: keymap || 'sublime',
        lineNumbers: false,
        lineWrapping: true,
        styleActiveLine: false,
        autofocus: false,
        readOnly: readOnly || false,
      })

      const debouncedUpdateCodeBlockStyling = _.debounce(this.updateCodeBlockStyling, 50)
      this.codeMirror.on('change', debouncedUpdateCodeBlockStyling)
      this.setState({ codeMirror: this.codeMirror })
    }

    // Display ```code blocks``` in monospace
    updateCodeBlockStyling = () => {
      let inCode = false
      this.codeMirror.eachLine((line) => {
        if (line.text.slice(0, 3) === '```') {
          inCode = !inCode
          this.codeMirror.addLineClass(line, 'wrap', 'cm-code')
        } else {
          this.codeMirror[inCode ? 'addLineClass' : 'removeLineClass'](line, 'wrap', 'cm-code')
        }
        return false
      })
      this.codeMirror.refresh()
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.hide !== prevProps.hide) {
        this.codeMirror && this.codeMirror.refresh()
      }

      if (this.props.darkColorScheme !== prevProps.darkColorScheme)
        this.codeMirror.setOption('theme', this.props.darkColorScheme ? 'monokai' : 'one-light')

      if (this.props.keymap !== prevProps.keymap) {
        this.codeMirror.setOption('keyMap', this.props.keymap)

        // Make sure we're not in overwrite mode
        // (otherwise you can activate it in Vim mode, switch to default mode, and be stuck in it.)
        this.codeMirror.toggleOverwrite(false)
      }
    }

    componentWillUnmount() {
      // This deletes the CodeMirror instance to prevent a memory leak.
      this.codeMirror.toTextArea()
    }

    render() {
      return (
        <WrappedComponent codeMirror={this.state.codeMirror} hide={this.props.hide} {...this.props}>
          <div className="MarkdownCodeMirrorWrapper" ref={this.codeMirrorWrapperRef} />
        </WrappedComponent>
      )
    }
  }

  return WithMarkdownCodeMirror
}

export default withMarkdownCodeMirror
