import { styled } from '@mui/material'
import React, { forwardRef, useMemo } from 'react'

import { usePlaybackParticipants } from '../playback/hooks/usePlaybackParticipants'
import { TranscriptEntry, TranscriptEntryKind } from './types'

const Author = styled('span')<{ color?: string }>(({ theme, color }) => ({
  display: 'inline-block',
  color: color || theme.palette.text.primary,
}))

export const secondsToMinutesSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

interface TranscriptItemProps {
  searchText: string
  firstTimestamp: number
  transcriptEntry: TranscriptEntry
  active: boolean
  onClick?: () => void
}

export const TranscriptItem = forwardRef<HTMLTableRowElement, TranscriptItemProps>(
  ({ firstTimestamp, transcriptEntry, active, onClick, searchText }, ref) => {
    const participants = usePlaybackParticipants()
    const isSystemMessage = transcriptEntry.kind === TranscriptEntryKind.SystemMessage

    const author = isSystemMessage
      ? null
      : Object.values(participants).find((participant) => participant.id === transcriptEntry.userId)

    const transcriptBody = isSystemMessage
      ? transcriptEntry.message
      : transcriptEntry.transcripts[transcriptEntry.preferredTranscript ?? 0]
    const timestamp = secondsToMinutesSeconds((transcriptEntry.timestamp - firstTimestamp) / 1000)

    const innerHTML = useMemo(() => {
      const escapedSearchText = searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      if (searchText) {
        const regex = new RegExp(escapedSearchText, 'gi')
        return transcriptBody.replace(regex, (match) => `<b>${match}</b>`)
      }
      return transcriptBody
    }, [searchText, transcriptBody])

    return isSystemMessage ? (
      <tr
        ref={ref}
        style={{
          opacity: active ? 1 : 0.5,
          cursor: active ? 'default' : 'pointer',
          fontSize: 16,
        }}
        onClick={onClick}
      >
        <td id="timestamp">
          <span>{timestamp}</span>
        </td>
        <td id="system-message" colSpan={2}>
          <span dangerouslySetInnerHTML={{ __html: innerHTML }} />
        </td>
      </tr>
    ) : (
      <tr
        ref={ref}
        style={{
          opacity: active ? 1 : 0.5,
          cursor: active ? 'default' : 'pointer',
          fontSize: 16,
        }}
        onClick={onClick}
      >
        <td id="timestamp">
          <span>{timestamp}</span>
        </td>
        <td id="author">
          <Author color={author?.color}>{author?.name}: </Author>
        </td>
        <td id="transcript">
          <span dangerouslySetInnerHTML={{ __html: innerHTML }} />
        </td>
      </tr>
    )
  }
)
