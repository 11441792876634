import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import React from 'react'

import { useInterviewNotesContext } from './InterviewNotesContextProvider/InterviewNotesContextProvider'
import { IInterviewNotesAuthor } from './types'

export const InterviewNotesEditorAccessDenied = () => {
  const { authors } = useInterviewNotesContext()
  const authorList = authors
    .map((author: IInterviewNotesAuthor) => {
      return author.email
    })
    .join(', ')

  return (
    <div className="InterviewNotesEditor-accessDenied">
      <div className="InterviewNotesEditor-accessDeniedText">
        <ReportProblemOutlinedIcon className="InterviewNotesEditor-accessDeniedIcon" />
        <p>Only interviewers who added/edited the notes can see them.</p>
      </div>
      <p>
        <strong>Need to see the notes?</strong>
        <br />
        Contact one of the following people to get access:
        <br />
        {authorList}
      </p>
    </div>
  )
}
