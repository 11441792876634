import React from 'react'

import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { DirectoryItemInput } from '../DirectoryItemInput'
import LanguageIcon from '../LanguageIcon'
import { WrappedToDepth } from '../utils/WrappedToDepth'

interface IAddFileProps {
  depth: number
  folderPath: string | null
  onExit: () => void
  showHighlight: boolean
  showSubHighlights: boolean
}
export const AddFile: React.FC<IAddFileProps> = ({
  depth,
  folderPath,
  onExit,
  showHighlight,
  showSubHighlights,
}) => {
  const { activeFile, addFile } = useActiveEnvironment()

  if (activeFile == null) {
    return null
  }

  const handleSubmit = (fileName: string) => {
    addFile(folderPath + fileName, '', true)
    onExit()
  }

  const activeFilePath = activeFile.path.replace(activeFile.name, '')
  const highlightDepth = activeFilePath.split('/').length - 1

  return (
    <div className="directory-item-input">
      <WrappedToDepth
        depth={depth}
        highlightDepth={highlightDepth}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
        opaque={true}
      >
        <DirectoryItemInput
          onExit={onExit}
          onSubmit={handleSubmit}
          icon={<LanguageIcon language={''} />}
        />
      </WrappedToDepth>
    </div>
  )
}
