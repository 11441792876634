import React, { FC } from 'react'

import { ScreenAd } from './Ads/ScreenAd'
import { TranscriptionAd } from './Ads/TranscriptionAd'

const ACTIVE_TAKEHOME_AD = <ScreenAd />
const ACTIVE_LIVE_AD = <TranscriptionAd />

export interface PlaybackAdSpaceProps {
  takeHome: boolean
}

export const PlaybackAdSpace: FC<PlaybackAdSpaceProps> = ({ takeHome }) => {
  return <>{takeHome ? ACTIVE_TAKEHOME_AD : ACTIVE_LIVE_AD}</>
}
