import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'

export function useFirebaseActiveSandboxExecEnv() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  const [activeSandboxExecutionEnvironment, setActiveSandboxExecutionEnvironment] = useState('')

  useEffect(() => {
    let activeSandboxExecEnvWatcher: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      activeSandboxExecEnvWatcher = SyncHandle().watch(
        'activeSandboxExecutionEnvironment',
        (slug: string) => setActiveSandboxExecutionEnvironment(slug),
        ''
      )
    }
    return () => {
      if (activeSandboxExecEnvWatcher != null) {
        SyncHandle().off('activeSandboxExecutionEnvironment', activeSandboxExecEnvWatcher)
      }
    }
  }, [hasEnvironments])

  return activeSandboxExecutionEnvironment
}
