import { FileFrames, RawFrames } from '../../playback/types'

/**
 * This function was pulled out of our sagas with little to no changes. The way
 * environments are processed are fundamentally different than the way legacy
 * playback is processed. For this reason, I wanted to separate the functions
 * we are using as early as possible to prevent having forked logic all over
 * our frame processing functions. Eventually, this will be optimized better
 * specifically for environments playback.
 */
export const getDerivedFrames = (fileFrames: RawFrames): FileFrames => {
  const transformedFrames: FileFrames = {}

  for (const fileName in fileFrames) {
    const frames = fileFrames[fileName]

    transformedFrames[fileName] = frames.map((frame, index) => {
      const ranges = []
      let offset = 0
      let totalOpLen = 0

      for (const op of frame.operation.ops) {
        if (op.isRetain()) {
          offset += op.chars
        } else {
          const opLen = op.isInsert() ? op.text.length : op.chars
          totalOpLen += opLen
          ranges.push({ startOffset: offset, endOffset: offset + opLen })
          offset += opLen
        }
      }

      const delay = index === 0 ? 0 : frame.timestamp - frames[index - 1].timestamp

      return {
        ...frame,
        editStartOffset: ranges.length > 0 ? ranges[0].startOffset : 0,
        editEndOffset: ranges.length > 0 ? ranges[ranges.length - 1].endOffset : 0,
        highlightRanges: totalOpLen > 3 || ranges.length > 1 ? ranges : [],
        delay,
        peripheralFrames: [],
      }
    })
  }

  return transformedFrames
}
