import { Theme } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React, { FC } from 'react'

import { SliderType, TimelineBase, TimelineBaseProps, TimelineSliderBase } from './TimelineBase'

export const TimelineSliderMain = withStyles((theme: Theme) => ({
  rail: {
    backgroundColor: theme.palette.playback?.timeline.background.primary,
  },
  track: {
    backgroundColor: theme.palette.activation?.main,
  },
  thumb: {
    marginTop: 0,
    width: 18,
    height: 18,
    top: '50%',
    zIndex: 10,
    backgroundColor: theme.palette.activation?.main,
    border: '5px solid #ffffff',
  },
}))(TimelineSliderBase)

export const TimelineMain: FC<TimelineBaseProps> = (props) => {
  return <TimelineBase {...props} SliderComponent={TimelineSliderMain as SliderType} />
}
