import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SendIcon from '@mui/icons-material/Send'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import { Box, Button, CircularProgress, styled, TextField, Typography } from '@mui/material'
import { usePadConfigValues, usePadContext } from 'packs/dashboard/components/PadContext/PadContext'
import { track } from 'packs/main/coderpad_analytics'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Socket } from 'socket.io-client'
import io from 'socket.io-client'
import { useWindow } from 'utils'
import { useFetch } from 'utils/fetch/useFetch'

import ConversationMessageRow from './ConversationMessageRow'
import EmptyState from './EmptyState'
import PendingMessage from './PendingMessage'
import { PlaybackAiTab } from './PlaybackAiTab'
import { ConversationMessage, PartialMessageOutput, PartialMessageOutputDone, Role } from './Types'

export const AiTabContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const MessageList = styled(ScrollView)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  flex: 1,
  marginTop: theme.spacing(2),
  '& > div > div:nth-of-type(even)': {
    backgroundColor: theme.palette.chatHistory.queryBackground,
  },
  '& > div > div:nth-of-type(odd)': {
    backgroundColor: theme.palette.chatHistory.responseBackground,
  },
}))

const CenteredBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
})

const SendMessageIcon = styled(SendIcon)<{ disabled: boolean }>(({ disabled }) => ({
  color: disabled ? '#434343' : '#E0E0E0',
  cursor: 'pointer',
}))

const PromptField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2),
  '& textarea': {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
}))

interface AiTabProps {
  hidden: boolean
  buttonRef: React.RefObject<Element>
}

export const AiTab: FC<AiTabProps> = ({ hidden, buttonRef }) => {
  const { firebaseAuthorId, firebaseShard, isPlayback, slug } = usePadConfigValues(
    'firebaseAuthorId',
    'firebaseShard',
    'isPlayback',
    'slug'
  )
  const [error, setError] = useState('')
  const [query, setQuery] = useState('')
  const [connecting, setConnecting] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [responding, setResponding] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [connectionRefused, setConnectionRefused] = useState(false)
  const [messages, setMessages] = useState<ConversationMessage[]>([])
  const socketRef = useRef<Socket | null>(null)
  const window = useWindow()
  const fetcher = useFetch()

  const { getToken } = usePadContext()

  const establishConnection = useCallback(async (): Promise<Socket> => {
    const token = await getToken()

    return io(window.CoderPad.AI_CHAT_SERVICE_URL, {
      query: {
        pad: slug,
        firebaseShard,
        token,
      },
      reconnectionDelayMax: 10000,
      reconnectionDelay: 2000,
      transports: ['websocket'],
    })
  }, [firebaseShard, slug, window.CoderPad.AI_CHAT_SERVICE_URL, getToken])

  const processCatchup = useCallback((data: ConversationMessage[]) => {
    setMessages(data)
  }, [])

  const processFullMessage = useCallback((data: ConversationMessage) => {
    setMessages((oldMessages) => [...oldMessages, data])
  }, [])

  const processPartialMessage = useCallback(
    (data: PartialMessageOutput) => {
      // If we join mid-response, disable prompt submission until
      // we get the complete response
      setDisableSubmit(true)

      // If we aren't expecting an incoming partial message (connected after the started message was broadcast)
      // then ignore the partial messages. We'll get it from onPartialMessageDone instead.
      if (!responding) return

      setMessages((oldMessages) => {
        const lastMessage = oldMessages[oldMessages.length - 1]
        if (lastMessage.messageId === data.message.messageId) {
          const newMessages = [...oldMessages]
          newMessages[newMessages.length - 1].content += data.messageToken
          return newMessages
        } else {
          return [...oldMessages, { ...data.message, content: data.messageToken, incomplete: true }]
        }
      })
    },
    [responding]
  )

  const onPartialMessageDone = useCallback((data: PartialMessageOutputDone) => {
    setMessages((oldMessages) => {
      const messageIndex = oldMessages.findIndex(
        (message) => message.messageId === data.message.messageId
      )
      if (messageIndex !== -1) {
        // Set incomplete false to rerender the message and add copy buttons to code blocks
        oldMessages[messageIndex].incomplete = false
        oldMessages[messageIndex].finishReason = data.message.finishReason
      } else {
        // Else add the message if it wasn't assembled from partial messages e.g. user connected
        // in the middle of a streamed response
        return [...oldMessages, { ...data.message }]
      }
      return oldMessages
    })
    setError('')
  }, [])

  useEffect(() => {
    if (isPlayback) {
      return
    }

    let socket: Socket
    async function initializeSocket(): Promise<void> {
      socket = await establishConnection()

      socketRef.current = socket

      socket.on('tokenExpired', async () => {
        socket.disconnect()

        const authToken = await getToken(true)

        // @ts-ignore no way to type the query object
        socket.io.opts.query!.token = authToken
        socket.connect()
      })

      socket.on('connectionRefused', () => {
        setConnectionRefused(true)
        console.log('not allowed to access AI chat')
      })

      socket.on('connect', () => {
        setConnecting(false)
        setConnectionRefused(false)
        setIsFirstLoad(false)
        setError('')
      })
      socket.on('disconnect', () => {
        setConnecting(true)
        setResponding(false)
        setDisableSubmit(false)
        setError('Connection to AI chat lost, attempting to reconnect')
      })
      socket.on('started', () => {
        setResponding(true)
        setDisableSubmit(true)
      })
      socket.on('catchup', processCatchup)
      socket.on('fullMessageOutput', processFullMessage)
      socket.on('partialMessageOutputDone', onPartialMessageDone)
      socket.on('finished', () => {
        setResponding(false)
        setDisableSubmit(false)
      })
      socket.on('platformError', () => {
        setResponding(false)
        setDisableSubmit(false)
        setError(
          'Something went wrong. You may have hit the max length limit for this conversation, try resetting the chat.'
        )
      })
      socket.on('reset', () => {
        setMessages([])
        setError('')
        setResponding(false)
        setDisableSubmit(false)
      })
    }

    initializeSocket()

    return () => {
      socket.off('tokenExpired')
      socket.off('connectionRefused')
      socket.off('connect')
      socket.off('disconnect')
      socket.off('started')
      socket.off('catchup')
      socket.off('fullMessageOutput')
      socket.off('partialMessageOutputDone')
      socket.off('finished')
      socket.off('platformError')
      socket.off('reset')
      socket.close()
    }
  }, [
    establishConnection,
    onPartialMessageDone,
    processFullMessage,
    processCatchup,
    isPlayback,
    getToken,
  ])

  // processPartialMessage depends on `responding`, so this effect runs before and after every
  // response from the AI, so we handle it separately to avoid having to on/off all the
  // other message handlers unnecessarily
  useEffect(() => {
    const socket = socketRef.current
    if (!socket) return

    socket.on('partialMessageOutput', processPartialMessage)

    return () => {
      socket.off('partialMessageOutput')
    }
  }, [processPartialMessage])

  const canSubmit = !connecting && !responding && !disableSubmit
  const submitMessage = () => {
    const socket = socketRef.current
    if (!socket || !canSubmit) {
      return
    }

    // Sets a flag on the pad so we know to show ai chat in playback even if the feature flag is off
    if (messages.length <= 0) {
      fetcher(`/${slug}/used_ai_chat`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
    }

    track('Submit AI Message', { content: query })
    scrollMessagesToBottom()
    socket.emit('submitMessage', { authorId: firebaseAuthorId, content: query })
    setQuery('')
  }

  const scrollMessagesToBottom = () => {
    const messageListEl = document.getElementById('message-list')
    if (messageListEl) {
      messageListEl.scroll({ top: messageListEl.scrollHeight })
    }
  }

  const clearMessages = () => {
    const socket = socketRef.current
    if (!socket) {
      return
    }

    track('Reset AI Conversation')
    socket.emit('reset')
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (canSubmit) {
        submitMessage()
      }
      e.preventDefault()
    }
  }

  const onStopResponse = () => {
    const socket = socketRef.current
    if (!socket) {
      return
    }

    track('Stop AI Response')
    socket.emit('abortSubmitMessage')
  }

  if (hidden) {
    return null
  }

  if (isPlayback) {
    return <PlaybackAiTab />
  }

  if (connectionRefused) {
    return (
      <CenteredBox>
        <Typography color="text.secondary">AI Chat in CoderPad is currently offline.</Typography>
      </CenteredBox>
    )
  }

  if (connecting && isFirstLoad) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    )
  }

  return (
    <>
      <AiTabContainer>
        {messages.length > 0 ? (
          <MessageList id="message-list">
            {/* This Box component allows MessageList to reverse the scroll direction without reversing all of its contents */}
            <Box>
              {messages.map((m, index) => (
                <ConversationMessageRow
                  key={index}
                  role={m.role}
                  content={m.content}
                  authorId={m.authorId}
                  messageId={m.messageId}
                  finishReason={m.finishReason}
                  incomplete={m.incomplete}
                />
              ))}
              {responding && messages[messages.length - 1]?.role !== Role.assistant && (
                <PendingMessage />
              )}
              {/* This acts as an overflow anchor so that if you are scrolled all the way down, you stay anchored */}
              {/* to the bottom as new messages come in */}
              <Box sx={{ overflowAnchor: 'auto', height: '1px' }} />
            </Box>
          </MessageList>
        ) : (
          <EmptyState />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<SquareOutlinedIcon />}
            sx={{ borderColor: '#848587' }}
            disabled={!responding}
            onClick={onStopResponse}
          >
            Stop response
          </Button>
        </Box>
        <PromptField
          variant="outlined"
          value={query}
          multiline
          maxRows={6}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: <SendMessageIcon disabled={!canSubmit} onClick={submitMessage} />,
            sx: { backgroundColor: 'transparent' },
          }}
          error={error !== ''}
          helperText={error ?? ''}
        />
      </AiTabContainer>
      {messages.length > 0 &&
        buttonRef.current &&
        createPortal(
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<RestartAltIcon />}
            sx={{ borderColor: '#848587' }}
            onClick={clearMessages}
          >
            Reset Chat
          </Button>,
          buttonRef.current
        )}
    </>
  )
}

export default AiTab
