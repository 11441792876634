import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CookieJar } from 'tough-cookie'

import { RequestConfig, RequestMethod } from '../types'

export function useSendRequest(cookieJar: CookieJar, baseUrl: string) {
  const dispatch = useDispatch()

  const sendRequest = useCallback(
    (requestConfig: RequestConfig) => {
      const hydratedConfig = {
        ...requestConfig,
        headers: {
          cookie: cookieJar.getCookieStringSync(`${baseUrl}/${requestConfig.path}`),
          ...requestConfig.headers,
        },
        body:
          requestConfig.method === RequestMethod.Get || requestConfig.method === RequestMethod.Head
            ? null
            : requestConfig.body ?? '',
      }
      dispatch({
        type: 'project/request',
        requestConfig: hydratedConfig,
      })
    },
    [cookieJar, dispatch, baseUrl]
  )

  return sendRequest
}

export function useAbortRequest() {
  const dispatch = useDispatch()

  const abortRequest = useCallback(
    (requestId: string) => {
      dispatch({
        type: 'project/abort_request',
        requestId,
      })
    },
    [dispatch]
  )

  return abortRequest
}
