// Tracks the last code that was copied from the editor, and the language it was in,
// so we can paste it into interviewer notes with highlighting.

const DEFAULT_STATE = {
  lines: [],
  language: null,
}

export default function lastCopyReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'code_copied':
      return {
        lines: action.lines,
        language: action.language,
      }
  }
  return state
}
