import { useCallback } from 'react'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'

export function useSetSandboxExecEnv() {
  const isSandbox = usePadConfigValue('isSandbox')

  const setSandboxExecutionEnvironment = useCallback(
    (slug: string) => {
      if (isSandbox) {
        SyncHandle().set('activeSandboxExecutionEnvironment', slug)
      }
    },
    [isSandbox]
  )

  return setSandboxExecutionEnvironment
}
