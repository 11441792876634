/**
 * Take a fileId (Base64-encoded, URL-safe, UTF-8 safe) and get the path.
 * Inverse of pathToFileId().
 *
 * @param fileId {string}
 * @returns {string}
 */
export function fileIdToPath(fileId: string): string {
  return decodeURIComponent(escape(window.atob(fileId.replace(/-/g, '+').replace(/_/g, '/'))))
}

/**
 * Take a file path and get the fileId (Base64-encoded, URL-safe, UTF-8 safe)
 * Inverse of fileIdToPath().
 *
 * @param path {string}
 * @returns {string}
 */
export function pathToFileId(path: string): string {
  return window
    .btoa(unescape(encodeURIComponent(path)))
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
}
