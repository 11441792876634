import { useEffect } from 'react'

import { setExtraProperties } from '../../../coderpad_analytics'
import { EnvironmentSummary } from '../types'

export function useAnalyticsProperties(environment: EnvironmentSummary | null) {
  useEffect(() => {
    setExtraProperties({
      padEnvironment: environment?.slug,
    })
  }, [environment?.slug])
}
