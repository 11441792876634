import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

export default class PadBanner extends React.Component {
  static propTypes = {
    // If set, delays appearance even if {visible: true}.
    delayAppearanceSeconds: PropTypes.number,
    onHide: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    warning: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const { delayAppearanceSeconds } = this.props
    this.state = { hiddenByDelay: !!delayAppearanceSeconds }
    if (delayAppearanceSeconds)
      setTimeout(() => this.setState({ hiddenByDelay: false }), delayAppearanceSeconds * 1000)
  }

  render() {
    return (
      <div
        className={classNames({
          PadBanner: true,
          'PadBanner--visible': this.props.visible && !this.state.hiddenByDelay,
          'PadBanner--warning': this.props.warning,
        })}
        data-testid='pad_banner'
      >
        {this.props.children}
        {this.props.onHide && (
          <a href="#" className="PadBanner-close fui-cross" onClick={this.props.onHide} />
        )}
      </div>
    )
  }
}
