import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import ActionButton from './ActionButton'

export function MainProcessActions() {
  const dispatch = useDispatch()
  const serviceStatus = useSelector((state) => state.padSettings.serviceStatus) as string
  const { projectTemplate } = useActiveEnvironment()

  const handleRestart = useCallback(() => {
    dispatch({ type: 'project/restartMainProcess' })
  }, [dispatch])

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <ActionButton
        onClick={handleRestart}
        disabled={['restarting', 'starting'].includes(serviceStatus)}
        title={
          // Give preference to "server" wording unless we know that this is a RunCommand project.
          projectTemplate?.settings?.executionType !== 'RunCommand' ? 'Restart server' : ''
        }
        color="inherit"
      >
        {projectTemplate?.settings?.executionType !== 'RunCommand' ? 'Restart' : 'Restart Shell'}
      </ActionButton>
    </Box>
  )
}
