import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Button from './button'
import FocusTimeCountdown from './focus_time_countdown'

class _FocusTimeOverlay extends React.Component {
  static propTypes = {
    darkBg: PropTypes.bool.isRequired,
    focusTimeStartedAt: PropTypes.number,
    onFocusTimeToggled: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className={'FocusTimeOverlay-container'}>
        <div className={'FocusTimeOverlay-modal'}>
          <div className={'FocusTimeOverlay-modalTitle'}>Focus Time in-progress</div>
          <div className={'FocusTimeOverlay-modalText'}>
            While your candidate is taking 5 minutes to wrap their head around the coding assessment
            you gave them, you can learn more about
            <br />
            <a
              href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
              target="_blank"
              rel="noopener noreferrer"
            >
              why Focus Time is a good idea
            </a>
            , take a break, or (quickly) go get something else done.
          </div>
          <div className={'FocusTimeOverlay-modalText'}>
            Turn your sound up to hear a notification sound when Focus Time is done.
          </div>
          <div className={'FocusTimeOverlay-modalText FocusTimeOverlay-modalText--attribution'}>
            Sound:{' '}
            <a
              href="https://freesound.org/people/rhodesmas/sounds/353206/"
              target="_blank"
              rel="noopener noreferrer"
            >
              "Intro 01"
            </a>{' '}
            by rhodesmas / CC-BY
          </div>
          <div className={'FocusTimeOverlay-modalCountdownWrapper'}>
            <FocusTimeCountdown startedAt={this.props.focusTimeStartedAt} />
          </div>
          <div className={'FocusTimeOverlay-modalButtonWrapper'}>
            <Button
              darkBg={this.props.darkBg}
              onClick={() => {
                this.props.onFocusTimeToggled(false)
              }}
              type={'primary'}
            >
              End Early
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { darkColorScheme } = state.editorSettings

  return {
    darkBg: darkColorScheme,
    focusTimeStartedAt: state.padSettings.focusTimeStartedAt,
  }
}

const mapDispatchToProps = {
  onFocusTimeToggled(value) {
    return {
      type: 'focus_time_toggled',
      value: value,
    }
  },
}

const FocusTimeOverlay = connect(mapStateToProps, mapDispatchToProps)(_FocusTimeOverlay)

export default FocusTimeOverlay
