import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'
import { EnvironmentSummary, EnvironmentTypes, IFirebaseEnvironment } from '../types'

export function useFirebaseEnvironments() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  const [environments, setEnvironments] = useState<EnvironmentSummary[]>([])
  const [hasLoadedEnvironments, setHasLoadedEnvironments] = useState(false)

  useEffect(() => {
    let watcherCB: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      watcherCB = SyncHandle().watch('environments', (fbEnvs: IFirebaseEnvironment[]) => {
        if (fbEnvs != null) {
          const envs = Object.keys(fbEnvs).map((eId) => {
            const e = fbEnvs[eId]
            return {
              id: eId,
              slug: e.slug,
              nameDisplayable: e.display,
              language: e.language,
              allowMultipleFiles:
                e.language === 'html' ||
                (!!e.projectTemplateSlug && !e.projectTemplateSlug.startsWith('jupyter')),
              questionId: e.questionId ? e.questionId : undefined,
              isQuestionWithVariants: e.isQuestionWithVariants,
              snippet: e.snippet ? e.snippet : undefined,
              spreadsheet: e.spreadsheet ? e.spreadsheet : undefined,
              projectTemplateSlug: e.projectTemplateSlug,
              projectTemplateVersion: e.projectTemplateVersion,
              customDatabaseLanguage: e.customDatabaseLanguage,
              kind: e.questionId
                ? EnvironmentTypes.Question
                : e.projectTemplateSlug
                ? EnvironmentTypes.Project
                : EnvironmentTypes.Language,
              visible: !e.isPreview,
            }
          })
          setEnvironments(envs)
        } else {
          setEnvironments([])
        }
        setHasLoadedEnvironments(true)
      })
    }
    return () => {
      if (watcherCB != null) {
        SyncHandle().off('environments', watcherCB)
      }
    }
  }, [hasEnvironments])

  return {
    environments,
    hasLoadedEnvironments,
  }
}
