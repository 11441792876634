import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import { IFrame } from './IFrame'
import { MiniBrowserProvider } from './MiniBrowserProvider'

export interface IMiniBrowserProps {
  hidden: boolean
}

export const MiniBrowser: React.FC<IMiniBrowserProps> = ({ hidden }) => {
  const processedHtml = useSelector((state) => state.execution.processedHtml)
  const timestamp = useSelector((state) => state.execution.processedHtmlTimestamp)
  const errors = useSelector((state) => state.execution.htmlErrors)

  return (
    <MiniBrowserProvider processedHtml={processedHtml} timestamp={timestamp} errors={errors}>
      <div className={classNames('MiniBrowser', { 'MiniBrowser--hidden': hidden })}>
        <IFrame />
      </div>
    </MiniBrowserProvider>
  )
}
