import { Exit } from '@codinpad/shared-components/components/icons/index.js'
import { Alert, Box, Grid, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'classnames'
import { PadState } from 'packs/dashboard/components/PadContext/padConfig'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BeamerButton } from '../BeamerButton'
import { PlaybackAdSpace } from '../components/PlaybackAdSpace/PlaybackAdSpace'
import { useEnvironments } from '../Environments/EnvironmentsContext/EnvironmentsContext'
import { FooterButton } from '../FooterButton'
import PadButton from '../pad_button'
import { PadFeedbackButton } from '../PadFeedbackButton'
import { PlaybackControls } from '../playback/Controls/PlaybackControls'
import { PlaybackParticipants } from '../playback/Controls/PlaybackParticipants'
import { useDebouncedEffect } from '../playback/hooks/useDebouncedEffect'
import { useFrames } from '../playback/hooks/useFrames'
import { usePlaybackParticipants } from '../playback/hooks/usePlaybackParticipants'
import { usePlayback } from '../playback/PlaybackContext'
import TitlePrompt from '../title_prompt'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 10,
    fontSize: '0.875rem',
    flexDirection: 'column',
    display: 'flex',
    transition: 'height 600ms ease-out',
    background: theme.palette.playback?.background,
  },
  userColor: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    background: '#fff',
    display: 'inline-block',
    marginRight: '0.75em',
  },
  bar: {
    height: 50,
    padding: `${theme.spacing(1)} 1em`,
  },
  participants: {
    height: 250,
  },
  toolbar: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > input': {
      flex: '1 1 auto',
    },
  },
}))

const PadFooterPlayback = () => {
  const styles = useStyles()
  const { playing } = usePlayback()
  const [collapsed, setCollapsed] = useState(false)
  const [shouldCollapse, setShouldCollapse] = useState(false)
  const [didAutoCollapse, setDidAutoCollapse] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)
  const { isOwner, padState, uiType, takeHome } = usePadConfigValues(
    'isOwner',
    'padState',
    'uiType',
    'takeHome'
  )
  const isDrawingPad = uiType === 'drawing_only'

  const dispatch = useDispatch()
  const participants = usePlaybackParticipants()
  const { trackedUserId, frames } = useSelector((state) => state.playbackHistory)
  const { getTimelineForUser } = useFrames()

  /**
   * Check through each participants timelines for edits. If no user has
   * made edits, we use this as a signal to hide the timeline controls and
   * show a helpful message instead. Initial operations and question-injections
   * are done under the `CoderPad` authorId, so those won't count as edits.
   */
  const hasHistory = useMemo(() => {
    for (const user of Object.keys(participants)) {
      if (getTimelineForUser(user).edits > 0) {
        return true
      }
    }
    return false
  }, [getTimelineForUser, participants])

  const { environments } = useEnvironments()
  const hasJupyterEnvironment = useMemo(() => {
    return environments.filter((e) => e.projectTemplateSlug?.includes('jupyter')).length > 0
  }, [environments])

  const historyLoaded = Object.values(frames).length > 0

  const COLLAPSE_DELAY = 3500

  const setActiveUser = useCallback(
    (userId: string) => {
      dispatch({
        type: 'playback_track_user',
        userId,
      })
    },
    [dispatch]
  )

  useDebouncedEffect(
    () => {
      if (shouldCollapse && !mouseOver) {
        setCollapsed(true)
        setDidAutoCollapse(true)
      }
    },
    COLLAPSE_DELAY,
    [shouldCollapse, mouseOver]
  )

  useEffect(() => {
    if (collapsed && shouldCollapse) {
      setShouldCollapse(false)
    }
  }, [collapsed, shouldCollapse])

  const handleMouseEnter = useCallback(() => {
    setMouseOver(true)
    if (didAutoCollapse) {
      setCollapsed(false)
      setDidAutoCollapse(false)
    }
  }, [didAutoCollapse])

  const handleMouseExit = useCallback(() => {
    setMouseOver(false)
    if (playing) {
      setShouldCollapse(true)
    }
  }, [playing])

  return (
    <div
      className={clsx(styles.root)}
      style={{
        height: !hasHistory ? 64 : collapsed ? 50 : 300,
        width: '100%',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
    >
      <Grid container className={styles.bar}>
        <Grid item xs={8} md={9}>
          {!isDrawingPad &&
            (hasHistory ? (
              <PlaybackControls
                setCollapsed={setCollapsed}
                setShouldCollapse={setShouldCollapse}
                collapsed={collapsed}
              />
            ) : (
              historyLoaded &&
              (hasJupyterEnvironment ? (
                <Box display="flex" flexDirection="column">
                  <Alert severity="warning">
                    Playback is currently unavailable for Beta Jupyter Notebooks. You can still
                    review all code in the Notebook window.
                  </Alert>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Alert severity="warning">
                    Playback is not available, because no edits were made in this pad.
                  </Alert>
                </Box>
              ))
            ))}
        </Grid>
        <Grid item xs={4} md={3} className={styles.toolbar}>
          {isOwner && <TitlePrompt />}
          <BeamerButton />
          <Box
            display={{
              xs: 'none',
              md: 'inherit',
            }}
          >
            <PadFeedbackButton />
          </Box>
          <Tooltip placement="top" arrow title="Return to dashboard">
            <span>
              <FooterButton
                href="/dashboard/pads"
                iconOnly={true}
                startIcon={<Exit sx={{ width: 20, height: 20 }} />}
              />
            </span>
          </Tooltip>
          {isOwner && padState === PadState.submitted_take_home && (
            <PadButton danger modalId="end-interview-modal">
              Finish Review
            </PadButton>
          )}
        </Grid>
      </Grid>
      <Grid container className={styles.participants}>
        <Grid item container xs={8} md={9} style={{ height: '100%' }}>
          <PlaybackParticipants
            participants={participants}
            setActiveUserId={setActiveUser}
            activeUserId={trackedUserId}
            setShouldCollapse={setShouldCollapse}
          />
        </Grid>
        <Grid item container xs={4} md={3} style={{ height: '100%' }}>
          <PlaybackAdSpace takeHome={takeHome} />
        </Grid>
      </Grid>
    </div>
  )
}

export default PadFooterPlayback
