import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { usePlaybackFrames } from '../../EnvironmentsPlayback/PlaybackFramesProvider'
import { PastedContentGlobalEvent } from '../GlobalEvents/GlobalEventTypes'
import { EventFrame, FlatFrames, Frame, Timeline } from '../types'
import { useConsoleHistory } from './useConsoleHistory'

/**
 * Convenience hook for pulling the frame state from the Redux store and
 * providing multiple convenience functions for accessing specific frames.
 *
 * Passing an `overrideFrames` object will wrap the provided frames in these
 * same convenience functions.
 */
export const useFrames = (overrideFrames?: FlatFrames) => {
  const padOrg = usePadConfigValue('padOrg')
  const frameIndex = useSelector((state) => state.playbackHistory.frameIndex)
  const frameDelay = useSelector((state) => state.playbackHistory.frameDelay)
  const globalEvents = useSelector((state) => state.playbackHistory.globalEvents)
  const { environmentFrames } = usePlaybackFrames()
  const consoleHistory = useConsoleHistory()
  const cachedUserFrames = useRef<{ [userId: string]: FlatFrames }>({})

  const trackedUserId = useSelector((state) => state.playbackHistory.trackedUserId)

  // memoize the file frames to prevent the for loop for triggering every render
  const frames: FlatFrames = useMemo(() => {
    if (overrideFrames) {
      return overrideFrames
    }
    if (environmentFrames.length > 0) {
      return environmentFrames
    }
    const frames: FlatFrames = []
    return frames
  }, [overrideFrames, environmentFrames])

  const globalEventFrames = useMemo(
    () => Object.values(globalEvents || {}).sort((a, b) => a.time - b.time),
    [globalEvents]
  )

  /**
   * Builds a timeline of events for the given user.
   * This specific funciton feels a bit terse. I think there's some
   * room for readability improvement, at the expense of some extra
   * code.
   */
  const getTimelineForUser = useCallback(
    (authorId: string): Timeline => {
      const newFrames: Frame[] = []
      const events: EventFrame[] = []
      let edits = 0

      let currentExecution = 0
      let lastExecutionTimestamp = 0
      const executions = Object.values(consoleHistory).filter(
        (console) => console.authorId === authorId && console.type === 'execution'
      )

      let currentPaste = 0
      let lastPasteTimestamp = 0
      const pastes = globalEventFrames.filter(
        (event) => event.type === 'pasted-content' && event.user == authorId
      ) as PastedContentGlobalEvent[]

      let currentLostFocus = 0
      let lastLostFocusTimestamp = 0
      const lostFocus = globalEventFrames.filter(
        (event) => event.type === 'lost-focus' && event.user == authorId
      )

      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i]
        const isAuthor = frame.authorId === authorId

        if (isAuthor) {
          let newExecution = executions[currentExecution]
          while (
            newExecution &&
            newExecution.timestamp >= lastExecutionTimestamp &&
            (newExecution.timestamp <= frame.timestamp || i === frames.length - 1)
          ) {
            currentExecution += 1
            events.push({
              type: 'execution',
              execution: newExecution,
              frame: i,
              tooltip: 'Code Execution',
            })
            lastExecutionTimestamp = newExecution.timestamp
            newExecution = executions[currentExecution]
          }

          if (padOrg?.tsaPastePlaybackEnabled) {
            const newPaste = pastes[currentPaste]
            if (
              newPaste &&
              newPaste.time > lastPasteTimestamp &&
              newPaste.time <= frame.timestamp
            ) {
              currentPaste += 1
              events.push({
                type: 'externalPaste',
                content: newPaste.data.content,
                frame: i,
                tooltip: 'External Paste',
              })
              lastPasteTimestamp = frame.timestamp
            }
          }

          if (padOrg?.tsaDefocusPlaybackEnabled) {
            const newLostFocusEvents = lostFocus
              .slice(currentLostFocus)
              .filter(
                (event) => event.time > lastLostFocusTimestamp && event.time <= frame.timestamp
              )
            if (newLostFocusEvents.length > 0) {
              currentLostFocus += newLostFocusEvents.length
              newLostFocusEvents.forEach((event) => {
                // calculate the first frame of the lost focus event
                // by finding the frame that is closest to the timestamp - duration
                const lostFocusFrame = frames.findIndex(
                  (frame) => frame.timestamp >= event.time - event.data.duration
                )

                events.push({
                  type: 'lostFocus',
                  duration: event.data.duration,
                  frame: lostFocusFrame === i ? i : [lostFocusFrame, i],
                  tooltip: `Clicked away for ${Math.ceil(event.data.duration / 1000)} seconds`,
                })
              })
              lastLostFocusTimestamp = newLostFocusEvents[newLostFocusEvents.length - 1].time
            }
          }

          if (frame.type !== 'retain') {
            edits++
            newFrames[i] = { ...frame }
          }
        } else if (frame.type === 'ai-chat') {
          newFrames[i] = { ...frame }
        } else {
          newFrames[i] = {
            ...frame,
            ...(newFrames[i] ? {} : { authorId: '', type: 'retain' }),
          }
        }
      }

      const timeline: Timeline = {
        frames: newFrames,
        events,
        edits,
      }
      return timeline
    },
    [
      consoleHistory,
      frames,
      globalEventFrames,
      padOrg?.tsaDefocusPlaybackEnabled,
      padOrg?.tsaPastePlaybackEnabled,
    ]
  )

  /**
   * Returns a list of frames ONLY for the `trackedUserId`.
   * NOTE: These frames are ONLY for doing calculations against, they are
   * not a replacement for the frames or fileFrames arrays. This array contains
   * synthetic frames in place of foreign-user edits.
   */
  const userFrames = useMemo(() => {
    if (!trackedUserId) return []
    const cached = cachedUserFrames.current[trackedUserId]
    if (cached) return cached
    const newFrames: FlatFrames = frames.map((frame) => {
      return frame.authorId === trackedUserId ? frame : { ...frame, type: 'retain' }
    })
    cachedUserFrames.current[trackedUserId] = newFrames
    return newFrames
  }, [frames, trackedUserId])

  return {
    frameIndex,
    frameDelay,
    frames,
    userFrames,
    getTimelineForUser,
  }
}
