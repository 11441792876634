import { useCallback } from 'react'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { addGlobalEvent } from './addGlobalEvent'
import { LanguageChangeGlobalEvent } from './GlobalEventTypes'

export const useGlobalEventHelpers = () => {
  const { firebaseAuthorId } = usePadConfigValues('firebaseAuthorId')

  const persistLanguageChange = useCallback(
    (language: string) => {
      addGlobalEvent<LanguageChangeGlobalEvent>({
        type: 'change-active-language',
        user: firebaseAuthorId?.toString() ?? 'unknown',
        data: {
          language,
          multifile: language === 'html',
          initialLanguage: false,
        },
      })
    },
    [firebaseAuthorId]
  )

  return {
    persistLanguageChange,
  }
}
