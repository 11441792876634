// Singleton class to hold TwilioRoom, LocalAudioTrack and LocalVideoTrack instances.
// This keeps the stores pure of these non-serializable, mutable objects.

class CallData {
  constructor() {
    this.localAudioTrack = null
    this.localVideoTrack = null
    this.room = null
  }
}

export default new CallData()
