export function humanizeMinutesToHours(minutes) {
  if (!minutes) {
    return ''
  } else if (minutes === 60) {
    return '1 hour'
  } else {
    const hours = parseInt(minutes / 60)
    if (hours * 60 === minutes) {
      return `${hours} hours`
    } else {
      return `${minutes} minutes`
    }
  }
}

export function humanizeSeconds(seconds) {
  if (!seconds) {
    return ''
  } else if (seconds === 1) {
    return '1 second'
  } else if (seconds < 60) {
    return `${seconds} seconds`
  } else {
    const minutes = parseInt(seconds / 60)
    if (minutes === 1) {
      return '1 minute'
    } else if (minutes < 180) {
      return `${minutes} minutes`
    } else {
      const hours = parseInt(minutes / 60)
      return `${hours} hours`
    }
  }
}
