import { useCallback, useState } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'
import { useFetch } from '../../../../../utils/fetch/useFetch'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { EnvironmentSummary } from '../types'

export function useDeleteEnvironment(
  environments: EnvironmentSummary[],
  activateEnvironment: (environmentId: string) => void
) {
  const fetch = useFetch()
  const { slug: padSlug } = usePadConfigValues('slug')
  const [deleteStatus, setDeleteStatus] = useState(queryStates.initial())

  const deleteEnvironment = useCallback(
    async (envSlug: string) => {
      if (queryStates.isLoadingState(deleteStatus)) return false

      const visibleEnvs = environments.filter((e) => e.visible)
      const envToDeleteIdx = visibleEnvs.findIndex((env) => env.slug == envSlug)
      const envToDelete = visibleEnvs[envToDeleteIdx]
      let envToActivate: EnvironmentSummary | null = null
      if (visibleEnvs.length > 1) {
        if (envToDeleteIdx === visibleEnvs.length - 1) {
          // Deleting the last env, so activate the preceeding.
          envToActivate = visibleEnvs[envToDeleteIdx - 1]
        } else {
          // Deleting env at the beginning of middle of the envs list, so activate the subsequent env.
          envToActivate = visibleEnvs[envToDeleteIdx + 1]
        }
      }

      // Activate the next env now to avoid any flashes of a "no environments" view.
      if (envToActivate) {
        activateEnvironment(envToActivate.id)
      }

      setDeleteStatus(queryStates.loading())
      // TODO add functionality to select next existing env after deletion.
      try {
        const rawResp = await fetch(`/${padSlug}/delete_environment/${envSlug}`, {
          method: 'POST',
        })

        if (rawResp.ok) {
          setDeleteStatus(queryStates.success())
        } else if (rawResp.status === 404) {
          throw new Error('Unable to find environment to delete.')
        } else if (rawResp.status === 401) {
          throw new Error('You are not authorized to delete this environment.')
        } else {
          throw new Error('An unknown error occurred while deleting this environment.')
        }

        return rawResp.ok
      } catch (err: unknown) {
        const error = err as Error
        setDeleteStatus(
          queryStates.error(
            error.message || 'An unknown error occurred while deleting this environment.',
            error
          )
        )
        // If deletion failed, we should re-activate the env that was to be deleted.
        activateEnvironment(envToDelete.id)
      }
      return false
    },
    [fetch, padSlug, deleteStatus, environments, activateEnvironment]
  )

  const clearDeleteStatus = useCallback(() => {
    setDeleteStatus(queryStates.initial())
  }, [setDeleteStatus])

  return { deleteEnvironment, deleteStatus, clearDeleteStatus }
}
