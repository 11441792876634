import { styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { omit } from 'lodash'
import React, { FC } from 'react'

import { SliderType, TimelineBase, TimelineBaseProps, TimelineSliderBase } from './TimelineBase'

const useStyles = makeStyles({
  playhead: {
    position: 'absolute',
    width: 1,
    height: 62,
    backgroundColor: '#828282',
    marginTop: -24,
  },
})

export const TimelineSliderUser = styled(TimelineSliderBase, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})(({ theme }) => ({
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.playback?.timeline.background.user,
  },
  '& .MuiSlider-track': {
    backgroundColor: 'transparent',
  },
}))

export const TimelineUser: FC<TimelineBaseProps> = (props) => {
  const styles = useStyles()
  return (
    <TimelineBase
      {...props}
      SliderComponent={TimelineSliderUser as SliderType}
      components={{
        Thumb: (props) => <span {...omit(props, 'ownerState')} className={styles.playhead} />,
      }}
    />
  )
}
