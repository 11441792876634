import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { useEnvironmentsPlaybackMock } from '../../../EnvironmentsPlayback/EnvironmentsPlaybackMock'
import { addGlobalEvent } from '../../../playback/GlobalEvents/addGlobalEvent'
import { ChangeEnvironmentGlobalEvent } from '../../../playback/GlobalEvents/GlobalEventTypes'
import SyncHandle from '../../../sync_handle'
import { EnvironmentSummary } from '../types'

export function useActivateEnvironment(environments: EnvironmentSummary[]) {
  const dispatch = useDispatch()
  const { isSandbox, isPlayback, firebaseAuthorId } = usePadConfigValues(
    'isSandbox',
    'isPlayback',
    'firebaseAuthorId'
  )

  const { setActiveEnvironmentId: setPlaybackActiveEnvironmentId } = useEnvironmentsPlaybackMock()

  const activateEnvironment = useCallback(
    (environmentId: string) => {
      const env = environments.find((e) => e.id === environmentId)
      if (env != null) {
        dispatch({
          type: 'activate_environment',
          environmentId,
        })
        SyncHandle().set('activeEnvironment', env.slug)
        if (isSandbox) {
          SyncHandle().set('activeSandboxExecutionEnvironment', env.slug)
        }
        if (isPlayback) {
          setPlaybackActiveEnvironmentId(environmentId)
        } else {
          addGlobalEvent<ChangeEnvironmentGlobalEvent>({
            type: 'change-environment',
            user: firebaseAuthorId!,
            data: {
              environment: environmentId,
            },
          })
        }
      }
    },
    [
      environments,
      dispatch,
      isSandbox,
      isPlayback,
      setPlaybackActiveEnvironmentId,
      firebaseAuthorId,
    ]
  )

  return activateEnvironment
}
