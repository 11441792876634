import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Switch, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadUpdateInterviewNotesPrivacy } from '../../../../graphql/hooks/pad/usePadUpdateInterviewNotesPrivacy/usePadUpdateInterviewNotesPrivacy'
import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { track } from '../../coderpad_analytics'
import { enqueueNotif } from '../../reducers/notifications'
import { selectEditorSettings } from '../../selectors'
import { useInterviewNotesContext } from '../InterviewNotesContextProvider/InterviewNotesContextProvider'
import { IInterviewNotesAuthor } from '../types'

export const InterviewNotesPrivacyControlBar = () => {
  const { darkColorScheme } = useSelector(selectEditorSettings)
  const { interviewNotesArePrivate, authors } = useInterviewNotesContext()
  const [isLoading, setIsLoading] = useState(false)
  const slug = usePadConfigValue('slug')
  const dispatch = useDispatch()
  const { updateInterviewNotesPrivacySetting } = usePadUpdateInterviewNotesPrivacy()

  const togglePrivacy = () => {
    setIsLoading(true)

    const newPrivateValue = !interviewNotesArePrivate
    track('Pad Interview Notes Privacy Toggled', { setToPrivate: newPrivateValue })

    updateInterviewNotesPrivacySetting({
      slug: slug,
      private: newPrivateValue,
    }).then(({ pad, message, errors }) => {
      setIsLoading(false)

      if (!pad || (errors && errors.length > 0)) {
        dispatch(
          enqueueNotif({
            message: message || 'There was a problem saving your changes. Please try again.',
            key: 'update-interview-notes-privacy-error',
            variant: 'error',
            autoDismissMs: 5000,
          })
        )
      }
    })
  }

  const labelText = interviewNotesArePrivate
    ? 'Notes are visible only to interviewers'
    : 'Notes are visible to anyone in the company'
  const themeClassSuffix = darkColorScheme ? 'darkBg' : 'lightBg'

  return (
    <div className="InterviewNotes-privacyControlBarHolder">
      <Box
        display="flex"
        alignItems="center"
        className={`InterviewNotes-privacyControlBar InterviewNotes-privacyControlBar--${themeClassSuffix}`}
      >
        <Switch
          checked={!interviewNotesArePrivate}
          onChange={togglePrivacy}
          disabled={isLoading}
          size="small"
          classes={{
            root: 'InterviewNotes-privacyControlBarSwitch',
            switchBase: `InterviewNotes-privacyControlBarSwitchBase--${themeClassSuffix}`,
          }}
          data-testid="notes-access-switch"
        />

        <div className="InterviewNotes-privacyControlBarText">{labelText}</div>
        {interviewNotesArePrivate && (
          <Tooltip
            arrow
            placement="bottom"
            classes={{
              tooltip: 'InterviewNotes-privacyControlBarTooltip',
              arrow: 'InterviewNotes-privacyControlBarTooltipArrow',
            }}
            title={
              <>
                The notes are only visible to interviewers who added/edited them.
                <br />
                Toggle the visiblity if anyone else needs to access them.
                <br />
                <br />
                <strong>Who can see the notes?</strong>
                <br />
                <ul>
                  {authors.map((author: IInterviewNotesAuthor) => {
                    return <li key={author.id}>{author.email}</li>
                  })}
                </ul>
              </>
            }
          >
            <InfoOutlinedIcon className="InterviewNotes-privacyControlBarInfoIcon" />
          </Tooltip>
        )}
      </Box>
    </div>
  )
}
