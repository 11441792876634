import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

interface OrganizationLogoProps {
  isInline?: boolean
  logoUrl?: string
  className?: string
}

export const OrganizationLogo: React.FC<OrganizationLogoProps> = ({
  isInline = false,
  logoUrl,
  className,
}) => {
  const [isSquareLogo, setIsSquareLogo] = useState(false)

  useEffect(() => {
    const getImageDimensions = async () => {
      try {
        const dimensions: { width: number; height: number } = await new Promise(
          (resolve, reject) => {
            const image = new Image()
            image.onload = () => resolve({ width: image.width, height: image.height })
            image.onerror = reject
            image.src = logoUrl ?? ''
          }
        )
        setIsSquareLogo(dimensions.width === dimensions.height)
      } catch {
        setIsSquareLogo(false)
      }
    }

    getImageDimensions()
  }, [logoUrl])

  const imgStyles = useMemo(
    () => ({
      height: isInline && isSquareLogo ? '160px' : '75px',
      ...(isInline
        ? {}
        : {
            transition: 'all 0.3s ease-in-out',
          }),
    }),
    [isInline, isSquareLogo]
  )

  return (
    <Box position="relative" width="0px" className={className}>
      <Box component="img" sx={imgStyles} alt="company logo" src={logoUrl} />
    </Box>
  )
}
