import * as Sentry from '@sentry/browser'
import { useCallback, useState } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'
import { useFetch } from '../../../../../utils/fetch/useFetch'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'
import { EnvironmentSummary } from '../types'

export function useResetEnvironment(environments: EnvironmentSummary[]) {
  const fetch = useFetch()
  const { slug } = usePadConfigValues('slug')
  const [resetStatus, setResetStatus] = useState(queryStates.initial())

  const resetEnvironment = useCallback(
    (envSlug: string) => {
      return new Promise<boolean>((resolve) => {
        if (queryStates.isLoadingState(resetStatus)) {
          resolve(false)
        }

        setResetStatus(queryStates.loading())

        const envFirebaseId = environments.find((env) => env.slug === envSlug)?.id

        fetch(`/${slug}/environment_file_contents/${envSlug}`, {
          method: 'GET',
        })
          .then((rawRes) => {
            if (!rawRes.ok) {
              if (rawRes.status === 404) {
                throw new Error('Unable to find environment to reset.')
              } else if (rawRes.status === 401) {
                throw new Error('You are not authorized to reset this environment.')
              } else {
                throw new Error(
                  `An unknown error occurred while getting information for this environment. Status ${rawRes.status}`
                )
              }
            }
            return rawRes
          })
          .then((rawRes) => rawRes.json())
          .then((res) => {
            if (res.files) {
              const originalFiles = res.files as { [key: string]: string }
              SyncHandle().get(
                `environmentFileIds/${envSlug}`,
                (envFileIds: { [key: string]: string }) => {
                  const addedFilesToDelete = Object.keys(envFileIds).reduce((acc, fileKey) => {
                    if (!originalFiles[fileKey]) {
                      acc.push(fileKey)
                    }
                    return acc
                  }, [] as string[])
                  const writePromises: Promise<void>[] = []
                  Object.keys(originalFiles).forEach((originalFileId) => {
                    writePromises.push(
                      new Promise<void>((resolve) => {
                        SyncHandle().set(`environmentFileIds/${envSlug}/${originalFileId}`, true)
                        const hlfp = SyncHandle().firepadHeadless(
                          `environmentFiles/${envSlug}/${originalFileId}`
                        )
                        hlfp.on('ready', () => {
                          hlfp.setText(originalFiles[originalFileId]).then(() => {
                            hlfp.dispose()
                            resolve()
                          })
                        })
                      })
                    )
                  })
                  addedFilesToDelete.forEach((delFileId) => {
                    writePromises.push(
                      new Promise<void>((resolve) => {
                        SyncHandle().set(`environmentFileIds/${envSlug}/${delFileId}`, false)
                        resolve()
                        const hlfp = SyncHandle().firepadHeadless(
                          `environmentFiles/${envSlug}/${delFileId}`
                        )
                        hlfp.on('ready', () => {
                          hlfp.setText('').then(() => {
                            hlfp.dispose()
                            resolve()
                          })
                        })
                      })
                    )
                  })

                  if (res.question?.language && envFirebaseId) {
                    SyncHandle().set(
                      `environments/${envFirebaseId}/language`,
                      res.question.language
                    )
                  }

                  Promise.all(writePromises).then(() => {
                    setResetStatus(queryStates.success())
                    resolve(true)
                  })
                }
              )
            } else {
              throw new Error('No environment files returned from backend.')
            }
          })
          .catch((err) => {
            setResetStatus(queryStates.error(err.message, err))
            Sentry.captureException(err, {
              tags: {
                padSlug: slug,
                environmentSlug: envSlug,
              },
            })
            resolve(false)
          })
      })
    },
    [fetch, slug, resetStatus, setResetStatus, environments]
  )

  const clearResetStatus = useCallback(() => {
    setResetStatus(queryStates.initial())
  }, [])

  return {
    resetEnvironment,
    resetStatus,
    clearResetStatus,
  }
}
