import { Box, Divider, List, ListItem, ListItemText } from '@mui/material'
import { Pagination } from '@mui/material'
import React from 'react'

import { memoizedMakeUseExamples } from '../../../../../graphql/hooks/question/useQuestionSearch/useExampleSearch'
import * as queryStates from '../../../../../graphql/queryStates'
import { QueryExampleQuestionsSearchArgs } from '../../../../../graphql/types'
import { LoadingBlock } from '../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { ScrollView } from '../../../ScrollView/ScrollView'

interface IExampleListProps {
  filters: QueryExampleQuestionsSearchArgs
  setFilter: (name: string, value: any) => void
  onSelect: (exampleId: string, isExample: boolean) => void
  selectedExampleId?: string
}

export const ExampleList: React.FC<IExampleListProps> = ({
  filters,
  setFilter,
  onSelect,
  selectedExampleId,
}) => {
  const useExamples = memoizedMakeUseExamples(`
    id
    title
    languages
    difficulty
    createdAt
    description
`)
  const { questions, status, pageInfo } = useExamples(filters)

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <ScrollView flex="1 1 auto" overflow="auto">
        <LoadingBlock
          isLoading={queryStates.isLoadingState(status)}
          message="Searching example questions"
          minDisplayMS={0}
        >
          <List>
            {!questions.length && (
              <Box display="flex" justifyContent="center">
                No question search results
              </Box>
            )}
            {questions.map((question) => (
              <ListItem
                button
                key={question.id}
                onClick={() => onSelect(question.id, true)}
                selected={question.id === selectedExampleId}
              >
                <Box overflow="hidden" width="100%">
                  <ListItemText
                    primary={question.title}
                    secondary={question.description}
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                  <Divider key={`${question.id}-divider`} />
                </Box>
              </ListItem>
            ))}
          </List>
        </LoadingBlock>
      </ScrollView>
      <Box display="flex" justifyContent="flex-end">
        <Pagination
          count={pageInfo.filteredPageCount}
          // The MUI Pagination component is 1-based.
          page={pageInfo.pageIdx + 1}
          onChange={(e, v) => setFilter('page', v - 1)}
        />
      </Box>
    </Box>
  )
}
