import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { QuestionLanguageDisplay } from 'packs/dashboard/Questions/QuestionLanguageDisplay/QuestionLanguageDisplay'
import { useEnvironments } from 'packs/main/Environments/EnvironmentsContext/EnvironmentsContext'
import React, { useCallback, useState } from 'react'

import { useMakeUseQuestion } from '../../../../../../graphql/hooks/question/useQuestion/useQuestion'
import * as queryStates from '../../../../../../graphql/queryStates'
import { LoadingBlock } from '../../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { DetailBody } from '../DetailBody/DetailBody'

export const QuestionDetail: React.FC<{
  onSelect: (id: number, envOpts?: Record<string, any>) => void
  questionId?: number
}> = ({ onSelect, questionId }) => {
  const { activeEnvironment } = useEnvironments()

  const { question, status } = useMakeUseQuestion(`
    user {
      id
      name
    }
    title
    createdAt
    language
    description
    solution
    contents
    fileContents
    padType
    customDatabase {
      id
      language
      description
    }
    customFiles {
      id
      title
      description
      filename
      filesize
    }
    candidateInstructions {
      instructions
      defaultVisible
    }
    projectTemplate {
      name
      slug
    }
    drawing {
      boardId
    }
    spreadsheet {
      id
      gsheetId
    }
  `)(questionId)

  const [
    includePreviousContentsOnQuestionLoad,
    setIncludePreviousContentsOnQuestionLoad,
  ] = useState(false)
  const handleIncludePreviousContentsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIncludePreviousContentsOnQuestionLoad(event.target.checked)
    },
    [setIncludePreviousContentsOnQuestionLoad]
  )

  return (
    <LoadingBlock
      isLoading={queryStates.isLoadingState(status) && !question}
      message="Fetching question details"
      minDisplayMS={0}
    >
      {question && (
        <Box whiteSpace="pre-wrap" display="flex" flexDirection="column" height="100%">
          <Box flex={1} overflow="hidden" display="flex" flexDirection="column">
            <Box mb={2}>
              <Typography variant="body1">{question.title}</Typography>
            </Box>
            <Box mb={1.5}>
              <Typography variant="body2" color="textSecondary">
                {question.description}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" component="div">
              <Box display="flex" alignItems="center" component="span">
                <QuestionLanguageDisplay question={question} />
                <Box component="span" ml={1}>
                  by {question.user?.name}{' '}
                  {formatDistanceToNow(new Date(question.createdAt), { addSuffix: true })}
                </Box>
              </Box>
            </Typography>
            <Box mt={2} flex={1} overflow="hidden">
              <DetailBody question={question} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            {activeEnvironment?.projectTemplateSlug == null && question.projectTemplate == null && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includePreviousContentsOnQuestionLoad}
                      onChange={handleIncludePreviousContentsChange}
                    />
                  }
                  label="Append question to current tab"
                />
              </FormGroup>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={() =>
                onSelect(
                  question.id,
                  includePreviousContentsOnQuestionLoad && activeEnvironment?.slug
                    ? { env_slug_to_prepend: activeEnvironment.slug }
                    : undefined
                )
              }
            >
              Add Question
            </Button>
          </Box>
        </Box>
      )}
    </LoadingBlock>
  )
}
