import { gql, useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import {
  DeleteQuickActionInput,
  DeleteQuickActionPayload,
  MutationDeleteQuickActionArgs,
  QuickAction,
} from '../../../types'

export const DELETE_QUICK_ACTION_MUTATION = gql`
  mutation DeleteQuickAction($input: DeleteQuickActionInput!) {
    deleteQuickAction(input: $input) {
      errors {
        message
        path
      }
      message
      quickAction {
        id
        name
        payload
      }
    }
  }
`

export interface DeleteQuickActionData {
  deleteQuickAction: DeleteQuickActionPayload
}

export function useDeleteQuickAction() {
  const [deleteQuickActionMutation, { data, error, loading }] = useMutation<
    DeleteQuickActionData,
    MutationDeleteQuickActionArgs
  >(DELETE_QUICK_ACTION_MUTATION, {
    context: {
      source: 'useDeleteQuickAction.ts',
    },
  })

  const deleteQuickAction = useCallback(
    async (quickActionDeleteAttributes: DeleteQuickActionInput['quickActionDeleteAttributes']) =>
      await deleteQuickActionMutation({
        update: (cache, { data }) => {
          const deletedQuickAction = data?.deleteQuickAction.quickAction

          if (!deletedQuickAction) return

          cache.modify({
            fields: {
              quickActions(existingQuickActionRefs: QuickAction[] = [], { readField }) {
                return existingQuickActionRefs.filter(
                  (quickActionRef) => readField('id', quickActionRef) !== deletedQuickAction.id
                )
              },
            },
          })
        },
        variables: { input: { quickActionDeleteAttributes } },
      }),
    [deleteQuickActionMutation]
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(
        isEmpty(data?.deleteQuickAction?.message)
          ? error.message
          : data?.deleteQuickAction?.message || ''
      )
    } else {
      return queryStates.success(data?.deleteQuickAction?.message || '')
    }
  }, [data, error, loading])

  return {
    error,
    deleteQuickAction,
    status,
  }
}
