import { Invite } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Tooltip } from '@mui/material'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'

import { useAnalytics } from '../../../utils'
import { PadTranslation } from '../constants'
import { FooterButton } from '../FooterButton'
import { PadInvites } from './PadInvites'

export function PadInvitesButton() {
  const [isPadInvitesOpen, setIsPadInvitesOpen] = useState(false)
  const { track } = useAnalytics()

  const handleClick = useCallback(() => {
    setIsPadInvitesOpen(!isPadInvitesOpen)

    if (!isPadInvitesOpen) {
      track('Invite Modal Opened')
    }
  }, [isPadInvitesOpen, track])

  const handlePadInvitesClose = useCallback(() => {
    setIsPadInvitesOpen(false)
  }, [])

  return (
    <div className={classNames({ InviteButton: true })}>
      <Tooltip
        placement="top"
        arrow
        title={<Box textAlign="center">{PadTranslation.padInvitesButtonTooltip}</Box>}
      >
        <span>
          <FooterButton onClick={handleClick} startIcon={<Invite sx={{ width: 20, height: 20 }} />}>
            <Box sx={{ display: { xs: 'none', lg: 'initial' } }}>
              {PadTranslation.padInvitesButtonLabel}
            </Box>
          </FooterButton>
        </span>
      </Tooltip>
      <Box sx={{ bottom: (theme) => theme.spacing(6), position: 'absolute', left: 0, zIndex: 2 }}>
        <PadInvites isOpen={isPadInvitesOpen} onClose={handlePadInvitesClose} />
      </Box>
    </div>
  )
}
