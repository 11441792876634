import { Howl } from 'howler'
import React, { useCallback, useMemo, useState } from 'react'

import { useLocalStorage } from '../../../utils'

interface Sound {
  id: number
  asset: string
  sound: Howl
}

interface SoundContext {
  volume: number
  setVolume: (volume: number) => void
  play: (sound: string) => number
  stop: (soundId: number) => void
  pause: (soundId: number) => void
}

const soundContext = React.createContext<SoundContext>({
  volume: 1,
  setVolume: () => {},
  play: () => -1,
  stop: () => {},
  pause: () => {},
})

export const SoundProvider: React.FC<SoundContext> = ({ children }) => {
  const [volume, setVolume] = useLocalStorage('sfx_volume', 1)
  const [sounds, setSounds] = useState<Sound[]>([])

  const play = useCallback(
    (assetName: string) => {
      const asset = window.CoderPad.ASSETS[assetName]
      if (!asset) {
        throw new Error(`No asset found for ${assetName}`)
      }

      const sound = new Howl({
        src: [asset],
        volume,
      })

      const id = sound.play()

      sound.on('end', () => {
        setSounds(sounds.filter((s) => s.id !== id))
      })

      setSounds([...sounds, { id, asset, sound }])

      return id
    },
    [sounds, volume]
  )

  const stop = useCallback(
    (soundId: number) => {
      const sound = sounds.find((s) => s.id === soundId)
      if (sound) {
        sound.sound.stop()
        setSounds(sounds.filter((s) => s.id !== soundId))
      }
    },
    [sounds]
  )

  const pause = useCallback(
    (soundId: number) => {
      const sound = sounds.find((s) => s.id === soundId)
      if (sound) {
        sound.sound.pause()
      }
    },
    [sounds]
  )

  const values = useMemo(
    () => ({
      volume,
      setVolume,
      play,
      stop,
      pause,
    }),
    [volume, setVolume, play, stop, pause]
  )

  return <soundContext.Provider value={values}>{children}</soundContext.Provider>
}

export const useSound = () => {
  return React.useContext(soundContext)
}
