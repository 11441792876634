import { createFetcher } from 'utils/fetch/fetch'

interface padAuthTokenOpts {
  environmentSlug?: string | null
  customDatabaseLanguage?: string | null
}

export async function fetchAuthToken(
  padSlug: string,
  opts: padAuthTokenOpts = {}
): Promise<string> {
  const token =
    document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
  const fetcher = createFetcher(token)
  const authResponse = await fetcher(`/custom_resources_auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ padSlug, ...opts }),
  })
  const authResponseJson = await authResponse.json()

  return authResponseJson.token
}
