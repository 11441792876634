import { Box, styled } from '@mui/material'
import clsx from 'classnames'
import React, { useCallback, useState } from 'react'
import { DraggableCore } from 'react-draggable'

import { PaneOrientation, useSplitPaneContext } from '../SplitPane'

const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: PaneOrientation }>(({ theme, orientation }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: orientation === 'x' ? '0px' : '100%',
  height: orientation === 'x' ? '100%' : '0px',
  margin: orientation === 'x' ? '0 1px' : '1px 0',
  '& .indicator': {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.palette.resizer.background,
    width: orientation === 'x' ? '2px' : '100%',
    height: orientation === 'x' ? '100%' : '2px',
  },
  '&.active .indicator': {
    backgroundColor: theme.palette.resizer.active,
    width: orientation === 'x' ? '3px' : '100%',
    height: orientation === 'x' ? '100%' : '3px',
  },
  '&:hover': {
    '& .indicator': {
      backgroundColor: theme.palette.resizer.active,
      transition: theme.transitions.create(['background-color', 'width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.complex,
      }),
      width: orientation === 'x' ? '3px' : '100%',
      height: orientation === 'x' ? '100%' : '3px',
    },
  },
}))

const ResizerBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<{ orientation: PaneOrientation }>(({ orientation }) => ({
  zIndex: 2,
  position: 'absolute',
  cursor: orientation === 'x' ? 'col-resize' : 'row-resize',
  width: orientation === 'x' ? '10px' : '100%',
  height: orientation === 'x' ? '100%' : '10px',
}))

export const Resizer: React.FC<{
  onResize: (right: number) => void
  onResizeStop: () => void
  isActive?: boolean
}> = ({ onResize, onResizeStop, isActive }) => {
  const { orientation } = useSplitPaneContext()

  const [startPos, setStartPos] = useState(-1)
  const handleStart = useCallback(
    (_e, { x, y }) => {
      setStartPos(orientation === 'x' ? x : y)
    },
    [orientation]
  )
  const handleMove = useCallback(
    (_e, { x, y }) => {
      onResize(orientation === 'x' ? x - startPos : y - startPos)
    },
    [orientation, startPos, onResize]
  )
  const handleStop = useCallback(() => {
    setStartPos(-1)
    onResizeStop()
  }, [onResizeStop])

  return (
    <DraggableCore onStart={handleStart} onDrag={handleMove} onStop={handleStop}>
      <Root className={clsx({ active: isActive })} orientation={orientation}>
        <ResizerBar data-testid="splitpane-pane-resizer" orientation={orientation} />
        <div className="indicator"></div>
      </Root>
    </DraggableCore>
  )
}
