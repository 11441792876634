import { Box, styled } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { Tab, TabList, TabPanel, Tabs } from 'packs/dashboard/components/Tabs'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { track } from '../../coderpad_analytics'
import { PadAnalyticsEvent } from '../../constants'
import { ScrollView } from '../../ScrollView/ScrollView'
import { useRequestClient } from '../RequestClientContext/RequestClientContext'
import { RequestStatus } from '../RequestClientContext/types'
import { useRequestClientSelected } from '../RequestClientSelectedContext/RequestClientSelectedContext'
import { RequestClientAddressBar } from './RequestClientAddressBar/RequestClientAddressBar'
import { useRequestClientConfiguration } from './RequestClientConfigurationContext'
import { RequestClientBody } from './tabs/RequestClientBody'
import { RequestClientCookies } from './tabs/RequestClientCookies'
import { RequestClientHeaders } from './tabs/RequestClientHeaders'
import { RequestClientParams } from './tabs/RequestClientParams'

const StyledTabPanel = styled(TabPanel)({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  marginTop: 16,
  padding: 0,
  overflow: 'hidden',
})

export const RequestClientConfiguration: FC = () => {
  const isPlayback = usePadConfigValue('isPlayback')
  const {
    method,
    setMethod,
    path,
    setPath,
    performRequest,
    clearForm,
  } = useRequestClientConfiguration()
  const {
    setIsDirty,
    currentlyViewingRequestId,
    currentlyViewingRequestStatus,
  } = useRequestClientSelected()
  const { abortRequest } = useRequestClient()

  const doRequestCancel = useCallback(() => {
    if (currentlyViewingRequestId) {
      abortRequest(currentlyViewingRequestId)
      track(PadAnalyticsEvent.RequestClientRequestCanceled)
    }
  }, [currentlyViewingRequestId, abortRequest])

  const handlePerformRequest = useCallback(() => {
    performRequest()
    track(PadAnalyticsEvent.RequestClientRequestSent)
  }, [performRequest])

  const [isWorking, setIsWorking] = useState(false)
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null
    if (currentlyViewingRequestStatus === RequestStatus.Pending) {
      timeout = setTimeout(() => {
        setIsWorking(true)
      }, 200)
    } else if (timeout) {
      setIsWorking(false)
      clearTimeout(timeout)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
        setIsWorking(false)
      }
    }
  }, [currentlyViewingRequestStatus])

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <RequestClientAddressBar
        method={method}
        url={path}
        onChangeMethod={(method) => {
          setMethod(method)
          setIsDirty(true)
        }}
        onChangeUrl={(path) => {
          setPath(path, true)
          setIsDirty(true)
        }}
        onSend={handlePerformRequest}
        onClear={clearForm}
        readOnly={isPlayback}
        isWorking={isWorking}
        doRequestCancel={doRequestCancel}
      />
      <Box
        mt={1}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
        }}
      >
        <Tabs size="small">
          <TabList>
            <Tab value="body" label="Body" />
            <Tab value="cookies" label="Cookies" />
            <Tab value="headers" label="Headers" />
            <Tab value="params" label="Params" />
          </TabList>
          <StyledTabPanel value="body">
            <RequestClientBody />
          </StyledTabPanel>
          <StyledTabPanel value="cookies">
            <ScrollView>
              <RequestClientCookies />
            </ScrollView>
          </StyledTabPanel>
          <StyledTabPanel value="headers">
            <ScrollView>
              <RequestClientHeaders />
            </ScrollView>
          </StyledTabPanel>
          <StyledTabPanel value="params">
            <ScrollView>
              <RequestClientParams />
            </ScrollView>
          </StyledTabPanel>
        </Tabs>
      </Box>
    </Box>
  )
}
