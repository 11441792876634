import { Box, styled } from '@mui/material'

export const Selector = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.editor?.sidebar.background,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // Need z-index to be just above the envs menu.
  zIndex: 14,
  position: 'relative',
}))

export const Menu = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMenuOpen',
})<{ isMenuOpen: boolean }>(({ theme, isMenuOpen }) => ({
  backgroundColor: theme.palette.editor?.sidebar.background,
  top: 0,
  width: '75vw',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  position: 'absolute',
  border: 'none',
  height: '100%',
  // Needs a z-index to float above the pane resizer, etc...
  zIndex: 13,
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100vw - 90px)',
  },
  transition: theme.transitions.create(['left', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isMenuOpen
    ? {
        left: '82px',
        opacity: 1,
      }
    : {
        left: '-200vw',
        opacity: 0,
        overflowX: 'hidden',
      }),
}))

export const CreationOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  backgroundColor:
    theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)',
}))

export const HintTitle = styled(Box)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: '#FFFFFF',
}))
