import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { Maybe, QuickAction, QuickActionsPage } from '../../../types'

export const QUICK_ACTIONS_QUERY = gql`
  query QuickActions($page: QuickActionsPage!) {
    quickActions(page: $page) {
      id
      name
      payload
    }
  }
`

export interface QuickActionsData {
  quickActions: Maybe<Array<QuickAction>>
}

export function useQuickActions(page: QuickActionsPage) {
  const { data, error, loading, refetch } = useQuery<QuickActionsData>(QUICK_ACTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { page },
  })

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(error.message || 'There was an error while fetching quick actions.')
    } else {
      return queryStates.success()
    }
  }, [error, loading])

  return useMemo(() => ({ quickActions: data?.quickActions || undefined, refetch, status }), [
    data,
    refetch,
    status,
  ])
}
