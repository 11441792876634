import '@convergencelabs/monaco-collab-ext/css/monaco-collab-ext.css'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import clsx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { track } from '../coderpad_analytics'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { MonacoPlaybackProvider } from '../playback/MonacoPlayback'
import { clearEditorError } from '../reducers/editorStatus'
import EditorLogo from './EditorLogo'
import EditorPane from './EditorPane'
import EditorPaneHeader from './EditorPaneHeader'
import { FilePane } from './FilePane/FilePane'
import LanguageClient from './LanguageClient'
import { MonacoProvider as ClassicMonacoProvider } from './MonacoContext'
import { QuestionWithVariantsEntry } from './QuestionWithVariantsEntry'
import { useGoldmanRedirect } from './useGoldmanRedirect/useGoldmanRedirect'

function GoldmanRedirectCheck() {
  useGoldmanRedirect()
  return null
}

const MonacoEditor = () => {
  const { hasEnvironments, isPlayback } = usePadConfigValues('isPlayback', 'hasEnvironments')
  const dispatch = useDispatch()
  const { activeFile, environment } = useActiveEnvironment()

  // This is a value used to the force a remount of the MonacoProvider, thus re-establishing the Firepad connection.
  // This is a workaround to help users combat the cases where their editor has encountered a Firepad error and is
  // no longer syncing to Firebase.
  const { sentinel, hasErrorOccurred } = useSelector((state) => state.editorStatus)

  const [reloadDialogOpen, setReloadDialogOpen] = useState(false)
  useEffect(() => {
    setReloadDialogOpen(hasErrorOccurred)
  }, [hasErrorOccurred])
  const reloadEditor = useCallback(() => {
    dispatch(clearEditorError())
    track('Dialog editor reload clicked')
  }, [dispatch])

  // For environment pads, the monaco provider lives higher up the tree of components. Only need a provider
  // here is this is a classic pad.
  const MonacoProvider = hasEnvironments ? React.Fragment : ClassicMonacoProvider

  const InnerEditor = (
    <div
      className={clsx('monaco-editor-holder', { fullHeight: hasEnvironments })}
      data-testid="monaco-code-editor"
    >
      {
        // Environment pads will provide their own implementation of a file pane.
        hasEnvironments ? null : <FilePane />
      }
      <div className="monaco-editor-pane">
        {
          // Environment pads will provider their own implementation of an editor header.
          hasEnvironments ? null : <EditorPaneHeader />
        }
        <div className="monaco-editor">
          {environment?.isQuestionWithVariants &&
          environment?.language == null &&
          environment?.projectTemplateSlug == null ? (
            <QuestionWithVariantsEntry />
          ) : activeFile?.isBinary || activeFile?.isLargeFile ? (
            <Box
              sx={(theme) => {
                return {
                  color: theme.palette.editor.iconStroke,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }
              }}
            >
              {activeFile?.isBinary ? 'Unable to display file' : 'File is too large to display'}
            </Box>
          ) : (
            <EditorPane />
          )}
          <EditorLogo />
        </div>
        {isPlayback !== true && <LanguageClient />}
      </div>
    </div>
  )

  return (
    <MonacoProvider key={sentinel}>
      <GoldmanRedirectCheck />
      {isPlayback === true ? (
        <MonacoPlaybackProvider>{InnerEditor}</MonacoPlaybackProvider>
      ) : (
        InnerEditor
      )}
      <Dialog open={reloadDialogOpen}>
        <DialogTitle>
          <Typography component="div" variant="h6" color="text.primary">
            Editor syncing disconnected
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your editor has gotten out of sync. Other participants will not see your changes and any
            new changes will not be saved. Click "Reload Editor" below to reconnect your editor and
            ensure that your edits are saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={() => setReloadDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={reloadEditor}>
            Reload Editor
          </Button>
        </DialogActions>
      </Dialog>
    </MonacoProvider>
  )
}
export default MonacoEditor
