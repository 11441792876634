import Monaco from '@codingame/monaco-editor-react'
import { Box, FormControlLabel, Switch } from '@mui/material'
import React, { useMemo, useState } from 'react'

export interface ResponseBodyProps {
  bodyType: string
  bodyText: string
}

export const ResponseBody: React.FC<ResponseBodyProps> = ({ bodyType, bodyText }) => {
  const [isPretty, setIsPretty] = useState(true)

  const formattedBodyText = useMemo(() => {
    if (bodyType === 'json' && isPretty && bodyText) {
      try {
        const parsed = JSON.parse(bodyText)
        return JSON.stringify(parsed, null, 2)
      } catch (e) {
        return bodyText
      }
    }
    return bodyText
  }, [bodyType, bodyText, isPretty])

  return (
    <>
      {bodyType === 'json' && (
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Switch
              size="small"
              checked={isPretty}
              onChange={(e) => setIsPretty(e.target.checked)}
              inputProps={{ 'data-testid': 'request-client-response-body-pretty' } as any}
            />
          }
          label="Pretty"
        />
      )}
      <Box minHeight={40} mt={2} flex="1 1 auto" data-testid="request-client-response-body-text">
        <Monaco
          programmingLanguage={bodyType}
          value={formattedBodyText}
          ref={(editor) => {
            if (editor != null) {
              editor
                .getContainerDomNode()
                .setAttribute('data-testid', 'request-client-response-body-monaco')
              $(editor.getContainerDomNode()).data('editor', editor)
            }
          }}
          options={{
            readOnly: true,
          }}
        />
      </Box>
    </>
  )
}
