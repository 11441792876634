import classnames from 'classnames'
import Cookies from 'js-cookie'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValues } from '../dashboard/components/PadContext/PadContext'
import BannerButton from './banner_button'
import { track } from './coderpad_analytics'
// @ts-ignore This is a erb file.
import DemoBanner from './demo_banner'
import PadFooter from './Footer/PadFooter'
import PadFooterMultitrackPlayback from './Footer/PadFooterMultitrackPlayback'
import LoginModal from './login_modal'
import MultifileModal from './multifile_modal'
import Notifications from './Notifications/Notifications'
import PadBanner from './pad_banner'
import { PrivacyBanner } from './PrivacyBanner'
import { clearEditorError } from './reducers/editorStatus'
import { HIDE_CANDIDATE_OVERLAY_COOKIE_NAME } from './reducers/user_state'
import SandboxBanner from './Sandbox/Banner'
import SandboxSwitcher from './Sandbox/Switcher'
import { WelcomeModal as SandboxWelcomeModal } from './Sandbox/WelcomeModal'
import { useTranscriberContext } from './Transcriber/ThanscriberContext/TranscriberContext'
import CallRoot from './video/call_root'
import { CC } from './video/ClosedCaptions'
import { WelcomeCandidate } from './WelcomeCandidate'
import { Workspace } from './Workspace/Workspace'

interface PadComponentProps {
  hasEnvironments: boolean
  iioLayout: boolean
  isDemoPad: boolean
  isExpired: boolean
  isLoggedIn: boolean
  isOwner: boolean
  isPlayback: boolean
  isSandbox: boolean
}

export function Pad({
  hasEnvironments,
  iioLayout,
  isDemoPad,
  isExpired,
  isLoggedIn,
  isOwner,
  isPlayback,
  isSandbox,
}: PadComponentProps) {
  const dispatch = useDispatch()

  const [expiredBannerHidden, setExpiredBannerHidden] = useState(false)

  const darkColorScheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const showCandidateOverlay = useSelector((state) => state.userState.showCandidateOverlay)
  const showMultifileModal = useSelector((state) => state.multifile.modalVisible)
  const username = useSelector((state) => state.userState.uncommittedUsername)
  const isPadPrivate = !useSelector((state) => state.padSettings.isPublic)
  const hasEditorError = useSelector((state) => state.editorStatus.hasErrorOccurred)
  const callMaximized = useSelector((state) => state.call.maximized)
  const callStatus = useSelector((state) => state.call.status)

  const { closedCaptionsEnabled } = useTranscriberContext()

  const { takeHome, isQuestionDraftPreview } = usePadConfigValues(
    'takeHome',
    'isQuestionDraftPreview'
  )

  const handleCandidateOverlayHide = useCallback(() => {
    if (!takeHome) {
      Cookies.set(HIDE_CANDIDATE_OVERLAY_COOKIE_NAME, 'true', { expires: 14 })
    }
    dispatch({ type: 'candidate_overlay_hidden' })
  }, [dispatch, takeHome])

  const handleExpiredBannerHide = useCallback(() => {
    setExpiredBannerHidden(true)
  }, [])

  const handleEditorReload = useCallback(() => {
    dispatch(clearEditorError())
    track('Banner editor reload clicked')
  }, [dispatch])

  const onMakePublic = useCallback(() => {
    dispatch({
      type: 'pad_setting_changed',
      key: 'isPublic',
      value: true,
    })
  }, [dispatch])

  const searchParams = new URLSearchParams(location?.search)
  const questionId = searchParams.get('question_id') ?? undefined
  const useQuestionButton = searchParams.has('use_question_button')

  const footer =
    iioLayout || isQuestionDraftPreview ? null : isPlayback ? (
      <PadFooterMultitrackPlayback />
    ) : (
      <PadFooter />
    )

  const urlParams = new URLSearchParams(window.location.search)
  const isFullScreenView = urlParams.has('drawing') || urlParams.has('instructions')

  let modalContent
  if (isPlayback || iioLayout) modalContent = null
  else if (username == null) modalContent = <LoginModal />
  else if (showCandidateOverlay)
    modalContent = (
      <WelcomeCandidate
        darkBackground={darkColorScheme}
        onHide={handleCandidateOverlayHide}
        hasEnvironments={hasEnvironments}
      />
    )
  else if (!isFullScreenView) modalContent = <CallRoot />

  let banner
  let hasOtherBanner = false
  if (isDemoPad && !isPlayback) {
    banner = <DemoBanner />
    hasOtherBanner = true
  } else if (isSandbox && !isLoggedIn && !iioLayout) {
    banner = <SandboxBanner useQuestionButton={useQuestionButton} questionId={questionId} />
    hasOtherBanner = true
  } else if (isExpired) {
    banner = (
      <PadBanner onHide={handleExpiredBannerHide} visible={!expiredBannerHidden} warning={true}>
        Pads created by free plan users are locked after 4 days. For permanent pads, please{' '}
        <a href="/billing/plans">upgrade</a>.
      </PadBanner>
    )
    hasOtherBanner = !expiredBannerHidden
  } else if (!isSandbox && isOwner && !isPlayback) {
    const currentUrl = window.location.href

    banner = <PrivacyBanner onMakePublic={onMakePublic} visible={isPadPrivate} />
    hasOtherBanner = isPadPrivate && !currentUrl.includes('?drawing')
  }

  const editorErrorBanner = (
    <PadBanner onHide={clearEditorError} visible={hasEditorError} warning={true}>
      <span>Your editor is out of sync. Click to reload the editor.</span>
      <BannerButton onClick={handleEditorReload}>Reload Editor</BannerButton>
    </PadBanner>
  )

  const showCC = closedCaptionsEnabled && !callMaximized && callStatus === 'in_call'

  return (
    <div
      className={classnames('PadSpace', {
        hasEditorBanner: hasEditorError,
        hasOtherBanner,
      })}
    >
      {editorErrorBanner}
      {banner}
      {showCC && <CC />}
      <Workspace />
      <Notifications />
      <SandboxSwitcher />
      <SandboxWelcomeModal />
      {!isFullScreenView && footer}
      {modalContent}
      {showMultifileModal && <MultifileModal />}
    </div>
  )
}
