import PropTypes from 'prop-types'
import React from 'react'
import { formatDistanceToNow } from 'date-fns'

import BannerButton from './banner_button'
import PadBanner from './pad_banner'
import getDemoTimeLimit from './demo_time_limit'

export default class DemoBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hidden: false, timeLeft: this._getTimeLeft() }
    setInterval(() => this.setState({timeLeft: this._getTimeLeft()}), 1000)
  }

  _getTimeLeft() {
    return formatDistanceToNow(getDemoTimeLimit(), { addSuffix: true })
  }

  handleHide = () => {
    this.setState({ hidden: true })
  }

  render() {
    return (
      <PadBanner
        delayAppearanceSeconds={30}
        onHide={this.handleHide}
        visible={!this.state.hidden}
      >
        <img
          className="DemoBanner-cp-logo"
          src="https://d1a7p14oqam61r.cloudfront.net/assets/coderpad_logo_32x32-24181f5cf210bb0b684fa1e96da1c24461217cb84133a77756c3dca6269ca411.png"
        />
        <span>Try CoderPad free for 14 days</span>
        <BannerButton href="/register">
          Start Free Trial
        </BannerButton>
        <span className="DemoBanner-expiryWarning">(this demo expires {this.state.timeLeft})</span>
      </PadBanner>
    )
  }
}
