import { styled } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectOnlineUsers } from '../selectors'
import { useTranscripts } from '../Transcriber/hooks/useTranscripts'
import { useTranscriberContext } from '../Transcriber/ThanscriberContext/TranscriberContext'
import { Transcript, TranscriptEntryKind } from '../Transcriber/types'

const Wrapper = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  bottom: 100,
  left: 0,
  right: 0,
  width: '100%',
  height: 75,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
  zIndex: 100,
  flexDirection: 'row',
  pointerEvents: 'none',
})

// should be styled like closed \tions...
// black background around text
// and white text
const StyledText = styled('span')({
  display: 'block',
  fontFamily: 'Helvetica',
  fontSize: 21,
  fontWeight: 400,
  textShadow: '2px 2px 0px black',
  letterSpacing: 1,
  padding: 8,
  backgroundColor: '#0000006e',
  color: 'white',
  backdropFilter: 'blur(5px)',

  webkitBoxDecorationBreak: 'clone',
  boxDecorationBreak: 'clone',
  lineHeight: 1.5,

  maxWidth: 250,
})

export const Text: FC<{ transcript: Transcript }> = ({ transcript }) => {
  const users = useSelector(selectOnlineUsers)

  const user = useMemo(() => Object.values(users).find(({ id }) => id === transcript.userId), [
    transcript.userId,
    users,
  ])

  const [show, setShow] = useState(true)

  const currentTranscript = useMemo(
    () => transcript?.transcripts[transcript.preferredTranscript ?? 0] ?? '',
    [transcript.transcripts, transcript.preferredTranscript]
  )

  /**
   * Returns whether a transcript should be visible or not.
   * This is determined based on:
   * - if the timestamp is within the last 2 seconds
   */
  const checkVisible = useCallback(() => {
    if (transcript.timestamp + 2000 > Date.now()) {
      return true
    }
    return false
  }, [transcript.timestamp])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (currentTranscript) {
      const isVisible = checkVisible()
      if (!isVisible) {
        setShow(false)
        return
      }

      interval = setInterval(() => {
        const visible = checkVisible()
        setShow(visible)

        if (!visible) {
          clearInterval(interval)
        }
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [checkVisible, currentTranscript, show])

  return (
    <div style={{ flex: 1, display: 'inline-flex', justifyContent: 'center' }}>
      {show && (
        <StyledText>
          <span style={{ color: user?.color }}>{user?.name}</span>: {currentTranscript}
        </StyledText>
      )}
    </div>
  )
}

/**
 * This component will auto-hide after 2 seconds of no new text
 */
export const CC: FC = () => {
  const users = useSelector(selectOnlineUsers)
  const isPlayback = usePadConfigValue('isPlayback')
  const transcripts = useTranscripts()

  const { closedCaptionsEnabled } = useTranscriberContext()

  const currentTranscripts = useMemo(
    () =>
      (transcripts
        .sort((a, b) => b.timestamp - a.timestamp)
        .filter(
          (transcript) => transcript.kind === TranscriptEntryKind.Transcript
        ) as Transcript[]).reduce((acc, transcript) => {
        if (!acc.find(({ userId }) => userId === transcript.userId)) {
          acc.push(transcript)
        }
        return acc
      }, [] as Transcript[]),
    [transcripts]
  )

  if (isPlayback || !closedCaptionsEnabled) {
    return null
  }

  return (
    <Wrapper>
      {Object.values(users)
        .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))
        .map((user) => {
          const transcript = currentTranscripts.find(({ userId }) => userId === user.id)
          return transcript && <Text key={transcript.id} transcript={transcript} />
        })}
    </Wrapper>
  )
}
