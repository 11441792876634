import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

class _TakeHomePanel extends React.Component {
  static propTypes = {
    serverTimeOffset: PropTypes.number,
    openedAt: PropTypes.number,
    takeHomeTimeLimit: PropTypes.number,
    startedAt: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this._timerRef = React.createRef()
    this.timerInterval = null
    this.state = { timeElapsedMin: 0 }
  }

  componentDidMount() {
    const { openedAt, startedAt, takeHomeTimeLimit } = this.props
    const title = takeHomeTimeLimit
      ? `This is the length of time since you started. You have ${takeHomeTimeLimit} minutes total to complete this take-home.`
      : 'This is the length of time since you started. You have no time limit for this take-home.'
    $(this._timerRef.current).tooltip({
      trigger: 'hover manual',
      placement: 'top',
      title: title,
    })
    if (openedAt || startedAt) {
      this.timerLoop()
    }
  }

  componentWillUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.openedAt || this.props.startedAt) !==
        (prevProps.openedAt || prevProps.startedAt) &&
      !this.timerInterval
    ) {
      this.timerLoop()
    }
  }

  timerLoop = () => {
    const timeElapsedMin =
      this.props.openedAt || this.props.startedAt
        ? parseInt(
            (new Date().getTime() / 1000 -
              (this.props.openedAt || this.props.startedAt) +
              this.props.serverTimeOffset) /
              60
          )
        : 0
    this.setState({ timeElapsedMin })
    this.timerInterval = setTimeout(this.timerLoop, 10000)
  }

  render() {
    const timeElapsedMin = this.state.timeElapsedMin
    return (
      <div className="TakeHomePanel" style={{ whiteSpace: 'nowrap' }}>
        <span className="TakeHomePanel-timer" ref={this._timerRef}>
          {
            // If the take-home already started, then this timer is visible.
            // Candidates can always see the timer element so its help
            // tooltip is visible in the welcome/help overlay,
            this.props.openedAt || this.props.startedAt || !window.padConfig.isOwner ? (
              <>
                Time elapsed: {timeElapsedMin} minute
                {timeElapsedMin === 0 || timeElapsedMin > 1 ? 's' : ''}{' '}
                {this.props.takeHomeTimeLimit
                  ? ` (of ${this.props.takeHomeTimeLimit} minutes)`
                  : ''}
              </>
            ) : (
              ''
            )
          }
        </span>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { serverTimeOffset, takeHomeTimeLimit, openedAt, startedAt } = state.padSettings
  return {
    serverTimeOffset,
    takeHomeTimeLimit,
    openedAt,
    startedAt,
  }
}

const TakeHomePanel = connect(mapStateToProps)(_TakeHomePanel)
export default TakeHomePanel
