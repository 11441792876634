import firebase from 'firebase/compat/app'
import { useEffect, useReducer } from 'react'

import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import SyncHandle from '../../sync_handle'
import { IUser, PadUser } from '../FilePane/utils/types'

export function useFirebaseUsers(padUserId: string) {
  const { files } = useActiveEnvironment()
  const [usersByFile, localDispatch] = useReducer(fileUsersReducer, {})

  // Effect to watch the users on the files in the active environment and update the state.
  useEffect(() => {
    // Array of tuples. Tuples are `[<firebase path to file users>, <sync handle watch callback, for that path>]`.
    const callbacks: [string, (snap: firebase.database.DataSnapshot) => void][] = []

    if (files.length > 0) {
      files.forEach((file) => {
        const fileUsersPath = `${file.firebasePath}/users`

        callbacks.push([
          fileUsersPath,
          SyncHandle().watch(fileUsersPath, (users: Record<string, IUser>) => {
            const fileUsers: PadUser[] = []

            if (users) {
              Object.keys(users).forEach((userId) => {
                const user = users[userId]

                if (user?.cursor) {
                  fileUsers.push({
                    id: userId,
                    color: user.color,
                    cursorPosition: user.cursor?.position,
                  })
                }
              })
            }

            localDispatch(fileUsersUpdate(file.id, fileUsers))
          }),
        ])
      })
    }

    return () => {
      callbacks.forEach((cb) => {
        SyncHandle().off(cb[0], cb[1])
      })
    }
  }, [files])

  return {
    usersByFile,
    updateFileUser: (fileId: string, fileUser: PadUser) =>
      localDispatch(fileUserUpdate(fileId, fileUser)),
  }
}

enum UserActionTypes {
  FileUsersChange = 'FILE_USERS_CHANGE',
  FileUserChange = 'FILE_USER_CHANGE',
}

type UserActions = fileUsersUpdateReturn | fileUserUpdateReturn

interface FileUsersState {
  [fileId: string]: PadUser[]
}

function fileUsersReducer(state: FileUsersState = {}, action: UserActions) {
  if (action.type === UserActionTypes.FileUsersChange) {
    const { fileId, users } = action.payload
    const newState = { ...state }

    newState[fileId] = users

    return newState
  } else if (action.type === UserActionTypes.FileUserChange) {
    const { fileId, user } = action.payload
    const fileUsers = state[fileId]
    if (fileUsers) {
      const newFileUsers = [...fileUsers].filter((u) => u.id !== user.id)
      newFileUsers.push(user)
      return { ...state, [fileId]: newFileUsers }
    }
  }

  return state
}

type fileUsersUpdateReturn = {
  type: UserActionTypes.FileUsersChange
  payload: { fileId: string; users: PadUser[] }
}
function fileUsersUpdate(fileId: string, fileUsers: PadUser[]): fileUsersUpdateReturn {
  return {
    type: UserActionTypes.FileUsersChange,
    payload: {
      fileId,
      users: fileUsers,
    },
  }
}

type fileUserUpdateReturn = {
  type: UserActionTypes.FileUserChange
  payload: { fileId: string; user: PadUser }
}
function fileUserUpdate(fileId: string, fileUser: PadUser): fileUserUpdateReturn {
  return {
    type: UserActionTypes.FileUserChange,
    payload: {
      fileId,
      user: fileUser,
    },
  }
}
