import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import SpinnerPulse from '../spinner_pulse'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    border: 0,
    flexGrow: 1,
    position: 'relative',
    background: theme.palette.programOutput?.iFrame.background,
    color: '#363739',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'block',
    textAlign: 'center',
  },
  spinnerWrap: {
    position: 'relative',
    height: '50px',
  },
}))

export interface ServiceStatusProps {
  spinner?: boolean
  message?: string | null
}

export const ServiceStatus: React.FC<ServiceStatusProps> = ({
  spinner = false,
  message = null,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {spinner && (
          <div className={styles.spinnerWrap}>
            <SpinnerPulse />
          </div>
        )}
        {message != null && <div dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </div>
  )
}
