import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GlobalEvent, GlobalEventTypes } from '../../playback/GlobalEvents/GlobalEventTypes'
import { useRealtimeGlobalEvents } from '../../playback/GlobalEvents/useRealtimeGlobalEvents'
import { enqueueNotif } from '../../reducers/notifications'
import { SingleUserInfo } from '../../reducers/user_state'
import { selectUserInfo } from '../../selectors'
import { StateNotifierProps } from './StateNotifier'

interface GlobalEventNotifications
  extends StateNotifierProps<{
    user: SingleUserInfo
    event: GlobalEvent
  }> {
  event: GlobalEventTypes
}

export const GlobalEventNotifications: FC<GlobalEventNotifications> = ({
  event,
  notification,
  shouldNotify,
}) => {
  const users = useSelector(selectUserInfo)
  const dispatch = useDispatch()

  const handleCheatingEvent = useCallback(
    (event: GlobalEvent) => {
      const user = users[event.user]
      const payload = { user, event }

      if (shouldNotify && !shouldNotify(payload)) {
        return
      }

      if (user) {
        dispatch(enqueueNotif({ ...notification(payload) }))
      }
    },
    [users, shouldNotify, dispatch, notification]
  )

  useRealtimeGlobalEvents(event, handleCheatingEvent)

  return null
}
