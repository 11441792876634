import React from 'react'
import { channel } from 'redux-saga'
import { put, select, takeEvery } from 'redux-saga/effects'

import { enqueueNotif, removeNotif, removeNotifByClassification } from '../reducers/notifications'
import { padSettingChanged } from '../reducers/pad_settings'
import {
  selectActiveTab,
  selectDrawingModeOpen,
  selectMyUserInfo,
  selectPadSettings,
  selectUserInfo,
} from '../selectors'
import trackEvent from '../track_event'

// This is a channel being used to get around supporting an "onclick" callback for a notification that needs
// to dispatch a Redux action. Using this, a notification can include a callback that puts an action.
const notificationOnClickProxy = channel()

/**
 * Translate certain events into notification actions.
 */
export default function* notificationSaga() {
  let hasExecuteDisconnected = false
  let hasFirepadDisconnected = false
  let candidateInstructionsVisibility = []
  yield takeEvery('connection_status_changed', function* (action) {
    if (!action.isOnline) {
      hasFirepadDisconnected = true
    }
    // Only dispatch a notification if it is a disconnection or it is a reconnection after
    // a disconnection has occurred.
    if (!action.isOnline || hasFirepadDisconnected) {
      yield put(
        enqueueNotif({
          message: action.isOnline
            ? 'Editor syncing connected.'
            : 'Editor syncing disconnected. Other people may not see your changes.',
          key: 'firebase_connection_status_msg',
          autoDismissMs: action.isOnline ? 2000 : 0,
          variant: action.isOnline ? 'info' : 'error',
        })
      )
    }
  })

  yield takeEvery('execution_connected', function* () {
    // Only dispatch the notification if execute has previously disconnected.
    if (hasExecuteDisconnected) {
      yield put(
        enqueueNotif({
          message: 'Connected to code execution environment.',
          key: 'execution_connection_msg',
          autoDismissMs: 2000,
          variant: 'info',
        })
      )
    }
  })

  yield takeEvery('execution_connection_failed', function* () {
    hasExecuteDisconnected = true
    yield put(
      enqueueNotif({
        message:
          'Disconnected from code execution environment. You may not be able to run your code.',
        key: 'execution_connection_msg',
        autoDismissMs: 0,
        variant: 'error',
      })
    )
  })

  yield takeEvery('execution_connection_failed_max_clients', function* () {
    yield put(
      enqueueNotif({
        message:
          'You cannot run your code or interact with the execution environment because there are too many participants. Please wait for them to leave, then reload the page, or contact support@coderpad.io for assistance.',
        key: 'execution_connection_msg',
        autoDismissMs: 0,
        variant: 'error',
      })
    )
  })

  // Notify pad owners when a candidate leaves the pad if the pad is private.
  // This should instruct interviewers that they will need to make the pad public for the candidate to re-enter.
  yield takeEvery('user_removed', function* ({ userObj }) {
    const myUserInfo = yield select(selectMyUserInfo)
    const padSettings = yield select(selectPadSettings)
    const isPrivatePad = !padSettings.isPublic

    if (
      userObj &&
      isPrivatePad &&
      myUserInfo.isOwner &&
      !userObj.isOwner &&
      userObj.id !== myUserInfo.id
    ) {
      yield put(
        enqueueNotif({
          message: (
            <>
              {userObj.name || 'Candidate'} has left the pad. Click here to make the pad public so
              they can re-enter.
            </>
          ),
          key: `userLeft-${userObj.id}`,
          variant: 'error',
          classification: 'userLeft',
          onClick: () => notificationOnClickProxy.put(padSettingChanged('isPublic', true)),
        })
      )
    }
  })

  // For any event on this channel, dispatch its payload as a redux action.
  yield takeEvery(notificationOnClickProxy, function* (padSettingAction) {
    yield put(padSettingAction)
  })

  yield takeEvery('pad_setting_changed', function* ({ key, value, userId, previousValue }) {
    if (key === 'isPublic' && value) {
      yield put(removeNotifByClassification('userLeft'))
    } else if (
      key === 'language' &&
      value &&
      previousValue != null &&
      previousValue !== 'plaintext'
    ) {
      const userInfo = yield select(selectUserInfo)
      const user = userInfo[userId]
      const newLangDisplay = CoderPad.LANGUAGES[value]?.display
      const prevLangDisplay = CoderPad.LANGUAGES[previousValue]?.display

      let message = ''
      message += user?.self ? 'You switched' : 'The Pad was switched'
      message += ` to the ${newLangDisplay} environment.  To view your previous code, switch back to the ${prevLangDisplay} environment.`

      yield put(
        enqueueNotif({
          message: message,
          key: 'languageChange',
          variant: 'info',
          autoDismissMs: 5000,
        })
      )
    }
  })

  yield takeEvery('question_selected_by_local_user', function* ({ language, padLanguage }) {
    if (language !== padLanguage) {
      const newLangDisplay = CoderPad.LANGUAGES[language]?.display
      const prevLangDisplay = CoderPad.LANGUAGES[padLanguage]?.display
      const message = `You loaded a question into the ${newLangDisplay} environment.  To view your previous code, switch back to the ${prevLangDisplay} environment.`

      yield put(
        enqueueNotif({
          message: message,
          key: 'languageChange',
          variant: 'info',
          autoDismissMs: 5000,
        })
      )
    }
  })

  yield takeEvery('network_quality_video_degraded', function* () {
    yield put(
      enqueueNotif({
        message: 'Try disabling video to improve call quality.',
        key: 'network_quality_changed',
        variant: 'info',
        classification: 'videoQuality',
      })
    )
  })

  yield takeEvery('project/request_error', function* ({ message }) {
    yield put(
      enqueueNotif({
        message,
        key: 'execute_request_error',
        variant: 'error',
        autoDismissMs: 5000,
      })
    )
  })

  // determine when to show a notification to the user that new candidate instructions are available to them.
  // don't show the notification upon initial loading of the question, or when an instruction step is being
  // toggled as invisible.
  yield takeEvery('instructions_added', function* ({ candidateVisibilitySettings }) {
    let showNotification = false
    const currTab = yield select(selectActiveTab)
    showNotification = currTab !== 'candidateInfo'

    if (candidateInstructionsVisibility.length === 0) {
      showNotification = false
    } else if (
      candidateInstructionsVisibility.filter((i) => i).length >=
      candidateVisibilitySettings.filter((i) => i).length
    ) {
      showNotification = false
    }

    candidateInstructionsVisibility = candidateVisibilitySettings

    if (showNotification) {
      yield put(
        enqueueNotif({
          message: 'New instructions are available to you.  Click here to view them.',
          key: 'new_instructions_available',
          variant: 'info',
          autoDismissMs: 5000,
          onClick: () => {
            notificationOnClickProxy.put({ type: 'tab_selected', tab: 'candidateInfo' })
          },
        })
      )
    }
  })

  // `user_changed` actions are dispatched on every cursor change for a user. If we were to take the action at face
  // value, it would cause the DM notification to show on every cursor change for a remote user. This means that we
  // need to check if the incoming action is signalling a a change in the drawing mode openness for a remote user.
  // To fix that case, we need to keep a set of user ids that have drawing mode open that we can check in the saga.
  // We cannot use the values in the Redux store to see if the user whose data was updated already had drawing mode
  // open, because the user_changed action has already been processed by the reducers.
  const usersWithDrawingModeOpen = new Set()
  yield takeEvery('user_changed', function* ({ userObj }) {
    const myUserInfo = yield select(selectMyUserInfo)
    const drawingModeOpen = yield select(selectDrawingModeOpen)
    const remoteUserDMOpen = usersWithDrawingModeOpen.has(userObj.userId)

    if (
      !drawingModeOpen &&
      userObj.userId !== myUserInfo.id &&
      userObj.drawingModeOpen &&
      !remoteUserDMOpen &&
      userObj.name
    ) {
      yield put(
        enqueueNotif({
          message: `${userObj.name} has entered drawing mode. Click here to join them.`,
          key: `userStartedDrawing-${userObj.userId}`,
          variant: 'info',
          autoDismissMs: 5000,
          onClick: () => {
            notificationOnClickProxy.put({ type: 'drawing_mode_toggled', isOpen: true })
            trackEvent('Drawing Mode Modal Open')
          },
        })
      )
    }

    if (userObj.drawingModeOpen) {
      usersWithDrawingModeOpen.add(userObj.userId)
    } else {
      usersWithDrawingModeOpen.delete(userObj.userId)
    }
  })

  yield takeEvery(['invited_to_call', 'intent_to_start_call_expressed'], function* () {
    yield put(removeNotif('transcription_call_message'))
  })
}
