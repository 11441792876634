import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Modal as MuiModal,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Cookies from 'js-cookie'
import sample from 'lodash/sample'
import { IPadConfig } from 'packs/dashboard/components/PadContext/padConfig'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { validateEmail } from 'utils/validateEmail'

import { ReactComponent as CoderPadLogo } from '../../../assets/images/coderpad_logo_2020_reverse_white.svg'
import { OrganizationLogo } from '../waiting_room/components/OrganizationLogo/OrganizationLogo'
import { humanizeMinutesToHours } from './time_helper'

const FormSection = styled('div')(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? theme.palette.annualPlans.unselectedToggle
      : theme.palette.divider
  }`,
  borderRadius: 8,
  display: 'flex',
  flex: '0 1 400px',
  flexDirection: 'column',
  padding: theme.spacing(5),
}))

export interface LoginModalProps {
  logoUrl: IPadConfig['logoUrl']
  onLogin: (name: string, email: string) => void
  padOrg: IPadConfig['padOrg']
  takeHome: boolean
  takeHomeTimeLimit?: number
}

export const LoginModalForm: FC<LoginModalProps> = React.forwardRef(
  ({ logoUrl, onLogin, padOrg, takeHome, takeHomeTimeLimit }, ref) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [isTosChecked, setIsTosChecked] = useState(false)
    const takeHomeTimeLimitHuman = humanizeMinutesToHours(takeHomeTimeLimit)

    const handleTosChange = useCallback(() => {
      setIsTosChecked((prevIsTosChecked) => !prevIsTosChecked)
    }, [])

    const orgName = useMemo(() => {
      if (padOrg != null) {
        if (padOrg.name != null && !padOrg.name.includes('Tesla')) {
          return padOrg.name
        } else {
          return 'Tesla'
        }
      } else {
        return null
      }
    }, [padOrg])

    const isNameValid = useMemo(() => {
      return !!name.trim()
    }, [name])

    const isEmailValid = useMemo(() => {
      return validateEmail(email)
    }, [email])

    const isFormValid = useMemo(() => isEmailValid && isNameValid && isTosChecked, [
      isEmailValid,
      isNameValid,
      isTosChecked,
    ])

    const disabledButtonTooltip = useMemo(() => {
      if (!isEmailValid) {
        return 'The email address you provided is invalid'
      } else if (!isNameValid) {
        return 'Please enter your name'
      }
      return ''
    }, [isEmailValid, isNameValid])

    const login = () => {
      if (isFormValid) {
        onLogin(name, email)
      } else {
        setError(true)
      }
    }

    const submitOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        login()
        e.preventDefault()
      }
    }

    const namePlaceholder = useMemo(
      () =>
        `Name e.g., ${sample(['Ada Lovelace', 'Alan Turing', 'Edsger Dijkstra', 'Grace Hopper'])}`,
      []
    )

    const takeHomeTimeLimitText = useMemo(
      () =>
        takeHomeTimeLimitHuman ? (
          <>
            <Typography>
              We want to be respectful of your schedule, so you have a limited amount of time to
              complete the assignment. Your timer will start when you click the Let&rsquo;s Get
              Started! button on the next screen.
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Time Limit: {takeHomeTimeLimitHuman}
            </Typography>
          </>
        ) : (
          <Typography>
            This take-home has no time limit. Please remember to submit your take-home when
            finished.
          </Typography>
        ),
      [takeHomeTimeLimitHuman]
    )

    return (
      <Box
        ref={ref}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          paddingX: 4,
          textAlign: 'center',
          '& *': {
            boxSizing: 'border-box',
          },
        }}
      >
        <Stack alignItems="center" spacing={10} sx={{ flex: 1, paddingTop: 12 }}>
          <Stack spacing={3}>
            {!!logoUrl && <OrganizationLogo isInline logoUrl={logoUrl} />}
            <Typography color="inherit" sx={{ fontSize: 32, fontWeight: 600 }}>
              Welcome to your interview{orgName == null ? '' : ` at ${orgName}`}!
            </Typography>
            {takeHome && (
              <>
                <Typography>
                  This is a take-home coding challenge. You may start working on it whenever it's
                  convenient for you.
                </Typography>
                {takeHomeTimeLimitText}
                <Typography>Enter your information to get your interview started.</Typography>
              </>
            )}
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            justifyContent={{
              xs: 'flex-start',
              md: takeHome ? 'center' : 'space-between',
            }}
            sx={{
              flex: '0 0 auto',
              maxWidth: { xs: 400, md: 960 },
              overflow: 'auto',
              width: '100%',
            }}
          >
            <FormSection>
              <Stack spacing={4}>
                {!takeHome && (
                  <Stack
                    alignItems="flex-start"
                    spacing={2.5}
                    sx={{ fontSize: '1rem', fontWeight: 400, textAlign: 'left' }}
                  >
                    <>
                      <Typography color="inherit" variant="h2">
                        Joining as a candidate?
                      </Typography>
                      <Typography>Enter your name and email to join the interview.</Typography>
                    </>
                  </Stack>
                )}
                <Stack spacing={2.5}>
                  <TextField
                    autoFocus
                    data-testid="NameInput"
                    error={error}
                    InputProps={{ sx: { height: 42 } }}
                    margin="normal"
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={submitOnEnter}
                    placeholder={namePlaceholder}
                    sx={{ margin: 0 }}
                    value={name}
                    variant="outlined"
                  />
                  <TextField
                    data-testid="EmailInput"
                    error={error}
                    InputProps={{ sx: { height: 42 }, type: 'email' }}
                    margin="normal"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={submitOnEnter}
                    placeholder="Email address"
                    value={email}
                    variant="outlined"
                  />
                </Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTosChecked}
                      name={name}
                      id={name}
                      size="medium"
                      sx={{ marginLeft: '-9px', marginTop: '-9px' }}
                      onChange={handleTosChange}
                    />
                  }
                  data-testid="TosCheckbox"
                  label={
                    <Typography
                      sx={{
                        textAlign: 'left',
                        '& a': {
                          color: 'inherit',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      I accept these{' '}
                      <a target="_blank" href="/tos">
                        terms
                      </a>{' '}
                      and acknowledge that I have read the{' '}
                      <a target="_blank" href="/privacy">
                        privacy policy
                      </a>{' '}
                      and understand that my data is not sold to third parties.
                    </Typography>
                  }
                  sx={{ alignItems: 'flex-start' }}
                />
                <Tooltip title={disabledButtonTooltip} placement="top" arrow>
                  <Button
                    data-testid="LoginButton"
                    onClick={login}
                    variant="contained"
                    sx={{ borderRadius: 100, fontSize: '1rem' }}
                    disabled={!isFormValid}
                  >
                    Join
                  </Button>
                </Tooltip>
                <Typography sx={{ textAlign: 'left' }}>
                  First time here? Read our{' '}
                  <Link
                    href="https://coderpad.io/getting-started-candidates"
                    rel="noreferrer"
                    sx={{ textDecoration: 'none' }}
                    target="_blank"
                  >
                    quick guide
                  </Link>
                  {takeHome && (
                    <>
                      {' '}
                      or try the{' '}
                      <Link
                        href="/sandbox"
                        rel="noreferrer"
                        sx={{ textDecoration: 'none' }}
                        target="_blank"
                      >
                        sandbox
                      </Link>
                    </>
                  )}
                  .
                </Typography>
              </Stack>
            </FormSection>
            {padOrg && !takeHome && (
              <FormSection
                sx={{
                  marginLeft: (theme) => ({ md: theme.spacing(4) }),
                  marginTop: (theme) => ({ xs: theme.spacing(4), md: 0 }),
                }}
              >
                <Stack spacing={4} sx={{ flex: 1 }}>
                  <Stack
                    alignItems="flex-start"
                    spacing={2.5}
                    sx={{ fontSize: '1rem', fontWeight: 400, textAlign: 'left' }}
                  >
                    <Typography color="inherit" variant="h2">
                      Work for {orgName}?
                    </Typography>
                    <Typography>
                      Log into your account to access private notes and options to manage the
                      interview.
                    </Typography>
                  </Stack>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <Box sx={{ alignItems: 'center', display: 'flex', marginTop: 'auto' }}>
                      {!padOrg.idpEnabled && padOrg.seededEmail && (
                        <>
                          <Button
                            variant="contained"
                            sx={{ borderRadius: 100, fontSize: '1rem' }}
                            href={`https://${location.host.replace('app.', '')}/register/?email=${
                              padOrg.seededEmail
                            }&previous_url=${location.href}`}
                          >
                            Register
                          </Button>
                          <Typography sx={{ color: '#7a8b99', marginX: 2 }}>or</Typography>
                        </>
                      )}
                      <Button
                        variant="contained"
                        sx={{ borderRadius: 100, flex: 1, fontSize: '1rem' }}
                        href={
                          padOrg.idpEnabled
                            ? `/saml/init?issuer=${padOrg.idpIssuer}&previous_url=${location.href}`
                            : `/login?previous_url=${location.href}&interviewer_login=${padOrg.id}`
                        }
                      >
                        Log in
                      </Button>
                    </Box>
                  </Box>
                </Stack>
              </FormSection>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            alignSelf: { xs: 'center', md: 'flex-end' },
            marginTop: 'auto',
            paddingTop: 12,
            '& svg': {
              width: 160,
            },
          }}
        >
          <CoderPadLogo />
        </Box>
      </Box>
    )
  }
)

const Modal = styled(MuiModal)(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background[50] : 'white',
  },
}))

export const LoginModal: FC = () => {
  const { logoUrl, padOrg, slug, takeHome, takeHomeTimeLimit } = usePadConfigValues(
    'logoUrl',
    'padOrg',
    'slug',
    'takeHome',
    'takeHomeTimeLimit'
  )
  const dispatch = useDispatch()

  const onLogin = (username: string, email: string) => {
    Cookies.set(`pad_${slug}_username`, username)
    Cookies.set(`pad_${slug}_email`, email)

    dispatch({
      type: 'logged_in',
      username,
      email,
    })
  }

  return (
    <Modal open={true}>
      <LoginModalForm
        logoUrl={logoUrl}
        onLogin={onLogin}
        padOrg={padOrg}
        takeHome={takeHome}
        takeHomeTimeLimit={takeHomeTimeLimit}
      />
    </Modal>
  )
}

export default LoginModal
