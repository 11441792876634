import { useCallback } from 'react'
import { LanguageConfiguration } from 'utils/languages'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { EnvironmentSummary } from '../../../Environments/EnvironmentsContext/types'
import SyncHandle from '../../../sync_handle'

export function useSetEnvironmentCustomDBLanguage(environment: EnvironmentSummary) {
  const takeHome = usePadConfigValue('takeHome')

  return useCallback(
    (dbLang: string, dbExampleCode: string) => {
      // Only accept this action for single-file environments, since we have to set the example code into the pad.
      if (takeHome || !environment?.language || environment?.allowMultipleFiles) return

      const langMeta: LanguageConfiguration = window.CoderPad.LANGUAGES[environment.language]
      if (langMeta?.packages) {
        SyncHandle().set(`environments/${environment.id}/customDatabaseLanguage`, dbLang)

        SyncHandle().get<Record<string, boolean>>(
          `environmentFileIds/${environment.slug}`,
          (fileIds = {}) => {
            const targetFileId = Object.keys(fileIds).find((id) => fileIds[id])

            const hlFP = SyncHandle().firepadHeadless(
              `/environmentFiles/${environment.slug}/${targetFileId}`
            )
            hlFP.on('ready', () => {
              hlFP.setText(dbExampleCode)
              hlFP.dispose()
            })
          }
        )
      }
    },
    [environment, takeHome]
  )
}
