import { Box, Slide } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { INotification, removeNotif } from '../reducers/notifications'
import { Notification } from './Notification'

/**
 * Component to display notifications to the user. Selects notifications from the Redux store and renders Notifications
 * in the bottom right corner of the screen.
 */
export default function Notifications() {
  const dispatch = useDispatch()
  const notifs = useSelector((state) => state.notifications) as INotification[]

  // State to track which notifications are currently being dismissed. This is necessary so that the Slide
  // transition can complete before we remove the notification from the Redux store.
  const [dismissing, setDismissing] = useState<string[]>([])

  return (
    // Needs z-index 101 to be on top of maximized video call.
    <Box
      position="absolute"
      bottom={70}
      right={0}
      zIndex={101}
      overflow="hidden"
      paddingRight="20px"
    >
      {notifs.map((notif) => (
        <Slide
          key={notif.key}
          in={!dismissing.includes(notif.key)}
          direction="left"
          mountOnEnter
          unmountOnExit
          // Only remove the notification after it exits so that the slide out transition completes.
          onExited={() => {
            dispatch(removeNotif(notif.key))
            setDismissing(dismissing.filter((key) => key !== notif.key))
          }}
        >
          <div>
            <Notification
              notification={notif}
              requestDismiss={() => setDismissing([...dismissing, notif.key])}
            />
          </div>
        </Slide>
      ))}
    </Box>
  )
}
