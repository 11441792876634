export type TranscriptEntry = Transcript | SystemMessage

export enum TranscriptEntryKind {
  Transcript = 'TRANSCRIPT',
  SystemMessage = 'SYSTEM_MESSAGE',
}

export interface Transcript {
  /**
   * A property to distinguish a transcript from a system message.
   */
  kind: TranscriptEntryKind.Transcript
  /**
   * Unique identifier for the speech chunk.
   */
  id: string
  /**
   * The transcript of the speech chunk. The array will contain
   * multiple interpretations of the same speech chunk, allowing
   * for the interviewer to correct the speech-to-text engine's
   * interpretation and select the preferred transcript.
   */
  transcripts: string[]
  /**
   * The index of the preferred transcript in the `transcripts` array.
   */
  preferredTranscript: number
  /**
   * The timestamp of the speech chunk start time in milliseconds.
   */
  timestamp: number
  /**
   * Duration of recognized speech in 100 nano second increments.
   */
  duration: number
  /**
   * The firebase author id of the user who spoke the speech chunk.
   */
  userId: string
  /**
   * Flags whether the speech chunk is the final interpretation
   * or an interim interpretation. Only the final interpretation
   * will contain multiple interpretations in the `transcripts`
   * array. Interim interpretations will contain only one index.
   */
  isFinal: boolean
}

export interface SystemMessage {
  /**
   * A property to distinguish a system message from a transcript.
   */
  kind: TranscriptEntryKind.SystemMessage
  /**
   * Unique identifier for the system message.
   */
  id: string
  /**
   * Message that the system produced.
   */
  message: string
  /**
   * The timestamp of the system message in milliseconds.
   */
  timestamp: number
}

export interface TranscriptResult {
  text: string
  duration: number
  isFinal?: boolean
}
