import React from 'react'

export interface INotification {
  message: string | React.ReactNode
  key: string
  autoDismissMs?: number
  variant?: 'error' | 'info' | 'success' | 'warning'
  classification?: string
  onClick?: () => void
  title?: string
}

export type NotificationsState = INotification[]

export enum NotificationActions {
  EnqueueNotif = 'ENQUEUE_NOTIFICATION',
  RemoveNotif = 'REMOVE_NOTIFICATION',
  RemoveNotifByClassification = 'REMMOVE_NOTIFICATION_BY_CLASS',
}

type ActionTypes = ReturnType<
  typeof enqueueNotif | typeof removeNotif | typeof removeNotifByClassification
>

/**
 * Reducer for UI notifications. Supports adding, updating, and removing notifications.
 */
export default function reducer(
  state: NotificationsState = [],
  action: ActionTypes
): NotificationsState {
  const { type, payload } = action

  switch (type) {
    case NotificationActions.EnqueueNotif: {
      const notification = payload as INotification
      const idx = state.findIndex((notif) => notif.key === notification.key)
      // Update the notification if it already exists.
      if (idx >= 0) {
        const nwState = [...state]
        nwState.splice(idx, 1, notification)
        return nwState
      }
      return [...state, notification]
    }

    case NotificationActions.RemoveNotif: {
      const key = (payload as { key: string }).key
      return key ? state.filter((notif) => notif.key !== key) : state
    }

    case NotificationActions.RemoveNotifByClassification: {
      const classification = (payload as { classification: string }).classification
      return classification
        ? state.filter((notif) => notif.classification !== classification)
        : state
    }

    default:
      return state
  }
}

export function enqueueNotif(notif: INotification) {
  return {
    type: NotificationActions.EnqueueNotif,
    payload: {
      ...notif,
      key: notif.key || `${new Date().getTime() + Math.random()}`,
    },
  }
}

export function removeNotif(key: string) {
  return {
    type: NotificationActions.RemoveNotif,
    payload: {
      key,
    },
  }
}

export function removeNotifByClassification(classification: string) {
  return {
    type: NotificationActions.RemoveNotifByClassification,
    payload: {
      classification,
    },
    classification,
  }
}
