import { onSubmit } from '@analytics/form-utils'

import { track } from './coderpad_analytics'

const teamCreationForm = document.querySelector('#team-creation-form')
if (teamCreationForm) {
  onSubmit(teamCreationForm, (event, data) => {
    track('Team created')
  })
}

const inviteCreation = document.querySelector('#invite-creation')
if (inviteCreation) {
  onSubmit(inviteCreation, (event, data) => {
    const quantity = $('#invite-emails').val().split('\n').length
    track('Invite sent', { quantity })
  })
}

const urlSearchParams = new URLSearchParams(window.location.search)
const inviteToken = urlSearchParams.get('invite_token')
const newUserForm = document.querySelector('#new_user')
if (inviteToken && newUserForm) {
  onSubmit(newUserForm, (event, data) => {
    track('Invite accepted', {})
  })
}

$('#join-invite').on('click', () => {
  track('Invite accepted', {})
})
