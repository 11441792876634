import { Box } from '@mui/material'
import classNames from 'classnames'
import CodeMirror from 'codemirror'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetch } from 'utils/fetch/useFetch'

import Button from '../../../../../packs/main/button'
import padConfig from '../../../pad_config'
import { selectEditorSettings, selectQuestionId } from '../../../selectors'

interface IFilesTabProps {
  hidden: boolean
}

export const FilesTab: React.FC<IFilesTabProps> = ({ hidden }) => {
  const questionId: number = useSelector(selectQuestionId)
  const textAreaRef = React.createRef<HTMLTextAreaElement>()
  const { darkColorScheme, keymap } = useSelector(selectEditorSettings)
  const filesEditor = useRef<CodeMirror.EditorFromTextArea>()
  const file = useSelector((state) => state.tabs.customFileSelected)
  const [isPathCopied, setPathCopied] = useState(false)
  const [filePath, setFilePath] = useState('')
  const [isImageDisplayed, setIsImageDisplayed] = useState(false)
  const fetch = useFetch()

  const imageRef = useRef<HTMLElement>(null)

  useEffect(() => {
    // Init the files editor
    if (textAreaRef.current != null && filesEditor.current == null) {
      filesEditor.current = CodeMirror.fromTextArea(textAreaRef.current, {
        mode: file?.mimeType ?? 'text/plain',
        lineNumbers: true,
        lineWrapping: true,
        styleActiveLine: false,
        autofocus: false,
        readOnly: true,
      })
    }
    // Updates the theme & keymap
    if (filesEditor.current != null) {
      filesEditor.current.setOption('theme', darkColorScheme ? 'monokai' : 'one-light')
      filesEditor.current.setOption('keyMap', keymap)
      filesEditor.current.refresh()
    }
  }, [textAreaRef, darkColorScheme, keymap])

  // Load the current file data
  useEffect(() => {
    setIsImageDisplayed(false)

    if (file == null) {
      return
    }
    setFilePath(`/home/coderpad/data/${file.filename}`)

    if (filesEditor.current == null) {
      return
    }
    filesEditor.current.setValue('')

    const path = padConfig.isSandbox
      ? `/sandbox/download_custom_file?question_id=${questionId}&file_id=${file.id}`
      : `/${padConfig.slug}/download_custom_file?question_id=${questionId}&file_id=${file.id}`

    if (
      (file.mimeType != null && file.mimeType.includes('image')) ||
      file.filename.toLowerCase().match(/\.(png|jpg|jpeg|gif|bmp)$/) != null
    ) {
      // Image
      const oReq = new XMLHttpRequest()
      oReq.open('GET', path, true)
      oReq.responseType = 'arraybuffer'

      oReq.onload = () => {
        const blob = new Blob([oReq.response])
        const url = URL.createObjectURL(blob)

        if (imageRef.current != null && filesEditor.current != null) {
          imageRef.current.firstElementChild?.setAttribute('src', url)
          setIsImageDisplayed(true)
          filesEditor.current.addWidget({ line: 0, ch: 0 }, imageRef.current, false)
        }
      }

      oReq.send()
    } else {
      // Text or other
      fetch(path, { method: 'get' })
        .then((res) => {
          if (res.ok) {
            return res.text()
          } else {
            throw new Error()
          }
        })
        .then((content) => {
          filesEditor.current != null && filesEditor.current.setValue(content)
        })
        .catch(() => {
          filesEditor.current != null &&
            filesEditor.current.setValue(
              "Error: Couldn't retrieve the file for display. You may still be able to access the file by writing code."
            )
        })
    }
  }, [filesEditor, file, questionId])

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(filePath)
    setPathCopied(true)
    setTimeout(() => setPathCopied(false), 2000)
  }

  return (
    <Box className={classNames({ hidden, FilesTab })}>
      {file != null && (
        <div className="FilesTab-fileHeader">
          This file is available to your program. Its path is:
          <span className="FilesTab-filePath">
            {filePath}
            <Button
              type="regular"
              darkBg={darkColorScheme}
              className="FilesTab-filePath-copyButton"
              onClick={handleCopyToClipboard}
            >
              {isPathCopied ? 'Copied!' : 'Copy'}
            </Button>
          </span>
        </div>
      )}
      <div className="FilesTab-codeMirrorWrapper">
        <textarea ref={textAreaRef}></textarea>
      </div>

      <Box ref={imageRef} mt={-3} sx={{ display: isImageDisplayed ? 'block' : 'none' }}>
        <img src="" style={{ maxWidth: '90%', maxHeight: '600px' }} />
      </Box>
    </Box>
  )
}
