import { useCallback } from 'react'

import { isProduction, useEnvironment } from './environment/environment'

/**
 * Generic email validation regex. This is not exhaustive, but should be good
 * enough for most cases. I've been using this regex for a while, and it seems
 * to work well.
 */
export function validateEmail(email: string) {
  // Only validate if the email is not empty
  if (email === '') return true
  // Allow emails with a plus sign in testing environments
  const validEmailRegex = isProduction()
    ? /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return validEmailRegex.test(email.toLowerCase())
}

export function useValidateEmail() {
  const { isProduction } = useEnvironment()

  const validateEmail = useCallback(
    (email: string) => {
      // Only validate if the email is not empty
      if (email === '') return true
      // Allow emails with a plus sign in testing environments
      const validEmailRegex = isProduction
        ? /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      return validEmailRegex.test(email.toLowerCase())
    },
    [isProduction]
  )

  return {
    validateEmail,
  }
}
