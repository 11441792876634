import Monaco from '@codingame/monaco-editor-react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import * as marked from 'marked'
import * as monaco from 'monaco-editor'
import { LegacyToMonacoKeyBindingMode } from 'packs/main/Monaco/MonacoKeybindings'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IThemeService, StandaloneServices } from 'vscode/services'

import padConfig from '../../pad_config'
import { selectEditorSettings, selectMyUserId } from '../../selectors'
import SyncHandle from '../../sync_handle'

interface IInterviewNotesEditorProps {
  hidden: boolean
}

const editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
  lineNumbers: 'off',
  glyphMargin: false,
  lineDecorationsWidth: 0,
  lineNumbersMinChars: 0,
  scrollBeyondLastLine: true,
  scrollbar: {
    vertical: 'auto',
  },
}

function createPlaceholder() {
  const isDrawingPad = padConfig.uiType === 'drawing_only'
  const placeholderFirst = `\
# This is a placeholder for your Markdown-enabled interview notes

These notes are **private** and hidden from the candidate
They are saved permanently with the interview in this tab

${isDrawingPad ? '' : 'You can also embed code by copying it from the left pane, like so:'}

\
`
  const placeholderSecond = isDrawingPad
    ? ''
    : `
\`\`\`javascript
function find(node, value) {
  if (!node || node.value == value) return node;
  return find(node.next, value);
}
\`\`\`\
`

  return `${placeholderFirst}${placeholderSecond}`
}
const PLACEHOLDER = createPlaceholder()

/**
 * Some languages are hardcoded inside the the markdown grammar and typescriptreact/javascriptreact are not part of it
 */
const LANGUAGE_TO_MARKDOWN_LANGUAGE: Record<string, string> = {
  typescriptreact: 'tsx',
  javascriptreact: 'jsx',
}

function getMarkdownLanguageAtPosition(content: string, position: number) {
  const tokens = marked.Lexer.lex(content)
  let contextToken: marked.marked.Token | null = null
  let index = 0
  for (const token of tokens) {
    index += token.raw.length
    if (index > position) {
      contextToken = token
      break
    }
  }
  if (contextToken != null && contextToken.type === 'code') {
    return contextToken.lang ?? 'markdown'
  }
  return 'markdown'
}

export const InterviewNotesEditor: React.FC<IInterviewNotesEditorProps> = ({ hidden }) => {
  const dispatch = useDispatch()
  const { keymap } = useSelector(selectEditorSettings)
  const userId = useSelector(selectMyUserId)

  const notesEditor = useRef<monaco.editor.IStandaloneCodeEditor>(null)
  const placeholder = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!notesEditor.current) {
      return
    }

    // Create new firepad for interviewer notes
    const editor = notesEditor.current
    editor.getContainerDomNode().setAttribute('data-testid', 'interview-notes-editor')
    const handle = SyncHandle()
    const firepadRef = handle.firepad(editor.getModel()!, 'notes', {
      userId,
      userColor: undefined,
    })

    const didFocusWatcher = editor.onDidFocusEditorWidget(() => {
      if (editor.hasWidgetFocus()) {
        handle.set(`notes/users/${userId}`, { isAuthor: true })
      }
    })

    const didChangeWatcher = editor.getModel()?.onDidChangeContent(() => {
      dispatch({ type: 'interviewer_notes_changed' })
    })

    // Automatically add ```language when code pasted from left tab
    const didPasteWatcher = editor.onDidPaste(({ range, languageId }) => {
      if (languageId != null && languageId !== 'markdown') {
        const model = editor.getModel()

        if (!model) {
          return
        }

        const languageAtPosition = getMarkdownLanguageAtPosition(
          model.getValue(),
          model.getOffsetAt(range.getStartPosition())
        )
        if (languageAtPosition != 'markdown') {
          // do not wrap code if we are already inside a code block
          return
        }

        const content = model.getValueInRange(range)
        let replacement: string
        if (range.endLineNumber > range.startLineNumber) {
          replacement = `\`\`\`${
            LANGUAGE_TO_MARKDOWN_LANGUAGE[languageId] ?? languageId
          }\n${content.trim()}\n\`\`\``
        } else {
          replacement = `\`${content.trim()}\``
        }
        editor.executeEdits(
          null,
          [
            {
              range,
              text: replacement,
            },
          ],
          (inverseEditOperations) => {
            return [monaco.Selection.fromPositions(inverseEditOperations[0].range.getEndPosition())]
          }
        )
      }
    })
    return () => {
      firepadRef.dispose()
      didFocusWatcher.dispose()
      didChangeWatcher?.dispose()
      didPasteWatcher.dispose()
    }
  }, [dispatch, userId])

  const [editorValue, setEditorValue] = useState('')
  const [colorizedText, setColorizedText] = useState('')
  useEffect(() => {
    if (!notesEditor.current || !placeholder.current) {
      return
    }

    const colorize = () => {
      monaco.editor.colorize(PLACEHOLDER, 'markdown', {}).then((colorized) => {
        if (!notesEditor.current || !placeholder.current) {
          return
        }
        setColorizedText(colorized)
      })
    }

    const disposable = StandaloneServices.get(IThemeService).onDidColorThemeChange(colorize)
    colorize()

    return () => {
      disposable.dispose()
    }
  }, [placeholder])

  useEffect(() => {
    if (!notesEditor.current || !placeholder.current) {
      return
    }

    const editor = notesEditor.current
    const placeholderEl = placeholder.current
    editor.applyFontInfo(placeholderEl)
    const disposable = editor.onDidChangeConfiguration(() => {
      editor.applyFontInfo(placeholderEl)
    })
    return () => {
      disposable.dispose()
    }
  }, [placeholder])

  return (
    <div
      className={classNames({ hidden })}
      style={{ flex: 1, height: '100%', position: 'relative' }}
    >
      <Monaco
        programmingLanguage="markdown"
        height="100%"
        options={editorOptions}
        keyBindingsMode={LegacyToMonacoKeyBindingMode[keymap] ?? 'classic'}
        onChange={setEditorValue}
        ref={notesEditor}
      />
      <Box
        sx={{
          visibility: editorValue.length === 0 ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: (theme) => theme.spacing(2),
          pointerEvents: 'none',
          opacity: 0.7,
        }}
        ref={placeholder}
        className="Monaco-placeholder"
        dangerouslySetInnerHTML={{
          __html: colorizedText,
        }}
      />
    </div>
  )
}
