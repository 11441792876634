import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, IconButton, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { selectPadSettings } from 'packs/main/selectors'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import * as queryStates from '../../../../graphql/queryStates'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import {
  EnvironmentTypes,
  useEnvironments,
} from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { ScrollView } from '../../ScrollView/ScrollView'
import {
  EnvironmentSelectorMenuTabs,
  useEnvironmentSelectorMenuContext,
} from '../EnvironmentSelectorMenuContext'
import { LanguageEnvironments } from './LanguageEnvironments/LanguageEnvironments'
import { QuestionEnvironments } from './QuestionEnvironments/QuestionEnvironments'

const Header = styled('div')<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  position: collapsed ? 'absolute' : 'relative',
  top: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const useStyles = makeStyles({
  tabPanel: {
    height: '100%',
    overflow: 'auto',
  },
})

export const NewEnvironmentMenu: React.FC<{ handleOpenPadSettingsMenu: () => void }> = ({
  handleOpenPadSettingsMenu,
}) => {
  const styles = useStyles()
  const { disablePadSettings, isSandbox } = usePadConfigValues('disablePadSettings', 'isSandbox')
  const { execEnabled } = useSelector(selectPadSettings)
  const {
    environments,
    activateEnvironment,
    createEnvironment,
    createStatus,
    createOrSelectEnvironment,
  } = useEnvironments()

  const { currentTab, closeMenu } = useEnvironmentSelectorMenuContext()

  const onQuestionEnvSelect = useCallback(
    (questionId: number | string, envOpts?: Record<string, any>) => {
      const existingEnv = environments.find(
        (env) =>
          env.kind === EnvironmentTypes.Question && env.questionId === questionId && env.visible
      )
      if (existingEnv) {
        activateEnvironment(existingEnv.id)
      } else {
        createEnvironment(EnvironmentTypes.Question, questionId, envOpts)
      }
    },
    [environments, activateEnvironment, createEnvironment]
  )

  // If an env is being created or has failed to be created, the parent EnvironmentSelect should be showing an overlay
  // with a message. To make that message stand out more, blur the menu in the background.
  const shouldBlur =
    queryStates.isLoadingState(createStatus) || queryStates.isErrorState(createStatus)

  const headerCollapsed = currentTab === EnvironmentSelectorMenuTabs.Languages

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{ filter: shouldBlur ? 'blur(2px)' : 'none' }}
    >
      {isSandbox && (
        <Box sx={{ borderBottom: (theme) => `2px solid ${theme.palette.background.default}` }}>
          <Alert severity="warning" sx={{ borderRadius: 0 }}>
            You are currently working in a sandbox. Each tab and its contents will be deleted after
            one hour of inactivity, so save important work elsewhere.
          </Alert>
        </Box>
      )}
      <Header collapsed={headerCollapsed}>
        <IconButton
          aria-label="Close environment menu"
          onClick={closeMenu}
          data-testid="environmentmenu-close"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <Box flex={1} minHeight={0}>
        <ScrollView className={styles.tabPanel}>
          {currentTab === EnvironmentSelectorMenuTabs.Languages && (
            <LanguageEnvironments
              execEnabled={execEnabled}
              onSelect={createOrSelectEnvironment}
              onEnableExecutionClick={disablePadSettings ? undefined : handleOpenPadSettingsMenu}
              languageLimitationReason="code execution was disabled by the interviewer"
            />
          )}
          {currentTab === EnvironmentSelectorMenuTabs.Questions && (
            <QuestionEnvironments onSelect={onQuestionEnvSelect} />
          )}
        </ScrollView>
      </Box>
    </Box>
  )
}
