import {
  alpha,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SaveButton from './SaveButton'

const AutoSaveSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-input': {
    margin: 0,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.success.main,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[200],
  },
}))

export function ProjectSaveAction() {
  const project = useSelector((state) => state.project)
  const dispatch = useDispatch()

  const onAutoSaveChanged = useCallback<NonNullable<SwitchProps['onChange']>>(
    (event, checked) => dispatch({ type: 'project/updateAutoSave', value: checked }),
    [dispatch]
  )

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <SaveButton />
      <FormControlLabel
        sx={{ margin: 0 }}
        label={
          <Typography fontSize="11px" lineHeight="13px">
            Auto-save files
          </Typography>
        }
        control={
          <AutoSaveSwitch size="small" checked={project.autoSave} onChange={onAutoSaveChanged} />
        }
      />
    </Stack>
  )
}
