import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { CandidateInstructionsCandidate } from '../../RightPane/Tabs/CandidateInstructions/CandidateInstructionsCandidate'
import { CandidateInstructionsInterviewer } from '../../RightPane/Tabs/CandidateInstructions/CandidateInstructionsInterviewer'
import SyncHandle from '../../sync_handle'
import { useRecordWorkspace } from '../CodeSpace/hooks/useRecordWorkspace'

const BigBox = styled(Box)({
  height: '100%',
  width: '100%',
})

export function FullScreenInstructions() {
  const { question } = useActiveEnvironment()
  const isOwner = usePadConfigValue('isOwner')
  useRecordWorkspace()

  useEffect(() => {
    SyncHandle().watch<boolean>('ended', (isEnded) => {
      if (isEnded) {
        window.close()
      }
    })
  }, [])

  if (question?.candidateInstructions == null) return null

  return (
    <BigBox>
      {isOwner ? (
        <CandidateInstructionsInterviewer
          candidateInstructions={question.candidateInstructions}
          hidden={false}
        />
      ) : (
        <CandidateInstructionsCandidate
          candidateInstructions={question.candidateInstructions}
          hidden={false}
        />
      )}
    </BigBox>
  )
}
