import { ConverterType, create } from '@alexanderolsen/libsamplerate-js'

/**
 * Forked from: https://github.com/microsoft/cognitive-services-speech-sdk-js/blob/master@%7B2022-10-27T07:51:29Z%7D/src/common/RiffPcmEncoder.ts
 */
export class RiffPcmEncoder {
  public constructor(
    private sourceSampleRate: number,
    private targetSampleRate: number,
    private channelCount: number
  ) {}

  public async encode(actualAudioFrame: Float32Array): Promise<ArrayBuffer | null> {
    const audioFrame = await this.downSampleAudioFrame(actualAudioFrame)

    if (!audioFrame) {
      return null
    }

    const audioLength = audioFrame.length * 2

    const buffer = new ArrayBuffer(audioLength)
    const view = new DataView(buffer)
    this.floatTo16BitPCM(view, 0, audioFrame)

    return buffer
  }

  private floatTo16BitPCM(view: DataView, offset: number, input: Float32Array): void {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]))
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
  }

  private async downSampleAudioFrame(srcFrame: Float32Array): Promise<Float32Array | null> {
    if (!srcFrame) {
      return null
    }

    if (
      this.targetSampleRate === this.sourceSampleRate ||
      this.targetSampleRate > this.sourceSampleRate
    ) {
      return srcFrame
    }

    const converterType = ConverterType.SRC_SINC_FASTEST

    const src = await create(this.channelCount, this.sourceSampleRate, this.targetSampleRate, {
      converterType: converterType,
    })

    const data = new Float32Array(44100)
    const resampledData = src.simple(data)
    src.destroy()

    return resampledData
  }
}
