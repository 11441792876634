import _ from 'lodash'

// This cookie's name is backwards. Let's define a constant to make it less confusing
// where it's used.
export const HIDE_CANDIDATE_OVERLAY_COOKIE_NAME = 'showCandidateOverlay'

export type SingleUserInfo = {
  id: string
  self: boolean
  name: string
  color: string
  isOwner: boolean
  drawingModeOpen: boolean
  isOnline: boolean
  isOutsidePad: boolean
}

export type UserInfo = Record<string, SingleUserInfo>

export interface UserState {
  trackedUserId?: string
  userId: string
  userInfo: UserInfo
  uncommittedUsername: string
  uncommittedEmail: string
  showCandidateOverlay: boolean
  isOnline: boolean
  isOutsidePad: boolean
}

const initialState: UserState = {
  userId: '',
  userInfo: {},
  uncommittedUsername: '',
  uncommittedEmail: '',
  showCandidateOverlay: false,
  isOnline: false,
  isOutsidePad: false,
}

export default function userStateReducer(state: UserState = initialState, action: any): UserState {
  switch (action.type) {
    case 'user_removed':
      if (action.userId !== state.userId) {
        return {
          ...state,
          userInfo: _.omit(state.userInfo, action.userId),
        }
      }
      break
    case 'user_added':
    case 'user_changed': {
      const user = action.userObj
      const id = user.userId

      if (
        user.invisible ||
        !user.name ||
        !(typeof user.color === 'string' && user.color.match(/^#[a-fA-F0-9]{3,6}$/))
      ) {
        break
      }

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [id]: {
            ...state.userInfo[id],
            id,
            self: id == state.userId,
            name: user.name && user.name.substring(0, 30),
            color: user.color,
            isOwner: user.isOwner,
            drawingModeOpen: user.drawingModeOpen,
            isOnline: !!user.connections,
            isOutsidePad: user.isOutsidePad,
          },
        },
      }
    }
    case 'username_edited':
      return {
        ...state,
        uncommittedUsername: action.newName,
      }
    case 'logged_in':
      return {
        ...state,
        uncommittedUsername: action.username,
        uncommittedEmail: action.email,
      }
    case 'candidate_overlay_hidden':
      return {
        ...state,
        showCandidateOverlay: false,
      }
    case 'connection_status_changed':
      return {
        ...state,
        isOnline: action.isOnline,
      }
    case 'drawing_mode_toggled':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [state.userId]: {
            ...state.userInfo[state.userId],
            drawingModeOpen: action.isOpen,
          },
        },
      }
    case 'tracked_user_changed':
      return {
        ...state,
        trackedUserId: action.userId,
      }
  }
  return state
}
