import { Reducer } from 'react'

export enum ProjectTab {
  MainProcess = 'mainProcess',
  Shell = 'shell',
  Console = 'console',
  RequestHistory = 'requestHistory',
}

export interface ProjectTabsState {
  activeTab: ProjectTab
  unseenContent: Record<ProjectTab, boolean>
  visibleTabs: Record<ProjectTab, boolean>
}

export enum ActionsKeys {
  TabSelected = 'project_tab_selected',
  ShellOutputProduced = 'console_output_produced',
  APIRequestPerformed = 'project/request_sent',
  UpdateVisibleTabs = 'project_tabs/update_visible_tabs',
}

type Action =
  | {
      type: ActionsKeys.TabSelected
      tab: ProjectTab
    }
  | {
      type: ActionsKeys.ShellOutputProduced
      output: string
    }
  | {
      type: ActionsKeys.APIRequestPerformed
    }
  | {
      type: ActionsKeys.UpdateVisibleTabs
      visibleTabs: Record<ProjectTab, boolean>
    }

function getDefaultState(): ProjectTabsState {
  return {
    activeTab: ProjectTab.MainProcess,
    unseenContent: {
      [ProjectTab.Shell]: true,
      [ProjectTab.Console]: false,
      [ProjectTab.MainProcess]: false,
      [ProjectTab.RequestHistory]: false,
    },
    visibleTabs: {
      [ProjectTab.MainProcess]: true,
      [ProjectTab.Shell]: false,
      [ProjectTab.Console]: false,
      [ProjectTab.RequestHistory]: false,
    },
  }
}

export const projectTabsReducer: Reducer<ProjectTabsState, Action> = (
  state = getDefaultState(),
  action: Action
) => {
  switch (action.type) {
    case ActionsKeys.TabSelected:
      return {
        ...state,
        activeTab: action.tab,
        unseenContent: {
          ...state.unseenContent,
          [action.tab]: false,
        },
      }
    case ActionsKeys.APIRequestPerformed:
      if (state.activeTab !== ProjectTab.RequestHistory) {
        return {
          ...state,
          unseenContent: {
            ...state.unseenContent,
            [ProjectTab.RequestHistory]: true,
          },
        }
      }
      break
    case ActionsKeys.ShellOutputProduced:
      if (state.activeTab !== ProjectTab.Shell) {
        return {
          ...state,
          unseenContent: {
            ...state.unseenContent,
            [ProjectTab.Shell]: true,
          },
        }
      }
      break
    case ActionsKeys.UpdateVisibleTabs: {
      const updatedState = {
        ...state,
        visibleTabs: {
          ...state.visibleTabs,
          ...action.visibleTabs,
        },
      }
      if (!updatedState.visibleTabs[state.activeTab]) {
        updatedState.activeTab = Object.keys(updatedState.visibleTabs)[0] as ProjectTab
      }
      return updatedState
    }
  }
  return state
}

// Tab Actions
export function setSelectedTab(tab: ProjectTab): Action {
  return {
    type: ActionsKeys.TabSelected,
    tab,
  }
}

export function updateVisibleTabs(visibleTabs: Record<ProjectTab, boolean>) {
  return {
    type: ActionsKeys.UpdateVisibleTabs,
    visibleTabs,
  }
}
