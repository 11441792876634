import { Typography } from '@mui/material'
import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import MarkdownOutput from 'packs/main/markdown_output'
import React, { useMemo } from 'react'

import { MarkdownOutputContainer } from '../components/MarkdownOutputContainer'

// A read-only text tab for showing markdown content as a pad tab.
export const SolutionsTab = ({ solution, hidden }: { solution: string; hidden: boolean }) => {
  const { environment, question } = useActiveEnvironment()

  const activeQuestionVariant = useMemo(() => {
    if (environment == null || question == null) {
      return null
    }
    if (!environment.isQuestionWithVariants) {
      return null
    }
    return question.questionVariants?.find(
      (qv) =>
        qv.language === environment.language ||
        qv.project_template_slug === environment.projectTemplateSlug
    )
  }, [environment, question])

  return (
    <MarkdownOutputContainer hidden={hidden}>
      <MarkdownOutput value={solution} hidden={hidden} />
      {activeQuestionVariant?.solution != null && activeQuestionVariant.solution != '' && (
        <>
          <Typography variant="h4">Notes for {activeQuestionVariant.display} solutions:</Typography>
          <MarkdownOutput
            key={activeQuestionVariant.id}
            value={activeQuestionVariant.solution}
            hidden={hidden}
          />
        </>
      )}
    </MarkdownOutputContainer>
  )
}
