import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, DialogContentText } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const BROWSER_PERMISSIONS_LINK = {
  Firefox:
    'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_using-firefoxaos-address-bar-to-clear-camera-or-microphone-permissions-for-a-site',
  Chrome: 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en',
  Safari: 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac',
}

export function CallErrorScreen() {
  const browserInfo = usePadConfigValue('browser')
  const dispatch = useDispatch()
  const { errorCode, errorText, mayReconnect } = useSelector((state) => ({ ...state.call }))
  const onDismiss = useCallback(() => dispatch({ type: 'call_error_dismissed' }), [dispatch])
  const onReconnect = useCallback(() => {
    dispatch({
      type: 'reconnect_to_call_clicked',
      _analytics: {
        name: 'Call Setup',
        params: {
          from_invite: false,
          from_reconnect: true,
        },
      },
    })
  }, [dispatch])

  const additionalInfo = useMemo(() => {
    if (errorCode === 'no_permission') {
      let link
      for (const browser of ['Chrome', 'Firefox', 'Safari']) {
        if (browserInfo.startsWith(`${browser} `))
          link = (
            <a href={BROWSER_PERMISSIONS_LINK[browser]} target="_blank" rel="noopener noreferrer">
              Here's how to enable video in {browser}.
            </a>
          )
      }
      return (
        <DialogContentText>
          Check your browser settings for <code>app.coderpad.io</code>. {link} You may need to
          reload this pad after updating settings.
        </DialogContentText>
      )
    }
    return null
  }, [errorCode, browserInfo])

  const actions = useMemo(() => {
    const actions = [
      <Button key="dismiss" onClick={onDismiss} variant="outlined" color="inherit">
        Dismiss
      </Button>,
    ]
    if (mayReconnect) {
      actions.push(
        <Button key="reconnect" onClick={onReconnect} variant="contained" color="primary">
          Reconnect
        </Button>
      )
    }
    return actions
  }, [onDismiss, onReconnect, mayReconnect])

  return <Dialog open={true} dialogTitle={errorText} content={additionalInfo} actions={actions} />
}
