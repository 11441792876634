import { formatDistanceToNow } from 'date-fns'
import firebase from 'firebase/compat/app'
import { enqueueNotif, removeNotif } from 'packs/main/reducers/notifications'
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { EventEmitter } from '../../../../utils/events/EventEmitter'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { track } from '../../coderpad_analytics'
import { PadAnalyticsEvent } from '../../constants/PadAnalyticsEvent'
import logPadEvent from '../../log_pad_event'
import { selectLocalUsername, selectMyUserId, selectOnlineUsers } from '../../selectors'
import SyncHandle from '../../sync_handle'
import { useAudioStream } from '../hooks/useAudioStream'
import { useTranscriber } from '../hooks/useTranscriber'
import { SystemMessage, Transcript, TranscriptEntryKind, TranscriptResult } from '../types'
import { Consent } from './Consent'

export const AUTO_END_THRESHOLD = 1000 * 60 * 60 // 1 hour.
export const AUTO_END_WARNING_THRESHOLD = 1000 * 60 * 5 // 5 minutes.

export enum TranscriberEvents {
  TranscriptionDenied = 'TRANSCRIPTION_DENIED',
}

export const TranscriberContext = createContext<{
  startTranscription: (autoConsent?: boolean) => void
  stopTranscription: () => void
  isTranscribing: boolean
  setConsentStatus: (hasConsented: boolean) => void
  hasUserConsentedToTranscription: boolean
  transcriberEvents: EventEmitter
  setAudioDeviceId: (deviceId: string) => void
  isTranscriptionMuted: boolean
  muteTranscription: (isMuted: boolean) => void
  acceptTranscription: () => void
  denyTranscription: () => void
  closedCaptionsEnabled: boolean
  toggleClosedCaptions: () => void
}>({
  startTranscription: () => {},
  stopTranscription: () => {},
  isTranscribing: false,
  setConsentStatus: () => {},
  hasUserConsentedToTranscription: false,
  transcriberEvents: new EventEmitter(),
  setAudioDeviceId: () => {},
  isTranscriptionMuted: false,
  muteTranscription: () => {},
  acceptTranscription: () => {},
  denyTranscription: () => {},
  closedCaptionsEnabled: false,
  toggleClosedCaptions: () => {},
})

interface TranscriberContextProviderProps {
  children: React.ReactNode
}

export function TranscriberContextProvider({ children }: TranscriberContextProviderProps) {
  const dispatch = useDispatch()

  const { hasPureTranscription, hasTranscriptions, isOwner } = usePadConfigValues(
    'hasTranscriptions',
    'isOwner',
    'hasPureTranscription'
  )
  const selfUserId = useSelector(selectMyUserId)
  const selfName = useSelector(selectLocalUsername)
  const onlineUsers = useSelector(selectOnlineUsers)

  const [hasUserConsentedToTranscription, setHasUserConsentedToTranscription] = useState(false)
  const [isTranscribing, setIsTranscribing] = useState(false)
  const [audioDeviceId, setAudioDeviceId] = useState('')
  const [isMuted, setIsMuted] = useState(false)
  const [closedCaptionsEnabled, setClosedCaptionsEnabled] = useState(false)
  const events = useRef(new EventEmitter())

  const onInterimResult = useCallback(
    (id: string, result: TranscriptResult) => {
      console.log('Interim result:', result)
      if (result != null && result.text.length > 0) {
        SyncHandle().set(`transcript/${id}`, {
          kind: TranscriptEntryKind.Transcript,
          transcripts: [result.text || ''],
          preferredTranscript: 0,
          userId: selfUserId,
          timestamp: Date.now(),
          duration: result.duration,
          isFinal: false,
        } as Transcript)
      }
    },
    [selfUserId]
  )

  const onFinalResult = useCallback(
    (id: string, result: TranscriptResult) => {
      console.log('Final result:', result)
      if (result != null && result.text.length > 0) {
        SyncHandle().set(`transcript/${id}`, {
          kind: TranscriptEntryKind.Transcript,
          transcripts: [result.text || ''],
          preferredTranscript: 0,
          userId: selfUserId,
          timestamp: Date.now(),
          duration: result.duration,
          isFinal: true,
        } as Transcript)
      }
    },
    [selfUserId]
  )

  const transcriber = useTranscriber({
    transcriberUrl: window.CoderPad.TRANSCRIPT_SERVICE_URL,
    onInterimResult,
    onFinalResult,
    hasConsentedToTranscription: hasUserConsentedToTranscription,
  })

  const onAudioData = useCallback(
    (data: Int16Array) => {
      if (isMuted) return
      transcriber.send(data)
    },
    [transcriber, isMuted]
  )

  const stream = useAudioStream(audioDeviceId, onAudioData)

  useEffect(() => {
    if (stream.audioContext && transcriber) {
      if (stream.sampleRate !== transcriber.sampleRate) {
        transcriber.setSampleRate(stream.sampleRate)
      }
      if (stream.channelCount !== transcriber.channelCount) {
        transcriber.setChannelCount(stream.channelCount)
      }
    }
  }, [stream.audioContext, stream.sampleRate, stream.channelCount, transcriber])

  const startTranscription = useCallback(
    (autoConsent = false) => {
      if (
        !stream.isListening &&
        hasTranscriptions &&
        (hasUserConsentedToTranscription || autoConsent) &&
        !isTranscribing
      ) {
        console.log('audio stream starting')
        stream.start()
        setIsTranscribing(true)
        SyncHandle().set(`transcriptionStartedAt`, SyncHandle().TIMESTAMP_SENTINEL)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasTranscriptions,
      hasUserConsentedToTranscription,
      stream.start,
      stream.isListening,
      isTranscribing,
    ]
  )

  const stopTranscription = useCallback(() => {
    if (stream.isListening) {
      console.log('audio stream stopping')
      stream.stop()
      setIsTranscribing(false)
      setHasUserConsentedToTranscription(false)
      SyncHandle().set(`transcriptionStartedAt`, [])
    }
  }, [stream.isListening, stream.stop]) // eslint-disable-line react-hooks/exhaustive-deps

  // Listen for updates to `transcriptionStartedAt`.
  const [startedAt, setStartedAt] = useState(0)
  useEffect(() => {
    const watchCB = SyncHandle().watch<number>('transcriptionStartedAt', (startTime) => {
      setStartedAt(startTime)
    })

    return () => {
      SyncHandle().off('transcriptionStartedAt', watchCB)
    }
  }, [])

  // There are a few things that need to happen when `transcriptionStartedAt` changes:
  // 1. Set up auto-end and auto-end warning timeouts.
  // 2. Prompt user to start transcription if not transcribing but within the auto-end threshold.
  useEffect(() => {
    let warningTimeout: ReturnType<typeof setTimeout> | null = null
    let autoEndTimeout: ReturnType<typeof setTimeout> | null = null

    // Only pads with pure transcription should setup auto-end and notifications.
    if (hasPureTranscription) {
      const timeLeft = startedAt != null ? startedAt + AUTO_END_THRESHOLD - Date.now() : 0

      // If there is time left within the auto-end threshold, we should be transcribing.
      const shouldBeTranscribing =
        timeLeft < AUTO_END_THRESHOLD &&
        timeLeft > 0 && // There is positive time left .
        !isTranscribing // Not already transcribing.

      if (shouldBeTranscribing) {
        dispatch(
          enqueueNotif({
            message:
              'This pad is currently transcribing. Click to consent and join the transcription.',
            key: 'resume_transcription_prompt',
            variant: 'info',
            onClick: () => {
              setIsObtainingConsent(true)
              dispatch(removeNotif('resume_transcription_prompt'))
            },
          })
        )
      }

      if (isTranscribing && timeLeft > 0) {
        const timeBeforeWarning = timeLeft - AUTO_END_WARNING_THRESHOLD
        if (timeBeforeWarning <= 0) {
          // Warn immediately; there is time left but it's less than the warning threshold.
          dispatch(
            enqueueNotif({
              message: `Transcription will time out in ${formatDistanceToNow(
                new Date(Date.now() + timeLeft)
              )}, click here to continue transcribing.`,
              key: 'extend_transcription_prompt',
              variant: 'warning',
              onClick: () => {
                SyncHandle().set(`transcriptionStartedAt`, SyncHandle().TIMESTAMP_SENTINEL)
                dispatch(removeNotif('extend_transcription_prompt'))
              },
            })
          )
        } else {
          dispatch(removeNotif('extend_transcription_prompt'))
          warningTimeout = setTimeout(() => {
            if (isTranscribing) {
              dispatch(
                enqueueNotif({
                  message: `Transcription will time out in ${formatDistanceToNow(
                    new Date(Date.now() + timeLeft)
                  )}, click here to continue transcribing.`,
                  key: 'extend_transcription_prompt',
                  variant: 'warning',
                  onClick: () => {
                    SyncHandle().set(`transcriptionStartedAt`, SyncHandle().TIMESTAMP_SENTINEL)
                    dispatch(removeNotif('extend_transcription_prompt'))
                  },
                })
              )
            }
          }, timeLeft - AUTO_END_WARNING_THRESHOLD)
        }
        autoEndTimeout = setTimeout(() => {
          stopTranscription()
          dispatch(
            enqueueNotif({
              message:
                'Transcription was automatically stopped due to inactivity. Click here to start transcribing again.',
              key: 'stop_transcription_prompt',
              variant: 'warning',
              onClick: () => {
                setIsObtainingConsent(true)
                dispatch(removeNotif('stop_transcription_prompt'))
              },
            })
          )
        }, timeLeft)
      }
    }

    return () => {
      if (warningTimeout != null) {
        clearTimeout(warningTimeout)
      }
      if (autoEndTimeout != null) {
        clearTimeout(autoEndTimeout)
      }
    }
  }, [dispatch, hasPureTranscription, isTranscribing, startedAt, stopTranscription])

  const [isObtainingConsent, setIsObtainingConsent] = useState(false)

  useEffect(() => {
    if (isTranscribing) {
      dispatch(removeNotif('resume_transcription_prompt'))
      dispatch(removeNotif('stop_transcription_prompt'))
    } else {
      dispatch(removeNotif('extend_transcription_prompt'))
    }
  }, [dispatch, isTranscribing])

  const denyTranscription = useCallback(() => {
    SyncHandle().set(`transcript/${v4()}`, {
      kind: TranscriptEntryKind.SystemMessage,
      message: `${selfName} joined the call and declined recording.`,
      timestamp: SyncHandle().TIMESTAMP_SENTINEL,
    } as SystemMessage)
    SyncHandle().set(`usersDeniedTranscription/${selfUserId}`, Date.now())
    track(PadAnalyticsEvent.TranscriptionUserDisagreed, {
      username: selfName,
    })
    setHasUserConsentedToTranscription(false)
  }, [selfName, selfUserId, setHasUserConsentedToTranscription])

  const acceptTranscription = useCallback(() => {
    SyncHandle().set(`transcript/${v4()}`, {
      kind: TranscriptEntryKind.SystemMessage,
      message: `${selfName} joined the call and agreed to recording.`,
      timestamp: SyncHandle().TIMESTAMP_SENTINEL,
    } as SystemMessage)
    SyncHandle().set(`usersDeniedTranscription/${selfUserId}`, 0)
    setHasUserConsentedToTranscription(true)
    logPadEvent('agreed_to_transcription', { username: selfName })
    track(PadAnalyticsEvent.TranscriptionUserAgreed, {
      username: selfName,
    })
  }, [selfName, selfUserId, setHasUserConsentedToTranscription])

  // We want to keep a record of users that have denied transcription. It is important to keep their deniedAt
  // timestamp so that if they join-and-decline twice, we can tell that there was a second denial and emit
  // a second event.
  const denialNotifications = useRef<Record<number, number>>({})
  // Using a ref for this because we don't want the excessive number of updates to re-trigger this effect all the time.
  // It is OK to use a ref because we really just care about the state of online users at the time
  // `usersDeniedTranscription` updates in Firebase.
  const onlineUsersRef = useRef(new Set<string>())
  useEffect(() => {
    for (const user of onlineUsers) {
      onlineUsersRef.current.add(String(user.id))
    }
  }, [onlineUsers])
  // Watch for users denying transcription.
  useEffect(() => {
    let watcher: (snap: firebase.database.DataSnapshot) => void
    if (isOwner && isTranscribing) {
      watcher = SyncHandle().watch<Record<number, number>>(
        'usersDeniedTranscription',
        (userDenials) => {
          if (userDenials != null) {
            for (const [userId, deniedAt] of Object.entries(userDenials)) {
              if (
                deniedAt !== 0 && // Has denied transcirption/recording.
                String(userId) !== selfUserId && // Not the current user.
                onlineUsersRef.current.has(String(userId)) && // Is online in the pad.
                denialNotifications.current[+userId] !== deniedAt // Is a new denial.
              ) {
                events.current.emit(TranscriberEvents.TranscriptionDenied, userId)
              }
              denialNotifications.current[+userId] = deniedAt
            }
          }
        }
      )
    }

    return () => {
      if (watcher) {
        SyncHandle().off('usersDeniedTranscription', watcher)
      }
      denialNotifications.current = {}
    }
  }, [isOwner, selfUserId, isTranscribing])

  return (
    <TranscriberContext.Provider
      value={{
        startTranscription,
        stopTranscription,
        isTranscribing,
        setConsentStatus: setHasUserConsentedToTranscription,
        hasUserConsentedToTranscription,
        transcriberEvents: events.current,
        setAudioDeviceId,
        isTranscriptionMuted: isMuted,
        muteTranscription: setIsMuted,
        acceptTranscription,
        denyTranscription,
        closedCaptionsEnabled,
        toggleClosedCaptions: () => setClosedCaptionsEnabled(!closedCaptionsEnabled),
      }}
    >
      {children}
      <Consent
        isOpen={isObtainingConsent}
        requestClose={() => setIsObtainingConsent(!isObtainingConsent)}
      />
    </TranscriberContext.Provider>
  )
}

export function useTranscriberContext(onTranscriptionDenial?: (firebaseAuthorId: string) => void) {
  const context = React.useContext(TranscriberContext)

  useEffect(() => {
    if (context && onTranscriptionDenial) {
      context.transcriberEvents.on(TranscriberEvents.TranscriptionDenied, onTranscriptionDenial)
    }

    return () => {
      if (onTranscriptionDenial) {
        context.transcriberEvents.remove(
          TranscriberEvents.TranscriptionDenied,
          onTranscriptionDenial
        )
      }
    }
  }, [context, onTranscriptionDenial])

  return context
}
