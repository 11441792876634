import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import prettier from 'prettier'
import parserBabel from 'prettier/parser-babel'
import parserHtml from 'prettier/parser-html'
import parserCss from 'prettier/parser-postcss'
import { all, call, put, takeEvery } from 'redux-saga/effects'

export default function* codeFormatterSaga() {
  yield takeEvery('question_format_and_select_requested', function* ({ question }) {
    try {
      // question fileContents looks like [ { path, contents }, ... ] so it
      // doesn't specify languages. we guess each file's language by taking the
      // file path and looking it up in the question language multifile list.
      const langData = CoderPad.LANGUAGES[question.language]
      const langFilesByPath = _.keyBy(langData.files, 'path') || {}
      const formattedFileContents = yield all(
        question.fileContents.map(function* (fileContent) {
          const { path, contents } = fileContent
          const language =
            path === langData.global_file_path
              ? question.language
              : langFilesByPath[path]?.['language']
          const formattedContents = yield call(formatCode, contents, language)
          return {
            ...fileContent,
            contents: formattedContents,
          }
        })
      )
      yield put({
        type: 'question_selected_by_local_user',
        ...question,
        fileContents: formattedFileContents,
        // HACK: for monaco users, set the contents to the global file's contents, since we don't
        // use fileContents in MonacoContext
        contents: formattedFileContents[0].contents,
      })
    } catch (error) {
      // formatting errors should fail gracefully and still select the question
      Sentry.captureException(new Error('Code formatter error'), {
        tags: {
          questionId: question.id,
        },
        extra: {
          error,
          question,
        },
      })
      yield put({
        type: 'question_selected_by_local_user',
        ...question,
      })
    }
  })
}

export function formatCode(code, language) {
  switch (language) {
    case 'css': {
      return prettier.format(code, {
        parser: 'css',
        plugins: [parserCss],
      })
    }
    case 'html': {
      return prettier.format(code, {
        parser: 'html',
        plugins: [parserHtml],
      })
    }
    case 'javascript':
    case 'jsx': {
      return prettier.format(code, {
        parser: 'babel',
        plugins: [parserBabel],
      })
    }
    default: {
      return code
    }
  }
}
