import _ from 'lodash'

const DEFAULT_STATE = {
  lastLoadedQuestionId: null,
  modalVisible: false,
  question: null,
}

export const MULTIFILE_ACTIONS = {
  confirmationRequested: 'MUTIFILE_CONFIRMATION_REQUESTED',
  confirmationDismissed: 'MULTIFILE_CONFIRMATION_DISMISSED',
}

export default function multifileReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    // When the interviewer picks an autoparsed single->multifile question,
    // we ask for their confirmation before actually triggering the question
    // selection.
    case MULTIFILE_ACTIONS.confirmationRequested: {
      return {
        ...state,
        modalVisible: true,
        question: _.omit(action, ['type']),
      }
    }
    case MULTIFILE_ACTIONS.confirmationDismissed: {
      return {
        ...state,
        modalVisible: false,
      }
    }
    case 'question_data_loaded':
    case 'question_selected_by_local_user': {
      return {
        ...state,
        lastLoadedQuestionId: action.questionId,
      }
    }
  }
  return state
}

export function dismissMultifileConfirmation(analyticsData) {
  return {
    ...(analyticsData && { _analytics: analyticsData }),
    type: MULTIFILE_ACTIONS.confirmationDismissed,
  }
}
