import { track } from './coderpad_analytics'

$('.sign-in-track').click((e) => {
  const path_url = window.location.href
  const login_method = e.target.value ? 'standard_app' : 'google'
  track('Login click', { path_url, login_method })
})

$('.sign-up-track').click((e) => {
  const path_url = window.location.href
  const login_method = e.target.value ? 'standard_app' : 'google'
  track('Signup click', { path_url, login_method })
})