import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  alpha,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  keyframes,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Tooltip,
} from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'

import { spin } from '../../util'

const slideInText = keyframes`
    0% {
        margin: 0;
        max-width: 0;
        opacity: 0;
    }
    50% {
        margin: 0;
        max-width: 0;
        opacity: 0;
    }
    80% {
        max-width: 200px;
        opacity: 0;
    }
`

const StyledBaseButton = styled(Button)(({ theme }) => ({
  ':disabled': {
    backgroundColor: alpha(theme.palette.success.main, 0.4),
  },
}))

const StyledButton = styled(StyledBaseButton)(({ theme }) => ({
  textTransform: 'none',
  overflow: 'hidden',
  minWidth: '91px',
  width: '100%',
  '& .glyph': {
    width: '1em',
  },
  '& .fui-gear': {
    animation: `2s ${spin} infinite linear`,
  },
}))

const Wrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'starting',
})(({ starting }: { starting: boolean }) => ({
  cursor: `${starting ? 'wait' : 'inherit'}`,
}))

const RunLoader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'running',
})<{ running: boolean }>(({ theme, running }) => ({
  width: '300%',
  height: '100%',
  background: `linear-gradient(90deg, ${theme.palette.error.main} 0 45%, ${theme.palette.success.main} 55%)`,
  top: 0,
  position: 'absolute',
  left: running ? '0%' : '-200%',
  transitionProperty: running ? 'left' : 'inherit',
  opacity: running ? 'inherit' : 0,
  transitionDuration: '1s',
}))

const Content = styled('div')({
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})

const Label = styled('span', {
  shouldForwardProp: (prop) => prop !== 'starting',
})(({ starting }: { starting: boolean }) => ({
  marginRight: '1em',
  animation: starting ? slideInText : 'inherit',
}))

interface ButtonAction {
  key: string
  label: string
  busyLabel?: string
  perform: () => void
}
export type ButtonActions = [ButtonAction, ...ButtonAction[]]

interface AnimatedActionButtonProps {
  isBusy: boolean
  disabled?: boolean
  handleHotkeys?: (e: KeyboardEvent) => void
  actions: ButtonActions
  tooltip?: string
}
export function AnimatedActionButton(props: AnimatedActionButtonProps) {
  const { isBusy, disabled = false, actions, tooltip } = props
  const [starting, setStarting] = useState(false)
  const [activeActionIdx, setActiveActionIdx] = useState(0)

  // If the active action is not in the list of actions, default to the first action
  // This can happen when the amount of actions changed due to cpad file changes
  const activeAction = actions[activeActionIdx] ? actions[activeActionIdx] : actions[0]

  const TOGGLE_DURATION_IN_MS = 1000

  const runClick = useCallback(
    (action: ButtonAction) => {
      if (disabled || starting) {
        return
      }
      action.perform()
      setStarting(true)
      setTimeout(() => {
        setStarting(false)
      }, TOGGLE_DURATION_IN_MS)
    },
    [disabled, starting]
  )

  const handleActionSelection = useCallback(
    (actionIdx: number) => {
      setActiveActionIdx(actionIdx)
      runClick(actions[actionIdx])
      setActionMenuOpen(false)
    },
    [runClick, actions]
  )

  const handleActionMenuClose = useCallback((event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setActionMenuOpen(false)
  }, [])

  const anchorRef = useRef<HTMLDivElement>(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  return (
    <Wrapper starting={starting}>
      <Tooltip title={tooltip}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          disabled={disabled}
          color={isBusy ? 'error' : 'success'}
        >
          <StyledButton onClick={() => runClick(activeAction)}>
            <RunLoader
              running={isBusy}
              style={{ animationDuration: `${TOGGLE_DURATION_IN_MS / 1000}s` }}
              aria-hidden={true}
            />
            <Content>
              <Label starting style={{ animationDuration: `${TOGGLE_DURATION_IN_MS / 1000}s` }}>
                {isBusy ? activeAction.busyLabel ?? activeAction.label : activeAction.label}
              </Label>
              <div
                className={`fui-${
                  disabled ? 'lock' : isBusy ? 'gear' : 'triangle-right-large'
                } glyph`}
                aria-hidden={true}
              />
            </Content>
          </StyledButton>
          {actions.length > 1 ? (
            <StyledBaseButton
              aria-controls={actionMenuOpen ? 'action-menu' : undefined}
              aria-expanded={actionMenuOpen ? 'true' : undefined}
              aria-label="select action"
              aria-haspopup="menu"
              onClick={() => setActionMenuOpen(!actionMenuOpen)}
            >
              <ArrowDropDownIcon />
            </StyledBaseButton>
          ) : null}
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={actionMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleActionMenuClose}>
                <MenuList id="action-menu" autoFocusItem sx={{ minWidth: '134px' }}>
                  {actions.map((action, idx) => (
                    <MenuItem
                      key={action.key}
                      selected={action.label === activeAction.label}
                      onClick={(event) => handleActionSelection(idx)}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Wrapper>
  )
}
