import './MonacoThemes'

import { monaco } from '@codingame/monaco-editor-wrapper'
import * as Sentry from '@sentry/browser'
import { flatMap, fromPairs } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetch } from 'utils/fetch/useFetch'
import useStateEffect from 'utils/hooks/useStateEffect'
import { errorHandler } from 'vscode/monaco'

import { EventEmitter } from '../../../utils/events/EventEmitter'
import { Language, LanguageConfiguration } from '../../../utils/languages'
import { fileIdToPath, pathToFileId } from '../../../utils/multifile'
import { isNotNull } from '../../../utils/types'
import {
  usePadConfigValue,
  usePadConfigValues,
} from '../../dashboard/components/PadContext/PadContext'
import { useGlobalEventHelpers } from '../playback/GlobalEvents/useGlobalEventHelpers'
import { editorErrorOccurred } from '../reducers/editorStatus'
import { selectLanguagesAvailable, selectUserInfo } from '../selectors'
import SyncHandle from '../sync_handle'
import { getUserColor } from '../util'
import { getFirepadCreateOptions } from './EnvironmentMonaco/useFirepadCreateOptions'
import { useMonacoConfiguration } from './EnvironmentMonaco/useMonacoSettings'
import { MonacoFile, PadUser } from './FilePane/utils/types'
import { ActionsKeys, defaultMonacoState, MonacoReducer, MonacoState } from './MonacoReducer'
import { setCoderpadTheme } from './MonacoThemes'
import { Firepad, FirepadHeadless } from './types'

errorHandler.addListener((error: Error) => {
  Sentry.captureException(error, {
    tags: {
      layer: 'monaco',
    },
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cause: Error = (error as any).cause
  if (cause != null) {
    Sentry.captureException(cause, {
      tags: {
        layer: 'monaco',
      },
    })
  }
})

// This will be an event emitter that outside sources can emit events into to trigger updates in the MonacoContext.
// For instance: a language change can emit a `langChange` event with this emitter that will update the language state
// in this context.
export const monacoContextEmitter = new EventEmitter()

/**
 * We want to keep tight control on what the context provider provides.
 * We want to keep all dispatches and state-based function strictly
 * inside this file. We surface the functions that the components
 * can consume through useContext();
 */
export type MonacoProviderContract = {
  files: MonacoFile[]
  activeFile?: MonacoFile
  activeLanguage: string
  activeLanguageSettings?: LanguageConfiguration
  setEditor: (editor: monaco.editor.IStandaloneCodeEditor) => void
  createFile: (file: MonacoFile) => void
  setActiveFile: (file: MonacoFile) => void
  setSingleFilePadLanguage: (language: string) => void
  updateFileUser: (fileId: string, fileUser: PadUser) => void
  firepadReady: boolean
  questionId: number | string
  getEditor: () => monaco.editor.IStandaloneCodeEditor | null
}

export const MonacoContext = React.createContext({} as MonacoProviderContract)

const emptyLanguages = {}
export const MonacoProvider: React.FC = function ({ children }) {
  const firepadErrorMsgs = useRef(new Set())
  const [state, dispatch] = useReducer(MonacoReducer, defaultMonacoState)
  const reduxDispatch = useDispatch()
  const availableLanguages = useSelector(selectLanguagesAvailable) ?? emptyLanguages
  const {
    firebaseAuthorId,
    invisible,
    isSandbox,
    slug,
    isPlayback,
    question: padConfigQuestion,
  } = usePadConfigValues(
    'firebaseAuthorId',
    'invisible',
    'isSandbox',
    'slug',
    'isPlayback',
    'question'
  )
  const monacoPathByLegacyPath = useMemo(() => {
    return fromPairs(
      flatMap(
        Object.values(availableLanguages)
          .map(({ files }) => files)
          .filter(isNotNull),
        (files) => files.map<[string, string]>((file) => [file.path, file.default_path])
      )
    )
  }, [availableLanguages])
  const fetch = useFetch()

  const { files, activeFileId } = state

  // Firepad and the Monaco editor object must be kept in local state instead of in the MonacoReducer state. Keeping
  // complex/large objects in the reducer causes issues with certain browser devtool extensions that provide
  // introspection into React contexts and `useReducer` usages. With objects like these in a reducer, the reducer
  // state diff'ing consumes a ton of memory and crashes the app.
  const firepadRef = useRef<Firepad | null>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const [editorLoaded, setEditorLoaded] = useState(false)
  const getEditor = useCallback(() => {
    if (!editorLoaded) {
      return null
    }
    return editorRef.current
  }, [editorLoaded])
  const setEditor = useCallback((editor: monaco.editor.IStandaloneCodeEditor) => {
    editorRef.current = editor
    setEditorLoaded(true)
  }, [])

  const [singleFilePadLanguage, setSingleFilePadLanguage] = useState(
    useSelector((state) => state.padSettings.language)
  )
  const singleFilePadPath = availableLanguages[singleFilePadLanguage].default_path
  const [questionId, setQuestionId] = useState(useSelector((state) => state.padSettings.questionId))
  const [questionLoadIncrementer, setQuestionLoadIncrementer] = useState(0)
  const questionAppendRef = useRef(false)
  const wasLangChangeRemoteRef = useRef(false)
  // Default the question contents ref to the question contents from the pad config. This satisfies the case where a
  // question is previewed in the sandbox from the QBank. No fetching of the question occurs, the question info is
  // passed via pad config.
  const questionContentsRef = useRef<null | string>(padConfigQuestion?.contents ?? null)
  const questionStarterCodeByLanguageRef = useRef(
    usePadConfigValue('question')?.starterCodeByLanguage
  )

  const { persistLanguageChange } = useGlobalEventHelpers()

  const setActiveFile = useCallback((file: MonacoFile) => {
    dispatch({ type: ActionsKeys.SET_ACTIVE_FILE, value: file.fileId })
  }, [])

  useEffect(() => {
    // if the user is switching to the HTML language, we may need to seed the
    // CSS and JS files with their example code
    const seedNonGlobalFiles = () => {
      availableLanguages['html'].files?.forEach((file) => {
        const fileId = pathToFileId(file.path)
        const headlessFirepad: FirepadHeadless = SyncHandle().firepadHeadless(`files/${fileId}`)
        headlessFirepad.on('ready', () => {
          if (headlessFirepad.isHistoryEmpty()) {
            headlessFirepad.setText(file.example)
            headlessFirepad.dispose()
          }
        })
      })
    }

    const languageChangeHandler = ({
      language,
      fromRemote,
    }: {
      language: string
      fromRemote: boolean
    }) => {
      wasLangChangeRemoteRef.current = fromRemote
      setSingleFilePadLanguage(language)
      // only push local langauge change events
      if (!isPlayback && !fromRemote) {
        persistLanguageChange(language)
      }
      if (language === 'html') {
        seedNonGlobalFiles()
      }
    }
    const questionSelectedByLocalUserHandler = ({
      contents,
      questionId: selectedQuestionId,
      language,
      append,
      starterCodeByLanguage,
    }: {
      contents: string
      questionId: number
      language: string
      append: boolean
      starterCodeByLanguage: unknown[]
    }) => {
      wasLangChangeRemoteRef.current = false
      questionContentsRef.current = contents
      questionAppendRef.current = append
      questionStarterCodeByLanguageRef.current = starterCodeByLanguage

      // question loading always sets the user back to the global file
      const globalFile = files.filter(isNotNull).find(({ isGlobalFile }) => isGlobalFile)
      setActiveFile(globalFile!)

      if (language === singleFilePadLanguage) {
        if (questionId === selectedQuestionId) {
          // this is a request to reload the question in the editor...
          setQuestionLoadIncrementer(questionLoadIncrementer + 1)
        } else {
          setQuestionId(selectedQuestionId)
        }
      } else {
        setQuestionId(selectedQuestionId)
        setSingleFilePadLanguage(language)
        persistLanguageChange(language)
      }
    }
    const questionDataLoadedHandler = ({
      starterCodeByLanguage,
    }: {
      starterCodeByLanguage: unknown[]
    }) => {
      questionStarterCodeByLanguageRef.current = starterCodeByLanguage
    }
    monacoContextEmitter.on('languageChange', languageChangeHandler)
    monacoContextEmitter.on('questionSelectedByLocalUser', questionSelectedByLocalUserHandler)
    monacoContextEmitter.on('questionDataLoaded', questionDataLoadedHandler)

    return () => {
      monacoContextEmitter.remove('languageChange', languageChangeHandler)
      monacoContextEmitter.remove('questionSelectedByLocalUser', questionSelectedByLocalUserHandler)
      monacoContextEmitter.remove('questionDataLoaded', questionSelectedByLocalUserHandler)
    }
  }, [
    questionLoadIncrementer,
    singleFilePadLanguage,
    files,
    isPlayback,
    persistLanguageChange,
    availableLanguages,
    setActiveFile,
    questionId,
  ])

  const packageName = useSelector((state) => state.padSettings.packageName)
  const isMultiFile = singleFilePadLanguage === Language.HTML
  const padUsers = useSelector(selectUserInfo)
  const activeFile = useMemo(
    () =>
      isMultiFile
        ? files.find((file) => file.fileId === activeFileId)
        : files.filter(isNotNull).find(({ isGlobalFile }) => isGlobalFile),
    [activeFileId, files, isMultiFile]
  )

  const singleFilePathLanguageSettings = availableLanguages?.[singleFilePadLanguage]

  const activeFileExampleCode = useMemo(() => {
    if (activeFile != null) {
      if (activeFile.isGlobalFile) {
        if (questionStarterCodeByLanguageRef.current?.[singleFilePadLanguage] != null) {
          return questionStarterCodeByLanguageRef.current[singleFilePadLanguage]
        } else {
          const { multifile_example, example } = singleFilePathLanguageSettings
          return multifile_example ?? example ?? ''
        }
      } else {
        const singleFileExampleCode = singleFilePathLanguageSettings?.files?.find(
          (f) => f.default_path === activeFile.name
        )?.example
        return singleFileExampleCode ?? ''
      }
    }

    return ''
  }, [activeFile, singleFilePathLanguageSettings, singleFilePadLanguage])

  const editorSettings = useSelector(({ editorSettings }) => editorSettings)

  useMonacoConfiguration(singleFilePadLanguage)

  const editorContents = useRef('')
  const savePadInRails = useCallback(() => {
    if (!isSandbox!) {
      fetch(`/${slug}/update_firebase`, { method: 'post' })
    }
  }, [isSandbox, slug])
  const maybeSavePadInRails = useCallback(() => {
    const tmpContents = editorRef.current?.getModel()?.getValue()
    if (tmpContents != null && tmpContents !== editorContents.current) {
      editorContents.current = tmpContents
      savePadInRails()
    }
  }, [savePadInRails])
  useEffect(() => {
    if (isSandbox) {
      return
    }

    const interval = setInterval(maybeSavePadInRails, 60000 + Math.floor(Math.random() * 60000))

    return () => {
      clearInterval(interval)
    }
  }, [isSandbox, maybeSavePadInRails])

  const createFile = useCallback((file: MonacoFile, activate = false) => {
    dispatch({ type: ActionsKeys.CREATE_FILE, value: { file, activate } })
  }, [])

  const updateState = useCallback((updates: Partial<MonacoState>) => {
    dispatch({ type: ActionsKeys.UPDATE_STATE, value: updates })
  }, [])

  const updateFileUser = useCallback(
    (fileId: string, fileUser: PadUser) => {
      dispatch({
        type: ActionsKeys.UPDATE_FILE_USER,
        value: { fileId, fileUser },
      })
    },
    [dispatch]
  )

  const watchFileUsers = useCallback((fileId?: string) => {
    const firebasePath = fileId != null ? `files/${fileId}/users` : 'users'
    SyncHandle().watch(firebasePath, (users: string[]) => {
      let fileUsers: PadUser[] = []
      if (users != null) {
        fileUsers = Object.keys(users)
          .filter((userId) => users[userId].cursor)
          .map((userId) => {
            const user = users[userId]
            return {
              id: userId,
              color: user.color,
              cursorPosition: user.cursor?.position,
            }
          })
      }

      dispatch({ type: ActionsKeys.UPDATE_FILE_USERS, value: { fileId: fileId ?? '', fileUsers } })
    })
  }, [])

  useEffect(() => {
    if (packageName == null) {
      return
    }

    const packageCode = availableLanguages?.[singleFilePadLanguage].packages?.[packageName]?.example
    if (packageCode != null) {
      editorRef.current!.getModel()!.setValue(packageCode)
    }
  }, [availableLanguages, packageName, singleFilePadLanguage])

  useEffect(() => {
    // ensure this effect only happens upon initialization of the Pad
    if (!isFirstMountRef.current) {
      return
    }

    const createDefaultFile = () => {
      const language = availableLanguages?.[singleFilePadLanguage]
      const file: MonacoFile = {
        name: language?.default_path ?? 'pad.txt',
        path: language?.default_path ?? 'pad.txt',
        isGlobalFile: true,
        fileId: '',
        users: [],
        monacoLanguage: language?.monaco_language,
        isLocked: false,
        isImmutable: false,
      }
      dispatch({ type: ActionsKeys.CREATE_FILE, value: { file } })
      return file
    }

    const registerFile = (fileId: string) => {
      const filePath = fileIdToPath(fileId)
      const file: MonacoFile = {
        name: monacoPathByLegacyPath[filePath] ?? availableLanguages?.['html']?.default_path,
        path: monacoPathByLegacyPath[filePath] ?? availableLanguages?.['html']?.default_path,
        fileId: fileId,
        users: [],
        isGlobalFile: false,
        isLocked: false,
        isImmutable: false,
      }

      if (!isPlayback) {
        watchFileUsers(fileId)
      }

      dispatch({ type: ActionsKeys.CREATE_FILE, value: { file } })
    }

    createDefaultFile()
    SyncHandle().watch('fileIds', (fileIds: string[]) => {
      Object.keys(fileIds ?? []).forEach(registerFile)
    })
  }, [
    availableLanguages,
    monacoPathByLegacyPath,
    singleFilePadLanguage,
    watchFileUsers,
    isPlayback,
  ])

  useEffect(() => {
    setCoderpadTheme(editorSettings?.darkColorScheme)
  }, [editorSettings?.darkColorScheme])

  const isFirstMountRef = useRef(true)
  const previousContentRef = useRef('')
  const lastLanguageRef = useRef<string | null>(null)
  const hasActiveFile = activeFile != null
  const firepadReady = useStateEffect(
    (setFirepadReady) => {
      savePadInRails()

      const editor = editorRef.current
      if (editor == null || !hasActiveFile) {
        return
      }

      const getActiveFilePath = () => {
        if (activeFile.isGlobalFile ?? true) {
          return '' // the default
        } else {
          return `files/${activeFile.fileId}`
        }
      }

      const modelUri = monaco.Uri.file(activeFile.name)
      const activeFilePath = getActiveFilePath()

      // sanity check - make sure that the activeFile matches the current language
      if (activeFile.isGlobalFile && activeFile.path !== singleFilePadPath) {
        return
      }

      // preserve previous model's content in case we want to add it
      // to the next model's starter code in a comment
      previousContentRef.current =
        lastLanguageRef.current != null
          ? `\nYour previous ${
              availableLanguages?.[lastLanguageRef.current].display
            } content is preserved below:\n\n` + editor.getModel()?.getValue()
          : ''

      let monacoModel = monaco.editor.getModel(modelUri)
      let content = ''
      if (monacoModel == null) {
        monacoModel = monaco.editor.createModel('', activeFile.monacoLanguage, modelUri)
        content = activeFileExampleCode!

        // when a user switches _from_ plaintext or markdown to another language, we'll preserve
        // the plaintext/markdown contents and append them to new language's model
        if (['plaintext', 'markdown'].includes(lastLanguageRef.current ?? '')) {
          content += previousContentRef.current
        }
      } else if (!isPlayback) {
        content = monacoModel.getValue()
      }

      // if question just got loaded in, set contents to the question's contents
      let hasQuestionContents = false
      if (questionContentsRef.current != null) {
        hasQuestionContents = true
        if (questionAppendRef.current) {
          content = content + '\n' + questionContentsRef.current!
        } else {
          content = questionContentsRef.current!
        }
        questionContentsRef.current = null
      }

      editor.setModel(monacoModel)

      if (isPlayback) {
        isFirstMountRef.current = false

        setFirepadReady(true)

        // this empty return is for the linter.
        return
      } else {
        const userId = (firebaseAuthorId ?? Date.now().toString().substring(5)).toString()
        const newFirepad = SyncHandle().firepad(
          monacoModel,
          activeFilePath,
          getFirepadCreateOptions(userId, activeFileExampleCode)
        )

        newFirepad.on('error', (e: Error) => {
          if (!firepadErrorMsgs.current.has(e?.message)) {
            Sentry.captureException(e, {
              tags: {
                layer: 'firepad',
              },
            })
            firepadErrorMsgs.current.add(e?.message)
          }
          reduxDispatch(editorErrorOccurred())
        })

        newFirepad.on('ready', () => {
          const isFirstMount = isFirstMountRef.current
          isFirstMountRef.current = false

          setFirepadReady(true)

          // various things we need to do upon firepad being ready for the first time in a Pad session
          if (isFirstMount) {
            // notify the redux sagas that the editor is now ready
            reduxDispatch({ type: 'editor_mounted', editor: editorRef.current })

            watchFileUsers()
          }

          // situations where we do NOT want to force-update firebase:
          // - on initial page load
          // - on non-global files, if newFirepad has history
          // - on the global file, if the language is not changing (ie, switched from non-global file to global file)
          // - if another user in the pad changed the language
          // exception - always update (disconnected) firebase in sandbox pads, since
          // there's no chance of overwriting another user's changes
          if (
            isSandbox ||
            (!isFirstMount &&
              (activeFile.isGlobalFile || (newFirepad.isHistoryEmpty() as boolean)) &&
              (!activeFile.isGlobalFile ||
                lastLanguageRef.current !== singleFilePadLanguage ||
                hasQuestionContents) &&
              !wasLangChangeRemoteRef.current)
          ) {
            newFirepad.setText(content)

            // if we appended the previous language's content to this model, we now need to comment it out
            // find the content we preserved, set the selection to cover that content, issue the commentLine
            // command, and then set the user's position to the start of the document
            const matchRange = monacoModel!.findNextMatch(
              previousContentRef.current,
              monacoModel!.getPositionAt(0),
              false,
              true,
              null,
              false
            )
            if (matchRange != null) {
              editor.setSelection(matchRange.range)
              editor
                .getAction('editor.action.commentLine')
                .run()
                .then(() => {
                  editor.setPosition({ column: 1, lineNumber: 1 })
                })
            }
          }

          lastLanguageRef.current = singleFilePadLanguage

          firepadRef.current = newFirepad
        })

        return () => {
          newFirepad.dispose()
          firepadRef.current = null
        }
      }
      // Do not put `activeFile` as deps because the `users` in it changes often
    },
    [
      reduxDispatch,
      questionId,
      questionLoadIncrementer,
      updateState,
      isSandbox,
      slug,
      firebaseAuthorId,
      savePadInRails,
      activeFileExampleCode,
      hasActiveFile,
      activeFile?.isGlobalFile,
      activeFile?.fileId,
      singleFilePadLanguage,
      availableLanguages,
      isPlayback,
      watchFileUsers,
      activeFile?.name,
      activeFile?.path,
      activeFile?.monacoLanguage,
      editorLoaded,
      singleFilePadPath,
    ],
    false
  )

  useEffect(() => {
    const allFiles = files.filter(isNotNull)
    if (allFiles.length === 0 || availableLanguages == null) {
      return
    }
    const language = availableLanguages[singleFilePadLanguage]
    const globalFilePath = language?.default_path
    if (allFiles.every((f) => !f.isGlobalFile || f.name === globalFilePath)) {
      return
    }
    const nextFiles: MonacoFile[] = allFiles.map((file) => {
      return file.isGlobalFile
        ? {
            ...file,
            name: globalFilePath,
            path: globalFilePath,
            monacoLanguage: language.monaco_language,
          }
        : { ...file }
    })
    updateState({ files: nextFiles, activeFileId: '' })
  }, [availableLanguages, files, singleFilePadLanguage, updateState])

  useEffect(() => {
    const editor = editorRef.current
    if (editor != null && activeFile?.name.endsWith('.md')) {
      const model = editor.getModel()!
      const watcher = model.onDidChangeContent((event) => {
        reduxDispatch({ type: 'markdown_content_changed', content: model.getValue() })
      })
      return () => {
        watcher.dispose()
      }
    }
    return
  }, [activeFile?.name, reduxDispatch])

  const userColor = useMemo((): string | undefined => {
    if (padUsers != null && !invisible) {
      const userId = (firebaseAuthorId ?? Date.now().toString().substring(5)).toString()
      return getUserColor(padUsers, userId)
    }
    return undefined
  }, [padUsers, invisible, firebaseAuthorId])

  useEffect(() => {
    if (userColor != null) {
      firepadRef.current?.setUserColor(userColor)
    }
  }, [userColor, firepadReady])

  const userName: string = useSelector((state) => state.userState.uncommittedUsername)
  useEffect(() => {
    firepadRef.current?.setUserName(userName)
  }, [userName, firepadReady, singleFilePadLanguage])

  /**
   * Rather than providing {state, dispatch} directly we surface functions that really handle the state.
   */
  return (
    <MonacoContext.Provider
      value={{
        activeLanguage: singleFilePadLanguage,
        activeLanguageSettings: singleFilePathLanguageSettings,
        files,
        updateFileUser,
        activeFile,
        setActiveFile,
        setSingleFilePadLanguage,
        createFile,
        setEditor,
        // No Firepads will be created during playback, so Firepad is always "ready" in that case.
        firepadReady,
        questionId,
        getEditor,
      }}
    >
      {children}
    </MonacoContext.Provider>
  )
}
// Custom hook to get the context properties for the pad.
export function useMonacoContext() {
  const contextVal = useContext(MonacoContext)

  if (contextVal == null) {
    throw new Error('`useMonacoContext` hook must be a descendant of a `MonacoContextProvider`')
  }

  return contextVal
}
