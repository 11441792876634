import { Button, ButtonProps, Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'

interface ActionButtonProps {
  onClick: () => void
  disabled?: boolean
  color?: ButtonProps['color']
  children?: ReactNode
  title: string
}

export default function ActionButton({
  onClick,
  disabled,
  color,
  title,
  children,
}: ActionButtonProps) {
  return (
    <Tooltip title={title} arrow>
      <Button
        disabled={disabled}
        onClick={onClick}
        color={color}
        variant="outlined"
        size="small"
        sx={{ textTransform: 'none' }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}
