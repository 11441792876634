import InfoIcon from '@mui/icons-material/Info'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import LockIcon from '../../../assets/images/Locked.svg'
import Button from './button'
import padConfig from './pad_config'

class _FocusTimeButton extends React.Component {
  static propTypes = {
    darkBg: PropTypes.bool.isRequired,
    focusTimeStartedAt: PropTypes.number,
  }

  _getTooltipText() {
    if (padConfig.isSandbox) {
      return `
        <div>
          <div class="SandboxTips-content">
            <p><b>Focus Time</b></p>
            An optional feature you may provide candidates.
            The feature provides a maximum of 5 minutes of uninterrupted “quiet” time for a candidate.
            During this time, interviewer’s viewing will be obscured for privacy.
          </div>
          <div class="SandboxTips-lock">
            <img src=${LockIcon} class="LockIcon" alt="Lock Icon" />
            <i>Available with free account</i>
          </div>
        </div>
        `
    }

    return padConfig.isOwner
      ? 'Focus Time lets you "pause" the interview while the candidate collects their thoughts.'
      : 'Improve your performance by collecting your thoughts for 5 minutes, without your interviewer watching.'
  }

  render() {
    const { darkBg, focusTimeStartedAt } = this.props

    // differentiate between focusTimeStartedAt having an undefined value vs a null value
    // undefined means we haven't yet asked Firebase if the Focus Time has been started
    // null means we have asked Firebase and Focus Time has not yet been started
    // the button should be disabled until we know for sure that FT hasn't yet been started
    const disabled = focusTimeStartedAt === undefined || !!focusTimeStartedAt

    if (disabled) {
      return null
    }

    return (
      <Button
        className="FocusTime-Button"
        darkBg={darkBg}
        disabled={disabled}
        ref={(el) => (this._el = el)}
        tooltip={{
          placement: 'bottom',
          trigger: 'hover',
          html: true,
          title: this._getTooltipText(),
          template: `<div class="tooltip ${
            padConfig.isSandbox ? 'SandboxTips SandboxTips-large' : 'FocusTime-tooltip'
          }" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`,
        }}
        extraProps={
          padConfig.isSandbox ? {} : { 'data-toggle': 'modal', 'data-target': '#focus-time-modal' }
        }
        type={disabled ? 'primary' : 'info'}
      >
        <span className="FocusTime-Button-text">Focus Time</span>
        <InfoIcon className="FocusTime-Button-icon" />
      </Button>
    )
  }
}

function mapStateToProps(state) {
  const { focusTimeStartedAt } = state.padSettings
  const { darkColorScheme } = state.editorSettings
  return {
    darkBg: darkColorScheme,
    focusTimeStartedAt,
  }
}

const mapDispatchToProps = {
  onClick() {
    return {
      type: 'focus_time_intro_toggled',
      _analytics: { name: 'Focus Time Intro Toggled' },
    }
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(_FocusTimeButton)
