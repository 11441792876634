import { ContentCopy } from '@mui/icons-material'
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useWindow } from '../../../utils'
import { PadTranslation } from '../constants'
import { selectPadSettings } from '../selectors'

type PadInvitesPermalinkProps = Record<string, unknown>

export const PadInvitesPermalink = memo<PadInvitesPermalinkProps>(() => {
  const [didJustCopyLink, setDidJustCopyLink] = useState(false)
  const { takeHome } = useSelector(selectPadSettings)
  const window = useWindow()

  const permalinkUrl = useMemo(
    () =>
      `${window.location.protocol}//${window.location.host.replace('app.', '')}${
        window.location.pathname
      }`,
    [window]
  )

  const handleCopyButtonClick = useCallback(() => {
    window.navigator.clipboard?.writeText(permalinkUrl)

    setDidJustCopyLink(true)

    setTimeout(() => {
      setDidJustCopyLink(false)
    }, 1000)
  }, [permalinkUrl, window])

  return (
    <Box sx={{ paddingX: 3 }}>
      <Stack spacing={2}>
        <Typography
          color="inherit"
          component="h1"
          sx={{ alignSelf: 'center', fontSize: '1.125rem', fontWeight: 500 }}
        >
          {PadTranslation.padInvitesPermalinkTitle}
        </Typography>
        <Typography
          color="secondary"
          component="p"
          dangerouslySetInnerHTML={{
            __html: PadTranslation.padInvitesPermalinkDescription(takeHome),
          }}
        />
        <OutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                placement="top-end"
                title={
                  didJustCopyLink
                    ? PadTranslation.padInvitesPermalinkCopyButtonTitleCopying
                    : PadTranslation.padInvitesPermalinkCopyButtonTitleDefault
                }
              >
                <IconButton
                  aria-label={PadTranslation.padInvitesPermalinkCopyButtonTitleDefault}
                  edge="end"
                  onClick={handleCopyButtonClick}
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          inputProps={{ readOnly: true }}
          size="small"
          sx={{ flexGrow: 1 }}
          value={permalinkUrl}
        />
      </Stack>
    </Box>
  )
})
