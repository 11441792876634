import clsx from 'clsx'
import React, { ReactEventHandler, useCallback, useState } from 'react'

import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'

const EditorLogo = () => {
  const [logoFailedToLoad, setLogoFailedToLoad] = useState(false)
  const logoUrl = usePadConfigValue('logoUrl')
  const [isSquare, setIsSquare] = useState(false)

  // This works due to the constraints set in OrganizationLogoUpload

  const handleLogoLoad = useCallback<ReactEventHandler<HTMLImageElement>>((e) => {
    const imgElement = e.target as HTMLImageElement
    if (e.target instanceof HTMLImageElement && imgElement.height && imgElement.width) {
      setIsSquare(imgElement.height === imgElement.width)
    }
  }, [])

  const handleLogoLoadError = useCallback(() => {
    setLogoFailedToLoad(true)
  }, [])

  return logoUrl != null && !logoFailedToLoad ? (
    <img
      src={window.padConfig!.logoUrl}
      className={clsx('branded-logo', { square: isSquare })}
      onError={handleLogoLoadError}
      onLoad={handleLogoLoad}
      alt="company logo"
    />
  ) : null
}
export default EditorLogo
