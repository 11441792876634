import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { PadAnalyticsEvent } from 'packs/main/constants/PadAnalyticsEvent'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'utils'

interface FrameworkDetails {
  destination: {
    url: string
    framework?: string
    questionId?: string
  }
}

interface ScreenDetails {
  message: string
  link: string
}

export const useAdSpaceAnalytics = (adType: string) => {
  const { track } = useAnalytics()
  const slug = usePadConfigValue('slug')
  const userId = useSelector((state) => state.userState.userId)

  const trackAnalytics = useCallback(
    (details: FrameworkDetails | ScreenDetails) => {
      track(PadAnalyticsEvent.PlaybackAdSpaceClicked, {
        user_id: userId,
        pad_id: slug,
        timestamp: Math.floor(Date.now() / 1000),
        ad_type: adType,
        ...details,
      })
    },
    [adType, slug, track, userId]
  )

  return trackAnalytics
}
