import { TextOperation } from '@codinpad/firepad'
import _ from 'lodash'

import { FirepadEdits, RawFrame } from '../../playback/types'

export function calculateFrames(
  edits: FirepadEdits,
  fileId: string,
  padEndedAt: number
): RawFrame[] {
  const frames: RawFrame[] = [
    {
      fileId,
      authorId: undefined,
      value: '',
      timestamp: 0,
      executions: [],
      operation: new TextOperation(),
      type: 'retain',
    },
  ]
  for (const key of _.sortBy(_.keys(edits))) {
    const snap = edits[key]

    // padEndedAt is 0 if the pad has not been ended
    // padEndedAt is in seconds, snap.t is in milliseconds
    if (padEndedAt > 0 && snap.t > padEndedAt * 1000) {
      break
    }

    const authorId = snap.a.toString()
    const lastTimestamp = frames[frames.length - 1].timestamp
    const timestamp = lastTimestamp && snap.t < lastTimestamp ? lastTimestamp + 1 : snap.t

    // History operations may contain several insert and delete ops which
    // happened at the same time. For UI visibility, we want to render deletes
    // first then inserts. This is a clear way to show text replacement, which
    // may be stored in history less clearly as Insert op then Delete op.
    const fullOperation = TextOperation.fromJSON(snap.o)
    const deleteOperation = new TextOperation()
    const insertOperation = new TextOperation()
    for (const op of fullOperation.ops) {
      if (op.isRetain()) {
        deleteOperation.retain(op.chars)
        insertOperation.retain(op.chars)
      } else if (op.isInsert()) {
        insertOperation.insert(op.text)
      } else if (op.isDelete()) {
        deleteOperation.delete(op.chars)
      }
    }

    if (!deleteOperation.isNoop()) {
      const lastValue = frames[frames.length - 1].value
      // Sanity check. Especially after the Oct. 20, 2021 fiasco. TextOps crash if the length is unexpected.
      if ((lastValue || '').length === deleteOperation.baseLength || 0) {
        frames.push({
          fileId,
          authorId,
          operation: deleteOperation,
          type: 'delete',
          value: deleteOperation.apply(lastValue),
          timestamp,
          executions: [],
        })
      }
    }

    if (!insertOperation.isNoop()) {
      const lastValue = frames[frames.length - 1].value
      // Sanity check. Especially after the Oct. 20, 2021 fiasco. TextOps crash if the length is unexpected.
      if ((lastValue || '').length === insertOperation.baseLength || 0) {
        frames.push({
          fileId,
          authorId,
          operation: insertOperation,
          type: 'insert',
          value: insertOperation.apply(lastValue),
          timestamp,
          executions: [],
        })
      }
    }
  }

  return frames
}
