/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

import * as queryStates from '../../../queryStates'
import { MutationUpdateUserArgs, UpdateUserInput, UpdateUserPayload, User } from '../../../types'

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        path
      }
      message
      user {
        id
        name
        email
        defaultLanguage
        defaultPrivacy
        defaultExecution
        emailOptIn
        receivePadEndedSummary
        isDefaultTeamConfirmed
        defaultTeam {
          id
        }
      }
    }
  }
`

export interface UpdateUserData {
  updateUser: UpdateUserPayload
}

export interface UseUserUpdateResult {
  fieldErrors: { [key: string]: string }
  status: queryStates.QueryState
  updateUser: (settings: UpdateUserInput) => Promise<any>
  updateUserWithError: (settings: UpdateUserInput) => Promise<any>
}

export function useUserUpdate(onSuccess?: (data: User) => void): UseUserUpdateResult {
  const [updateUser, { loading, error, data }] = useMutation<
    UpdateUserData,
    MutationUpdateUserArgs
  >(UPDATE_USER_MUTATION, {
    onCompleted: (updatedData: UpdateUserData) => {
      const updateUser = updatedData?.updateUser
      // Only call the onSuccess callback if we have a return query and no errors.
      if (onSuccess && updateUser.user && !updateUser.errors?.length) {
        onSuccess(updateUser.user)
      }
    },
  })

  // Grab the field and general errors from the repsonse, if they exist.
  const fieldErrors: { [key: string]: string } = {}
  let generalError: string = ''
  data?.updateUser?.errors?.forEach((err) => {
    if (err.message && err.path?.length) {
      const errorType = err.path && err.path[0]
      if (errorType === 'attributes') {
        fieldErrors[err.path[1]] = err.message
      } else if (errorType === 'general') {
        generalError = err.message
      }
    }
  })

  const updateUserWithError = useCallback(
    (settings: UpdateUserInput) =>
      updateUser({
        variables: { input: settings },
        context: { source: 'useUserUpdate.ts' },
      }),
    [updateUser]
  )

  const updateUserCallback = useCallback(
    (settings: UpdateUserInput) =>
      // Swallow the error here to avoid an unhandled rejection. The Apollo error link will log the error.
      updateUserWithError(settings).catch(() => null),
    [updateUserWithError]
  )

  // Determine the state of the mutation from the data response.
  let statusMessage = ''
  let status = queryStates.initial()
  const hasFieldErrors = Object.keys(fieldErrors).length
  if (loading) {
    // Loading, so clear out the status message.
    status = queryStates.loading()
  } else if (error || generalError || hasFieldErrors) {
    // Error cases are: non 200 status, a general error in the errors array, or field errors.
    if (generalError) {
      statusMessage = generalError
    } else if (hasFieldErrors) {
      statusMessage = 'There were one or more errors in the provided values.'
    } else {
      statusMessage = 'Something went wrong. Please try again later'
    }
    status = queryStates.error(statusMessage)
  } else if (data?.updateUser?.message) {
    // No errors and we have a data message in the repsonse, this is what success looks like.
    status = queryStates.success(data?.updateUser?.message || '')
  }

  return {
    updateUser: updateUserCallback,
    updateUserWithError,
    fieldErrors,
    status,
  }
}
