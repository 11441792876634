import { VideoCamera } from '@codinpad/shared-components/components/icons/index.js'
import { Dialog, DialogContent } from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorBoundary } from '../../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../../dashboard/components/GenericErrorView/GenericErrorView'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import MaximizedCall from '../maximized_call'
import MinimizedCall from '../minimized_call'
import { enqueueNotif } from '../reducers/notifications'
import {
  selectAudioMuted,
  selectCallMaximized,
  selectCallQuality,
  selectCallStatus,
  selectLocalUsername,
  selectMyUserId,
  selectTwilioUsers,
  selectUserInfo,
  selectVideoMuted,
} from '../selectors'
import {
  TranscriberEvents,
  useTranscriberContext,
} from '../Transcriber/ThanscriberContext/TranscriberContext'
import CallData from './call_data'
import { CallErrorScreen } from './CallErrorScreen'
import { CallInviteScreen } from './CallInvite'
import { ParticipantDeclinedTranscription } from './ParticipantDeclinedTranscription'
import { PendingScreen } from './PendingScreen'
import { TranscriptConsent } from './TranscriptConsent'

type CallStatus = 'pending' | 'invited' | 'in_call' | 'error' | 'transcription_consent'

export interface TwilioCaller {
  audio: string
  video: string
  name: string
  id: string
  self: boolean
}

interface TwilioUser {
  audioTrackId: string
  participantId: string
  videoTrackId: string
}

interface TwilioUserMap {
  [userId: string]: TwilioUser
}

const _CallRoot: FC = () => {
  const dispatch = useDispatch()
  const callStatus: CallStatus = useSelector(selectCallStatus)
  const twilioUsers: TwilioUserMap = useSelector(selectTwilioUsers)
  const selfUserId = useSelector(selectMyUserId)
  const selfName = useSelector(selectLocalUsername)
  const firebaseUserInfo = useSelector(selectUserInfo)
  const audioMuted = useSelector(selectAudioMuted)
  const videoMuted = useSelector(selectVideoMuted)
  const maximized = useSelector(selectCallMaximized)
  const networkQualityLevel = useSelector(selectCallQuality)
  const { hasTranscriptions, isOwner } = usePadConfigValues('hasTranscriptions', 'isOwner')
  const [callDenialDialogOpen, setCallDenialDialogOpen] = useState(false)

  const {
    closedCaptionsEnabled,
    muteTranscription,
    stopTranscription,
    toggleClosedCaptions,
    transcriberEvents,
  } = useTranscriberContext()

  const [callers, setCallers] = useState<TwilioCaller[]>([])

  useEffect(() => {
    muteTranscription(audioMuted)
  }, [audioMuted, muteTranscription])

  useEffect(() => {
    const cb = () => {
      if (isOwner) {
        setCallDenialDialogOpen(true)
      }
    }
    transcriberEvents.on(TranscriberEvents.TranscriptionDenied, cb)

    return () => {
      transcriberEvents.remove(TranscriberEvents.TranscriptionDenied, cb)
    }
  }, [transcriberEvents, isOwner])

  useEffect(() => {
    const callers: TwilioCaller[] = []
    if (callStatus === 'in_call') {
      callers.push({
        audio: CallData.localAudioTrack,
        video: CallData.localVideoTrack,
        name: selfName,
        id: selfUserId,
        self: true,
      })
      const room = CallData.room
      for (const [userId, twilioInfo] of Object.entries(twilioUsers)) {
        // Don't push a duplicate of own tracks
        if (userId === selfUserId) continue

        if (twilioInfo.participantId) {
          const participant = room.participants.get(twilioInfo.participantId)
          callers.push({
            audio:
              twilioInfo.audioTrackId && participant.audioTracks.get(twilioInfo.audioTrackId).track,
            video:
              twilioInfo.videoTrackId && participant.videoTracks.get(twilioInfo.videoTrackId).track,
            // Twilio and Firebase each have presence info, cached client-side in the call
            // and user-state reducers, respectively. Here, we join the two in order to get
            // a remote caller's name, which only Firebase knows. In the edge case where someone
            // is connected to the call but has lost their Firebase connection, we may be
            // unable to display a name.
            //
            // TODO: Cache the last known name of someone who has disconnected from Firebase
            // so their name won't disappear from the UI in this case.
            name: (firebaseUserInfo[userId] && firebaseUserInfo[userId].name) || '',
            id: userId,
            self: false,
          })
        }
      }
      setCallers(callers)
    }
  }, [callStatus, firebaseUserInfo, twilioUsers])

  const hasNotified = useRef(false)
  useEffect(() => {
    if (!hasNotified.current && hasTranscriptions && isOwner) {
      dispatch(
        enqueueNotif({
          title: 'New Feature - Call Transcription',
          autoDismissMs: 30000,
          message: (
            <>
              Click "
              <VideoCamera sx={{ height: 16, width: 16, marginBottom: '-3px' }} /> Start Call" to
              focus less on note-taking and get a complete interview transcript after you end the
              pad.
            </>
          ),
          key: 'transcription_call_message',
          variant: 'info',
        })
      )
      hasNotified.current = true
    }
  }, [hasTranscriptions, dispatch, isOwner])

  let callComponent = null
  switch (callStatus) {
    case 'pending':
      callComponent = <PendingScreen />
      break
    case 'transcription_consent':
      callComponent = <TranscriptConsent />
      break
    case 'invited':
      callComponent = <CallInviteScreen callParticipants={callers} />
      break
    case 'in_call': {
      const CallComponent = maximized ? MaximizedCall : MinimizedCall
      // TODO fix excessive props
      callComponent = (
        <CallComponent
          ccEnabled={closedCaptionsEnabled}
          toggleCC={toggleClosedCaptions}
          callers={callers}
          endCall={() => {
            dispatch({ type: 'call_ended', _analytics: { name: 'Call Left' } })
            stopTranscription()
          }}
          minimize={() =>
            dispatch({ type: 'call_minimized', _analytics: { name: 'Call Minimized' } })
          }
          maximize={() =>
            dispatch({ type: 'call_maximized', _analytics: { name: 'Call Maximized' } })
          }
          unmuteVideo={() => dispatch({ type: 'mute_clicked', kind: 'video', enabled: true })}
          unmuteAudio={() => dispatch({ type: 'mute_clicked', kind: 'audio', enabled: true })}
          muteVideo={() => dispatch({ type: 'mute_clicked', kind: 'video', enabled: false })}
          muteAudio={() => dispatch({ type: 'mute_clicked', kind: 'audio', enabled: false })}
          videoMuted={videoMuted}
          audioMuted={audioMuted}
          networkQualityLevel={networkQualityLevel}
        />
      )
      break
    }
    case 'error':
      callComponent = <CallErrorScreen />
      break
    default:
      callComponent = null
  }

  return (
    <>
      {callComponent}
      <ParticipantDeclinedTranscription
        open={callDenialDialogOpen}
        onClose={() => setCallDenialDialogOpen(false)}
      />
    </>
  )
}

function ErrorFallback({ e }: { e?: Error }) {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <GenericErrorView error={e} message="An error occurred while starting the call." />
      </DialogContent>
    </Dialog>
  )
}

function CallRoot() {
  return (
    <ErrorBoundary fallback={(e) => <ErrorFallback e={e} />}>
      <_CallRoot />
    </ErrorBoundary>
  )
}

export default CallRoot
