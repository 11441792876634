import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import ExampleQuestionList from './example_question_list'
import QuestionFromPadButton from './question_from_pad_button'
import QuestionList from './question_list'
import QuestionSelected from './question_selected'

export default class QuestionLibrary extends React.PureComponent {
  static propTypes = {
    allowPadCreation: PropTypes.bool.isRequired,
    allowSharedEdit: PropTypes.bool,
    languagesUsed: PropTypes.arrayOf(PropTypes.string),
    initialFavorites: PropTypes.arrayOf(PropTypes.number).isRequired,
    initiallySelectedQuestionId: PropTypes.any,
    organizationId: PropTypes.number,
    // Null/undefined if not in a pad.
    language: PropTypes.string,
    // Null/undefined if not in a pad.
    padSlug: PropTypes.string,
    // Null/undefined if not in a pad.
    onSetQuestion: PropTypes.func,
    // Null/undefined if you're not part of an org.
    organizationName: PropTypes.string,
    questionsExist: PropTypes.bool,
    exampleQuestionsExist: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    if (this.exampleInitiallySelected(props)) {
      this.state = { activeLibrary: 'example' }
    } else {
      this.state = {
        activeLibrary:
          this.props.questionsExist || !this.props.exampleQuestionsExist ? 'myLibrary' : 'example',
      }
    }

    this._examplesTooltipRef = React.createRef()

    this.onSelectQuestion = this.onSelectQuestion.bind(this)
    this.onDeselectQuestion = this.onDeselectQuestion.bind(this)
    this.onSelectQuestionVariation = this.onSelectQuestionVariation.bind(this)
  }

  componentDidMount() {
    if (this.props.exampleQuestionsExist) {
      $(this._examplesTooltipRef.current).tooltip()
    }
  }

  exampleInitiallySelected(props) {
    return (
      typeof props.initiallySelectedQuestionId === 'string' &&
      props.initiallySelectedQuestionId.indexOf('examples/') !== -1
    )
  }

  get newQuestionPath() {
    return `/questions/new${this.props.takeHome === true ? '?question%5Btake_home%5D=true' : ''}`
  }

  getActivateLibraryHandler = (activeLibrary) => {
    return () => {
      if (this.state.activeLibrary === activeLibrary) {
        return
      }
      this.setState({ activeLibrary, selectedQuestion: null })
    }
  }

  getQuestionsIndex = () => {
    return this.props.takeHome === true ? '/questions/take_home' : '/questions'
  }

  onSelectQuestionVariation(variation) {
    this.setState({
      selectedQuestionActiveVariation: variation,
      lastManuallySelectedLanguage: variation?.language,
    })
  }

  onSelectQuestion(question, variations, activeVariation) {
    const selectedVariation = variations?.length
      ? activeVariation ??
        variations.find((v) => v.language === this.state.lastManuallySelectedLanguage) ??
        variations.find((v) => v.language === this.props.language) ??
        variations[0]
      : activeVariation

    this.setState({
      selectedQuestion: question,
      selectedQuestionVariations: variations,
      selectedQuestionActiveVariation: selectedVariation,
    })
    if (!this.props.padSlug && question.id) {
      const path = activeVariation
        ? `${this.getQuestionsIndex()}/${question.id}/${activeVariation}`
        : `${this.getQuestionsIndex()}/${question.id}`
      history.replaceState(null, '', path)
    }
  }

  onDeselectQuestion() {
    const question = this.state.selectedQuestion
    if (!this.props.padSlug) {
      if (typeof question.id === 'string' && question.id.indexOf('examples/') !== -1) {
        history.replaceState(null, '', `${this.getQuestionsIndex()}/examples`)
      } else {
        history.replaceState(null, '', this.getQuestionsIndex())
      }
    }

    this.setState({ selectedQuestion: null })
  }

  renderDeselected() {
    const isEligibleForQuestionCreation = this.props.padSlug && !this.props.takeHome

    return this.state.activeLibrary === 'example' ? (
      <div
        id="empty-message"
        style={{
          marginTop: '160px',
        }}
      >
        <h3>Welcome to {this.props.takeHome ? 'Take-Home' : ''} Example Questions</h3>
        <div>
          <p>
            These example questions can be used during your CoderPad interviews. You can use them as
            is, or save them to your Question Bank and make changes.
          </p>
          <p>Browse examples on the left and select one to get started.</p>
        </div>
      </div>
    ) : (
      <div
        id="empty-message"
        style={{
          marginTop: this.props.takeHome ? '110px' : '160px',
        }}
      >
        <h3>Welcome to your{this.props.takeHome ? ' Take-Home' : ''} Question Bank</h3>
        {this.props.takeHome ? (
          <div>
            <p>
              Take-Home questions are programming exercises given to candidates to complete on their
              own time. By providing test inputs and outputs, you help candidates automatically
              assess the correctness of their solutions.
            </p>
            <p>
              Create Take-Home pads from these questions.
              {this.props.organizationName &&
                ' Like Live questions, Take-Home questions can be shared with colleagues.'}
            </p>
          </div>
        ) : (
          <div>
            <p>
              Questions you
              {this.props.organizationName && ' and your colleagues'} have written, as well as
              example questions, are listed on the left.
            </p>
            <p>
              Search for questions to use in live interviews, edit existing questions, and create
              new ones.
            </p>
          </div>
        )}
        <p>
          Detailed instructions with screenshots are available in the{' '}
          {this.props.takeHome ? (
            <a
              href="https://coderpad.io/resources/docs/take-home-question-bank/"
              target="_blank"
              rel="noreferrer"
            >
              Take-Home Questions Guide
            </a>
          ) : (
            <a
              href="https://coderpad.io/resources/docs/live-question-bank/"
              target="_blank"
              rel="noreferrer"
            >
              Live Questions Guide
            </a>
          )}
          .
        </p>

        {isEligibleForQuestionCreation && <QuestionFromPadButton />}
      </div>
    )
  }

  render() {
    return (
      <div id="questions-wrap">
        <div className="right">
          {this.state.selectedQuestion ? (
            <QuestionSelected
              key={this.state.selectedQuestion.id}
              question={this.state.selectedQuestion}
              variations={this.state.selectedQuestionVariations}
              activeVariation={this.state.selectedQuestionActiveVariation}
              allowEdit={this.props.allowSharedEdit || !!this.state.selectedQuestion.mine}
              allowPadCreation={this.props.allowPadCreation}
              padSlug={this.props.padSlug}
              padLanguage={this.props.language}
              onDeselectQuestion={this.onDeselectQuestion}
              onSetQuestion={this.props.onSetQuestion}
              onSelectQuestionVariation={this.onSelectQuestionVariation}
            />
          ) : (
            this.renderDeselected()
          )}
        </div>
        <div className="left">
          <div className="QuestionLibrary-LibraryTabs">
            <div
              className={classNames({
                'QuestionLibrary-LibraryTab': true,
                'QuestionLibrary-LibraryTab--active': this.state.activeLibrary === 'myLibrary',
              })}
              onClick={this.getActivateLibraryHandler('myLibrary')}
            >
              My Library
            </div>
            {this.props.exampleQuestionsExist && (
              <div
                className={classNames({
                  'QuestionLibrary-LibraryTab': true,
                  'QuestionLibrary-LibraryTab--active': this.state.activeLibrary === 'example',
                })}
                onClick={this.getActivateLibraryHandler('example')}
              >
                Examples
                <span
                  className="QuestionLibrary-LibraryTabInfoIcon editor-setting-tooltip fui-info-circle"
                  data-placement="top"
                  ref={this._examplesTooltipRef}
                  title={`Quickly get started with CoderPad ${
                    this.props.takeHome ? 'take-homes' : ''
                  }! Find inspiration for writing your own questions, or customize and add examples to your library.`}
                />
              </div>
            )}
          </div>
          <div className="QuestionLibrary-QuestionListContainer">
            {this.props.exampleQuestionsExist && (
              <ExampleQuestionList
                onSelectQuestion={this.onSelectQuestion}
                onDeselectQuestion={this.onDeselectQuestion}
                selectedQuestion={this.state.selectedQuestion}
                allowPadCreation={this.props.allowPadCreation}
                initiallySelectedQuestionId={
                  this.exampleInitiallySelected(this.props)
                    ? this.props.initiallySelectedQuestionId
                    : null
                }
                exampleQuestionsExist={this.props.exampleQuestionsExist}
                takeHome={this.props.takeHome}
                hidden={this.state.activeLibrary !== 'example'}
              />
            )}
            <QuestionList
              onSelectQuestion={this.onSelectQuestion}
              onDeselectQuestion={this.onDeselectQuestion}
              selectedQuestion={this.state.selectedQuestion}
              allowPadCreation={this.props.allowPadCreation}
              initialFavorites={this.props.initialFavorites}
              initiallySelectedQuestionId={
                this.exampleInitiallySelected(this.props)
                  ? null
                  : this.props.initiallySelectedQuestionId
              }
              languagesUsed={this.props.languagesUsed}
              language={this.props.language}
              organizationId={this.props.organizationId}
              organizationName={this.props.organizationName}
              takeHome={this.props.takeHome}
              hidden={this.state.activeLibrary !== 'myLibrary'}
            />
          </div>
        </div>
      </div>
    )
  }

  static init(options, domElement) {
    ReactDOM.render(React.createElement(QuestionLibrary, options), domElement)
  }
}
