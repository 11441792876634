import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Button from './button'
import padConfig from './pad_config'

// TODO: remove this dev feature that allows for short timers
const FOCUS_TIME_SECONDS = RegExp('short=1').test(window.location) ? 20 : 5 * 60

class _FocusTimeCountdown extends React.Component {
  static propTypes = {
    darkBg: PropTypes.bool.isRequired,
    startedAt: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      showFocusTimeInfo: false,
      countdownSeconds: (this.props.startedAt + FOCUS_TIME_SECONDS * 1000 - Date.now()) / 1000,
    }
    this._tooltipRef = React.createRef()
    this.audio = null
  }

  componentDidMount() {
    this._interval = setInterval(this.tick, 500)
    document.addEventListener('mousedown', this.hideTooltip)
  }

  componentWillUnmount() {
    if (this._interval) {
      // someone else in the pad ended Focus Time early, so play the alert audio
      this.maybePlayWarning()
      clearInterval(this._interval)
    }
    document.removeEventListener('mousedown', this.hideTooltip)
  }

  tick = () => {
    const secondsRemaining = (this.props.startedAt + FOCUS_TIME_SECONDS * 1000 - Date.now()) / 1000

    if (secondsRemaining > 0) {
      this.setState({ countdownSeconds: secondsRemaining })
    } else {
      this.setState({ countdownSeconds: 0 })
      clearInterval(this._interval)
      this.maybePlayWarning()

      this.props.onFocusTimeToggled(false, true)
    }
  }

  formatTimeRemaining = () => {
    if (!this.state.countdownSeconds) return ''

    let minutes = Math.floor(this.state.countdownSeconds / 60)
    let seconds = Math.floor(this.state.countdownSeconds % 60)

    if (seconds < 10) seconds = `0${seconds}`

    return `${minutes}:${seconds}`
  }

  hideTooltip = (event) => {
    // catch clicks outside of the toolbar
    if (
      this._tooltipRef &&
      this._tooltipRef.current &&
      !this._tooltipRef.current.contains(event.target)
    ) {
      this.setState({ showFocusTimeInfo: false })
    }
  }

  maybePlayWarning = () => {
    if (this.audio) {
      return
    }
    this.audio = new Audio(`${window.CoderPad.ASSETS['focus_time_warning.mp3']}`)
    this.audio.play()
  }

  render() {
    const { isOwner } = padConfig

    if (!this.props.startedAt) {
      return ''
    }

    return (
      <div className={'FocusTime-countdown'}>
        <div
          className={classNames({
            'FocusTime-countdownTimerBox': true,
            'FocusTime-countdownTimerBoxWarning': this.state.countdownSeconds < 11,
          })}
          onClick={() => {
            if (!isOwner) {
              this.setState({ showFocusTimeInfo: !this.state.showFocusTimeInfo })
            }
          }}
        >
          <span className={'FocusTime-countdownText'}>Focus Time </span>
          {this.formatTimeRemaining()}
          {!isOwner && (
            <span className={`fui-triangle-down-large FocusTimeButton-glyph`}>&#9660;</span>
          )}
        </div>
        {this.state.showFocusTimeInfo && (
          <div className="FocusTime-headerFocusTimeInfo" ref={this._tooltipRef}>
            <div className="tooltip-arrow" />
            <div className="tooltip-inner">
              Focus Time (5 minutes of alone time to think) has been shown to
              <a
                href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                drastically improve a candidates’ ability to pass technical assessments
              </a>
              .
              <br />
              <br />
              Your interviewer can’t see what you’re doing in the pad right now.
              <br />
              <br />
              So take a few minutes to plan, brainstorm, or take a deep breath before you dive back
              in.
              <br />
              <br />
              <div className={'FocusTimeInfo-attribution'}>
                Sound:{' '}
                <a
                  href="https://freesound.org/people/rhodesmas/sounds/353206/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  "Intro 01"
                </a>{' '}
                by rhodesmas / CC-BY
              </div>
              <br />
              <br />
              <div className="FocusTimeInfo-buttonTitle">Done with Focus Time?</div>
              <div className="FocusTimeInfo-buttonWrapper">
                <Button
                  darkBg={this.props.darkBg}
                  onClick={() => {
                    this.props.onFocusTimeToggled(false, false)
                  }}
                  type={'primary'}
                >
                  End Early
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { darkColorScheme } = state.editorSettings

  return {
    darkBg: darkColorScheme,
  }
}

const mapDispatchToProps = {
  onFocusTimeToggled(value, auto) {
    return {
      type: 'focus_time_toggled',
      value,
      auto,
    }
  },
}

const FocusTimeCountdown = connect(mapStateToProps, mapDispatchToProps)(_FocusTimeCountdown)

export default FocusTimeCountdown
