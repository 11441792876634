import * as Sentry from '@sentry/browser'
import { applyMiddleware, combineReducers, compose as reduxCompose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import callReducer from './reducers/call'
import editorSettingsReducer from './reducers/editor_settings'
import editorStatusReducer from './reducers/editorStatus'
import executionReducer from './reducers/execution'
import filesReducer from './reducers/files'
import lastCopyReducer from './reducers/last_copy'
import multifileReducer from './reducers/multifile'
import notificationsReducer from './reducers/notifications'
import padSettingsReducer from './reducers/pad_settings'
import playbackHistoryReducer from './reducers/playback_history'
import { projectReducer } from './reducers/project'
import { projectTabsReducer } from './reducers/projectTabs'
import questionReducer from './reducers/question'
import tabsReducer from './reducers/tabs'
import userStateReducer from './reducers/user_state'
import trackEvent from './track_event'

const compose = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || reduxCompose

const analyticsMiddleware = (store) => (next) => (action) => {
  if (action._analytics) {
    trackEvent(action._analytics.name, action._analytics.params)

    // footgun protection
    if (__DEV__) {
      const unexpectedKeys = Object.keys(action._analytics).filter(
        (key) => key !== 'name' && key !== 'params'
      )
      if (unexpectedKeys.length > 0) {
        console.warn(`in action ${action.type}:`)
        console.warn(`ignored unexpected tracking keys: ${unexpectedKeys.join(', ')},`)
        console.warn('did you mean to put these inside `params`?')
      }
    }
  }
  next(action)
}

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware({
    onError: function (error, { sagaStack }) {
      console.error('Error in saga:', error, sagaStack)
      Sentry.captureException(error)
    },
  })
  return {
    ...createStore(
      combineReducers({
        call: callReducer,
        editorSettings: editorSettingsReducer,
        execution: executionReducer,
        files: filesReducer,
        lastCopy: lastCopyReducer,
        multifile: multifileReducer,
        padSettings: padSettingsReducer,
        playbackHistory: playbackHistoryReducer,
        question: questionReducer,
        tabs: tabsReducer,
        projectTabs: projectTabsReducer,
        project: projectReducer,
        userState: userStateReducer,
        notifications: notificationsReducer,
        editorStatus: editorStatusReducer,
        // New state items should be typed and added to ./store.d.ts
      }),
      initialState,
      compose(applyMiddleware(analyticsMiddleware, sagaMiddleware))
    ),
    runSaga: sagaMiddleware.run,
  }
}
