import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../queryStates'
import { Snippet } from '../../types'

export const SNIPPET_QUERY = gql`
  query Snippet($slug: String!) {
    snippet(slug: $slug) {
      id
      slug
      title
      description
      language
      fileContents
    }
  }
`

export interface UseSnippetData {
  snippet: Snippet
}

export function useSnippet(slug: string | undefined) {
  const { data, error, loading } = useQuery<UseSnippetData>(SNIPPET_QUERY, {
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    context: { source: 'useSnippet.ts' },
    skip: !slug,
  })
  const snippet = data?.snippet

  const status = useMemo(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error || !snippet) {
      return queryStates.error('There was an error fetching the snippet')
    } else {
      return queryStates.success()
    }
  }, [error, loading, snippet])

  return useMemo(
    () => ({
      snippet,
      status,
    }),
    [snippet, status]
  )
}
