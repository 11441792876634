import { useEffect, useState } from 'react'

import {
  usePadConfigValue,
  usePadConfigValues,
} from '../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../sync_handle'
import { ConsoleHistory } from '../types'

export const useConsoleHistory = () => {
  const [consoleHistory, setConsoleHistory] = useState<ConsoleHistory>({})
  const hasEnvironments = usePadConfigValue('hasEnvironments')
  const { isPlayback } = usePadConfigValues('hasEnvironments', 'isPlayback')

  useEffect(() => {
    if (!isPlayback) return

    SyncHandle().get(`console`, (newConsoleHistory: Record<string, ConsoleHistory>) => {
      if (hasEnvironments) {
        setConsoleHistory(
          Object.values(newConsoleHistory ?? {}).reduce((acc, val) => ({ ...acc, ...val }), {})
        )
      } else {
        setConsoleHistory(((newConsoleHistory ?? {}) as unknown) as ConsoleHistory)
      }
    })
  }, [hasEnvironments, isPlayback])

  return consoleHistory
}
