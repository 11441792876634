import _ from 'lodash'

import * as EditorSettingsCookie from './editor_settings_cookie'

// Runs on questions edit and edit_with_test_cases.
if ($('.formtastic.question').length > 0) {
  let editor
  // Note: This code runs in the dashboard, not in a pad, so it doesn't use reducers.

  const languageDropdown = $('#question_language')
  let currentLang = CoderPad.LANGUAGES[languageDropdown.val()]

  const initialTabSize = EditorSettingsCookie.currentConfig().tab_sizes[currentLang.name]

  const questionContents = document.getElementById('question_contents')
  if (questionContents) {
    editor = CodeMirror.fromTextArea(questionContents, {
      theme: 'one-light',
      mode: currentLang.mode,
      indentUnit: initialTabSize,
      tabSize: initialTabSize,
      viewportMargin: Infinity,
    })
  }

  let lastLanguage = languageDropdown.val()

  const updateDatabaseSelect = function (language) {
    const languageVal = languageDropdown.val()
    const databaseAllowed = language && language.database_allowed
    const select = document.querySelector('#question_custom_database_id')
    const optionEmpty = document.querySelector('#question_custom_database_id option[value=""]')
    if (databaseAllowed) {
      optionEmpty.text = ''
      select.value = select.dataset.customDatabaseIdPersisted
    } else {
      optionEmpty.text = '(Not available for this language)'
      select.value = ''
    }

    _.each(document.querySelectorAll('#question_custom_database_id option'), (option) => {
      if (option.value === '') {
        return
      }
      option.disabled =
        !databaseAllowed ||
        (languageVal === 'mysql' && option.dataset.language === 'postgresql') ||
        (languageVal === 'postgresql' && option.dataset.language === 'mysql')
      if (option.disabled && option.selected) {
        select.value = ''
      }
    })
  }

  const updateFileSelect = function (language) {
    const fileAllowed = language && language.category !== 'data'
    const select = document.querySelector('#question_custom_file_ids')
    const optionEmpty = document.querySelector('#question_custom_file_ids option[value=""]')
    if (fileAllowed) {
      optionEmpty.text = ''
      select.value = select.dataset.customFileIdPersisted
    } else {
      optionEmpty.text = '(Not available for this language)'
      select.value = ''
    }

    _.each(document.querySelectorAll('#question_custom_file_ids option'), (option) => {
      if (option.value === '') {
        return
      }
      option.disabled = !fileAllowed
      if (option.disabled && option.selected) {
        select.value = ''
      }
    })
  }

  updateDatabaseSelect(currentLang)
  updateFileSelect(currentLang)

  $('#question_language').change(function () {
    const language = languageDropdown.val()
    currentLang = CoderPad.LANGUAGES[language]

    if (editor) {
      const newLangTabSize = EditorSettingsCookie.currentConfig().tab_sizes[currentLang.name]
      editor.setOption('mode', currentLang.mode)
      editor.setOption('indentUnit', newLangTabSize)
      editor.setOption('tabSize', newLangTabSize)

      if (editor.getValue() === CoderPad.LANGUAGES[lastLanguage].example || !editor.getValue()) {
        editor.setValue(currentLang.example)
      }
    }

    updateDatabaseSelect(currentLang)
    updateFileSelect(currentLang)

    lastLanguage = language
  })

  const exampleSolution = document.getElementById('question_solution')
  if (exampleSolution) {
    CodeMirror.fromTextArea(exampleSolution, {
      mode: 'gfm',
      theme: 'one-light',
      lineNumbers: false,
      lineWrapping: true,
      styleActiveLine: false,
      viewportMargin: Infinity,
    })
  }
}
