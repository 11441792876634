import PropTypes from 'prop-types'

const executionPropType = PropTypes.shape({
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
})

export default executionPropType
