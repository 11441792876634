import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AnimatedActionButton,
  ButtonActions,
} from '../../../../components/AnimatedActionButton/AnimatedActionButton'
import { useActiveEnvironment } from '../../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'

export function ProjectRunAction() {
  const { environment } = useActiveEnvironment()
  const [activeTarget, setActiveTarget] = useState('run')
  const dispatch = useDispatch()
  const runTargets = useSelector((state) => state.project.runTargets || {})

  const runCommand = useCallback(
    (runCommand?: string) => {
      dispatch({
        type: 'run_clicked',
        payload: {
          environment,
          runCommand,
        },
        meta: {
          isEnvironment: true,
        },
      })
    },
    [dispatch, environment]
  )

  const handleClick = useCallback(
    (cmd: string) => {
      runCommand(cmd)
      setActiveTarget(cmd)
    },
    [runCommand]
  )
  const { running } = useSelector((state) => {
    const { language, execEnabled, projectTemplateSlug } = state.padSettings
    const running = state.execution.running
    return { language, execEnabled, running, projectTemplateSlug }
  })

  useEffect(() => {
    const runOnHotkeys = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'Enter' || e.key === 's') {
          handleClick(activeTarget)
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
    window.addEventListener('keydown', runOnHotkeys, { capture: true })
    return () => {
      window.removeEventListener('keydown', runOnHotkeys, { capture: true })
    }
  }, [runCommand, activeTarget, handleClick])

  const noTargets = useMemo(() => Object.keys(runTargets).length === 0, [runTargets])

  const btnActions = useMemo<ButtonActions>(() => {
    if (noTargets) {
      return [{ key: 'run', label: 'Run', busyLabel: 'Stop', perform: runCommand }]
    }
    const actionList = Object.entries(runTargets).map(([key, value]) => ({
      key,
      label: `Run ${value.label || key}`,
      busyLabel: `Stop ${value.label || key}`,
      perform: () => runCommand(key),
    })) as ButtonActions
    return actionList
  }, [runCommand, runTargets, noTargets])

  if (!btnActions.length) return null

  return (
    <AnimatedActionButton
      isBusy={running}
      actions={btnActions}
      disabled={noTargets}
      tooltip={
        noTargets ? 'You must have at least one target specified in your .cpad file.' : undefined
      }
    />
  )
}
