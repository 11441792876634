import { usePadConfigValues, usePadContext } from 'packs/dashboard/components/PadContext/PadContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import io, { Socket } from 'socket.io-client'
import { v4 } from 'uuid'

import { TranscriptResult } from '../types'

interface useTranscriberProps {
  transcriberUrl: string
  onInterimResult?: (id: string, result: TranscriptResult) => void
  onFinalResult?: (id: string, result: TranscriptResult) => void
  hasConsentedToTranscription: boolean
}

export const useTranscriber = ({
  transcriberUrl,
  onInterimResult,
  onFinalResult,
  hasConsentedToTranscription,
}: useTranscriberProps) => {
  const currentID = useRef(v4())
  const [sampleRate, setSampleRate] = useState<number>(0)
  const [channelCount, setChannelCount] = useState<number>(0)

  const socketRef = useRef<Socket | null>(null)

  const send = useCallback((data: Int16Array) => {
    const socket = socketRef.current
    if (socket != null) {
      socket.emit('pcm-int-16-audio', data)
    }
  }, [])

  const { slug, firebaseAuthorId, hasTranscriptions } = usePadConfigValues(
    'slug',
    'firebaseAuthorId',
    'hasTranscriptions'
  )
  const { getToken } = usePadContext()

  const establishConnection = useCallback(async (): Promise<Socket> => {
    const token = await getToken()

    const socket = io(transcriberUrl, {
      query: {
        slug,
        userId: firebaseAuthorId,
        sampleRate,
        channelCount,
        token,
      },
      transports: ['websocket', 'polling'],
    })
    socket.on('connect_error', () => {
      // Websocket connection failed, return with polling.
      socket.io.opts.transports = ['polling', 'websocket']
    })
    return socket
  }, [slug, transcriberUrl, firebaseAuthorId, sampleRate, channelCount, getToken])

  useEffect(() => {
    if (hasTranscriptions && hasConsentedToTranscription) {
      if (!sampleRate) {
        console.warn(
          "Sample rate not yet set, `useTranscriber` will not connect to the WebSocket yet. This is normal if a call hasn't started yet."
        )
        return
      }

      if (!channelCount) {
        console.warn(
          "Channel count not yet set, `useTranscriber` will not connect to the WebSocket yet. This is normal if a call hasn't started yet."
        )
        return
      }

      let socket: Socket
      const connect = async () => {
        socket = await establishConnection()
        socketRef.current = socket

        socket.on('tokenExpired', async () => {
          console.log('token expired for Transcription')
          socket.disconnect()

          socket.io.opts.query!.token = await getToken(true)
          socket.connect()
        })

        socket.on('connectionRefused', () => {
          console.log('not allowed to access Transcription')
        })

        socket.on('connect', () => {
          console.log('WebSocket connected')
        })

        socket.on(
          'interim_result',
          (result: { result: TranscriptResult; slug: string; userId: string }) => {
            if (result.slug === slug && result.userId === `${firebaseAuthorId}`) {
              console.log('Received Interim result:', result)
              onInterimResult?.(currentID.current, result.result)
            }
          }
        )

        socket.on(
          'final_result',
          (result: { result: TranscriptResult; slug: string; userId: string }) => {
            if (result.slug === slug && result.userId === `${firebaseAuthorId}`) {
              console.log('Received Final result:', result)
              onFinalResult?.(currentID.current, result.result)
              currentID.current = v4()
            }
          }
        )
      }

      connect()
    }

    return () => {
      // Make sure to reset the current transcription id to prevent the id leaking between transcription sessions.
      currentID.current = v4()
      const socket = socketRef.current
      if (socket) {
        socket.close()
      }
    }
  }, [
    firebaseAuthorId,
    onFinalResult,
    onInterimResult,
    sampleRate,
    channelCount,
    slug,
    transcriberUrl,
    hasTranscriptions,
    hasConsentedToTranscription,
    establishConnection,
    getToken,
  ])

  return {
    send,
    sampleRate,
    setSampleRate,
    channelCount,
    setChannelCount,
  }
}
