import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import TwilioVideo from 'twilio-video'

export default class TwilioTrack extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // Annoyingly, Twilio doesn't expose the Track constructor, so we can't do
    // a proper instanceOf check here. We'll use duck typing.
    track: PropTypes.shape({
      kind: PropTypes.oneOf(['audio', 'video']).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.div.appendChild(this.props.track.attach())
  }

  componentDidUpdate() {
    this.props.track.detach()
    $(this.div).find(this.props.track.kind).remove()
    this.div.appendChild(this.props.track.attach())
  }

  componentWillUnmount() {
    this.props.track.detach()
  }

  render() {
    return (
      <div
        className={classNames(this.props.className, {
          'TwilioTrack--videoMirrored': this.props.track instanceof TwilioVideo.LocalVideoTrack,
        })}
        ref={(div) => (this.div = div)}
      />
    )
  }
}
