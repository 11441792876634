import { useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router'

import { useMemoizedOrganizationHookMaker } from '../../../graphql/hooks/organization/useOrganization/useOrganization'
import { useCurrentPlan } from '../../../graphql/hooks/plans/useCurrentPlan/useCurrentPlan'
import { SearchParamNames } from '../../../graphql/hooks/question/useQuestionFilters/useQuestionFilters'
import { memoizedMakeUseExamples } from '../../../graphql/hooks/question/useQuestionSearch/useExampleSearch'
import { memoizedMakeUseQuestions } from '../../../graphql/hooks/question/useQuestionSearch/useQuestionSearch'
import { useCurrentUser } from '../../../graphql/hooks/users/useCurrentUser/useCurrentUser'
import {
  ExampleQuestion,
  PlanCategorization,
  QueryQuestionsSearchArgs,
  Question,
} from '../../../graphql/types'
import { scrollIntoView, scrollToTop } from '../../../utils/scroll'
import { IQuestionRouteParams } from './Questions'
import type { TabKey } from './TabLabel'

export const useQuestions = (searchParams: QueryQuestionsSearchArgs, isDraft: boolean) =>
  memoizedMakeUseQuestions(`
id
title
isDraft
favorited
takeHome
padType
language
createdAt
description
lastUsedAt
publicTakeHomeSettingId
testCasesEnabled
global
${
  isDraft
    ? `draftValidationErrors {
  message
}`
    : ''
}
user {
  id
  name
}
projectTemplate {
  name
  slug
}
spreadsheet {
  id
  gsheetId
}
`)(searchParams)

export const useExamples = memoizedMakeUseExamples(`
id
title
languages
languageDisplays
difficulty
createdAt
description
padType
questionVariants {
  id
  display
  language
  projectTemplate {
    id
    slug
    name
  }
}
tags {
  id
  tag
}
`)

export function isExampleQuestion(question: Question | ExampleQuestion) {
  return question.id.toString().startsWith('examples/')
}

export function useIsQuestionEditable(question: Question) {
  const { organization } = useMemoizedOrganizationHookMaker(`
    id
    sharedQuestionEditingEnabled
    questionsRestricted
  `)()
  const { currentUser } = useCurrentUser()

  if (isExampleQuestion(question)) return false

  const isOwnerOfQuestion = question?.user?.id === currentUser?.id

  return (
    !organization ||
    (!organization.questionsRestricted &&
      (organization.sharedQuestionEditingEnabled || isOwnerOfQuestion)) ||
    currentUser?.organizationOwner
  )
}

export function useIsExampleQuestionEditable() {
  const { organization } = useMemoizedOrganizationHookMaker(`
    id
    sharedQuestionEditingEnabled
    questionsRestricted
  `)()
  const { currentUser } = useCurrentUser()

  return (
    !organization ||
    (!organization.questionsRestricted && organization.sharedQuestionEditingEnabled) ||
    currentUser?.organizationOwner
  )
}

export function useCanEditQuestionCopies() {
  const { organization } = useMemoizedOrganizationHookMaker(`
    id
    questionsRestricted
  `)()
  const { currentUser } = useCurrentUser()

  return !organization || !organization.questionsRestricted || currentUser?.organizationOwner
}

export function useHasPlan(planCategorization: PlanCategorization) {
  const { currentPlan } = useCurrentPlan()
  return currentPlan?.planCategorization === planCategorization
}

export function useHasEnterprisePlan() {
  return useHasPlan(PlanCategorization.Enterprise)
}

export function useHasFreePlan() {
  return useHasPlan(PlanCategorization.Free)
}

export function useFileBelongsToUser(fileUserId: number | undefined) {
  const { currentUser } = useCurrentUser()
  return currentUser?.id === fileUserId
}

export function useCanDownloadCustomFiles(fileUserId: number | undefined) {
  const { organization } = useMemoizedOrganizationHookMaker('customFileDownloadEnabled')()
  const fileBelongsToUser = useFileBelongsToUser(fileUserId)
  const { currentUser } = useCurrentUser()
  return (
    organization?.customFileDownloadEnabled || fileBelongsToUser || currentUser?.organizationOwner
  )
}

export const usePagination = (
  setFilter: (param: SearchParamNames, value: any) => void,
  onPageChange?: () => void
) => {
  const handlePageChange = useCallback(
    (pageNum: number) => {
      setFilter('page', pageNum)
      if (onPageChange) onPageChange()
    },
    [setFilter, onPageChange]
  )

  return {
    handlePageChange,
  }
}

export function useSelection(isString?: boolean) {
  const { questionId: selected } = useParams<IQuestionRouteParams>()
  const history = useHistory()
  const match = useRouteMatch()

  const handleRoute = useCallback(
    (questionId?: number | string) => {
      const path = generatePath(match.path, { ...match.params, questionId })
      history.push(path)
    },
    [history, match]
  )

  const handleSelect = useCallback(
    (id: number | string, target?: Element) => {
      handleRoute(id)
      if (target) {
        scrollIntoView(target, 500)
      } else {
        scrollToTop()
      }
    },
    [handleRoute]
  )

  const handleDeselect = useCallback(() => {
    handleRoute()
    scrollToTop()
  }, [handleRoute])

  return {
    selectedId: isString ? selected : parseInt(selected || ''),
    handleSelect,
    handleDeselect,
  }
}

// progressively degrade the experience in small viewport
export function useSmallViewport() {
  return useMediaQuery('(max-width:890px)')
}

export function useStoredTabKey(
  initialValue?: TabKey
): [TabKey, React.Dispatch<React.SetStateAction<TabKey>>] {
  const [tab, setTab] = useState<TabKey>(initialValue || (localStorage.getItem('qtab') as TabKey))

  useEffect(() => {
    localStorage.setItem('qtab', tab)
  }, [tab])

  return [tab, setTab]
}

/**
 * Multipurpose hook for storing state in the sessionStorage object.
 * Values must be serializable, since they are stringified before saving.
 */
export function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  const getValue = useCallback(
    (key: string) => {
      const value = sessionStorage.getItem(key)
      if (value) {
        return JSON.parse(value)
      }
      return initialValue
    },
    [initialValue]
  )

  const [value, setValue] = useState<T>(getValue(key))

  const setSessionStorage = useCallback(
    (newValue: T) => {
      sessionStorage.setItem(key, JSON.stringify(newValue))
      setValue(newValue)
    },
    [key, setValue]
  )

  useEffect(() => {
    const value = sessionStorage.getItem(key)
    if (value) {
      setValue(JSON.parse(value))
    }
  }, [key])

  return [value, setSessionStorage]
}
