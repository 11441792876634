import { useThemeColor } from '@codingame/monaco-editor-react'
import clsx from 'classnames'
import React, { useMemo, useState } from 'react'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useMonacoContext } from '../MonacoContext'
import { DragAndDropProvider } from './DragAndDrop/DragAndDropContext'
import { FileTree } from './FileTree/FileTree'

export const FilePane = () => {
  const { activeLanguage } = useMonacoContext()
  const { environment, projectTemplate } = useActiveEnvironment()

  const sidebarBackground = useThemeColor('sidebar.background')
  const sidebarForeground = useThemeColor('sidebar.foreground')
  const style = useMemo(
    () => ({
      backgroundColor: sidebarBackground,
      color: sidebarForeground,
    }),
    [sidebarBackground, sidebarForeground]
  )

  const [isHovering, setIsHovering] = useState(false)

  const { hasEnvironments } = usePadConfigValues('hasEnvironments')
  const hasFilePane = activeLanguage === 'html' || environment?.allowMultipleFiles

  // if this is a Project environment, wait until the projectTemplate object (and its
  // settings) have loaded before trying to render the file pane
  if (environment?.projectTemplateSlug != null && projectTemplate == null) {
    return null
  }

  return (
    <DragAndDropProvider>
      <div
        className={clsx('file-pane', {
          open: hasFilePane,
          isHovering,
          fill: hasEnvironments,
        })}
        style={style}
        data-testid="file-pane"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <FileTree isHovering={isHovering} />
      </div>
    </DragAndDropProvider>
  )
}
