enum EditorStatusActionTypes {
  ErrorOccurred = 'ERROR_OCCURRED',
  ClearError = 'CLEAR_ERROR',
}

const initialState = {
  sentinel: 0,
  hasErrorOccurred: false,
}

type EditorStatusAction =
  | {
      type: EditorStatusActionTypes.ErrorOccurred
    }
  | {
      type: EditorStatusActionTypes.ClearError
    }

export default function editorStatusReducer(state = initialState, action: EditorStatusAction) {
  const { type } = action

  switch (type) {
    case EditorStatusActionTypes.ErrorOccurred:
      return { ...state, hasErrorOccurred: true }
    case EditorStatusActionTypes.ClearError:
      return { ...state, hasErrorOccurred: false, sentinel: state.sentinel + 1 }
  }

  return state
}

export function editorErrorOccurred() {
  return {
    type: EditorStatusActionTypes.ErrorOccurred,
  }
}

export function clearEditorError() {
  return {
    type: EditorStatusActionTypes.ClearError,
  }
}
