import { styled } from '@mui/material'

export const TrackedUserBanner = styled('div')<{ color: string }>(({ theme, color }) => ({
  position: 'absolute',
  bottom: '100%',
  left: 0,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  background: color,
  color: 'black',
  zIndex: 1,
  padding: theme.spacing(0.5, 1),
  fontSize: 14,
}))
