import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'
import { CookieJar } from 'tough-cookie'

import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import SyncHandle from '../../../sync_handle'

export function useCookieJar() {
  const { activeEnvironment } = useEnvironments()
  const [cookieJar, setCookieJar] = useState(
    new CookieJar(undefined, { rejectPublicSuffixes: false })
  )

  useEffect(() => {
    let watcher: (snap: firebase.database.DataSnapshot) => void
    if (activeEnvironment?.slug) {
      watcher = SyncHandle().watch(
        `environmentsData/${activeEnvironment.slug}/cookieJar`,
        (jar: any) => {
          if (jar) {
            setCookieJar(
              CookieJar.fromJSON({
                cookies: [],
                ...jar,
              })
            )
          }
        }
      )
    }

    return () => {
      if (watcher) {
        SyncHandle().off(`environmentsData/${activeEnvironment?.slug}/cookieJar`, watcher)
      }
    }
  }, [activeEnvironment?.slug])

  return cookieJar
}
