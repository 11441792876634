import { gql, useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import {
  CreateQuickActionInput,
  CreateQuickActionPayload,
  MutationCreateQuickActionArgs,
} from '../../../types'

export const CREATE_QUICK_ACTION_MUTATION = gql`
  mutation CreateQuickAction($input: CreateQuickActionInput!) {
    createQuickAction(input: $input) {
      errors {
        message
        path
      }
      message
      quickAction {
        id
        name
        payload
      }
    }
  }
`

export interface CreateQuickActionData {
  createQuickAction: CreateQuickActionPayload
}

export function useCreateQuickAction() {
  const [createQuickActionMutation, { data, error, loading }] = useMutation<
    CreateQuickActionData,
    MutationCreateQuickActionArgs
  >(CREATE_QUICK_ACTION_MUTATION, {
    context: {
      source: 'useCreateQuickAction.ts',
    },
  })

  const createQuickAction = useCallback(
    async (quickActionCreateAttributes: CreateQuickActionInput['quickActionCreateAttributes']) =>
      await createQuickActionMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              quickActions(existingQuickActions = []) {
                const newQuickActionRef = cache.writeFragment({
                  data: data?.createQuickAction.quickAction,
                  fragment: gql`
                    fragment NewQuickAction on QuickAction {
                      id
                      name
                      payload
                    }
                  `,
                })

                return [...existingQuickActions, newQuickActionRef]
              },
            },
          })
        },
        variables: { input: { quickActionCreateAttributes } },
      }),
    [createQuickActionMutation]
  )

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(
        isEmpty(data?.createQuickAction?.message)
          ? error.message
          : data?.createQuickAction?.message || ''
      )
    } else {
      return queryStates.success(data?.createQuickAction?.message || '')
    }
  }, [data, error, loading])

  return {
    error,
    createQuickAction,
    status,
  }
}
