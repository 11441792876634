// Example schemas come from Execute; look there for coderpad.sql and createstate

const DATABASE_INFO = {
  mysql: {
    name: 'MySQL',
    path: '/tmp/mysql/socket/mysqld.sock',
    exampleSchema: `
DESCRIBE departments;
+-------+--------------+------+-----+---------+----------------+
| Field | Type         | Null | Key | Default | Extra          |
+-------+--------------+------+-----+---------+----------------+
| id    | int          | NO   | PRI | NULL    | auto_increment |
| name  | varchar(255) | YES  |     | NULL    |                |
+-------+--------------+------+-----+---------+----------------+
DESCRIBE employees;
+---------------+--------------+------+-----+---------+----------------+
| Field         | Type         | Null | Key | Default | Extra          |
+---------------+--------------+------+-----+---------+----------------+
| id            | int          | NO   | PRI | NULL    | auto_increment |
| first_name    | varchar(255) | YES  |     | NULL    |                |
| last_name     | varchar(255) | YES  |     | NULL    |                |
| salary        | int          | YES  |     | NULL    |                |
| department_id | int          | YES  | MUL | NULL    |                |
+---------------+--------------+------+-----+---------+----------------+
DESCRIBE employees_projects;
+-------------+------+------+-----+---------+-------+
| Field       | Type | Null | Key | Default | Extra |
+-------------+------+------+-----+---------+-------+
| project_id  | int  | YES  | MUL | NULL    |       |
| employee_id | int  | YES  | MUL | NULL    |       |
+-------------+------+------+-----+---------+-------+
DESCRIBE projects;
+------------+--------------+------+-----+---------+----------------+
| Field      | Type         | Null | Key | Default | Extra          |
+------------+--------------+------+-----+---------+----------------+
| id         | int          | NO   | PRI | NULL    | auto_increment |
| title      | varchar(255) | YES  |     | NULL    |                |
| start_date | date         | YES  |     | NULL    |                |
| end_date   | date         | YES  |     | NULL    |                |
| budget     | int          | YES  |     | NULL    |                |
+------------+--------------+------+-----+---------+----------------+

Want to use your own custom database in an interview? Go to https://app.coderpad.io/dashboard/databases to get started.

`,
    schemaJson: {
      tables: [
        {
          name: 'departments',
          columns: [
            { name: 'id', type: 'int', pk: true, nn: true },
            { name: 'name', type: 'varchar(255)', pk: false, nn: false },
          ],
        },
        {
          name: 'projects',
          columns: [
            { name: 'id', type: 'int', pk: true, nn: true },
            { name: 'title', type: 'varchar(255)', pk: false, nn: false },
            { name: 'start_date', type: 'date', pk: false, nn: false },
            { name: 'end_date', type: 'date', pk: false, nn: false },
            { name: 'budget', type: 'int', pk: false, nn: false },
          ],
        },
        {
          name: 'employees',
          columns: [
            { name: 'id', type: 'int', pk: true, nn: true },
            { name: 'first_name', type: 'varchar(255)', pk: false, nn: false },
            { name: 'last_name', type: 'varchar(255)', pk: false, nn: false },
            { name: 'salary', type: 'int', pk: false, nn: false },
            {
              name: 'department_id',
              type: 'int',
              pk: false,
              nn: false,
              fk: { table: 'departments', column: 'id' },
            },
          ],
        },
        {
          name: 'employees_projects',
          columns: [
            {
              name: 'project_id',
              type: 'int',
              pk: false,
              nn: false,
              fk: { table: 'projects', column: 'id' }
            },
            {
              name: 'employee_id',
              type: 'int',
              pk: false,
              nn: false,
              fk: { table: 'employees', column: 'id'}
            },
          ],
        },
      ],
      arrangement: 'spiral',
    },
  },
  postgresql: {
    name: 'PostgreSQL',
    path: '/tmp/postgresql/socket',
    exampleSchema: `
           Table "public.employees_projects"
   Column    |  Type   | Collation | Nullable | Default
-------------+---------+-----------+----------+---------
 project_id  | integer |           |          |
 employee_id | integer |           |          |
Foreign-key constraints:
    "employees_projects_employee_id_fkey" FOREIGN KEY (employee_id) REFERENCES employees(id)
    "employees_projects_project_id_fkey" FOREIGN KEY (project_id) REFERENCES projects(id)

                                      Table "public.projects"
   Column   |          Type          | Collation | Nullable |               Default
------------+------------------------+-----------+----------+--------------------------------------
 id         | integer                |           | not null | nextval('projects_id_seq'::regclass)
 title      | character varying(255) |           |          |
 start_date | date                   |           |          |
 end_date   | date                   |           |          |
 budget     | integer                |           |          |
Indexes:
    "projects_pkey" PRIMARY KEY, btree (id)
Referenced by:
    TABLE "employees_projects" CONSTRAINT "employees_projects_project_id_fkey" FOREIGN KEY (project_id) REFERENCES projects(id)

                                    Table "public.departments"
 Column |          Type          | Collation | Nullable |                 Default
--------+------------------------+-----------+----------+-----------------------------------------
 id     | integer                |           | not null | nextval('departments_id_seq'::regclass)
 name   | character varying(255) |           |          |
Indexes:
    "departments_pkey" PRIMARY KEY, btree (id)
Referenced by:
    TABLE "employees" CONSTRAINT "employees_department_id_fkey" FOREIGN KEY (department_id) REFERENCES departments(id)

                                       Table "public.employees"
    Column     |          Type          | Collation | Nullable |                Default
---------------+------------------------+-----------+----------+---------------------------------------
 id            | integer                |           | not null | nextval('employees_id_seq'::regclass)
 first_name    | character varying(255) |           |          |
 last_name     | character varying(255) |           |          |
 salary        | integer                |           |          |
 department_id | integer                |           |          |
Indexes:
    "employees_pkey" PRIMARY KEY, btree (id)
Foreign-key constraints:
    "employees_department_id_fkey" FOREIGN KEY (department_id) REFERENCES departments(id)
Referenced by:
    TABLE "employees_projects" CONSTRAINT "employees_projects_employee_id_fkey" FOREIGN KEY (employee_id) REFERENCES employees(id)

Want to use your own custom database in an interview? Go to https://app.coderpad.io/dashboard/databases to get started.

`,
    schemaJson: {
      tables: [
        {
          name: 'departments',
          columns: [
            { name: 'id', type: 'integer', pk: true, nn: true },
            { name: 'name', type: 'character varying(255)', pk: false, nn: false },
          ],
        },
        {
          name: 'projects',
          columns: [
            { name: 'id', type: 'integer', pk: true, nn: true },
            { name: 'title', type: 'character varying(255)', pk: false, nn: false },
            { name: 'start_date', type: 'date', pk: false, nn: false },
            { name: 'end_date', type: 'date', pk: false, nn: false },
            { name: 'budget', type: 'integer', pk: false, nn: false },
          ],
        },
        {
          name: 'employees',
          columns: [
            { name: 'id', type: 'integer', pk: true, nn: true },
            { name: 'first_name', type: 'character varying(255)', pk: false, nn: false },
            { name: 'last_name', type: 'character varying(255)', pk: false, nn: false },
            { name: 'salary', type: 'integer', pk: false, nn: false },
            {
              name: 'department_id',
              type: 'integer',
              pk: false,
              nn: false,
              fk: { table: 'departments', column: 'id' },
            },
          ],
        },
        {
          name: 'employees_projects',
          columns: [
            {
              name: 'project_id',
              type: 'integer',
              pk: false,
              nn: false,
              fk: { table: 'projects', column: 'id' },
            },
            {
              name: 'employee_id',
              type: 'integer',
              pk: false,
              nn: false,
              fk: { table: 'employees', column: 'id' },
            },
          ],
        },
      ],
      arrangement: 'spiral',
    },
  }
}

export default DATABASE_INFO
