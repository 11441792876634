// Goes inside the QuestionLibrary as the left list

import * as Sentry from '@sentry/browser'
import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import { createFetcher } from 'utils/fetch/fetch'

import { SearchInput } from '../dashboard/components/SearchInput/SearchInput'
import QuestionListItem from './question_list_item'

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(token)

const BASE_STATE = {
  language: '',
  query: '',
  organizationId: null,
  favorites: [],
  records: [],
  selectedQuestion: null,
  hasMoreQuestions: false,
}

export default class QuestionList extends React.PureComponent {
  static propTypes = {
    allowPadCreation: PropTypes.bool.isRequired,
    initiallySelectedQuestionId: PropTypes.number,
    languagesUsed: PropTypes.arrayOf(PropTypes.string),
    // Null/undefined if not in a pad.
    language: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    onSelectQuestion: PropTypes.func,
    takeHome: PropTypes.bool,
    hidden: PropTypes.bool.isRequired,
  }

  async reset() {
    this.runNewQuery({ language: '', query: '', organizationId: null })

    this.search({
      favorites_only: true,
      per_page: 20,
    }).then((result) => {
      const favorites = result.records
      favorites.forEach((record) => {
        record.mine = record.owner_email === CoderPad.USER.email
      })
      this.setState({ favorites })
    })

    if (this.props.initiallySelectedQuestionId) {
      const resp = await fetcher(`/questions/${this.props.initiallySelectedQuestionId}.json`)
      if (resp.ok) {
        const result = await resp.json()
        this.props.onSelectQuestion(result)
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      ...BASE_STATE,
    }
    this.queryId = 0
    this.lastReceivedQueryId = -1
    this.reset()

    this.onFavoriteQuestion = this.onFavoriteQuestion.bind(this)
    this.onLanguageChange = this.onLanguageChange.bind(this)
    this.onOrganizationIdChange = this.onOrganizationIdChange.bind(this)
    this.onLoadMore = this.onLoadMore.bind(this)
    this.onQueryChange = this.onQueryChange.bind(this)
    this.search = this.search.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.takeHome !== prevProps.takeHome) {
      this.setState(BASE_STATE)
      this.reset()
    }
  }

  get languageOptions() {
    return _.map(this.props.languagesUsed, (lang) => ({
      value: lang,
      label: CoderPad.LANGUAGES[lang]?.display,
    }))
  }

  get organizationIds() {
    if (
      this.props.parentAndChildOrganizations &&
      this.props.parentAndChildOrganizations.length > 0
    ) {
      return this.props.parentAndChildOrganizations.map((org) => ({ value: org[0], label: org[1] }))
    } else {
      return []
    }
  }

  async search(options) {
    const data = new URLSearchParams(_.omitBy(options, _.isNil))
    data.append('pad_types[]', this.props.takeHome ? 'take_home' : 'live')
    data.append('pad_types[]', 'any')
    const res = await fetcher('/search/questions', {
      method: 'post',
      body: new URLSearchParams(data),
    })
    if (!res.ok) {
      return Sentry.captureException(
        new Error(`Search questions failure: ${res.status} (${res.statusText})`)
      )
    }
    return res.json()
  }

  runQuery({ language, query, organizationId, page }, cb) {
    const options = {
      language,
      organization_id: organizationId,
      per_page: 20,
      page: page,
      text: query,
    }
    const currentQueryId = this.queryId++
    return this.search(options).then((result) => {
      if (currentQueryId < this.lastReceivedQueryId) return

      this.lastReceivedQueryId = currentQueryId
      const records = result.records
      records.forEach((record) => {
        record.mine = record.owner_email === CoderPad.USER.email
      })
      const hasMoreQuestions = result.pages_total > result.page_number
      cb({ records, hasMoreQuestions })
    })
  }

  runNewQuery({ language, query, organizationId }) {
    this.runQuery({ language, query, organizationId, page: 0 }, ({ records, hasMoreQuestions }) => {
      this.lastPage = 0
      this.setState({ records, hasMoreQuestions })
    })
  }

  onLanguageChange(evt) {
    const language = evt && evt.value
    this.runNewQuery({
      language,
      query: this.state.query,
      organizationId: this.state.organizationId,
    })
    this.setState({ language })
  }

  onOrganizationIdChange(evt) {
    const orgId = evt && evt.value
    this.runNewQuery({
      language: this.state.language,
      query: this.state.query,
      organizationId: orgId,
    })
    this.setState({ organizationId: orgId })
  }

  onQueryChange(query) {
    this.runNewQuery({
      language: this.state.language,
      query,
      organizationId: this.state.organizationId,
    })
    this.setState({ query })
  }

  onLoadMore() {
    this.runQuery(
      {
        language: this.state.language,
        query: this.state.query,
        organizationId: this.state.organizationId,
        page: ++this.lastPage,
      },
      ({ records, hasMoreQuestions }) => {
        this.setState((prevState) => {
          return {
            records: prevState.records.concat(records),
            hasMoreQuestions,
          }
        })
      }
    )
  }

  async onFavoriteQuestion(question, remove) {
    const res = await fetcher(`/questions/${question.id}/favorite`, {
      method: 'post',
      body: new URLSearchParams({
        remove,
      }),
    })
    if (res.ok) {
      this.setState((prevState) => {
        return {
          favorites: remove
            ? prevState.favorites.filter((favorite) => {
                return favorite.id != question.id
              })
            : [...prevState.favorites, question],
        }
      })
    }
  }

  renderQuestionListSection(headerText, questions) {
    return [
      <li className="header" key={headerText}>
        {headerText}
      </li>,
      ...questions.map((question) => {
        return (
          <QuestionListItem
            key={question.id}
            query={this.state.query}
            question={question}
            onFavorite={this.onFavoriteQuestion}
            onSelect={this.props.onSelectQuestion}
            isFavorite={this.state.favorites.some((favorite) => favorite.id == question.id)}
            isSelected={
              this.props.selectedQuestion && this.props.selectedQuestion.id == question.id
            }
          />
        )
      }),
    ]
  }

  renderListItems() {
    const listItems = []
    let records = this.state.records

    if (
      !this.state.language &&
      !this.state.query &&
      !this.state.organizationId &&
      this.state.favorites.length > 0
    ) {
      // When no query is given, show favorites at the top of the list in their own section
      listItems.push(...this.renderQuestionListSection('Your Favorites', this.state.favorites))
      records = records.filter((question) => {
        return !this.state.favorites.some((favorite) => favorite.id == question.id)
      })
    }
    let [userRecords, orgRecords] = _.partition(records, (record) => record.mine)
    if (userRecords.length > 0) {
      listItems.push(
        ...this.renderQuestionListSection(
          this.props.takeHome ? 'Your Challenges' : 'Your Questions',
          userRecords
        )
      )
    }
    if (orgRecords.length > 0) {
      let questionsByOrganizations = _.groupBy(orgRecords, (record) => record.organization_name)
      // Putting user's organization first in the list of available organization names
      let organizationNameList = _.partition(
        _.keys(questionsByOrganizations),
        (key) => key === this.props.organizationName
      ).flat()
      organizationNameList.forEach((organizationName) => {
        const orgQuestions = questionsByOrganizations[organizationName]
        if (orgQuestions.length > 0) {
          listItems.push(
            ...this.renderQuestionListSection(
              `${organizationName} ${this.props.takeHome ? ' Challenges' : 'Questions'}`,
              orgQuestions
            )
          )
        }
      })
    }
    if (
      listItems.length === 0 &&
      this.state.language === '' &&
      this.state.query === '' &&
      this.state.organizationId !== null
    ) {
      listItems.push(
        <li key="noResults">
          <p className="QuestionList-Message">
            Sorry, there are currently no {this.props.takeHome ? 'challenges' : 'questions'}{' '}
            belonging to this organization.
          </p>
        </li>
      )
    } else if (listItems.length === 0) {
      listItems.push(
        <li key="noResults">
          <p className="QuestionList-Message">Sorry, your search returned no results.</p>
          <p className="QuestionList-Message">
            Queries match against {this.props.takeHome ? 'challenge' : 'question'} titles,
            description and authors.
          </p>
        </li>
      )
    } else if (this.state.hasMoreQuestions) {
      listItems.push(
        <li id="load-more" key="loadMore" onClick={this.onLoadMore}>
          Load more
        </li>
      )
    }
    return listItems
  }

  render() {
    let questionListTopPixels = '77px'
    if (this.organizationIds.length > 0 && !this.props.takeHome) {
      questionListTopPixels = '161px'
    } else if (this.organizationIds.length > 0 || !this.props.takeHome) {
      questionListTopPixels = '113px'
    }

    return (
      <div className={classNames({ hidden: this.props.hidden })}>
        <div id="questions-search">
          <SearchInput
            className="QuestionList-SearchQuery"
            debounce={250}
            onValueChange={this.onQueryChange}
            placeholder={this.props.takeHome ? 'Search challenges…' : 'Search questions…'}
            variant="outlined"
          />
          {this.props.takeHome !== true && (
            <Select
              escapeClearsValue={true}
              isClearable={true}
              isSearchable={true}
              onChange={this.onLanguageChange}
              options={this.languageOptions}
              placeholder="Filter by language"
              className="QuestionList-LanguageSelect"
              classNamePrefix="QuestionList-LanguageSelect"
              styles={{
                container: (base) => ({
                  ...base,
                  height: '37px',
                  width: '100%',
                }),
                control: (base) => ({
                  ...base,
                  borderColor: '#c7d1d9',
                }),
              }}
              value={this.languageOptions.find(({ value }) => value === this.state.language)}
            />
          )}
          {this.organizationIds.length > 0 && (
            <Select
              escapeClearsValue={true}
              isClearable={true}
              isSearchable={true}
              onChange={this.onOrganizationIdChange}
              options={this.organizationIds}
              placeholder="Filter by organization"
              styles={{
                container: (base) => ({
                  ...base,
                  height: '37px',
                  width: '100%',
                }),
                control: (base) => ({
                  ...base,
                  borderColor: '#c7d1d9',
                }),
              }}
              value={this.organizationIds.find(({ value }) => value === this.state.organizationId)}
            />
          )}
        </div>

        <ul
          id="questions-list"
          style={{
            top: questionListTopPixels,
          }}
        >
          {this.renderListItems()}
        </ul>
      </div>
    )
  }
}
