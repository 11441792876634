import { Box, Divider, styled } from '@mui/material'
import { useGlobalEvents } from 'packs/main/playback/GlobalEvents/useGlobalEvents'
import { usePlayback } from 'packs/main/playback/PlaybackContext'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { AiTabContainer, MessageList } from './AiTab'
import { ConversationMessageRow } from './ConversationMessageRow'
import { ConversationMessage } from './Types'
import { useAiChatHistory } from './useAiChatHistory'

const ConversationDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.playback.border,
  padding: `${theme.spacing(1)} 0`,
}))

export const PlaybackAiTab = () => {
  const { frameIndex } = usePlayback()
  const frameLength = useSelector((state) => state.playbackHistory.frameLength)
  const { currentTimestamp } = useGlobalEvents()
  const chatHistory = useAiChatHistory()
  const [visibleMessages, setVisibleMessages] = useState<ConversationMessage[]>([])
  const scrollRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!currentTimestamp || !chatHistory) return
    // If we're at the end of the timeline, don't filter messages.
    if (frameIndex === frameLength - 1) {
      setVisibleMessages(chatHistory)
    } else {
      const newVisibleMessages = chatHistory.filter(
        (message) => message.messageTimestamp <= currentTimestamp
      )
      if (newVisibleMessages.length !== visibleMessages.length) {
        setVisibleMessages(newVisibleMessages)
      }
    }
  }, [currentTimestamp, chatHistory, frameIndex, frameLength, visibleMessages.length])

  const scrollToElement = useCallback((element: HTMLElement) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = element.offsetTop
    }
  }, [])

  let lastConversationId: string | null = null
  return (
    <AiTabContainer>
      <MessageList ref={scrollRef}>
        <Box>
          {visibleMessages.map((m, index) => {
            const cell = (
              <div
                key={index}
                ref={(element) => {
                  if (element && index === visibleMessages.length - 1) {
                    scrollToElement(element)
                  }
                }}
              >
                {lastConversationId != null && m.conversationId !== lastConversationId && (
                  <ConversationDivider>CHAT RESET</ConversationDivider>
                )}
                <ConversationMessageRow
                  role={m.role}
                  content={m.content}
                  authorId={m.authorId}
                  messageId={m.messageId}
                  finishReason={m.finishReason}
                />
              </div>
            )

            if (lastConversationId !== m.conversationId) {
              lastConversationId = m.conversationId
            }

            return cell
          })}
        </Box>
      </MessageList>
    </AiTabContainer>
  )
}
