import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { FC } from 'react'

export interface FileMessageQueueItem {
  title?: string
  message: React.ReactNode
  actions: React.ReactNode[]
  data?: any
}

export interface FileMessageDialogProps {
  title?: string
  message?: React.ReactNode
  open: boolean
  onClose: () => void
  actions: React.ReactNode[]
}

export const FileMessageDialog: FC<FileMessageDialogProps> = ({
  title,
  message,
  open,
  onClose,
  actions,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle color="inherit">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}
