import { useEffect, useState } from 'react'

import SyncHandle from '../../sync_handle'
import { PlaybackParticipant, PlaybackParticipants } from '../types'

export const usePlaybackParticipants = () => {
  const [participants, setParticipants] = useState<PlaybackParticipants>({})
  useEffect(() => {
    SyncHandle().get('/users', (users: PlaybackParticipant[] | null | undefined) => {
      if (users == null) {
        return
      }

      const value: PlaybackParticipants = {}
      for (const [id, user] of Object.entries(users)) {
        value[id] = {
          ...user,
          id,
        }
      }
      setParticipants(value)
    })
  }, [])
  return participants
}
