import _ from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const numVolumeDots = 3

const AudioContext = window.AudioContext || window.webkitAudioContext

export default class VolumeMeter extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { volume: 0 }
  }

  componentDidMount() {
    this.setupAudioProcessing()
  }

  componentWillUnmount() {
    if (this.javascriptNode && this.javascriptNode.onaudioprocess) {
      this.javascriptNode.onaudioprocess.cancel()
      this.javascriptNode.onaudioprocess = null
    }
    if (this._audioContext) this._audioContext.close()
  }

  setupAudioProcessing() {
    const audioContext = (this._audioContext = AudioContext && new AudioContext())
    if (!audioContext) return

    this.analyser = audioContext.createAnalyser()
    const stream = new MediaStream()
    stream.addTrack(this.props.track.mediaStreamTrack)
    const microphone = audioContext.createMediaStreamSource(stream)
    this.javascriptNode = audioContext.createScriptProcessor(2048, 1, 1)

    this.analyser.smoothingTimeConstant = 0.8
    this.analyser.fftSize = 1024

    microphone.connect(this.analyser)
    this.analyser.connect(this.javascriptNode)
    this.javascriptNode.connect(audioContext.destination)

    this.javascriptNode.onaudioprocess = this.handleAudioProcess
  }

  handleAudioProcess = _.throttle(() => {
    const frequencyData = new Uint8Array(this.analyser.frequencyBinCount)
    this.analyser.getByteFrequencyData(frequencyData)
    this.setState({ volume: this.calculateVolume(frequencyData) })
  }, 50)

  // return volume between 0 and numberOfDots + 1
  calculateVolume(frequencyData) {
    // avgAmplitude is between 0 and 255, but in practice rarely above 140
    // better to be overly senstive since this is essentially a "mic is working or not indicator"
    // so consider 100 enough to represent max volume
    const avgAmplitude = _.reduce(frequencyData, (sum, val) => sum + val, 0) / frequencyData.length
    return Math.min(((numVolumeDots + 1) * avgAmplitude) / 100, numVolumeDots + 1)
  }

  render() {
    return (
      <div className="volumeMeter">
        <TransitionGroup>
          {_.times(Math.min(3, Math.floor(this.state.volume)), (index) => {
            return (
              <CSSTransition key={index} classNames="opacity" timeout={{ enter: 100, exit: 500 }}>
                <div className="volumeDot" />
              </CSSTransition>
            )
          }).reverse()}
        </TransitionGroup>
      </div>
    )
  }
}
