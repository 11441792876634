import { Box } from '@mui/material'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React from 'react'

export const MarkdownOutputContainer = ({
  children,
  hidden,
}: {
  children: React.ReactNode
  hidden: boolean
}) => {
  return (
    <Box
      p={2.5}
      pr={0}
      sx={(theme) => ({
        height: `calc(100% - ${theme.spacing(10)})`,
        display: hidden ? 'none' : 'block',
      })}
    >
      <ScrollView>{children}</ScrollView>
    </Box>
  )
}
