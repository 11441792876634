import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFrames } from '../hooks/useFrames'
import { PlaybackParticipant } from '../types'
import { TimelineUser } from './Timeline/TimelineUser'
import { UserDot } from './UserDot'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2)} 0`,
    paddingLeft: '1em',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.mode === 'dark' ? '#22252A' : 'transparent',
    },
  },
  userIndicator: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    height: 30,
    width: 30,
    color: '#37C773',
  },
  activeColor: {
    background: theme.palette.mode === 'dark' ? '#22252A' : 'transparent',
    color: '#37C773',
  },
  userName: {
    overflow: 'hidden',
  },
}))

export const ParticipantRow: FC<{
  authorId: string
  participant: PlaybackParticipant
  active?: boolean
  onClick?: () => void
}> = ({ authorId, participant, active = false, onClick }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { getTimelineForUser } = useFrames()
  const [hovering, setHovering] = useState(false)

  const timeline = useMemo(() => getTimelineForUser(authorId), [getTimelineForUser, authorId])

  const playing = useSelector((state) => state.playbackHistory.playing)

  const onPlay = useCallback(() => {
    dispatch({ type: 'playback_play' })
  }, [dispatch])

  const onPause = useCallback(() => {
    dispatch({ type: 'playback_pause' })
  }, [dispatch])

  const UserIndicator: FC = useCallback(() => {
    let component = <UserDot color={participant.color} />
    if (active) {
      if (playing) {
        component = <PauseIcon onClick={onPlay} />
      } else {
        component = <PlayArrowIcon onClick={onPause} />
      }
    } else if (hovering) {
      component = <PlayArrowIcon onClick={onPlay} />
    }
    return <Box className={styles.userIndicator}>{component}</Box>
  }, [hovering, active, playing, onPlay, onPause, participant, styles.userIndicator])

  return (
    <Grid
      container
      onClick={onClick}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={classNames(styles.root, {
        [styles.activeColor]: active,
      })}
    >
      <Grid item container xs={3}>
        <Grid item xs={2}></Grid>
        <Grid item container xs={10} alignItems="center" wrap="nowrap" className={styles.userName}>
          <UserIndicator />
          {participant.name}
        </Grid>
      </Grid>
      <Grid item xs={7} container alignItems="center">
        <TimelineUser timeline={timeline} />
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'center' }}>
        {timeline.edits}
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'center' }}>
        {timeline.events.filter((e) => e.type === 'execution').length}
      </Grid>
    </Grid>
  )
}
