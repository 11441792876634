import { monaco } from '@codingame/monaco-editor-react'
import * as Sentry from '@sentry/browser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStateEffect from 'utils/hooks/useStateEffect'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { editorErrorOccurred } from '../../reducers/editorStatus'
import { PadUser } from '../FilePane/utils/types'
import { MonacoContext } from '../MonacoContext'
import { useMonacoMutations } from '../useMonacoMutations'
import { MonacoFirepadFile, MonacoFirepadFileProps } from './MonacoFirepadFile'
import { useFirebaseUsers } from './useFirebaseUsers'
import { useMarkdownRenderEvent } from './useMarkdownRenderEvent'
import { useMonacoContextValueAdapter } from './useMonacoContextValueAdapter'
import { useMonacoSettings } from './useMonacoSettings'
import { useSyncFirebase } from './useSyncFirebase'

/**
 * An implementation of MonacoContext that uses the active pad environment as the source of information for files
 * and active files.
 *
 * NOTE: Some functionality (file CRUD, specifically) defined in the MonacoContext value contract is not implemented
 * in this provider implementation, since that functionality is not yet part of the product.
 */
export const MonacoProvider: React.FC = ({ children }) => {
  const firepadErrorMsgs = useRef(new Set())
  const [readyFiles, setReadyFiles] = useState<Set<string>>(new Set())
  const dispatch = useDispatch()
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null)
  const firebaseAuthorId = usePadConfigValue('firebaseAuthorId')
  const padUserId = useMemo(
    () => (firebaseAuthorId ?? Date.now().toString().substring(5)).toString(),
    [firebaseAuthorId]
  )
  const { activeFile, activateFile, environment, files } = useActiveEnvironment()
  const editorViewStates = useRef<
    Record<string, { state: monaco.editor.ICodeEditorViewState; versionId: number }>
  >({})
  const trackedUserId = useSelector((state) => state.userState.trackedUserId)
  const { scrollToPosition } = useMonacoMutations(editor)

  // Make periodic requests to the Rails backend to sync Firebase to the Rails DB.
  useSyncFirebase(editor)
  // Watch for updates to editor settings (tab size, color theme, etc...).
  useMonacoSettings()
  // TODO this might be able to move somewhere else outside of Monaco. It's not actually dependent on Firepad or Monaco.
  // Watch the Firebase users for each file in the active environment.
  const { usersByFile, updateFileUser } = useFirebaseUsers(padUserId)

  // Dispatch Redux actions when the editor contents changes for markdown files.
  useMarkdownRenderEvent(editor, activeFile?.name)

  /**
   * If a user is currently being tracked in the UI, this will return
   * the PadUser object for that user, along with the file ID of the file
   * that the user is currently focused on.
   */
  const trackedUser = useMemo(() => {
    if (trackedUserId) {
      let trackedUserFileId: string = ''
      let trackedUser: PadUser | null = null
      for (const [fileId, users] of Object.entries(usersByFile)) {
        const user = users.find((user) => user.id === trackedUserId)
        if (user != null) {
          trackedUserFileId = fileId
          trackedUser = user
          break
        }
      }
      if (trackedUser != null) {
        return {
          user: trackedUser,
          fileId: trackedUserFileId,
        }
      }
    }
    return null
  }, [trackedUserId, usersByFile])

  /**
   * If a user is currently being tracked, this will automatically activate
   * the file that the user is currently focused on. This allows you to follow
   * the user's cursor as they navigate between files.
   */
  useEffect(() => {
    if (trackedUser != null && activeFile?.id !== trackedUser.fileId) {
      activateFile(trackedUser.fileId)
    }
  }, [editor, trackedUser, activeFile, activateFile])

  /**
   * If a user is currently being tracked and we have the correct file activated,
   * this will scroll the editor to center on the user's cursor position.
   */
  useEffect(() => {
    if (
      editor != null &&
      trackedUser != null &&
      activeFile?.id === trackedUser.fileId &&
      trackedUser.user.cursorPosition
    ) {
      scrollToPosition(trackedUser.user.cursorPosition)
    }
  }, [editor, trackedUser, activeFile, scrollToPosition])

  // Ensures the editor is readOnly when necessary
  useEffect(() => {
    if (editor != null) {
      editor.updateOptions({ readOnly: activeFile?.isLocked ?? false })
    }
  }, [editor, activeFile?.isLocked])

  const areAllFilesReady = useMemo(
    () => files.length > 0 && files.every(({ monacoPath }) => readyFiles.has(monacoPath)),
    [files, readyFiles]
  )
  // Context will be considered ready as soon as the initial files are ready.
  // If a new file is created afterward (and is not ready), the context will still be ready.
  const wereAllFilesReadyOnce = useStateEffect(
    (setWereAllFilesReadyOnce) => {
      if (areAllFilesReady) {
        setWereAllFilesReadyOnce(true)
      }
    },
    [areAllFilesReady, environment?.id],
    false
  )

  const isFirstMountRef = useRef(false)
  useEffect(() => {
    if (wereAllFilesReadyOnce && !isFirstMountRef.current) {
      // notify the redux sagas that the editor is now ready
      dispatch({ type: 'editor_mounted', editor })
      dispatch({ type: 'editor_modified' }) // set modified time to when it is mounted
      isFirstMountRef.current = true
    }
  }, [dispatch, editor, wereAllFilesReadyOnce])

  const onCursorChange = useCallback(
    (e: monaco.editor.ICursorPositionChangedEvent) => {
      if (editor != null) {
        dispatch({
          type: 'tracked_user_changed',
          userId: undefined,
        })
      }
    },
    [dispatch, editor]
  )

  // TODO: for some reason, the jsonDefaults get reset when models change
  // this is a hack to re-set them when the model changes
  const onModelChange = useCallback(
    (e: monaco.editor.IModelChangedEvent) => {
      if (editor != null) {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: true,
          schemaValidation: 'error',
          schemas: [
            {
              uri: 'http://json-schema.org/draft-07/schema#',
              fileMatch: ['.cpad'],
              schema: {
                type: 'object',
                properties: {
                  targets: {
                    type: 'object',
                    additionalProperties: {
                      type: 'object',
                      properties: {
                        label: {
                          type: 'string',
                        },
                        command: {
                          type: 'string',
                        },
                      },
                      required: ['command'],
                    },
                  },
                },
                required: ['targets'],
              },
            },
          ],
        })
      }
    },
    [editor]
  )

  useEffect(() => {
    if (!editor) return
    // if the user manually changes their cursor position, stop following the tracked user
    const cursorPositionEvent = editor.onDidChangeCursorPosition(onCursorChange)
    const modelChangedEvent = editor.onDidChangeModel(onModelChange)

    return () => {
      cursorPositionEvent.dispose()
      modelChangedEvent.dispose()
    }
  }, [editor, onCursorChange, onModelChange])

  // if the user has not yet entered their username, then they are currently being shown the LoginModal,
  // and we do not want to focus the editor while this modal is being displayed.
  const everEnteredUsername = useRef(false)
  useSelector((state) => {
    const me = Object.values<{ name: string; self: boolean }>(state.userState.userInfo).find(
      (user) => user.self
    )
    if (me?.name != null) {
      everEnteredUsername.current = true
    }
  })

  // Ensures setting the model in the editor
  const currentModelPath =
    activeFile != null && readyFiles.has(activeFile.monacoPath) ? activeFile.monacoPath : null
  useEffect(() => {
    let listener: monaco.IDisposable | null
    if (editor != null) {
      let fileModel: monaco.editor.ITextModel | null = null
      if (currentModelPath != null) {
        const fileURI = monaco.Uri.file(currentModelPath)
        fileModel = monaco.editor.getModel(fileURI)
      }
      const previousModel = editor.getModel()
      const viewState = editor.saveViewState()
      if (previousModel != null && viewState != null) {
        editorViewStates.current[previousModel.uri.path] = {
          state: viewState,
          versionId: previousModel.getVersionId(),
        }
      }
      editor.setModel(fileModel)
      if (fileModel != null) {
        listener = fileModel.onDidChangeContent(() => {
          dispatch({ type: 'editor_modified' })
        })

        // Ensure that model change is effective before focusing or restoring view state
        // This gives the time to firepad to init properly
        setTimeout(() => {
          const existingState = editorViewStates.current[fileModel!.uri.path]
          if (existingState != null && existingState.versionId === fileModel!.getVersionId()) {
            editor.restoreViewState(existingState.state)
          }
          if (everEnteredUsername.current) {
            // Wee hack to _not_ focus the editor when the pad view is in a frame. This prevents unexpected scrolling
            // in marketing pages where the sandbox pad view is embedded within an iframe.
            // We also don't want to focus the editor if a user is currently being tracked in the UI
            if (top === self && !trackedUserId && editor.hasTextFocus()) {
              editor.focus()
            }
          }
        }, 0)
      }
    }
    return () => {
      listener?.dispose()
    }
  }, [currentModelPath, editor, dispatch, trackedUserId])

  const onFileReady = useCallback<MonacoFirepadFileProps['onReady']>(
    (filePath) => setReadyFiles((readyFiles) => new Set(readyFiles).add(filePath)),
    []
  )

  const onFileDestroyed = useCallback<MonacoFirepadFileProps['onDestroy']>(
    (filePath) =>
      setReadyFiles((readyFiles) => {
        delete editorViewStates.current[filePath]

        const duplicated = new Set(readyFiles)
        duplicated.delete(filePath)
        return duplicated
      }),
    []
  )

  const onFileError = useCallback<MonacoFirepadFileProps['onError']>(
    (filePath, error) => {
      if (!firepadErrorMsgs.current.has(error?.message)) {
        Sentry.captureException(error, {
          tags: {
            layer: 'firepad',
          },
        })
        firepadErrorMsgs.current.add(error?.message)
      }
      dispatch(editorErrorOccurred())
    },
    [dispatch]
  )

  // This will take the active environment info + a few things from this provider implementation to build a context
  // value that adheres the MonacoContext value contract.
  const ctxVal = useMonacoContextValueAdapter(
    wereAllFilesReadyOnce,
    usersByFile,
    updateFileUser,
    setEditor,
    editor
  )

  return (
    <>
      {editor != null &&
        files.map((file) => (
          <MonacoFirepadFile
            key={file.firebasePath}
            file={file}
            onReady={onFileReady}
            onError={onFileError}
            onDestroy={onFileDestroyed}
          />
        ))}
      <MonacoContext.Provider value={ctxVal}>{children}</MonacoContext.Provider>
    </>
  )
}
