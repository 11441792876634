import { useThemeColor } from '@codingame/monaco-editor-react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'
import { selectUserInfo } from '../selectors'
import { useMonacoContext } from './MonacoContext'

const EditorPaneHeader = () => {
  const { activeFile, getEditor } = useMonacoContext()
  const prettyName = activeFile?.name?.split('/')?.reverse()[0]
  const padUsers = useSelector(selectUserInfo)
  const isPlayback = usePadConfigValue('isPlayback')
  const editor = getEditor()

  const titleBarBackground = useThemeColor('editor.titlebar.background')
  const titleBarForeground = useThemeColor('editor.titlebar.foreground')
  const titleBarUserIndicatorBackground = useThemeColor('editor.titlebar.userIndicatorBackground')
  const titleBarUserIndicatorForeground = useThemeColor('editor.titlebar.userIndicatorForeground')
  const fileBarStyle = useMemo(
    () => ({
      backgroundColor: titleBarBackground,
      color: titleBarForeground,
    }),
    [titleBarBackground, titleBarForeground]
  )
  const fileUserIndicatorStyle = useMemo(
    () => ({
      backgroundColor: titleBarUserIndicatorBackground,
      color: titleBarUserIndicatorForeground,
    }),
    [titleBarUserIndicatorBackground, titleBarUserIndicatorForeground]
  )

  return (
    <div className="file-bar" style={fileBarStyle}>
      <div className="file-bar-name">{prettyName}</div>
      {!isPlayback && (
        <div className="file-user-indicators">
          {activeFile?.users.map((user) => {
            const padUser = padUsers[user.id]

            if (!padUser?.name) {
              // our list of users in the active file has gotten out of sync with the user list
              // in the redux store.  handle this by not trying to render anything for the user
              // indicator.
              return
            }

            const { cursorPosition } = user
            const contentToCursor = editor?.getValue().slice(0, cursorPosition) || ''
            const newLines = (contentToCursor.match(/\n/g) || []).length
            const displayLine = newLines + 1

            return (
              <div className="file-user-indicator" key={user.id} style={fileUserIndicatorStyle}>
                <div className="file-user-indicator-initial" style={{ background: user.color }}>
                  {padUser.name[0].toUpperCase()}
                </div>
                <div className="file-user-indicator-line-number">{displayLine}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default EditorPaneHeader
