import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import SyncHandle from 'packs/main/sync_handle'
import { useEffect, useState } from 'react'

import { ConversationMessage } from './Types'

export const useAiChatHistory = () => {
  const [chatHistory, setChatHistory] = useState<ConversationMessage[]>([])
  const { isPlayback } = usePadConfigValues('isPlayback')

  useEffect(() => {
    if (!isPlayback) return

    SyncHandle().get('chats', (chats: Record<string, Record<string, ConversationMessage>>) => {
      const conversationStarts: Record<string, number> = {}
      Object.keys(chats ?? {}).forEach((conversationId) => {
        conversationStarts[conversationId] = Object.values(
          chats[conversationId]
        )[0].messageTimestamp
      })
      const history = Object.entries(conversationStarts)
        .sort((a, b) => a[1] - b[1])
        .map((c) => c[0])
        .flatMap((conversationId) => {
          return Object.values(chats[conversationId]).map((m) => {
            return {
              ...m,
              // authorId can be stored a a number in firebase, but we want it to be
              // a string for type consistency
              authorId: m.authorId.toString(),
              conversationId,
            }
          })
        })
      setChatHistory(history)
    })
  }, [isPlayback])

  return chatHistory
}
