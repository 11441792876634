import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const OUTLINE_TYPES = ['regular', 'lightDanger', 'info']
const SOLID_TYPES = ['primary', 'danger']

export default class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    // Please, if you use a class name, only use it for external positioning.
    // Do not style the contents of the button custom.
    className: PropTypes.string,
    darkBg: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf([...OUTLINE_TYPES, ...SOLID_TYPES]).isRequired,
    onClick: PropTypes.func,
    tooltip: PropTypes.shape({
      html: PropTypes.bool,
      placement: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      trigger: PropTypes.string,
    }),
    style: PropTypes.object,
  }

  componentDidMount() {
    const tooltipOptions = this.props.tooltip

    if (tooltipOptions && this._el) $(this._el).tooltip(tooltipOptions)
  }

  componentWillUnmount() {
    if (this.props.tooltip) $(this._el).tooltip('destroy')
  }

  componentDidUpdate(prevProps, prevState) {
    const newTooltip = this.props.tooltip
    const oldTooltip = prevProps.tooltip

    if (oldTooltip && !newTooltip) {
      $(this._el).tooltip('destroy')
    } else if (newTooltip && newTooltip !== oldTooltip) {
      $(this._el).tooltip(newTooltip)
    }
  }

  render() {
    const { children, className, darkBg, disabled, extraProps, onClick, type, style } = this.props

    const buttonProps = {
      className: classNames(className, {
        Button: true,
        'Button--solid': SOLID_TYPES.includes(type),
        'Button--outline': OUTLINE_TYPES.includes(type),
        'Button--disabled': disabled,
        'Button--enabled': !disabled,
        'Button--darkBg': darkBg,
        'Button--lightBg': !darkBg,
        'Button--danger': type === 'danger',
        'Button--lightDanger': type === 'lightDanger',
        'Button--info': type === 'info',
        'Button--primary': type === 'primary',
        'Button--regular': type === 'regular',
      }),
      disabled: disabled && 'disabled',
      onClick: onClick || undefined,
      style,
    }

    return (
      <button ref={(el) => (this._el = el)} {...buttonProps} {...extraProps}>
        {children}
      </button>
    )
  }
}
