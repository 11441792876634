import React, { useCallback } from 'react'

import { useLanguageInfo } from './EnvironmentSelector/LanguageInfoProvider'
import trackEvent from './track_event'

export const LanguageSelectorInfoLink = ({ language }: { language: string }) => {
  const { setLanguage } = useLanguageInfo()

  const handleClick = useCallback(() => {
    setLanguage(window.CoderPad.LANGUAGES[language])
    trackEvent('Language Info Clicked', {
      language: language,
      from: 'list',
    })
  }, [language, setLanguage])

  return (
    <a href="#" onClick={handleClick}>
      Info
    </a>
  )
}
