import { Button, ButtonProps, Stack, Typography } from '@mui/material'
import React, { memo } from 'react'

export interface PadSettingsActionProps {
  buttonLabel: string
  label: string
  onClick?: ButtonProps['onClick']
}

export const PadSettingsAction = memo<PadSettingsActionProps>((props) => {
  const { buttonLabel, label, onClick } = props

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
      <Typography>{label}</Typography>
      <Button color="secondary" onClick={onClick} size="small" variant="outlined">
        {buttonLabel}
      </Button>
    </Stack>
  )
})
