import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { call, cancel, delay, fork, put, select, takeEvery } from 'redux-saga/effects'
import TwilioVideo from 'twilio-video'

import padConfig from '../pad_config'
import { selectCallStatus, selectMyUserId, selectUserInfo } from '../selectors'
import SyncHandle from '../sync_handle'
import CallData from '../video/call_data'

// When in a room, we listen on this for Twilio events.
// When leaving a call, we close this.
let twilioChannel

let videoDegradedFork
const VIDEO_DEGRADATION_THRESHOLD = 3
const VIDEO_DEGRADATION_TIMEOUT = 30 * 1000

export default function* twilioSaga() {
  yield fork(watchForTwilioRoomIdAndDeviceChanges)

  yield takeEvery(
    ['intent_to_start_call_expressed', 'invite_accepted', 'reconnect_to_call_clicked'],
    createTracksForCall
  )
  yield takeEvery('device_switched', switchDevice)
  yield takeEvery('call_join_requested', joinOrCreateCall)
  yield takeEvery('call_canceled', stopLocalTracks)
  yield takeEvery('call_ended', function* () {
    yield call(stopLocalTracks)
    yield call(disconnectFromRoom)
    yield call(closeTwilioChannel)
  })
  yield takeEvery('call_dropped', function* () {
    CallData.localAudioTrack = null
    CallData.localVideoTrack = null
    CallData.room = null
    yield call(closeTwilioChannel)
  })
  yield takeEvery('mute_clicked', handleMute)
  yield takeEvery('focus_time_toggled', handleFocusTimeToggle)
}

function* watchForTwilioRoomIdAndDeviceChanges() {
  const chan = eventChannel((emit) => {
    SyncHandle().watch('twilioRoomId', (id) => emit(['room_id', id]))

    if (navigator.mediaDevices && typeof navigator.mediaDevices.addEventListener == 'function')
      navigator.mediaDevices.addEventListener('devicechange', (evt) => emit(['device_change', evt]))
    return () => null // This channel remains open for entire session, don't bother to cleanup
  })

  yield takeEvery(chan, function* ([eventType, param]) {
    if (eventType === 'room_id') yield call(handleTwilioRoomIdChange, param)
    else if (eventType === 'device_change') yield call(handleDeviceChange, param)
  })
}

function* handleTwilioRoomIdChange(id) {
  // Only show the invite dialog if the user is not in the call, and the web
  // server indicates there is an ongoing call.
  if (id == null) return
  const callStatus = yield select(selectCallStatus)
  if (callStatus !== 'no_call') return

  try {
    const roomInfoResult = yield fetch(`/${padConfig.slug}/room_info?room_id=${id}`).then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        throw new Error(`${res.statusText} - ${res.status}`)
      }
    })
    if (roomInfoResult && roomInfoResult.status === 'in-progress')
      yield put({
        type: 'invited_to_call',
        twilioRoomId: id,
        _analytics: {
          name: 'Call Invite Received',
        },
      })
  } catch (err) {
    // Swallow exceptions, but log to keep an eye on how often this happens
    Sentry.withScope((scope) => {
      scope.setExtra('callStage', 'possibly_invited_room_info_lookup')
      Sentry.captureException(err)
    })
  }
}

function* createTracksForCall() {
  let audioTrack, videoTrack
  try {
    const res = yield call([TwilioVideo, TwilioVideo.createLocalTracks], {
      audio: {
        advanced: [
          {
            echoCancellation: true,
            noiseSuppression: true,
          },
        ],
      },
      video: { facingMode: 'user' },
    })
    audioTrack = res.audioTrack
    videoTrack = res.videoTrack
  } catch (err) {
    if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
      yield put({
        type: 'permissions_denied',
        _analytics: {
          name: 'Call Setup Failed',
          params: {
            reason: 'permissions',
          },
        },
      })
      return
    }
    // For non-permission errors, try again with just audio
    try {
      audioTrack = yield call([TwilioVideo, TwilioVideo.createLocalAudioTrack])
    } catch (audioErr) {
      yield put({
        type: 'video_errored',
        _analytics: {
          name: 'Call Setup Failed',
          params: {
            reason: 'device_open',
          },
        },
      })
      return
    }
  }

  CallData.localAudioTrack = audioTrack
  CallData.localVideoTrack = videoTrack

  yield put({
    type: 'local_tracks_created',
    audioDeviceId: audioTrack && audioTrack.mediaStreamTrack.getSettings().deviceId,
    videoDeviceId: videoTrack && videoTrack.mediaStreamTrack.getSettings().deviceId,
  })

  yield call(enumerateDevices)
}

function* switchDevice(action) {
  // Switching to a specific device ID.
  const { kind, deviceId } = action

  const localTrackKey = kind === 'video' ? 'localVideoTrack' : 'localAudioTrack'
  if (CallData[localTrackKey]) CallData[localTrackKey].stop()
  CallData[localTrackKey] = null

  // For video the deviceId can be null which means you're "switching" to
  // joining the call with no video.
  if (action.deviceId !== '____no_video') {
    let track

    try {
      const createFn =
        kind === 'video' ? TwilioVideo.createLocalVideoTrack : TwilioVideo.createLocalAudioTrack
      track = yield call([TwilioVideo, createFn], { deviceId: { exact: deviceId } })
    } catch (err) {
      if (err.name === 'NotAllowedError' || err.name === 'PermissionsDeniedError')
        yield put({ type: 'permissions_denied' })
      else yield put({ type: 'device_switch_failed', kind })
      return
    }

    CallData[localTrackKey] = track
  }

  yield put({
    type: 'device_switch_succeeded',
    kind,
    deviceId,
  })
}

function* joinOrCreateCall() {
  // This can either be joining a call or starting a call.
  const userId = yield select(selectMyUserId)
  const fromInvite = yield select((state) => state.call.fromInvite)
  let token
  try {
    token = yield fetch(`/${padConfig.slug}/twilio_token?username=${userId}`).then((res) => {
      if (res.ok) {
        return res.text()
      } else {
        throw new Error(`${res.statusText} - ${res.status}`)
      }
    })
  } catch (err) {
    yield put({
      type: 'video_errored',
      errorText: "We couldn't contact CoderPad's servers to set up the call.",
      _analytics: {
        name: 'Call Join Failed',
        params: {
          from_invite: fromInvite,
        },
      },
    })
    yield call(stopLocalTracks)
    Sentry.withScope((scope) => {
      scope.setExtra('callStage', 'call_join_requested_twilio_token_lookup')
      Sentry.captureException(err) // Monitor these; some amount are expected (bad connection)
    })
    return
  }

  let room
  try {
    room = yield call([TwilioVideo, TwilioVideo.connect], token, {
      audio: false,
      video: false,
      networkQuality: { local: 3, remote: 3 },
    })
  } catch (err) {
    yield put({
      type: 'video_errored',
      errorText: "We couldn't establish a connection to Twilio's servers to join the call.",
      _analytics: {
        name: 'Call Join Failed',
        params: {
          from_invite: fromInvite,
        },
      },
    })
    yield call(stopLocalTracks)
    Sentry.withScope((scope) => {
      scope.setExtra('callStage', 'call_join_requested_twilio_connect')
      Sentry.captureException(err)
    })
    return
  }

  CallData.room = room
  SyncHandle().set('/twilioRoomId', room.sid)

  const [audioState, videoState] = yield select((state) => [
    state.call.audioDeviceState,
    state.call.videoDeviceState,
  ])

  try {
    const lp = room.localParticipant
    if (audioState === 'open') yield call([lp, lp.publishTrack], CallData.localAudioTrack)
    if (videoState === 'open') yield call([lp, lp.publishTrack], CallData.localVideoTrack)
  } catch (err) {
    yield put({
      type: 'video_errored',
      // TODO If we see this error happening a significant amount of the time in the wild,
      // improve this crappy error messaging.
      errorText: "We couldn't add your audio and video to the call.",
      _analytics: {
        name: 'Call Join Failed',
        params: {
          from_invite: fromInvite,
        },
      },
    })
    yield call(stopLocalTracks)
    yield call(disconnectFromRoom)
    Sentry.withScope((scope) => {
      scope.setExtra('callStage', 'call_join_requested_publish_tracks')
      Sentry.captureException(err)
    })
    return
  }

  // Set up listening to Twilio events
  twilioChannel && twilioChannel.close()
  twilioChannel = eventChannel((emit) => {
    const eventsToHandle = {
      trackSubscribed: ['track', 'publication', 'participant'],
      trackUnsubscribed: ['track', 'publication', 'participant'],
      participantConnected: ['participant'],
      participantDisconnected: ['participant'],
      disconnected: ['room', 'error'],
      dominantSpeakerChanged: ['dominantSpeaker'],
    }

    // For each event above, pass it through by emitting an object with an
    // eventName parameter, and named arguments from Twilio.
    const handlers = _.mapValues(eventsToHandle, (eventArgNames, eventName) => (...args) =>
      emit({
        eventName,
        ..._.zipObject(eventArgNames, args),
      })
    )
    for (const [eventName, handler] of Object.entries(handlers)) room.on(eventName, handler)

    const networkQualityHandler = (...args) =>
      emit({
        eventName: 'networkQualityLevelChanged',
        ..._.zipObject(['networkQualityLevel', 'networkQualityStats'], args),
      })
    room.localParticipant.on('networkQualityLevelChanged', networkQualityHandler)

    // And then remove all those listeners when the channel is closed.
    return () => {
      for (const [eventName, handler] of Object.entries(handlers)) room.off(eventName, handler)
      room.off('networkQualityLevel', networkQualityHandler)
    }
  })

  yield takeEvery(twilioChannel, function* (twilioEvent) {
    switch (twilioEvent.eventName) {
      case 'trackSubscribed':
        yield put({
          type: 'call_track_subscribed',
          kind: twilioEvent.track.kind,
          twilioTrackId: twilioEvent.track.sid,
          userId: twilioEvent.participant.identity,
        })
        break
      case 'trackUnsubscribed':
        yield put({
          type: 'call_track_unsubscribed',
          kind: twilioEvent.track.kind,
          twilioTrackId: twilioEvent.track.sid,
          userId: twilioEvent.participant.identity,
        })
        break
      case 'participantConnected':
        yield put({
          type: 'user_joined_call',
          userId: twilioEvent.participant.identity,
          twilioParticipantId: twilioEvent.participant.sid,
        })
        break
      case 'participantDisconnected':
        yield put({
          type: 'user_left_call',
          userId: twilioEvent.participant.identity,
          twilioParticipantId: twilioEvent.participant.sid,
        })
        break
      case 'disconnected': {
        const callStatus = yield select(selectCallStatus)
        if (callStatus === 'in_call')
          yield put({
            type: 'call_dropped',
          })
        yield cancel(videoDegradedFork)
        break
      }
      case 'dominantSpeakerChanged':
        // TODO
        break
      case 'networkQualityLevelChanged': {
        yield put({
          type: 'network_quality_level_changed',
          networkQualityLevel: twilioEvent.networkQualityLevel,
          networkQualityStats: twilioEvent.networkQualityStats,
        })
        const videoDegraded = yield select((state) => state.call.videoDegraded)
        if (
          !videoDegraded &&
          twilioEvent.networkQualityStats.video.send < VIDEO_DEGRADATION_THRESHOLD
        ) {
          videoDegradedFork = yield fork(setVideoDegraded)
        } else if (
          !videoDegraded &&
          twilioEvent.networkQualityStats.video.send >= VIDEO_DEGRADATION_THRESHOLD
        ) {
          yield cancel(videoDegradedFork)
        }
        break
      }
    }
  })

  // Events won't tell us about users who were already on the call when we joined.
  // Look up those users now.
  const userIdToTwilioInfo = {}
  const userInfo = yield select(selectUserInfo)
  for (const [sid, participant] of room.participants) {
    if (Object.hasOwnProperty.call(userInfo, participant.identity)) {
      // Grab the audio and video track publications if any.
      // Note: .values().next().value is an idiom for grabbing the first value
      // out of a Map. (We only expect 0 or 1 tracks per type.)
      const audioPub = participant.audioTracks.values().next().value
      const videoPub = participant.videoTracks.values().next().value

      userIdToTwilioInfo[participant.identity] = {
        participantId: sid,
        audioTrackId: audioPub && audioPub.track && audioPub.track.sid,
        videoTrackId: videoPub && videoPub.track && videoPub.track.sid,
      }
    }
  }

  yield put({
    type: 'call_joined',
    twilioRoomId: room.sid,
    twilioUsers: userIdToTwilioInfo,
    _analytics: {
      name: 'Call Joined',
      params: {
        from_invite: fromInvite,
        other_callers: room.participants.size,
        with_video: videoState === 'open',
      },
    },
  })
}

function* setVideoDegraded() {
  yield delay(VIDEO_DEGRADATION_TIMEOUT)
  yield put({ type: 'network_quality_video_degraded' })
}

function* stopLocalTracks() {
  if (CallData.localAudioTrack) {
    CallData.localAudioTrack.stop()
    CallData.localAudioTrack = null
  }
  if (CallData.localVideoTrack) {
    CallData.localVideoTrack.stop()
    CallData.localVideoTrack = null
  }
}

function* disconnectFromRoom() {
  if (CallData.room) {
    // As an extra step to make sure any local tracks don't tie up the webcam/mic,
    // unpublish them
    for (const trackPub of CallData.room.localParticipant.tracks.values()) trackPub.unpublish()

    CallData.room.disconnect()
    CallData.room = null
  }
}

function* closeTwilioChannel() {
  twilioChannel && twilioChannel.close()
  twilioChannel = null
}

// Mute or unmute action
// In the code we call temporarily hiding your video "muting"
function* handleMute(action) {
  const track = action.kind === 'video' ? CallData.localVideoTrack : CallData.localAudioTrack
  if (track) {
    if (action.enabled) track.enable()
    else track.disable()
  }
}

// During Focus Time, audio and video are muted for everyone in the call
function* handleFocusTimeToggle(action) {
  if (action.type === 'focus_time_toggled') {
    const focusTimeEnabled = action.value

    const tracks = []

    tracks.push(CallData.localVideoTrack)
    tracks.push(CallData.localAudioTrack)

    tracks.forEach((track) => {
      if (track) {
        if (focusTimeEnabled) track.disable()
        // mute tracks when it is focus time
        else track.enable() // unmute when it's no longer focus time
      }
    })
  }
}

function* handleDeviceChange() {
  // A media device was plugged in or unplugged.
  // Ignore these unless we're in pending state...we use them to update the dropdown list.
  // The event object contains no information so we must call enumerateDevices() again.
  const callStatus = yield select(selectCallStatus)
  if (callStatus === 'pending') yield call(enumerateDevices)
}

function* enumerateDevices() {
  try {
    const devices = yield call([navigator.mediaDevices, navigator.mediaDevices.enumerateDevices])
    yield put({
      type: 'devices_enumerated',
      devices,
    })
  } catch (err) {
    yield put({ type: 'device_enumerate_failed' })
    Sentry.withScope((scope) => {
      scope.setExtra('callStage', 'enumerate_devices')
      Sentry.captureException(err)
    })
  }
  // TODO: Update local audio/video track if it was removed
}
