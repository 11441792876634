import { gql, useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { MutationUpdatePadsArgs, PadAttributes, UpdatePadsPayload } from '../../../types'
import { makeEvictor } from '../../../utils/cacheUtils'

export const PAD_UPDATE_MUTATION = gql`
  mutation UpdatePad($input: UpdatePadsInput!) {
    updatePads(input: $input) {
      pads {
        id
        slug
        title
        state
        team {
          id
          name
        }
      }
    }
  }
`

export interface IUpdatePadData {
  updatePads: UpdatePadsPayload
}

const evictor = makeEvictor<IUpdatePadData>()

export function usePadUpdate() {
  const [padUpdate, { data, error, loading }] = useMutation<IUpdatePadData, MutationUpdatePadsArgs>(
    PAD_UPDATE_MUTATION,
    {
      update(cache, { data }) {
        const updatedPads = data?.updatePads?.pads
        if (updatedPads?.length) {
          updatedPads.forEach((pad) => {
            if (pad?.state === 'deleted') {
              evictor(cache, pad)
            }
          })
        }
      },
    }
  )

  const handlePadUpdate = useCallback(
    (padAttrs: PadAttributes | PadAttributes[]) =>
      padUpdate({
        variables: { input: { padsAttributes: Array.isArray(padAttrs) ? padAttrs : [padAttrs] } },
        context: { source: 'usePadUpdate.ts' },
      }).catch((err) => null),
    [padUpdate]
  )

  const handlePadUpdateWithError = useCallback(
    (padAttrs: PadAttributes | PadAttributes[]) =>
      padUpdate({
        variables: { input: { padsAttributes: Array.isArray(padAttrs) ? padAttrs : [padAttrs] } },
        context: { source: 'usePadUpdate.ts' },
      }),
    [padUpdate]
  )

  const status = useMemo(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error('There was an error updating the pad state')
    } else if (data?.updatePads?.pads?.length) {
      return queryStates.success('Pad state updated')
    } else {
      return queryStates.initial()
    }
  }, [data, error, loading])

  return useMemo(
    () => ({
      padUpdate: handlePadUpdate,
      padUpdateWithError: handlePadUpdateWithError,
      status,
    }),
    [handlePadUpdate, handlePadUpdateWithError, status]
  )
}
