import { gql, useQuery } from '@apollo/client'

import * as queryStates from '../../../queryStates'
import { Currency, Plan } from '../../../types'

export const PLAN_INFORMATION_QUERY = gql`
  query Plans($currency: Currency) {
    plans(currency: $currency) {
      allowOverages
      id
      title
      description
      widgetSubTitle
      widgetDescription
      current
      monthlyPrice
      annualPrice
      formattedMonthlyPrice
      formattedAnnualPrice
      overagePrice
      formattedOveragePrice
      billingCyclePadLimit
      planCategorization
      userAccountLimit
      canBeSelected
      currency
      upgradeOrder
      annual
      hasAnnualAlternative
      upgradeOrder
    }
  }
`

export interface IPlansInformationData {
  plans: Plan[]
}

export function usePlansInformation(currency?: Currency) {
  const { data, error, loading, refetch } = useQuery<IPlansInformationData>(
    PLAN_INFORMATION_QUERY,
    {
      context: {
        source: 'usePlansInformation.ts',
      },
      variables: {
        currency: currency,
      },
    }
  )

  const plans = data?.plans

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error != null) {
    status = queryStates.error(
      `An error occurred while fetching plan information${
        error.message ? ': ' + error.message : ''
      }`
    )
  } else {
    status = queryStates.success()
  }

  return {
    plans,
    status,
    refetch,
  }
}
