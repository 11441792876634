import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { WhiteboardPreviewMode } from '../dashboard/components/Whiteboard/WhiteboardPreviewMode'
import padConfig from './pad_config'

export default function DrawingTab(props) {
  const { darkColorScheme } = useSelector((state) => state.editorSettings)
  // Effect to trigger window resize event when the drawing tab visibility changes. Since the tab is initially
  // hidden, Whiteboard is rendered with no size. When it becomes shown, a resize event will trigger Whiteboard
  // to resize its canvases and fill the tab area.
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [props.hidden])

  if (padConfig.drawingBoardId) {
    return (
      <div
        style={{ height: '100%' }}
        className={classNames('DrawingTab', { hidden: props.hidden })}
      >
        <WhiteboardPreviewMode
          drawingBoardId={padConfig.drawingBoardId}
          darkColorScheme={darkColorScheme}
        />
      </div>
    )
  }
  return (
    <div className={classNames({ hidden: props.hidden, DrawingTab: true })}>
      {padConfig.drawingImageUrl ? (
        <img className="Drawing-exportedImage" src={padConfig.drawingImageUrl} />
      ) : (
        <div className="Drawing-waitMessage">
          The image is being processed and will be available soon. Please check back again shortly.
        </div>
      )}
    </div>
  )
}
