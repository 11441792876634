import type { LanguageClientId } from '@codingame/monaco-languageclient-react'

export enum Language {
  BASH = 'bash',
  C = 'c',
  CLOJURE = 'clojure',
  COFFEESCRIPT = 'coffeescript',
  CPP = 'cpp',
  CSHARP = 'csharp',
  DART = 'dart',
  ELIXIR = 'elixir',
  ERLANG = 'erlang',
  FSHARP = 'fsharp',
  GO = 'go',
  GSHEETS = 'gsheets',
  HASKELL = 'haskell',
  HTML = 'html',
  JAVA = 'java',
  JAVASCRIPT = 'javascript',
  JULIA = 'julia',
  KOTLIN = 'kotlin',
  LUA = 'lua',
  MARKDOWN = 'markdown',
  MYSQL = 'mysql',
  OBJC = 'objc',
  OCAML = 'ocaml',
  PERL = 'perl',
  PHP = 'php',
  PLAINTEXT = 'plaintext',
  POSTGRESQL = 'postgresql',
  PYTHON = 'python',
  PYTHON3 = 'python3',
  R = 'r',
  RUBY = 'ruby',
  RUST = 'rust',
  SCALA = 'scala',
  SOLIDITY = 'solidity',
  SWIFT = 'swift',
  TCL = 'tcl',
  TYPESCRIPT = 'typescript',
  VB = 'vb',
  VERILOG = 'verilog',
}

type LanguageCategory =
  | 'interpreted'
  | 'compiled'
  | 'frontend'
  | 'data'
  | 'documentation'
  | 'jupyter'
  | 'spreadsheets'
  | 'mobile'

interface Autocomplete {
  type: 'lsp' | 'legacy'
  diagnostics?: boolean
}

interface Package {
  database: string
  display: string
  example: string
  name: string
}

interface Packages {
  [id: string]: Package
}

interface File {
  path: string
  label: string
  language: string
  default_path: string
  example: string
}

type Mode =
  | string
  | {
      name: string
      version: number
    }

export interface LanguageConfiguration {
  default_path: string
  display: string
  version?: string
  tab_size: number
  mode: Mode
  category: LanguageCategory
  execution?: boolean
  custom_file_allowed?: boolean
  example: string
  name: string
  autocomplete?: Autocomplete
  test_case_grading?: boolean
  database_allowed?: boolean
  packages?: Packages
  multifile_example?: string
  global_file_path?: string
  global_file_label?: string
  files?: File[]
  custom_database?: boolean
  language_client_id?: LanguageClientId
  monaco_language?: string
  type?: string
  id?: number
}

export function isLanguageAutocompleted(language: string): boolean {
  return (
    window.CoderPad.LANGUAGES[language]?.language_client_id != null || language === Language.HTML
  )
}
