import { gql, useQuery } from '@apollo/client'
import { Quota } from 'graphql/types'
import { useMemo } from 'react'

import * as queryStates from '../../queryStates'

export interface IActiveQuotaData {
  user: {
    id: number
    quotaOwner: boolean
    activeQuota: Quota
  }
}

export function buildActiveQuotaQuery(fieldFragment: string) {
  return gql`
    query Quota {
      user {
        id
        quotaOwner
        activeQuota {
          ... on Quota {
            id
            ${fieldFragment}
          }
        }
      }
    }
  `
}

export function useActiveQuotaHookBuilder(fieldFragment: string) {
  return useMemo(() => {
    const query = buildActiveQuotaQuery(fieldFragment)
    return function useActiveQuota() {
      const { data, error, loading, refetch } = useQuery<IActiveQuotaData>(query, {
        context: {
          source: 'useActiveQuota.ts',
        },
      })

      const activeQuota = {
        quotaOwner: data?.user.quotaOwner,
        ...data?.user.activeQuota,
      }

      let status = queryStates.initial()
      if (loading) {
        status = queryStates.loading()
      } else if (error) {
        status = queryStates.error(
          `An error occurred while fetching quota information${
            error.message ? ': ' + error.message : ''
          }`
        )
      } else {
        status = queryStates.success()
      }

      return {
        activeQuota,
        status,
        refetch,
      }
    }
  }, [fieldFragment])
}
