import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Button from './button'
import padConfig from './pad_config'

class _FocusTimeIntroModal extends React.Component {
  static propTypes = {
    darkBg: PropTypes.bool.isRequired,
    onFocusTimeEnabled: PropTypes.func.isRequired,
  }

  handleFocusTimeClick = () => {
    this.props.onFocusTimeEnabled()
  }

  render() {
    return (
      <div className={'FocusTimeIntroModal-content'}>
        <div className="FocusTimeIntroModal-header">Start Focus Time</div>

        {padConfig.isOwner ? (
          <div>
            How can you assess a candidate’s coding skillset when they’re under artificial stress?
            Giving a qualified candidate a few minutes to collect their thoughts can{' '}
            <a
              href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
              target="_blank"
              rel="noopener noreferrer"
            >
              drastically improve their ability to pass technical assessments
            </a>
            .
            <br />
            <br />
            Focus Time will “pause” the interview for 5 minutes, blurring your screen while the
            candidate starts to think through the problem without the stress of someone watching.
            We’ll ring a bell when it’s time for you to come back to the interview.
            <br />
            <br />
            (If you’re using a 3rd party audio/videoconference, be sure to mute those as well).
          </div>
        ) : (
          <div>
            You’re stressed, we get it.{' '}
            <a
              href="https://coderpad.io/blog/interview-with-chris-parnin-mahnaz-behroozi"
              target="_blank"
              rel="noopener noreferrer"
            >
              You’re more likely to perform better
            </a>{' '}
            if you take a few minutes to collect your thoughts.
            <br />
            <br />
            Focus Time “pauses” your interview for 5 minutes, so you can think through the problem
            on your own, without the interviewer watching. We’ll ring a bell when 10 seconds are
            left, and again when time is up.
          </div>
        )}

        <div className={'FocusTimeIntroModal-buttonWrap'}>
          <Button
            darkBg={this.props.darkBg}
            onClick={this.handleFocusTimeClick}
            extraProps={{ 'data-toggle': 'modal', 'data-target': '#focus-time-modal' }}
            type={'primary'}
          >
            Begin Focus Time
          </Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { darkColorScheme } = state.editorSettings

  return {
    darkBg: darkColorScheme,
  }
}

const mapDispatchToProps = {
  onFocusTimeEnabled() {
    return {
      type: 'focus_time_toggled',
      value: true,
      remote: false,
    }
  },
}

const FocusTimeIntroModal = connect(mapStateToProps, mapDispatchToProps)(_FocusTimeIntroModal)

export default FocusTimeIntroModal
