import { track } from './coderpad_analytics'

$('.payment-info-button-track').on('click',function() {
  track('Checkout payment info modal viewed', {})
})

$('.billing-info-button-track').on('click', function() {
  track('Checkout billing info modal viewed', {})
})

$('#upgrade-confirm-modal #cancel-upgrade').click(() => $('#upgrade-confirm-modal').modal('hide'))
