import { gql, useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'

import * as queryStates from '../../../queryStates'
import {
  HubspotFormSubmitInput,
  HubspotFormSubmitPayload,
  MutationHubspotFormSubmitArgs,
} from '../../../types'

export const HUBSPOT_FORM_SUBMIT = gql`
  mutation HubspotFormSubmit($input: HubspotFormSubmitInput!) {
    hubspotFormSubmit(input: $input) {
      errors {
        message
        path
      }
      message
    }
  }
`

export interface HubspotFormSubmitData {
  hubspotSubmitForm: HubspotFormSubmitPayload
}

export function useHubspotFormSubmit() {
  const isInitialState = useRef(true)
  const [submitHubspotFormMutation, { data, error, loading }] = useMutation<
    HubspotFormSubmitData,
    MutationHubspotFormSubmitArgs
  >(HUBSPOT_FORM_SUBMIT, {
    context: {
      source: 'useHubspotFormSubmit.ts',
    },
  })

  const status = useMemo<queryStates.QueryState>(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error != null) {
      return queryStates.error(
        isEmpty(data?.hubspotSubmitForm?.message)
          ? error.message
          : data?.hubspotSubmitForm?.message || ''
      )
    } else if (isInitialState.current) {
      return queryStates.initial()
    } else {
      return queryStates.success(data?.hubspotSubmitForm?.message || '')
    }
  }, [data, error, loading])

  const submitHubspotForm = useCallback(
    /** Note that the `fields` property of the input is of the format: [{ name: string, value: string }]. */
    async (hubspotFormSubmitAttributes: HubspotFormSubmitInput['hubspotFormSubmitAttributes']) => {
      isInitialState.current = false
      return await submitHubspotFormMutation({
        variables: { input: { hubspotFormSubmitAttributes } },
      })
    },
    [submitHubspotFormMutation]
  )

  return {
    error,
    status,
    submitHubspotForm,
  }
}
