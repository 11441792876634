import { Box, Button, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { createElement } from 'react'

import { ReactComponent as CoderPadLogoLightTheme } from '../../../../assets/images/coderpad_logo_2022_design.svg'
import { ReactComponent as CoderPadLogoDarkTheme } from '../../../../assets/images/coderpad_logo_full_white_red.svg'
import PadBanner from '../pad_banner'

interface SandboxBannerProps {
  useQuestionButton?: boolean
  questionId?: string
}

export const SandboxBanner: React.FC<SandboxBannerProps> = ({
  useQuestionButton = false,
  questionId,
}) => {
  const theme = useTheme()
  const shouldShowBannerText = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const bannerLabel =
    useQuestionButton && questionId != null
      ? 'Hiring? Use this question in an interview'
      : 'Try CoderPad - The Technical Interview Platform'

  const linkDomain = window.CoderPad.CANONICAL_URL

  return (
    <PadBanner visible={true}>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundContrast[50],
          color: theme.palette.secondary.contrastText,
          display: 'flex',
          height: 50,
          paddingX: 2,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ flexGrow: 1 }}
        >
          <Box
            component="a"
            href={`${linkDomain}/register/`}
            sx={{ marginTop: 1.5 }}
            target="_parent"
          >
            {createElement(
              theme.palette.mode === 'light' ? CoderPadLogoDarkTheme : CoderPadLogoLightTheme,
              {
                alt: 'CoderPad Logo',
                height: '25px',
                title: 'CoderPad',
                width: '140px',
              }
            )}
          </Box>
          {shouldShowBannerText && (
            <Typography sx={{ fontSize: 20, fontWeight: 400 }}>{bannerLabel}</Typography>
          )}
          <Stack direction="row" spacing={1}>
            {useQuestionButton && questionId != null ? (
              <Button
                component="a"
                href={`${linkDomain}/register?use_question_id=${questionId}`}
                target="_parent"
              >
                Use question
              </Button>
            ) : (
              <>
                <Button
                  color="brandInterview"
                  component="a"
                  disableElevation
                  href={`${linkDomain}/register/`}
                  sx={{
                    borderRadius: 32,
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                  target="_parent"
                  variant="contained"
                >
                  Sign Up Free
                </Button>
                <Button
                  color="inherit"
                  component="a"
                  href={`${linkDomain}/login/`}
                  sx={{
                    borderRadius: 32,
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                  target="_parent"
                >
                  Login
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </PadBanner>
  )
}
export default SandboxBanner
