import { gql, useQuery } from '@apollo/client'

import * as queryStates from '../../../../graphql/queryStates'
import { ProjectTemplate } from '../../../../graphql/types'

export const GET_PROJECT_TEMPLATE = gql`
  query ProjectTemplate($slug: String!) {
    projectTemplate(slug: $slug) {
      id
      name
      slug
      settings
      category
    }
  }
`

interface IUseEnvironmentProjectTemplateData {
  projectTemplate: ProjectTemplate
}

/**
 * Fetches project template information for the active environment, if applicable.
 */
export function useEnvironmentProjectTemplate(projectTemplateSlug?: string) {
  const { data, loading, error, refetch } = useQuery<IUseEnvironmentProjectTemplateData>(
    GET_PROJECT_TEMPLATE,
    {
      variables: { slug: projectTemplateSlug },
      fetchPolicy: 'cache-and-network',
      skip: projectTemplateSlug == null,
    }
  )
  const projectTemplate = data?.projectTemplate

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error != null || projectTemplate == null) {
    status = queryStates.error('There was an error fetching the Project Template')
  } else if (projectTemplate != null) {
    status = queryStates.success()
  }

  return { status, projectTemplate, refetch }
}
